import get from 'lodash/get';
import { asyncPayload } from '../apiHelpers';
import Api from '../../utils/Api';
import { fetchSoTitleCheck } from './fetchSoTitleCheck';
import { updateSoProposal } from './updateSoProposal';
import { skipTitleCheckModal, titleCheckNotAvailable } from '../../utils/titleCheck';

export function sendSoComplianceCheck(storageQuote, account, soDynamicProposal) {
  const requestPath = `storage/quotes/${storageQuote.sfid}/send_compliance_check`;
  const accountId = account.sfid;
  const promise = Api.update(requestPath, { accountId });

  return (dispatch) => {
    const response = dispatch({
      type: 'sendSoComplianceCheck',
      payload: asyncPayload({
        beforeRequest: {
          soDynamicProposal: {
            showModalLoader: true
          }
        },
        request: promise
      })
    });

    response.then((data) => {
      if (get(data, 'value.error', null)) {
        dispatch({
          type: 'sendSoTitleCheckFailed',
          payload: {
            soDynamicProposal: {
              showModalLoader: false,
              showComplianceCheckModal: false,
              acknowledgeNotifiedToCustomer: false,
              acknowledgeIdentityOfCustomer: false
            }
          }
        });
      } else if (titleCheckNotAvailable(get(storageQuote, 'titleCheckDetails', null))) {
        dispatch({
          type: 'soComplianceCheckUpdate',
          payload: {
            storageQuote: {
              isVerifiedCompliance: get(data, 'value.isVerifiedCompliance', null)
            }
          }
        });
        dispatch(fetchSoTitleCheck(storageQuote, account, soDynamicProposal));
      } else if (skipTitleCheckModal(get(storageQuote, 'titleCheckDetails', null))) {
        dispatch({
          type: 'soComplianceCheckUpdate',
          payload: {
            storageQuote: {
              isVerifiedCompliance: get(data, 'value.isVerifiedCompliance', null)
            },
            soDynamicProposal: {
              showModalLoader: false
            }
          }
        });
        dispatch(updateSoProposal(storageQuote.sfid, 'send', soDynamicProposal.sentVia));
      } else {
        dispatch({
          type: 'soComplianceCheckUpdate',
          payload: {
            storageQuote: {
              isVerifiedCompliance: get(data, 'value.isVerifiedCompliance', null)
            },
            soDynamicProposal: {
              showComplianceCheckModal: false,
              showModalLoader: false,
              showTitleCheckModal: true
            }
          }
        });
      }
      return data;
    });
  };
}
