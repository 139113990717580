import Api from '../../utils/Api';
import { asyncPayload } from '../apiHelpers';

export const deletePricingFile = (afterDelete) => (dispatch) => {
  const requestPath = 'admin_portals/delete_pricing_file';
  const promise = Api.deleteForAdmin(requestPath)
  .then((response) => {
    afterDelete();
    return response.data;
  });

  dispatch({
    type: 'deletePricingFile',
    payload: asyncPayload({
      request: promise
    })
  });
};

