export function setAcknowledgeNotifiedToCustomer(value) {
  return {
    type: 'setAcknowledgeNotifiedToCustomer',
    payload: {
      dynamicProposal: {
        acknowledgeNotifiedToCustomer: value
      }
    }
  };
}
