import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { designType } from '../types';
import { designStructureRangeSelector } from '../selectors/design';
import {
  assignModuleArrayStructure as assignModuleArrayStructureRaw
} from '../actions';

function setBackgroundColor(index, arrayPairing, hoveredModuleArray) {
  const hasBeenPaired = arrayPairing.includes(hoveredModuleArray);
  const nextHighlightedRow = arrayPairing.length === index;
  const shouldHighlight = Number.isInteger(hoveredModuleArray) && nextHighlightedRow && !hasBeenPaired;

  return shouldHighlight ? 'blue' : null;
}

export function SolarTable({
    design, assignModuleArray, designStructureRange,
    hoveredModuleArray = null
  }) {
  const arrayPairing = design.moduleArrays.map((ma) => ma.roofPlaneNumber).filter((n) => Number.isInteger(n));

  return (
    <table className="table">
      <thead>
        <tr>
          <th>Array</th>
          <th>Panel Count</th>
          <th>Azimuth</th>
          <th>TSRF %</th>
          <th>Structure</th>
        </tr>
      </thead>
      <tbody>
        {design.moduleArrays.map((row, index) =>
          <tr
            key={index}
            style={{ background: setBackgroundColor(index, arrayPairing, hoveredModuleArray) }}
          >
            <td>{row.arrayNumber}</td>
            <td>{row.panelCount}</td>
            <td>
              <span
                className="image-icon-azimuth-arrow"
                style={{ transform: `rotate(${row.azimuth}deg)` }}
              />
              <span>{row.azimuth}</span>
            </td>
            <td>{row.annualTsrf}</td>
            <td>
              <select
                disabled={design.paired}
                className="solar-table__structure-select"
                value={row.structure || 1}
                onChange={(event) => assignModuleArray(index, parseInt(event.target.value, 10), design.moduleArrays)}
              >
                {designStructureRange.map((n) => (
                  <option key={n} value={n}>{n}</option>
                ))}
              </select>
            </td>
          </tr>
        )}
      </tbody>
    </table>
  );
}

export default connect(
  ({ design, hoveredModuleArray }) => ({
    design,
    hoveredModuleArray,
    designStructureRange: designStructureRangeSelector({ design })
  }),
  (dispatch) => ({
    assignModuleArray: (...args) => dispatch(assignModuleArrayStructureRaw(...args))
  })
)(SolarTable);

SolarTable.propTypes = {
  design: designType.isRequired,
  hoveredModuleArray: PropTypes.number,
  assignModuleArray: PropTypes.func.isRequired,
  designStructureRange: PropTypes.arrayOf(PropTypes.number).isRequired
};
