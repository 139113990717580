import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { register } from '../../../utils/redux';
import {
  quoteType,
  dropdownsType,
  accountType,
  businessFunctionsType,
  settingsType, pvModuleType
} from '../../../types';
import { tagList } from '../../../utils/pvModuleFiltersAndTags';
import { paymentOptionsForStorage } from '../../../utils/calculations';
import { getAvailableStorageConfigs } from '../../../utils/storageWithMultiInverter';
import adminFinConfigs from '../../../selectors/adminFinConfigs';

const defaultContainerClasses = 'input-container input-container--icon icon-right dropdown-with-tags';

export function StorageListForMultiInvertersDropdown({
                                                       pvModule,
                                                       selectedStorageName,
                                                       dropdowns: { isStorageListWithMultiInverterOpen },
                                                       onSelect,
                                                       toggleStorageListWithMultiInverterDropdown,
                                                       quote,
                                                       account,
                                                       settings
                                                     }) {
  const financeTags = tagList(paymentOptionsForStorage(account), settings);
  const storageConfigs = getAvailableStorageConfigs(pvModule, quote, settings, account);
  let storageNames = storageConfigs ? storageConfigs.map((configuration) =>
      configuration.storageName) : null;
  storageNames = storageNames ? storageNames.filter((c, index) => {
    return storageNames.indexOf(c) === index;
  }) : null;

  const options = (<ul>
    <li
      className={cx('cs-option-container',
        { 'cs-selected': selectedStorageName === 'None' })}
      onClick={() => {
        toggleStorageListWithMultiInverterDropdown();
        onSelect(null);
      }}
      key={`list${0}`}
    >
      <span className="cs-option">
        <div className="cs-option-name">None</div>
      </span>
    </li>
    {storageNames && storageNames.map((option) => {
      return (
        <li
          className={cx('cs-option-container',
            { 'cs-selected': option === selectedStorageName })}
          onClick={() => {
            toggleStorageListWithMultiInverterDropdown();
            onSelect(option);
          }}
          key={`list${option}`}
        >
          <span className="cs-option">
            <div className="cs-option-name">{option}</div>
            {financeTags}
          </span>
        </li>
      );
    })}
  </ul>);

  return pvModule.storageConfigurations.length === 0 ? (
    <div
      id="storage_dropdown"
      className={defaultContainerClasses}
    >
      <input type="text" value="None" disabled={true} />
    </div>
  ) : (
    <div
      id="storage_dropdown"
      className={cx(defaultContainerClasses, { 'is-disabled': quote.locked })}
    >
      <i className="icon icon-chevron-down" />
      <div
        className={cx('select-dropdown cs-skin-border', { 'cs-active': isStorageListWithMultiInverterOpen })}
        tabIndex="0"
      >
        <span className="cs-placeholder" onClick={toggleStorageListWithMultiInverterDropdown}>
          <div className="cs-option-name">{selectedStorageName || 'None'}</div>
          {financeTags}
        </span>
        <div className="cs-options">
          {options}
        </div>
      </div>
    </div>
  );
}

StorageListForMultiInvertersDropdown.propTypes = {
  pvModule: pvModuleType.isRequired,
  selectedStorageName: PropTypes.string,
  dropdowns: dropdownsType.isRequired,
  quote: quoteType.isRequired,
  account: accountType.isRequired,
  settings: settingsType.isRequired,
  onSelect: PropTypes.func.isRequired,
  toggleStorageListWithMultiInverterDropdown: PropTypes.func.isRequired
};

export default register(
  [
    'pvModuleSelector',
    'dropdownsSelector',
    'quoteSelector',
    'accountSelector',
    'settingsSelector'
  ],
  ['toggleStorageListWithMultiInverterDropdown'],
  StorageListForMultiInvertersDropdown
);

