import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import { datadogTrackLoadingTime } from '../utils/datadogUserMonitoring';
import { asyncPayload } from './apiHelpers';
import Api from '../utils/Api';
import { informUserOfChangesToSettings } from '../utils/informUserOfChangesToSettings';
import { CALCULATING_SAVINGS } from '../config';
import { fetchGraphs } from './fetchGraphs';

export const saveSettings = (quote, orgSettings, adders = {}, financeOptionToEdit = null) => (dispatch, getState) => {
  const startTime = Date.now();
  const nonSunpowerRacking = quote.rackingType !== 'Invisimount';
  const settings = { ...orgSettings, general: { non_sunpower_racking: nonSunpowerRacking } };
  const currentState = getState();
  const account = currentState.account;
  const channel = get(account, 'partner.channel');
  const previousSettings = currentState.settings;
  const previousRoofDesignId = currentState.quote.roofDesignId;
  const { activeTab } = currentState;
  const payload = { settings, quote };

  if (adders && !isEmpty(adders)) {
    payload.adders = adders;
  }

  if (financeOptionToEdit) {
    payload.financeOptionToEdit = financeOptionToEdit;
  }

  const lease = {
    pricePerWattWithMonitoringCost: 0,
    grossPriceWithMonitoringCost: 0,
    isChanging: false
  };

  if (settings.lease) {
    Object.assign(lease, {
      fetchingFinEngAdders: settings.lease.adders,
      fetchingFinEngDiscount: settings.lease.discount,
      fetchingFinEngOverrideRebate: settings.lease.overrideRebate
    });
  }

  dispatch({
    type: 'saveSettings',
    payload: asyncPayload({
      request: Api.saveSettingsWithPolling(
        `quotes/${quote.sfid}/settings`,
        payload,
        previousRoofDesignId,
        1000
      )
      .then((result) => {
        const messages = [
          ...(result.messages || []),
          ...(informUserOfChangesToSettings(previousSettings, result.settings).messages || [])
        ].filter((x) => !!x);

        if (financeOptionToEdit) {
          dispatch(fetchGraphs(quote.sfid));

          const loadingTime = Date.now() - startTime;
          datadogTrackLoadingTime(
            `Save ${financeOptionToEdit.toUpperCase()} Attributes`,
            loadingTime,
            quote,
            settings,
            channel
          );
        }

        return { ...result, messages };
      }),
      beforeRequest: { mode: CALCULATING_SAVINGS },
      afterRequest: {
        savings: {
          activeTab
        },
        design: {
          moduleSelectionModal: false
        },
        settings: {
          cash: {
            pricePerWattWithMonitoringCost: 0,
            grossPriceWithMonitoringCost: 0,
            isChanging: false
          },
          lease,
          loan: {
            pricePerWattWithMonitoringCost: 0,
            grossPriceWithMonitoringCost: 0,
            isChanging: false
          }
        }
      }
    })
  });
};
