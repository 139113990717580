import get from 'lodash/get';
import { asyncPayload } from './apiHelpers';
import Api from '../utils/Api';
import { updateProposal } from './updateProposal';

export const sendTitleCheck = (quote, accountId, dynamicProposal, actionType, updateType = '') => {
  const promise = Api.update(`title_check/${quote.sfid}/send_to_sfdc`, {
    accountId, actionType, updateType
  });

  return (dispatch) => {
    const response = dispatch({
      type: 'sendTitleCheck',
      payload: asyncPayload({
        request: promise,
        beforeRequest: {
          dynamicProposal: {
            showComplianceCheckModal: true,
            showTitleCheckModal: false,
            showModalLoader: true
          }
        }
      })
    });

    response.then((data) => {
      dispatch({
        type: 'sendTitleCheckCompleted',
        payload: {
          dynamicProposal: {
            showModalLoader: false,
            showTitleCheckModal: false,
            showComplianceCheckModal: false
          },
          quote: {
            titleCheckDetails: get(data, 'value.titleCheckDetails', null)
          }
        }
      });
      if (!get(data, 'value.error', null)) {
        if (actionType !== 'cancel') {
          dispatch(updateProposal(quote.sfid, 'send', dynamicProposal.sentVia));
        }
      }
      return data;
    });
  };
};
