import React, { useEffect, useState } from 'react';
import { register } from '../../utils/redux';
import InitialPage from './InitialPage';
import SystemCheck from './SystemCheck';
import { getValueFromLocalStore, saveValueToLocalStore } from '../../utils/storageQuote/localStore';
import { getPartnerPortalUrl } from '../../utils/url';
import { accountType, storageQuoteType } from '../../types';
import { COMPATIBLE_INVERTERS } from '../../utils/storageQuote/inverters';

export function StorageQuoteHomePage({ storageQuote, account }) {
  const [ showSystemCheck, setShowSystemCheck ] = useState(false);
  const cancelUrl = getPartnerPortalUrl(account.sfid);
  useEffect(() => {
    if (getValueFromLocalStore('showSystemCheck') === 'false') {
      setShowSystemCheck(false);
    } else if (COMPATIBLE_INVERTERS.indexOf(storageQuote.inverterType) > -1) {
      setShowSystemCheck(true);
    }
  }, [storageQuote]);

  function showSystemCheckPage(value) {
    setShowSystemCheck(value);
    saveValueToLocalStore('showSystemCheck', value);
  }

  return (
    <div className="container">
      <div className="row">
        <div
          className="col-md-6 col-md-offset-3 storage-quote-home-page"
        >
          {!showSystemCheck && <InitialPage showSystemCheckPage={showSystemCheckPage} cancelUrl={cancelUrl} />}
          {showSystemCheck && <SystemCheck showSystemCheckPage={showSystemCheckPage} cancelUrl={cancelUrl} />}
        </div>
      </div>
    </div>
  );
}

StorageQuoteHomePage.propTypes = {
  storageQuote: storageQuoteType.isRequired,
  account: accountType.isRequired
};

export default register(
  [ 'storageQuoteSelector', 'accountSelector' ],
  [],
  StorageQuoteHomePage
);
