import { cloneDeep } from 'lodash';
import { findApFinConfigIndexById } from '../../utils/admin_portal/updateHelpers';
import constants from '../../utils/EddieCalculations/constants';

/**
 *
 * @param {object} object
 * @returns updated state
 */
const {
  LOAN_FINANCE_TYPE
} = constants;

export const updateApDefaultLoanProduct = (configId, object) => (dispatch, getState) => {
  const currentState = getState();
  const apFinConfigsToUpdate = cloneDeep(currentState.apFinConfigs);
  const finConfigIndex = findApFinConfigIndexById(apFinConfigsToUpdate, configId);

  if (apFinConfigsToUpdate[finConfigIndex].defaultLoanProductAttributes) {
    apFinConfigsToUpdate[finConfigIndex].defaultLoanProductAttributes = {
      ...apFinConfigsToUpdate[finConfigIndex].defaultLoanProductAttributes,
      ...object
    };
  } else {
    apFinConfigsToUpdate[finConfigIndex].defaultLoanProductAttributes = object;
  }

  dispatch({
    type: 'updateApDefaultLoanProduct',
    payload: {
      apFinConfigs: apFinConfigsToUpdate
    }
  });
};
