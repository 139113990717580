import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { register } from '../../../utils/redux';
import { moduleSelectionModalType, selectedStorageType } from '../../../types';
import { isSunpowerStorage } from '../../../utils/sunPowerStorage';

const ModuleSection = ({
  moduleSelectionModal,
  updateModuleSelectionModal,
  title,
  section,
  prop,
  insetText,
  hiddenImage,
  selectedStorage
}) => {
  const [ activeItem, setActiveItem ] = useState();

  const [seriesCaptions] = useState({
    A: 'The first panel to produce up to 415 watts for residential use.',
    E: 'The first panel to produce up to 415 watts for residential use. ',
    X: 'These Panels deliver 44% more power per panel at a smaller size.',
    M: '435-420 watts module w/ Maxeon Gen 6 cell is over 5% larger than prior generations.',
    U: 'These are Non-Maxeon panels modules.',
    U370: 'These are Non-Maxeon panels modules.',
    U365: 'These are Non-Maxeon panels modules.',
    U405: 'These are Non-Maxeon panels modules.'
  });

  const accessories = {
    A: [ 'Front Edge Skirt', 'Critter Guard' ],
    E: [ 'Front Edge Skirt', 'Critter Guard' ],
    X: [ 'Front Edge Skirt', 'Critter Guard' ],
    M: [ 'Front Edge Skirt', 'Critter Guard' ],
    U: ['Critter Guard'],
    U370: ['Critter Guard'],
    U365: ['Critter Guard'],
    U405: ['Critter Guard']
  };

  const isActive = useCallback((section, item) => {
    switch (section) {
      case 'inverter':
        return activeItem && item.name === activeItem.name;

      case 'accessories':
        switch (item) {
          case 'Front Edge Skirt':
            return moduleSelectionModal.isSkirtGuard;

          case 'Critter Guard':
            return moduleSelectionModal.isCritterGuard;

          default:
            return false;
        }

      default:
        return item === activeItem;
    }
  }, [ activeItem, moduleSelectionModal ]);

  const getOptions = useCallback((section) => {
    switch (section) {
      case 'inverter':
        return moduleSelectionModal.inverters;

      case 'monitoring':
        return [moduleSelectionModal.selectedInverter.monitoring.name];

      case 'accessories':
        return accessories[moduleSelectionModal.selectedSeries];

      default:
        return moduleSelectionModal[section];
    }
  }, [moduleSelectionModal]);

  const getText = useCallback((section, item) => {
    const series = moduleSelectionModal.selectedSeries;
    switch (section) {
      case 'series':
        return seriesCaptions[item];

      case 'rackings':
        return item === 'Invisimount' ? 'InvisiMount' : item;

      case 'inverter':
        switch (item.monitoring.name) {
          case 'PVS5 Production Monitoring':
            return 'SunPower Type D Microinverter';

          case 'PVS6 Monitoring Kit':
            if (series === 'A') {
              return 'SunPower Type G Microinverter';
            } else if (item.name.search('Type-D') >= 0) {
              return 'SunPower Type D Microinverter';
            } else if (series === 'M' || series === 'U' ||
              series === 'U365' || series === 'U405' || series === 'U370') {
              return 'SunPower Type H Microinverter';
            }

            return 'SunPower Type E Microinverter';

          default:
            return item.name;
        }

      case 'monitoring':
        if (
          selectedStorage
          && isSunpowerStorage(selectedStorage.name)
        ) {
          return 'Hub+ Production Monitoring';
        }

        return item;

      default:
        return item;
    }
  }, [ seriesCaptions, moduleSelectionModal, selectedStorage ]);

  const getImageName = useCallback((section, value) => {
    switch (section) {
      case 'models':
        value = moduleSelectionModal.selectedColor;
        return value ? value.replace(/ /g, '-') : '';

      case 'inverter':
      case 'monitoring':
        return getText(section, value).replace(/[ +]/g, '-');

      default:
        return value ? value.replace(/ /g, '-') : '';
    }
  }, [ moduleSelectionModal, getText ]);

  const handleOptionClick = useCallback((section, item) => {
    switch (section) {
      case 'inverter':
        updateModuleSelectionModal('selectedMonitoring', item.monitoring.name);
        updateModuleSelectionModal(prop, item);
        break;

      case 'accessories':
        switch (item) {
          case 'Front Edge Skirt':
            updateModuleSelectionModal('isSkirtGuard',
            !moduleSelectionModal.isSkirtGuard);
            break;

          case 'Critter Guard':
            updateModuleSelectionModal('isCritterGuard',
            !moduleSelectionModal.isCritterGuard);
            break;

          default:
            break;
        }
        break;

      default:
        updateModuleSelectionModal(prop, item);
        break;
    }
  }, [ updateModuleSelectionModal, prop, moduleSelectionModal ]);

  useEffect(() => {
    setActiveItem(moduleSelectionModal[prop]);
  }, [ moduleSelectionModal, prop, setActiveItem ]);

  return (
    <div className={`module-section ${section}`}>
      {title && title.length > 0 ? (
        <div className={`module-section-title ${section}`}>
          {title}
        </div>
      ) : null}

      <div className={`module-options-list ${section}`}>
        {getOptions(section).map((item) =>
          <div
            key={item}
            className={cx(`module-option ${section}`, {
              active: isActive(section, item)
            })}
            onClick={() => handleOptionClick(section, item)}
          >
            {!hiddenImage ? (
              <div
                className={cx(
                  `module-option-image ${section}`,
                  getImageName(section, item),
                  { 'inset-text': insetText }
                )}
              >
                {getText && insetText ? (
                  <div className={`text ${section} inset`}>
                    {getText(section, item)}
                  </div>
                ) : null}
              </div>
            ) : null}

            {getText && (hiddenImage || !insetText) ? (
              <div className={`text ${section}`}>
                {getText(section, item)}
              </div>
            ) : null}
          </div>
        )}
      </div>

      { section === 'monitoring' && moduleSelectionModal.cellularMonitoringExists ? (
        <div className="checkbox cellular-option">
          <label className="cellular-label">
            <input
              className="cellular-checkbox"
              type="checkbox"
              checked={moduleSelectionModal.selectedCellularMonitoring}
              onChange={(e) => updateModuleSelectionModal('selectedCellularMonitoring', e.target.checked)}
            />
            <span className="cellular-span">Include Cellular Monitoring</span>
          </label>
        </div>
      ) : null}
    </div>
  );
};

ModuleSection.propTypes = {
  moduleSelectionModal: moduleSelectionModalType.isRequired,
  updateModuleSelectionModal: PropTypes.func.isRequired,
  title: PropTypes.string,
  section: PropTypes.string,
  prop: PropTypes.string,
  insetText: PropTypes.bool,
  hiddenImage: PropTypes.bool,
  selectedStorage: selectedStorageType
};

export default register(
  [
    'moduleSelectionModalSelector', 'selectedStorageSelector'
  ],
  [
    'updateModuleSelectionModal'
  ],
  ModuleSection
);
