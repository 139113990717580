import get from 'lodash/get';
import { asyncPayload } from './apiHelpers';
import Api from '../utils/Api';
import { fetchTitleCheck } from './fetchTitleCheck';
import { updateProposal } from './updateProposal';
import { skipTitleCheckModal, titleCheckNotAvailable } from '../utils/titleCheck';

export function sendComplianceCheck(quote, account, dynamicProposal) {
  const requestPath = `quotes/${quote.sfid}/send_compliance_check`;
  const accountId = account.sfid;
  const promise = Api.update(requestPath, { accountId });

  return (dispatch) => {
    const response = dispatch({
      type: 'sendComplianceCheck',
      payload: asyncPayload({
        beforeRequest: {
          dynamicProposal: {
            showModalLoader: true
          }
        },
        request: promise
      })
    });

    response.then((data) => {
      if (get(data, 'value.error', null)) {
        dispatch({
          type: 'sendTitleCheckFailed',
          payload: {
            dynamicProposal: {
              showModalLoader: false,
              showComplianceCheckModal: false,
              acknowledgeNotifiedToCustomer: false,
              acknowledgeIdentityOfCustomer: false
            }
          }
        });
      } else if (quote.creditBureau === 'SPF') {
        dispatch({
          type: 'complianceCheckUpdate',
          payload: {
            quote: {
              isVerifiedCompliance: get(data, 'value.isVerifiedCompliance', null)
            },
            dynamicProposal: {
              showModalLoader: false
            }
          }
        });
        dispatch(updateProposal(quote.sfid, 'send', dynamicProposal.sentVia));
      } else if (titleCheckNotAvailable(get(quote, 'titleCheckDetails', null))) {
        dispatch({
          type: 'complianceCheckUpdate',
          payload: {
            quote: {
              isVerifiedCompliance: get(data, 'value.isVerifiedCompliance', null)
            }
          }
        });
        dispatch(fetchTitleCheck(quote, account, dynamicProposal));
      } else if (skipTitleCheckModal(get(quote, 'titleCheckDetails', null))) {
        dispatch({
          type: 'complianceCheckUpdate',
          payload: {
            quote: {
              isVerifiedCompliance: get(data, 'value.isVerifiedCompliance', null)
            },
            dynamicProposal: {
              showModalLoader: false
            }
          }
        });
        dispatch(updateProposal(quote.sfid, 'send', dynamicProposal.sentVia));
      } else {
        dispatch({
          type: 'complianceCheckUpdate',
          payload: {
            quote: {
              isVerifiedCompliance: get(data, 'value.isVerifiedCompliance', null)
            },
            dynamicProposal: {
              showComplianceCheckModal: false,
              showModalLoader: false,
              showTitleCheckModal: true
            }
          }
        });
      }
      return data;
    });
  };
}
