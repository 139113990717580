import get from 'lodash/get';
import constants from './EddieCalculations/constants';

const { CRITTER_GUARD, SKIRT_GUARD, SUNVAULT } = constants;

const selectedPaymentOptions = (settings) => [ 'cash', 'lease', 'loan', 'spwrCash', 'ppa' ]
  .filter((option) => get(settings, `${option}.showCustomer`, false));

const ACCESSORIES = [ '0', '1', '2', CRITTER_GUARD, SKIRT_GUARD, SUNVAULT ];

const getAdminAvailableStorages = (quote, settings, selectedStorageConfiguration) => {
  if (quote && quote.adminFinConfigs) {
    const storagesAvailable = [];
    let adminStoragesAvailable = false;
    quote.adminFinConfigs.forEach((config) => {
      if (config.productConfigsAttributes && config.productConfigsAttributes.length &&
          selectedPaymentOptions(settings).includes(config.finType)) {
        const productConfig = config.productConfigsAttributes.filter((config) => !ACCESSORIES.includes(config.product));
        if (productConfig.length) {
          adminStoragesAvailable = true;
          productConfig.forEach((config) => {
            if (!config.sell) {
              storagesAvailable.push(config.product);
            }
          });
        }
      }
    });
    if (adminStoragesAvailable) {
      return selectedStorageConfiguration.filter((config) => !storagesAvailable.includes(config.storageConfigId));
    }
  }
  return selectedStorageConfiguration;
};

export const filterEOLStorages = (quote, storageConfigs) => {
  if (quote.cloneVariant === 'amendment') {
    const storageConfigsWithBaseQuoteConfig = storageConfigs.filter(
      (config) => config.storageConfigId === quote.baseQuoteStorageConfigId || config.active
    );
    return storageConfigsWithBaseQuoteConfig;
  }
  return storageConfigs;
};

export const getAvailableStorageConfigs = (pvModule, quote, settings, account) => {
  if (pvModule && pvModule.storageConfigurations) {
    let storageConfigs = pvModule.storageConfigurations;
    // storageConfigs = filterEOLStorages(quote, storageConfigs);0
    if (account && account.features && !account.features.teslaCertified) {
      storageConfigs = storageConfigs.filter((storage) => storage.batteryManufacturer.toLowerCase() !== 'tesla');
    }
    storageConfigs = getAdminAvailableStorages(quote, settings, storageConfigs);
    return storageConfigs;
  }
  return pvModule.storageConfigurations;
};

export const getAvailableStorageQuantities = (pvModule, selectedStorageName, quote, settings) => {
  let selectedStorageConfiguration = pvModule.storageConfigurations.filter((configuration) =>
    selectedStorageName === configuration.storageName);
  selectedStorageConfiguration = getAdminAvailableStorages(quote, settings, selectedStorageConfiguration);
  let rangeValue = selectedStorageConfiguration.map((storageConfiguration) => {
    return storageConfiguration.storageQuantity;
  });
  rangeValue = rangeValue.filter((c, index) => {
    return rangeValue.indexOf(c) === index;
  }).sort();
  return rangeValue;
};

export const getAvailableStorageExpansionPacks =
  (pvModule, selectedStorageName, selectedStorageQuantity, quote, settings) => {
    let selectedStorageConfiguration = pvModule.storageConfigurations.filter((configuration) =>
      configuration.storageName === selectedStorageName && configuration.storageQuantity === selectedStorageQuantity);
    selectedStorageConfiguration = getAdminAvailableStorages(quote, settings, selectedStorageConfiguration);
    let rangeValue = selectedStorageConfiguration.map((storageConfiguration) => {
      return storageConfiguration.expansionPacks;
    });
    rangeValue = rangeValue.filter((c, index) => {
      return rangeValue.indexOf(c) === index;
    }).sort();
    return rangeValue;
  };
