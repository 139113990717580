import PropTypes from 'prop-types';

export const storageQuoteType = PropTypes.shape({
  sfid: PropTypes.string,
  name: PropTypes.string,
  systemSize: PropTypes.number,
  locked: PropTypes.bool,
  dirty: PropTypes.bool,
  dealerCommission: PropTypes.number,
  evDealerCommission: PropTypes.number,
  processing: PropTypes.bool,
  updatedAt: PropTypes.string,
  storageQuantity: PropTypes.number,
  systemCost: PropTypes.number,
  inverterType: PropTypes.string
});
