const ORIENTATION_EPSILON = 0.5;
const DISTANCE_THRESHOLD = 0.05;

const getDistance = (pointA, pointB) => {
  return (pointA[0] - pointB[0]) ** 2 + (pointA[1] - pointB[1]) ** 2;
};

export const moduleOrientationIsPortrait = (module, roofs) => {
  const roof = roofs.find((r) => r.id === module.roof_id);
  const edge = (getDistance(module.shape.points[0], module.shape.points[1]) >
    getDistance(module.shape.points[1], module.shape.points[2])) ? [ module.shape.points[0], module.shape.points[1] ] :
    [ module.shape.points[1], module.shape.points[2] ];
  let theta = Math.atan2(edge[0][1] - edge[1][1], edge[0][0] - edge[1][0]); // range (-PI, PI]
  if (theta < 0) {
    theta += Math.PI;
  }
  theta *= 180 / Math.PI;
  theta += 90;
  return (Math.abs(roof.azimuth - theta) < ORIENTATION_EPSILON ||
    Math.abs(roof.azimuth - (theta + 180)) < ORIENTATION_EPSILON);
};

const getVectorDelta = (pointA, pointB) => {
  return [ pointA[0] - pointB[0], pointA[1] - pointB[1] ];
};

export const moduleRowNeighbours = (module, modules) => {
  const rowNeighbours = [];
  const firstEdgeIsLonger = (getDistance(module.shape.points[0], module.shape.points[1]) >
    getDistance(module.shape.points[1], module.shape.points[2]));
  let leftRightVector;
  if (module.orientation) {
    if (firstEdgeIsLonger) {
      leftRightVector = getVectorDelta(module.shape.points[1], module.shape.points[2]);
    } else {
      leftRightVector = getVectorDelta(module.shape.points[0], module.shape.points[1]);
    }
  } else if (firstEdgeIsLonger) {
    leftRightVector = getVectorDelta(module.shape.points[0], module.shape.points[1]);
  } else {
    leftRightVector = getVectorDelta(module.shape.points[1], module.shape.points[2]);
  }
  const leftRightPoints = [ [ module.shape.centroid[0] - leftRightVector[0],
    module.shape.centroid[1] - leftRightVector[1] ],
    [ module.shape.centroid[0] + leftRightVector[0], module.shape.centroid[1] + leftRightVector[1] ] ];
  modules.forEach((otherModule) => {
    if (otherModule.id !== module.id &&
      otherModule.roof_id === module.roof_id &&
      otherModule.orientation === module.orientation &&
      (Math.abs(otherModule.shape.centroid[0] - leftRightPoints[0][0]) +
        Math.abs(otherModule.shape.centroid[1] - leftRightPoints[0][1]) < DISTANCE_THRESHOLD ||
        Math.abs(otherModule.shape.centroid[0] - leftRightPoints[1][0]) +
        Math.abs(otherModule.shape.centroid[1] - leftRightPoints[1][1]) < DISTANCE_THRESHOLD)) {
      rowNeighbours.push(otherModule.id);
    }
  });
  return rowNeighbours;
};

const getModuleCentroid = (points: number[][]) => {
  let x = 0;
  let y = 0;
  for (let n = 0; n < 4; n += 1) {
    x += points[n][0];
    y += points[n][1];
  }
  return [ x / 4, y / 4 ];
};

const crossProduct = (vectorA, vectorB) => {
  return [ vectorA[1] * vectorB[2] - vectorA[2] * vectorB[1],
    vectorA[2] * vectorB[0] - vectorA[0] * vectorB[2],
    vectorA[0] * vectorB[1] - vectorA[1] * vectorB[0] ];
};

const addVector = (a, b) => {
  return a.map((e, i) => e + b[i]);
};

const elementwiseArrayDiv = (a, d) => {
  return a.map((e) => e / d);
};

const getEdge = (module, sideEdge) => {
  const firstEdgeIsLonger = (getDistance(module.shape.points[0], module.shape.points[1]) >
    getDistance(module.shape.points[1], module.shape.points[2]));
  return firstEdgeIsLonger === (module.orientation === sideEdge) ?
    getVectorDelta(module.shape.points[0], module.shape.points[1]) :
    getVectorDelta(module.shape.points[1], module.shape.points[2]);
};

const moduleTopDownNeighbours = (module, modules, roofs) => {
  const neighbours = [];
  const roof = roofs.find((r) => r.id === module.roof_id);
  const sinTheta = Math.sin(roof.azimuth / 180 * Math.PI);
  const cosTheta = Math.cos(roof.azimuth / 180 * Math.PI);

  const sinGamma = Math.sin(roof.azimuth / 180 * Math.PI + Math.PI / 2);
  const cosGamma = Math.cos(roof.azimuth / 180 * Math.PI + Math.PI / 2);

  const c = -cosTheta * module.shape.centroid[0] - sinTheta * module.shape.centroid[1];

  modules.forEach((otherModule) => {
    if (otherModule.id !== module.id && otherModule.roof_id === module.roof_id) {
      if (getDistance(module.shape.centroid, otherModule.shape.centroid) < 5 ** 2) {
        const d = -cosGamma * otherModule.shape.centroid[0] - sinGamma * otherModule.shape.centroid[1];
        const crossProd = crossProduct([ cosTheta, sinTheta, c ], [ cosGamma, sinGamma, d ]);
        const crossPoint = [ crossProd[0] / crossProd[2], crossProd[1] / crossProd[2] ];
        const verticalDistance = elementwiseArrayDiv(addVector(getEdge(module, true),
          getEdge(otherModule, true)), 2);
        const horizontalDistanceAllowed = elementwiseArrayDiv(addVector(getEdge(module, false),
          getEdge(otherModule, false)), 2);
        if (Math.abs(Math.sqrt(getDistance(module.shape.centroid, crossPoint)) -
          Math.sqrt(verticalDistance[0] ** 2 + verticalDistance[1] ** 2)) < DISTANCE_THRESHOLD) {
          if (getDistance(otherModule.shape.centroid, crossPoint) <
            horizontalDistanceAllowed[0] ** 2 + horizontalDistanceAllowed[1] ** 2) {
            neighbours.push(otherModule.id);
          }
        }
      }
    }
  });

  return neighbours;
};

export const establishModulesTopology = (modules, roofs) => {
  const rowNeighbours = {};
  const topdownNeighbours = {};
  modules.forEach((module) => {
    module.orientation = moduleOrientationIsPortrait(module, roofs);
    module.shape.centroid = getModuleCentroid(module.shape.points);
  });
  modules.forEach((module) => {
    rowNeighbours[module.id] = moduleRowNeighbours(module, modules);
  });
  modules.forEach((module) => {
    topdownNeighbours[module.id] = moduleTopDownNeighbours(module, modules, roofs);
  });
  return {
    rowNeighbours,
    topdownNeighbours
  };
};
