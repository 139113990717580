import React from 'react';
import get from 'lodash/get';
import ToolbarItem from './ToolbarItem';
import PDFJSHelper from '../../utils/PDFJS';
import {
  proposalType,
  adobeSignType
} from '../../types';
import { register } from '../../utils/redux';
import browser from '../../utils/browser';
import ProposalToolbarEmailView from './ProposalToolbarEmailView';
import SignatureIcon from '../../../images/icon-signature.svg';
import PrintIcon from '../../../images/icon-print.svg';
import MailIcon from '../../../images/icon-mail.svg';
import DownloadIcon from '../../../images/icon-download.svg';
import { adobeSignEnabled } from '../../utils/backendFeatureFlags';

const makeToolbarItems = ({ proposal, adobeSign }) => {
  let downloadLinkProps = {};
  let href = proposal.pdfUrl;

  if (browser.isIos()) {
    downloadLinkProps = { target: '_blank', rel: 'noopener noreferrer' };
    href = proposal.pdfDataUrl;
  }

  let toolbarItems = [{
    id: 'download',
    label: 'Download',
    iconProps: {
      download: proposal.pdfFilename,
      href,
      ...downloadLinkProps
    },
    icon: <DownloadIcon />,
    eventName: 'quotePage:proposalTab:downloadButton'
  }];

  const printItem = {
    id: 'print',
    label: 'Print',
    onClickAction: (e) => {
      e.preventDefault();
      PDFJSHelper.printBlob(proposal.pdfUrl);
    },
    icon: <PrintIcon />,
    eventName: 'quotePage:proposalTab:printButton'
  };

  if (adobeSignEnabled() && adobeSign.widgetExists) {
    const emailItem = {
      id: 'email',
      label: 'Email',
      icon: <MailIcon />,
      canActivate: true,
      content: <ProposalToolbarEmailView />
    };
    const signatureItem = {
      id: 'signature',
      label: 'Signature',
      icon: <SignatureIcon />,
      canActivate: true,
      eventName: 'quotePage:proposalTab:signProposal'
    };
    toolbarItems = toolbarItems.concat([
      emailItem, printItem, signatureItem
    ]);
  } else {
    toolbarItems.push(printItem);
  }

  return toolbarItems;
};

export function ProposalToolbar({
  proposal,
  adobeSign
}) {
  const { activeItemId } = proposal.toolbar;

  const toolbarItems = makeToolbarItems({ proposal, adobeSign });

  const activeItem = toolbarItems.find(({ id }) => id === activeItemId);
  const expandedContent = get(activeItem, 'content', null);

  return (
    <nav className={`pdf-toolbar${expandedContent ? ' pdf-toolbar--expanded' : ''}`}>
      <div className="pdf-toolbar__buttons">
        <div className="nav-action-wrapper">
          {toolbarItems.map((item) => <ToolbarItem key={item.id} item={item} activeItemId={activeItemId} />)}
        </div>
      </div>
      {
        expandedContent ?
          <div className="pdf-toolbar__panel">
            { expandedContent }
          </div>
        : null
      }
    </nav>
  );
}

ProposalToolbar.propTypes = {
  proposal: proposalType.isRequired,
  adobeSign: adobeSignType.isRequired
};

export default register(
  [
    'proposalSelector',
    'adobeSignSelector'
  ],
  [
    'toggleProposalToolbarActiveItem'
  ],
  ProposalToolbar
);
