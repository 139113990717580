import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { register } from '../utils/redux';

function ExpandButton({ rightPanelExpanded, setRightPanelMenuStatus }) {
  const handleClick = useCallback(() => {
    setRightPanelMenuStatus(!rightPanelExpanded);
  }, [ rightPanelExpanded, setRightPanelMenuStatus ]);

  return (
    <div id="right-panel-expand-button">
      <button onClick={handleClick} className={rightPanelExpanded ? 'expanded' : 'closed'} />
    </div>
  );
}

ExpandButton.propTypes = {
  rightPanelExpanded: PropTypes.bool,
  setRightPanelMenuStatus: PropTypes.func
};

export default register(
  ['rightPanelExpandedSelector'],
  ['setRightPanelMenuStatus'],
  ExpandButton
);
