import React, { useEffect, useState } from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import { register } from '../../utils/redux';
import constants from '../../utils/EddieCalculations/constants';
import { totalQuantity } from '../../utils/storage';
import { awsImagePath } from '../../utils/SourceHelper';
import { getAvailableStorageConfigs } from '../../utils/storageWithMultiInverter';
import { currentStorageConfigurationType, pvModuleType, quoteType, settingsType,
  businessFunctionsType, accountType } from '../../types';

function StorageWith3pSetting({
    quote,
    toggleAddStorageModalFlag,
    updateStorage,
    pvModule,
    businessFunctions,
    account,
    settings
  }) {
  function uniqueArray(value, index, array) {
    return array.indexOf(value) === index;
  }

  const { ENPHASE, SUNPOWER, TESLA } = constants;
  const enphaseQuantites = [ 5, 10, 15, 20 ];
  let availableStorageConfigs = getAvailableStorageConfigs(pvModule, quote, settings, account);
  availableStorageConfigs = availableStorageConfigs || pvModule.storageConfigurations;
  const storageConfigs = availableStorageConfigs.filter(
    (bat) => bat.batterySize != null && bat.batteryManufacturer != null);

  const batteryManufacturers = storageConfigs.map(
    (config) => config.batteryManufacturer).filter(uniqueArray);

  const [ selectedBatteryManufacturer, setSelectedBatteryManufacturer
  ] = useState(quote.batteryManufacturer || storageConfigs[0].batteryManufacturer);

  const manufaturereConfigs = storageConfigs.filter((bat) =>
  bat.batteryManufacturer === selectedBatteryManufacturer).sort((a, b) =>
  a.storageQuantity - b.storageQuantity);

  const [ sizesTab, setSizesTab ] = useState(manufaturereConfigs.map((bat) =>
  bat.batterySize).filter(uniqueArray).sort((a, b) => a - b));

  const [ selectedBatterySize, setSelectedBatterySize
  ] = useState(quote.batterySize || sizesTab[0]);

  const [ batteryIndex, setBatteryIndex ] = useState(0);
  const [ batConfigsForSize, setBatConfigsForSize ] = useState([]);
  const [ batConfigs, setBatConfigs ] = useState([]);
  const [ disableAdd, setDisableAdd ] = useState(false);
  const [ disableRemove, setDisableRemove ] = useState(true);

  const [ selectedBattery, setSelectedBattery ] = useState(storageConfigs.find((bat) =>
  bat.storageConfigId === quote.storageConfigId) ||
  manufaturereConfigs.find((bat) => bat.batterySize === selectedBatterySize));

  const getConfigsForBatteryType = (
    selectedBatteryManufacturer,
    selectedBatterySize = null
  ) => {
    const tabs = storageConfigs.filter((bat) =>
      bat.batteryManufacturer === selectedBatteryManufacturer).map((bat) =>
      bat.batterySize).filter(uniqueArray).sort((a, b) => a - b);
    setSizesTab(tabs);

    const tabValue = selectedBatterySize || tabs[0];
    setSelectedBatterySize(tabValue);

    const filterConfigsArr = storageConfigs.filter((bat) =>
      bat.batterySize === tabValue &&
      bat.batteryManufacturer === selectedBatteryManufacturer).sort((a, b) =>
      a.storageQuantity - b.storageQuantity);
    const filterConfigs = [...new Map(filterConfigsArr.map((item) =>
        [ item.storageQuantity, item ])).values()];
    setBatConfigs(filterConfigsArr);
    setBatConfigsForSize(filterConfigs);
    const configIndex = filterConfigs.map((bat) => bat.storageConfigId).indexOf(selectedBattery.storageConfigId);
    setSelectedBattery(configIndex >= 0 ? selectedBattery : filterConfigs[0]);
    setBatteryIndex(configIndex > 0 ? configIndex : 0);
    setDisableAdd(!(configIndex < filterConfigs.length - 1));
    setDisableRemove(!(configIndex > 0));
  };

  const backupAvailable = (selectedBattery) => {
    const battery = batConfigs.filter((storage) =>
      storage.storageQuantity === selectedBattery.storageQuantity &&
      storage.backupCapable !== selectedBattery.backupCapable);
    return battery;
  };

  useEffect(() => {
    getConfigsForBatteryType(selectedBatteryManufacturer);
  }, [selectedBatteryManufacturer]);

  useEffect(() => {
    getConfigsForBatteryType(selectedBatteryManufacturer, selectedBatterySize);
  }, [selectedBatterySize]);

  const onCancel = (event) => {
    event.preventDefault();
    toggleAddStorageModalFlag();
  };

  const changeSelectedBatteryManufacturer = (value) => {
    setSelectedBatteryManufacturer(value);
  };

  const changeSelectedBatterySize = (value) => {
    setSelectedBatterySize(value);
  };

  const onSave = (event) => {
    event.preventDefault();
    if (quote.locked) return;
    const storageConfigId = selectedBattery.storageConfigId;
    const storageLaborCost = selectedBattery.storageLaborCost;
    const storageQuantity = selectedBattery.storageQuantity;
    const storageExpansionPackQuantity = selectedBattery.expansionPacks;
    const batterySize = selectedBattery.batterySize;
    const batteryManufacturer = selectedBattery.batteryManufacturer;
    const backupCapable = selectedBattery.backupCapable;
    const batteryWarranty = selectedBattery.batteryWarranty;
    const storageName = selectedBattery.storageName;

    const params = {
      storageConfigId,
      storageLaborCost,
      storageQuantity,
      storageExpansionPackQuantity,
      batterySize,
      batteryManufacturer,
      backupCapable,
      batteryWarranty
    };
    updateStorage(quote, params);
    toggleAddStorageModalFlag();
  };

  const addQuantity = () => {
    if (batteryIndex <= batConfigsForSize.length) {
      const i = batteryIndex + 1;
      if (i < batConfigsForSize.length) {
        setDisableRemove(false);
        setBatteryIndex(i);
        setSelectedBattery(batConfigsForSize[i]);
      }
      if (i >= batConfigsForSize.length - 1) {
        setDisableAdd(true);
      } else {
        setDisableAdd(false);
      }
    }
  };

  const removeQuantity = () => {
    if (batteryIndex > 0) {
      const j = batteryIndex - 1;
      if (j >= 0) {
        setDisableAdd(false);
        setBatteryIndex(j);
        setSelectedBattery(batConfigsForSize[j]);
      }
      if (j === 0) {
        setDisableRemove(true);
      }
    }
  };

  const backupCheckbox = (selectedBattery) => {
    return (
      <div>
        { backupAvailable(selectedBattery).length > 0 ?
          <div className="backup checkbox">
            <div className="toggle-btn-container">
              <label className="toggle-btn">
                <input
                  type="checkbox"
                  checked={!!selectedBattery.backupCapable}
                  onChange={(e) => setSelectedBattery(backupAvailable(selectedBattery)[0])}
                />
                <span className="slider round" />
              </label>
              <div className="include-backup">Include Backup</div>
            </div>
          </div> :
          <div className="backup">
            <label className="unavailable">Backup Not Available With This Selection</label>
          </div>
        }
      </div>
    );
  };

  const renderBatteryImg = (selectedBattery) => {
    let imagePath = '/build/images/';
    let imageSrc = 'storage_with_multi_inverters_battery_1.png';
    let imageClass = 'sunvault-battery';
    if (selectedBattery.batteryManufacturer &&
      selectedBattery.batteryManufacturer.toLowerCase() === TESLA) {
      imageClass = 'battery-3p';
      imagePath = '';
      imageSrc = awsImagePath('tesla-storage.png');
    } else if (selectedBattery.batteryManufacturer &&
    selectedBattery.batteryManufacturer.toLowerCase() === ENPHASE) {
      const quantity = totalQuantity(selectedBattery);
      if (enphaseQuantites.includes(quantity)) {
        imageSrc = `modal-${quantity}`;
        imageSrc += selectedBattery.backupCapable ? '-backup.jpg' : '-nonbackup.jpg';
      } else {
        imageSrc = 'modal-enphase';
        imageClass = 'enphase-default';
        imageSrc += selectedBattery.backupCapable ? '-backup.svg' : '-nonbackup.svg';
      }
    }

    return (
      <div>
        <div className="battery-config">
          <img
            src={imagePath + imageSrc}
            alt="battery config"
            className={imageClass}
          />
        </div>
      </div>
    );
  };

  return (
    <div className="modal-data-outer-container">
      <div className="modal-data-container">
        <div className="system-breakdown-container">
          <div className="close" onClick={() => toggleAddStorageModalFlag()} />
          <div className="manf-tabs">
            { batteryManufacturers.map((battery) => {
              return (
                <div
                  className={cx('system-breakdown-heading', { selected: selectedBatteryManufacturer === battery })}
                  onClick={() => changeSelectedBatteryManufacturer(battery)}
                >
                  {battery}
                </div>
              );
            })}
          </div>
          <div className="batter-size">
            Battery Size
            <div className="tab-selection">
              { sizesTab.map((size) => {
                return (
                  <span
                    className={cx('', { active: selectedBatterySize === size, singleTab: sizesTab.length === 1 })}
                    onClick={() => changeSelectedBatterySize(size)}
                  > {size} kWh
                  </span>
                );
              })}
            </div>
          </div>
          {renderBatteryImg(selectedBattery)}
          {selectedBattery.batteryManufacturer.toLowerCase() === SUNPOWER && selectedBattery.expansionPacks > 0 && (
          <div className="expansion-pack">
            <label><span>{selectedBattery.expansionPacks}</span> Expansion Pack</label>
          </div>
          )}
          {selectedBattery.batteryManufacturer.toLowerCase() === ENPHASE && (backupCheckbox(selectedBattery))}
          <div className="battery-details">
            <div className="detail-box">
              <label>Total Size</label>
              <span>{totalQuantity(selectedBattery)} kWh</span>
            </div>
            <div className="detail-box">
              <label>Power Rating</label>
              <span>{selectedBattery.powerRating} kW</span>
            </div>
          </div>
          <div className="select-quantity">
            <div
              className={cx('buttons remove-button', { disabled: disableRemove })} onClick={() => removeQuantity()}
            >
              <img
                alt="remove unit"
                src={disableRemove ? '/build/images/RemoveUnitDisabled.svg' : '/build/images/RemoveUnit.svg'}
              />
            </div>
            <span className="quantity">{selectedBattery.storageQuantity} Quantity</span>
            <div
              className={cx('buttons add-button', { disabled: disableAdd })}
              onClick={() => addQuantity()}
            >
              <img
                alt="add unit"
                src={disableAdd ? '/build/images/AddUnitDisabled.svg' : '/build/images/AddUnit.svg'}
              />
            </div>
          </div>
          <div className="action-container">
            <button
              id="storage-confirm-btn"
              className={cx('confirm-btn', { disabled: quote.locked })}
              onClick={onSave}
            >
                Add
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

StorageWith3pSetting.propTypes = {
  quote: quoteType.isRequired,
  toggleAddStorageModalFlag: PropTypes.func.isRequired,
  updateStorage: PropTypes.func.isRequired,
  pvModule: pvModuleType.isRequired,
  instantQuoteMode: PropTypes.bool,
  constantValues: PropTypes.arrayOf(PropTypes.number),
  currentStorageConfiguration: currentStorageConfigurationType.isRequired,
  settings: settingsType.isRequired,
  businessFunctions: businessFunctionsType,
  account: accountType.isRequired,
  updateCurrentStorageConfiguration: PropTypes.func.isRequired
};

export default register(
  [
    'instantQuoteModeSelector', 'pvModulesSelector', 'settingsSelector', 'accountSelector'
  ],
  [],
  StorageWith3pSetting
);
