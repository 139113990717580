import get from 'lodash/get';
import accountSelector from './account';
import settingsSelector from './settings';
import quoteSelector from './quote';

export const showDealerFeePerWatt = (state) => {
  const { account } = accountSelector(state);
  const { settings: { activeTab } } = settingsSelector(state);
  const { settings } = settingsSelector(state);
  const { quote } = quoteSelector(state);

  if ((activeTab || '').toLowerCase() === 'loan' && get(quote, 'manualMode', false)) {
    if (get(quote, 'dealerHic', null) &&
      ((get(quote, 'locked', false) && !get(settings, 'loan.dealerFeePerWatt', 0)) ||
        !get(quote, 'locked', false)
      )
    ) {
      return {
        showDealerFeePerWatt: false
      };
    }
    return {
      showDealerFeePerWatt: get(account, 'features.dealerFeePerWatt', false)
    };
  }
  return {
    showDealerFeePerWatt: get(account, 'features.dealerFeePerWatt', false) &&
      (activeTab || '').toLowerCase() !== 'cash'
  };
};

export const showDynamicProposal = (state) => {
  const { account } = accountSelector(state);
  return {
    showDynamicProposal: get(account, 'features.dynamicProposal', false)
  };
};

