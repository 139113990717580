
export function expireLocking(error) {
  return {
    type: 'expireLocking',
    payload: {
      error: { ...error, display: 'form' },
      quote: { dirty: true }
    }
  };
}
