import { get } from 'lodash';
import constants from './EddieCalculations/constants';

const { MIN_SYS_SIZE_STORAGE } = constants;

const selectedPaymentOptions = (settings) => [ 'cash', 'loan', 'spwrCash', 'mortgage' ]
  .filter((option) => get(settings, `${option}.showCustomer`, false));

export const minimumSystemSizeForStorage = (quote, design, settings) => {
  if (!quote.statesFeatureFlags ||
      !quote.statesFeatureFlags.length ||
      selectedPaymentOptions(settings).length) {
    return true;
  }
  const systemSizeKW = design.systemSize / 1000;
  return quote.statesFeatureFlags.some((flag) => flag.flagName === MIN_SYS_SIZE_STORAGE
    && (systemSizeKW === 0 || systemSizeKW >= flag.flagValue));
};
