import { omit } from 'lodash';
import { asyncPayload } from './apiHelpers';
import Api from '../utils/Api';
import { addItemFunction } from '../utils/collectionUpdater';

export function addNewRoof(roof, designId) {
  const roofData = omit(roof, ['id']);
  const requestPath = `manual_mode/designs/${designId}/roofs`;
  const promise = Api.create(requestPath, roofData)
    .then((result) => {
      return {
        design: { moduleArrays: addItemFunction(result.roof) },
        selectedInverterItems: result.selectedInverterItems,
        quote: { dirty: true },
        moduleChanged: true
      };
    });

  return {
    type: 'addNewRoof',
    payload: asyncPayload({
      request: promise
    })
  };
}
