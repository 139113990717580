export const roofEnergyMin = '#949494';
export const roofEnergyMax = '#e3e2e2';

export const moduleEnergyMin = '#fdcd0b';
export const moduleEnergyMax = '#f26b36';

export const moduleNormal = '#1a1919';
export const moduleUnmatched = '#62a6dc';
export const moduleOffOpacity = 0.3;
export const moduleColor = '#383838';

export const MODE_CHANGE_SLIDER = 'MODE_CHANGE_SLIDER';
export const MODE_CHANGE_PANELS = 'MODE_CHANGE_PANELS';
export const MODE_CHANGE_ARRAYS = 'MODE_CHANGE_ARRAYS';
export const CALCULATING_SAVINGS = 'CALCULATING_SAVINGS';

export const ROOF_PREVIEW_VISUALIZATION = 'ROOF_PREVIEW_VISUALIZATION';
export const ROOF_PREVIEW_MAP = 'ROOF_PREVIEW_MAP';

export const BASIC_VIEW = 'BASIC_VIEW';
export const DETAILED_VIEW = 'DETAILED_VIEW';

export const SALESFORCE_INSTANCE_URL = process.env.SALESFORCE_INSTANCE_URL || 'http://www.google.com';
export const PARTNER_PORTAL_INSTANCE_BASE_URL = process.env.PARTNER_PORTAL_INSTANCE_BASE_URL || 'http://www.google.com';
export const GOOGLE_MAPS_API_KEY = process.env.GOOGLE_MAPS_API_KEY || '';
export const ENABLE_PRICE_PER_KWH = process.env.ENABLE_PRICE_PER_KWH || '';
export const ENABLE_MORTGAGE = process.env.ENABLE_MORTGAGE || '';
export const ENABLE_GUARD_ACCESSORIES = process.env.ENABLE_GUARD_ACCESSORIES || '';
export const ENABLE_ADOBE_SIGN = process.env.ENABLE_ADOBE_SIGN || '';
export const ENABLE_STORAGE_EXPANSION_PACKS = process.env.ENABLE_STORAGE_EXPANSION_PACKS || '';
export const ENABLE_STORAGE_EXPANSION_PACKS_SO = process.env.ENABLE_STORAGE_EXPANSION_PACKS_SO || '';
export const VALIDATE_PRICE_PER_WATT_FOR_CASH_LEASE_FOR_ND =
  process.env.VALIDATE_PRICE_PER_WATT_FOR_CASH_LEASE_FOR_ND || '';
export const DD_RUM_APP_ID = process.env.DD_RUM_APP_ID;
export const DD_RUM_TOKEN = process.env.DD_RUM_TOKEN;
export const DD_RUM_SITE = process.env.DD_RUM_SITE;
export const DD_RUM_SERVICE = process.env.DD_RUM_SERVICE;
export const CODE_ENV = process.env.CODE_ENV;
export const CODE_VERSION = process.env.CODE_VERSION;
export const FIN_ENG_CALCULATOR_URL = process.env.FIN_ENG_CALCULATOR_URL;
export const FIN_ENG_CALCULATOR_API_KEY = process.env.FIN_ENG_CALCULATOR_API_KEY;
export const ENABLE_H_INVERTER_SO = process.env.ENABLE_H_INVERTER_SO;
export const ENABLE_DYNAMIC_PROPOSAL = process.env.ENABLE_DYNAMIC_PROPOSAL;
export const ENABLE_PPA_FINANCE_OPTION = process.env.ENABLE_PPA_FINANCE_OPTION;
export const ENABLE_EV_CHARGER = process.env.ENABLE_EV_CHARGER;
export const ENABLE_EV_CHARGER_SO = process.env.ENABLE_EV_CHARGER_SO;
export const ENABLE_LEASE_PPA_FULL_PREPAYMENT = process.env.ENABLE_LEASE_PPA_FULL_PREPAYMENT;
export const ENABLE_LEASE_PPA_DOWN_PAYMENT = process.env.ENABLE_LEASE_PPA_DOWN_PAYMENT;
export const ENABLE_CREDIT_APP_IN_EDDIE = process.env.ENABLE_CREDIT_APP_IN_EDDIE;
export const ENABLE_DYNAMIC_PROPOSAL_SO = process.env.ENABLE_DYNAMIC_PROPOSAL_SO;
export const ENABLE_DYNAMIC_PROPOSAL_ACKNOWLEDGEMENT = process.env.ENABLE_DYNAMIC_PROPOSAL_ACKNOWLEDGEMENT;
export const LD_CLIENT_KEY = process.env.LD_CLIENT_KEY;
export const SURFBOARD_CLIENT_KEY = process.env.SURFBOARD_CLIENT_KEY;
export const ENABLE_SEND_MORE_FIELDS_TO_SFDC = process.env.ENABLE_SEND_MORE_FIELDS_TO_SFDC;
export const ENABLE_INSTALL_DATE = process.env.ENABLE_INSTALL_DATE;
export const ENABLE_CUSTOM_ADDERS = process.env.ENABLE_CUSTOM_ADDERS;
export const ENABLE_DESIGN_REVISION = process.env.ENABLE_DESIGN_REVISION;
export const ENABLE_DOWNLOAD_DP_PDF = process.env.ENABLE_DOWNLOAD_DP_PDF;
export const ENABLE_GOODLEAP_CHECKBOX = process.env.ENABLE_GOODLEAP_CHECKBOX;

// This is requried for development only.
export const NOT_FOUND_URL = process.env.NOT_FOUND_URL || '/';
export const INTERCOM_APP_ID = process.env.INTERCOM_APP_ID || '';

export const initialState = {
  currentTabName: null,
  error: null,
  messages: null,
  validationErrors: {},
  loading: false,
  graphLoading: false,
  moduleChanged: false,
  storageChanged: false,
  updatingLease: false,
  updatingPpa: false,
  savingLoanPricing: null,
  loanCalcDirty: null,
  updateCoPayment: null,
  newCoPaymentValue: null,
  modals: {
    name: null,
    coordinates: null,
    stateOnOpen: null
  },
  hoveredModuleArray: null,
  mode: null,
  selectedPvModule: null,

  instantQuoteMode: false,
  canceledInstantQuoteLoading: false,
  instantQuoteDesignError: false,
  rightPanelExpanded: true,
  instantQuoteProduction: {
    production: 0.0,
    systemSize: 0.0,
    energyOffset: 0.0
  },
  instantQuoteSaveDesignRequest: 0,
  instantQuoteSaveDesignPreviousRequest: 0,
  instantQuoteSyncDesign: false,

  quote: {
    sfid: '',
    roofDesignId: -1,
    locked: false,
    pvModuleItemId: '',
    dirty: false,
    selectedFinanceOption: null,
    shadingMeasurementDate: null,
    lockedAnnualUsage: null,
    name: '',
    storageQuantity: 0
  },

  storageQuote: {
    sfid: '',
    locked: false,
    dirty: false,
    name: '',
    systemSize: null,
    dealerCommission: null,
    processing: false,
    updatedAt: '',
    storageQuantity: 1,
    systemCost: null,
    inverterType: '',
    isAddEvChargerForSOModalOpen: false,
    storageConfigId: null
  },

  allSystemCosts: {},

  credit: {
    terms: [],
    maxAmounts: [],
    status: {
      loan: null,
      lease: null,
      ppa: null
    },
    loanIsDeclined: null,
    aprs: [],
    aprPercentages: [],
    products: []
  },

  account: {
    name: '',
    street: '',
    annualUsage: 0,
    latitude: 0,
    longitude: 0,
    specialPrograms: '',
    features: {},
    partner: {},
    city: '',
    state: '',
    postalCode: '',
    isPDFProcessing: false
  },

  proposal: {
    pdfFilename: '',
    docxFilename: '',
    toolbar: {
      expandedMode: null
    },
    signedName: null,
    pdfUrl: null,
    docxUrl: null
  },

  energyData: {
    year1AnnualBill: {
      before: 0,
      after: 0,
      difference: 0
    },
    incentives: {
      cash: {},
      lease: {},
      ppa: {},
      loan: {}
    }
  },
  selectedDesign: null,
  designList: [],
  design: {
    roofPreviewMode: ROOF_PREVIEW_VISUALIZATION,
    viewMode: BASIC_VIEW,
    roof: {
      roofPlanes: [],
      moduleArrays: {},
      dimensions: { width: 0, height: 0 }
    },
    moduleArrays: [],
    selectedInverters: [],
    paired: false,
    activeModule: null,
    hasChanges: false,
    isInstant: false,
    satelliteImgUrl: ''
  },

  settings: {
    general: {
      nonSunpowerRacking: false
    },
    cash: {
      systemPriceSelection: 'pricePerWatt',
      rebateSelection: 'rebateEstimate',
      pricePerWatt: 0,
      pricePerWattWithMonitoringCost: 0,
      dealerFeePerWatt: 0,
      grossPrice: 0,
      grossPriceWithMonitoringCost: 0,
      storagePriceSelection: 'storagePrice',
      storagePrice: 0,
      storageDealerFee: 0,
      adders: 0,
      discount: 0,
      overrideRebate: 0,
      showCustomer: true,
      nonSunpowerLoan: '',
      downPaymentRate: null,
      loanInterestRate: null,
      loanTerm: null,
      evChargerDealerFee: 0
    },
    lease: {
      systemPriceSelection: 'pricePerWatt',
      rebateSelection: 'rebateEstimate',
      pricePerWatt: 0,
      pricePerWattWithMonitoringCost: 0,
      dealerFeePerWatt: 0,
      pricePerKwh: 0,
      grossPrice: 0,
      grossPriceWithMonitoringCost: 0,
      adders: 0,
      discount: 0,
      overrideRebate: 0,
      showCustomer: false,
      fullPrepayment: false,
      downPayment: 0,
      paymentEscalator: 0,
      fetchingFinEng: false
    },
    ppa: {
      systemPriceSelection: 'pricePerWatt',
      rebateSelection: 'rebateEstimate',
      pricePerWatt: 0,
      pricePerWattWithMonitoringCost: 0,
      dealerFeePerWatt: 0,
      pricePerKwh: 0,
      grossPrice: 0,
      grossPriceWithMonitoringCost: 0,
      adders: 0,
      discount: 0,
      overrideRebate: 0,
      showCustomer: false,
      fullPrepayment: false,
      downPayment: 0,
      paymentEscalator: 0,
      fetchingFinEng: false
    },
    loan: {
      systemPriceSelection: 'pricePerWatt',
      rebateSelection: 'rebateEstimate',
      pricePerWatt: 0,
      pricePerWattWithMonitoringCost: 0,
      dealerFeePerWatt: 0,
      grossPrice: 0,
      grossPriceWithMonitoringCost: 0,
      storagePriceSelection: 'storagePrice',
      storagePrice: 0,
      storageDealerFee: 0,
      adders: 0,
      discount: 0,
      overrideRebate: 0,
      showCustomer: false,
      coPayment: 0,
      termInMonths: null,
      enhancedPerformanceWarranty: false,
      aprType: null,
      evChargerDealerFee: 0
    },
    spwrCash: {
      systemPriceSelection: 'pricePerWatt',
      rebateSelection: 'rebateEstimate',
      pricePerWatt: 0,
      pricePerWattWithMonitoringCost: 0,
      dealerFeePerWatt: 0,
      grossPrice: 0,
      grossPriceWithMonitoringCost: 0,
      adders: 0,
      discount: 0,
      overrideRebate: 0,
      showCustomer: false
    },
    activeTab: 'cash',
    isPDFProcessing: false
  },

  productConfiguration: {
    financialOfferings: {
    },
    inverters: [],
    rackings: []
  },

  savings: {
    activeTab: null,
    activeGraphTab: 'savingsOverTimeGraph'
  },

  chartTerm: {
    cash: null,
    lease: null,
    loan: null,
    ppa: null
  },

  pvModules: [],

  partialPrepayment: [],

  roof: {
    id: null,
    pitch: 0,
    azimuth: 0,
    panelStringCount: 0,
    panelPerStringCount: 0,
    monthlySolarAccess: [ 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0 ],
    panelCount: 0,
    selectedInverterId: ''
  },

  dropdowns: null,

  selectedInverter: {
    inverterItemId: '',
    inverterName: '',
    id: null
  },

  annualUsage: 0,

  savingsChart: null,

  adobeSign: {
    refreshingWidget: false
  },

  inflationRate: '2',

  savingsChartSelection: null,

  emailProposalUi: {
    error: null,
    value: '',
    sent: false,
    sending: false
  },

  returnURL: null,

  isPublic: false,

  panelSliderValue: null,

  schema: {},

  productSelectionModal: {
    activeTab: 'modules',
    mainContentTab: 'info',
    sidebarContentTab: 'moduleType'
  },

  moduleSelectionModal: {
    selectedSeries: '',
    series: [],
    selectedColor: '',
    colors: [],
    selectedModel: '',
    models: [],
    selectedMonitoring: '',
    isCritterGuard: '',
    isSkirtGuard: '',
    inverters: [],
    selectedCellularMonitoring: null,
    cellularMonitoringExists: true,
    selectedRacking: '',
    rackings: [],
    pvModuleItemId: '',
    monitoringConfigurationId: '',
    selectedStorage: null,
    selectedStorageQuantity: null,
    storages: []
  },
  storageSelectionModal: {
    selectedStorageQuantity: null,
    storages: []
  },
  evChargerSelectionModal: {
    selectedWallBoxQuantity: null,
    selectedOutletQuantity: null,
    evChargers: []
  },
  loanCalcValues: null,
  dynamicProposal: {
    showComplianceCheckModal: false,
    showTitleCheckModal: false,
    acknowledgeNotifiedToCustomer: false,
    acknowledgeIdentityOfCustomer: false,
    sentVia: '',
    status: null,
    showModalLoader: false
  },
  soDynamicProposal: {
    showComplianceCheckModal: false,
    showTitleCheckModal: false,
    acknowledgeNotifiedToCustomer: false,
    acknowledgeIdentityOfCustomer: false,
    sentVia: '',
    status: null,
    showModalLoader: false
  },
  showCreditAppModal: false,
  pvModuleDetails: [],
  pvModuleSeriesDetails: [],
  utilitySavingsType: 'perMonth'
};
