import { omit } from 'lodash';
import { asyncPayload } from './apiHelpers';
import { initialState } from '../config';
import Api from '../utils/Api';
import { ensureValidId } from './roofDesign';
import { addItemFunction, updateItemFunction, deleteItemFunction } from '../utils/collectionUpdater';

function prepareSelectedInverterForm(selectedInverter, inverters) {
  const newSelectedInverter = Object.assign({}, initialState.selectedInverter, selectedInverter);
  const inverterItemId = ensureValidId(newSelectedInverter.inverterItemId, inverters);
  const inverter = inverters.find((inverter) => inverter.id === inverterItemId);
  newSelectedInverter.inverterItemId = inverterItemId;
  newSelectedInverter.inverterName = inverter ? inverter.name : '';
  return newSelectedInverter;
}

export function clearSelectedInverter(inverters) {
  const updatedSelectedInverter = prepareSelectedInverterForm(initialState.selectedInverter, inverters);
  return {
    type: 'clearSelectedInverter',
    payload: {
      selectedInverter: updatedSelectedInverter
    }
  };
}

export function deleteSelectedInverter(selectedInverter, designId) {
  const selectedInverterId = selectedInverter.id;
  const requestPath = `manual_mode/designs/${designId}/selected_inverters/${selectedInverterId}`;
  const promise = Api.delete(requestPath)
    .then((result) => ({
      design: {
        selectedInverters: deleteItemFunction(result.selectedInverter)
      },
      selectedInverterItems: result.selectedInverterItems,
      quote: { dirty: true, finEng: null }
    }));

  return {
    type: 'deleteSelectedInverter',
    payload: asyncPayload({
      request: promise
    })
  };
}

export function fillInSelectedInverter(selectedInverter, inverters) {
  const updatedSelectedInverter = prepareSelectedInverterForm(selectedInverter, inverters);
  return {
    type: 'fillInSelectedInverter',
    payload: { selectedInverter: updatedSelectedInverter }
  };
}

export function addNewSelectedInverter(selectedInverter, designId) {
  const selectedInverterData = omit(selectedInverter, ['id']);
  const requestPath = `manual_mode/designs/${designId}/selected_inverters`;
  const promise = Api.create(requestPath, selectedInverterData)
    .then((result) => ({
      design: {
        selectedInverters: addItemFunction(result.selectedInverter)
      },
      selectedInverterItems: result.selectedInverterItems,
      quote: { dirty: true, finEng: null }
    }));

  return {
    type: 'addNewSelectedInverter',
    payload: asyncPayload({
      request: promise
    })
  };
}

export function updateSelectedInverter(selectedInverter, designId) {
  const selectedInverterId = selectedInverter.id;
  const selectedInverterData = omit(selectedInverter, ['id']);
  const requestPath = `manual_mode/designs/${designId}/selected_inverters/${selectedInverterId}`;
  const promise = Api.update(requestPath, selectedInverterData)
    .then((result) => ({
      design: {
        selectedInverters: updateItemFunction(result.selectedInverter)
      },
      selectedInverterItems: result.selectedInverterItems,
      quote: { dirty: true, finEng: null }
    }));

  return {
    type: 'updateSelectedInverter',
    payload: asyncPayload({
      request: promise
    })
  };
}
