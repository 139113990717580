import createSelector from '../utils/createSelector';
import toNumber from '../utils/toNumber';

function annualUsage({ quote, account }) {
  const annualUsage = quote.locked ? quote.lockedAnnualUsage : account.annualUsage;
  return {
    annualUsage: toNumber(annualUsage)
  };
}

export default createSelector(
  annualUsage
);
