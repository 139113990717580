import { get } from 'lodash';
import { asyncPayload } from './apiHelpers';
import Api from '../utils/Api';

export const resetAdminPortal = () => (dispatch, getState) => {
  const currentState = getState();
  const accountDetails = get(currentState, 'apSalesforce.accountDetails');
  const userName = accountDetails ? (`${accountDetails.firstName} ${accountDetails.lastName}`) : '';
  const data = {
    user_name: userName
  };
  const promise = Api.deleteForAdmin('admin_portals', data)
  .then((resp) => {
    return resp.data;
  });

  dispatch({
    type: 'resetAdminPortal',
    payload: asyncPayload({
      beforeRequest: { loading: true },
      request: promise
    })
  });
};
