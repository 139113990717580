import React from 'react';
import PropTypes from 'prop-types';
import { register } from '../../utils/redux';
import { designType, quoteType, selectedInverterType } from '../../types';

export function Roofs({
  toggleModal,
  clearRoof,
  deleteRoof,
  fillInRoof,
  design,
  dcModule,
  selectedInverters,
  quote
}) {
  const sortedModuleArrays = design.moduleArrays.slice(0);
  sortedModuleArrays.sort((a1, a2) => a1.id - a2.id);
  const annualSolarAccess = (roof) => {
    const sum = roof.monthlySolarAccess.reduce((acc, val) => { return acc + val; });
    const avg = Math.round(sum / 12);
    return `${avg}%`;
  };
  const selectedInverterName = (selectedInverterId) => {
    if (selectedInverters.length === 0) {
      return '';
    }
    const selectedInverter = selectedInverters.find((selectedInverter) => selectedInverter.id === selectedInverterId);
    return selectedInverter && selectedInverter.name;
  };
  const selectableSelectedInverters = dcModule ? selectedInverters : [];

  return (
    <div id="roofs-section" className="manual-mode-design-section">
      <div className="section-header">
        <span className="title h3">Roofs</span>
        { quote.locked || (
        <span className="title-buttons">
          <a
            id="new_roof_modal_toggle"
            className="add-new-roof-btn add-new-btn"
            href="#" onClick={(evt) => {
              evt.preventDefault();
              clearRoof(selectableSelectedInverters);
              toggleModal('new-roof', null, { top: 50 });
            }}
          >NEW ROOF</a>
        </span>
        )}
      </div>

      <table className="table">
        <thead>
          <tr>
            <th>Name</th>
            <th>Pitch</th>
            <th>Azimuth</th>
            <th>Module Count</th>
            { dcModule && (<th>Modules per String</th>) }
            { dcModule && (<th>Parallel String</th>) }
            { dcModule && (<th>Inverter</th>) }
            <th>Annual Solar Access</th>
            <th />
          </tr>
        </thead>

        <tbody>
          {sortedModuleArrays.map((roof, index) => (
            <tr key={index} className="roof-row">
              <td className="roof-name">{`Roof ${index + 1}`}</td>
              <td className="roof-pitch">{roof.pitch}°</td>
              <td className="roof-azimuth">{roof.azimuth}°</td>
              <td className="roof-panel-count">{roof.panelCount}</td>
              { dcModule && (<td className="roof-panel-per-string-count">{roof.panelPerStringCount}</td>) }
              { dcModule && (<td className="roof-panel-string-count">{roof.panelStringCount}</td>) }
              { dcModule && (
                <td className="roof-selected-inverter-model">{selectedInverterName(roof.selectedInverterId)}</td>
              )}
              <td className="roof-annual-solar-access">
                {annualSolarAccess(roof)}
              </td>
              <td>
                {quote.locked ? (
                  <span>
                    <a
                      href="#"
                      onClick={(evt) => {
                        evt.preventDefault();
                        fillInRoof(roof, selectableSelectedInverters);
                        toggleModal('new-roof', null, { top: 50 });
                      }}
                      className="image-item image-preview-item image-preview-roof"
                    ><i className="icon icon-eye" /></a>
                  </span>
                ) : (
                  <span>
                    <a
                      href="#"
                      onClick={(evt) => {
                        evt.preventDefault();
                        fillInRoof(roof, selectableSelectedInverters);
                        toggleModal('new-roof', null, { top: 50 });
                      }}
                      className="image-item image-edit-item image-edit-roof"
                    ><i className="icon icon-pencil" /></a>
                    <a
                      href="#"
                      onClick={(evt) => {
                        evt.preventDefault();
                        /* eslint-disable no-alert */
                        if (confirm('Are you sure you want to delete this roof?')) {
                          deleteRoof(roof, design.id);
                        }
                        /* eslint-enable no-alert */
                      }}
                      className="image-item image-delete-item image-delete-roof"
                    ><i className="icon icon-trash" /></a>
                  </span>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

Roofs.propTypes = {
  toggleModal: PropTypes.func.isRequired,
  clearRoof: PropTypes.func.isRequired,
  deleteRoof: PropTypes.func.isRequired,
  fillInRoof: PropTypes.func.isRequired,
  design: designType,
  dcModule: PropTypes.bool.isRequired,
  selectedInverters: PropTypes.arrayOf(selectedInverterType),
  quote: quoteType.isRequired
};

export default register(
  [ 'designSelector', 'dcModuleSelector', 'selectedInvertersSelector', 'quoteSelector' ],
  [ 'toggleModal', 'clearRoof', 'deleteRoof', 'fillInRoof' ],
  Roofs
);
