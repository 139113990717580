import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import {
  quoteType,
  pvModuleType,
  selectedStorageWithExpansionPackType,
  businessFunctionsType,
  selectedStorageType,
  storageSelectionModalType
} from '../../../types';
import { InstantQuoteGraphSection } from './InstantQuoteGraphSection';
import { InstantQuoteEquipmentSection } from './InstantQuoteEquipmentSection';
import { InstantQuoteNoStorageSelected } from '../storage/InstantQuoteNoStorageSelected';
import { InstantQuoteStorageDetails } from '../storage/InstantQuoteStorageDetails';
import { isStorageEnabled } from '../../../utils/storage';
import { gaTrackEvent } from '../../../utils/googleAnalytics';

export function InstantQuoteBasicViewTab({
  quote,
  annualUsage,
  instantQuoteProduction,
  toggleModuleSelectionModal,
  moduleImageSrc,
  mountingImageSrc,
  monitoringName,
  selectedPvModule,
  storageSelected,
  toggleAddStorageModalFlag,
  storageSelectionModal,
  selectedStorage,
  selectedStorageWithExpansionPack,
  businessFunctions,
  isPartnerEnabledForAnyStorage
}) {
  const isQuoteLocked = quote.locked;
  const rackingType = quote.rackingType;

  const openStorageModal = useCallback(() => {
    toggleAddStorageModalFlag();
    gaTrackEvent('quotePage:designTab:addStorage');
  }, [toggleAddStorageModalFlag]);

  const storageEnabled = isStorageEnabled(businessFunctions, isPartnerEnabledForAnyStorage);

  return (
    <div className="div-relative basic-view-tab">
      { isQuoteLocked && (
        <div className="guide">
          <span>This design is locked and cannot be edited</span>
        </div>
      ) }

      <InstantQuoteGraphSection
        annualUsage={annualUsage}
        instantQuoteProduction={instantQuoteProduction}
      />

      <InstantQuoteEquipmentSection
        isQuoteLocked={isQuoteLocked}
        toggleModuleSelectionModal={toggleModuleSelectionModal}
        moduleImageSrc={moduleImageSrc}
        mountingImageSrc={mountingImageSrc}
        rackingType={rackingType}
        monitoringName={monitoringName}
        selectedPvModule={selectedPvModule}
      />

      { storageEnabled && (
        <div className="section storage-selection new">

          { storageSelectionModal.storages.length > 0 && !storageSelected && !isQuoteLocked && (
            <InstantQuoteNoStorageSelected openStorageModal={openStorageModal} />
          )}

          { storageSelected ? (
            <InstantQuoteStorageDetails
              quote={quote}
              selectedStorage={selectedStorage}
              selectedStorageWithExpansionPack={selectedStorageWithExpansionPack}
              openStorageModal={openStorageModal}
            />
          ) : null}

          { storageSelectionModal.storages.length === 0 && (
            <div className="row">
              <p>Please select another module to enable storage options</p>
            </div>
          )}
        </div>
      )}
    </div>
  );
}

InstantQuoteBasicViewTab.propTypes = {
  quote: quoteType,

  annualUsage: PropTypes.number,
  instantQuoteProduction: PropTypes.shape({
    production: PropTypes.number,
    systemSize: PropTypes.number,
    energyOffset: PropTypes.number
  }),
  toggleModuleSelectionModal: PropTypes.func,

  moduleImageSrc: PropTypes.string,
  mountingImageSrc: PropTypes.string,
  rackingType: PropTypes.string,
  monitoringName: PropTypes.string,
  selectedPvModule: pvModuleType,

  storageSelected: PropTypes.bool,
  toggleAddStorageModalFlag: PropTypes.func,

  selectedStorage: selectedStorageType,
  selectedStorageWithExpansionPack: selectedStorageWithExpansionPackType,
  businessFunctions: businessFunctionsType,
  isPartnerEnabledForAnyStorage: PropTypes.bool,
  storageSelectionModal: storageSelectionModalType
};
