import React, { useState } from 'react';
import { browserHistory } from 'react-router';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { register } from '../../utils/redux';
import { getPartnerPortalUrl } from '../../utils/url';
import { accountType, storageQuoteType, errorType } from '../../types';
import Link from '../Link';
import { formatCurrency } from '../../utils/formatNumber';

export function StorageSelectionPage({
  storageQuote,
  account,
  updateStorageQuote,
  lockStorageQuoteAndGenerateProposal,
  setError
}) {
  const STORAGE_CAPACITY = 13;
  const HOME_URL = `/storage/quote/${storageQuote.sfid}/home`;
  const PROPOSAL_URL = `/storage/quote/${storageQuote.sfid}/proposal`;
  const cancelUrl = getPartnerPortalUrl(account.sfid);

  const [ storageCount, setStorageCount ] = useState(storageQuote.storageQuantity);
  const [ storageCapacity, setStorageCapacity ] = useState(storageQuote.storageQuantity * STORAGE_CAPACITY);

  if (storageQuote.systemSize === null) {
    window.location.href = HOME_URL;
  }

  const handleBackNavigation = () => {
    browserHistory.push(HOME_URL);
  };

  const handleStorageChange = (isAddition) => {
    if (isAddition) {
      const newStorageCount = storageCount + 1;
      setStorageCount(newStorageCount);
      setStorageCapacity((newStorageCount) * STORAGE_CAPACITY);
      updateStorageQuote(storageQuote, { storage_quantity: newStorageCount }, false);
    } else {
      const newStorageCount = storageCount - 1;
      setStorageCount(newStorageCount);
      setStorageCapacity((newStorageCount) * STORAGE_CAPACITY);
      updateStorageQuote(storageQuote, { storage_quantity: newStorageCount }, false);
    }
  };

  const openSettingsModal = () => {
    document.getElementById('settingModalToggleGearButton').click();
  };

  const viewProposalBtnClickHandler = () => {
    if (storageQuote.dealerCommission === null) {
      setError({ message: 'Please go to the settings icon and enter the dealer commission.' });
      return;
    }
    lockStorageQuoteAndGenerateProposal(storageQuote, browserHistory, PROPOSAL_URL);
  };


  const viewProposalButtonHtml = () => {
    if (storageQuote.locked) {
      return (
        <Link
          className="btn btn-primary view-proposal-btn"
          to={PROPOSAL_URL}
        >
          View Proposal
        </Link>
      );
    }
    return (
      <button
        className="btn btn-primary view-proposal-btn"
        onClick={() => {
          viewProposalBtnClickHandler();
        }}
      >
        View Proposal
      </button>
    );
  };

  return (
    <div className="container-fluid storage-selection-container">
      <div className="storage-quote-page">
        <div className="graph-image-container">
          <img src={'/build/images/static-graph.svg'} alt="Static Graph" />
        </div>
        <div className="storage-quote-form-container">
          <div className="storage-count-container">
            <div className="count-action-container">
              <button
                id="subtract-storage"
                className={cx('count-action-btn subtract-storage-btn', {
                  active: storageCount > 1 && !storageQuote.locked
                })}
                disabled={storageCount === 1 || storageQuote.locked}
                onClick={() => handleStorageChange(false)}
              >&#8722;</button>
            </div>
            <div className="increment-container">
              <span id="storage-count" className="count-span">{storageCount}</span>
            </div>
            <div className="count-action-container">
              <button
                id="add-storage"
                className={cx('count-action-btn add-storage-btn', { active: storageCount < 2 && !storageQuote.locked })}
                disabled={storageCount === 2 || storageQuote.locked}
                onClick={() => handleStorageChange(true)}
              >&#43;</button>
            </div>
          </div>
          <div className="storage-detail-container">
            <div className="heading-container">
              <div className="heading">SunVault<sup className="sup-text">TM</sup> Storage</div>
              <div className="storage-wattage-text">
                {storageCapacity} kWh {storageCount === 1 ? 'BATTERY' : 'BATTERIES'}
              </div>
            </div>
            <div className="price-detail-container">
              <div className="total-price-text">Total Price for <b>
                {storageCount} {storageCount === 1 ? 'Battery' : 'Batteries'}:
              </b></div>
              {storageQuote.dealerCommission !== null &&
              <div
                id="total-storage-price"
                className="storage-price-div"
                data-tip={formatCurrency(storageQuote.systemCost, 0)}
              >
                {formatCurrency(storageQuote.systemCost, 0)}
              </div>
              }
              {storageQuote.dealerCommission === null &&
              <div
                id="view-price"
                className="view-price-btn"
                onClick={() => {
                  openSettingsModal();
                }}
              >VIEW PRICE</div>
              }
            </div>
            <div className="action-btn-container">
              <div className="solid-btn-container margin-bottom-top-10">
                <button
                  className="btn btn-secondary back-btn"
                  onClick={() => handleBackNavigation()}
                >
                  Back
                </button>
                {viewProposalButtonHtml()}
              </div>
              <a
                href={cancelUrl}
                className="cancel-btn"
                onClick={() => {
                  return true;
                }}
              >
                CANCEL
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

StorageSelectionPage.propTypes = {
  storageQuote: storageQuoteType.isRequired,
  account: accountType.isRequired,
  error: errorType,
  updateStorageQuote: PropTypes.func,
  lockingStorageQuote: PropTypes.bool,
  lockStorageQuoteAndGenerateProposal: PropTypes.func,
  setError: PropTypes.func
};

export default register(
  [
    'storageQuoteSelector',
    'accountSelector',
    'lockingStorageQuoteSelector',
    'errorSelector'
  ],
  [ 'updateStorageQuote', 'lockStorageQuoteAndGenerateProposal', 'setError' ],
  StorageSelectionPage
);
