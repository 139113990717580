import Papa from 'papaparse';
import constants from './admin_portal/constants';

const {
  AVAILABLE_FINANCIAL_OFFERINGS,
  PRICING_OPTIONS,
  PREVIEW_COLUMNS,
  GENERATED_COLUMNS
} = constants;

// All columns within the user uploaded CSV file, not including 'Status',
// 'isDuplicate'  'hasMultiplePo', 'hasOverlappingSize' which are added when we parse the CSV.
const CSV_COLUMNS = PREVIEW_COLUMNS.filter((col) => !GENERATED_COLUMNS.includes(col));

export const uploadDate = (updatedDate) => {
  const date = new Date(updatedDate);
  const modifiedDate = date ? date.toLocaleDateString() : '';
  return modifiedDate;
};

export const generateCSV = (rows, name) => {
  const csv = Papa.unparse(rows);
  const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
  return new File([blob], name);
};

export const downloadCSV = (rows, name) => {
  const file = generateCSV(rows, name);
  const anchor = document.createElement('a');
  anchor.setAttribute('download', file.name);
  const src = URL.createObjectURL(file);
  anchor.href = src;
  anchor.click();
  URL.revokeObjectURL(src);
};

const isEmptyLine = (line) => {
  const market = line.Market;
  const fo = line['Financial Option'];
  const sku = line.SKU;
  const po = line['Pricing Option'];
  const value = line['Dollar Value'];
  return !market && !fo && !sku && !po && !value;
};

const validateHeaders = (line, errors) => {
  const userCSVColumns = Object.keys(line).map((col) => col.toLowerCase().trim());
  const expectedCSVColumns = CSV_COLUMNS.map((col) => col.toLowerCase().trim());
  expectedCSVColumns.forEach((expectedCol) => {
    const colCount = userCSVColumns.filter((userCSVCol) => userCSVCol === expectedCol).length;
    if (colCount === 0) {
      errors.push(`Missing column header: ${expectedCol}`);
    } else if (colCount > 1) {
      errors.push(`Duplicate column header: ${expectedCol}`);
    }
  });
};

const validateLine = (line, index, financials, states, utilities, errors) => {
  if (isEmptyLine(line)) return true;
  const columnValidation = CSV_COLUMNS.every((column) => {
    const cell = line[column];
    const isMarket = column === 'Market';
    if (isMarket) {
      const validState = cell && states.includes(cell.toLowerCase());
      const validUtility = !utilities.length || cell && utilities.map((utility) => utility.name).includes(cell);
      const validation = cell && validUtility || validState;
      const error = `Invalid Market Value (${cell || 'Empty'}) on line: ${index + 2}.`;
      if (!validation) errors.push(error);
    }
    const isFinancial = column === 'Financial Option';
    if (isFinancial) {
      const offerings = AVAILABLE_FINANCIAL_OFFERINGS.map((offering) => offering.toLowerCase());
      const validation = cell && offerings.includes(cell.toLowerCase());
      const error = `Invalid Financial Option (${cell || 'Empty'}) on line: ${index + 2}.`;
      if (!validation) errors.push(error);
    }
    const isSku = column === 'SKU';
    if (isSku) {
      const option = line['Financial Option'].toLowerCase() === 'ppa' ? 'pPA' : line['Financial Option'].toLowerCase();
      const skuFinancial = financials[option];
      const market = line.Market;
      const utility = utilities ? utilities.find((util) => util.name === market) : null;
      const marketState = market && market.length === 2 ?
        market[0].toLowerCase() + market[1].toUpperCase() :
        utility ? utility.state[0].toLowerCase() + utility.state[1].toUpperCase() : null;
      const skuState = skuFinancial && marketState ? skuFinancial[marketState] : false;
      const skuList = skuState ? skuState.module.map((sku) => sku.moduleItemId) : [];
      const validation = cell && skuList.includes(cell);
      const error = `Invalid SKU (${cell || 'Empty'}) on line: ${index + 2}.`;
      if (!validation) errors.push(error);
    }
    const isPricing = column === 'Pricing Option';
    if (isPricing) {
      const options = PRICING_OPTIONS.map((option) => option.toLowerCase());
      const validation = cell && options.includes(cell.toLowerCase());
      const error = `Invalid Pricing Option (${cell || 'Empty'}) on line: ${index + 2}.`;
      if (!validation) errors.push(error);
    }
    const errorLine = `on line: ${index + 2}`;
    const minLabel = 'Min Dollar Value';
    const maxLabel = 'Max Dollar Value';
    const isMinDollarValue = column === minLabel;
    if (isMinDollarValue && !!cell) {
      const minValue = Number(cell);
      const maxValue = Number(line[maxLabel]);
      if (isNaN(minValue)) errors.push(`Invalid ${minLabel} (${cell}) ${errorLine}.`);
      if (maxValue && minValue === maxValue) {
        const errorMessage = `${minLabel} (${minValue}) cannot be equal to ${maxLabel} (${maxValue}) ${errorLine}.`;
        errors.push(errorMessage);
      }
      if (maxValue && minValue > maxValue) {
        const errorMessage =
          `${minLabel} (${minValue}) cannot be greater than ${maxLabel} (${maxValue}) ${errorLine}.`;
        errors.push(errorMessage);
      }
    }
    const isDollar = column === 'Dollar Value';
    if (isDollar) {
      const errorLine = `on line: ${index + 2}`;
      const validation = cell && !isNaN(Number(cell));
      const minValue = line[minLabel];
      const maxValue = line[maxLabel];
      if (validation) {
        if (minValue && Number(minValue) > Number(cell)) {
          errors.push(`${minLabel} (${minValue}) is greater than Dollar Value (${cell}) ${errorLine}.`);
        }
        if (maxValue && Number(maxValue) < Number(cell)) {
          errors.push(`Dollar Value (${cell}) cannot be greater than ${maxLabel} (${maxValue}) ${errorLine}.`);
        }
      } else {
        errors.push(`Invalid Dollar Value (${cell || 'Empty'}) ${errorLine}.`);
      }
    }
    const isMaxDollarValue = column === maxLabel;
    if (isMaxDollarValue && !!cell) {
      const maxValue = Number(cell);
      if (isNaN(maxValue)) errors.push(`Invalid ${maxLabel} (${cell}) ${errorLine}.`);
    }
    const isSystemSizeMin = cell && column === 'System Size Min (kW)';
    if (isSystemSizeMin) {
      const notNumber = !isNaN(Number(cell));
      const noMaxSize = !line['System Size Max (kW)'];
      const isEqual = Number(cell) !== Number(line['System Size Max (kW)']);
      const isMinor = Number(cell) < Number(line['System Size Max (kW)']);
      const validation = cell && noMaxSize || notNumber && isEqual && isMinor;
      const specificError = isEqual ? ', sizes are equal' : isMinor ? ', min size is greater than max size' : 'value';
      const error = `Invalid System Size Min ${specificError} (${cell}) on line: ${index + 2}.`;
      if (!validation) errors.push(error);
    }
    const isSystemSizeMax = cell && column === 'System Size Max (kW)';
    if (isSystemSizeMax) {
      const notNumber = !isNaN(Number(cell));
      const noMinSize = !line['System Size Min (kW)'];
      const isEqual = Number(cell) !== Number(line['System Size Min (kW)']);
      const isMayor = Number(cell) > Number(line['System Size Min (kW)']);
      const validation = cell && noMinSize || notNumber && isEqual && isMayor;
      const specificError = isEqual ? ', sizes are equal' : isMayor ? ', max size is greater than min size' : 'value';
      const error = `Invalid System Size Max ${specificError} (${cell}) on line: ${index + 2}.`;
      if (!validation) errors.push(error);
    }
    return cell || ([ 'System Size Min (kW)', 'System Size Max (kW)' ].includes(column));
  });
  return columnValidation;
};

export const validateFile = (csv, financials, states, utilities) => {
  const errors = [];
  const filteredLines = csv.filter((line) => line);
  validateHeaders(filteredLines[0], errors);
  filteredLines.forEach((line, index) => {
    validateLine(line, index, financials, states, utilities, errors);
  });
  return errors;
};

const rangeIsOverlapping = (x, y) => {
  if (x[0] < y[0]) {
    return !(x[0] < y[1] && x[1] < y[0]);
  }
  return !(y[0] < x[1] && y[1] < x[0]);
};

export const parseDuplicates = (file) => {
  const rows = file.filter((row) => Object.keys(row).length >= 7);
  const reversedRows = rows.reverse();
  const pairs = [];
  const uniqPairsHash = {};
  const overLappingPairs = {};
  reversedRows.map((row) => {
    // DUPLICATE MARKET/SKU/FO/SYSTEMSIZE
    const systemSizes = `${row['System Size Min (kW)']}-${row['System Size Max (kW)']}`;
    const differenciator = `${row.Market}-${row.SKU}-${row['Financial Option']}-${systemSizes}`;
    const uniqSet = `${row.Market}-${row['Financial Option']}`;
    row.isDuplicate = pairs.includes(differenciator);
    if (!pairs.includes(differenciator)) pairs.push(differenciator);

    // MUlTIPLE PRICING OPTIONS FOR SAME MARKET/FO
    row.hasMultiplePo = uniqPairsHash[uniqSet] && uniqPairsHash[uniqSet] !== row['Pricing Option'] && !row.isDuplicate;
    uniqPairsHash[uniqSet] = row['Pricing Option'];

    // OVERLAPPING SYSTEM SIZE
    const rowArray = [ row['System Size Min (kW)'], row['System Size Max (kW)'] ];
    if (overLappingPairs[uniqSet]) {
      const rangeIsOverlappingBool = rangeIsOverlapping(overLappingPairs[uniqSet], rowArray);
      row.hasOverlappingSize = rangeIsOverlappingBool && !row.hasMultiplePo && !row.isDuplicate;
      if (rangeIsOverlappingBool) overLappingPairs[uniqSet] = rowArray;
    } else {
      overLappingPairs[uniqSet] = rowArray;
    }


    return row;
  });
  rows.reverse();
  return rows;
};

export const cleanFile = (lines) => {
  const noEmptyLines = lines.filter((line) => !isEmptyLine(line));
  return parseDuplicates(noEmptyLines);
};

export const parseFile = async (rawFile) => {
  const fileParser = (unParsedFile) => {
    return new Promise((resolve) => {
      Papa.parse(unParsedFile, {
        header: true,
        complete: (results) => resolve(results.data)
      });
    });
  };
  const parsedFile = await fileParser(rawFile);
  return cleanFile(parsedFile);
};
