import u from 'updeep';
/* Selects panels in moduleArray based on numberPanelsEnabled.
 * There are the following rules:
 * a) numberPanelsEnabled should correspond to the number of selected panels,
 *    unless the number is not valid (e.g. we would have
 *    only one panel in an array) in which case, we round the number
 *    to the next valid one.
 * b) There cannot be only one selected panel on a single array
 * c) If array A has higher TSRF value than array B,
 *    we should enable panels in the array A
 *    before doing that in the array B
 * d) If panel X in array A is enabled after panel Y in array A
 *    then X and Y have to be neighbors (in the sense that
 *    there should be no empty space between them)
 *
 * To achieve that we divide all panels into chunks
 * that can be either fully enabled, or fully disabled,
 * and we iterate over the list of chunks enabling
 * first `numberPanelsEnabled` panels, while making sure
 * that all the other ones are disabled.
 *
 * As consecutive panels within a single moduleArray
 * are not necessarily next to each other on the roof,
 * to follow the rule (d) we sort the chunks related
 * to every module array according to panel's coordinates
 * (for more details check documentation
 * of the function sortedPanelIds)
 *
 */
export function autoSelectPanels(numberPanelsEnabled, design) {
  const moduleArrays = design.moduleArrays;
  const moduleArraysArrangement = design.roof.moduleArrays;
  let panelChunks = buildSortedPanelChunksList(moduleArrays, moduleArraysArrangement);
  panelChunks = setPanelChunkStates(panelChunks, numberPanelsEnabled);
  const validPanels = panelChunks.map((p) => p.overallPanelId);
  const newModuleArrays = buildModuleArraysList(moduleArrays, panelChunks);
  return u({ validPanels, moduleArrays: newModuleArrays }, design);
}


function buildModuleArraysList(moduleArrays, panelChunks) {
  return moduleArrays.map((moduleArray, moduleId) => {
    const panelChunksPerArray = panelChunks.filter((item) => item.arrayId === moduleId);
    const panelsOn = panelChunksPerArray.reduce((acc, meta) => {
      const newPanels = meta.on ? meta.panelIds : [];
      return acc.concat(newPanels);
    }, []);
    const panels = moduleArray.panels.map((panel, panelId) => {
      const enabled = panelsOn.includes(panelId);
      return { enabled };
    });
    return u({ panels }, moduleArray);
  });
}

function setPanelChunkStates(panelChunks, numberPanelsEnabled) {
  return panelChunks.map((state) => {
    const isOn = (numberPanelsEnabled !== 0) && (state.overallPanelId < numberPanelsEnabled);
    return u({ on: isOn }, state);
  });
}

/* This function defines rules of what can be selected in one step.
 * For now, we have one rule: we can't have in one array only one selected panel.
 */
function buildSortedPanelChunksList(moduleArrays, moduleArraysArrangement) {
  const acceptableStates = [];
  const sortedModuleArrays = sortedModuleArraysWithOriginalIds(moduleArrays);
  let overallPanelId = 0;
  sortedModuleArrays.forEach((ma) => {
    const moduleArrayArangement = moduleArraysArrangement[ma.roofPlaneNumber];
    const panelIds = sortedPanelIds(moduleArrayArangement);
    const ids = [ panelIds[0], panelIds[1] ];
    acceptableStates.push(buildPanelChunk(ma.originalId, ids, ma.roofPlaneNumber, overallPanelId));
    overallPanelId += 2;
    for (let i = 2; i < ma.panels.length; i++) {
      acceptableStates.push(buildPanelChunk(ma.originalId, [panelIds[i]], ma.roofPlaneNumber, overallPanelId));
      overallPanelId += 1;
    }
  });
  return acceptableStates;
}

/* This function sorts panels according to their visual representation,
 * to make sure that 2 panels enabled consecutively within one array
 * are always neighbours.
 *
 * To avoid situation where we try to sort over X coordinate
 * on a roof plane that is completely vertical (so has the same X values)
 * we sort over coordinate that is more diverse.
 */
function sortedPanelIds(moduleArrayArrangement) {
  const panelsInfo = moduleArrayArrangement.map((maa, panelId) => {
    return {
      panelId,
      minX: Math.min(...maa.map((coordinates) => coordinates.x)),
      minY: Math.min(...maa.map((coordinates) => coordinates.y))
    };
  });
  const xs = panelsInfo.map((i) => i.minX);
  const ys = panelsInfo.map((i) => i.minY);
  const xDiff = Math.max(...xs) - Math.min(...xs);
  const yDiff = Math.max(...ys) - Math.min(...ys);
  const significantCoordinate = yDiff > xDiff ? 'minY' : 'minX';
  panelsInfo.sort((a, b) => a[significantCoordinate] - b[significantCoordinate]);
  return panelsInfo.map((info) => info.panelId);
}

function sortedModuleArraysWithOriginalIds(moduleArrays) {
  const clone = moduleArrays.slice();
  const sortedModuleArrays = clone.map((moduleArray, originalId) => {
    return u({ originalId }, moduleArray);
  });
  sortedModuleArrays.sort((a, b) => {
    return b.annualTsrf - a.annualTsrf;
  });
  return sortedModuleArrays;
}

function buildPanelChunk(arrayId, panelIds, roofPlaneNumber, overallPanelId) {
  return { arrayId, panelIds, roofPlaneNumber, on: null, overallPanelId };
}
