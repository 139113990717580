import PropTypes from 'prop-types';
import React from 'react';

export function HelpGuideBtn({ setHelpText }) {
  return (
    <button
      className="btn btn-secondary info-container"
      onClick={() => setHelpText(true)}
    >
      Help Guide
    </button>
  );
}

HelpGuideBtn.propTypes = {
  setHelpText: PropTypes.func
};
