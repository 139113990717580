import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

export default function Tab({ activeTab, customClass, toggle, tabName, title, showTab, wrapping, id = '' }) {
  return (
    showTab &&
    <li
      id={id}
      className={cx(
        'tabs__header-item',
        customClass,
        { 'tabs__header-item--active': activeTab === tabName }
      )}
      onClick={() => {
        toggle(tabName);
      }}
    >
      { wrapping === true ? (<span>{title}</span>) : title }
    </li>
  );
}

Tab.propTypes = {
  activeTab: PropTypes.string.isRequired,
  customClass: PropTypes.string.isRequired,
  toggle: PropTypes.func.isRequired,
  tabName: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  showTab: PropTypes.bool.isRequired,
  wrapping: PropTypes.bool,
  id: PropTypes.string
};
