import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import { datadogTrackLoadingTime } from '../utils/datadogUserMonitoring';
import { asyncPayload } from './apiHelpers';
import Api from '../utils/Api';
import { informUserOfChangeInCoPayment } from '../utils/informUserOfChangesToSettings';
import { fetchGraphs } from './fetchGraphs';
import { updateCoPaymentValue } from './updateCoPaymentValue';
import constants from '../utils/EddieCalculations/constants';
import { CALCULATING_SAVINGS } from '../config';

const {
  LOAN_FINANCE_TYPE
} = constants;

export const updateLoan = (quote, data, source) => (dispatch, getState) => {
  const startTime = Date.now();

  const currentState = getState();

  const existingSavingsChart = currentState.savingsChart;
  const existingTotalSavingsChart = currentState.totalSavingsChart;
  const initialSavingsChart = {
    0: {
      loan: {
        legend: null,
        labels: {},
        metaSeries: {},
        graphSeries: {}
      }
    },
    2: {
      loan: {
        legend: null,
        labels: {},
        metaSeries: {},
        graphSeries: {}
      }
    },
    4: {
      loan: {
        legend: null,
        labels: {},
        metaSeries: {},
        graphSeries: {}
      }
    }
  };

  const account = currentState.account;
  const channel = get(account, 'partner.channel');
  const {
    downPayment,
    apr,
    aprPercentage,
    term,
    loanCalcValues
  } = data;
  const payload = {
    co_payment: downPayment,
    term_in_months: term,
    apr_type: apr,
    apr_percentage: aprPercentage,
    loan_calc_values: loanCalcValues
  };
  const timeoutTimeInMin = 1;
  let updateCoPaymentMsg = null;

  const beforeRequestState = () => {
    if (source === 'editLoan') {
      // we show whole screen loader in case of edit loan
      return {
        mode: CALCULATING_SAVINGS,
        savingsChart: initialSavingsChart,
        totalSavingsChart: null
      };
    } else if (source === 'loanCalculator') {
      // this only shows loader on the save pricing button
      return {
        loanCalcDirty: true,
        savingLoanPricing: true,
        savingsChart: initialSavingsChart,
        totalSavingsChart: null
      };
    }
    return {};
  };

  const afterRequestState = () => {
    if (source === 'editLoan') {
      // we show whole screen loader in case of edit loan
      return {};
    } else if (source === 'loanCalculator') {
      // this only shows loader on the save pricing button
      return { loanCalcDirty: false, savingLoanPricing: false };
    }
    return {};
  };

  const errorRequestState = () => {
    if (source === 'editLoan') {
      // we show whole screen loader in case of edit loan
      return {
        savingsChart: existingSavingsChart,
        totalSavingsChart: existingTotalSavingsChart
      };
    } else if (source === 'loanCalculator') {
      // this only shows loader on the save pricing button
      return {
        loanCalcDirty: true,
        savingLoanPricing: false,
        savingsChart: existingSavingsChart,
        totalSavingsChart: existingTotalSavingsChart
      };
    }
    return {};
  };

  dispatch({
    type: 'updateLoan',
    payload: asyncPayload({
      request: Api.updateWithPollingAndTimeout(
        `quotes/${quote.sfid}/settings/update_loan`,
        `quotes/${quote.sfid}/quote_details?record_types=quote,settings,all_system_costs`,
        payload,
        timeoutTimeInMin
      )
      .then((result) => {
        const downPaymentAfterFinCall = get(result, 'settings.loan.coPayment');
        updateCoPaymentMsg = informUserOfChangeInCoPayment(downPaymentAfterFinCall, downPayment).messages;
        const messages = [
          ...(result.messages || []),
          ...(updateCoPaymentMsg || [])
        ].filter((x) => !!x);

        dispatch(fetchGraphs(quote.sfid));

        if (source === 'loanCalculator') {
          dispatch(updateCoPaymentValue(!isEmpty(updateCoPaymentMsg), downPaymentAfterFinCall));
        }

        const loadingTime = Date.now() - startTime;
        datadogTrackLoadingTime(
          `Save ${LOAN_FINANCE_TYPE.toUpperCase()} Attributes`,
          loadingTime,
          quote,
          currentState.settings,
          channel
        );

        return { ...result, messages };
      }),
      beforeRequest: beforeRequestState(),
      afterRequest: afterRequestState(),
      errorRequest: errorRequestState()
    })
  });
};
