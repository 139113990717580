import u from 'updeep';
import createSelector from '../utils/createSelector';

function addNames({ design }) {
  const selectedInverters = design.selectedInverters;
  const selectedInvertersWithNames = selectedInverters.map((selectedInverter, index) => {
    return u({ name: `Inverter ${index + 1}` }, selectedInverter);
  });
  return {
    selectedInverters: selectedInvertersWithNames
  };
}

export default createSelector(
  addNames
);
