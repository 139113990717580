import React from 'react';
import PropTypes from 'prop-types';

export default function PricingDisclaimer({ isPublic }) {
  return isPublic ? (
    <div className="breakout-disclaimer legal-text">
  Pricing may change with further inputs and updates by your sales representative.
  Designing a solar system is customized specifically for you so further information
  may be needed that will impact the price above.
  </div>) : null;
}

PricingDisclaimer.propTypes = {
  isPublic: PropTypes.bool.isRequired
};
