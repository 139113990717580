import React from 'react';
import PropTypes from 'prop-types';
import { register } from '../utils/redux';
import { messagesType } from '../types';

export function MessagesOverlay({ messages, clearMessages }) {
  return (
    <div className="modal is-centered">
      <div id="overlay" />
      <div id="messages" className="popover">
        {
          messages.map((m, i) =>
            <p key={i}>{m.message}</p>
          )
        }
        <a href="#" className="btn btn-primary" onClick={clearMessages}>OK</a>
      </div>
    </div>
  );
}

MessagesOverlay.propTypes = {
  messages: messagesType,
  clearMessages: PropTypes.func
};

export default register(
  ['messagesSelector'],
  ['clearMessages'],
  MessagesOverlay
);
