import React from 'react';
import PropTypes from 'prop-types';
import { register } from '../../utils/redux';
import { quoteType, designType, selectedInverterType, productConfigurationType } from '../../types';

export function SelectedInverters({
  toggleModal,
  clearSelectedInverter,
  deleteSelectedInverter,
  fillInSelectedInverter,
  design,
  selectedInverters,
  productConfiguration,
  quote
}) {
  const inverters = productConfiguration.inverters;
  const inverterName = (selectedInverter) => {
    if (quote.locked) {
      return (<span>{selectedInverter.inverterName}</span>);
    }
    const foundInverter = inverters.find((inverter) => inverter.id === selectedInverter.inverterItemId);
    return foundInverter ?
      (<span>{foundInverter.name}</span>) :
      (<span>{selectedInverter.inverterName} <strong>(incompatible)</strong></span>);
  };

  const confirmMessage = (selectedInverter) => {
    const assigned = design.moduleArrays.find(
      (moduleArray) => moduleArray.selectedInverterId === selectedInverter.id
    );
    if (assigned) {
      return 'This inverter is associated with one or more configured roof arrays, ' +
        'deleting the inverter will remove this association.';
    }
    return 'Are you sure you want to delete this inverter?';
  };

  return (
    <div id="selected-inverters-section" className="manual-mode-design-section">
      <div className="section-header">
        <span className="title h3">Inverters</span>
        { quote.locked || (
        <span className="title-buttons">
          <a
            className="add-new-inverter-btn add-new-btn"
            href="#" onClick={(evt) => {
              evt.preventDefault();
              clearSelectedInverter(inverters);
              toggleModal('new-selected-inverter', null, { top: 50 });
            }}
          >NEW INVERTER</a>
        </span>
        )}
      </div>

      <table className="table">
        <thead>
          <tr>
            <th>Name</th>
            <th colSpan={2}>Model</th>
          </tr>
        </thead>

        <tbody>
          {selectedInverters.map((selectedInverter, index) => (
            <tr key={index} className="inverter-row">
              <td className="selected-inverter-name">{selectedInverter.name}</td>
              <td className="inverter-name">{inverterName(selectedInverter)}</td>
              <td>
                { quote.locked || (
                  <span>
                    <a
                      href="#"
                      onClick={(evt) => {
                        evt.preventDefault();
                        fillInSelectedInverter(selectedInverter, inverters);
                        toggleModal('new-selected-inverter', null, { top: 50 });
                      }}
                      className="image-item image-edit-item image-edit-inverter"
                    ><i className="icon icon-pencil" /></a>
                    <a
                      href="#"
                      onClick={(evt) => {
                        evt.preventDefault();
                        /* eslint-disable no-alert */
                        if (confirm(confirmMessage(selectedInverter))) {
                          deleteSelectedInverter(selectedInverter, design.id);
                        }
                        /* eslint-enable no-alert */
                      }}
                      className="image-item image-delete-item image-delete-inverter"
                    ><i className="icon icon-trash" /></a>
                  </span>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

SelectedInverters.propTypes = {
  toggleModal: PropTypes.func.isRequired,
  clearSelectedInverter: PropTypes.func.isRequired,
  deleteSelectedInverter: PropTypes.func.isRequired,
  fillInSelectedInverter: PropTypes.func.isRequired,
  design: designType,
  selectedInverters: PropTypes.arrayOf(selectedInverterType),
  productConfiguration: productConfigurationType.isRequired,
  quote: quoteType.isRequired
};

export default register(
  [ 'designSelector', 'selectedInvertersSelector', 'productConfigurationSelector', 'quoteSelector' ],
  [ 'toggleModal', 'clearSelectedInverter', 'deleteSelectedInverter', 'fillInSelectedInverter' ],
  SelectedInverters
);
