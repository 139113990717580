import React from 'react';
import PropTypes from 'prop-types';

import PercentCircle from '../../PercentCircle';
import ValueBox from '../../ValueBox';

export function InstantQuoteGraphSection({
  annualUsage,
  instantQuoteProduction
}) {
  return (
    <div className="section">
      <div className="row energy">
        <PercentCircle
          label="ENERGY OFFSET (ESTIMATED)"
          labelPosition="inside"
          className="new half"
          value={instantQuoteProduction.energyOffset}
          hideShadowAfter100={true}
        />
        <div className="column half">
          <ValueBox
            className="new"
            label="System Size"
            value={instantQuoteProduction.systemSize}
            subvalue="kW"
            places={2}
          />
          <ValueBox
            className="new"
            label="Electricity Production (est.)"
            value={instantQuoteProduction.production}
            subvalue="kWh/year"
          />
          <ValueBox
            className="new"
            label="Energy Needs"
            value={Math.round(annualUsage)}
            subvalue="kWh/year"
          />
        </div>
      </div>
    </div>
  );
}

InstantQuoteGraphSection.propTypes = {
  annualUsage: PropTypes.number,
  instantQuoteProduction: PropTypes.shape({
    production: PropTypes.number,
    systemSize: PropTypes.number,
    energyOffset: PropTypes.number
  })
};
