export function updateGeneralSettings(key, value) {
  return {
    type: 'updateGeneralSettings',
    payload: {
      settings: {
        general: {
          [key]: value
        }
      }
    }
  };
}
