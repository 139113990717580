import { get } from 'lodash';
import { pvModuleTagToName } from '../pvModuleFiltersAndTags';
import * as designCalcs from '../EddieCalculations/design';
import { CASH, LEASE, LOAN, PPA } from '../EddieCalculations/helpers/financeTypes';
import { getSelectedFinancialOptions, moduleAvailableForAllSelectedPaymentOptionsValidation }
  from '../selectedModuleValidationUtility';
import constants from '../EddieCalculations/constants';

const { CRITTER_GUARD, SKIRT_GUARD, MIN_SYS_SIZE_STORAGE } = constants;

// this function can return false for example when some inverter was assigned to the roof
// and then removed from the inverters list.
const selectedPaymentOptions = (settings) => [ 'cash', 'lease', 'loan', 'spwrCash', 'ppa', 'mortgage' ]
  .filter((option) => get(settings, `${option}.showCustomer`, false));

const moduleTrim = (module) => {
  return module.replace(' Series ', '').replace('REC', 'R');
};

export const validateInvertersOfRoofs = ({ pvModule, selectedInverters, design }) => {
  if (!designCalcs.isDc(pvModule)) return null;
  const allRoofsHaveInverter = design.moduleArrays.every((moduleArray) =>
    selectedInverters.some((selectedInverter) => moduleArray.selectedInverterId === selectedInverter.id)
  );
  if (allRoofsHaveInverter) return null;
  return { message: 'All roofs must be associated with an inverter.' };
};

export const validateRoofsOfInverters = ({ design, selectedInverters, pvModule }) => {
  if (!designCalcs.isDc(pvModule)) return null;
  const allInvertersHaveModuleArray = selectedInverters.every((selectedInverter) =>
    design.moduleArrays.some((moduleArray) => moduleArray.selectedInverterId === selectedInverter.id)
  );
  if (allInvertersHaveModuleArray) return null;
  return {
    message:
      'There are inverters included in the design which are not associated with an array, ' +
      'please either delete the extra inverter(s) or associate them with an array.'
  };
};

export const validateModuleOfInverters = ({ pvModule, selectedInverters, productConfiguration }) => {
  if (!designCalcs.isDc(pvModule)) return null;
  const { inverters } = productConfiguration;
  const allSelectedInvertersHaveInverters = selectedInverters.every((selectedInverter) =>
    inverters.some((inverter) => inverter.id === selectedInverter.inverterItemId)
  );
  if (allSelectedInvertersHaveInverters) return null;
  return {
    message: 'One or more of the selected inverters is not available for the selected module.'
  };
};

export const validateShadingMeasurementDate = ({ quote }) => {
  if (quote.shadingMeasurementDate) return null;
  return {
    message: 'Please enter a shading measurement date.'
  };
};

export const validateSystemForAllPaymentOptions = ({ productConfiguration, settings, pvModule }) => {
  const selectedFinancialOptions = getSelectedFinancialOptions({ productConfiguration, settings });
  const moduleFinancialOptions = pvModule.financialOfferings;

  const uncompatibleFinancialOptions = selectedFinancialOptions.filter((option) =>
    !moduleFinancialOptions.includes(option)
  );
  const optionsAsString = uncompatibleFinancialOptions.map(pvModuleTagToName).join(', ');
  if (moduleAvailableForAllSelectedPaymentOptionsValidation({ moduleFinancialOptions, selectedFinancialOptions })) {
    return null;
  }
  return {
    message: 'This module is not available for all of the selected financial offerings, ' +
    `please change module, or deselect the following financial option(s): ${optionsAsString}`
  };
};

export const OVER_10KW_SYSTEM_SIZE_ERROR = `The design exceeds the 10kW AC maximum system size.
Please reduce the system size to less than 10kW AC to proceed.`;
export const OVER_25KW_SYSTEM_SIZE_ERROR = `The design exceeds the 25kW AC maximum system size.
Please reduce the system size to less than 25kW AC to proceed.`;
export const STORAGE_SYSTEM_SIZE_ERROR = `Minimum system size for storage on Lease/PPA is 4kW. 
Please increase the system size or unselect storage`;

export const validateSystemSize = (
  { quote,
    account,
    design,
    pvModule,
    selectedInverterItems,
    inverterUnits,
    settings,
    partnerIsRvar,
    partnerIsNewHomes
  }
) => {
  const systemSizeKW = design.systemSize / 1000;
  if (!partnerIsNewHomes && quote.storageConfigId && quote.statesFeatureFlags &&
      [ LEASE, PPA ].some((fo) => selectedPaymentOptions(settings).includes(fo))) {
    const systemSizeStorageValidation = quote.statesFeatureFlags && quote.statesFeatureFlags.some((flag) =>
    flag.flagName === MIN_SYS_SIZE_STORAGE && systemSizeKW < flag.flagValue);
    if (systemSizeStorageValidation) {
      const fo = selectedPaymentOptions(settings).includes(LEASE) ? LEASE : PPA;
      return {
        message: `Minimum system size for storage on ${fo.toUpperCase()} is 4kW. 
         Please increase the system size or unselect storage`
      };
    }
  }

  const systemCapacityAcWatts =
    designCalcs.systemCapacityAcWatts(design, pvModule, selectedInverterItems, inverterUnits);

  const state = account.state;
  if ((state !== 'IL') && (state !== 'MA')) {
    return null;
  }

  const skipValidationForMA =
  partnerIsRvar &&
  get(settings, `${CASH}.showCustomer`) &&
  !get(settings, `${LEASE}.showCustomer`) &&
  !get(settings, `${LOAN}.showCustomer`) &&
  !get(settings, `${PPA}.showCustomer`);
  if ((state === 'MA') && skipValidationForMA) {
    return null;
  }

  if ((state === 'MA') &&
     (get(settings, `${CASH}.showCustomer`) ||
     get(settings, `${LEASE}.showCustomer`) ||
     get(settings, `${LOAN}.showCustomer`) ||
     get(settings, `${PPA}.showCustomer`))) {
    if (systemCapacityAcWatts <= 25000) return null;
    return {
      message: OVER_25KW_SYSTEM_SIZE_ERROR
    };
  }

  if ((state === 'IL') &&
    (get(settings, `${CASH}.showCustomer`) ||
      get(settings, `${LEASE}.showCustomer`) ||
      get(settings, `${LOAN}.showCustomer`) ||
      get(settings, `${PPA}.showCustomer`)
    )) {
    if (systemCapacityAcWatts <= 25000) return null;
    return {
      message: OVER_25KW_SYSTEM_SIZE_ERROR
    };
  }

  if (systemCapacityAcWatts <= 10000) return null;
  return {
    message: OVER_10KW_SYSTEM_SIZE_ERROR
  };
};

export const validateModuleWithAdminSetting = ({ settings, quote, pvModule }) => {
  let error = '';
  if (quote.adminFinConfigs) {
    const filteredAdminFinConfig = quote.adminFinConfigs.filter(((config) =>
     selectedPaymentOptions(settings).includes(config.finType)
    ));
    if (filteredAdminFinConfig.length) {
      const unavailableModule = filteredAdminFinConfig.filter((config) =>
        config.moduleConfigsAttributes && config.moduleConfigsAttributes.every((con) =>
        pvModule.series.toLowerCase() !== moduleTrim(con.moduleSeries).toLowerCase()
      ));
      if (unavailableModule.length) {
        error = 'This module is not available for all of the selected financial offerings, ' +
        `please change module, or deselect the following financial option(s): ${unavailableModule[0].finType}`;
        return error;
      }
    }
  }
  return error ? { message: error } : null;
};

export const validateStorageAndAccesoriesWithAdminSetting = ({ settings, quote }) => {
  let error = '';
  if (quote.adminFinConfigs) {
    const filteredAdminFinConfig = quote.adminFinConfigs.filter(((config) =>
     selectedPaymentOptions(settings).includes(config.finType)
    ));
    if (filteredAdminFinConfig.length) {
      if (quote.storageConfigId || quote.isCritterGuard || quote.isSkirtGuard) {
        filteredAdminFinConfig.map((config) => {
          if (config && config.productConfigsAttributes) {
            if (quote.storageConfigId) {
              const storageUnavailable = config.productConfigsAttributes.filter((productConfig) =>
                quote.storageConfigId === productConfig.product && !productConfig.sell
              );
              if (storageUnavailable.length) {
                error = 'This storage is not available for all of the selected financial offerings, ' +
                `please change storage, or deselect the following financial option(s): ${config.finType}`;
                return error;
              }
            }
            if (quote.isCritterGuard) {
              const accessoryUnavailable = config.productConfigsAttributes.filter((productConfig) =>
                productConfig.product === CRITTER_GUARD && !productConfig.sell
              );
              if (accessoryUnavailable.length) {
                error = 'Critter Guard is not available for all of the selected financial offerings, ' +
                `please unselect accessory,
                or deselect the following financial option(s): ${config.finType}`;
                return error;
              }
            }
            if (quote.isSkirtGuard) {
              const accessoryUnavailable = config.productConfigsAttributes.filter((productConfig) =>
                productConfig.product === SKIRT_GUARD && !productConfig.sell
              );
              if (accessoryUnavailable.length) {
                error = 'Skirt Guard is not available for all of the selected financial offerings, ' +
                `please unselect accessory,
                or deselect the following financial option(s): ${config.finType}`;
                return error;
              }
            }
          }
          return error ? { message: error } : null;
        });
      }
    }
  }
  return error ? { message: error } : null;
};

export default ({
  settings, productConfiguration, design, selectedInverters,
  quote, pvModule, account, selectedInverterItems, inverterUnits, partnerIsRvar, partnerIsNewHomes
}) => (
  validateInvertersOfRoofs({ design, selectedInverters, pvModule }) ||
  validateRoofsOfInverters({ design, selectedInverters, pvModule }) ||
  validateModuleOfInverters({ productConfiguration, selectedInverters, pvModule }) ||
  validateShadingMeasurementDate({ quote }) ||
  validateModuleWithAdminSetting({ settings, quote, pvModule }) ||
  validateStorageAndAccesoriesWithAdminSetting({ settings, quote }) ||
  validateSystemForAllPaymentOptions({ pvModule, settings, productConfiguration }) ||
  validateSystemSize({ quote,
    account,
    design,
    pvModule,
    selectedInverterItems,
    inverterUnits,
    settings,
    partnerIsRvar,
    partnerIsNewHomes })
);
