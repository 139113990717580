import PropTypes from 'prop-types';
import { rackingType } from './racking';
import { inverterType } from './inverter';
import { monitoringConfigurationType } from './monitoringConfiguration';

export const productConfigurationType = PropTypes.shape({
  financialOfferings: PropTypes.shape({
    cash: PropTypes.bool.isRequired,
    lease: PropTypes.bool.isRequired,
    loan: PropTypes.bool.isRequired
  }),
  rackings: PropTypes.arrayOf(rackingType),
  inverters: PropTypes.arrayOf(inverterType),
  monitoringConfigurations: PropTypes.arrayOf(monitoringConfigurationType)
});
