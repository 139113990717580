import React, { useEffect, useRef, useState } from 'react';
import ReactTooltip from 'react-tooltip';
import PropTypes from 'prop-types';
import { register } from '../../../utils/redux';
import { InputFieldWithCurrencySymbol } from '../shared/InputFieldWithCurrencySymbol';
import { enableCreditAppInEddie } from '../../../utils/backendFeatureFlags';
import { invalidateAdderNameAndPrice } from '../../../utils/admin_portal/quoteHelpers';

export function AddersList({ customAdder, selectedAdderType, updateApCustomAdders, financeType }) {
  const [ adderInfo, setAdderInfo ] = useState([]);

  /* eslint no-underscore-dangle: 0 */
  useEffect(() => {
    if (customAdder) {
      let filteredAdders = customAdder.filter((adder) =>
        adder.adderType === selectedAdderType && adder._destroy !== true);
      if (selectedAdderType === 'optional') {
        const mandatedAdders = customAdder.filter((adder) =>
          adder.adderType === 'mandated');
        filteredAdders = mandatedAdders.concat(filteredAdders);
      }
      setAdderInfo(filteredAdders);
    }
  }, [ customAdder, selectedAdderType ]);

  const findCustomAdder = (adderId) => {
    return adderInfo.find((adder) => adder.id === adderId || adder.adderId === adderId);
  };

  const editStatus = (adderId, changeStatus) => {
    const adder = findCustomAdder(adderId);
    if (changeStatus === 'delete') {
      adderInfo.splice(adderInfo.indexOf(adder), 1);
    } else {
      const newAdder = { ...adder, status: changeStatus };
      adderInfo.splice(adderInfo.indexOf(adder), 1, newAdder);
    }
    setAdderInfo([...adderInfo]);
  };

  const saveCustomAdder = (financeType, adderId) => {
    editStatus(adderId, 'clear');
    const adder = findCustomAdder(adderId);
    updateApCustomAdders(financeType, adderId, adder);
  };

  const createCustomAdder = (financeType, adderId) => {
    editStatus(adderId, 'clear');
    const adder = findCustomAdder(adderId);
    updateApCustomAdders(financeType, null, adder);
  };

  const deleteCustomAdder = (financeType, adderId) => {
    const adder = findCustomAdder(adderId);
    const newAdder = { ...adder, _destroy: true };
    adderInfo.splice(adderInfo.indexOf(adder), 1, newAdder);
    updateApCustomAdders(financeType, adderId, newAdder);
  };

  const handleValueChange = (key, value, adderId) => {
    if (!invalidateAdderNameAndPrice(key, value)) {
      const adder = findCustomAdder(adderId);
      const newAdder = { ...adder, [key]: value };
      adderInfo.splice(adderInfo.indexOf(adder), 1, newAdder);
      setAdderInfo([...adderInfo]);
    }
  };

  const addCustomAdder = () => {
    setAdderInfo([
      ...adderInfo,
      {
        adderId: selectedAdderType + Math.floor(Math.random() * 9000),
        adderName: '',
        adderPrice: 0,
        adderType: selectedAdderType,
        isLive: true,
        status: 'new'
      }
    ]);
  };

  const renderActionButtons = (status, adderId) => {
    const adder = findCustomAdder(adderId);
    switch (status) {
      case 'new':
        return (
          <div className="action-container adders-btn">
            <button
              className="btn btn-cancel"
              onClick={() => editStatus(adderId, 'delete')}
            >
              Cancel
            </button>
            <button
              className="btn btn-save margin-20"
              onClick={() => createCustomAdder(financeType, adderId)}
              disabled={adder.adderName === '' || adder.adderPrice === ''}
            >
              Create
            </button>
          </div>
        );

      case 'edit':
        return (
          <div className="action-container adders-btn">
            <button
              className="btn btn-cancel"
              onClick={() => editStatus(adderId, 'clear')}
            >
              Cancel
            </button>
            <button
              className="btn btn-save margin-20"
              onClick={() => saveCustomAdder(financeType, adderId)}
              disabled={adder.adderName === '' || adder.adderPrice === ''}
            >
              Save
            </button>
            <div className="delete-svg" onClick={() => deleteCustomAdder(financeType, adderId)} />
          </div>
        );

      default:
        return (
          <div className="action-container adders-btn">
            {adder.adderType !== 'mandated' ? (
              <div className="edit-btn" onClick={() => editStatus(adderId, 'edit')}>
                <div>Edit</div>
                <div className="edit-svg" />
              </div>
            ) : <div className="mandated">
              <div>Mandated</div>
            </div>
              }
          </div>
        );
    }
  };

  const renderLiveButton = (isLive, status, adderId) => {
    switch (status) {

      case 'new':
      case 'edit':
        return (
          <div className="toggle-btn-with-label-container live-btn">
            <div className="toggle-btn-container">
              <label className="toggle-btn">
                <input
                  type="checkbox"
                  checked={isLive}
                  onChange={(e) => handleValueChange('isLive', e.target.checked, adderId)}
                />
                <span className="slider round" />
              </label>
              <label className="toggle-btn-status-label">{isLive ? 'Live' : 'Off'}</label>
            </div>
          </div>
        );

      default:
        return (
          <div className="is-live">
            { isLive ? <div className="live-btn-on" /> : ''}
            <div className="label">{ isLive ? 'Live' : 'Off' }</div>
          </div>
        );
    }
  };

  return (
    <React.Fragment>
      {adderInfo.length > 0 ?
          adderInfo.map((adderInfo) =>
            <div className="adder-list">
              <input
                type="text"
                placeholder="Type adder name"
                className="adder-name-input"
                value={adderInfo.adderName}
                onChange={(e) => handleValueChange('adderName', e.target.value, (adderInfo.id || adderInfo.adderId))}
                disabled={!adderInfo.status || adderInfo.status === 'clear'}
              />
              <div className="currency-input">
                <InputFieldWithCurrencySymbol
                  currency={'$'}
                  placeHolder={'0'}
                  value={adderInfo.adderPrice}
                  onChange={(e) => handleValueChange('adderPrice', e, (adderInfo.id || adderInfo.adderId))}
                  disabled={!adderInfo.status || adderInfo.status === 'clear'}
                />
              </div>
              <div className="live-checkbox">
                {renderLiveButton(adderInfo.isLive, adderInfo.status, (adderInfo.id || adderInfo.adderId))}
                <div>
                  <a data-tip={true} data-event="click focus" data-for="live-function">
                    <img src="/build/images/admin-info-icon.svg" alt="info" className="info-img" />
                  </a>
                  <ReactTooltip
                    id="live-function"
                    globalEventOff="click"
                    event="click" place="top"
                    type="light"
                    clickable={true}
                    effect="solid"
                    multiline={true}
                  >
                    <p>Tool tip for the live function - explainer text</p>
                  </ReactTooltip>
                </div>
              </div>
              <div className="payment-route"><p>Payment Route : <b>SunPower</b></p></div>
              {renderActionButtons(adderInfo.status, (adderInfo.id || adderInfo.adderId))}
            </div>)
        : null }

      <div className="new-adder" onClick={addCustomAdder}>
        <label>New <div className="plus">+</div> Adder</label>
      </div>
    </React.Fragment>
  );
}

AddersList.propTypes = {
  financeType: PropTypes.string.isRequired,
  availableStates: PropTypes.arrayOf(PropTypes.object),
  updateStateSelection: PropTypes.func.isRequired,
  customAdder: PropTypes.arrayOf(PropTypes.object),
  selectedAdderType: PropTypes.string.isRequired,
  updateApCustomAdders: PropTypes.func.isRequired
};

export default register(
  [],
  [],
  AddersList
);
