import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { get, keys } from 'lodash';
import { register } from '../../utils/redux';
import { modalsType } from '../../types';
import { Modal } from '../Modal';
import Link from '../Link';
import { HistoryLogPagination } from './HistoryLogPagination';

const logLabelMapping = {
  cash: 'cash',
  loan: 'loan',
  lease: 'lease',
  ppa: 'ppa'
};

const usersPerPage = 10;

export function HistoryView(prop) {
  const [ historyLog, setHistoryLog ] = useState(prop.historyLog[0]);
  const [ activeLogUser, setActiveLogUser ] = useState(Object.keys(historyLog)[0]);

  useEffect(() => {
    setHistoryLog(prop.historyLog[0]);
  }, [prop.historyLog]);

  useEffect(() => {
    setActiveLogUser(Object.keys(historyLog)[0]);
  }, [historyLog]);

  const [ showModal, setShowModal ] = useState([]);
  const [ userModal, setUserModal ] = useState('');
  const onClickSetActive = (user) => {
    const setUser = activeLogUser === user ? '' : user;
    setActiveLogUser(setUser);
  };

  const renderViewMoreButton = (userData, user) => {
    if (userData.length > 4) {
      return (
        <table className="history-table table admin-table">
          <tr className="body border-bottom">
            <td className="view-more">
              <a href="#" onClick={() => { setShowModal(userData); setUserModal(user); }}>View more</a>
            </td>
          </tr>
        </table>
      );
    }
    return ('');
  };

  function titleCase(string) {
    const result = string.replace(/([A-Z])/g, ' $1');
    return result.charAt(0).toUpperCase() + result.slice(1);
  }

  const renderNewProperty = (label, property) => {
    if (property !== null) {
      if (label === '| Option' || label === '| Default' || label === '| Opted In' || label === '| AdHocAdders') {
        property = property ? 'On' : 'Off';
      }
      if (label === '| Custom Adder') {
        let value = '';
        property.forEach((log) => {
          value += `| ${titleCase(log.adderType)} Adder ${log.changeType}:`;
          if (log.adderName) {
            value += ` ${log.adderName}`;
          }
          if (log.adderPrice) {
            value += ` $${log.adderPrice}`;
          }
          if (log.adderPrice) {
            value += log.isLive ? ' Is-Live' : ' Off';
          }
        });
        return (
          <span>{value}</span>
        );
      }
      if (label === '| Price') {
        let val = '';
        Object.keys(property).forEach((each) => {
          val += `| ${each.toUpperCase()}`;
          Object.keys(property[each]).forEach((key) => {
            val += `, ${key}:$${property[each][key]}`;
          });
        });
        return (
          <span>{val}</span>
        );
      }
      if (label === '| Product') {
        let value = '';
        const productKeys = Object.keys(property);
        productKeys.forEach((product) => {
          let subValue = '';
          const eachProduct = property[product];
          Object.keys(eachProduct).forEach((propertyName) => {
            if (eachProduct[propertyName] !== undefined) {
              if (propertyName === 'commissionPerUnit' && eachProduct[propertyName]) {
                subValue += eachProduct[propertyName] === 'Nil' ? '' : '$';
                subValue += `${eachProduct[propertyName]}`;
              }
              if (propertyName === 'sell') {
                subValue += eachProduct[propertyName] ? ' Sell' : ' Unsell';
              }
            }
          });
          value += `| ${product.toUpperCase()}: ${subValue}`;
        });
        return (
          <span>{value}</span>
        );
      }
      if (label === '| State') {
        property = property.states.join(', ').toUpperCase();
      }
      if (label === '| Module Series') {
        let removed = '';
        let added = '';
        let value = '';
        Object.values(property).forEach((module) => {
          if (module.Destroy) {
            removed += removed ? ', ' : '';
            removed += ` ${module.moduleSeries}`;
          } else {
            added += added ? ', ' : '';
            added += ` ${module.moduleSeries}`;
          }
        });
        value += added ? `Added: ${added}` : '';
        value += value && removed ? ', ' : '';
        value += removed ? `Removed: ${removed}` : '';
        property = value;
      }
      if (label === '| Payment Escalator') {
        property = property.paymentEscalator;
      }
      if (label === '| Default Loan') {
        let value = '';
        if (property.loanTerm) {
          value += `| Loan Term: ${property.loanTerm}`;
        }
        if (property.choiceRate) {
          value += `| Default APR: ${property.choiceRate}`;
        }
        return (<span>{value}</span>);
      }
      return (
        <span>{label}: {property}</span>
      );
    }
    return ('');
  };

  const spanValue = (label, property) => {
    return (
      <span>{label}: {property}</span>
    );
  };

  const parseActivity = (foArray, financeOption) => {
    const foType = `Finance Type: ${financeOption}`;
    let val = '';
    let moduleTypes = '';
    let paymentEscalator = '';
    let product = '';
    let productPrice = '';
    let price = '';
    let priceType = '';
    foArray.forEach((fo) => {
      if (Array.isArray(fo[financeOption])) {
        if (fo[financeOption][0].product !== undefined) {
          product = `| ${fo[financeOption][0].product.toUpperCase()}`;
          productPrice = `$${fo[financeOption][0].commissionPerUnit}`;
        }
        if (fo[financeOption][0].price !== undefined) {
          priceType = `| ${fo[financeOption][0].pricingType}`;
          price = `$${fo[financeOption][0].price}`;
        }
      } else {
        if (fo[financeOption].default !== undefined) {
          val = fo[financeOption].default ? 'On' : 'Off';
        }
        if (fo[financeOption].moduleSeries !== undefined) {
          moduleTypes = fo[financeOption].moduleSeries;
        }
        if (fo[financeOption].paymentEscalator !== undefined) {
          paymentEscalator = fo[financeOption].paymentEscalator;
        }
      }
    });
    return (
      <div>
        <span>{foType}</span>
        {val && spanValue('| Default', val)}
        {price && spanValue(priceType, price)}
        {moduleTypes && spanValue('| Module Series', moduleTypes)}
        {paymentEscalator && spanValue('| Payment Escalator', paymentEscalator)}
        {product && spanValue(product, productPrice)}
      </div>
    );
  };

  const renderNonFOData = (entry) => {
    const activity = entry.activity;
    if (activity && activity.reset) {
      return (
        <tr className="body info-table">
          <td className="log-field bold">{entry.activity.reset}</td>
          <td className="timestamp">
            {moment(entry.createdAt).format('LT')} {moment(entry.createdAt).format('L')}
          </td>
        </tr>
      );
    }
    if (activity && activity.graphSelection) {
      return (
        <tr className="body info-table">
          <td className="log-field">
            Graph Selection:
            {Object.keys(activity.graphSelection).map((key, index) => {
              let val = activity.graphSelection[key] ? 'On' : 'Off';
              val += index + 1 !== Object.keys(activity.graphSelection).length ? ' | ' : '';
              return (
                <span> {titleCase(key)}: {val}</span>
              );
            })}
          </td>
          <td className="timestamp">
            {moment(entry.createdAt).format('LT')} {moment(entry.createdAt).format('L')}
          </td>
        </tr>
      );
    }
    const loanArrays = [];
    const ppaArrays = [];
    const cashArrays = [];
    const leaseArrays = [];
    let val = '';
    Object.keys(activity).forEach((key) => {
      const loanArray = activity[key].find((log) => log.loan);
      if (loanArray) { loanArrays.push(loanArray); }
      const ppaArray = activity[key].find((log) => log.ppa);
      if (ppaArray) { ppaArrays.push(ppaArray); }
      const cashArray = activity[key].find((log) => log.cash);
      if (cashArray) { cashArrays.push(cashArray); }
      const leaseArray = activity[key].find((log) => log.lease);
      if (leaseArray) { leaseArrays.push(leaseArray); }
    });
    if (loanArrays.length) {
      val = parseActivity(loanArrays, logLabelMapping.loan);
      return (
        <tr className="body info-table">
          <td className="log-field">{val}</td>
          <td className="timestamp">
            {moment(entry.createdAt).format('LT')} {moment(entry.createdAt).format('L')}
          </td>
        </tr>
      );
    }
    if (cashArrays.length) {
      val = parseActivity(cashArrays, logLabelMapping.cash);
      return (
        <tr className="body info-table">
          <td className="log-field">{val}</td>
          <td className="timestamp">
            {moment(entry.createdAt).format('LT')} {moment(entry.createdAt).format('L')}
          </td>
        </tr>
      );
    }
    if (leaseArrays.length) {
      val = parseActivity(leaseArrays, logLabelMapping.lease);
      return (
        <tr className="body info-table">
          <td className="log-field">{val}</td>
          <td className="timestamp">
            {moment(entry.createdAt).format('LT')} {moment(entry.createdAt).format('L')}
          </td>
        </tr>
      );
    }
    if (ppaArrays.length) {
      val = parseActivity(ppaArrays, logLabelMapping.ppa);
      return (
        <tr className="body info-table">
          <td className="log-field">{val}</td>
          <td className="timestamp">
            {moment(entry.createdAt).format('LT')} {moment(entry.createdAt).format('L')}
          </td>
        </tr>
      );
    }
    return ('');
  };


  const renderLogDataTable = (entry, index) => {
    if (index < 5 || showModal.length > 0) {
      return (
        <table className="history-table table">
          {entry.financeType ?
            <tr key={index} className="body info-table">
              <td className="log-field">
                {renderNewProperty('Finance Type', entry.financeType)}
                {renderNewProperty('| Option', entry.enabled)}
                {renderNewProperty('| Default', entry.defaulted)}
                {renderNewProperty('| Price', entry.price)}
                {renderNewProperty('| Payment Escalator', entry.paymentEscalator)}
                {renderNewProperty('| Default Loan', entry.defaultLoan)}
                {renderNewProperty('| Module Series', entry.moduleTypes)}
                {renderNewProperty('| Default Module', entry.defaultModule)}
                {renderNewProperty('| Product', entry.products)}
                {renderNewProperty('| Opted In', entry.optedIn)}
                {renderNewProperty('| Install Date', entry.installDays)}
                {renderNewProperty('| Custom Adder', entry.customAdders)}
                {renderNewProperty('| AdHocAdders', entry.adHocAddersEnabled)}
                {renderNewProperty('| State', entry.states)}
              </td>
              <td className="timestamp">
                {moment(entry.createdAt).format('LT')} {moment(entry.createdAt).format('L')}
              </td>
            </tr> : ''}
          {entry.activity ? renderNonFOData(entry, index) : ''}
        </table>
      );
    }
    return ('');
  };

  const rendorModal = (user) => {
    return (
      <Modal
        modals={prop.modals}
        name="history-log-modal"
        showCloseButtonOnly={true}
        closeModalAndRevertState={prop.closeModalAndRevertState}
      >
        <div>
          <table className="history-table table admin-table">
            <tr className="body border-bottom">
              <td className="user-name">{userModal.toUpperCase()}</td>
              <td className="close-button">
                <a href="#" onClick={() => { setShowModal([]); setUserModal(''); }}>X</a>
              </td>
            </tr>
          </table>
          {user.map((entry, index) => {
            return (
              <div className="table admin-table">{
                renderLogDataTable(entry, index)}
              </div>
            );
          })}
        </div>
      </Modal>
    );
  };

  return (
    <div className="admin-table-container">
      <div className="table-heading">
        <b>History log</b>
      </div>
      <div className="history-log-container">
        <table className="table admin-table">
          {Object.keys(historyLog).map((data) => {
            if (data !== 'paginationRange') {
              const userId = data;
              const user = Object.keys(historyLog[data])[0];
              const iconClass = activeLogUser === userId ? 'icon icon-chevron-up' : 'icon icon-chevron-down';
              return (
                <div>
                  <table className="history-table table admin-table">
                    <tr className="body border-bottom">
                      <td className="user-name">{user.toUpperCase()}</td>
                      <td className="view-changes">
                        <span className="label-align">VIEW CHANGES</span>
                        <i className={iconClass} onClick={() => onClickSetActive(userId)} />
                      </td>
                    </tr>
                  </table>
                  {historyLog[data][user].map((entry, index) => {
                    if (activeLogUser === userId) {
                      return (
                        renderLogDataTable(entry, index)
                      );
                    }
                    return ('');
                  })}
                  {activeLogUser === userId ? renderViewMoreButton(historyLog[data][user], user) : ''}
                  {showModal.length ? rendorModal(showModal) : ''}
                </div>
              );
            }
            return ('');
          })}
        </table>
      </div>
      {
        Boolean(historyLog.paginationRange) &&
        <HistoryLogPagination
          historyLog={historyLog}
          fetchHistoryLogs={prop.fetchHistoryLogs}
        />
      }
    </div>
  );
}

HistoryView.propTypes = {
  logData: PropTypes.arrayOf(PropTypes.object),
  toggleModal: PropTypes.func.isRequired,
  closeModalAndRevertState: PropTypes.func.isRequired,
  modals: modalsType.isRequired
};

export default register(
  [],
  [],
  HistoryView
);
