import React from 'react';
import PropTypes from 'prop-types';
import { Link as ReactLink } from 'react-router';
import pickBy from 'lodash/pickBy';
import { register } from '../../utils/redux';

const toQueryString = (params) =>
  Object.keys(pickBy(params))
    .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`)
    .join('&');

export function Link({ returnURL, to, ...remainingProps }) {
  const query = toQueryString({
    returnURL
  });

  const toWithQuery = query ? `${to}?${query}` : to;
  const newProps = { ...remainingProps, to: toWithQuery };
  return (
    <ReactLink {...newProps} />
  );
}

Link.propTypes = {
  returnURL: PropTypes.string,
  to: PropTypes.string.isRequired
};

export default register(
  ['returnURLSelector'],
  [],
  Link
);
