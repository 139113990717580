import { asyncPayload } from './apiHelpers';
import Api from '../utils/Api';

export function updateMatch(designSfid, design) {
  return {
    type: 'updateMatch',
    payload: asyncPayload({
      beforeRequest: {
        design: {
          paired: true
        }
      },
      request: Api.update(`designs/${designSfid}`, { design }),
      afterRequest: {
        design: {
          hasChanges: false
        }
      }
    })
  };
}
