const isPrd = () => window.location.hostname.search('eddie.mysunpower.com') >= 0;
const isInt = () => window.location.hostname.search('eddie-dev.mysunpower.com') === 0;
const isUat = () => window.location.hostname.search('eddie-uat.mysunpower.com') === 0;
const isQa = () => window.location.hostname.search('eddie-qa.mysunpower.com') === 0;
const isDevelopment = () =>
  window.location.hostname.search('localhost') === 0 ||
  window.location.hostname.search('127.0.0.1') === 0;

export default {
  isPrd,
  isQa,
  isInt,
  isUat,
  isDevelopment
};
