import u from 'updeep';
import { datadogRum } from '@datadog/browser-rum';
import get from 'lodash/get';

import { asyncPayload } from './apiHelpers';
import Api from '../utils/Api';
import { datadogTrackLoadingTime } from '../utils/datadogUserMonitoring';

import { setDrafSessionInLocalStorage } from '../utils/common';

function logDatadogTiming(startTime, response, firstLoad = false) {
  const loadingTime = Date.now() - startTime;
  const quote = response.quote;
  const settings = response.settings;
  const design = response.design;
  if (quote && quote.sfid && design && design.id) {
    datadogRum.addRumGlobalContext('quote', {
      sfid: quote.sfid,
      locked: !!quote.locked,
      type: design.isInstant ? 'instant' : design.designType,
      version: quote.version,
      firstLoad: !!firstLoad
    });
    datadogTrackLoadingTime(
      'Quote Load',
      loadingTime,
      quote,
      settings,
      null,
      null
    );
  }
}

export function fetchInstantQuoteQuote(quoteSfid, query = {}) {
  let state;
  const startTime = Date.now();
  let firstLoad = false;
  let quoteResponse = {};

  const promise = Api.fetchWithPooling(`instant_quote/quotes/${quoteSfid}/ready`, 3000).then((response) => {
    if (response.ready === true) {
      return Api.fetch(`quotes_status/${quoteSfid}`)
        .then((response) => {
          firstLoad = !response.present;

          return Api.fetchOrCreateDynamicQuote('instant_quote/quote', quoteSfid, Object.assign({
            id: quoteSfid
          }, query));
        });
    }
    return false;
  })
    .then((response) => {
      if (!get(response, 'quote.locked', false)) {
        setDrafSessionInLocalStorage();
      }
      if (!response || !response.settings || !response.settings.lease) {
        quoteResponse = Object.assign(response, {
          instantQuoteMode: true,
          instantQuoteDesignError: true
        });
      } else {
        quoteResponse = Object.assign(response, {
          instantQuoteMode: true,
          settings: {
            ...response.settings,
            lease: {
              ...response.settings.lease,
              fetchingFinEngAdders: response.settings.lease.adders,
              fetchingFinEngDiscount: response.settings.lease.discount,
              fetchingFinEngOverrideRebate: response.settings.lease.overrideRebate
            }
          }
        });
      }

      return Api.fetch(`design_optimization/?quote_sfid=${quoteSfid}`);
    })
    .then((schema) => {
      state = {
        schema
      };
      logDatadogTiming(startTime, quoteResponse, firstLoad);
      return u(quoteResponse, state);
    });
  return {
    type: 'fetchInstantQuoteQuote',
    payload: asyncPayload({
      beforeRequest: { loading: true },
      request: promise
    })
  };
}
