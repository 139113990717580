import React from 'react';
import ReactTooltip from 'react-tooltip';

export const moduleErrorDisplay = (moduleSeriesError, series) => {
  if (moduleSeriesError && Object.keys(moduleSeriesError).includes(series)) {
    return (
      <div>
        <a data-tip={true} data-event="click focus" data-for={`${series}-error`} ß={true}>
          <img src="/build/images/admin-info-icon-red.svg" className="info-icon-module" alt="info" />
        </a>
        <ReactTooltip
          id={`${series}-error`}
          globalEventOff="mouseover"
          event="click"
          place="bottom"
          type="light"
          clickable={true}
          effect="solid"
          multiline={true}
        >
          <p>
            <b>
              <p style={{ margin: 0 }}>This series module is </p>
              <p style={{ margin: 0 }}>not available in the following </p>
              {moduleSeriesError[series].length > 1 ? ' states' : ' state'}
            </b>
            <p>
              {
            moduleSeriesError[series].map((state, index) => {
              return (
                <div>
                  <span>{state}</span>
                </div>
              );
            })
          }
            </p>
          </p>
        </ReactTooltip>
      </div>
    );
  }
  return null;
};
