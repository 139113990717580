import intercom from './intercom';

const locationMap = [
  {
    title: 'Savings | EDDiE | SunPower',
    regex: /\/quote\/.*\/savings.*/,
    chatbot: true
  },
  {
    title: 'Design | EDDiE | SunPower',
    regex: /\/quote\/.*\/design.*/,
    chatbot: true
  },
  {
    title: 'Proposal | EDDiE | SunPower',
    regex: /\/quote\/.*\/proposal.*/
  },
  {
    // default
    title: 'EDDiE | SunPower',
    regex: /.*/
  }
];

export async function createHistoryListener(documentObj, browserHistory, store) {
  browserHistory.listen((location) => {
    window.scrollTo(0, 0);
    const locationEntry = locationMap.find(
      (entry) => location.pathname.match(entry.regex)
    );
    if (!locationEntry) return;
    // eslint-disable-next-line no-param-reassign
    documentObj.title = `${locationEntry.title}`;

    if (locationEntry.chatbot) {
      intercom.initializeIfNecessary(store);
    } else {
      intercom.hide();
    }
  });
}
