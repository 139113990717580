import React from 'react';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import { StorageOnlyDynamicProposal } from 'common-components';
import { register } from '../../../utils/redux';
import { calculatedRatings } from '../../../utils/StorageWithMultiInverters/storageWithMultiInverters';
import {
  accountType,
  soDynamicProposalType,
  soProposalDetailType,
  storageQuoteType,
  storageConfigurationType
} from '../../../types';
import { ActionButtonView } from './ActionButtonView';
import { ComplianceCheckModal } from './ComplianceCheckModal';
import { TitleCheckModal } from './TitleCheckModal';
import { calculatedEvChargerSize, isEvChargerSelected } from '../../../utils/storageQuote/evChargers';

const DynamicProposalPage = ({ storageQuote, constantValues, account, updateSoProposal,
  soProposalDetails, toggleSoComplianceCheckModal, toggleSoTitleCheckModal,
  soDynamicProposal, updateSoProposalSentVia, fetchSoTitleCheck, sendSoComplianceCheck,
  setSoAcknowledgeIdentityOfCustomer, setSoAcknowledgeNotifiedToCustomer,
  sendSoTitleCheck, storageConfigurations }) => {
  const currentStorageConfiguration = storageConfigurations.find(
    (storage) => storage.storageConfigId === storageQuote.storageConfigId);
  const storageRatings = calculatedRatings(constantValues,
    storageQuote.storageQuantity, storageQuote.storageExpansionPackQuantity, currentStorageConfiguration);
  const showComplianceCheckModal = soDynamicProposal.showComplianceCheckModal;
  const evChargerSelected = isEvChargerSelected(storageQuote);
  const evChargerSize = calculatedEvChargerSize(storageQuote, constantValues);
  const isPDFProcessing = get(account, 'isPDFProcessing');

  return (
    <div className="storage-dynamic-proposal-page">
      <StorageOnlyDynamicProposal
        storageName={'SunVault'}
        isPDFProcessing={isPDFProcessing}
        energyRating={get(storageRatings, 'energyRating', 0)}
        powerRating={get(storageRatings, 'powerRating', 0)}
        systemCost={storageQuote.systemCost}
        systemCostWithoutEv={storageQuote.systemCostWithoutEv}
        evChargerCost={storageQuote.totalEvCost}
        totalCost={storageQuote.systemCost}
        utilityName={account.utilityDescription}
        utilityRatePlanName={account.proposedRate}
        evChargerSelected={evChargerSelected}
        evChargerSize={evChargerSize}
        ledgerActionButton={ActionButtonView({
          storageQuote,
          updateSoProposal,
          soProposalDetails,
          toggleSoComplianceCheckModal,
          toggleSoTitleCheckModal,
          showComplianceCheckModal,
          soDynamicProposal,
          updateSoProposalSentVia,
          account,
          fetchSoTitleCheck
        })}
      />
      <ComplianceCheckModal
        storageQuote={storageQuote}
        showSoComplianceCheckModal={soDynamicProposal.showComplianceCheckModal}
        setSoAcknowledgeIdentityOfCustomer={setSoAcknowledgeIdentityOfCustomer}
        setSoAcknowledgeNotifiedToCustomer={setSoAcknowledgeNotifiedToCustomer}
        toggleSoComplianceCheckModal={toggleSoComplianceCheckModal}
        acknowledgeIdentityOfCustomer={soDynamicProposal.acknowledgeIdentityOfCustomer}
        acknowledgeNotifiedToCustomer={soDynamicProposal.acknowledgeNotifiedToCustomer}
        soDynamicProposal={soDynamicProposal}
        soProposalDetails={soProposalDetails}
        soProposalStatus={soDynamicProposal.status}
        sendSoComplianceCheck={sendSoComplianceCheck}
        account={account}
      />
      <TitleCheckModal
        storageQuote={storageQuote}
        account={account}
        showSoTitleCheckModal={soDynamicProposal.showTitleCheckModal}
        toggleSoTitleCheckModal={toggleSoTitleCheckModal}
        sendSoTitleCheck={sendSoTitleCheck}
        soDynamicProposal={soDynamicProposal}
      />
    </div>);
};

DynamicProposalPage.propTypes = {
  storageQuote: storageQuoteType.isRequired,
  account: accountType.isRequired,
  constantValues: PropTypes.arrayOf(PropTypes.number),
  updateSoProposal: PropTypes.func.isRequired,
  toggleSoComplianceCheckModal: PropTypes.func.isRequired,
  setSoAcknowledgeIdentityOfCustomer: PropTypes.func.isRequired,
  setSoAcknowledgeNotifiedToCustomer: PropTypes.func.isRequired,
  soProposalDetails: soProposalDetailType,
  toggleSoTitleCheckModal: PropTypes.func.isRequired,
  soDynamicProposal: soDynamicProposalType.isRequired,
  updateSoProposalSentVia: PropTypes.func.isRequired,
  sendSoComplianceCheck: PropTypes.func.isRequired,
  fetchSoTitleCheck: PropTypes.func.isRequired,
  storageConfigurations: PropTypes.arrayOf(storageConfigurationType).isRequired,
  sendSoTitleCheck: PropTypes.func.isRequired
};

export default register(
  [ 'storageQuoteSelector', 'constantValuesSelector', 'accountSelector',
    'soProposalDetailsSelector', 'soDynamicProposalSelector', 'storageConfigurationsSelector' ],
  [ 'updateSoProposal', 'toggleSoComplianceCheckModal', 'setSoAcknowledgeIdentityOfCustomer',
    'setSoAcknowledgeNotifiedToCustomer', 'toggleSoTitleCheckModal', 'updateSoProposalSentVia',
    'sendSoComplianceCheck', 'fetchSoTitleCheck', 'sendSoTitleCheck' ],
  DynamicProposalPage
);
