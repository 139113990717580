import d3 from 'd3';
import round from 'lodash/round';
import makeScaledTicks, { getMaxLeastMax } from '../makeScaledTicks';

// Must be > 1.
const nonSavingsToSavingsThresholdRatio = 1.25;

const makeDataColumns = (graphSeries, scale) => ([
  [ 'savings', ...graphSeries.savings.map(scale) ],
  [ 'costs', ...graphSeries.costs.map(scale) ],
  [ 'rebatesAndCredits', ...graphSeries.rebatesAndCredits.map(scale) ]
]);

/**
 * makeScaledC3Options will create C3 chart options
 * that scale the chart y-axis so that it is no larger than
 * nonSavingsToSavingsThresholdRatio times the maximum savings
 * amount. In such a case, non-savings value above the threshold
 * will be scaled compactly at the top of the graph.
 * If scaling is not necessary it will return empty options.
 * @param {Object} graphSeries
 * @param {string} chartSelector d3 selector for svg for breakmark post-processing.
 * @param {number} nYAxisTicks Number y-axis tick marks on savings scaled
 * graph.
 * @returns {Object} Empty or scaled C3 chart options.
 */
const makeScaledC3Options = (graphSeries, chartSelector, nYAxisTicks) => {
  const costs = graphSeries.costs || [];
  const rebatesAndCredits = graphSeries.rebatesAndCredits || [];

  const seriesValues = [ costs, graphSeries.savings, rebatesAndCredits ];
  const [ mostMax, leastMax ] = getMaxLeastMax(seriesValues);
  if (mostMax / leastMax <= nonSavingsToSavingsThresholdRatio) {
    return {
      grid: { y: { lines: [] } }
    };
  }

  const { scale, max, ticks, breakValue } = makeScaledTicks(seriesValues, {
    nTickMarks: nYAxisTicks,
    maxBrokenHeightRatio: nonSavingsToSavingsThresholdRatio
  });
  const columns = makeDataColumns(graphSeries, scale);
  const axis = {
    y: {
      min: 0,
      max,
      tick: {
        format: (value) => d3.format('$,')(round(scale.invert(value), -2)),
        values: ticks
      }
    }
  };
  const grid = {
    y: {
      lines: [{
        value: scale(breakValue),
        class: 'savings-chart-break-line'
      }]
    }
  };
  const data = { columns };
  return {
    axis,
    data,
    grid
  };
};

export default makeScaledC3Options;
