import Api from '../../utils/Api';
import { asyncPayload } from '../apiHelpers';

export const uploadPricingFile = (rawFile, parsed, afterUpload) => (dispatch) => {
  const formData = new FormData();
  formData.append('file', rawFile);
  const requestPath = 'admin_portals/upload_pricing_file';

  const promise = Api.adminPortalPostFile(requestPath, formData)
  .then((response) => {
    afterUpload(rawFile, parsed, response);
    return response.data;
  });

  dispatch({
    type: 'uploadPricingFile',
    payload: asyncPayload({
      request: promise
    })
  });
};
