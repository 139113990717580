import cx from 'classnames';
import React from 'react';
import PropTypes from 'prop-types';
import withValidation from './withValidation';

function Select({
    name,
    value,
    children,
    className,
    disabled = false,
    onChange,
    showErrors,
    fieldErrors,
    hasErrors
  }) {
  const classes = cx('input-container', 'input-group', className, { 'has-error': hasErrors });

  return (
    <div className={classes}>
      <select
        name={name}
        id={name}
        value={value}
        disabled={disabled}
        onChange={onChange}
      >
        {children}
      </select>

      {showErrors && hasErrors ?
        <div className="input__error-messages">
          { fieldErrors.map((error, key) => <span key={key}>{error}</span>) }
        </div> : null
      }
    </div>
  );
}
Select.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([ PropTypes.string, PropTypes.number ]).isRequired,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  children: PropTypes.arrayOf(PropTypes.element).isRequired,
  validators: PropTypes.arrayOf(PropTypes.func).isRequired,
  onChange: PropTypes.func.isRequired,
  showErrors: PropTypes.bool,
  hasErrors: PropTypes.bool,
  fieldErrors: PropTypes.arrayOf(PropTypes.string)
};

export default withValidation(Select);
