import React, { useEffect } from 'react';
import get from 'lodash/get';
import { camelCase } from 'lodash';
import PropTypes from 'prop-types';
import { DynamicProposal } from 'common-components';
import isEmpty from 'lodash/isEmpty';
import { register } from '../../utils/redux';
import {
  accountType,
  allSystemCostsType,
  chartTermType,
  creditType,
  designType,
  dynamicProposalType,
  energyDataType,
  productConfigurationType,
  proposalDetailType,
  proposalType,
  pvModuleType,
  quoteType,
  savingsChartType,
  selectedStorageType,
  selectedStorageWithExpansionPackType,
  storageSelectionModalType,
  totalSavingsChartType,
  userDataType,
  settingsType
} from '../../types';
import { isStorageSelected, getStorageSize } from '../../utils/storage';
import { enabledPanelCount, getDegradation } from '../../utils/EddieCalculations/design';
import constants from '../../utils/EddieCalculations/constants';
import { CASH, LEASE, LOAN, PPA } from '../../utils/EddieCalculations/helpers/financeTypes';
import { ActionButtonView } from './ActionButtonView';
import { BeginCreditAppView } from './BeginCreditAppView';
import { ComplianceCheckModal } from './ComplianceCheckModal';
import { TitleCheckModal } from './TitleCheckModal';
import { CreditAppModal } from './CreditAppModal';
import { calculatedEvChargerSize, isEvChargerSelected } from '../../utils/EvChargers/evChargers';
import { enableCreditAppInEddie, enableInstallDate } from '../../utils/backendFeatureFlags';

const getTermForSavingsGraph = (selectedFinanceOption, allSystemCosts) => {
  const selectedFinOption = camelCase(selectedFinanceOption);
  switch (selectedFinOption) {
    case CASH:
      return null;
    case LEASE:
      return get(allSystemCosts, `${selectedFinOption}.leaseTerm`, null);
    case PPA:
      return get(allSystemCosts, `${selectedFinOption}.ppaTerm`, null);
    case LOAN:
      return get(allSystemCosts, `${selectedFinOption}.termInMonths`)
        ? get(allSystemCosts, `${selectedFinOption}.termInMonths`) / 12 : null;
    default:
      return null;
  }
};

const {
  NY_DEFAULT_INFLATION_RATE,
  ALL_STATE_DEFAULT_INFLATION_RATE,
  SOURCE,
  SUNPOWER
} = constants;

const DynamicProposalPage = ({ quote, productConfiguration, storageSelectionModal,
  selectedStorageWithExpansionPack, selectedStorage, design, pvModule, energyData, account,
  inflationRate, allSystemCosts, savingsChart, totalSavingsChart, graphLoading, fetchGraphs,
  toggleEscalatorDropdown, updateInflationRate, isSpecialState, updateProposal, proposalDetails,
  isV2CalculationState, partnerIsRvar, toggleComplianceCheckModal, setAcknowledgeIdentityOfCustomer,
  setAcknowledgeNotifiedToCustomer, updateProposalSentVia, dynamicProposal, defaultChartTab,
  toggleTitleCheckModal, sendComplianceCheck, chartTerm, sendTitleCheck, fetchTitleCheck, credit,
  getCreditAppUrl, showCreditAppModal, toggleCreditAppModal, creditAppUrl, constantValues,
  updateSalesforceQuote, updateCreditApp, userData, settings, updateUtilitySavingsType, utilitySavingsType
}) => {
  const storageSelected = isStorageSelected(storageSelectionModal,
    quote, selectedStorageWithExpansionPack, selectedStorage);
  const selectedStorageQuantity = get(quote, 'storageQuantity', 0);
  const selectedPvModule = quote.locked ? quote.lockedPvModuleItem : pvModule;
  const inflationRateSelectOptionsForTotalSavings = isEmpty(totalSavingsChart) ? [] : Object.keys(totalSavingsChart);
  const showComplianceCheckModal = dynamicProposal.showComplianceCheckModal;
  const termForSavingsGraph = getTermForSavingsGraph(quote.selectedFinanceOption, allSystemCosts);
  const evChargerSelected = isEvChargerSelected(quote);
  const evChargerSize = calculatedEvChargerSize(quote, constantValues);

  useEffect(() => {
    if (account.state === 'NY') {
      toggleEscalatorDropdown(NY_DEFAULT_INFLATION_RATE);
      updateInflationRate(quote, { inflationRate: NY_DEFAULT_INFLATION_RATE });
    } else {
      toggleEscalatorDropdown(ALL_STATE_DEFAULT_INFLATION_RATE);
      updateInflationRate(quote, { inflationRate: ALL_STATE_DEFAULT_INFLATION_RATE });
    }
    if (isEmpty(savingsChart) || isEmpty(totalSavingsChart) && !graphLoading) fetchGraphs(quote.sfid);
  }, []);

  const batterySize = getStorageSize(quote, selectedStorageQuantity);
  const degradationRate = getDegradation(pvModule);

  const proposalStatus = dynamicProposal.status;
  const installDate = get(settings[quote.selectedFinanceOption], 'installDays');
  const graphSelections = quote.graphSelections ? quote.graphSelections : {
    savingsOverTime: true,
    totalSavings: true,
    electricityBillSavings: false
  };
  const toggleUtilitySavingsType = (savingsType) => {
    updateUtilitySavingsType(savingsType);
  };
  const isPerKwh = () => {
    return utilitySavingsType === 'perKWH';
  };

  return (
    <div className="dynamic-proposal-page">
      <DynamicProposal
        chartTerm={chartTerm}
        contractorLicenseNumber={get(productConfiguration, 'contractorLicenseNumber')}
        storageSelected={storageSelected}
        selectedStorageQuantity={selectedStorageQuantity}
        evChargerSelected={evChargerSelected}
        evChargerSize={evChargerSize}
        energyOffset={get(energyData, 'energyOffset')}
        backSheetType={get(selectedPvModule, 'item.moduleBackSheetType')}
        storageName={get(quote, 'storageName')}
        storageSize={batterySize}
        installDate={installDate}
        showInstallDate={enableInstallDate() && partnerIsRvar && get(settings[quote.selectedFinanceOption], 'optedIn')}
        systemSize={get(design, 'systemSize')}
        numberOfPanels={enabledPanelCount(design)}
        purchasedAnnualKwh={get(energyData, 'annualKwh.purchased')}
        totalAnnualKwh={get(energyData, 'year1Production')}
        systemDesignImgUrl={quote.systemDesignImgUrl}
        systemDesignBase64Img={quote.systemDesignBase64Img}
        monthlyEnergy={get(design, 'simEng.monthlyEnergy')}
        annualEnergy={get(design, 'simEng.annualEnergy')}
        totalEnergyProduction={get(design, 'totalEnergyProduction')}
        isSpecialState={isSpecialState}
        state={account.state}
        selectedFinanceOption={quote.selectedFinanceOption}
        selectedInflationRate={inflationRate}
        graphSelections={graphSelections}
        ledger={allSystemCosts[quote.selectedFinanceOption]}
        savingsChart={savingsChart}
        totalSavingsChart={totalSavingsChart}
        inflationRateSelectOptions={inflationRateSelectOptionsForTotalSavings}
        energyData={energyData}
        batteryManufacturer={quote.batteryManufacturer}
        batterySize={batterySize}
        batteryWarranty={quote.batteryWarranty}
        backupCapable={quote.backupCapable}
        readOnlyInflationRate={true}
        utilityName={account.utilityDescription}
        utilityRate={account.utilityRate}
        utilityRatePlanName={account.proposedRate}
        term={termForSavingsGraph}
        degradationRate={degradationRate}
        ledgerActionButton={ActionButtonView({
          quote,
          updateProposal,
          proposalDetails,
          toggleComplianceCheckModal,
          toggleTitleCheckModal,
          showComplianceCheckModal,
          dynamicProposal,
          updateProposalSentVia,
          account,
          fetchTitleCheck })}
        isV2CalculationState={isV2CalculationState}
        source={SOURCE}
        defaultChartTab={defaultChartTab}
        beginCreditAppView={
          BeginCreditAppView({
            credit,
            quote,
            getCreditAppUrl,
            toggleCreditAppModal,
            updateCreditApp,
            account,
            userData
          })
        }
        showCreditAppView={false}
        toggleUtilitySavingsType={toggleUtilitySavingsType}
        isPerKwh={isPerKwh}
      />
      <ComplianceCheckModal
        quote={quote}
        showComplianceCheckModal={dynamicProposal.showComplianceCheckModal}
        setAcknowledgeIdentityOfCustomer={setAcknowledgeIdentityOfCustomer}
        setAcknowledgeNotifiedToCustomer={setAcknowledgeNotifiedToCustomer}
        toggleComplianceCheckModal={toggleComplianceCheckModal}
        acknowledgeIdentityOfCustomer={dynamicProposal.acknowledgeIdentityOfCustomer}
        acknowledgeNotifiedToCustomer={dynamicProposal.acknowledgeNotifiedToCustomer}
        dynamicProposal={dynamicProposal}
        proposalDetails={proposalDetails}
        proposalStatus={proposalStatus}
        sendComplianceCheck={sendComplianceCheck}
        account={account}
      />
      <TitleCheckModal
        quote={quote}
        account={account}
        showTitleCheckModal={dynamicProposal.showTitleCheckModal}
        toggleTitleCheckModal={toggleTitleCheckModal}
        sendTitleCheck={sendTitleCheck}
        dynamicProposal={dynamicProposal}
      />
      <CreditAppModal
        quote={quote}
        showCreditAppModal={showCreditAppModal}
        toggleCreditAppModal={toggleCreditAppModal}
        creditAppUrl={creditAppUrl}
        updateSalesforceQuote={updateSalesforceQuote}
      />
    </div>);
};

DynamicProposalPage.propTypes = {
  quote: quoteType.isRequired,
  settings: settingsType.isRequired,
  productConfiguration: productConfigurationType.isRequired,
  selectedStorage: selectedStorageType.isRequired,
  storageSelectionModal: storageSelectionModalType.isRequired,
  selectedStorageWithExpansionPack: selectedStorageWithExpansionPackType.isRequired,
  design: designType.isRequired,
  energyData: energyDataType.isRequired,
  pvModule: pvModuleType.isRequired,
  account: accountType.isRequired,
  inflationRate: PropTypes.string.isRequired,
  allSystemCosts: allSystemCostsType.isRequired,
  savingsChart: savingsChartType,
  totalSavingsChart: totalSavingsChartType,
  graphLoading: PropTypes.bool.isRequired,
  fetchGraphs: PropTypes.func.isRequired,
  updateInflationRate: PropTypes.func.isRequired,
  toggleEscalatorDropdown: PropTypes.func.isRequired,
  isSpecialState: PropTypes.bool.isRequired,
  proposal: proposalType.isRequired,
  isPublic: PropTypes.bool,
  updateProposal: PropTypes.func.isRequired,
  proposalDetails: proposalDetailType,
  isV2CalculationState: PropTypes.bool.isRequired,
  defaultChartTab: PropTypes.string,
  toggleComplianceCheckModal: PropTypes.func.isRequired,
  setAcknowledgeIdentityOfCustomer: PropTypes.func.isRequired,
  setAcknowledgeNotifiedToCustomer: PropTypes.func.isRequired,
  updateProposalSentVia: PropTypes.func.isRequired,
  dynamicProposal: dynamicProposalType.isRequired,
  toggleTitleCheckModal: PropTypes.func.isRequired,
  sendComplianceCheck: PropTypes.func.isRequired,
  chartTerm: chartTermType,
  sendTitleCheck: PropTypes.func.isRequired,
  fetchTitleCheck: PropTypes.func.isRequired,
  credit: creditType,
  partnerIsRvar: PropTypes.bool,
  getCreditAppUrl: PropTypes.func,
  showCreditAppModal: PropTypes.bool,
  toggleCreditAppModal: PropTypes.func,
  creditAppUrl: PropTypes.string,
  constantValues: PropTypes.arrayOf(PropTypes.number),
  updateSalesforceQuote: PropTypes.func.isRequired,
  updateCreditApp: PropTypes.func,
  userData: userDataType,
  updateUtilitySavingsType: PropTypes.func,
  utilitySavingsType: PropTypes.string
};

export default register(
  [
    'quoteSelector', 'productConfigurationSelector', 'storageSelectionModalSelector',
    'selectedStorageSelector', 'selectedStorageWithExpansionPackSelector', 'settingsSelector',
    'designSelector', 'energyDataSelector', 'pvModuleSelector', 'accountSelector',
    'inflationRateSelector', 'allSystemCostsSelector', 'savingsChartSelector', 'totalSavingsChartSelector',
    'graphLoadingSelector', 'isSpecialStateSelector',
    'proposalDetailsSelector', 'isV2CalculationState', 'dynamicProposalSelector', 'defaultChartTabSelector',
    'chartTermSelector', 'creditSelector', 'showCreditAppModalSelector', 'creditAppUrlSelector',
    'constantValuesSelector', 'userDataSelector', 'partnerIsRvarSelector', 'utilitySavingsTypeSelector'
  ],
  [
    'fetchGraphs', 'toggleEscalatorDropdown', 'updateInflationRate', 'updateProposal',
    'toggleComplianceCheckModal', 'setAcknowledgeIdentityOfCustomer',
    'setAcknowledgeNotifiedToCustomer', 'updateProposalSentVia', 'toggleTitleCheckModal', 'sendTitleCheck',
    'sendComplianceCheck', 'fetchTitleCheck', 'getCreditAppUrl', 'toggleCreditAppModal',
    'updateSalesforceQuote', 'updateCreditApp', 'updateUtilitySavingsType'
  ],
  DynamicProposalPage
);
