import PropTypes from 'prop-types';
import { pathType } from './path';
import { moduleType } from './module';

export const roofType = PropTypes.shape({
  roofPlanes: PropTypes.arrayOf(pathType),
  moduleArray: PropTypes.objectOf(PropTypes.arrayOf(moduleType)),
  dimensions: PropTypes.shape({
    width: PropTypes.number.isRequired,
    height: PropTypes.number.isRequired
  }).isRequired
});
