import { get } from 'lodash';
import { asyncPayload } from './apiHelpers';
import Api from '../utils/Api';
import logger from '../utils/logger';

export function getCreditAppUrl(quoteSfid) {
  const promise = Api.create(`credit_app_request/?quote_sfid=${quoteSfid}`)
  .then((result) => {
    return {
      creditAppUrl: get(result, 'data.activationURL'),
      showCreditAppModal: true
    };
  })
  .catch((err) => {
    logger.warn(err);
    return {
      showCreditAppModal: false
    };
  });

  return {
    type: 'getCreditAppUrl',
    payload: asyncPayload({
      request: promise
    })
  };
}
