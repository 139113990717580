export function updateGraphSelection(title, value) {
  return {
    type: 'updateGraphSelection',
    payload: {
      graphSelections: {
        [title]: value
      }
    }
  };
}
