import PropTypes from 'prop-types';

const adPolygonType = PropTypes.shape({
  type: PropTypes.string.isRequired,
  coordinates: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.number)))
});

const adShapeParamsType = PropTypes.shape({
  x: PropTypes.number,
  y: PropTypes.number,
  version: PropTypes.number,
  points: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.number)),
  holes: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.number))),
  radius: PropTypes.number,
  type: PropTypes.string.isRequired,
  visible: PropTypes.bool,
  selected: PropTypes.bool,
  locked: PropTypes.bool,
  center: PropTypes.arrayOf(PropTypes.number),
  pointsRotationInRadians: PropTypes.arrayOf(PropTypes.number),
  polygonGeometry: adPolygonType,
  invalid: PropTypes.bool,
  rotationInDegrees: PropTypes.number,
  orientation: PropTypes.string,
  errorType: PropTypes.string
});

export const adSolarPanelType = PropTypes.shape({
  id: PropTypes.string.isRequired,
  roof_id: PropTypes.string,
  source_id: PropTypes.string,
  shape: adShapeParamsType,
  type: PropTypes.string.isRequired,
  azimuth: PropTypes.number,
  tilt: PropTypes.number,
  active: PropTypes.bool,
  production: PropTypes.objectOf(PropTypes.number)
});
