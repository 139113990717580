function toggleDropdown(dropdownName) {
  return {
    type: 'toggleDropdown',
    payload: {
      dropdowns: (currentState) => (currentState === dropdownName ? null : dropdownName)
    }
  };
}

export function toggleModulesDropdown() {
  return toggleDropdown('moduleList');
}

export function toggleMonitoringConfigurationDropdown() {
  return toggleDropdown('monitoringConfiguration');
}

export function toggleStoragesDropdown() {
  return toggleDropdown('storageList');
}

export function toggleStorageQuantityDropdown() {
  return toggleDropdown('storageQuantity');
}

export function toggleInverterQuantityDropdown() {
  return toggleDropdown('inverterQuantity');
}

export function toggleStorageListWithMultiInverterDropdown() {
  return toggleDropdown('storageListWithMultiInverter');
}

export function toggleStorageQuantityWithMultiInverterDropdown() {
  return toggleDropdown('storageQuantityWithMultiInverter');
}

export function toggleExpansionPackQuantityDropdown() {
  return toggleDropdown('expansionPackQuantity');
}

export function toggleEvChargerListDropdown() {
  return toggleDropdown('evChargerList');
}

export function toggleEvChargerQuantityDropdown() {
  return toggleDropdown('evChargerQuantity');
}

export function toggleEvOutletListDropdown() {
  return toggleDropdown('evOutletList');
}

export function toggleEvOutletQuantityDropdown() {
  return toggleDropdown('evOutletQuantity');
}

export function toggleDesignsListDropdown() {
  return toggleDropdown('designList');
}
