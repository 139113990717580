import PropTypes from 'prop-types';

export const evChargerSelectionModalType = PropTypes.shape({
  selectedWallBoxQuantity: PropTypes.number,
  selectedOutletQuantity: PropTypes.number,
  evChargers: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    type: PropTypes.string
  }))
});
