export const allSystemCostsSelector = (state) => ({
  allSystemCosts: state.allSystemCosts
});
export const cashSystemCostSelector = (state) => ({
  cashSystemCost: state.allSystemCosts.cash
});
export const leaseSystemCostSelector = (state) => ({
  leaseSystemCost: state.allSystemCosts.lease
});
export const ppaSystemCostSelector = (state) => ({
  ppaSystemCost: state.allSystemCosts.ppa
});
export const loanSystemCostSelector = (state) => ({
  loanSystemCost: state.allSystemCosts.loan
});
export const spwrCashSystemCostSelector = (state) => ({
  spwrCashSystemCost: state.allSystemCosts.spwrCash
});
export const mortgageSystemCostSelector = (state) => ({
  mortgageSystemCost: state.allSystemCosts.mortgage
});
