import { cloneDeep } from 'lodash';
import { findApFinConfigIndexById } from '../../utils/admin_portal/updateHelpers';

/**
 *
 * @param {string} financeType
 * @param {object} key
 * @param {object} val
 * @returns updated state
 */
export const updateApPaymentConfig = (configId, key, val) => (dispatch, getState) => {
  const currentState = getState();
  const apFinConfigsToUpdate = cloneDeep(currentState.apFinConfigs);
  const finConfigIndex = findApFinConfigIndexById(apFinConfigsToUpdate, configId);

  if (finConfigIndex > -1) {
    if (apFinConfigsToUpdate[finConfigIndex].paymentConfigAttributes) {
      apFinConfigsToUpdate[finConfigIndex].paymentConfigAttributes[key] = val;
    } else {
      apFinConfigsToUpdate[finConfigIndex].paymentConfigAttributes = {
        [key]: val
      };
    }
  }

  dispatch({
    type: 'updateApPaymentConfig',
    payload: {
      apFinConfigs: apFinConfigsToUpdate
    }
  });
};
