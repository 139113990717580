import get from 'lodash/get';
import { datadogTrackLoadingTime } from '../utils/datadogUserMonitoring';
import visualizationToImage from '../utils/visualizationToImage';
import { asyncPayload } from './apiHelpers';
import store from '../store';
import Api from '../utils/Api';
import { isPDFProcessing } from '../actions';

export function lockDynamicQuote(quote, selectedFinanceOption) {
  const startTime = Date.now();

  const promise = visualizationToImage()
    .then((dataUri) =>
      Api.lockQuoteWithPolling(`quotes/${quote.sfid}/lock`, {
        quote: {
          visualizationImage: dataUri,
          selectedFinanceOption
        }
      }, `quotes/${quote.sfid}`)
    );

  return (dispatch) => {
    const response = dispatch({
      type: 'lockDynamicQuote',
      payload: asyncPayload({
        beforeRequest: {
          lockingQuote: true
        },
        request: promise,
        afterRequest: {
          lockingQuote: false
        },
        errorRequest: {
          lockingQuote: false
        }
      })
    });

    response.then((data) => {
      const noError = !get(data, 'value.error', null);
      if (noError) {
        const locked = get(data, 'value.quote.locked', null);
        if (locked && noError) store.dispatch(isPDFProcessing(true));
        if (!quote.isInstantQuote && locked) {
          // tracking only successful loading time and intended for dynamic quote only
          const loadingTime = Date.now() - startTime;
          datadogTrackLoadingTime(
            'Lock Quote',
            loadingTime,
            quote,
            null,
            null,
            null,
            { fin_option_locked: selectedFinanceOption }
          );
        }
      }
      return data;
    });
  };
}
