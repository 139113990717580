import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import cx from 'classnames';
import { financeType } from '../../types/settings';
import { settingsType } from '../../types';

export default function ShowCustomerCheckbox({
  isSavingsPage,
  isQuoteLocked,
  financeTypes,
  activeSettingsTab,
  settings,
  onChange
}) {
  const checkedCount = financeTypes.reduce((count, financeType) => {
    return get(settings, `${financeType}.showCustomer`, false) ? count + 1 : count;
  }, 0);
  const forceCheck = (checkedCount === 1) && activeSettingsTab.showCustomer;
  const isDisabled = isSavingsPage || isQuoteLocked;

  const forceCheckbox = (
    <label>
      <input
        type="checkbox"
        checked={activeSettingsTab.showCustomer}
        onChange={onChange}
        disabled={true}
      />
      <span>Show customer this option (check other option first to uncheck this one)</span>
    </label>
  );

  const normalCheckbox = (
    <label>
      <input
        type="checkbox"
        checked={activeSettingsTab.showCustomer}
        onChange={onChange}
        disabled={isDisabled}
      />
      <span>Show customer this option</span>
    </label>
  );

  const cssClasses = cx('checkbox customer-option', { 'is-disabled': isDisabled });
  let tooltip = '';
  if (isSavingsPage) {
    tooltip = 'To Add/Remove finance options please go back to the design page and select the settings icon.';
  } else if (isQuoteLocked) {
    tooltip = 'You cannot change finance options when quote is locked.';
  }

  return (
    <div className={cssClasses} data-tip={tooltip}>
      {
        isDisabled
          ? normalCheckbox
          : forceCheck ? forceCheckbox : normalCheckbox
      }
    </div>
  );
}
ShowCustomerCheckbox.propTypes = {
  isSavingsPage: PropTypes.bool,
  isQuoteLocked: PropTypes.bool,
  financeTypes: PropTypes.arrayOf(PropTypes.string).isRequired,
  activeSettingsTab: financeType.isRequired,
  settings: settingsType.isRequired,
  onChange: PropTypes.func.isRequired
};
