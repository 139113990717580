import { cloneDeep } from 'lodash';

/* eslint no-underscore-dangle: 0 */
export const updateOptionalAddersSelection = (
  setting,
  financeType,
  adderId,
  key,
  val,
  oldAdderPrice,
  optionalAddersFromAp = []) => (dispatch, _) => {
    const optionalAddersToUpdate = cloneDeep(setting.optionalAddersAttributes);
    const adder = optionalAddersToUpdate.find((opAdder) =>
    opAdder.adderId === adderId || opAdder.id === adderId || opAdder.adderName === adderId);

    if (adder) {
      const value = key === 'adderPrice' && !(val) ? '' : val;
      const adderKey = key === 'deleteAdder' ? '_destroy' : key;
      adder[adderKey] = value;
      adder.adderPrice = adder.adderPrice || 0;
    } else if (optionalAddersFromAp.length > 0) {
      const opAdder = optionalAddersFromAp.find((opAdder) =>
      opAdder.adderId === adderId || opAdder.id === adderId || opAdder.adderName === adderId);
      optionalAddersToUpdate.push({
        adderId: opAdder.id,
        adderName: opAdder.adderName,
        adderPrice: opAdder.adderPrice,
        adderType: 'optional',
        isLive: val
      });
    } else if (adderId) {
      const newAdder = {};
      newAdder.adderId = adderId;
      newAdder[key] = val;
      newAdder.adderType = 'adhoc';
      optionalAddersToUpdate.push(newAdder);
    }

    const optionAddersTotalCost = optionalAddersToUpdate.filter((optionalAdders) =>
      optionalAdders._destroy !== true && optionalAdders.isLive).reduce((sum, adder) => sum + adder.adderPrice, 0);
    const totalCost = optionAddersTotalCost ? optionAddersTotalCost + oldAdderPrice : oldAdderPrice;

    dispatch({
      type: 'updateOptionalAddersSelection',
      payload: {
        settings: {
          [financeType]: {
            optionalAddersAttributes: optionalAddersToUpdate,
            adders: totalCost
          }
        }
      }
    });
  };
