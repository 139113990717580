import AbortController from 'abort-controller';

export function cancelFetchFinEng(controller) {
  if (controller && controller instanceof AbortController) {
    controller.abort();
  }
  return {
    type: 'cancelFetchFinEng',
    payload: {
      settings: {
        lease: {
          needsFinEng: false,
          fetchingFinEng: false
        }
      }
    }
  };
}
