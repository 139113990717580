import { orderBy } from 'lodash';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { register } from '../../utils/redux';
import { mortgageEnabled } from '../../utils/backendFeatureFlags';
import constants from '../../utils/EddieCalculations/constants';

const {
  CASH_FINANCE_TYPE,
  LOAN_FINANCE_TYPE,
  LEASE_FINANCE_TYPE,
  PPA_FINANCE_TYPE,
  SPWR_CASH_FINANCE_TYPE,
  MORTGAGE_FINANCE_TYPE_LABEL,
  CASH_FINANCE_TYPE_LABEL,
  SPWR_FINANCE_TYPE_LABEL,
  LOAN_FINANCE_TYPE_LABEL,
  LEASE_FINANCE_TYPE_LABEL,
  PPA_FINANCE_TYPE_LABEL
} = constants;

export function FinanceOptionsDropdown({
  selectedOption,
  toggle,
  settingsShowCashChecked = false,
  settingsShowLeaseChecked = false,
  settingsShowPpaChecked = false,
  settingsShowLoanChecked = false,
  settingsShowSpwrCashChecked = false,
  financialOfferings = {
    cash: false, lease: false, ppa: false, loan: false, spwrCash: false
  },
  partnerSectorLeaseIsNewHomes
}) {
  const [ isDropdownOpen, setIsDropdownOpen ] = useState(false);
  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const financeOptions = [];
  Object.keys(financialOfferings).map((offering) => {
    if (financialOfferings[offering]) {
      if (offering === CASH_FINANCE_TYPE && settingsShowCashChecked) {
        financeOptions.push({
          name: CASH_FINANCE_TYPE,
          title: (mortgageEnabled() && partnerSectorLeaseIsNewHomes) ?
            MORTGAGE_FINANCE_TYPE_LABEL : CASH_FINANCE_TYPE_LABEL,
          selected: selectedOption === CASH_FINANCE_TYPE
        });
      } else if (offering === LOAN_FINANCE_TYPE && settingsShowLoanChecked) {
        financeOptions.push({
          name: LOAN_FINANCE_TYPE,
          title: LOAN_FINANCE_TYPE_LABEL,
          selected: selectedOption === LOAN_FINANCE_TYPE
        });
      } else if (offering === LEASE_FINANCE_TYPE && settingsShowLeaseChecked) {
        financeOptions.push({
          name: LEASE_FINANCE_TYPE,
          title: LEASE_FINANCE_TYPE_LABEL,
          selected: selectedOption === LEASE_FINANCE_TYPE
        });
      } else if (offering === PPA_FINANCE_TYPE && settingsShowPpaChecked) {
        financeOptions.push({
          name: PPA_FINANCE_TYPE,
          title: PPA_FINANCE_TYPE_LABEL,
          selected: selectedOption === PPA_FINANCE_TYPE
        });
      } else if (offering === SPWR_CASH_FINANCE_TYPE && settingsShowSpwrCashChecked) {
        financeOptions.push({
          name: SPWR_CASH_FINANCE_TYPE,
          title: SPWR_FINANCE_TYPE_LABEL,
          selected: selectedOption === SPWR_CASH_FINANCE_TYPE
        });
      }
    }
    return true;
  });

  const selectedFinanceOption = financeOptions.find((option) => option.selected);

  const options = (<ul>
    { orderBy(financeOptions, ['selected'], ['desc']).map((option, index) => {
      return (
        <li
          className={cx('cs-option-container', { 'cs-selected': option.selected })}
          onClick={() => {
            toggle(option.name, option.title);
            toggleDropdown();
          }}
          key={index}
        >
          <span className="cs-option">
            <div className="cs-option-name">{option.title}</div>
          </span>
        </li>
      );
    })}
  </ul>);

  return (
    <div id="finance_options_dropdown" className="input-container input-container--icon icon-right dropdown-with-tags">
      <i className="icon icon-chevron-down" />
      <div className={`select-dropdown cs-skin-border ${isDropdownOpen ? 'cs-active' : ''}`}>
        <span className="cs-placeholder" onClick={() => toggleDropdown()}>
          <div className="cs-option-name">{selectedFinanceOption.title}</div>
        </span>
        <div className="cs-options">
          { options }
        </div>
      </div>
    </div>
  );
}

FinanceOptionsDropdown.propTypes = {
  selectedOption: PropTypes.string.isRequired,
  toggle: PropTypes.func.isRequired,
  settingsShowCashChecked: PropTypes.bool,
  settingsShowLoanChecked: PropTypes.bool,
  settingsShowLeaseChecked: PropTypes.bool,
  settingsShowPpaChecked: PropTypes.bool,
  settingsShowSpwrCashChecked: PropTypes.bool,
  financialOfferings: PropTypes.shape({
    cash: PropTypes.bool,
    lease: PropTypes.bool,
    ppa: PropTypes.bool,
    loan: PropTypes.bool,
    spwrCash: PropTypes.bool
  }),
  partnerSectorLeaseIsNewHomes: PropTypes.bool.isRequired
};

export default register(
  ['partnerSectorLeaseIsNewHomesSelector'],
  [],
  FinanceOptionsDropdown
);

