import PropTypes from 'prop-types';

const annualKwhType = PropTypes.shape({
  purchased: PropTypes.number.isRequired,
  produced: PropTypes.number.isRequired,
  total: PropTypes.number.isRequired
});

const monthlyKwhType = PropTypes.arrayOf(PropTypes.shape({
  purchased: PropTypes.number.isRequired,
  produced: PropTypes.number.isRequired,
  excess: PropTypes.number.isRequired,
  total: PropTypes.number.isRequired
}));

const monthlyBillsType = PropTypes.arrayOf(PropTypes.shape({
  before: PropTypes.number.isRequired,
  after: PropTypes.number.isRequired
}));

const annualBillsType = PropTypes.arrayOf(PropTypes.shape({
  year: PropTypes.number.isRequired,
  before: PropTypes.number.isRequired,
  after: PropTypes.number.isRequired,
  difference: PropTypes.number.isRequired
}));

const year1AnnualBillType = PropTypes.shape({
  before: PropTypes.number.isRequired,
  after: PropTypes.number.isRequired,
  difference: PropTypes.number.isRequired
});

const year5AnnualBillType = PropTypes.shape({
  before: PropTypes.number.isRequired,
  after: PropTypes.number.isRequired,
  difference: PropTypes.number.isRequired
});

const incentiveType = PropTypes.shape({
  upfrontRebate: PropTypes.shape({
    name: PropTypes.string.isRequired,
    amount: PropTypes.number.isRequired
  }),
  specialRebate: PropTypes.shape({
    name: PropTypes.string.isRequired,
    amount: PropTypes.number.isRequired
  }),
  federalTaxCredit: PropTypes.shape({
    incentiveName: PropTypes.string.isRequired,
    rate: PropTypes.number.isRequired
  }),
  otherStateAndLocalIncentives: PropTypes.number
});

const incentivesType = PropTypes.shape({
  cash: incentiveType,
  lease: incentiveType,
  loan: incentiveType
});

export const energyDataType = PropTypes.shape({
  annualKwh: annualKwhType,
  monthlyKwh: monthlyKwhType,
  monthlyBills: monthlyBillsType,
  annualBills: annualBillsType,
  year1AnnualBill: year1AnnualBillType,
  year5AnnualBill: year5AnnualBillType,
  monthlyBillWithSolar: PropTypes.number,
  monthlyBillWithoutSolar: PropTypes.number,
  monthlyBillSavings: PropTypes.number,
  billSavingsPercent: PropTypes.number,
  incentives: incentivesType
});
