import { formatNumber } from './formatNumber';

const message = `The total loan price exceeds the approved loan amount.
  The customer co-payment was updated to meet the requirements of the loan.`;

export function informUserOfChangesToSettings(prevSettings, newSettings) {
  const hasChange = formatNumber(prevSettings.loan.coPayment) !== formatNumber(newSettings.loan.coPayment);
  return { messages: hasChange ? [{ message }] : null };
}

export function informUserOfChangeInCoPayment(newPayment, oldPayment) {
  const hasChange = formatNumber(oldPayment) !== formatNumber(newPayment);
  return { messages: hasChange ? [{ message }] : null };
}

