import createSelector from '../utils/createSelector';

function getManualMode({ quote }) {
  return {
    manualMode: !!quote.manualMode
  };
}

export default createSelector(
  getManualMode
);
