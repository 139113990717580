import React from 'react';
import { register } from '../../utils/redux';

export function Banner() {
  return (
    <div className="admin-page-banner">
      <div className="container admin-page-banner-data-container">
        <div className="admin-banner-text-container">
          <div className="title">
            PRICE & PRODUCT MANAGEMENT
          </div>
          <div className="subtitle">
            Define your preferred commissioning & control the product display defaults across your organization.
          </div>
        </div>
        <div className="admin-banner-image" />
        <img
          src="/build/images/admin-scroll-down-arrow.svg"
          className="admin-scroll-down-arrow"
          alt="Scroll Down"
        />
      </div>
    </div>
  );
}

Banner.propTypes = {};

export default register(
  [],
  [],
  Banner
);
