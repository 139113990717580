import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { formatNumber, formatCurrency, getDisplayDigits } from '../utils/formatNumber';
import StorageBox from './dynamic_design/StorageBox';
import { designType } from '../types';

function isNumber(string) {
  return !Number.isNaN(Number(string));
}

export default function ValueBox({
  id, label, value, subvalue, subtitle, imageClassName, isSelected, disabled,
  onClick, className, currency = false, rounded = false, centered = false,
  places, valueColor, storage = false, orange = false, subtitleClassName,
  toggleMonthlyProductionModal, design = false, isSaved = false
}) {
  const classes = cx(
    'value-box',
    className,
    {
      'is-editable': !disabled && onClick,
      'is-selected': isSelected,
      'is-centered': centered
    }
  );

  const digits = getDisplayDigits(currency, rounded, places);

  const displayValue = isNumber(value) ?
    (currency ? formatCurrency(value, digits) : formatNumber(value, digits)) :
    value;

  return (
    <div id={id} className={classes} onClick={disabled ? undefined : onClick}>
      <div className="main-container">
        <span className="label">{label}</span>
        <div className={cx(valueColor, 'value-container')}>
          <span className={cx({ orange }, 'value')}>{displayValue}</span>
          {subvalue && (
            <span className="subvalue">{subvalue}</span>
          )}
        </div>

        { label === 'Electricity Production (est.)' && design.simEng && isSaved && (
        <div
          className="month-view"
          onClick={() => toggleMonthlyProductionModal()}
        >
            Month view
            <span className="right-arrow" />
        </div>
        )}

        {subtitle && (
          <span className={cx(subtitleClassName, 'subtitle supporting-text')}>{subtitle}</span>
        )}
      </div>
      {imageClassName && (
        <div className={cx(imageClassName, 'value-box-image')} />
      )}
      {storage && <StorageBox />}
    </div>
  );
}

ValueBox.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([ PropTypes.number, PropTypes.string ]).isRequired,
  subvalue: PropTypes.string,
  subtitle: PropTypes.oneOfType([ PropTypes.string, PropTypes.element ]),
  subtitleClassName: PropTypes.string,
  imageClassName: PropTypes.string,
  isSelected: PropTypes.bool,
  onClick: PropTypes.func,
  isSaved: PropTypes.func,
  design: designType,
  toggleMonthlyProductionModal: PropTypes.func,
  className: PropTypes.string,
  places: PropTypes.number,
  currency: PropTypes.bool,
  rounded: PropTypes.bool,
  disabled: PropTypes.bool,
  centered: PropTypes.bool,
  id: PropTypes.string,
  valueColor: PropTypes.string,
  storage: PropTypes.bool,
  orange: PropTypes.bool
};

ValueBox.defaultProps = {
  id: null,
  subtitleClassName: ''
};
