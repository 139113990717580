import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-bootstrap-modal';
import { register } from '../utils/redux';
import { designType, pvModuleType } from '../types';
import { getDegradation } from '../utils/EddieCalculations/design';

export function monthlyProductionModal({
  design, closeMonthlyProductionModal, pvModule
}) {
  const MONTHS = [ 'JAN', 'FEB', 'MAR', 'APR',
    'MAY', 'JUN', 'JUL', 'AUG',
    'SEP', 'OCT', 'NOV', 'DEC' ];
  const [ showCopyMsg, setshowCopyMsg ] = useState(false);

  function copyToClipBoard() {
    const clipboardAry = [];
    design.simEng.monthlyEnergy.forEach((element) => {
      clipboardAry.push(element);
    });

    navigator.clipboard.writeText(`"MonthlyEnergy": [${
                                    clipboardAry}], "AnnualyEnergy": ${
                                    design.simEng.annualEnergy}`);
    setshowCopyMsg(true);
  }
  const degradation = getDegradation(pvModule);
  return (
    <Modal
      className="monthly-production-modal"
      show={design.monthlyProductionModal}
      onHide={closeMonthlyProductionModal}
      aria-labelledby="ModalHeader"
    >
      <Modal.Header closeButton={true} />
      <Modal.Body className="monthly-production-modal-body">
        <div className="monthly-production-modal-title">
          MONTHLY <span className="title-lable">kWh</span> PRODUCTION (EST)
        </div>
        <div className="monthly-chart">
          {design.simEng.monthlyEnergy.map((data, index) =>
            <div className="month-data">
              <div className="month-label">{MONTHS[index]}</div>
              <div className="production-data">{Math.round(data * degradation).toLocaleString('en-US')}</div>
            </div>
          )}
        </div>
        <div className="annual-production-data">
          {Math.floor(design.totalEnergyProduction * degradation).toLocaleString('en-US')}
          <label className="units">kWh/Year</label>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <a onClick={() => copyToClipBoard()}>CLICK TO COPY VALUES</a>
        { showCopyMsg && (
          <div className="clipboard-msg">
            Monthly Energy Data copied to clipboard
          </div>
        )}
      </Modal.Footer>
    </Modal>
  );
}

monthlyProductionModal.propTypes = {
  design: designType.isRequired,
  pvModule: pvModuleType.isRequired,
  closeMonthlyProductionModal: PropTypes.func
};

export default register(
  [
    'designSelector', 'pvModuleSelector'
  ],
  [
    'toggleModuleSelectionModal', 'closeMonthlyProductionModal'
  ],
  monthlyProductionModal
);
