import get from 'lodash/get';
import { asyncPayload } from '../apiHelpers';
import Api from '../../utils/Api';
import { updateSoProposal } from './updateSoProposal';

export const sendSoTitleCheck = (storageQuote, accountId, soDynamicProposal, actionType, updateType = '') => {
  const promise = Api.update(`title_check/${storageQuote.sfid}/send_to_sfdc`, {
    accountId, actionType, updateType
  });

  return (dispatch) => {
    const response = dispatch({
      type: 'sendTitleCheck',
      payload: asyncPayload({
        request: promise,
        beforeRequest: {
          soDynamicProposal: {
            showComplianceCheckModal: true,
            showTitleCheckModal: false,
            showModalLoader: true
          }
        }
      })
    });

    response.then((data) => {
      dispatch({
        type: 'sendTitleCheckCompleted',
        payload: {
          soDynamicProposal: {
            showModalLoader: false,
            showTitleCheckModal: false,
            showComplianceCheckModal: false
          },
          storageQuote: {
            titleCheckDetails: get(data, 'value.titleCheckDetails', null)
          }
        }
      });
      if (!get(data, 'value.error', null)) {
        if (actionType !== 'cancel') {
          dispatch(updateSoProposal(storageQuote.sfid, 'send', soDynamicProposal.sentVia));
        }
      }
      return data;
    });
  };
};
