import React from 'react';
import PropTypes from 'prop-types';
import ShowHideSectionComponent from '../ShowHideSectionComponent';

export class ShowHideSection extends React.Component {
  constructor(props) {
    super(props);
    this.state = { expanded: false };
    this.onClick = this.onClick.bind(this);
  }

  onClick() {
    if (!this.state.expanded) {
      switch (this.props.activeGraphTab) {
        case 'totalSavingsGraph':
          break;
        case 'savingsOverTimeGraph':
          break;
        default:
      }
    }

    this.setState({
      expanded: !this.state.expanded
    });
  }

  render() {
    return (
      <ShowHideSectionComponent
        expanded={this.state.expanded}
        onClick={this.onClick}
      >
        {this.props.children}
      </ShowHideSectionComponent>
    );
  }
}

ShowHideSection.propTypes = {
  activeGraphTab: PropTypes.string.isRequired,
  children: PropTypes.element.isRequired
};

export default ShowHideSection;
