import { pick } from 'lodash';
import mapStackTrace from 'sourcemapped-stacktrace';
import environment from './environment';

const silenceAlerts = () => window.location.search.includes('silence');

/**
 * @param {Error} error Error object with stacktrace apply sourcemap to.
 * @returns {Promise<[]String>}
 */
export const getMappedStack = async (error) => new Promise((done) => {
  if (error.stack) {
    mapStackTrace.mapStackTrace(error.stack, done);
  } else {
    done();
  }
});

// We export console here in order to
// encourage import of logger function, which
// can be customized, if and we find it necessary.
export default Object.assign(
  pick(console, [ 'log', 'debug', 'warn', 'info' ]),
  {
    error: async (arg) => {
      // eslint-disable-next-line no-console
      console.error(arg);

      let error = arg;
      if (typeof error === 'string') {
        error = new Error(arg);
      }

      let mappedStack = await getMappedStack(error);
      if (!mappedStack) {
        mappedStack = [];
      }
      const fingerprint = [
        error.message,
        ...mappedStack.slice(0, 3)
      ].join('\n');
      const context = {
        message: error.message,
        mappedStack,
        componentInfo: error.componentInfo,
        fingerprint
      };

      if (!environment.isPrd() && !environment.isUat() && !silenceAlerts()) {
        const alertInfo = pick(context, [ 'message', 'mappedStack', 'componentInfo' ]);
        // eslint-disable-next-line no-alert
      }
    }
  }
);
