import { uniq, range } from 'lodash';


export function assignModuleArrayStructure(arrayIndex, structure, moduleArrays) {
  const moduleArrayUpdate = {};
  // Set new structure for moduleArray.
  moduleArrayUpdate[arrayIndex] = { structure };

  // Ensure structureIndices are continuous
  // eg [ 2, 5, 7 ] -> [ 2, 0, 1 ].
  let structureIndices = moduleArrays.map(({ structure }) => (structure || 1));
  structureIndices[arrayIndex] = structure;
  structureIndices = uniq(structureIndices).sort();
  const maxStructureIndex = structureIndices.length;

  const adjustedIndices = {};
  moduleArrays.forEach((moduleArray, i) => {
    const actualStructure = (arrayIndex === i) ?
    structure : (moduleArray.structure || 1);
    if (actualStructure > maxStructureIndex) {
      let adjustedStructure = adjustedIndices[actualStructure];
      if (adjustedStructure === undefined) {
        adjustedStructure = range(1, maxStructureIndex + 1).find((n) => !structureIndices.includes(n));
        structureIndices.push(adjustedStructure);
        structureIndices.sort();
        adjustedIndices[actualStructure] = adjustedStructure;
      }
      moduleArrayUpdate[i] = { structure: adjustedStructure };
    }
  });
  return {
    type: 'assignModuleArrayStructure',
    payload: {
      design: {
        moduleArrays: moduleArrayUpdate
      }
    }
  };
}
