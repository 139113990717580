import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import RoofPreviewVisualization from '../dynamic_design/RoofPreviewVisualization';
import DesignMap from '../dynamic_design/DesignMap';
import { accountType, quoteType } from '../../types';
import { register } from '../../utils/redux';

import {
  ROOF_PREVIEW_VISUALIZATION, ROOF_PREVIEW_MAP
} from '../../config';

export const RoofPreview = ({ roofPreviewMode, account, interactive, quote }) => {
  const isRoofVisualizationActive = roofPreviewMode === null || roofPreviewMode === ROOF_PREVIEW_VISUALIZATION;

  return (
    <div id="roof_preview">
      <div className={cx('roof_preview__container')}>
        {isRoofVisualizationActive ? (
          <RoofPreviewVisualization
            interactive={interactive}
            quote={quote}
          />
        ) : (
          <DesignMap latitude={account.latitude} longitude={account.longitude} height={'calc(100vh - 17rem)'} />
        )}
      </div>
    </div>
  );
};

RoofPreview.propTypes = {
  toggleMode: PropTypes.func.isRequired,
  quote: quoteType,
  roofPreviewMode: PropTypes.oneOf([
    ROOF_PREVIEW_VISUALIZATION,
    ROOF_PREVIEW_MAP
  ]),
  setRoofPreviewMode: PropTypes.func.isRequired,
  account: accountType.isRequired,
  interactive: PropTypes.bool.isRequired
};


export default register(
  [ 'visualizationSelector', 'roofPreviewModeSelector', 'modeSelector', 'quoteSelector' ],
  [ 'setRoofPreviewMode', 'toggleMode' ],
  RoofPreview
);
