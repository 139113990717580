export function toggleSettingsTab(newTab) {
  return {
    type: 'toggleSettingsTab',
    payload: {
      settings: {
        activeTab: newTab
      }
    }
  };
}
