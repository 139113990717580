import BrowserStorage from './BrowserStorage';
import environment from './environment';

const AVAILABLE_FEATURE_FLAGS = [ 'ENABLE_STORAGE_EXPANSION_PACKS', 'ENABLE_STORAGE_EXPANSION_PACKS_SO',
  'ENABLE_DYNAMIC_PROPOSAL', 'ENABLE_PPA_FINANCE_OPTION', 'ENABLE_DYNAMIC_PROPOSAL_SO' ];
const RESET_VALUES = [ '', 'reset' ];

// Setting flags overwrites all the ones previously set,
// '' or 'reset' removes all the flags.
export function storeFeatureFlags(location) {
  if (location.query && (typeof location.query.ff !== 'undefined')) {
    BrowserStorage.setPermanentItem('featureFlags', location.query.ff);
  }
}

export function isFeatureFlagOn(flag) {
  const skipFeatureFlags = environment.isPrd();
  if (skipFeatureFlags) {
    return false;
  }
  if (!AVAILABLE_FEATURE_FLAGS.includes(flag)) {
    throw new Error(`Feature flag '${flag}' is referred to but is not available any more.`);
  }
  const ff = BrowserStorage.getItem('featureFlags') || '';
  const featureFlagsArray = RESET_VALUES.includes(ff) ? [] : ff.split(',');
  return featureFlagsArray.includes(flag);
}
