import React from 'react';
import PropTypes from 'prop-types';

export default function ShowHideSectionComponent({
  onClick,
  children,
  expanded,
  title
}) {
  const classNames = `show-hide-icon-container${expanded ? ' expanded' : ''}`;
  return (
    <div className="show-hide">
      <div className={classNames}>
        <div className="show-hide-icon-line">
          <div className="show-hide-icon" onClick={onClick}>
            {expanded ?
              (
                <i className="icon icon-chevron-up" />
              ) : (
                <i className="icon icon-chevron-down" />
              )
            }
            {title}
          </div>
        </div>
      </div>
      {expanded ? children : null}
    </div>
  );
}
ShowHideSectionComponent.propTypes = {
  onClick: PropTypes.func.isRequired,
  children: PropTypes.element.isRequired,
  expanded: PropTypes.bool,
  title: PropTypes.string
};
