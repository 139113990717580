/* eslint no-underscore-dangle: 0 */

export function findApFinConfigIndex(apFinConfigsToUpdate, financeType) {
  return apFinConfigsToUpdate
    .map((config) => config.finType.toLowerCase()).indexOf(financeType.toLowerCase());
}

export function findApFinConfigs(apFinConfigsToUpdate, financeType) {
  return apFinConfigsToUpdate
    .filter((config) => config.finType.toLowerCase() === financeType.toLowerCase());
}

export function findApFinConfigIndexById(apFinConfigsToUpdate, id) {
  return apFinConfigsToUpdate
    .map((config) => config.id).indexOf(id);
}

export function findSelectedStates(apFinConfigs, financeType, selectedApFinConfig = null) {
  const apFinConfig = selectedApFinConfig || findApFinConfig(apFinConfigs, financeType);

  return apFinConfig ? (apFinConfig.applicableStatesAttributes || [])
    .filter((stateConfig) => !stateConfig._destroy).map((stateConfig) => stateConfig.state) : [];
}

export function findSelectedModuleSeries(apFinConfigs, financeType, selectedApFinConfig = null) {
  const apFinConfig = selectedApFinConfig || findApFinConfig(apFinConfigs, financeType);

  return apFinConfig ? (apFinConfig.moduleConfigsAttributes || [])
    .filter((moduleConfig) => !moduleConfig._destroy).map((moduleConfig) => moduleConfig.moduleSeries) : [];
}

export function findSelectedModule(apFinConfigs, financeType, selectedApFinConfig = null) {
  const apFinConfig = selectedApFinConfig || findApFinConfig(apFinConfigs, financeType);

  return apFinConfig && apFinConfig.defaultModuleAttributes ? apFinConfig.defaultModuleAttributes.moduleItemId : null;
}

export function findApFinConfig(apFinConfigs, financeType) {
  return (apFinConfigs && financeType) ?
    apFinConfigs.findLast((config) => config.finType.toLowerCase() === financeType.toLowerCase()) : null;
}

export function getStates(config) {
  return config.applicableStatesAttributes.filter((configState) =>
    configState._destroy !== true).map((configState) => configState.state);
}
