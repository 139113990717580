import u from 'updeep';
import { asyncPayload } from './apiHelpers';
import Api from '../utils/Api';

export function savePvModuleForManualMode(quote, pvModuleItemId, storageConfigId,
  storageQuantity, storageExpansionPackQuantity, storageLaborCost, evChargerConfigs) {
  let newQuote;

  let afterRequestBody = {
    quote: {
      finEng: null
    }
  };
  let payload = {
    pvModuleItemId,
    storageConfigId,
    storageQuantity,
    storageExpansionPackQuantity,
    storageLaborCost,
    evChargerConfigs
  };

  if (quote.pvModuleItemId !== pvModuleItemId) {
    afterRequestBody = { ...afterRequestBody, moduleChanged: true };
    payload = { ...payload, moduleChanged: true };
  }

  const promise = Api.update(`manual_mode/quotes/${quote.sfid}/configurations`, payload)
    .then((response) => {
      newQuote = response;
      return Api.fetch(`manual_mode/quotes/${quote.sfid}/configurations`);
    })
    .then((response) => {
      return u(response, newQuote);
    });

  return {
    type: 'savePvModuleForManualMode',
    payload: asyncPayload({
      request: promise,
      afterRequest: afterRequestBody
    })
  };
}
