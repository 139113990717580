import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import AbortController from 'abort-controller';
import { register } from '../../../utils/redux';
import { modalsType } from '../../../types';

export function ModalButtons({ onCancel, icon }) {
  return (
    <div className="popover-navigation">
      {icon &&
      <a
        href="#"
        className="popover-cancel"
        onClick={() => {
          onCancel();
        }}
      >
        &#x2715;
      </a>
      }
    </div>
  );
}

ModalButtons.propTypes = {
  onSave: PropTypes.func,
  onCancel: PropTypes.func.isRequired,
  saveDisabled: PropTypes.func,
  name: PropTypes.string,
  icon: PropTypes.bool
};

export function Modal({
                        name,
                        title,
                        children,
                        modals,
                        onSave,
                        showCloseButtonOnly = false,
                        showButtons = true,
                        showButtonsTop = true,
                        closeModalAfterOnSave = true,
                        saveDisabled,
                        showIcon = false,
                        closeModal,
                        ...rest
                      }) {
  const { className } = rest;
  const coordinates = modals.coordinates;
  const isCentered = coordinates ? !(coordinates.left || coordinates.right) : true;

  const cssClasses = cx('modal', className, { 'is-centered': isCentered });
  const { top = null, left = null, right = null } = coordinates || {};
  const style = coordinates ? { top, left, right } : null;

  const onSaveFn = !closeModalAfterOnSave ? onSave : (e) => {
    onSave(e);
    closeModal();
  };

  const onCancel = () => {
    closeModal();
  };

  const displayButtons = () => {
    if (showCloseButtonOnly) {
      return (<ModalButtons name={name} onCancel={onCancel} saveDisabled={saveDisabled} icon={showIcon} />);
    } else if (showButtons) {
      return (<ModalButtons name={name} onSave={onSaveFn} onCancel={onCancel} saveDisabled={saveDisabled} />);
    }
    return null;
  };

  return (
    <div className={cssClasses}>
      <div id="overlay" />

      <div id={name} className="popover" style={style}>
        {showButtonsTop && (
          <div className="popover-header">
            <span className="h2 popover-title">{title}</span>
            {displayButtons()}
          </div>
        )}

        {children}

        {!showButtonsTop && displayButtons()}
      </div>
    </div>
  );
}

Modal.propTypes = {
  name: PropTypes.string.isRequired,
  title: PropTypes.string,
  children: PropTypes.node.isRequired,
  modals: modalsType.isRequired,
  onSave: PropTypes.func,
  showButtons: PropTypes.bool,
  showButtonsTop: PropTypes.bool,
  showCloseButtonOnly: PropTypes.bool,
  closeModalAfterOnSave: PropTypes.bool,
  controller: PropTypes.oneOfType([
    PropTypes.instanceOf(AbortController),
    PropTypes.instanceOf(PropTypes.object)
  ]),
  saveDisabled: PropTypes.func,
  showIcon: PropTypes.bool,
  closeModal: PropTypes.func
};

export default register(
  [],
  [],
  Modal
);
