export function updateStorageSelectionModal(prop, newValue) {
  return {
    type: 'updateStorageSelectionModal',
    payload: {
      storageSelectionModal: {
        [prop]: newValue
      }
    }
  };
}
