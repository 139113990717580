import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-bootstrap-modal';
import { register } from '../../utils/redux';
import { quoteType } from '../../types';

export function CreditAppModal({ quote, showCreditAppModal, toggleCreditAppModal,
  creditAppUrl, updateSalesforceQuote }) {
  const closeModal = () => {
    updateSalesforceQuote(quote.sfid, true);
    toggleCreditAppModal(false);
  };

  const creditAppModalView = () => {
    return (
      <Modal
        className="credit-app-modal"
        show={showCreditAppModal}
        onHide={closeModal}
        aria-labelledby="ModalHeader"
        data-keyboard="false"
        data-backdrop="static"
      >
        <Modal.Header className="credit-app-header" closeButton={true} />
        <Modal.Body className="credit-app-modal-body">
          <iframe
            src={creditAppUrl}
            title="credit application"
            frameBorder="0"
          />
        </Modal.Body>
      </Modal>
    );
  };

  return (
    <React.Fragment>
      { creditAppModalView() }
    </React.Fragment>
  );
}

CreditAppModal.propTypes = {
  quote: quoteType.isRequired,
  showCreditAppModal: PropTypes.bool.isRequired,
  toggleCreditAppModal: PropTypes.func.isRequired,
  updateSalesforceQuote: PropTypes.func.isRequired,
  creditAppUrl: PropTypes.string
};

export default register(
  [],
  [],
  CreditAppModal
);
