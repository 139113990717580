import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { register } from '../../utils/redux';
import { savingsChartInfoBoxType } from '../../types';

export class ChartSlider extends React.Component {
  constructor(props) {
    super(props);

    this.isDragging = false;
    this.wrapperEl = null;
    this.boundEvents = [];

    this.cancelDrag = this.cancelDrag.bind(this);
    this.handleDrag = _.debounce(this.handleDrag.bind(this), 10);
    this.startDrag = this.startDrag.bind(this);
  }

  componentDidMount() {
    this.bindEvent(window, 'mousemove', this.handleDrag);
    this.bindEvent(window, 'mouseup', this.cancelDrag);
    this.bindEvent(window, 'mouseleave', this.cancelDrag);
    this.bindEvent(this.wrapperEl, 'mousedown', this.startDrag);

    this.bindEvent(window, 'touchend', this.cancelDrag, { passive: false });
    this.bindEvent(window, 'touchmove', this.handleDrag, { passive: false });
    this.bindEvent(this.wrapperEl, 'touchstart', this.startDrag, { passive: false });
  }

  componentWillUnmount() {
    this.boundEvents.forEach(({ name, element, listenerFn }) => {
      element.removeEventListener(name, listenerFn);
    });
  }

  bindEvent(element, name, listenerFn, options) {
    element.addEventListener(name, listenerFn, options);
    this.boundEvents.push({ name, element, listenerFn });
  }

  relativeWrapperXPos(event) {
    const docXPos = event.changedTouches ? event.changedTouches[0].pageX : event.clientX;
    const boundingCoords = this.wrapperEl.getBoundingClientRect();
    return docXPos - boundingCoords.left;
  }

  get shiftLengthPx() {
    return this.wrapperEl.offsetWidth / this.props.savingsChartInfoBoxData.labelsCount;
  }

  get shift() {
    // additional half of a percent on each side
    return this.props.savingsChartInfoBoxData &&
      (100 * (this.props.savingsChartInfoBoxData.x + 0.5) / this.props.savingsChartInfoBoxData.labelsCount);
  }

  pxOffsetToX(pxOffset) {
    const labelsCount = this.props.savingsChartInfoBoxData.labelsCount;
    return normalizeX(Math.floor(pxOffset / this.shiftLengthPx));

    function normalizeX(selection) {
      const max = labelsCount - 1;
      const min = 0;

      if (selection > max) {
        return max;
      }

      if (selection < min) {
        return min;
      }

      return selection;
    }
  }

  startDrag(event) {
    event.preventDefault();

    this.isDragging = true;
    this.handleDrag(event);
  }

  cancelDrag(event) {
    if (!this.isDragging) {
      return;
    }

    event.preventDefault();
    this.isDragging = false;
  }

  handleDrag(event) {
    if (!this.isDragging) {
      return;
    }

    event.preventDefault();

    const draggedSelection = this.pxOffsetToX(this.relativeWrapperXPos(event));
    if (draggedSelection !== this.props.savingsChartInfoBoxData.x) {
      this.props.setSavingsChartSelection(draggedSelection);
    }
  }

  render() {
    return (
      <div
        className="chart-slider-wrapper"
        ref={(el) => { this.wrapperEl = el; }}
      >
        {
          this.props.savingsChartInfoBoxData.x !== null ?
            <div
              className="chart-slider"
              style={{ left: `calc(${this.shift}% - 12px)` }}
            >
              <div className="chart-slider-label">{this.props.savingsChartInfoBoxData.label}</div>
              <div className="chart-slider-arrow" />
            </div> :
          null
        }
      </div>
    );
  }
}

ChartSlider.propTypes = {
  savingsChartInfoBoxData: savingsChartInfoBoxType,
  setSavingsChartSelection: PropTypes.func.isRequired
};

export default register(
  ['savingsChartInfoBoxDataSelector'],
  ['setSavingsChartSelection'],
  ChartSlider
);
