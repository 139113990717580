export function toggleSoTitleCheckModal(value) {
  return {
    type: 'setSoTitleCheckModalStatus',
    payload: {
      soDynamicProposal: {
        showTitleCheckModal: value
      }
    }
  };
}
