import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { register } from '../../utils/redux';
import { accountType, storageQuoteType } from '../../types';
import WhyStorageModal from './modals/WhyStorageModal';
import { InverterListDropdown } from './InverterListDropdown';
import { COMPATIBLE_INVERTERS } from '../../utils/storageQuote/inverters';
import { getDealerPortalUrl } from '../../utils/url';

export function InitialPage({
                              storageQuote, showSystemCheckPage, cancelUrl, updateStorageQuote,
                              inverterType, setInverterType, account
                            }) {
  const [ isCompatibleInverterType, setIsCompatibleInverterType ] = useState(null);
  const [ showModal, setShowModal ] = useState(false);

  const [ modalCoordinates, setModalCoordinates ] = useState(null);
  useEffect(() => {
    if (storageQuote.inverterType) {
      setIsCompatibleInverterType(COMPATIBLE_INVERTERS.indexOf(storageQuote.inverterType) > -1);
    }
  }, [storageQuote]);

  useEffect(() => {
    if (inverterType) {
      setIsCompatibleInverterType(COMPATIBLE_INVERTERS.indexOf(inverterType) > -1);
    }
  }, [inverterType]);

  const saveInverterType = () => {
    if (!storageQuote.locked) {
      updateStorageQuote(storageQuote, { inverter_type: inverterType }, true);
    }
    if (isCompatibleInverterType) {
      showSystemCheckPage(true);
    }
  };

  const toggleModal = (target) => {
    const {
      top: targetTop,
      left: targetLeft,
      width: targetWidth,
      height: targetHeight
    } = target.getBoundingClientRect();
    setModalCoordinates({
      top: targetTop + targetHeight + 10,
      left: (targetLeft + targetWidth / 2) - 300
    });
    setShowModal(!showModal);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  const openDealerPortal = () => {
    window.location.href = getDealerPortalUrl(account.sfid);
  };

  return (
    <React.Fragment>
      <img className="heading-image" src={'/build/images/storage-static-icon.svg'} alt="" />
      <div className="heading-text margin-bottom-top-20">SUNVAULT COMPATIBILITY</div>
      <div className="sub-heading-text margin-bottom-top-10">
        <div>Select the inverter</div>
        <div>for the installed solar system:</div>
      </div>
      <div className="secondary-heading margin-bottom-top-10">
        <span
          id="why-storage-modal-toggle-btn"
          onClick={(e) => {
            e.preventDefault();
            toggleModal(e.currentTarget);
          }}
        >Why</span> is this important?</div>
      <div className="inverter-select-container">
        {storageQuote.locked ?
          <div className="input-container">
            <input value={storageQuote.inverterType} disabled={true} />
          </div>
         : <InverterListDropdown
           inverterType={storageQuote.inverterType}
           setInverterType={setInverterType}
         />
        }
      </div>
      <div className="negative-response-text margin-bottom-top-10">
        <div className="help-text">
          <span className="not-sure-text">Not sure?</span> Chat us in the
          <span
            id="open-dealer-portal-span"
            className="action-text"
            onClick={() => { openDealerPortal(); }}
          > Dealer Portal.</span>
        </div>
        {isCompatibleInverterType === false &&
        <div className="main-text margin-bottom-top-10">
          <div>SunVault is not yet compatible with this type of inverter.</div>
          <div className="secondary-text">Please reach out to your RSM for more information.</div>
        </div>
        }
      </div>
      <button
        id="next-btn"
        className="btn btn-primary next-btn margin-bottom-top-10"
        disabled={!isCompatibleInverterType}
        onClick={saveInverterType}
      >
        Next
      </button>
      <a
        href={cancelUrl}
        className="cancel-btn"
      >
        CANCEL
      </a>
      { showModal &&
      <WhyStorageModal modalCoordinates={modalCoordinates} closeModal={closeModal} />
      }
    </React.Fragment>
  );
}

InitialPage.propTypes = {
  storageQuote: storageQuoteType.isRequired,
  showSystemCheckPage: PropTypes.func,
  cancelUrl: PropTypes.string,
  updateStorageQuote: PropTypes.func,
  inverterType: PropTypes.string,
  setInverterType: PropTypes.func,
  account: accountType.isRequired
};

export default register(
  [
    'storageQuoteSelector', 'inverterTypeSelector', 'accountSelector'
  ],
  [ 'updateStorageQuote', 'setInverterType' ],
  InitialPage
);
