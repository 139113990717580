import { has } from 'lodash';

export const populateWithArrays = (arraysIds) => {
  const initial = {};
  return arraysIds.reduce((obj, item) => {
    return {
      ...obj,
      [item]: []
    };
  }, initial);
};

export const padInstantDesign = (design) => {
  if (!has(design, 'roof.moduleArraysIndexesToRoofPlaneNumbers')) {
    return {};
  }
  const padding = populateWithArrays(Object.keys(design.roof.moduleArraysIndexesToRoofPlaneNumbers));
  return {
    design: {
      roof: {
        moduleArrays: padding,
        obstructions: padding,
        setbacks: padding,
        panelShading: padding
      }
    }
  };
};
