if (typeof localStorage === 'object') {
  try {
    localStorage.setItem('localStorage', 1);
    localStorage.removeItem('localStorage');
  } catch (e) {
    /* eslint-disable no-alert */
    alert('Your web browser does not support storing settings locally. ' +
      'In Safari, the most common cause of this is using "Private Browsing Mode". ' +
      '\nThe app does not support Private Browsing.');
    /* eslint-enable no-alert */
    throw new Error('Private Browsing not supported');
  }
}

export default {
  getItem(key) {
    return global.sessionStorage.getItem(key) || global.localStorage.getItem(key);
  },

  setSessionItem(key, value) {
    global.sessionStorage.setItem(key, value);
  },

  setPermanentItem(key, value) {
    global.localStorage.setItem(key, value);
  },

  removeItem(key) {
    global.sessionStorage.removeItem(key);
    global.localStorage.removeItem(key);
  }
};
