import AbortController from 'abort-controller';
import cx from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { modalsType } from '../types';
import { gaTrackEvent } from '../utils/googleAnalytics';
import { register } from '../utils/redux';

export function ModalButtons({ onSave, onCancel, saveDisabled, name }) {
  const cancelButtonLabel = !onSave ? 'Close' : 'Cancel';
  const saveButtonClass = `popover-save btn btn-primary btn--small ${saveDisabled && saveDisabled() ? 'disabled' : ''}`;

  return (
    <div className="popover-navigation">
      <a
        href="#"
        className="popover-cancel"
        onClick={() => {
          gaTrackEvent(`quotePage:modal:${name}:cancel`);
          onCancel();
        }}
      >
        {cancelButtonLabel}
      </a>

      { onSave && (
        <a
          href="#"
          className={saveButtonClass}
          onClick={(evt) => {
            gaTrackEvent(`quotePage:modal:${name}:saveChanges`);
            onSave(evt);
          }}
          data-dd-action-name="Save Settings"
        >
          Save Changes
        </a>
      )}
    </div>
  );
}
ModalButtons.propTypes = {
  onSave: PropTypes.func,
  onCancel: PropTypes.func.isRequired,
  saveDisabled: PropTypes.func,
  name: PropTypes.string
};

export function Modal({
  name,
  title,
  children,
  modals,
  onSave,
  toggleModal,
  closeModalAndRevertState,
  showCloseButtonOnly = false,
  showButtons = true,
  showButtonsTop = true,
  closeModalAfterOnSave = true,
  controller,
  cancelFetchFinEng,
  saveDisabled,
  ...rest
}) {
  const { className, containerClass } = rest;
  const coordinates = modals.coordinates;
  const isCentered = coordinates ? !(coordinates.left || coordinates.right) : true;

  const cssClasses = cx('modal', className, { 'is-centered': isCentered });
  const { top = null, left = null, right = null } = coordinates || {};
  const style = coordinates ? { top, left, right } : null;

  const onSaveFn = !closeModalAfterOnSave ? onSave : (e) => { onSave(e); toggleModal(name); };

  const onCancel = () => {
    if (controller && controller instanceof AbortController) {
      cancelFetchFinEng(controller);
    }
    closeModalAndRevertState();
  };

  const displayButtons = () => {
    if (showCloseButtonOnly) {
      return (<ModalButtons name={name} onCancel={onCancel} saveDisabled={saveDisabled} />);
    } else if (showButtons) {
      return (<ModalButtons name={name} onSave={onSaveFn} onCancel={onCancel} saveDisabled={saveDisabled} />);
    }
    return null;
  };

  return (
    <div className={cssClasses}>
      <div id="overlay" />

      <div
        id={name}
        className={`popover ${containerClass || ''}`}
        style={style}
      >
        { showButtonsTop && (
          <div className="popover-header">
            <span className="h2 popover-title">{title}</span>
            {displayButtons()}
          </div>
        )}

        {children}

        { !showButtonsTop && displayButtons() }
      </div>
    </div>
  );
}
Modal.propTypes = {
  name: PropTypes.string.isRequired,
  title: PropTypes.string,
  children: PropTypes.node.isRequired,
  modals: modalsType.isRequired,
  onSave: PropTypes.func,
  closeModalAndRevertState: PropTypes.func,
  toggleModal: PropTypes.func,
  showButtons: PropTypes.bool,
  showButtonsTop: PropTypes.bool,
  showCloseButtonOnly: PropTypes.bool,
  closeModalAfterOnSave: PropTypes.bool,
  controller: PropTypes.oneOfType([
    PropTypes.instanceOf(AbortController),
    PropTypes.instanceOf(PropTypes.object)
  ]),
  cancelFetchFinEng: PropTypes.func,
  saveDisabled: PropTypes.func
};

export default register(
  ['modalsSelector'],
  [ 'toggleModal', 'closeModalAndRevertState', 'cancelFetchFinEng' ],
  Modal
);
