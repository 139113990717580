import React from 'react';
import { roofType } from '../types';

import Building from './Building';
import ModuleArray from './ModuleArray';

export default function Visualization({ roof }) {
  const { dimensions = { width: 0, height: 0 }, moduleArrays = {} } = roof;
  const viewBox = `0 0 ${dimensions.width} ${dimensions.height}`;
  return (
    <svg className="visualization" width="570" height="500" viewBox={viewBox} preserveAspectRatio="xMidYMin meet">
      <Building roof={roof} />
      {Object.entries(moduleArrays).map(([ id, modules ], index) =>
        <ModuleArray key={index} id={Number(id)} modules={modules} />
      )}
    </svg>
  );
}

Visualization.propTypes = {
  roof: roofType.isRequired
};
