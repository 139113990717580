import PropTypes from 'prop-types';

export const userDataType = PropTypes.shape({
  sfid: PropTypes.string.isRequired,
  email: PropTypes.string,
  phone: PropTypes.string,
  mobilePhone: PropTypes.string,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  companyName: PropTypes.string
});
