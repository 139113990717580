import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import cx from 'classnames';

import { register } from '../../../utils/redux';

export function PaymentConfigView({
  financeType,
  availablePaymentEscalators,
  apAvailableDownPayments,
  apIsFullPrepaymentAllowed,
  updateApPaymentConfig,
  apFinConfig
}) {
  const [ isDownPaymentDropDownOpen, setIsDownPaymentDropDownOpen ] = useState(false);
  const [ isPaymentEscalatorDropDownOpen, setIsPaymentEscalatorDropDownOpen ] = useState(false);

  const paymentConfig = get(apFinConfig, 'paymentConfigAttributes', {}) || {};
  const {
    downPayment,
    fullPrepayment,
    paymentEscalator
  } = paymentConfig;
  const isDownPaymentDropdownDisabled = (apAvailableDownPayments || []).length === 0 || !!fullPrepayment;
  const isFOEnabled = get(apFinConfig, 'show');
  const DOWN_PAYMENT_DROPDOWN_ID = 'downPaymentDropDown';
  const PAYMENT_ESCALATOR_DROPDOWN_ID = 'paymentEscalatorDropDown';
  const downPaymentDropDownRef = useRef(DOWN_PAYMENT_DROPDOWN_ID);
  const paymentEscalatorDropDownRef = useRef(PAYMENT_ESCALATOR_DROPDOWN_ID);

  const togglePaymentEscalatorDropDown = () => {
    setIsPaymentEscalatorDropDownOpen(!isPaymentEscalatorDropDownOpen);
  };

  const toggleDownPaymentDropDown = () => {
    setIsDownPaymentDropDownOpen(!isDownPaymentDropDownOpen);
  };

  const handleFocusChange = (ref) => {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          if (ref.current.id === DOWN_PAYMENT_DROPDOWN_ID) {
            setIsDownPaymentDropDownOpen(false);
          } else if (ref.current.id === PAYMENT_ESCALATOR_DROPDOWN_ID) {
            setIsPaymentEscalatorDropDownOpen(false);
          }
        }
      }

      // Bind the event listener
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, [ref]);
  };

  handleFocusChange(downPaymentDropDownRef);
  handleFocusChange(paymentEscalatorDropDownRef);

  useEffect(() => {
    if (isDownPaymentDropdownDisabled) {
      updateApPaymentConfig(apFinConfig.id, 'downPayment', null);
    }
  }, [isDownPaymentDropdownDisabled]);

  useEffect(() => {
    if (!apIsFullPrepaymentAllowed) {
      updateApPaymentConfig(apFinConfig.id, 'fullPrepayment', false);
    }
  }, [apIsFullPrepaymentAllowed]);

  const updateFullPrePayment = (val) => {
    updateApPaymentConfig(apFinConfig.id, 'fullPrepayment', val);

    if (val) {
      updateApPaymentConfig(apFinConfig.id, 'downPayment', null);
    }
  };

  const paymentEscalatorOptions = (<ul>
    <li
      className={cx('cs-module-option-container', { 'cs-selected': paymentEscalator === null })}
      onClick={() => {
        updateApPaymentConfig(apFinConfig.id, 'paymentEscalator', null);
        togglePaymentEscalatorDropDown(true);
      }}
    >
      <span className="cs-option">
        <div className="cs-option-name">-- Select --</div>
      </span>
    </li>
    { availablePaymentEscalators.map((option) => {
      return (
        <li
          className={cx('cs-module-option-container', { 'cs-selected': option === paymentEscalator })}
          onClick={() => {
            updateApPaymentConfig(apFinConfig.id, 'paymentEscalator', option);
            togglePaymentEscalatorDropDown(true);
          }}
        >
          <span className="cs-option">
            <div className="cs-option-name">{option}%</div>
          </span>
        </li>
      );
    })}
  </ul>);

  const downPaymentOptions = (<ul>
    <li
      className={cx('cs-module-option-container', { 'cs-selected': downPayment === null })}
      onClick={() => {
        updateApPaymentConfig(apFinConfig.id, 'downPayment', null);
        toggleDownPaymentDropDown(true);
      }}
    >
      <span className="cs-option">
        <div className="cs-option-name">-- Select --</div>
      </span>
    </li>
    { apAvailableDownPayments.map((option) => {
      return (
        <li
          className={cx('cs-module-option-container', { 'cs-selected': option === downPayment })}
          onClick={() => {
            updateApPaymentConfig(apFinConfig.id, 'downPayment', option);
            toggleDownPaymentDropDown(true);
          }}
        >
          <span className="cs-option">
            <div className="cs-option-name">${option}</div>
          </span>
        </li>
      );
    })}
  </ul>);

  return (
    <div className="admin-section-data-container border-bottom">
      <div className="admin-section-data-heading-container flex-start">
        <div className="module-section-heading-label">
          <b>Select the default Payment Escalator</b> you want to show first:
        </div>
      </div>
      <div className="details-container input-container width-90">
        <div className="select-container">
          <div className="label">Payment Escalator</div>
          <div
            id={PAYMENT_ESCALATOR_DROPDOWN_ID}
            ref={paymentEscalatorDropDownRef}
            className={cx('input-container input-container--icon icon-right dropdown-with-tags option-select',
              { disabled: !isFOEnabled }
            )}
          >
            <i className="icon icon-chevron-down" />
            <div className={cx('select-dropdown cs-skin-border', { 'cs-active': isPaymentEscalatorDropDownOpen })}>
              <span
                className="cs-placeholder"
                onClick={() => {
                  if (!isFOEnabled) {
                    setIsPaymentEscalatorDropDownOpen(false);
                    return;
                  }
                  togglePaymentEscalatorDropDown();
                }}
              >
                <div className="cs-option-name">{paymentEscalator === 0 || paymentEscalator ?
                  `${paymentEscalator}%` : 'Select Payment Escalator'}</div>
              </span>
              <div className="cs-options">
                {paymentEscalatorOptions}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
PaymentConfigView.propTypes = {
  financeType: PropTypes.string,
  availablePaymentEscalators: PropTypes.arrayOf(PropTypes.string),
  apAvailableDownPayments: PropTypes.arrayOf(PropTypes.number),
  updateApPaymentConfig: PropTypes.func,
  apFinConfig: PropTypes.objectOf(PropTypes.object),
  apIsFullPrepaymentAllowed: PropTypes.bool
};

export default register(
  [],
  [],
  PaymentConfigView
);
