import React, { useState, useEffect } from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import { register } from '../utils/redux';
import ValidatedModal from './ValidatedModal';
import Link from './Link';
import { quoteType, userDataType } from '../types';
import constants from '../utils/EddieCalculations/constants';
import { get } from '../selectors';

const {
  COMPLETED
} = constants;

export function FeedbackModal({ quote, saveFeedback, toggleModal, userData, storageQuote = {} }) {
  const [ feedbackSent, setFeedbackSent ] = useState(false);
  const [ rating, setRating ] = useState(null);
  const [ experience, setExperience ] = useState(null);
  const [ addThoughts, setAddThoughts ] = useState(null);
  const quoteSfid = storageQuote.sfid || quote.sfid;

  const closeModal = () => {
    toggleModal('feedback', null);
  };

  const sendFeedbackToSfdc = () => {
    const data = {
      quoteSfid,
      userId: userData.sfid,
      smiley: rating,
      experience,
      addThoughts,
      status: COMPLETED
    };
    saveFeedback(data);
    setFeedbackSent(true);
  };

  const feedbackForm = () => {
    return (
      <div>
        <div className="side-title">
          How was your overall quoting experience:
        </div>
        <div className="ratings-box">
          <div className="rating">
            <div
              className={cx('feedback-emoji emoji-1', { active: rating === 1 })}
              onClick={() => setRating(1)}
            />
            <span>Difficult</span>
          </div>
          <div className="rating">
            <div
              className={cx('feedback-emoji emoji-2', { active: rating === 2 })}
              onClick={() => setRating(2)}
            />
            <span />
          </div>
          <div className="rating">
            <div
              className={cx('feedback-emoji emoji-3', { active: rating === 3 })}
              onClick={() => setRating(3)}
            />
            <span />
          </div>
          <div className="rating">
            <div
              className={cx('feedback-emoji emoji-4', { active: rating === 4 })}
              onClick={() => setRating(4)}
            />
            <span />
          </div>
          <div className="rating">
            <div
              className={cx('feedback-emoji emoji-5', { active: rating === 5 })}
              onClick={() => setRating(5)}
            />
            <span>Easy</span>
          </div>
        </div>
        <div className="textarea-header">
          Why was the experience easy/difficult to use (optional):
        </div>
        <textarea
          placeholder="It was easy to..."
          disabled={!rating} value={experience}
          onChange={(e) => setExperience(e.target.value)}
          maxLength="255"
        />
        <hr />
        <div className="textarea-header">
          Any additional thoughts (optional):
        </div>
        <textarea
          placeholder="I would love if you..."
          disabled={!rating}
          value={addThoughts}
          onChange={(e) =>
          setAddThoughts(e.target.value)}
          maxLength="255"
        />
        <div className="buttons">
          <Link className="cancel-button">
            <div onClick={() => closeModal()}>Cancel</div>
          </Link>
          <Link
            className={cx('send-button', { disabled: !rating })}
            onClick={() => sendFeedbackToSfdc()}
          >
            <div>Send</div>
          </Link>
        </div>
      </div>
    );
  };

  const sentForm = () => {
    return (
      <div className="sent-form">
        <div className="check-mark">
          <div>&#10003;</div>
        </div>
        <div className="text-box">
          Thank you!
        </div>
        <span>
            You are Awesome.
        </span>
      </div>
    );
  };

  return (
    <ValidatedModal
      name="feedback"
      closeModalAfterOnSave={false}
      showButtons={false}
      title={feedbackSent ? '' : 'Help us improve!'}
    >
      <Link>
        <div className="close-button" onClick={() => closeModal()}>X</div>
      </Link>
      {feedbackSent ? sentForm() : feedbackForm()}
    </ValidatedModal>
  );
}

FeedbackModal.propTypes = {
  quote: quoteType.isRequired,
  storageQuote: quoteType.isRequired,
  closeModalAndRevertState: PropTypes.func.isRequired,
  saveFeedback: PropTypes.func.isRequired,
  toggleModal: PropTypes.func.isRequired,
  userData: userDataType
};

export default register(
  [ 'quoteSelector', 'userDataSelector' ],
  [ 'closeModalAndRevertState', 'saveFeedback', 'toggleModal' ],
  FeedbackModal
);
