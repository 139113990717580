import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { register } from '../utils/redux';
import { selectedStorageType, quoteType, dropdownsType, settingsType } from '../types';

const defaultContainerClasses = 'input-container input-container--icon icon-right dropdown-with-tags';

export function StorageQuantityInput({
  selectedStorage,
  dropdowns: { isStorageQuantityOpen },
  onSelect,
  toggleStorageQuantityDropdown,
  quote,
  settings,
  partnerIsNewHomes
}) {
  const limitStorageQuantity = partnerIsNewHomes;
  const maxPowerwallQuantity = limitStorageQuantity ? 1 : 5;
  const maxEquinoxQuantity = (settings.loan && settings.loan.showCustomer) ? 2 : limitStorageQuantity ? 1 : 2;

  const isPowerwall = selectedStorage.name.includes('Powerwall');
  const isEquinox = selectedStorage.name.toLowerCase().includes('sunvault');
  const powerwallRange = Array.from({ length: maxPowerwallQuantity }, (v, i) => i + 1);
  const equinoxRange = Array.from({ length: maxEquinoxQuantity }, (v, i) => i + 1);
  const rangeValue = isEquinox ? equinoxRange : powerwallRange;
  const options = (<ul>
    {
      rangeValue.map((i) => (
        <li
          className={cx('cs-option-container', { 'cs-selected': quote.storageQuantity === i })}
          onClick={() => {
            toggleStorageQuantityDropdown();
            onSelect(i);
          }}
          key={i}
        >
          <span className="cs-option">
            <div className="cs-option-name">{i}</div>
          </span>
        </li>
      ))
    }
  </ul>);

  const storageQuantity = quote.storageQuantity || 0;

  return isPowerwall || isEquinox ? (
    <div
      id="storage_quantity_dropdown"
      className={cx(defaultContainerClasses, { 'is-disabled': quote.locked })}
    >
      <i className="icon icon-chevron-down" />
      <div
        className={cx('select-dropdown cs-skin-border', { 'cs-active': isStorageQuantityOpen })}
        tabIndex="0"
      >
        <span className="cs-placeholder" onClick={toggleStorageQuantityDropdown}>
          <div className="cs-option-name">{quote.storageQuantity}</div>
        </span>
        <div className="cs-options">
          { options }
        </div>
      </div>
    </div>
  ) : (
    <div id="storage_quantity_dropdown" className="input-container">
      <input type="text" value={storageQuantity} disabled={true} />
    </div>
  );
}

StorageQuantityInput.propTypes = {
  selectedStorage: selectedStorageType.isRequired,
  dropdowns: dropdownsType.isRequired,
  quote: quoteType.isRequired,
  onSelect: PropTypes.func.isRequired,
  toggleStorageQuantityDropdown: PropTypes.func.isRequired,
  partnerIsNewHomes: PropTypes.bool.isRequired,
  settings: settingsType.isRequired
};

export default register(
  [
    'selectedStorageSelector',
    'dropdownsSelector',
    'quoteSelector',
    'settingsSelector',
    'partnerIsNewHomesSelector'
  ],
  ['toggleStorageQuantityDropdown'],
  StorageQuantityInput
);

