import u from 'updeep';
import get from 'lodash/get';

import { datadogRum } from '@datadog/browser-rum';

import { asyncPayload } from './apiHelpers';
import Api from '../utils/Api';
import { datadogTrackLoadingTime } from '../utils/datadogUserMonitoring';
import { updateSalesforceQuote } from './updateSalesforceQuote';
import { setDrafSessionInLocalStorage } from '../utils/common';

function logDatadogTiming(startTime, response, firstLoad = false) {
  const loadingTime = Date.now() - startTime;
  const quote = response.quote;
  const settings = response.settings;
  const design = response.design;
  if (quote && quote.sfid && design && design.id) {
    datadogRum.addRumGlobalContext('quote', {
      sfid: quote.sfid,
      locked: !!quote.locked,
      type: design.isInstant ? 'instant' : design.designType,
      version: quote.version,
      firstLoad: !!firstLoad
    });
    datadogTrackLoadingTime(
      'Quote Load',
      loadingTime,
      quote,
      settings,
      null,
      null
    );
  }
}

export const fetchManualModeQuote = (quoteSfid, queryPart = '') => (dispatch) => {
  const startTime = Date.now();
  let firstLoad = false;
  let quoteResponse = {};

  const promise = Api.fetch(`locked_quotes/${quoteSfid}`)
    .then((resp) => {
      if (resp.data) {
        logDatadogTiming(startTime, resp.data);
        const quote = get(resp, 'data.quote');
        if (get(quote, 'locked')) {
          dispatch(updateSalesforceQuote(quoteSfid));
        }
        return resp.data;
      }

      setDrafSessionInLocalStorage();

      let state;
      const addersWithQuoteDataPromise = Api.fetch(`quotes_status/${quoteSfid}`)
      .then((response) => {
        firstLoad = !response.present;

        return Api.fetchWithRetry(`manual_mode/quotes/${quoteSfid}${queryPart}`);
      })
      .then((response) => {
        if (response.settings && response.settings.lease) {
          quoteResponse = Object.assign(response, {
            settings: {
              ...response.settings,
              lease: {
                ...response.settings.lease,
                fetchingFinEngAdders: response.settings.lease.adders,
                fetchingFinEngDiscount: response.settings.lease.discount,
                fetchingFinEngOverrideRebate: response.settings.lease.overrideRebate
              }
            }
          });
        }
        return Api.fetch(`manual_mode/adders_schema/?quote_sfid=${quoteSfid}`);
      })
      .then((schema) => {
        state = {
          schema
        };
        logDatadogTiming(startTime, quoteResponse, firstLoad);
        return u(quoteResponse, state);
      });
      return addersWithQuoteDataPromise;
    });

  dispatch({
    type: 'fetchManualModeQuote',
    payload: asyncPayload({
      beforeRequest: { loading: true },
      request: promise
    })
  });
};
