import Heapify from 'heapify';

export const selectPanels = (modules, topology, annualUsage, modulesSpec) => {
  const queue = new Heapify(modules.length * 3);
  const queued = new Set();
  const modulesIndex = {};
  let modulesIdx = 1;
  const byPower = (moduleA, moduleB) => {
    return moduleB.production[modulesSpec.id] - moduleA.production[modulesSpec.id];
  };
  const modulesByPower = Object.values(modules).sort(byPower);
  const selectedIds = new Set();
  let selectedProduction = 0;

  const addNeighbours = (moduleId, topology) => {
    topology[moduleId].forEach((neighbourId) => {
      if (!queued.has(neighbourId)) {
        const neighbour = modules.find((m) => m.id === neighbourId);
        queue.push(modulesIdx, 10000 - neighbour.production[modulesSpec.id]);
        queued.add(neighbourId);
        modulesIndex[modulesIdx] = [neighbourId];
        modulesIdx += 1;
      }
    });
  };

  const addModule = (moduleId) => {
    selectedIds.add(moduleId);
    const module = modules.find((m) => m.id === moduleId);
    selectedProduction += module.production[modulesSpec.id];
    addNeighbours(moduleId, topology.rowNeighbours);
    if (topology.rowNeighbours[moduleId].some((neighbourId) => selectedIds.has(neighbourId))) {
      addNeighbours(moduleId, topology.topdownNeighbours);
      topology.rowNeighbours[moduleId].forEach((neighbourId) => {
        if (selectedIds.has(neighbourId)) {
          addNeighbours(neighbourId, topology.topdownNeighbours);
        }
      });
    }
  };

  Object.keys(topology.rowNeighbours).forEach((moduleId) => {
    topology.rowNeighbours[moduleId].forEach((otherModuleId) => {
      if (!queued.has(moduleId + otherModuleId) && !queued.has(otherModuleId + moduleId)) {
        const module = modules.find((m) => m.id === moduleId);
        const otherModule = modules.find((m) => m.id === otherModuleId);
        queue.push(modulesIdx,
          10000 - (module.production[modulesSpec.id] + otherModule.production[modulesSpec.id]) / 2);
        modulesIndex[modulesIdx] = [ moduleId, otherModuleId ];
        modulesIdx += 1;
        queued.add(moduleId + otherModuleId);
      }
    });
  });
  while (selectedProduction < annualUsage) {
    if (queue.size === 0) {
      const nextSoloModule = modulesByPower.find((m) => !selectedIds.has(m.id));
      if (nextSoloModule === undefined) {
        break;
      }
      queued.add(nextSoloModule.id);
      selectedIds.add(nextSoloModule.id);
      selectedProduction += nextSoloModule.production[modulesSpec.id];
      addNeighbours(nextSoloModule.id, topology.topdownNeighbours);
    } else {
      const nextModulesIdx = queue.pop();
      const nextModules = modulesIndex[nextModulesIdx];
      if (nextModules !== undefined && !nextModules.some((moduleId) => selectedIds.has(moduleId))) {
        nextModules.forEach(addModule);
      }
    }
  }
  modules.forEach((module) => {
    if (selectedIds.has(module.id)) {
      module.active = true;
    }
  });
};
