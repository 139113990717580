export function newInstantQuoteSaveDesignRequest() {
  return {
    type: 'newInstantQuoteSaveDesignRequest',
    payload: { instantQuoteSaveDesignRequest: Date.now() }
  };
}

export function setInstantQuoteSyncDesign(needSync) {
  return {
    type: 'setInstantQuoteSyncDesign',
    payload: {
      instantQuoteSyncDesign: needSync
    }
  };
}

export function setInstantQuoteSaveDesignPreviousRequest(request) {
  return {
    type: 'setInstantQuoteSaveDesignPreviousRequest',
    payload: { instantQuoteSaveDesignPreviousRequest: request }
  };
}
