import { asyncPayload } from './apiHelpers';
import Api from '../utils/Api';

export function saveFeedback(data) {
  return {
    type: 'saveFeedback',
    payload: asyncPayload({
      request: Api.create('csat_feedbacks', {
        csat_feedback: data
      })
    })
  };
}
