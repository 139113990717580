import React from 'react';
import PropTypes from 'prop-types';
import getErrors from './getErrors';

export default class GroupValidator extends React.Component {
  constructor(props) {
    super(props);

    this.attachToValidatorContainer = this.attachToValidatorContainer.bind(this);
    this.detachFromValidatorContainer = this.detachFromValidatorContainer.bind(this);

    this.name = this.props.name;
    this.validators = this.props.validators;
    this.inputs = {};
  }

  getChildContext() {
    return {
      attachToValidatorContainer: this.attachToValidatorContainer,
      detachFromValidatorContainer: this.detachFromValidatorContainer
    };
  }

  validate() {
    const validators = this.props.validators;
    const values = Object.keys(this.inputs).map((name) => this.inputs[name].getValue());
    const errors = getErrors(validators, values);

    return errors;
  }

  isValid() {
    return this.validate().length === 0;
  }

  attachToValidatorContainer(component) {
    this.inputs[component.props.name] = component;

    if (this.context.attachToValidatorContainer) {
      this.context.attachToValidatorContainer(this);
      this.context.attachToValidatorContainer(component);
    }
  }

  detachFromValidatorContainer(component) {
    delete this.inputs[component.props.name];

    if (this.context.detachFromValidatorContainer) {
      this.context.detachFromValidatorContainer(this);
      this.context.detachFromValidatorContainer(component);
    }
  }

  render() {
    const { className } = this.props;
    const { style } = this.props;

    return (
      <div style={style} className={className}>
        {this.props.children}
      </div>
    );
  }
}
GroupValidator.propTypes = {
  name: PropTypes.string.isRequired,
  className: PropTypes.string,
  style: PropTypes.objectOf(PropTypes.string),
  children: PropTypes.oneOfType([ PropTypes.object, PropTypes.arrayOf(PropTypes.element) ]).isRequired,
  validators: PropTypes.arrayOf(PropTypes.func).isRequired
};
GroupValidator.childContextTypes = {
  attachToValidatorContainer: PropTypes.func,
  detachFromValidatorContainer: PropTypes.func
};
GroupValidator.contextTypes = {
  attachToValidatorContainer: PropTypes.func,
  detachFromValidatorContainer: PropTypes.func
};
