import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { register } from '../../utils/redux';
import { quoteType, accountType, pvModuleType, settingsType } from '../../types';
import RemoveStorageModalButton from '../RemoveStorageModalButton';

const BATTERY_13KWH = 13;
const BATTERY_6KWH = 6.5;

// eslint-disable-next-line react/prefer-stateless-function
const StorageSettings = ({
  quote,
  account,
  updateStorage,
  toggleAddStorageModalFlag,
  pvModule,
  settings,
  instantQuoteMode
}) => {
  const [ battery13kWhCounter, setBattery13kWhCounter ] = useState(0);
  const [battery6kWhCounter] = useState(0);
  const [ backup, setBackup ] = useState('essential');

  const [ units, setUnits ] = useState(0);
  const [ unitsImage, setUnitsImage ] = useState(0);
  const [ kWh, setKWh ] = useState(0);
  const [ maxQuantity, setMaxQuantity ] = useState(2);

  const onCancel = useCallback((event) => {
    event.preventDefault();
    toggleAddStorageModalFlag();
  }, [toggleAddStorageModalFlag]);

  const findStorageConfigId = useCallback((wattage) => {
    const storage = pvModule.storageUnits.find(
      (storage) => storage.name.toLowerCase() === `sunvault ${wattage}`);
    return storage.id;
  }, [pvModule]);

  const onSave = useCallback((event) => {
    if (battery13kWhCounter === 0 || quote.locked) return;
    const params = {
      storageConfigId: findStorageConfigId(13),
      storageQuantity: battery13kWhCounter,
      storageBackup: backup
    };
    updateStorage(quote, params);
    event.preventDefault();
    toggleAddStorageModalFlag();
  }, [ battery13kWhCounter, quote, backup, updateStorage, toggleAddStorageModalFlag, findStorageConfigId ]);

  const isNewHomes = useCallback(() => {
    if (account &&
      account.partner &&
      account.partner.sectorLease.toLowerCase() === 'new homes') {
      return true;
    }
    return false;
  }, [account]);

  const handleMinusClick = useCallback(() => {
    if (!quote.locked) {
      setBattery13kWhCounter(battery13kWhCounter - 1 < 0 ? 0 : battery13kWhCounter - 1);
    }
  }, [ quote, setBattery13kWhCounter, battery13kWhCounter ]);

  const handlePlusClick = useCallback(() => {
    if (!quote.locked) {
      setBattery13kWhCounter(
        battery13kWhCounter + 1 > maxQuantity ? maxQuantity : battery13kWhCounter + 1);
    }
  }, [ quote, setBattery13kWhCounter, battery13kWhCounter, maxQuantity ]);

  useEffect(() => {
    setBattery13kWhCounter(quote && quote.storageQuantity ? quote.storageQuantity : 0);
    setBackup(quote && quote.storageBackup ? quote.storageBackup : 'essential');
  }, [ setBattery13kWhCounter, setBackup, quote ]);

  useEffect(() => {
    const newUnits = battery13kWhCounter + battery6kWhCounter;
    setUnits(newUnits);
    setUnitsImage(newUnits > 4 ? 4 : newUnits);
    setKWh(battery13kWhCounter * BATTERY_13KWH + battery6kWhCounter * BATTERY_6KWH);
  }, [ battery13kWhCounter, battery6kWhCounter, setUnits, setUnitsImage, setKWh ]);

  useEffect(() => {
    let newMaxQuantity = 2;
    if (settings.lease && settings.lease.showCustomer && isNewHomes()) {
      newMaxQuantity = 1;
    }

    setMaxQuantity(newMaxQuantity);
  }, [ setMaxQuantity, settings, isNewHomes ]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="add-storage-settings">
      <div className="add-storage-settings-content">
        <div className="add-storage-settings-content-elements">
          <div className="storage-settings-options">
            <div className="storage-settings-option left single">
              <div className="storage-settings-option-top">
                <div className="storage-selectors">
                  <span
                    className={cx(
                      'selector-action',
                      'minus',
                      { disabled: battery13kWhCounter === 0 || quote.locked }
                    )}
                    onClick={handleMinusClick}
                  >&minus;</span>
                  <div className="selector-counter-with-batter">
                    <div className="storage-settings-battery">
                      <img src="/build/images/storage-equinox-selection.png" alt="Battery" width="100" />
                    </div>
                    <div className="selector-counter">{battery13kWhCounter}</div>
                  </div>
                  <span
                    className={cx(
                      'selector-action',
                      'plus',
                      { disabled: battery13kWhCounter === maxQuantity || quote.locked }
                    )}
                    onClick={handlePlusClick}
                  >+</span>
                </div>
              </div>
              <div className="storage-settings-option-bottom">
                <h4 className="storage-section-title">13 kWh Battery</h4>
                <p className="storage-section-description">
                  Our strongest battery that supplies energy for
                  longer periods of time and can handle larger
                  energy loads.
                </p>
              </div>
            </div>
          </div>
          <div className="storage-settings-info">
            <img
              className="storage-units" src={`/build/images/storage-${unitsImage}-units.svg`}
              alt="Energy units"
            />
            <div className="description">
              <div>Energy with <span className="bold">{ units }</span> batter{`${units !== 1 ? 'ies' : 'y'}`}:</div>
              <div className="storage-settings-info-prod">
                <span>{ kWh } kWh</span>
              </div>
            </div>
          </div>
        </div>
        <div
          className={cx('add-storage-settings-content-elements storage-top-dashed storage-graph-container', {
            'instant-quote-mode': instantQuoteMode
          })}
        >
          <img src="/build/images/static-graph.svg" alt="Static Graph" />
          <div className="add-storage-settings-action-container">
            <button className="btn-storage-cancel" onClick={onCancel}>Cancel</button>
            <button
              className={cx('btn-storage-confirm', { disabled: battery13kWhCounter === 0 || quote.locked })}
              onClick={onSave}
            >Add</button>
          </div>

          {instantQuoteMode ? (
            <RemoveStorageModalButton />
          ) : null}
        </div>
      </div>
    </div>
  );
};

StorageSettings.propTypes = {
  quote: quoteType.isRequired,
  account: accountType.isRequired,
  updateStorage: PropTypes.func.isRequired,
  toggleAddStorageModalFlag: PropTypes.func.isRequired,
  pvModule: pvModuleType,
  settings: settingsType.isRequired,
  instantQuoteMode: PropTypes.bool
};

export default register(
  [
    'quoteSelector', 'accountSelector', 'pvModuleSelector',
    'settingsSelector', 'instantQuoteModeSelector'
  ],
  [
    'updateStorage', 'toggleAddStorageModalFlag'
  ],
  StorageSettings
);
