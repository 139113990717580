import PropTypes from 'prop-types';

export const totalSavingsDatumType = PropTypes.shape({
  withSolarElectricBill: PropTypes.number.isRequired,
  withoutSolarElectricBill: PropTypes.number.isRequired,
  solarPayments: PropTypes.number.isRequired,
  solarSavings: PropTypes.number.isRequired,
  solarSavingsForCash: PropTypes.number.isRequired,
  netCost: PropTypes.number.isRequired
});

export const totalSavingsDataType = PropTypes.shape({
  savings0: totalSavingsDatumType.isRequired,
  savings: totalSavingsDatumType.isRequired,
  savings4: totalSavingsDatumType.isRequired
});

export const totalSavingsChartType = PropTypes.shape({
  cash: totalSavingsDataType,
  lease: totalSavingsDataType,
  loan: totalSavingsDataType
});
