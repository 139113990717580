import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Slider from 'rc-slider';
import cx from 'classnames';
import ReactTooltip from 'react-tooltip';
import { register } from '../../utils/redux';
import { formatCurrency } from '../../utils/formatNumber';
import {
  leaseSystemCostType,
  ppaSystemCostType,
  settingsType,
  quoteType
} from '../../types';
import CloseIcon from '../../../images/close.svg';
import PrepaymentVector from '../../../images/prepaymentVector.svg';
import constants from '../../utils/EddieCalculations/constants';

const { LEASE_FINANCE_TYPE } = constants;

export function PrepaymentView({ activeTab, settings, quote }) {
  const availableEscalaton = Object.keys(quote.finEng[`${activeTab}Escalator`]).sort();
  const [ activeEscalator, setActiveEscalator ] =
    useState(settings[activeTab].paymentEscalator.toFixed(1).toString() || availableEscalaton[0]);
  const [ selectedYear, setSelectedYear ] = useState(0);
  const [ endMonth, setEndMonth ] = useState(11);
  const escalatorData = quote.finEng && quote.finEng[`${activeTab}Escalator`] ?
    quote.finEng[`${activeTab}Escalator`][activeEscalator] : [];
  const monthlyPayments = escalatorData.rentPayments || [];
  const estimatedPrepayments = escalatorData.estimatedPrepayments || [];
  const marks = { 0: 1, 5: 5, 10: 10, 15: 15, 20: 20, 25: 25 };

  const activeTabFo = activeTab === LEASE_FINANCE_TYPE ?
    activeTab.charAt(0).toUpperCase() + activeTab.slice(1) : activeTab.toUpperCase();

  const disabledEscalator = (escalator) => {
    return (
      quote.finEng &&
      !!quote.finEng[`${activeTab}Escalator`] &&
      !!quote.finEng[`${activeTab}Escalator`][escalator] &&
      !!quote.finEng[`${activeTab}Escalator`][escalator].estimatedPrepayments
    );
  };

  const prepaymentVerbage = `Estimated Prepayment assumes that a Prepayment is made at the first month of a 
  contract year and that no payments are outstanding. The actual Prepayment will be based on the month 
  that the Prepayment is made: if the ${activeTabFo} Term Start Date is January 1, the estimated Prepayment 
  assumes that the Prepayment is also made on January 1 of the relevant contract year.
  If, for example, the Prepayment is made on June 1, the actual Prepayment will be calculated based 
  on outstanding and remaining payments at that time.`;

  const onSliderChange = (value) => {
    setSelectedYear(value);
    if (value === 0) {
      setEndMonth(11);
    } else {
      setEndMonth(12);
    }
  };

  return (
    <div>
      { !!monthlyPayments.length && (
      <div className="prepayment-tab">
        <div className="prepayment-vector"><PrepaymentVector /></div>
        <div className="escalation section">
          <div className="label">Escalating at:</div>
          <div className="values">
            {availableEscalaton.map((escalationValue) => {
              return (
                <div>
                  {disabledEscalator(escalationValue) && (
                    <div
                      onClick={() => setActiveEscalator(escalationValue)}
                      className={cx('escalator-value', { active: escalationValue === activeEscalator })}
                    >
                      {escalationValue}%
                    </div>
                  )}
                </div>
              );
            })}
          </div>
        </div>
        <div className="monthly-prepayment section">
          <div className="title-section"><strong>Year {selectedYear + 1} </strong>- {endMonth} monthly payments:</div>
          <div className="content-section">
            <div className="value-section">
              <h1>{formatCurrency(monthlyPayments[selectedYear], 0)}</h1>
              <span>Monthly Payment</span>
            </div>
            <div className="prepayment-section">
              <div className="prepayment-value-section">
                <h1>{formatCurrency(estimatedPrepayments[selectedYear])}</h1>
                <span>Est. Prepayment</span>
              </div>
              <a data-tip={true} data-event="click focus" data-for="prepayment-tooltip"ß={true}>
                <img src="/build/images/info-blue.svg" alt="info" className="info-img" />
              </a>
              <ReactTooltip
                offset={{ top: 80, right: 100 }}
                id="prepayment-tooltip"
                globalEventOff="click"
                event="click"
                type="light"
                place="left"
                clickable={true}
                multiline={true}
                className="prepayment-tooltip"
              >
                <CloseIcon
                  style={{ float: 'right' }}
                  onClick={() => {
                    document.querySelector('#prepayment-tooltip').style = 'visibility: none';
                  }}
                />
                <p>
                  This represents the total combined monthly payments for the year selected at the escalator rate.
                </p>
              </ReactTooltip>
            </div>
          </div>
        </div>
        <div className="term-bar">
          <Slider
            value={selectedYear}
            onChange={onSliderChange}
            max={24}
            step={1}
            min={0}
            marks={marks}
            className={'magnetize'}
          />
        </div>
        <div className="monthly-contract">Monthly {activeTabFo} payments-contract year</div>
        <div className="breakout-line-items">
          <hr />
          <span className="line-item breakout-summary-breakdown supporting-text">
            <div className="label">
              {prepaymentVerbage}
            </div>
          </span>
          <hr />
        </div>
      </div>
      )}
    </div>
  );
}

PrepaymentView.propTypes = {
  activeTab: PropTypes.string,
  settings: settingsType.isRequired,
  quote: quoteType.isRequired
};

export default register(
  [
    'settingsSelector', 'leaseSystemCostSelector', 'ppaSystemCostSelector', 'quoteSelector'
  ],
  [],
  PrepaymentView
);
