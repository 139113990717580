import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { register } from '../../../utils/redux';
import { validateInput } from '../../../utils/admin_portal/validationHelper';

export function InputFieldWithUnit({ label, placeHolder, value, unit, symbol, onChange,
  disabled, key, errorMessageLabel = '' }) {
  const [ inputValue, setInputValue ] = useState(value);
  const handleOnChange = (value) => {
    const newValue = validateInput(value);
    setInputValue(newValue);
    onChange(newValue);
  };

  useEffect(() => {
    setInputValue(value);
  }, [value]);

  return (
    <div
      key={key}
      className="input-field-container-with-label"
    >
      <label className="label">{label}</label>
      <div className={cx('input-field-container', { disabled })}>
        {
          symbol &&
          <label className="input-symbol-field">{symbol}</label>
        }
        <input
          type="text"
          placeholder={placeHolder}
          className="input-field"
          value={inputValue}
          onChange={(e) => handleOnChange(e.target.value)}
          disabled={disabled}
        />
        {
          unit &&
          <label className="input-unit-field">{unit}</label>
        }
      </div>
      <span className="error">
        { errorMessageLabel || null }
      </span>
    </div>
  );
}

InputFieldWithUnit.propTypes = {
  label: PropTypes.string.isRequired,
  placeHolder: PropTypes.string,
  value: PropTypes.string,
  unit: PropTypes.string,
  onChange: PropTypes.func,
  symbol: PropTypes.string,
  errorMessageLabel: PropTypes.string,
  disabled: PropTypes.bool,
  key: PropTypes.string
};

export default register(
  [],
  [],
  InputFieldWithUnit
);
