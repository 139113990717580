import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';

import { Chart } from 'chart.js';

import omit from 'lodash/omit';
import { get } from 'lodash';
import { formatCurrency } from '../../utils/formatNumber';

import styles from '../../../scss/charts/SavingsOverTimeChart.module.scss';

Chart.defaults.global.defaultFontColor = '#000000';
Chart.defaults.global.defaultFontFamily = 'Open Sans';
Chart.defaults.global.defaultFontSize = 9;

/* eslint no-underscore-dangle: 0 */

const allValues = (graphSeries) => {
  return Object.keys(graphSeries).map((key) => graphSeries[key]).reduce((acc, arr) => acc.concat(arr), []);
};

const labelText = {
  cash: {
    annualBillsBefore: 'Cost of Electricity With Solar',
    annualBillsAfter: 'Cost of Electricity Without Solar'
  },
  loan: {
    annualBillsBefore: 'Electricity Cost With Solar',
    annualBillsAfter: 'Electricity Cost Without Solar'
  },
  lease: {
    annualBillsBefore: 'Electricity Cost With Solar',
    annualBillsAfter: 'Electricity Cost Without Solar'
  }
};

let lineChart;

const savingsOverTimeChart = ({
  data,
  selectedInflationRate,
  setChartXIndex,
  selectedFinanceOption,
  isPDFProcessing,
  term
}) => {
  const chartRef = useRef(null);

  const buildChart = () => {
    const canvasElm = document.getElementById('savings-over-time-chart-canvas');
    if (!canvasElm) {
      return null;
    }
    const myChartRef = canvasElm.getContext('2d');

    if (typeof lineChart !== 'undefined') {
      lineChart.destroy();
    }

    const labels = get(data, 'graphSeries.labels');

    const inputGraphSeries = omit(data.graphSeries, ['labels']);

    const beforeGradient = myChartRef.createLinearGradient(0, 0, 0, chartRef.current.height);
    if (beforeGradient) {
      beforeGradient.addColorStop(0, 'rgba(106, 164, 66, 1)');
      beforeGradient.addColorStop(1, 'rgba(107, 196, 45, 1)');
    }

    const beforeBorderColor = '#000000';

    const colorConfig = {
      annualBillsBefore: {
        backgroundColor: beforeGradient,
        fill: '+1',
        borderColor: beforeBorderColor,
        borderWidth: 3,
        order: 1
      },
      annualBillsAfter: {
        backgroundColor: '#FFFFFF',
        fill: true,
        borderColor: '#000000',
        borderWidth: 3,
        borderDash: [ 5, 5 ],
        order: 0
      }
    };

    const lineConfig = {
      annualBillsBefore: {
        lineTension: 0.4
      },
      annualBillsAfter: {
        lineTension: 0
      }
    };

    const datasets = Object.keys(inputGraphSeries).map((key) => {
      return {
        label: key,
        data: inputGraphSeries[key],
        pointRadius: 0,
        ...lineConfig[key],
        ...colorConfig[key]
      };
    });

    const values = allValues(inputGraphSeries);
    const max = Math.max(...values);
    const tickCount = 5;
    const ticks = {
      max,
      min: 0,
      stepSize: max / tickCount,
      fontColor: '#000000',
      fontFamily: 'Open Sans',
      fontSize: 12,
      callback: (value, _index, _values) => {
        return formatCurrency(value, 0);
      }
    };

    const options = {
      responsive: true,
      maintainAspectRatio: false,
      legend: {
        display: false
      },
      tooltips: {
        enabled: false,
        intersect: false,
        callbacks: {
          label: (tooltipItem, data) => {
            const label = data.datasets[tooltipItem.datasetIndex].label || '';
            let text = '';
            if (label) {
              text += labelText[`${selectedFinanceOption}`][`${label}`];
              text += ': ';
            }
            text += formatCurrency(tooltipItem.yLabel, 0);
            return text;
          }
        }
      },
      scales: {
        yAxes: [{
          ticks,
          gridLines: {
            drawOnChartArea: false
          }
        }],
        xAxes: [{
          ticks: {
            fontSize: 9
          },
          gridLines: {
            drawOnChartArea: false
          }
        }]
      }
    };
    if (isPDFProcessing) options.animation = { duration: 0 };

    const plugins = [
      {
        afterDatasetsDraw: (chart) => {
          const ctx = chart.ctx;
          if (term) {
            // draw end of loan/lease term vertical line if available
            const endTermPoint = chart.getDatasetMeta(1).data[term - 1]._model;
            const endTermX = endTermPoint.x;
            const endTermTopY = endTermPoint.y;
            const endTermbottomY = chart.chartArea.bottom;

            // draw line
            ctx.save();
            ctx.beginPath();
            ctx.moveTo(endTermX, endTermTopY);
            ctx.lineTo(endTermX, endTermbottomY);
            ctx.lineWidth = 4;
            ctx.strokeStyle = '#6AA442';
            ctx.stroke();
            ctx.restore();
          }

          if (chart.tooltip._active && chart.tooltip._active.length) {
            const xIndex = chart.tooltip._active[0]._index;
            setChartXIndex(xIndex);

            const activePoint = chart.tooltip._active[0];
            const x = activePoint.tooltipPosition().x;
            const topY = chart.legend.bottom;
            const bottomY = chart.chartArea.bottom;

            // draw line
            ctx.save();
            ctx.beginPath();
            ctx.moveTo(x, topY);
            ctx.lineTo(x, bottomY);
            ctx.lineWidth = 2;
            ctx.strokeStyle = '#000000';
            ctx.stroke();
            ctx.restore();
          }
        }
      }
    ];

    lineChart = new Chart(myChartRef, {
      type: 'line',
      data: {
        labels,
        datasets
      },
      options,
      plugins
    });

    return lineChart;
  };

  useEffect(() => {
    buildChart();
  }, []);

  useEffect(() => {
    buildChart();
  }, [selectedFinanceOption]);

  useEffect(() => {
    buildChart();
  }, [selectedInflationRate]);

  return (
    <div className={styles.chartContainer}>
      <canvas
        id="savings-over-time-chart-canvas"
        ref={chartRef}
      />
    </div>
  );
};

savingsOverTimeChart.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  data: PropTypes.object,
  selectedInflationRate: PropTypes.string,
  setChartXIndex: PropTypes.func,
  selectedFinanceOption: PropTypes.string,
  isPDFProcessing: PropTypes.bool,
  term: PropTypes.number
};
export default savingsOverTimeChart;
