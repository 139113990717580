import { datadogRum } from '@datadog/browser-rum';
import get from 'lodash/get';
import Api from '../utils/Api';
import constants from '../utils/admin_portal/constants';
import { getStateNameFromAbbreviation } from '../utils/admin_portal/stateNameHelper';
import { datadogTrackLoadingTime } from '../utils/datadogUserMonitoring';
import { asyncPayload } from './apiHelpers';

function logDatadogTiming(startTime, response) {
  const loadingTime = Date.now() - startTime;
  const userId = response.userData.sfid;
  const partnerId = response.data.apFinConfigs[0].partnerAccountId;
  if (userId) {
    datadogRum.addRumGlobalContext('adminPortal', {
      userSfid: userId,
      partnerId
    });
    datadogTrackLoadingTime(
      'AdminPortal Load',
      loadingTime,
      userId,
      partnerId,
      null,
      null
    );
  }
}

const formattedResponse = (response) => {
  const { AVAILABLE_FINANCIAL_OFFERINGS } = constants;
  const apSalesforce = get(response, 'apSalesforce', null);
  const apFinConfigs = get(response, 'apFinConfigs', null);
  const historyLog = get(response, 'historyLog', null);
  const dealersFeatureFlags = get(response, 'dealersFeatureFlags', null);
  const graphSelections = get(response, 'graphSelections', null);
  const pricingControls = get(response, 'pricingControls', null);
  const storageProductConfig = get(response, 'storageProductConfig', null);
  const partnerChannel = get(response, 'partnerChannel', null);
  const account = get(response, 'account', null);
  const apDownPayments = get(response, 'apDownPayments', null);
  const prepaymentAllowedStates = get(response, 'prepaymentAllowedStates', null);
  const financialOfferings = apSalesforce.financialOfferings;
  const financialOptions = AVAILABLE_FINANCIAL_OFFERINGS.filter((fo) => (Object.keys(financialOfferings)).includes(fo));
  const allAvailableStates = {};
  const pvModuleSeriesDetails = get(response, 'pvModuleSeriesDetails', []);
  financialOptions.forEach((option) => {
    allAvailableStates[option] = Object.keys(financialOfferings[option])
      .map((abbreviation) => {
        return { abbreviation, name: getStateNameFromAbbreviation(abbreviation), isChecked: false };
      });
  });
  return {
    financialOfferings,
    financialOptions,
    allAvailableStates,
    apFinConfigs,
    historyLog,
    dealersFeatureFlags,
    graphSelections,
    pricingControls,
    storageProductConfig,
    partnerChannel,
    account,
    apSalesforce,
    apDownPayments,
    prepaymentAllowedStates,
    pvModuleSeriesDetails
  };
};

export function fetchAdminPortal() {
  const startTime = Date.now();
  const promise = Api.fetchForAdmin('admin_portals')
  .then((resp) => {
    logDatadogTiming(startTime, resp);
    return formattedResponse(resp.data);
  });

  return {
    type: 'fetchAdminPortal',
    payload: asyncPayload({
      beforeRequest: { loading: true },
      request: promise
    })
  };
}
