import React from 'react';

import { register } from '../../utils/redux';
import { storageOnlyDynamicProposalEnabled } from '../../utils/backendFeatureFlags';

import DynamicProposalPage from './dynamic_proposal_page/DynamicProposalPage';
import ProposalPage from './proposal_page/StorageQuoteProposalPage';


const StorageOnlyProposalContainer = ({ partnerIsNewHomes, storageQuote }) => {
  const renderBody = () => {
    if (storageOnlyDynamicProposalEnabled() && storageQuote.version >= 5) {
      if (partnerIsNewHomes) return (<ProposalPage />);
      return (<DynamicProposalPage />);
    }
    return (<ProposalPage />);
  };

  return (renderBody());
};

export default register(
  [ 'partnerIsNewHomesSelector', 'showDynamicProposal', 'storageQuoteSelector' ],
  [ ],
  StorageOnlyProposalContainer
);
