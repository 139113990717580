import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { register } from '../../../utils/redux';
import { dropdownsType, pvModuleType, quoteType, settingsType } from '../../../types';
import { getAvailableStorageQuantities } from '../../../utils/storageWithMultiInverter';

const defaultContainerClasses = 'input-container input-container--icon icon-right dropdown-with-tags quantity-field';

export function StorageQuantityInputForMultiInverters({
                                                        pvModule,
                                                        selectedStorageName,
                                                        selectedStorageQuantity,
                                                        dropdowns: { isStorageQuantityWithMultiInverterOpen },
                                                        onSelect,
                                                        toggleStorageQuantityWithMultiInverterDropdown,
                                                        quote,
                                                        settings
                                                      }) {
  const storageQuantities = getAvailableStorageQuantities(pvModule, selectedStorageName, quote, settings);
  const storageQuantity = selectedStorageQuantity || quote.storageQuantity || 0;
  const options = (<ul>
    {
      storageQuantities.map((i) => (
        <li
          className={cx('cs-option-container', { 'cs-selected': storageQuantity === i })}
          onClick={() => {
            toggleStorageQuantityWithMultiInverterDropdown();
            onSelect(i);
          }}
          key={`quantity${i}`}
        >
          <span className="cs-option">
            <div className="cs-option-name">{i}</div>
          </span>
        </li>
      ))
    }
  </ul>);
  return options.props.children.length > 0 ? (
    <div
      id="storage_quantity_dropdown"
      className={cx(defaultContainerClasses, { 'is-disabled': quote.locked })}
    >
      <i className="icon icon-chevron-down" />
      <div
        className={cx('select-dropdown cs-skin-border', { 'cs-active': isStorageQuantityWithMultiInverterOpen })}
        tabIndex="0"
      >
        <span className="cs-placeholder" onClick={toggleStorageQuantityWithMultiInverterDropdown}>
          <div className="cs-option-name">{storageQuantity}</div>
        </span>
        <div className="cs-options">
          {options}
        </div>
      </div>
    </div>
  ) : (
    <div id="storage_quantity_dropdown" className="input-container">
      <input type="text" value={storageQuantity} disabled={true} />
    </div>
  );
}

StorageQuantityInputForMultiInverters.propTypes = {
  pvModule: pvModuleType.isRequired,
  selectedStorageName: PropTypes.string,
  selectedStorageQuantity: PropTypes.number,
  settings: settingsType.isRequired,
  dropdowns: dropdownsType.isRequired,
  quote: quoteType.isRequired,
  onSelect: PropTypes.func.isRequired,
  toggleStorageQuantityWithMultiInverterDropdown: PropTypes.func.isRequired
};

export default register(
  [
    'pvModuleSelector',
    'dropdownsSelector',
    'quoteSelector',
    'settingsSelector'
  ],
  ['toggleStorageQuantityWithMultiInverterDropdown'],
  StorageQuantityInputForMultiInverters
);

