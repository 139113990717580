import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { get, find } from 'lodash';
import isEmpty from 'lodash/isEmpty';
import cx from 'classnames';
import { register } from '../utils/redux';
import { formatCurrency } from '../utils/formatNumber';
import ValueBox from './ValueBox';
import PricingDisclaimer from './PricingDisclaimer';
import PercentCircle from './PercentCircle';
import dataAvailable from '../utils/SavingsChart/dataAvailable';
import constants from '../utils/EddieCalculations/constants';
import { gaTrackEvent } from '../utils/googleAnalytics';
import { isLease, isLoan, isPpa } from '../utils/EddieCalculations/helpers/financeTypes';
import { TCU, PENFED } from '../utils/EddieCalculations/helpers/setting';
import { mortgageEnabled } from '../utils/backendFeatureFlags';
import { setDefaultLoanCalcValues } from '../utils/loanCalculatorHelpers';
import {
  totalSavingsChartType,
  creditType,
  settingsType,
  savingsType,
  productConfigurationType,
  savingsChartType,
  allSystemCostsType,
  energyDataType,
  selectedStorageType,
  quoteType,
  accountType,
  loanSystemCostType,
  leaseSystemCostType,
  cashSystemCostType,
  ppaSystemCostType,
  chartTermType,
  designType,
  pvModuleType,
  selectedStorageWithExpansionPackType,
  storageSelectionModalType,
  userDataType
} from '../types';
import Tab from './Tab';
import PrepaymentView from './savings_page/PrepaymentView';
import CashView from './savings_page/CashView';
import LeaseView from './savings_page/LeaseView';
import LoanView from './savings_page/LoanView';
import SpwrCashView from './savings_page/SpwrCashView';
import PpaView from './savings_page/PpaView';
import ShowHideSection from './savings_page/ShowHideSection';
import ExpandableSection from './savings_page/ExpandableSection';
import TotalSavingsGraphView from './savings_page/TotalSavingsGraphView';
import SavingsOverTimeGraphView from './savings_page/SavingsOverTimeGraphView';
import SavingsPerYearModule from './chart_modules/SavingsPerYearModule';
import TotalSavingsModule from './chart_modules/TotalSavingsModule';
import FinanceOptionsDropdown from './savings_page/FinanceOptionsDropdown';
import LoanCalculatorView from './savings_page/LoanCalculatorView';
import ProposalGenerator from './savings_page/ProposalGenerator';
import { getDegradation } from '../utils/EddieCalculations/design';

const {
  NJ_TREC_INCENTIVE_ID,
  NY_DEFAULT_INFLATION_RATE,
  ALL_STATE_DEFAULT_INFLATION_RATE,
  LOAN_FINANCE_TYPE,
  CASH_FINANCE_TYPE,
  LEASE_FINANCE_TYPE,
  SPWR_CASH_FINANCE_TYPE,
  PPA_FINANCE_TYPE
} = constants;

const CT_MA_BUY_ALL_ID = [ '3398759', '3394817', '3398760', '3398788', '3398787',
  '2610', '3290446', '3385609', '81002', '3287514', '81192', '3318181' ];

export function SavingsPage({
  settings,
  productConfiguration,
  savings,
  energyData,
  allSystemCosts,
  credit,
  toggleSavingsTab,
  toggleSavingsGraphTab,
  savingsChart,
  totalSavingsChart,
  inflationRate,
  toggleEscalatorDropdown,
  setSavingsChartSelection,
  isPublic,
  quote,
  constantValues,
  pvModule,
  design,
  account,
  userData,
  instantQuoteMode,
  updateInflationRate,
  partnerSectorLeaseIsNewHomes,
  fetchGraphs,
  graphLoading,
  defaultChartTab,
  loanSystemCost,
  isSpecialState,
  specialStateFullName,
  selectedStorage,
  storageSelectionModal,
  selectedStorageWithExpansionPack,
  // eslint-disable-next-line no-unused-vars
  leaseSystemCost,
  // eslint-disable-next-line no-unused-vars
  cashSystemCost,
  // eslint-disable-next-line no-unused-vars
  ppaSystemCost,
  chartTerm
}) {
  const saveLoanCalcValuesInQuote = true;
  const { activeTab } = savings;
  let activeGraphTab = '';
  const showSavingsOverTime = get(quote, 'graphSelections.savingsOverTime', true);
  const showTotalSavings = get(quote, 'graphSelections.totalSavings', true);
  const showElectricityBillSavings = get(quote, 'graphSelections.electricityBillSavings', true);
  switch (savings.activeGraphTab) {
    case 'savingsOverTimeGraph':
      activeGraphTab = showSavingsOverTime ? 'savingsOverTimeGraph' : 'totalSavingsGraph';
      break;
    case 'totalSavingsGraph':
      activeGraphTab = showTotalSavings ? 'totalSavingsGraph' : 'savingsOverTimeGraph';
      break;
    default:
      break;
  }
  const hasEscalators = savingsChart ? Object.keys(savingsChart).length > 1 : false;
  const [ isCostBreakoutExpanded, setIsCostBreakoutExpanded ] = useState(false);
  const [ activeLedgerTab, setActiveLedgerTab ] = useState('financeOptionTab');
  const [ loanLedgerSubViewTab, setLoanLedgerSubViewTab ] = useState('loan_details');
  const loanDetailsTabTitle = 'Pricing Details';
  const loanCalculatorTabTitle = 'Calculator';
  const leaseTerm = isLease(activeTab) ? allSystemCosts[activeTab].leaseTerm : null;
  const ppaTerm = isPpa(activeTab) ? allSystemCosts[activeTab].ppaTerm : null;
  const loanTermInMonths = get(allSystemCosts[LOAN_FINANCE_TYPE], 'termInMonths');
  const loanTerm = loanTermInMonths ? loanTermInMonths / 12 : null;
  const termForSavingsGraph = isLease(activeTab) ? leaseTerm :
    (isPpa(activeTab) ? ppaTerm : (isLoan(activeTab) ? loanTerm : null));
  const pricePerKwh = get(settings, `${activeTab}.pricePerKwh`);
  const activeChartTerm = chartTerm ? chartTerm[activeTab] : null;
  // eslint-disable-next-line no-eval
  const activeTabSystemCost = eval(`${activeTab}SystemCost`);
  const selectedStorageQuantity = get(quote, 'storageQuantity', 0);
  const degradationRate = getDegradation(pvModule);
  const isCTMABuyAll = () => {
    return account && (account.state === 'CT' || account.state === 'MA') &&
      CT_MA_BUY_ALL_ID.includes(account.proposedRate);
  };

  useEffect(() => {
    if (activeTab === LOAN_FINANCE_TYPE && !credit.loanIsDeclined && quote.creditBureau === TCU) {
      setDefaultLoanCalcValues({ quote, settings, credit, loanSystemCost, account, saveLoanCalcValuesInQuote });
    }
  }, []);

  useEffect(() => {
    if (account.state === 'NY') {
      toggleEscalatorDropdown(NY_DEFAULT_INFLATION_RATE);
      updateInflationRate(quote, { inflationRate: NY_DEFAULT_INFLATION_RATE });
    } else {
      toggleEscalatorDropdown(ALL_STATE_DEFAULT_INFLATION_RATE);
      updateInflationRate(quote, { inflationRate: ALL_STATE_DEFAULT_INFLATION_RATE });
    }
  }, []);

  useEffect(() => {
    if (isEmpty(savingsChart) || isEmpty(totalSavingsChart) && !graphLoading) fetchGraphs(quote.sfid);
  }, []);

  const onChangeRate = (env) => {
    toggleEscalatorDropdown(env.target.value);
    updateInflationRate(quote, { inflationRate: env.target.value });
  };
  const activeRate = `${inflationRate}.${activeTab}`;
  const savingsChartData = get(savingsChart, activeRate, {});
  const breakout = savingsChartData.legend;
  const chartDataAvailable = dataAvailable(savingsChartData);
  const title = 'Estimated Average Electric Bill Savings for';
  const njTrecIncentive = activeTab !== LEASE_FINANCE_TYPE && activeTab !== PPA_FINANCE_TYPE && find(
    get(energyData.incentives[activeTab], 'eligibleProductionBasedIncentives') || [], (incentive) => {
      return incentive.incentiveId === NJ_TREC_INCENTIVE_ID;
    }
  );

  const produceElectricityText = (isSpecialState) ?
    'Produce electricity from the sun on your own rooftop!' :
    `Produce electricity at ${formatCurrency(allSystemCosts[activeTab].costPerKwhSavings, 3)} ` +
      'less per kilowatt-hour (kWh) than the utility';
  let rebatesText =
    `${'*Incentives and Tax Credits vary and are subject to change. SunPower does not warrant, ' +
    'guarantee or otherwise advise its partners or customers about specific tax outcomes. ' +
    'Consult your tax advisor regarding the solar tax credit.'}${
    njTrecIncentive ? ' Some incentives will be paid out over time based on system performance.' : ''}`;
  if (isSpecialState) {
    rebatesText = `${rebatesText} In the State of ${specialStateFullName}, the EDDiE app ` +
      'does not illustrate or describe potential financial savings ' +
      'associated with installing a SunPower solar system.';
  }
  const pricingDisclaimer = (<PricingDisclaimer isPublic={isPublic} />);
  const costBreakout = isPublic ? 'cost-breakout is-public' : 'cost-breakout';
  const isStorageSelected = Boolean(selectedStorageQuantity);
  const withSolarText = isStorageSelected ? 'With Solar + Storage' : 'With Solar';

  const isPDFProcessing = get(account, 'isPDFProcessing');
  const isOverTimeGraph = activeGraphTab === 'savingsOverTimeGraph';

  const toggleSavingsGraphTabWithTracking = (tabName) => {
    toggleSavingsGraphTab(tabName);

    if (tabName === 'totalSavingsGraph') {
      gaTrackEvent('quotePage:savingsTab:totalSavingsGraphSubTab');
    }
    if (tabName === 'savingsOverTimeGraph') {
      gaTrackEvent('quotePage:savingsTab:savingsOverTimeGraphSubTab');
    }
  };

  const toggleLoanCalculatorTab = (tabName) => {
    setLoanLedgerSubViewTab(tabName);
  };

  const ledgerTitle = () => {
    let title = '';

    if (activeTab === CASH_FINANCE_TYPE && mortgageEnabled() && partnerSectorLeaseIsNewHomes) {
      title = 'Mortgage Finance';
    } else if (activeTab === SPWR_CASH_FINANCE_TYPE) {
      title = 'SPWR Cash';
    } else if (activeTab === PPA_FINANCE_TYPE) {
      title = 'PPA';
    } else {
      title = activeTab;
    }

    return `${title} details`;
  };

  const calculationForCostPerKwhWithSolar = () => {
    if (activeTab === CASH_FINANCE_TYPE && mortgageEnabled() && partnerSectorLeaseIsNewHomes) {
      return allSystemCosts.mortgage.costPerKwhWithSolar;
    }
    return allSystemCosts[activeTab].costPerKwhWithSolar;
  };

  const calculationForCostPerKwhWithoutSolar = () => {
    if (activeTab === CASH_FINANCE_TYPE && mortgageEnabled() && partnerSectorLeaseIsNewHomes) {
      return allSystemCosts.mortgage.costPerKwhWithoutSolar;
    }
    return allSystemCosts[activeTab].costPerKwhWithoutSolar;
  };

  const toggleSavingsOptionsWithTracking = (optionName) => {
    toggleSavingsTab(optionName);

    if (optionName === CASH_FINANCE_TYPE) {
      gaTrackEvent('quotePage:savingsTab:cashSubTab');
    }

    if (optionName === CASH_FINANCE_TYPE && mortgageEnabled() && partnerSectorLeaseIsNewHomes) {
      gaTrackEvent('quotePage:savingsTab:mortgageSubTab');
    }

    if (optionName === LEASE_FINANCE_TYPE) {
      gaTrackEvent('quotePage:savingsTab:leaseSubTab');
    }

    if (optionName === PPA_FINANCE_TYPE) {
      gaTrackEvent('quotePage:savingsTab:ppaSubTab');
    }

    if (optionName === LOAN_FINANCE_TYPE && !credit.loanIsDeclined && quote.creditBureau === TCU) {
      setDefaultLoanCalcValues({ quote, settings, credit, loanSystemCost, account, saveLoanCalcValuesInQuote });
      gaTrackEvent('quotePage:savingsTab:loanSubTab');
    }
  };

  const billOffset = (
    <div className="bill-offset">
      { isPublic &&
      <span className="section-title">Reduce your bill drastically
      by drawing only Tier 1 electricity.</span>
      }
      <div className="value-box-container">
        <PercentCircle label="Electricity offset (est.)" value={energyData.energyOffset} />
        { isSpecialState || !showElectricityBillSavings ? '' : (
          <div className="arrow" />
        )}
        { isSpecialState || !showElectricityBillSavings ? '' : (
          <PercentCircle
            label="Electric bill savings (est.)"
            value={Math.round(100 * energyData.billSavingsPercent)}
            className="blue"
          />
        )}
      </div>
    </div>
  );

  const costPerKwhSavings = (
    <div className="cost-per-kwh-savings">
      <span id="savings_page_produce_electricity_text" className="section-title">
        {produceElectricityText}
      </span>
      <div className="value-box-container">
        { isSpecialState ? '' : (
          <ValueBox
            id="savings_page_cost_without_solar"
            label="Without Solar"
            currency={true}
            places={3}
            value={calculationForCostPerKwhWithoutSolar()}
            subvalue="/kWh"
            subtitle="Electricity Cost (est.)"
          />
        )}
        { isSpecialState ? '' : (
          <span className="separator">vs</span>
        )}
        <ValueBox
          id="savings_page_cost_with_solar"
          label={withSolarText}
          currency={true}
          places={3}
          centered={isSpecialState}
          value={calculationForCostPerKwhWithSolar()}
          subvalue="/kWh"
          subtitle="Solar system cost divided by solar energy production (est.)"
        />
      </div>
      <div className="disclaimer legal-text">
        {rebatesText}
      </div>
    </div>
  );

  const chartInflationRate = get(totalSavingsChart, `${inflationRate}.${activeTab}`, false);

  const totalSavingsTitle = 'TOTAL SAVINGS';
  const inflationRateSelectOptionsForTotalSavings = isEmpty(totalSavingsChart) ? [] : Object.keys(totalSavingsChart);
  const totalSavingsModule = (<TotalSavingsModule
    totalSavingsChart={totalSavingsChart}
    selectedFinanceOption={activeTab}
    selectedInflationRate={inflationRate}
    inflationRateSelectOptions={inflationRateSelectOptionsForTotalSavings}
    readOnlyInflationRate={false}
    onChangeRate={onChangeRate}
    chartTerm={activeChartTerm}
    isPDFProcessing={isPDFProcessing}
  />);
  const totalSavingsGraph = (<TotalSavingsGraphView
    activeTab={activeTab}
    hasEscalators={hasEscalators}
    inflationRate={inflationRate}
    onChangeRate={onChangeRate}
    leaseTerm={leaseTerm}
  />);
  const totalSavingsGraphView = partnerSectorLeaseIsNewHomes ? totalSavingsGraph : totalSavingsModule;

  const savingsOverTimeTitle = 'SAVINGS OVER TIME';
  const inflationRateSelectOptions = savingsChart ? Object.keys(savingsChart) : [];
  const savingsPerYearModule = (<SavingsPerYearModule
    savingsChart={savingsChart}
    selectedFinanceOption={activeTab}
    selectedInflationRate={inflationRate}
    selectedStateOption={account.state}
    energyOffsetPc={energyData.energyOffset}
    pricePerKwh={pricePerKwh}
    systemCost={activeTabSystemCost}
    onChangeRate={onChangeRate}
    readOnlyInflationRate={false}
    inflationRateSelectOptions={inflationRateSelectOptions}
    term={termForSavingsGraph}
    chartTerm={activeChartTerm}
    isStorageSelected={isStorageSelected}
    isPDFProcessing={isPDFProcessing}
  />);
  const savingsOverTime = (<SavingsOverTimeGraphView
    savingsChart={savingsChart}
    activeTab={activeTab}
    breakout={breakout}
    hasEscalators={hasEscalators}
    inflationRate={inflationRate}
    onChangeRate={onChangeRate}
    setSavingsChartSelection={setSavingsChartSelection}
  />);

  const savingsOverTimeGraphView = (partnerSectorLeaseIsNewHomes ? savingsOverTime : savingsPerYearModule);

  const getValidFO = (financialOfferings) => {
    if (!isCTMABuyAll()) {
      return financialOfferings;
    }
    const validFO = {};
    const availableFO = Object.keys(financialOfferings);
    for (let index = 0; index < availableFO.length; index++) {
      if (availableFO[index] !== LEASE_FINANCE_TYPE && availableFO[index] !== PPA_FINANCE_TYPE) {
        validFO[availableFO[index]] = financialOfferings[availableFO[index]];
      }
    }
    return validFO;
  };
  const financeOptionsDropdownComponent = (
    <FinanceOptionsDropdown
      selectedOption={activeTab}
      toggle={toggleSavingsOptionsWithTracking}
      settingsShowCashChecked={settings.cash.showCustomer}
      settingsShowLeaseChecked={isCTMABuyAll() ? false : settings.lease.showCustomer}
      settingsShowPpaChecked={isCTMABuyAll() ? false : (settings.ppa && settings.ppa.showCustomer)}
      settingsShowLoanChecked={settings.loan.showCustomer}
      settingsShowSpwrCashChecked={get(settings, 'spwrCash.showCustomer', false)}
      financialOfferings={getValidFO(productConfiguration.financialOfferings)}
    />
  );

  const pdfGenerator = (
    <ProposalGenerator
      account={account}
      allSystemCosts={allSystemCosts}
      design={design}
      energyData={energyData}
      inflationRate={inflationRate}
      instantQuoteMode={instantQuoteMode}
      isSpecialState={isSpecialState}
      productConfiguration={productConfiguration}
      quote={quote}
      selectedFinanceOption={activeTab}
      selectedStorage={selectedStorage}
      degradationRate={degradationRate}
      selectedStorageQuantity={selectedStorageQuantity}
      selectedStorageWithExpansionPack={selectedStorageWithExpansionPack}
      storageSelectionModal={storageSelectionModal}
      userData={userData}
      constantValues={constantValues}
      defaultChartTab={defaultChartTab}
    />
  );

  return (
    <div id="main-content" className="savings-page">
      <div className="savings-breakout">
        { (credit.loanIsDeclined && activeTab === LOAN_FINANCE_TYPE) ? (
          <React.Fragment>
            <div className="first-month-savings">
              <div className="title">
                <div className="text">{title}</div>
                {financeOptionsDropdownComponent}
              </div>
            </div>
            <div className="credit-declined">
              <p>Credit application has been declined.</p>
            </div>
          </React.Fragment>
        ) : (
          <div>
            { isSpecialState ? (
              <div className="first-month-savings">
                <div className="title">
                  {financeOptionsDropdownComponent}
                </div>
              </div>
              ) : (
                <React.Fragment>
                  <div className="first-month-savings">
                    <div className="title">
                      <div className="text">{title}</div>
                      {financeOptionsDropdownComponent}
                    </div>
                    <div className="value-box-container">
                      <ValueBox
                        label="Without Solar"
                        currency={true}
                        rounded={true}
                        value={Math.round(energyData.monthlyBillWithoutSolar)}
                        subvalue="/month"
                        subtitle="Electric bill (est.)"
                      />
                      <span className="separator">vs</span>
                      <ValueBox
                        label={withSolarText}
                        currency={true}
                        rounded={true}
                        centered={isSpecialState}
                        value={Math.round(energyData.monthlyBillWithSolar)}
                        subvalue="/month"
                        subtitle="New electric bill (est.)"
                        className="with-solar-value-box"
                      />
                      <div className="tooltip">
                        <span className="label" id="monthly-bill-savings-label">Electric Bill Savings</span>
                        <span className="value">${Math.round(energyData.monthlyBillSavingsRounded)}</span>
                        <span className="subtitle">Per month (est.)</span>
                      </div>
                    </div>
                    <div className="disclaimer legal-text">
                    Electric usage numbers may vary as your historical data has been translated<br />
                    from historical to a prediction of average future usage using the most current rates
                  </div>
                  </div>
                  <hr />
                </React.Fragment>
            )}

            { isPublic ? (
              <div className="year-savings public">
                <div className="legend-centered">{ savingsOverTimeTitle }</div>
                { savingsOverTimeGraphView }
              </div>
            ) : billOffset }
            <hr />
            { isPublic ? (
              <div className="year-savings public">
                <div className="legend-centered">{ totalSavingsTitle }</div>
                { totalSavingsGraphView }
              </div>
            ) : costPerKwhSavings }
            { isPublic ? (
              <ShowHideSection>
                { costPerKwhSavings }
                <hr />
                { billOffset }
              </ShowHideSection>
            ) :
              (chartDataAvailable && !isSpecialState && (showSavingsOverTime || showTotalSavings)) && (
                <React.Fragment>
                  <hr />
                  <div className="year-savings">
                    <div className="tabs">
                      <div className="tabs__header-container">
                        <ul className="tabs__header">
                          {(showTotalSavings &&
                            <Tab
                              activeTab={activeGraphTab}
                              toggle={toggleSavingsGraphTabWithTracking}
                              customClass="savings-type"
                              tabName="totalSavingsGraph"
                              id="totalSavingsGraph"
                              title={totalSavingsTitle}
                              showTab={true}
                              wrapping={true}
                            />
                          )}
                          {(showSavingsOverTime &&
                            <Tab
                              activeTab={activeGraphTab}
                              toggle={toggleSavingsGraphTabWithTracking}
                              customClass="savings-type"
                              tabName="savingsOverTimeGraph"
                              id="savingsOverTimeGraph"
                              title={savingsOverTimeTitle}
                              showTab={true}
                              wrapping={true}
                            />
                          )}
                        </ul>
                      </div>
                      <div className="tabs__content-container">
                        <div className="tabs__content">
                          <div className="tabs__content-item tabs__content-item--active">
                            <div
                              className={
                               isOverTimeGraph ? 'hidden' : ''
                              }
                            >
                              {(chartInflationRate && !isOverTimeGraph) ? totalSavingsGraphView : null}
                            </div>
                            <div className={!isOverTimeGraph ? 'hidden' : ''}>
                              {isOverTimeGraph ? savingsOverTimeGraphView : null}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </React.Fragment>
              )}
          </div>
        )
        }
      </div>
      <div className="cost-breakout-show-hide">
        <hr className="vertical-hr" />
        <div className="cost-breakout-show-hide-icon-container">
          <div
            className="cost-breakout-show-hide-icon"
            onClick={() => setIsCostBreakoutExpanded(!isCostBreakoutExpanded)}
          >
            <i className={`icon icon-chevron-${isCostBreakoutExpanded ? 'right' : 'left'}`} />
          </div>
        </div>
      </div>
      <ExpandableSection isExpanded={isCostBreakoutExpanded}>
        <div className={costBreakout}>
          <div className="tabs fixed-tabs loan-calculator-header-tabs-container">
            { activeTab === LOAN_FINANCE_TYPE &&
            (quote.creditBureau === TCU || quote.creditBureau === PENFED) &&
            !(quote.locked && quote.version < 4) ?
                (credit.loanIsDeclined ?
                  null :
                  (<div className="tabs__header-container loan-calculator-tabs-container">
                    <ul className="tabs__header">
                      <Tab
                        activeTab={loanLedgerSubViewTab}
                        toggle={toggleLoanCalculatorTab}
                        customClass="loan-calculator-tabs"
                        tabName="loan_details"
                        title={loanDetailsTabTitle}
                        showTab={true}
                        wrapping={true}
                      />
                      <Tab
                        activeTab={loanLedgerSubViewTab}
                        toggle={toggleLoanCalculatorTab}
                        customClass="loan-calculator-tabs"
                        tabName="loan_calculator"
                        title={loanCalculatorTabTitle}
                        showTab={true}
                        wrapping={true}
                      />
                    </ul>
                  </div>)
                ) :
              (
                (activeTab === LEASE_FINANCE_TYPE || activeTab === PPA_FINANCE_TYPE) && quote.version >= 15 ?
                  (
                    <div className="ledger-tabs">
                      <div
                        className={cx('ledger-tab-title',
                          { 'active-tab': (activeLedgerTab === 'financeOptionTab' && quote.version >= 15) }
                        )}
                        onClick={() => setActiveLedgerTab('financeOptionTab')}
                      >
                        {ledgerTitle()}
                      </div>
                      <div
                        className={cx('prepayments', { 'active-tab': activeLedgerTab === 'prepaymentTab' })}
                        onClick={() => setActiveLedgerTab('prepaymentTab')}
                      >
                        Prepayment
                      </div>
                    </div>
                  ) : (
                    <div className="ledger-title">{ledgerTitle()}</div>
                  )
              )
            }
            <div className="tabs__content-container">
              <div className="tabs__content">
                <div className="tabs__content-item tabs__content-item--active">
                  { activeTab === CASH_FINANCE_TYPE &&
                    (
                      <CashView
                        quote={quote}
                        pricingDisclaimer={pricingDisclaimer}
                      />
                    )
                  }
                  { activeTab === LEASE_FINANCE_TYPE && quote.version >= 15 &&
                    (activeLedgerTab === 'prepaymentTab' ?
                     (<PrepaymentView activeTab={activeTab} />) :
                     (<LeaseView pricingDisclaimer={pricingDisclaimer} />)
                    )
                  }
                  { activeTab === PPA_FINANCE_TYPE && quote.version >= 15 &&
                    (activeLedgerTab === 'prepaymentTab' ?
                    (<PrepaymentView activeTab={activeTab} />) :
                    (<PpaView pricingDisclaimer={pricingDisclaimer} />)
                    )
                  }
                  { activeTab === LEASE_FINANCE_TYPE && quote.version < 15 &&
                    (<LeaseView pricingDisclaimer={pricingDisclaimer} />) }
                  { activeTab === PPA_FINANCE_TYPE && quote.version < 15 &&
                    (<PpaView pricingDisclaimer={pricingDisclaimer} />) }
                  { activeTab === LOAN_FINANCE_TYPE &&
                  (loanLedgerSubViewTab === 'loan_calculator' ?
                      (<LoanCalculatorView />) :
                      (<LoanView pricingDisclaimer={pricingDisclaimer} />)
                  )
                  }
                  { activeTab === SPWR_CASH_FINANCE_TYPE &&
                    (<SpwrCashView pricingDisclaimer={pricingDisclaimer} />) }
                </div>
              </div>
            </div>
          </div>
        </div>
      </ExpandableSection>
      {isPDFProcessing ? pdfGenerator : null}
    </div>
  );
}

SavingsPage.propTypes = {
  settings: settingsType.isRequired,
  productConfiguration: productConfigurationType.isRequired,
  savings: savingsType.isRequired,
  energyData: energyDataType.isRequired,
  allSystemCosts: allSystemCostsType.isRequired,
  credit: creditType.isRequired,
  toggleSavingsTab: PropTypes.func.isRequired,
  toggleSavingsGraphTab: PropTypes.func.isRequired,
  savingsChart: savingsChartType,
  inflationRate: PropTypes.string.isRequired,
  toggleEscalatorDropdown: PropTypes.func.isRequired,
  totalSavingsChart: totalSavingsChartType,
  setSavingsChartSelection: PropTypes.func.isRequired,
  isPublic: PropTypes.bool.isRequired,
  selectedStorage: selectedStorageType.isRequired,
  quote: quoteType.isRequired,
  account: accountType.isRequired,
  updateInflationRate: PropTypes.func.isRequired,
  partnerSectorLeaseIsNewHomes: PropTypes.bool.isRequired,
  fetchGraphs: PropTypes.func.isRequired,
  graphLoading: PropTypes.bool.isRequired,
  loanSystemCost: loanSystemCostType,
  leaseSystemCost: leaseSystemCostType,
  ppaSystemCost: ppaSystemCostType,
  cashSystemCost: cashSystemCostType,
  isSpecialState: PropTypes.bool,
  specialStateFullName: PropTypes.string,
  chartTerm: chartTermType,
  defaultChartTab: PropTypes.string,
  constantValues: PropTypes.arrayOf(PropTypes.number),
  design: designType,
  instantQuoteMode: PropTypes.bool,
  pvModule: pvModuleType,
  selectedStorageWithExpansionPack: selectedStorageWithExpansionPackType.isRequired,
  storageSelectionModal: storageSelectionModalType.isRequired,
  userData: userDataType
};
export default register(
  [
    'settingsSelector', 'productConfigurationSelector', 'savingsSelector', 'creditSelector', 'designSelector',
    'allSystemCostsSelector', 'savingsChartSelector', 'inflationRateSelector',
    'totalSavingsChartSelector', 'isPublicSelector', 'pvModuleSelector',
    'energyDataSelector', 'selectedStorageSelector', 'defaultChartTabSelector',
    'quoteSelector', 'accountSelector', 'partnerSectorLeaseIsNewHomesSelector', 'graphLoadingSelector',
    'loanSystemCostSelector', 'leaseSystemCostSelector', 'cashSystemCostSelector', 'isSpecialStateSelector',
    'specialStateFullNameSelector', 'ppaSystemCostSelector', 'chartTermSelector',
    'userDataSelector', 'instantQuoteModeSelector', 'storageSelectionModalSelector',
    'selectedStorageWithExpansionPackSelector', 'constantValuesSelector'
  ],
  [
    'toggleSavingsTab', 'toggleEscalatorDropdown', 'toggleSavingsGraphTab',
    'setSavingsChartSelection', 'updateInflationRate',
    'fetchGraphs'
  ],
  SavingsPage
);
