import React from 'react';
import PropTypes from 'prop-types';

export function AutoselectInputContent(props) {
  return (
    <div onClick={handleClick}>
      {props.children}
    </div>
  );

  function handleClick(event) {
    if (!event.target || event.target.nodeName !== 'INPUT') {
      return;
    }

    event.target.select();
  }
}

AutoselectInputContent.propTypes = {
  children: PropTypes.element.isRequired
};
