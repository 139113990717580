import createSelector from '../utils/createSelector';
import { dcModuleSelector } from '../selectors';

function mainInverter(state) {
  const inverters = state.productConfiguration.inverters;
  let mainInverter;

  if (dcModuleSelector(state).dcModule) {
    const selectedInverters = state.design.selectedInverters;
    const firstSelectedInverter = selectedInverters && selectedInverters[0];
    const mainInverterId = firstSelectedInverter && firstSelectedInverter.inverterItemId;
    mainInverter = mainInverterId && inverters.find((inverter) => inverter.id === mainInverterId);
  } else {
    mainInverter = inverters[0];
  }

  return {
    mainInverter
  };
}

export default createSelector(
  mainInverter
);

