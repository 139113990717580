/* global gtag */
const events = {
  'quotePage:navBar:close': 'App Close',
  'quotePage:designTab': 'Design Page View',
  'quotePage:proposalTab': 'Proposal Page View',
  'quotePage:savingsTab': 'Savings Page View',
  'quotePage:navBar:tab:Design': 'Design Tab Click',
  'quotePage:navBar:tab:Savings': 'Savings Tab Click',
  'quotePage:navBar:tab:Proposal': 'Proposal Tab Click',
  'quotePage:navBar:help': 'Info Button Click',
  'quotePage:navBar:gear': 'Gear Menu Click',
  'quotePage:modal:settings:cancel': 'Gear Menu Cancel',
  'quotePage:modal:settings:saveChanges': 'Gear Menu Save Changes',
  'quotePage:designTab:pricePerKwhRun': 'Run Price Per Kwh Button Click',
  'quotePage:designTab:viewEstimatedSavings': 'View Estimated Savings Button',
  'quotePage:designTab:roofsButton': 'Roof Button Click',
  'quotePage:designTab:panelsButton': 'Panel Button Click', /* dynamic and public mode */
  'quotePage:designTab:saveDesign': 'Save Design Button Click', /* dynamic mode and public page only */
  'quotePage:designTab:editEquipment': 'Edit Equipment',
  'quotePage:designTab:addStorage': 'Add Storage',
  'quotePage:designTab:roofPreviewVisualization': 'Roof Preview Visualisation Click',
  'quotePage:designTab:roofPreviewMap': 'Roof Preview Map Click',
  'quotePage:designTab:basicView': 'Summary Button Click',
  'quotePage:designTab:detailedView': 'Details Button Click',
  'quotePage:savingsTab:leaseSubTab:editLink': 'Edit Lease Click',
  'quotePage:savingsTab:loanSubTab:editLink': 'Edit Loan Click',
  'quotePage:savingsTab:cashSubTab:selectButton': 'Select Cash Click',
  'quotePage:savingsTab:leaseSubTab:selectButton': 'Select Lease Click',
  'quotePage:savingsTab:loanSubTab:selectButton': 'Select Loan Click',
  'quotePage:savingsTab:viewProposal': 'View Proposal Button Click',
  'quotePage:savingsTab:mortgageSubTab': 'View Mortgage Finance',
  'quotePage:savingsTab:cashSubTab': 'View Cash',
  'quotePage:savingsTab:leaseSubTab': 'View Lease',
  'quotePage:savingsTab:loanSubTab': 'View Loan',
  'quotePage:savingsTab:EstimatesBasedOnUtilityRateGrowing': 'Estimates based on your utility bill rate growing Click',
  'quotePage:savingsTab:leaseDownPaymentSelection': 'Down Payment Click',
  'quotePage:savingsTab:leaseFullPrepaymentOption': 'Full Prepayment Click',
  'quotePage:savingsTab:leasePaymentEscalatorSelection': 'Payment Escalator Click',
  'quotePage:savingsTab:loanCoPaymentOption': 'Co-Payment Click',
  'quotePage:savingsTab:loanTermSelection': 'Term Click',
  'quotePage:savingsTab:totalSavingsGraphSubTab': 'View Total Savings Graph',
  'quotePage:savingsTab:savingsOverTimeGraphSubTab': 'View Savings Over Time Graph',
  'quotePage:proposalTab:sendEmail': 'Email Proposal Click',
  'quotePage:proposalTab:downloadButton': 'Download Button Click',
  'quotePage:proposalTab:printButton': 'Print Proposal Click',
  'quotePage:proposalTab:signProposal': 'Signature Button Click (Proposal)',
  'manualMode:quotePage:designTab:saveDesign': 'Save Design Button (Manual Mode) Click' /* manual mode only */
};

export const gaTrackEvent = (name, callback, eventOptions = {}) => {
  if (process.env.NODE_ENV === 'test') return false;

  const eventName = events[name];

  if (typeof eventName === 'undefined') {
    return false;
  }

  const options = { ...eventOptions };

  if (callback && typeof callback === 'function') {
    // To make sure callback is triggered anyway even if gtag fails
    let isCallbackTriggered = false;

    const triggerCallback = () => {
      if (!isCallbackTriggered) {
        isCallbackTriggered = true;
        callback();
      }
    };
    setTimeout(triggerCallback, 1000);

    gtag('event', eventName, {
      ...options,
      event_callback: triggerCallback
    });
    return true;
  }
  gtag('event', eventName, options);
  return true;
};
