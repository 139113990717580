import C3Chart from 'react-c3js';
import { findDOMNode } from 'react-dom';

export default class SavingsOverTimeGraphChart extends C3Chart {
  // Redraw the whole graph when props change
  // to make sure that all the attributes (like axis)
  // are updated. Otherwise the graph would have
  // the same scale (values on axes) for all the payment options
  componentDidUpdate(prevProps) {
    if (this.chart !== undefined && this.chart !== null) {
      const prevAxis = prevProps.axis;
      const currentAxis = this.props.axis;
      const xChanged = prevAxis.x.tick.values.length !== currentAxis.x.tick.values.length;
      const yChanged = prevAxis.y.max !== currentAxis.y.max;

      if (xChanged || yChanged) {
        // recreate the graph when the size of the X axis changes to avoid flickering on hover
        // or when the resizeGraph value changes which is controlled by collapsible cost breakout summary on savings page
        this.chart.destroy();
        this.chart = this.generateChart(findDOMNode(this), this.props);
        this.chart.flush();
      } else {
        this.chart.internal.loadConfig(this.props);
      }
    }
  }
}
