import React from 'react';
import PropTypes from 'prop-types';
import CurrencyInput from './CurrencyInput';

export default function LineInput({ id, label, value, readOnly = false,
  rounded = false, positive = false, onChange, onFocus = null, onBlur = null,
  tooltip, disabled = false, tooltipText = false }) {
  return (
    <div className="form-group">
      { tooltipText ?
        <label data-tip={tooltip} className="label-tool-tip">{label}
          <div className="image-icon image-icon-info" data-tip={tooltipText} data-html={true} />
        </label> :
        <label data-tip={tooltip}>{label}</label>
      }
      <div className="input-container">
        <CurrencyInput
          id={id}
          name={name}
          readOnly={readOnly}
          value={value}
          rounded={rounded}
          onChange={onChange}
          onFocus={onFocus}
          onBlur={onBlur}
          positive={positive}
          disabled={disabled}
        />
      </div>
    </div>
  );
}

LineInput.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
  tooltipText: PropTypes.string,
  value: PropTypes.oneOfType([ PropTypes.number, PropTypes.string ]).isRequired,
  readOnly: PropTypes.bool,
  rounded: PropTypes.bool,
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  tooltip: PropTypes.string,
  positive: PropTypes.bool,
  partnerIsDirect: PropTypes.bool,
  disabled: PropTypes.bool
};
