import React from 'react';
import PropTypes from 'prop-types';
import ModuleSelection from './module/ModuleSelection';
import { register } from '../../../utils/redux';
import { productSelectionModalType } from '../../../types';

export function SidebarContent() {
  return (
    <div className="sidebar-content">
      <ModuleSelection />
      <div className="sidebar-content-btns">
        <div className="sidebar-content-accept-btn">
          <button className="btn btn-primary">
            Accept Selection
          </button>
        </div>
        <div className="sidebar-content-cancel-btn">
          <a href="#">Cancel</a>
        </div>
      </div>
    </div>
  );
}

SidebarContent.propTypes = {
  productSelectionModal: productSelectionModalType.isRequired,
  updateProductSelectionModal: PropTypes.func.isRequired
};

export default register(
  [
    'productSelectionModalSelector'
  ],
  [
    'updateProductSelectionModal'
  ],
  SidebarContent
);
