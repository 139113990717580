import { get } from 'lodash';
import constants from '../EddieCalculations/constants';
import { updateSettings } from '../../actions/updateSettings';

const {
  CRITTER_GUARD,
  SKIRT_GUARD,
  PRICE_PER_WATT,
  DEALER_FEE_PER_WATT
} = constants;

const selectedPaymentOptions = (settings) => [ 'cash', 'lease', 'loan', 'spwrCash', 'ppa' ]
  .filter((option) => get(settings, `${option}.showCustomer`, false));

export const accessoriesToShow = (adminFinConfigs, settings) => {
  // if admin fin config of default finance option has these adders only then show it
  // default = ['Front Edge Skirt', 'Critter Guard']
  // show only those adders which are enabled in AP

  const accessories = [ CRITTER_GUARD, SKIRT_GUARD ];
  /* eslint-disable consistent-return */
  if (adminFinConfigs && adminFinConfigs.length > 0) {
    const filteredAdminFinConfig = adminFinConfigs.filter((config) =>
    selectedPaymentOptions(settings).includes(config.finType) && !!config.productConfigsAttributes);

    const adminAccessories = {};
    filteredAdminFinConfig.forEach((config) => {
      config.productConfigsAttributes.forEach((con) => {
        if (accessories.includes(con.product) && con.sell) {
          adminAccessories[con.product] = con.product;
        }
        if (Object.keys(adminAccessories).length === accessories.length) {
          return Object.keys(adminAccessories);
        }
      });
      return Object.keys(adminAccessories);
    });
    return Object.keys(adminAccessories);
  }
  /* eslint-enable consistent-return */
  return accessories;
};

export const optionalAdders = (finType, adminFinConfigs, settingsAdders = []) => {
  if (adminFinConfigs && adminFinConfigs.length > 0) {
    const adminFinConfig = adminFinConfigs.find((finConfig) => finConfig.finType === finType && finConfig.show);
    const adminAdders = adminFinConfig ?
      (adminFinConfig.customAddersAttributes || []).filter((customAdder) =>
        customAdder.adderType === 'optional' && customAdder.isLive === true) :
      [];
    return adminAdders.concat((settingsAdders || []).filter(
      (optionalAdder) => optionalAdder.adderType === 'mandated')
    );
  }
  return [];
};

export const stardardAdders = (finType, adminFinConfigs) => {
  if (adminFinConfigs && adminFinConfigs.length > 0) {
    const adminFinConfig = adminFinConfigs.find((finConfig) => finConfig.finType === finType && finConfig.show);
    const stardardAddersFromAp = adminFinConfig ?
      (adminFinConfig.customAddersAttributes || []).filter((customAdder) =>
      customAdder.adderType === 'standard' && customAdder.isLive === true) :
      [];
    return stardardAddersFromAp.reduce((sum, adder) => sum + adder.adderPrice, 0);
  }
  return 0;
};

const pricingConfigFromAdmin = (adminFinConfigs, financeType, pricingType) => {
  if (adminFinConfigs) {
    const adminFinConfig = adminFinConfigs.find((finConfig) => finConfig.finType === financeType);

    if (adminFinConfig && adminFinConfig.pricingDefaultsAttributes) {
      return adminFinConfig.pricingDefaultsAttributes
        .find((pricingDefault) => pricingDefault.pricingType === pricingType);
    }
  }
  return null;
};

export const getMinPricePerWattOfAdmin = (adminFinConfigs, financeType) => {
  const pricePerWattPricingConfig = pricingConfigFromAdmin(adminFinConfigs, financeType, PRICE_PER_WATT);
  return get(pricePerWattPricingConfig, 'min', null);
};

export const getMaxPricePerWattOfAdmin = (adminFinConfigs, financeType) => {
  const pricePerWattPricingConfig = pricingConfigFromAdmin(adminFinConfigs, financeType, PRICE_PER_WATT);
  return get(pricePerWattPricingConfig, 'max', null);
};

export const getMaxDealerFeePerWattOfAdmin = (adminFinConfigs, financeType) => {
  const dealerFeePerWattPricingConfig = pricingConfigFromAdmin(adminFinConfigs, financeType, DEALER_FEE_PER_WATT);
  return get(dealerFeePerWattPricingConfig, 'max', null);
};

export const getMinDealerFeePerWattOfAdmin = (adminFinConfigs, financeType) => {
  const dealerFeePerWattPricingConfig = pricingConfigFromAdmin(adminFinConfigs, financeType, DEALER_FEE_PER_WATT);
  return get(dealerFeePerWattPricingConfig, 'min', null);
};

export const partnerIsIndirect = (partner) => {
  return partner.toLowerCase() === 'indirect';
};

export const partnerIsDirect = (partner) => {
  return partner.toLowerCase() === 'direct';
};

export const partnerIsTps = (partner) => {
  return partner.toLowerCase() === 'tps';
};

export const invalidateAdderNameAndPrice = (key, value) => {
  return ((key === 'adderName' && value.length > 120) || (key === 'adderPrice' && value > 100000));
};

export const updateStorageCommission = (quote, settings) => {
  const storageConfigsObj = {};
  const adminFinConfigs = quote.adminFinConfigs || null;
  if (quote.storageConfigId && adminFinConfigs) {
    adminFinConfigs.forEach((config) => {
      if (config && config.productConfigsAttributes) {
        const storageConfig = config.productConfigsAttributes.find((con) => con.product === quote.storageConfigId);
        if (storageConfig) {
          storageConfigsObj[config.finType] = storageConfig;
        }
      }
    });
  }
  return storageConfigsObj;
};
