import { get } from 'lodash';
import createSelector from '../utils/createSelector';
import { evChargerEnabled } from '../utils/backendFeatureFlags';
import constants from '../utils/EddieCalculations/constants';

const {
  EV_CHARGER,
  EV_OUTLET
} = constants;

export function selectedEvChargerConfiguration(state) {
  if (evChargerEnabled()) {
    const quote = state.quote;
    const evChargerConfigs = get(quote, 'evChargerConfigs', []);

    const selectedConfig = {};
    if (evChargerConfigs.length > 0) {
      evChargerConfigs.forEach((config) => {
        if (config.productName && config.productName.toLowerCase() === EV_CHARGER) {
          selectedConfig.evChargerId = config.id;
          selectedConfig.evChargerQuantity = config.quantity;
          selectedConfig.evChargerConfigId = config.configId;
          selectedConfig.evChargerConfigName = config.configName;
        } else if (config.productName && config.productName.toLowerCase() === EV_OUTLET) {
          selectedConfig.evOutletId = config.id;
          selectedConfig.evOutletQuantity = config.quantity;
          selectedConfig.evOutletConfigId = config.configId;
          selectedConfig.evOutletConfigName = config.configName;
        }
      });
      return { selectedEvChargerConfiguration: selectedConfig };
    }
  }
  return {
    selectedEvChargerConfiguration: {
      evChargerId: null,
      evChargerConfigId: null,
      evChargerConfigName: 'None',
      evOutletId: null,
      evOutletConfigId: null,
      evOutletConfigName: 'None'
    }
  };
}

export default createSelector(
  selectedEvChargerConfiguration
);

