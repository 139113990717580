export function updateProposalSentVia(value) {
  return {
    type: 'updateProposalSentVia',
    payload: {
      dynamicProposal: {
        sentVia: value
      }
    }
  };
}
