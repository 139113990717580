import { get } from 'lodash';
import { evChargerEnabledForStorageOnly } from '../backendFeatureFlags';
import constants from './constants';

const {
  EV_CHARGER_TYPE,
  EV_OUTLET_TYPE,
  EV_CHARGER
} = constants;

const OUTLET_PRICE = 0;

const MAX_WALLBOX_CHARGERS_ALLOWED = 2;
const MAX_OUTLETS_ALLOWED = 2;

export const getEvSelectionImageName = (chargerSelected) => {
  return chargerSelected ? 'ev_charger_logo' : 'ev_outlet_logo';
};

export const getEvChargerConfigs = (evmChargerConfigurations) => {
  const configuration = evmChargerConfigurations.filter((configuration) => {
    return configuration.type &&
      configuration.type.toLowerCase() === EV_CHARGER_TYPE;
  });
  return configuration.length > 0 ? configuration : null;
};

export const getEvChargerConfigIds = (evmChargerConfigurations, selectedWallbox) => {
  const configuration = evmChargerConfigurations.filter((configuration) => {
    if (configuration.configName) {
      return configuration.type &&
        configuration.type.toLowerCase() === EV_CHARGER_TYPE &&
        configuration.configName.includes(`${selectedWallbox}A`);
    }
    return configuration.type &&
        configuration.type.toLowerCase() === EV_CHARGER_TYPE;
  });
  return configuration.length > 0 ? configuration[0].configId : null;
};

export const getWallBoxConfigId = (evChargerConfigurations) => {
  const configuration = evChargerConfigurations.filter((configuration) => {
    return configuration.type && configuration.type.toLowerCase() === EV_CHARGER_TYPE;
  });
  return configuration.length > 0 ? configuration[0].configId : null;
};

export const getOutletConfigId = (evChargerConfigurations) => {
  const configuration = evChargerConfigurations.filter((configuration) => {
    return configuration.type && configuration.type.toLowerCase() === EV_OUTLET_TYPE;
  });
  return configuration.length > 0 ? configuration[0].configId : null;
};

export const getWallBoxConfigName = (evChargerConfigurations, selectedWallbox) => {
  const configuration = evChargerConfigurations.filter((configuration) => {
    if (configuration.configName) {
      return configuration.type &&
      configuration.type.toLowerCase() === EV_CHARGER_TYPE &&
      configuration.configName.includes(`${selectedWallbox}A`);
    }
    return configuration.type &&
      configuration.type.toLowerCase() === EV_CHARGER_TYPE;
  });
  return configuration.length > 0 ? configuration[0].configName : null;
};

export const getOutletConfigName = (evChargerConfigurations, selectedWallbox) => {
  const configuration = evChargerConfigurations.filter((configuration) => {
    return configuration.type &&
    configuration.type.toLowerCase() === EV_OUTLET_TYPE &&
    configuration.configName.includes(`${selectedWallbox}A`);
  });
  return configuration.length > 0 ? configuration[0].configName : null;
};

export const getWallBoxAllowedTransitions = (evChargerConfigurations, wallBoxQuantity, outletQuantity) => {
  const wallBoxId = getWallBoxConfigId(evChargerConfigurations);
  let additionAllowed = !!wallBoxId;
  let subtractionAllowed = !!wallBoxId;
  if (!wallBoxId) {
    return {
      additionAllowed,
      subtractionAllowed
    };
  }

  switch (wallBoxQuantity) {
    case 0:
      subtractionAllowed = false;
      additionAllowed = outletQuantity !== MAX_OUTLETS_ALLOWED;
      break;
    case 1:
      subtractionAllowed = true;
      additionAllowed = outletQuantity === 0;
      break;
    case 2:
      subtractionAllowed = true;
      additionAllowed = false;
      break;
    default:
      subtractionAllowed = false;
      additionAllowed = false;
  }

  return {
    additionAllowed,
    subtractionAllowed
  };
};

export const getOutletAllowedTransitions = (evChargerConfigurations, wallBoxQuantity, outletQuantity) => {
  const outletId = getOutletConfigId(evChargerConfigurations);
  let additionAllowed = !!outletId;
  let subtractionAllowed = !!outletId;
  if (!outletId) {
    return {
      additionAllowed,
      subtractionAllowed
    };
  }

  switch (outletQuantity) {
    case 0:
      subtractionAllowed = false;
      additionAllowed = (wallBoxQuantity < MAX_WALLBOX_CHARGERS_ALLOWED);
      break;
    case 1:
      subtractionAllowed = true;
      additionAllowed = (wallBoxQuantity < 1);
      break;
    case 2:
      subtractionAllowed = true;
      additionAllowed = false;
      break;
    default:
      subtractionAllowed = false;
      additionAllowed = false;
  }

  return {
    additionAllowed,
    subtractionAllowed
  };
};

export const showEvChargerForStorageOnly = (storageQuote) => {
  return evChargerEnabledForStorageOnly() && storageQuote.version > 3;
};

export const isEvChargerSelected = (storageQuote) => {
  return get(storageQuote, 'evChargerConfigs', []).filter((c) =>
    c.productName === EV_CHARGER && c.quantity > 0).length > 0;
};

export const calculatedEvChargerSize = (storageQuote, constants) => {
  const selectedEvConfigs = get(storageQuote, 'evChargerConfigs', []).filter((c) => c.productName === EV_CHARGER);
  const quantity = selectedEvConfigs.length > 0 && selectedEvConfigs[0].quantity;
  let evChargerSize = constants.evChargerSize40;
  if (selectedEvConfigs[0] && selectedEvConfigs[0].configName) {
    if (selectedEvConfigs[0].configName.includes('48A')) { evChargerSize = constants.evChargerSize48; }
  }
  return constants && (evChargerSize * quantity);
};
