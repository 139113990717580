import React from 'react';
import PropTypes from 'prop-types';
import Modal from './Modal';
import { register } from '../../../utils/redux';

export function WhyStorageModal({ modalCoordinates, closeModal }) {
  const modalValues = () => {
    return { name: 'why-storage', coordinates: modalCoordinates };
  };

  return (
    <Modal
      name="why-storage"
      showCloseButtonOnly={true}
      showIcon={true}
      modals={modalValues()}
      closeModal={closeModal}
    >
      <div className="modal-data-container">
        <img src={'/build/images/why-storage-modal-image.svg'} alt="" />
        <div className="text-container">
          <div className="compatibility-text">SunVault is only compatible with</div>
          <div className="bold-text">Type E and Type G inverters</div>
          <div className="small-text">
            <span className="highlighted-text">Chat us in the Dealer Portal</span> to confirm the inverter
            type on the installed solar system.
          </div>
        </div>
      </div>
    </Modal>
  );
}
WhyStorageModal.propTypes = {
  modalCoordinates: PropTypes.shape({
    top: PropTypes.number,
    left: PropTypes.number,
    right: PropTypes.number
  }),
  closeModal: PropTypes.func
};

export default register(
  [],
  [],
  WhyStorageModal
);
