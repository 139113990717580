import { getStateNameFromAbbreviation } from '../utils/admin_portal/stateNameHelper';

const specialStatesList = [ 'az', 'hi', 'ut' ];

export const isSpecialStateSelector = ({ account }) => ({
  isSpecialState: !!(account.state && specialStatesList.includes(account.state.toLowerCase()))
});

export const specialStateFullNameSelector = ({ account }) => ({
  specialStateFullName: getStateNameFromAbbreviation(account.state)
});

export const isConnecticutSelector = ({ account }) => ({
  isConnecticut: (/ct/i).test(account.state)
});

export const isNewYorkSelector = ({ account }) => ({
  isNewYork: (/ny/i).test(account.state)
});

const newYorkCities = [ 'New York', 'New York City', 'NYC', 'Manhattan',
  'Brooklyn', 'Queens', 'Staten Island', 'Bronx', 'The Bronx' ];

export const isNewYorkCitySelector = ({ account }) => ({
  isNewYorkCity: ((/ny/i).test(account.state) &&
    newYorkCities.map((v) => v.toLowerCase()).indexOf(account.city.toLowerCase()) > -1)
});
