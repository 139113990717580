import { asyncPayload } from '../apiHelpers';
import Api from '../../utils/Api';

export function generateStorageQuoteProposalFile(storageQuote, extension) {
  const url = `/storage/quotes/${storageQuote.sfid}/proposal.${extension}`;
  const promise = Api.fetchFile(`${url}`)
    .then(async (fileBlob) => {
      const types = {
        docx: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        pdf: 'application/pdf'
      };
      const type = types[extension];
      const blob = new Blob([fileBlob], { type });
      return new Promise((done, _fail) => {
        const reader = new FileReader();
        reader.onload = (_progressEvent) => {
          done({
            proposal: {
              [`${extension}Url`]: URL.createObjectURL(blob),
              [`${extension}DataUrl`]: reader.result
            }
          });
        };
        reader.readAsDataURL(blob);
      });
    });

  return {
    type: 'generateStorageQuoteProposalFile',
    payload: asyncPayload({
      beforeRequest: {
        loading: true
      },
      request: promise
    })
  };
}
