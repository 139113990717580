import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { get } from 'lodash';
import { register } from '../../utils/redux';
import { accountType, creditType, quoteType, userDataType } from '../../types';
import constants from '../../utils/EddieCalculations/constants';

const {
  CREDIT_NOT_PRESENT,
  CREDIT_APP_SENT,
  CREDIT_APP_OPENED
} = constants;

export function BeginCreditAppView({
  credit,
  quote,
  getCreditAppUrl,
  toggleCreditAppModal,
  updateCreditApp,
  userData,
  account
}) {
  const isExpiredQuote = get(quote, 'expired', false);
  const creditCheckAck = get(quote, 'creditCheckAck');
  const creditStatus = get(credit, `status.${quote.selectedFinanceOption}`);
  const beginCreditApp = creditStatus === CREDIT_NOT_PRESENT || creditStatus === CREDIT_APP_SENT
    || creditStatus === CREDIT_APP_OPENED;
  const enableBtn = beginCreditApp && !isExpiredQuote && creditCheckAck;
  const isPdfProcessing = get(account, 'isPDFProcessing');

  // if credit app is Credit App Sent then show Credit application complete
  // if not complete then show Begin Credit Application

  // calls sfdc gets the link and
  // show it in the modal
  const beginCreditApplication = () => {
    getCreditAppUrl(quote.sfid);
    toggleCreditAppModal();
  };

  const acknowledgeCreditApp = (e) => {
    updateCreditApp(
      quote.sfid,
      {
        sentForCreditCheck: e.target.checked,
        partnerUserId: get(userData, 'sfid')
      });
  };

  return (
    <Fragment>
      <div className="begin-credit-app-container">
        <div>
          <label className="acknowledgement-container">
            <input
              type="checkbox"
              checked={creditCheckAck}
              disabled={!beginCreditApp}
              onChange={(e) => acknowledgeCreditApp(e)}
            />
            <span className="checkmark" />
            <b> By Checking this box in my capacity as an Energy Consultant,</b>
            <br />
            <span className="ack-text">
              I represent to SunPower Corporation (including its affiliated entities) that
              I am providing any applicable SunPower applicant access to the SunPower financing
              application and they he/she solely will submit the application on their/her/his own behalf.
            </span>
          </label>
        </div>
        {!isPdfProcessing && (
          <button
            className={cx('btn btn-primary begin-credit-app-btn', { disabled: !enableBtn })}
            disabled={!enableBtn}
            onClick={() => beginCreditApplication()}
          >
            {beginCreditApp ? 'Begin Credit Application' : 'Credit Application Complete'}
          </button>
        )}
      </div>
    </Fragment>
  );
}

BeginCreditAppView.propTypes = {
  quote: quoteType.isRequired,
  credit: creditType.isRequired,
  getCreditAppUrl: PropTypes.func,
  toggleCreditAppModal: PropTypes.func,
  updateCreditApp: PropTypes.func,
  userData: userDataType,
  account: accountType.isRequired
};

export default register(
  [],
  [],
  BeginCreditAppView
);

