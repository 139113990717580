import uniq from 'lodash/uniq';
import range from 'lodash/range';
import {
  enabledArrayCount,
  enabledPanelCount,
  pitchConditionPanelCount,
  landscapePanelCount,
  islandPanelCount,
  activeEnabledPanelCount,
  estimateAnnualEnergyProduction,
  separateStructures,
  roofPlanes,
  systemCapacityWatts
} from '../utils/EddieCalculations/design';
import { largeSystemDiscount } from '../utils/EddieCalculations/finance';
import {
  installationDistanceAdderCost,
  skirtGuardAdderCost,
  critterGuardAdderCost,
  groundMountAdderCost,
  applyInstallationDistanceAdder,
  isCritterGuardAdderSelected,
  isSkirtGuardAdderSelected,
  isGroundMountRackingSelected
} from '../utils/EddieCalculations/adders';
import { isNewYorkCitySelector } from './accountState';

export const designStructureRangeSelector = ({ design }) => range(
  1,
  Math.min(
    uniq(design.moduleArrays.map(({ structure }) => (structure || 1))).length + 2,
    design.moduleArrays.length + 1
  )
);

export const mockPvPanelFromLockedQuote = (quote) => {
  if (quote.lockedPvModuleItem) return quote.lockedPvModuleItem;
  if (quote.lockedModuleName) {
    const itemCategory = quote.lockedModuleName.match(/^(\d+)W/)[1];
    return {
      item: { itemCategory },
      name: quote.lockedModuleName
    };
  }
  return { item: { itemCategory: 0 } };
};

export const systemCapacityWattsSelector = ({ quote, design, pvModules, instantQuoteMode, instantQuoteProduction }) => {
  if (instantQuoteMode) {
    return { systemCapacityWatts: instantQuoteProduction.systemSize * 1000 };
  }
  const pvModule = pvModules.find((pvModule) => quote.pvModuleItemId === pvModule.itemId) ||
    mockPvPanelFromLockedQuote(quote);
  return { systemCapacityWatts: systemCapacityWatts(design, pvModule) };
};

export const annualKwhProductionSelector = ({ energyData }) => {
  return { annualKwhProduction: energyData.year1Production ? energyData.year1Production : 0 };
};

const systemSize = (quote, design, pvModules) => {
  const pvModule = pvModules.find((pvModule) => quote.pvModuleItemId === pvModule.itemId) ||
    mockPvPanelFromLockedQuote(quote);
  return systemCapacityWatts(design, pvModule);
};

export default ({ schema, design, quote, account, pvModules, moduleSelectionModal }) => ({
  design: {
    ...design,
    enabledArrayCount: enabledArrayCount(design),
    enabledPanelCount: enabledPanelCount(design),
    flatRoofPanelCount: pitchConditionPanelCount(design, 'flat'),
    steepRoofPanelCount: pitchConditionPanelCount(design, 'steep'),
    landscapePanelCount: landscapePanelCount(design),
    islandPanelCount: islandPanelCount(design),
    installationDistanceAdder: applyInstallationDistanceAdder(quote),
    installationDistanceAdderCost: installationDistanceAdderCost(schema, quote),
    critterGuardAdder: isCritterGuardAdderSelected(design, moduleSelectionModal, quote),
    skirtGuardAdder: isSkirtGuardAdderSelected(design, moduleSelectionModal, quote),
    critterGuardAdderCost: critterGuardAdderCost(schema, enabledPanelCount(design)),
    skirtGuardAdderCost: skirtGuardAdderCost(schema, enabledPanelCount(design)),
    groundMountAdder: isGroundMountRackingSelected(quote),
    groundMountAdderCost: groundMountAdderCost(schema, systemSize(quote, design, pvModules)),
    activeEnabledPanelCount: activeEnabledPanelCount(design),
    totalEnergyProduction: Math.floor(estimateAnnualEnergyProduction(quote, design)),
    separateStructures: separateStructures(design),
    roofPlanes: roofPlanes(design),
    isNYC: isNewYorkCitySelector({ account }).isNewYorkCity,
    systemSize: systemSize(quote, design, pvModules),
    satelliteImgUrl: `/quotes/${quote.sfid}/image/satelliteImg.png`,
    iqSatelliteImgUrl: `/instant_quote/quotes/${quote.sfid}/satellite_image`,
    largeSystemDiscount: largeSystemDiscount(quote, account.partner, systemSize(quote, design, pvModules)) > 0,
    largeSystemDiscountCost: largeSystemDiscount(quote, account.partner, systemSize(quote, design, pvModules))
  }
});

