import cx from 'classnames';
import get from 'lodash/get';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import {
  BASIC_VIEW,
  CALCULATING_SAVINGS,
  DETAILED_VIEW,
  MODE_CHANGE_ARRAYS,
  MODE_CHANGE_PANELS,
  ROOF_PREVIEW_MAP,
  ROOF_PREVIEW_VISUALIZATION
} from '../../config';
import {
  accountType,
  businessFunctionsType,
  designType,
  dropdownsType,
  energyDataType,
  errorType,
  evChargerSelectionModalType,
  modeType,
  monitoringConfigurationType,
  pvModuleType,
  quoteType,
  selectedDesignType,
  selectedEvChargerConfigurationType,
  selectedStorageType,
  selectedStorageWithExpansionPackType,
  settingsType,
  storageSelectionModalType
} from '../../types';
import { getDegradation, numberOfInverters, pvModuleDetailBoxImgUrl } from '../../utils/EddieCalculations/design';
import {
  getEvSelectionImageName,
  isEvChargerSelected,
  isEvOutletSelected,
  showEvCharger
} from '../../utils/EvChargers/evChargers';
import { showStorageWithExpPack } from '../../utils/StorageWithMultiInverters/helpers';
import { enableDesignRevision, storageExpansionPackEnabled } from '../../utils/backendFeatureFlags';
import { gaTrackEvent } from '../../utils/googleAnalytics';
import { getMonitoringName, getRackingName, getSystemName } from '../../utils/normalizedNames';
import { nyStateEnergyOffsetCheck, nyStateEnergyOffsetMsg } from '../../utils/nyStateEnergyOffsetCheck';
import { setPricingsControls } from '../../utils/pricingDefaultsHelper';
import { updateStorageCommission } from '../../utils/admin_portal/quoteHelpers';
import { getAvailableStorageConfigs } from '../../utils/storageWithMultiInverter';
import { register } from '../../utils/redux';
import {
  getStorageCombinationImageName,
  getStorageUnitsImage,
  isStorageEnabled,
  isStorageSelected,
  storage3pImage,
  storageMaxKwh,
  storageName,
  storageNameImage,
  totalQuantity
} from '../../utils/storage';
import { getStorageName } from '../../utils/sunPowerStorage';
import { minimumSystemSizeForStorage } from '../../utils/statesFeatureFlag';
import Link from '../Link';
import { MessagesOverlay } from '../MessagesOverlay';
import MonthlyProductionModal from '../MonthlyProductionModal';
import PercentCircle from '../PercentCircle';
import ValueBox from '../ValueBox';
import VisualizationLegend from '../VisualizationLegend';
import AddEvChargerModal from '../add_ev_charger/AddEvChargerModal';
import AddStorageModal from '../add_storage/AddStorageModal';
import AddStorageWith3pModal from '../add_storage/AddStorageWith3pModal';
import AddStorageWithMultiInvertersModal from '../add_storage/AddStorageWithMultiInvertersModal';
import RoofPreview from '../design_page/RoofPreview';
import ToggleRoofPreviewMode from '../dynamic_design/ToggleRoofPreviewMode';
import ModuleSelectionModal from '../module_selection_modal/ModuleSelectionModal';
import { DesignDropdown } from './DesignDropdown';
import OptimizationLightBulb from './OptimizationLightBulb';
import OptimizationModal from './OptimizationModal';

export function DesignPage({
  account, design, quote, mode, pvModule, roofPreviewMode, viewMode, annualUsage,
  toggleMode, setRoofPreviewMode, setViewMode, error, energyData, settings,
  isSaved, validateAndSaveSystemDesign, monitoringConfiguration, setMessage,
  toggleModuleSelectionModal, toggleAddStorageModalFlag, selectedStorage, pvModules,
  storageSelectionModal, updateStorage, businessFunctions, updateSettings, isPartnerEnabledForAnyStorage,
  selectedStorageWithExpansionPack, isSpecialState, specialStateFullName,
  toggleAddEvChargerModalFlag, evChargerSelectionModal, selectedEvChargerConfiguration,
  updateEvChargerConfig, partnerIsNewHomes, partnerIsRvar, partnerIsTps,
  toggleDesignsListDropdown, dropdowns, selectDesign, designVersion, resetSettings,
  selectedDesign, toggleMonthlyProductionModal, saveModuleMonitoringRacking, pvModuleDetails
}) {
  if (get(error, 'isFatal', false)) return null;

  const systemSizeKW = design.systemSize / 1000;
  const energyProduction = Math.round(design.totalEnergyProduction * getDegradation(pvModule));
  const energyOffset = Math.round(energyProduction / annualUsage * 100);
  const isNewDesign = !design.hasChanges && design.enabledPanelCount === 0;
  const selectedPvModule = quote.locked ? quote.lockedPvModuleItem : pvModule;
  const availableStorageConfigs = getAvailableStorageConfigs(pvModule, quote, settings, account);
  if (selectedPvModule === undefined) return null;
  const disclaimerText = isSpecialState ?
    `${'Electricity Production and Offset are preliminary estimates. Final estimated ' +
    'Electricity Offset will be displayed on the Electricity page and Proposal. ' +
    'In the state of'}${specialStateFullName}, the EDDiE app does not illustrate or describe ` +
    'potential financial savings associated with installing a SunPower solar system.' :

    'Electricity Production and Offset are preliminary estimates. Final estimated ' +
    'Electricity Offset will be displayed on the Savings page and Proposal.';

  const designTabs = [
    { mode: BASIC_VIEW, label: 'Summary' },
    { mode: DETAILED_VIEW, label: 'Details' }
  ];

  const monthlySolarAccessInput = (label, id) => {
    const value = design.activeModule ?
      design.moduleArrays[design.activeModule - 1].monthlySolarAccess[id] : 0;

    return (
      <div className="row month-solar-access">
        <label className="col-md-12 month-solar-access-label">{label}</label>
        <div className="input-container input-group detailed-view-solar-access">
          <input
            type="text"
            name={`monthly-solar-access-${id}`}
            id={`monthly-solar-access-${id}`}
            value={value}
            disabled={true}
          />
        </div>
      </div>
    );
  };

  const shadingMeasurementDate = design.activeModule ?
    moment(design.moduleArrays[design.activeModule - 1].shadingMeasurementDate).format('MM/DD/YYYY') : '-';

  const moduleDetailBoxImgUrl = pvModuleDetailBoxImgUrl(selectedPvModule, pvModuleDetails);

  const getMountingImageSrc = (rackingType, moduleBackSheetType) => {
    if (rackingType === 'Invisimount') {
      if (moduleBackSheetType === 'White Back-Sheet') {
        return 'invisimount-white';
      }
      return 'invisimount-black';
    }
    return 'non-sunpower';
  };

  const mountingImageSrc = getMountingImageSrc(quote.rackingType, selectedPvModule.item.moduleBackSheetType);
  const monitoringName = getMonitoringName(selectedStorageWithExpansionPack, monitoringConfiguration.name);

  const showNotifications = account.features.designOptimization || account.features.addersInEddie;

  const pitchValue = (pitch) => {
    return pitch.toString().includes('°') ? pitch : `${pitch}°`;
  };
  const storageUnitsImage = getStorageUnitsImage(quote);

  const [ showStorageBySystemSize, setShowStorageBySystemSize ] = useState(true);
  const storageSelected = isStorageSelected(storageSelectionModal,
    quote, selectedStorageWithExpansionPack, selectedStorage);
  const evChargerSelected = isEvChargerSelected(quote) || isEvOutletSelected(quote);
  const inverters = numberOfInverters(quote);
  const inverterText = inverters === 1 ? 'Inverter' : 'Inverters';
  const storageEnabled = isStorageEnabled(businessFunctions, isPartnerEnabledForAnyStorage) && showStorageBySystemSize;
  const chargerSelected = get(selectedEvChargerConfiguration, 'evChargerQuantity', 0) > 0;

  const deleteSelectedEvConfigs = () => {
    // eslint-disable-next-line no-alert
    if (confirm('Are you sure you want to delete this EV Charger selection?')) {
      const params = [];
      if (selectedEvChargerConfiguration.evChargerId) {
        params.push({
          id: selectedEvChargerConfiguration.evChargerId,
          _destroy: true
        });
      }
      if (selectedEvChargerConfiguration.evOutletId) {
        params.push({
          id: selectedEvChargerConfiguration.evOutletId,
          _destroy: true
        });
      }
      updateEvChargerConfig(quote, params);
    }
  };

  const evChargerOutputDetails = () => {
    if (chargerSelected) {
      return (
        <React.Fragment>
          <div className="values">
            { selectedEvChargerConfiguration.evChargerConfigName ?
              <div>{selectedEvChargerConfiguration.evChargerConfigName.includes('40A') ? 9.6 : 11.5}</div>
            : <div>9.6</div>
            }
            <div className="unit">kW</div>
          </div>
          <div className="details">
            EV Charger Size
          </div>
        </React.Fragment>
      );
    }
    return (
      <React.Fragment>
        <div className="values">
          <div>240</div>
          <div className="unit">Volts</div>
        </div>
        <div className="details">
          Outlet Size
        </div>
      </React.Fragment>
    );
  };

  function displayMsg(offsetMsgCheck) {
    nyStateEnergyOffsetMsg((offsetMsgCheck && !quote.dirty), setMessage);
    return <MessagesOverlay />;
  }

  const changeDesign = (design, quote) => {
    const pvModuleItemId = selectedPvModule.itemId;
    saveModuleMonitoringRacking({
      quote,
      pvModuleItemId,
      monitoringConfigurationId: quote.monitoringConfigurationId,
      rackingType: quote.selectedRacking,
      isSkirtGuard: quote.isSkirtGuard,
      isCritterGuard: quote.isCritterGuard,
      roofDesignId: design.id,
      settings,
      resetSettings
    });
  };

  useEffect(() => {
    const offsetMsgCheck = nyStateEnergyOffsetCheck(account, energyOffset, partnerIsTps,
      partnerIsRvar, settings, quote);
    displayMsg(offsetMsgCheck);
  }, [isSaved]);

  const selectedPaymentOptions = [ 'cash', 'lease', 'loan', 'spwrCash', 'ppa' ];
  const [ quoteVariantIsCloned, setQuoteVariantIsCloned ] = useState(quote.cloneVariant);

  useEffect(() => {
    if (!quoteVariantIsCloned) {
      if (quote.locked === false) {
        const storageConfigs = updateStorageCommission(quote, settings);
        if (Object.keys(storageConfigs).length > 0) {
          Object.keys(storageConfigs).forEach((finType) => {
            if (storageConfigs[finType].sell === true) {
              updateSettings(finType, 'storageDealerFee', storageConfigs[finType].commissionPerUnit);
              updateSettings(finType, 'storagePriceSelection', 'storageDealerFee');
            } else {
              updateSettings(finType, 'storageDealerFee', 0);
              updateSettings(finType, 'storagePriceSelection', 'storagePrice');
            }
          });
        } else {
          selectedPaymentOptions.forEach((finType) => {
            updateSettings(finType, 'storageDealerFee', 0);
            updateSettings(finType, 'storagePriceSelection', 'storagePrice');
          });
        }
      }
    } else {
      setQuoteVariantIsCloned(false);
    }
  }, [quote.storageConfigId]);

  const [ previousSize, setPreviousSize ] = useState(systemSizeKW);
  const [ previousSKU, setPreviousSKU ] = useState(quote.pvModuleItemId);

  useEffect(() => {
    if (!partnerIsNewHomes) {
      const systemSizeCheck = minimumSystemSizeForStorage(quote, design, settings);
      setShowStorageBySystemSize(!!systemSizeCheck);
      if (!systemSizeCheck && quote.storageConfigId) {
        const params = {
          storageConfigId: null,
          storageQuantity: null,
          storageLocation: null,
          storageBackup: null,
          storageExpansionPackQuantity: null,
          storageLaborCost: null,
          batterySize: null,
          batteryManufacturer: null,
          backupCapable: null,
          batteryWarranty: null
        };
        updateStorage(quote, params);
      }
    }
  }, [ design.systemSize, settings ]);

  useEffect(() => {
    if (previousSize !== systemSizeKW || previousSKU !== quote.pvModuleItemId) {
      setPreviousSize(systemSizeKW);
      setPreviousSKU(quote.pvModuleItemId);
      resetSettings(
        account,
        design,
        quote,
        pvModules,
        settings,
        updateSettings
      );
    }
  }, [ systemSizeKW, quote.pvModuleItemId ]);

  useEffect(() => {
    if (!quote.cloneOriginalSfid) {
      setPricingsControls(
        account,
        design,
        quote,
        pvModules,
        settings,
        updateSettings
      );
    }
  }, []);

  const isDesignVersionDsiabled = !!quote.locked;
  const { designList, maxFitDesignList } = designVersion || {};

  const totalSize = totalQuantity(quote);

  return (
    <div id="main-content">
      <div id="design-visualization-container" className="new">
        <div className="optimization_bulb_container">
          <div className="bulb_with_energy_legend">
            {showNotifications &&
              <OptimizationLightBulb />
            }
            {roofPreviewMode === ROOF_PREVIEW_VISUALIZATION && <VisualizationLegend />}
          </div>
          <ToggleRoofPreviewMode
            roofPreviewMode={roofPreviewMode}
            setRoofPreviewMode={setRoofPreviewMode}
            account={account}
            interactive={true}
          />
        </div>
        <div className="roof_preview_container">
          {enableDesignRevision() && designVersion && (<div className="section">
            <DesignDropdown
              value={design.maxfitSfid}
              designList={designList}
              maxFitDesignList={maxFitDesignList}
              onChange={(event) => changeDesign(event, quote)}
              data={designList.map((design) => {
                return { key: design.maxfitSfid, value: design.maxfitSfid };
              })}
              dropdowns={dropdowns}
              toggleDesignsListDropdown={toggleDesignsListDropdown}
              disabled={isDesignVersionDsiabled}
            />
          </div>)}
          <RoofPreview
            roofPreviewMode={roofPreviewMode}
            setRoofPreviewMode={setRoofPreviewMode}
            account={account}
            interactive={true}
          />
          <div className="section">
            <div className="row">
              <ValueBox
                label="Roofs"
                className="new"
                valueColor="value-color"
                value={design.enabledArrayCount}
                isSelected={mode === MODE_CHANGE_ARRAYS}
                orange={mode === MODE_CHANGE_ARRAYS}
                onClick={() => {
                  gaTrackEvent('quotePage:designTab:roofsButton');
                  toggleMode(MODE_CHANGE_ARRAYS);
                }}
                disabled={mode === CALCULATING_SAVINGS || quote.locked || roofPreviewMode === ROOF_PREVIEW_MAP}
              />
              <ValueBox
                label="Panels"
                className="new"
                valueColor="value-color"
                value={design.enabledPanelCount}
                orange={mode === MODE_CHANGE_PANELS}
                isSelected={mode === MODE_CHANGE_PANELS}
                onClick={() => {
                  gaTrackEvent('quotePage:designTab:panelsButton');
                  toggleMode(MODE_CHANGE_PANELS);
                }}
                disabled={mode === CALCULATING_SAVINGS || quote.locked || roofPreviewMode === ROOF_PREVIEW_MAP}
              />
            </div>
          </div>
        </div>
      </div>

      <div id="value-box-container" className="new">
        <div className="visualization-tabs">
          {designTabs.map((tab) =>
            <button
              key={tab.mode}
              className={`btn visualization-tabs__btn ${
                (viewMode === tab.mode ? 'visualization-tabs__btn--active' : '')
                }`}
              onClick={() => {
                setViewMode(tab.mode);
                gaTrackEvent(
                  tab.mode === BASIC_VIEW ? 'quotePage:designTab:basicView' : 'quotePage:designTab:detailedView'
                );
              }}
            >
              {tab.label}
            </button>
          )}
        </div>
        {(viewMode === BASIC_VIEW) &&
          <div>
            { quote.locked && (
              <div className="guide">
                <span>This design is locked and cannot be edited</span>
              </div>
            ) }
            <div className="section">
              <div className="row energy">
                <PercentCircle
                  label="ENERGY OFFSET (EST.)"
                  labelPosition="inside"
                  className="new half"
                  value={energyOffset}
                />
                <div className="column half">
                  <ValueBox
                    className="new"
                    label="System Size"
                    value={systemSizeKW}
                    subvalue="kW"
                    places={2}
                  />
                  <ValueBox
                    className="new"
                    label="Electricity Production (est.)"
                    value={energyProduction}
                    subvalue="kWh/year"
                    toggleMonthlyProductionModal={toggleMonthlyProductionModal}
                    design={design}
                    isSaved={isSaved}
                  />
                  <ValueBox
                    className="new"
                    label="Energy Needs"
                    value={Math.round(annualUsage)}
                    subvalue="kWh/year"
                  />
                </div>
              </div>
            </div>
            <div className="section top-border new">
              <h4 className="top-border-title">
                EQUIPMENT
                { !quote.locked && (
                  <img
                    className="storage-icon edit" onClick={() => {
                      toggleModuleSelectionModal();
                      gaTrackEvent('quotePage:designTab:editEquipment');
                    }}
                    src="/build/images/storage-edit.svg" height="15" alt="Edit Icon"
                  />
                )}
              </h4>
              <div className="row">
                <div className="details-box">
                  <div className={cx('image')} style={{ backgroundImage: `url(${moduleDetailBoxImgUrl})` }} />
                  <div className="details-info">
                    <div className="details-title">
                      EQUINOX SYSTEM
                    </div>
                    <p>
                      {selectedPvModule.item.productLine}<br />
                      {getSystemName(selectedPvModule.item.summaryDescription)}
                    </p>
                  </div>
                </div>
                <div className="details-box">
                  <div className={cx('image', mountingImageSrc)} />
                  <div className="details-info">
                    <div className="details-title">
                      MOUNTING
                    </div>
                    <p>{getRackingName(quote.rackingType)}</p>
                  </div>
                </div>
                <div className="details-box">
                  <div className="image monitoring" />
                  <div className="details-info">
                    <div className="details-title">
                      MONITORING
                    </div>
                    <p>{monitoringName}</p>
                  </div>
                </div>
              </div>
            </div>

            { storageEnabled && (
              <div
                className={cx('section top-border storage-selection new',
                { 'ev-charger-enabled': showEvCharger(partnerIsNewHomes, quote, businessFunctions) })}
              >
                <h4 className="top-border-title">
                  STORAGE
                  { storageSelected && !quote.locked && (
                    <React.Fragment>
                      <img
                        className="storage-icon edit" onClick={toggleAddStorageModalFlag}
                        src="/build/images/storage-edit.svg" height="15" alt="Edit Icon"
                      />
                      <img
                        className="storage-icon delete"
                        src="/build/images/storage-delete.svg" height="15" alt="Delete Icon"
                        onClick={(evt) => {
                          evt.preventDefault();
                          /* eslint-disable no-alert */
                          if (confirm('Are you sure you want to delete this storage?')) {
                            const params = {
                              storageConfigId: null,
                              storageQuantity: null,
                              storageLocation: null,
                              storageBackup: null,
                              storageExpansionPackQuantity: null,
                              storageLaborCost: null,
                              batterySize: null,
                              batteryManufacturer: null,
                              backupCapable: null,
                              batteryWarranty: null
                            };
                            updateStorage(quote, params);
                          }
                          /* eslint-enable no-alert */
                        }}
                      />
                    </React.Fragment>
                  )}
                  { storageSelected && quote.locked && (
                    <img
                      className="storage-icon view" onClick={toggleAddStorageModalFlag}
                      src="/build/images/storage-view.svg" height="15" alt="View Icon"
                    />
                  )}
                </h4>
                { storageSelectionModal.storages.length > 0 && !storageSelected &&
                !quote.locked && availableStorageConfigs.length > 0 && (
                  <div>
                    <div className="row">
                      <p className="no-storage">No storage selected</p>
                    </div>
                    <div className="row last">
                      <button
                        id="add-storage-btn"
                        className="btn-sunpower"
                        onClick={() => {
                          toggleAddStorageModalFlag();
                          gaTrackEvent('quotePage:designTab:addStorage');
                        }}
                      >
                          Add Storage
                      </button>
                    </div>
                  </div>
                )}
                { storageSelected && !showStorageWithExpPack(storageExpansionPackEnabled(), quote) && (
                  <div className="storage-details">
                    <div className="storage-name storage-box">
                      <div
                        className={cx('image',
                          storageNameImage(getStorageName(selectedStorage, selectedStorageWithExpansionPack, quote),
                            quote))}
                        height="90"
                      />
                      <div className="storage-info new">
                        <div className="storage-name">
                          {storageName(getStorageName(selectedStorage, selectedStorageWithExpansionPack, quote))}
                        </div>
                        <p>
                          WITH:
                        </p>
                        <div className="st-div1">
                          <strong>{inverters}</strong>&nbsp;{inverterText}
                        </div>
                      </div>
                    </div>
                    <div className="storage-quantity storage-box new">
                      <img
                        src={`/build/images/storage-${storageUnitsImage}-units.svg`}
                        alt="Energy units" width="100"
                      />
                      <div className="st-div2">Quantity:<strong>&nbsp;{quote.storageQuantity}</strong></div>
                    </div>
                    <div className="storage-kwh storage-box new">
                      <h2 className="new">
                        {storageMaxKwh(quote, selectedStorage, selectedStorageWithExpansionPack)}
                        <br />
                        <span>kWh</span>
                      </h2>
                      <div className="st-div3">Storage Size</div>
                    </div>
                  </div>
                )}
                { storageSelected && showStorageWithExpPack(storageExpansionPackEnabled(), quote) && (
                  quote.version >= 14 ?
                    <div className="storage-3p">
                      <div className="storage-details-3p">
                        <div
                          className={
                            cx('image expansion',
                              storage3pImage((quote.batteryManufacturer || 'SunPower'), totalSize,
                              !!quote.backupCapable))
                          }
                        />
                        <div className="storage-box-3p">
                          <div className="storage-type battery-name">
                            <span>Storage Type</span>
                            <div>
                              {quote.storageName}
                            </div>
                          </div>
                          <div className="battery-details">
                            <div className="storage-type storage-size">
                              <span>Storage Size</span>
                              <div>{totalSize || quote.storageSize} kWh</div>
                            </div>
                            <div className="storage-type">
                              <span>Battery QTY</span>
                              <div>{quote.storageQuantity}</div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  :
                    <div className="storage-details">
                      <div className="storage-name storage-box">
                        <div
                          className={cx('image expansion',
                        storageNameImage(getStorageName(selectedStorage, selectedStorageWithExpansionPack, quote),
                          quote))}
                        />
                        <div className="storage-info new expansion">
                          <div className="name"> SUNVAULT&trade;</div>
                          <div className="st-div1 expansion">
                          Storage System
                        </div>
                        </div>
                      </div>
                      <div id="storage-info" className="storage-quantity storage-box new">
                        <img
                          src={`/build/images/${getStorageCombinationImageName(quote)}.svg`}
                          alt="Energy units" className="expansion"
                        />
                        <div className="st-div2 expansion">
                          <div className="name">Batteries:</div>
                          <strong id="storage-quantity-label">&nbsp;{quote.storageQuantity}</strong></div>
                        <div className="st-div2 expansion">
                          <div className="name">Expansion Packs:</div>
                          <strong id="expansion-quantity-label">&nbsp;{quote.storageExpansionPackQuantity}</strong>
                        </div>
                      </div>
                      <div className="storage-kwh storage-box new">
                        <h2 className="new">
                          {storageMaxKwh(quote, selectedStorage, selectedStorageWithExpansionPack)}
                          <br />
                          <span>kWh</span>
                        </h2>
                        <div className="st-div3">Storage Size</div>
                      </div>
                    </div>
                )}
                { storageSelectionModal.storages.length === 0 && (
                  <div className="row">
                    <p>Please select another module to enable storage options</p>
                  </div>
                )}
              </div>
            )}
            { showEvCharger(partnerIsNewHomes, quote, businessFunctions) && (
              <div className="section top-border ev-charger-selection new">
                <h4 className="top-border-title">
                  EV ACCESSORIES
                  { evChargerSelected && !quote.locked && (
                    <React.Fragment>
                      <img
                        className="storage-icon edit" onClick={toggleAddEvChargerModalFlag}
                        src="/build/images/storage-edit.svg" height="15" alt="Edit Icon"
                      />
                      <img
                        className="storage-icon delete"
                        src="/build/images/storage-delete.svg" height="15" alt="Delete Icon"
                        onClick={(evt) => {
                          evt.preventDefault();
                          deleteSelectedEvConfigs();
                        }}
                      />
                    </React.Fragment>
                  )}
                  { evChargerSelected && quote.locked && (
                    <img
                      className="storage-icon view" onClick={toggleAddEvChargerModalFlag}
                      src="/build/images/storage-view.svg" height="15" alt="View Icon"
                    />
                  )}
                </h4>
                { evChargerSelectionModal.evChargers.length > 0 &&
                  !evChargerSelected && !quote.locked && (
                  <div className="label">
                    <div className="no-ev-charger">No chargers selected</div>
                    <div
                      className="btn-sunpower"
                      onClick={() => {
                        toggleAddEvChargerModalFlag();
                        gaTrackEvent('quotePage:designTab:addEvCharger');
                      }}
                    >
                      Add EV Chargers
                  </div>
                  </div>
                )}
                { evChargerSelected && (
                  <div className="ev-charger-details">
                    <div className="item-detail">
                      <div className="image ev_charger" />
                      <div className="description">
                        <div className="item-label"> WALLBOX</div>
                        <div className="item-name">
                          EV Charger
                        </div>
                      </div>
                    </div>
                    <div className="item-quantity">
                      <div className="image-container">
                        <img
                          src={`/build/images/${getEvSelectionImageName(chargerSelected)}.svg`}
                          alt="EV Selection Units"
                        />
                      </div>
                      <div className="details">
                        <div>EV Chargers: <span className="value">&nbsp;
                          {get(selectedEvChargerConfiguration, 'evChargerQuantity', 0)}</span>
                        </div>
                        <div>Additional Outlets: <span className="value">&nbsp;
                          {get(selectedEvChargerConfiguration, 'evOutletQuantity', 0)}</span>
                        </div>
                      </div>
                    </div>
                    <div className="item-size">
                      { evChargerOutputDetails() }
                    </div>
                  </div>
                )}
                { evChargerSelectionModal.evChargers.length === 0 && (
                  <div className="no-config-available-message">
                    Please select another module to enable EV Charger options
                  </div>
                )}
              </div>
            )}
          </div>
        }
        {(viewMode === DETAILED_VIEW) &&
          <div>
            <div className="section">
              <div className="row">
                <ValueBox
                  label="Pitch"
                  value={design.activeModule ? pitchValue(design.moduleArrays[design.activeModule - 1].pitch) : '-'}
                  isSelected={false}
                  onClick={() => {}}
                  disabled={true}
                />
                <ValueBox
                  label="Azimuth"
                  value={design.activeModule ? `${design.moduleArrays[design.activeModule - 1].azimuth}°` : '-'}
                  isSelected={false}
                  onClick={() => {}}
                  disabled={true}
                />
              </div>
            </div>
            <div className="section">
              <div className="row">
                <div className="value-box">
                  <div>
                    <span className="label month-solar-access-title">Roof Solar Access Percentages</span>
                    <div className="value-container">
                      <div className="row col-md-12 month-solar-access-row">
                        <div className="col-md-1">
                          {monthlySolarAccessInput('JAN', 0)}
                        </div>
                        <div className="col-md-1 col-md-offset-1">
                          {monthlySolarAccessInput('FEB', 1)}
                        </div>
                        <div className="col-md-1 col-md-offset-1">
                          {monthlySolarAccessInput('MAR', 2)}
                        </div>
                        <div className="col-md-1 col-md-offset-1">
                          {monthlySolarAccessInput('APR', 3)}
                        </div>
                        <div className="col-md-1 col-md-offset-1">
                          {monthlySolarAccessInput('MAY', 4)}
                        </div>
                        <div className="col-md-1 col-md-offset-1">
                          {monthlySolarAccessInput('JUN', 5)}
                        </div>
                      </div>
                      <div className="row col-md-12 month-solar-access-row">
                        <div className="col-md-1">
                          {monthlySolarAccessInput('JUL', 6)}
                        </div>
                        <div className="col-md-1 col-md-offset-1">
                          {monthlySolarAccessInput('AUG', 7)}
                        </div>
                        <div className="col-md-1 col-md-offset-1">
                          {monthlySolarAccessInput('SEP', 8)}
                        </div>
                        <div className="col-md-1 col-md-offset-1">
                          {monthlySolarAccessInput('OCT', 9)}
                        </div>
                        <div className="col-md-1 col-md-offset-1">
                          {monthlySolarAccessInput('NOV', 10)}
                        </div>
                        <div className="col-md-1 col-md-offset-1">
                          {monthlySolarAccessInput('DEC', 11)}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="section">
              <div className="row">
                <ValueBox
                  label="Shading Measurement Date"
                  value={shadingMeasurementDate}
                  isSelected={false}
                  onClick={() => {}}
                  disabled={true}
                />
                <ValueBox
                  label="TSRF %"
                  value={design.activeModule ? design.moduleArrays[design.activeModule - 1].annualTsrf : '-'}
                  isSelected={false}
                  onClick={() => {}}
                  disabled={true}
                />
              </div>
            </div>
          </div>
        }
        <div className="section">
          <div className="row">
            {
            isSaved ? (
              <Link
                className="view-savings btn btn-primary"
                onClick={() => { gaTrackEvent('quotePage:designTab:viewEstimatedSavings'); }}
                to={`/quote/${quote.sfid}/savings`}
              >
                View Estimated Savings
              </Link>
            ) : (
              <button
                className="btn btn-primary save"
                disabled={
                  (isNewDesign || mode === CALCULATING_SAVINGS || design.enabledPanelCount === 0)
                   && !design.hasChanges
                  }
                onClick={validateAndSaveSystemDesign}
              >
                Save Design
              </button>
            )
          }
          </div>
          <div className="row">
            <span className="disclaimer legal-text">
              {disclaimerText}
            </span>
          </div>
        </div>
      </div>
      <OptimizationModal
        show={design.notificationModal}
      />
      <ModuleSelectionModal />
      {
        !showStorageWithExpPack(storageExpansionPackEnabled(), quote) && (
          quote.version < 14 ? <AddStorageModal /> : <AddStorageWith3pModal />
        )
      }
      {
        showStorageWithExpPack(storageExpansionPackEnabled(), quote) && (
          quote.version < 14 ? <AddStorageWithMultiInvertersModal /> : <AddStorageWith3pModal />
        )
      }
      {design.monthlyProductionModal && <MonthlyProductionModal />}
      <AddEvChargerModal />
    </div>
  );
}

DesignPage.propTypes = {
  account: accountType.isRequired,
  design: designType.isRequired,
  error: errorType,
  quote: quoteType.isRequired,
  mode: modeType,
  roofPreviewMode: PropTypes.oneOf([
    ROOF_PREVIEW_VISUALIZATION,
    ROOF_PREVIEW_MAP
  ]),
  viewMode: PropTypes.oneOf([
    BASIC_VIEW,
    DETAILED_VIEW
  ]),
  resetSettings: PropTypes.func,
  pvModule: pvModuleType.isRequired,
  annualUsage: PropTypes.number.isRequired,
  toggleMode: PropTypes.func.isRequired,
  toggleModal: PropTypes.func.isRequired,
  toggleNotificationModal: PropTypes.func.isRequired,
  setRoofPreviewMode: PropTypes.func.isRequired,
  setViewMode: PropTypes.func.isRequired,
  isSaved: PropTypes.bool.isRequired,
  validateAndSaveSystemDesign: PropTypes.func.isRequired,
  monitoringConfiguration: monitoringConfigurationType.isRequired,
  toggleModuleSelectionModal: PropTypes.func.isRequired,
  toggleAddStorageModalFlag: PropTypes.func.isRequired,
  toggleDesignsListDropdown: PropTypes.func,
  selectDesign: PropTypes.func,
  selectedStorage: selectedStorageType.isRequired,
  storageSelectionModal: storageSelectionModalType.isRequired,
  updateStorage: PropTypes.func.isRequired,
  businessFunctions: businessFunctionsType,
  toggleMonthlyProductionModal: businessFunctionsType,
  settings: settingsType.isRequired,
  isPartnerEnabledForAnyStorage: PropTypes.bool.isRequired,
  selectedStorageWithExpansionPack: selectedStorageWithExpansionPackType.isRequired,
  isSpecialState: PropTypes.bool,
  specialStateFullName: PropTypes.string,
  toggleAddEvChargerModalFlag: PropTypes.func.isRequired,
  evChargerSelectionModal: evChargerSelectionModalType,
  partnerIsNewHomes: PropTypes.bool.isRequired,
  partnerIsRvar: PropTypes.bool.isRequired,
  partnerIsTps: PropTypes.bool.isRequired,
  setMessage: PropTypes.func.isRequired,
  energyData: energyDataType.isRequired,
  selectedEvChargerConfiguration: selectedEvChargerConfigurationType,
  updateEvChargerConfig: PropTypes.func.isRequired,
  dropdowns: dropdownsType,
  selectedDesign: selectedDesignType,
  designVersion: PropTypes.func.isRequired,
  pvModules: PropTypes.arrayOf(pvModuleType).isRequired,
  updateSettings: PropTypes.func.isRequired,
  saveModuleMonitoringRacking: PropTypes.func.isRequired,
  pvModuleDetails: PropTypes.arrayOf(PropTypes.object),
  pvModuleSeriesDetails: PropTypes.arrayOf(PropTypes.object)
};

DesignPage.defaultProps = {
  error: null
};

export default register(
  [
    'accountSelector', 'designSelector', 'annualUsageSelector', 'quoteSelector',
    'modeSelector', 'pvModuleSelector', 'roofPreviewModeSelector', 'viewModeSelector',
    'isSavedSelector', 'monitoringConfiguration', 'selectedStorageSelector', 'pvModulesSelector',
    'storageSelectionModalSelector', 'businessFunctionsSelector', 'settingsSelector',
    'isPartnerEnabledForAnyStorageSelector', 'selectedStorageWithExpansionPackSelector', 'isSpecialStateSelector',
    'specialStateFullNameSelector', 'evChargerSelectionModalSelector', 'selectedEvChargerConfigurationSelector',
    'partnerIsNewHomesSelector', 'partnerIsRvarSelector', 'energyDataSelector', 'partnerIsTpsSelector',
    'dropdownsSelector', 'selectedDesign', 'designVersion', 'pvModuleDetailsSelector', 'pvModuleSeriesDetailsSelector'
  ],
  [
    'toggleMode', 'toggleModal', 'setRoofPreviewMode', 'setViewMode', 'setMessage', 'toggleMonthlyProductionModal',
    'toggleNotificationModal', 'validateAndSaveSystemDesign', 'toggleModuleSelectionModal', 'resetSettings',
    'toggleAddStorageModalFlag', 'updateStorage', 'toggleAddEvChargerModalFlag', 'updateEvChargerConfig',
    'toggleDesignsListDropdown', 'selectDesign', 'saveModuleMonitoringRacking', 'updateSettings'
  ],
  DesignPage
);
