export function toggleProposalToolbarActiveItem(itemId, currentActiveItemId) {
  return {
    type: 'toggleProposalToolbarActiveItem',
    payload: {
      proposal: {
        toolbar: {
          activeItemId: currentActiveItemId === itemId ? null : itemId
        }
      }
    }
  };
}
