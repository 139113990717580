import React from 'react';
import PropTypes from 'prop-types';

export default class ValidationContainer extends React.Component {
  constructor(props) {
    super(props);

    this.isAllValid = this.isAllValid.bind(this);
    this.attachToValidatorContainer = this.attachToValidatorContainer.bind(this);
    this.detachFromValidatorContainer = this.detachFromValidatorContainer.bind(this);
    this.validateAllFields = this.validateAllFields.bind(this);
    this.validateField = this.validateField.bind(this);

    this.inputs = {};
  }

  getChildContext() {
    return {
      validateField: this.validateField,
      validateAllFields: this.validateAllFields,
      isAllValid: this.isAllValid,
      attachToValidatorContainer: this.attachToValidatorContainer,
      detachFromValidatorContainer: this.detachFromValidatorContainer
    };
  }

  attachToValidatorContainer(component) {
    this.inputs[component.props.name] = component;
  }

  detachFromValidatorContainer(component) {
    delete this.inputs[component.props.name];
  }

  validateField(component) {
    const errors = component.validate();
    this.props.storeUpdaterAction({
      [component.props.name]: errors
    });
  }

  validateAllFields() {
    const errors = Object.keys(this.inputs).reduce((m, name) => {
      return Object.assign(m, { [name]: this.inputs[name].validate() });
    }, {});

    this.props.storeUpdaterAction(errors);

    return errors;
  }

  isAllValid() {
    const errors = this.validateAllFields();
    return Object.keys(this.inputs).filter((name) => {
      const ve = errors[name];
      return ve && ve.length > 0;
    }).length === 0;
  }

  render() {
    return (
      <div>
        {this.props.children}
      </div>
    );
  }
}
ValidationContainer.childContextTypes = {
  validateField: PropTypes.func,
  validateAllFields: PropTypes.func,
  isAllValid: PropTypes.func,
  attachToValidatorContainer: PropTypes.func,
  detachFromValidatorContainer: PropTypes.func
};
ValidationContainer.propTypes = {
  storeUpdaterAction: PropTypes.func.isRequired,
  children: PropTypes.oneOfType([ PropTypes.object, PropTypes.arrayOf(PropTypes.element) ]).isRequired
};
