import _get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import round from 'lodash/round';
import get from './get';
import createSelector from '../utils/createSelector';
import {
  calculateSystemPrice,
  calculateSystemPriceWithAdders,
  calculateSystemPriceWithAddersAndDiscounts,
  getEvChargerPrice,
  getMonitoringCost,
  getStoragePrice
} from '../utils/EddieCalculations/finance';
import { isCellularMonitoringConfiguration } from '../utils/EddieCalculations/helpers/monitoringConfiguration';
import * as designCalcs from '../utils/EddieCalculations/design';
import constants from '../utils/EddieCalculations/constants';

import {
  selectedRackingSelector,
  monitoringConfiguration as monitoringConfigurationSelector,
  selectedStorageSelector,
  partnerIsRvarSelector,
  annualKwhProductionSelector,
  notificationsSelector,
  selectedStorageWithExpansionPackSelector
} from '../selectors';
import {
  partnerIsNewHomesSelector
} from './account';

import { isSpwrCash } from '../utils/EddieCalculations/helpers/financeTypes';
import { mockPvPanelFromLockedQuote } from './design';

const {
  LOAN_FINANCE_TYPE,
  LEASE_FINANCE_TYPE,
  CASH_FINANCE_TYPE,
  PPA_FINANCE_TYPE,
  SPWR_CASH_FINANCE_TYPE
} = constants;

// TODO: deprecate old naming conventions and use names that reflect
// EddieCalculations.
// NOTE: These calculations are redundant to SystemCost calculations that come from
// the server, however, they are synchronously responsive to user input in SettingsModal
// so this separate frontend implementation is necessary.

const {
  RACKING_DEALER_FEE_PER_WATT
} = constants;

function totalPriceValues(state) {
  const {
    settings,
    quote,
    design,
    pvModules,
    allSystemCosts,
    account,
    productConfiguration,
    energyData,
    instantQuoteMode,
    instantQuoteProduction
  } = state;
  const financeTypes = [ CASH_FINANCE_TYPE, LEASE_FINANCE_TYPE,
    LOAN_FINANCE_TYPE, SPWR_CASH_FINANCE_TYPE, PPA_FINANCE_TYPE ]
    .filter((financeType) => Boolean(settings[financeType]));
  const { selectedRacking } = selectedRackingSelector({ quote, settings });
  const { selectedStorage } = selectedStorageSelector({ quote, pvModules });
  const { selectedStorageWithExpansionPack } = selectedStorageWithExpansionPackSelector({ quote, pvModules });
  const { partnerIsRvar } = partnerIsRvarSelector({ account });
  const { partnerIsNewHomes } = partnerIsNewHomesSelector({ account });
  const { monitoringConfiguration } = monitoringConfigurationSelector({ quote, productConfiguration });
  const isCellularMonitoring = isCellularMonitoringConfiguration(monitoringConfiguration);

  const newSettings = {};

  const spwrTotalContractPrice = _get(allSystemCosts, 'spwrCash.totalContractPrice', null);
  const finEngMinPrice = quote.finEngMinPrice || {};
  const finEng = quote.finEng || {};

  let pvModule = pvModules.find((pvModule) => quote.pvModuleItemId === pvModule.itemId);
  if (!pvModule) pvModule = mockPvPanelFromLockedQuote(quote);
  const systemCapacityWatts = !instantQuoteMode ? designCalcs.systemCapacityWatts(design, pvModule)
    : instantQuoteProduction.systemSize * 1000;
  const { annualKwhProduction } = annualKwhProductionSelector({ energyData });
  const { partner } = account;
  const evmChargerConfigurations = _get(pvModule, 'evmChargerConfigurations', []);

  for (let i = financeTypes.length - 1; i >= 0; i--) {
    const financeType = financeTypes[i];
    const systemPrice = calculateSystemPrice(
      settings[financeType], systemCapacityWatts, financeType, selectedRacking,
      partner, finEngMinPrice, isCellularMonitoring, annualKwhProduction, finEng
    );
    const storageTotal = getStoragePrice(
      settings[financeType], quote, selectedStorage,
      partnerIsRvar, partnerIsNewHomes, settings.activeTab, selectedStorageWithExpansionPack
    );
    const evChargerTotal = getEvChargerPrice(settings[financeType], quote, partnerIsRvar, evmChargerConfigurations);
    const systemCost = calculateSystemPriceWithAdders(
      settings[financeType], systemCapacityWatts, financeType, selectedRacking,
      partner, finEngMinPrice, isCellularMonitoring, annualKwhProduction, finEng
    ) + storageTotal;
    let sumTotal = calculateSystemPriceWithAddersAndDiscounts(
      settings[financeType], systemCapacityWatts, financeType, selectedRacking,
      partner, finEngMinPrice, isCellularMonitoring, annualKwhProduction, finEng
    ) + storageTotal + evChargerTotal;

    if (isSpwrCash(financeType) && spwrTotalContractPrice !== null) {
      sumTotal = Math.ceil(spwrTotalContractPrice);
    }
    let monitoringCost = getMonitoringCost(financeType, isCellularMonitoring);
    if (financeType !== CASH_FINANCE_TYPE) {
      monitoringCost = 0;
    }
    const rackingFee = 0;// getRackingFee(systemCapacityWatts, financeType, selectedRacking);

    const initialPricePerWatt = systemPrice > monitoringCost &&
    settings[financeType].systemPriceSelection === 'pricePerWatt' ?
    (systemPrice / ((systemPrice - monitoringCost + rackingFee) / settings[financeType].pricePerWatt)).toFixed(2)
    : settings[financeType].pricePerWatt;

    let pricePerWattWithMonitoringCost = ((settings[financeType].pricePerWattWithMonitoringCost > 0
     && monitoringCost > 0) || settings[financeType].isChanging) ?
     settings[financeType].pricePerWattWithMonitoringCost : initialPricePerWatt;

    if (!pricePerWattWithMonitoringCost) pricePerWattWithMonitoringCost = 0;

    let grossPriceWithMonitoringCost = ((settings[financeType].grossPriceWithMonitoringCost > 0
     && monitoringCost > 0) || settings[financeType].isChanging) ?
     settings[financeType].grossPriceWithMonitoringCost :
     (settings[financeType].grossPrice ?
     (Number(settings[financeType].grossPrice) + monitoringCost - rackingFee).toFixed() : 0);

    if (!grossPriceWithMonitoringCost) grossPriceWithMonitoringCost = 0;

    newSettings[financeType] = {
      systemPrice,
      sumTotal,
      storageTotal,
      systemCost,
      pricePerWattWithMonitoringCost,
      grossPriceWithMonitoringCost,
      monitoringCost,
      rackingFee,
      evChargerTotal
    };
  }

  const pricePerKwhSelectedForLease = _get(settings, 'lease.systemPriceSelection') === 'pricePerKwh';
  const storageTotalForLease = _get(newSettings, 'lease.storageTotal', 0);

  const pricePerKwhSelectedForPpa = _get(settings, 'ppa.systemPriceSelection') === 'pricePerKwh';
  const storageTotalForPpa = _get(newSettings, 'ppa.storageTotal', 0);

  // storageTotal is inclusive of storageDealerFee
  const needsFinEngForLease = _get(settings, 'lease.needsFinEng');
  const needsFinEngForPpa = _get(settings, 'ppa.needsFinEng');

  const finEngData = finEng;
  const finEngHasLease = !isEmpty(finEngData) && finEngData.lease;
  const finEngHasPpa = !isEmpty(finEngData) && finEngData.ppa;

  if (pricePerKwhSelectedForLease && finEngHasLease && !needsFinEngForLease) {
    const { notifications } = notificationsSelector(state);
    const finEngSystemPriceForLease = finEngData.lease.systemPrice;
    const finEngDealerFeeForLease = finEngData.lease.dealerFee;

    const systemPriceWithoutAddersAndDiscountForLease = finEngSystemPriceForLease -
      Number(settings.lease.fetchingFinEngAdders || 0) -
      Number(notifications.totalCost || 0) +
      Number(settings.lease.fetchingFinEngDiscount || 0);
    let finEngDealerFeeWithoutAddersAndDiscountForLease = finEngDealerFeeForLease -
      Number(settings.lease.fetchingFinEngAdders || 0) -
      Number(notifications.totalCost || 0) +
      Number(settings.lease.fetchingFinEngDiscount || 0);

    if (quote.rackingType !== 'Invisimount' && partnerIsRvar) {
      finEngDealerFeeWithoutAddersAndDiscountForLease -=
        (RACKING_DEALER_FEE_PER_WATT * systemCapacityWatts);
    }

    newSettings.lease.grossPrice = round(systemPriceWithoutAddersAndDiscountForLease, 0);
    // sumTotal is equal to storage price and system price from FinEng
    // system price is inclusive of adders, discount, storage dealer fee but not storage price
    // so here we add storage price
    newSettings.lease.sumTotal = round(finEngSystemPriceForLease + storageTotalForLease, 3);
    newSettings.lease.pricePerWatt = round(systemPriceWithoutAddersAndDiscountForLease / systemCapacityWatts, 2);
    newSettings.lease.dealerFeePerWatt =
      round(finEngDealerFeeWithoutAddersAndDiscountForLease / systemCapacityWatts, 2);
  }

  if (pricePerKwhSelectedForPpa && finEngHasPpa && !needsFinEngForPpa) {
    const { notifications } = notificationsSelector(state);
    const finEngSystemPriceForPpa = finEngData.ppa.systemPrice;
    const finEngDealerFeeForPpa = finEngData.ppa.dealerFee;

    const systemPriceWithoutAddersAndDiscountForPpa = finEngSystemPriceForPpa -
      Number(settings.ppa.fetchingFinEngAdders || 0) -
      Number(notifications.totalCost || 0) +
      Number(settings.ppa.fetchingFinEngDiscount || 0);
    let finEngDealerFeeWithoutAddersAndDiscountForPpa = finEngDealerFeeForPpa -
      Number(settings.ppa.fetchingFinEngAdders || 0) -
      Number(notifications.totalCost || 0) +
      Number(settings.ppa.fetchingFinEngDiscount || 0);

    if (quote.rackingType !== 'Invisimount' && partnerIsRvar) {
      finEngDealerFeeWithoutAddersAndDiscountForPpa -=
        (RACKING_DEALER_FEE_PER_WATT * systemCapacityWatts);
    }

    newSettings.ppa.grossPrice = round(systemPriceWithoutAddersAndDiscountForPpa, 0);
    // sumTotal is equal to storage price and system price from FinEng
    // system price is inclusive of adders, discount, storage dealer fee but not storage price
    // so here we add storage price
    newSettings.ppa.sumTotal = round(finEngSystemPriceForPpa + storageTotalForPpa, 3);
    newSettings.ppa.pricePerWatt = round(systemPriceWithoutAddersAndDiscountForPpa / systemCapacityWatts, 2);
    newSettings.ppa.dealerFeePerWatt = round(finEngDealerFeeWithoutAddersAndDiscountForPpa / systemCapacityWatts, 2);
  }

  return {
    settings: newSettings
  };
}

export default createSelector(
  get('settings'),
  totalPriceValues
);
