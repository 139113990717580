import React from 'react';
import PropTypes from 'prop-types';
import NumberFormat from 'react-number-format';
import Modal from './Modal';
import { register } from '../utils/redux';
import { settingsType, quoteType, creditType } from '../types';

export function EditMortgageModal({
  settings,
  quote,
  updateSettings,
  saveSettings
}) {
  const selectedLoanTerm = settings.cash.loanTerm || quote.finEng.mortgage.loanTerm;
  const selectedLoanInterestRate = settings.cash.loanInterestRate || quote.finEng.mortgage.loanInterestRate;
  const selectedDownPaymentRate = settings.cash.downPaymentRate || quote.finEng.mortgage.downPayment;
  const loanTerms = [ 10, 15, 20, 25, 30 ];

  const onValueChangeHandler = (financeType, key, event) => {
    const newValue = (event.floatValue / 100).toFixed(4);
    updateSettings(financeType, key, newValue);
  };

  return (
    <Modal
      name="edit-mortgage"
      onSave={() => {
        saveSettings(quote, settings, {}, 'mortgage');
      }}
      showButtonsTop={false}
    >
      <div className="form-group down-payment form-group-percentage">
        <label>Down Payment</label>
        <div className="input-container">
          <NumberFormat
            label="Down Payment"
            decimalScale={2}
            disabled={quote.locked}
            value={selectedDownPaymentRate * 100}
            onValueChange={(e) =>
              onValueChangeHandler('cash', 'downPaymentRate', e)
            }
          />
        </div>
        <div className="pc">%</div>
      </div>

      <div className="form-group term">
        <label>Loan Term</label>

        <div className="input-container">
          <select
            name="term"
            value={selectedLoanTerm}
            disabled={quote.locked}
            onChange={(e) =>
              updateSettings('cash', 'loanTerm', parseInt(e.target.value, 10))
            }
          >
            {loanTerms.map((term, key) => (
              <option key={key} value={term}>
                {term}
              </option>
            ))}
          </select>
        </div>
      </div>

      <div className="form-group loan-rate form-group-percentage">
        <label>Loan Rate</label>
        <div className="input-container">
          <NumberFormat
            label="Loan Rate"
            decimalScale={2}
            disabled={quote.locked}
            value={selectedLoanInterestRate * 100}
            onValueChange={(e) =>
              onValueChangeHandler('cash', 'loanInterestRate', e)
            }
          />
        </div>
        <div className="pc">%</div>
      </div>
    </Modal>
  );
}

EditMortgageModal.propTypes = {
  settings: settingsType.isRequired,
  quote: quoteType.isRequired,
  credit: creditType.isRequired,
  updateSettings: PropTypes.func.isRequired,
  saveSettings: PropTypes.func.isRequired,
  toggleMode: PropTypes.func.isRequired
};

export default register(
  [ 'settingsSelector', 'quoteSelector', 'creditSelector' ],
  [ 'updateSettings', 'saveSettings', 'toggleMode' ],
  EditMortgageModal
);
