import React from 'react';

import { register } from '../utils/redux';
import { dynamicProposalEnabled } from '../utils/backendFeatureFlags';

import DynamicProposalPage from './dynamic_proposal_page/DynamicProposalPage';
import ProposalPage from './proposal_page/ProposalPage';


const ProposalContainer = ({ partnerIsNewHomes, showDynamicProposal, quote }) => {
  const renderBody = () => {
    if (dynamicProposalEnabled() && quote.version >= 7) {
      if (partnerIsNewHomes) return (<ProposalPage />);
      return (<DynamicProposalPage />);
    }
    return (<ProposalPage />);
  };

  return (renderBody());
};

export default register(
  [ 'partnerIsNewHomesSelector', 'showDynamicProposal', 'quoteSelector' ],
  [ ],
  ProposalContainer
);
