import React from 'react';
import { roofType } from '../types';
import svgPath from '../utils/svgPath';

const colors = [
  '#d6eaf8', '#a9cce3', '#5dade2', '#5499c7', '#ebedef', '#d6dbdf', '#aeb6bf',
  '#85929e', '#48c9b0', '#52be80'
];

export default function Building({ roof: { roofPlanes } }) {
  return (
    <g className="building">
      {roofPlanes.map((plane, index) =>
        <path
          key={index}
          className="roof-plane"
          d={svgPath(plane)} fill={colors[index % colors.length]}
        />
      )}
    </g>
  );
}

Building.propTypes = {
  roof: roofType.isRequired
};
