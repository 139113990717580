import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Document, Page } from 'react-pdf';
import { range } from 'lodash';

export default class PdfViewer extends Component {
  constructor() {
    super();
    this.state = { numPages: null, width: window.innerWidth };
  }

  componentDidMount = () => {
    window.addEventListener('resize', this.updateDimensions);
  }

  componentWillUnmount = () => {
    window.removeEventListener('resize', this.updateDimensions);
  }

  onDocumentLoad = ({ numPages }) => {
    this.setState({ numPages });
  };

  updateDimensions = () => {
    this.setState({ width: window.innerWidth });
  }

  renderPages = (numPages, width) => {
    // in case we don't know yet how many pages to render (numPage === null),
    // just print the first page.
    const pagesToRender = numPages || 1;
    return range(pagesToRender).map((id) => {
      return <Page pageNumber={id + 1} key={`pdfPage${id}`} width={width} />;
    });
  };

  render() {
    const { url } = this.props;

    return (
      <div className="pdf-viewer">
        { url && (
          <div className="pdf-content">
            <Document
              file={url}
              onLoadSuccess={this.onDocumentLoad}
            >
              {this.renderPages(this.state.numPages, this.state.width)}
            </Document>
          </div>
        )}
      </div>
    );
  }
}
PdfViewer.propTypes = {
  showViewer: PropTypes.func,
  url: PropTypes.string
};
