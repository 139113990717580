import { cloneDeep } from 'lodash';
import { findApFinConfigIndexById, findApFinConfigs, getStates } from '../../utils/admin_portal/updateHelpers';
import constants from '../../utils/EddieCalculations/constants';
/**
 *
 * @param {string} configId
 * @param {string} state
 * @param {object} val
 * @returns updated state
 */
/* eslint no-underscore-dangle: 0 */
/* eslint-disable no-unused-expressions */

const { CRITTER_GUARD, SKIRT_GUARD } = constants;

const getProductsforSates = (states, storageProducts, showAdders) => {
  const products = showAdders ? [ {
    product: CRITTER_GUARD,
    commissionPerUnit: 0,
    sell: true,
    productDescription: CRITTER_GUARD
  }, {
    product: SKIRT_GUARD,
    commissionPerUnit: 0,
    sell: true,
    productDescription: SKIRT_GUARD
  } ] : [];
  storageProducts && states.forEach((state) => {
    if (storageProducts[state]) {
      storageProducts[state].forEach((product) => {
        const config = {
          product: product.storageId,
          commissionPerUnit: 0,
          sell: true,
          productDescription: product.storageName
        };
        products.push(config);
      });
    }
  });
  return products;
};
/* eslint-enable no-unused-expressions */

export const updateStateSelection = (configId, financeType, state, val, storageProductConfig, showAdders) =>
(dispatch, getState) => {
  const { LEASE_FINANCE_TYPE, PPA_FINANCE_TYPE } = constants;
  const currentState = getState();
  const apFinConfigsToUpdate = cloneDeep(currentState.apFinConfigs);
  const finConfigIndex = findApFinConfigIndexById(apFinConfigsToUpdate, configId);
  const newId = `new-${financeType}`;
  let newFinConfig = apFinConfigsToUpdate.find((config) => config.id === newId &&
      config.finType.toLowerCase() === financeType.toLowerCase());
  if (!newFinConfig) {
    const newIndex = apFinConfigsToUpdate.length;
    apFinConfigsToUpdate[newIndex] = cloneDeep(apFinConfigsToUpdate[finConfigIndex]);
    newFinConfig = apFinConfigsToUpdate[newIndex];
    newFinConfig.id = `new-${financeType}`;
  }
  const stateIndex = newFinConfig.applicableStatesAttributes
    .map((config) => config.state).indexOf(state);

  if (stateIndex > -1) {
    if (val) {
      if (newFinConfig.applicableStatesAttributes[stateIndex].id) {
        newFinConfig.applicableStatesAttributes[stateIndex]._destroy = false;
      }
    } else if (newFinConfig.applicableStatesAttributes[stateIndex].id) {
      newFinConfig.applicableStatesAttributes[stateIndex]._destroy = true;
    } else {
      newFinConfig.applicableStatesAttributes.splice(stateIndex, 1);
    }
  } else {
    newFinConfig.applicableStatesAttributes = [
      ...newFinConfig.applicableStatesAttributes,
      {
        state
      }
    ];
  }

  const finConfigs = findApFinConfigs(apFinConfigsToUpdate, financeType).filter((config) => config.id !== newId);
  const selectedStates = getStates(newFinConfig);
  let configNotFound = true;
  if (selectedStates.length) {
    finConfigs.forEach((config) => {
      const finStates = getStates(config);
      const configAvailable = selectedStates.every((state) => finStates.includes(state)) && selectedStates;
      if (configAvailable) {
        const configClone = cloneDeep(config);
        configNotFound = false;
        newFinConfig.show = configClone.show;
        newFinConfig.default = configClone.default;
        newFinConfig.expiredProducts = configClone.expiredProducts;
        newFinConfig.pricingDefaultsAttributes = configClone.pricingDefaultsAttributes;
        newFinConfig.moduleConfigsAttributes = configClone.moduleConfigsAttributes;
        newFinConfig.defaultModuleAttributes = configClone.defaultModuleAttributes;
        newFinConfig.defaultLoanProductAttributes = configClone.defaultLoanProductAttributes;
        newFinConfig.productConfigsAttributes = configClone.productConfigsAttributes;
        newFinConfig.paymentConfigAttributes = configClone.paymentConfigAttributes;
        newFinConfig.installDateAttributes = configClone.installDateAttributes;
        newFinConfig.unselectedModuleSeriesAttributes = configClone.unselectedModuleSeriesAttributes;
        newFinConfig.selectSingleState = configNotFound;
      }
    });
  }
  if (configNotFound) {
    newFinConfig.pricingDefaultsAttributes = [];
    newFinConfig.moduleConfigsAttributes = [];
    newFinConfig.defaultModuleAttributes = null;
    newFinConfig.defaultLoanProductAttributes = null;
    newFinConfig.productConfigsAttributes = getProductsforSates(selectedStates, storageProductConfig, showAdders);
    if ([ LEASE_FINANCE_TYPE, PPA_FINANCE_TYPE ].includes(newFinConfig.finType)) {
      newFinConfig.paymentConfigAttributes = {
        downPayment: null,
        paymentEscalator: 2.9,
        fullPrepayment: null
      };
    } else {
      newFinConfig.paymentConfigAttributes = null;
    }
    newFinConfig.installDateAttributes =
    {
      installDays: 60,
      optedIn: false
    };
    newFinConfig.selectSingleState = configNotFound;
  }

  dispatch({
    type: 'updateStateSelection',
    payload: {
      apFinConfigs: apFinConfigsToUpdate
    }
  });
};
