import { findSelectedStates } from '../../utils/admin_portal/updateHelpers';

export default (state) => {
  const {
    apFinConfigs,
    selectedFinanceOption,
    prepaymentAllowedStates
  } = state;
  let apIsFullPrepaymentAllowed = true;

  if (selectedFinanceOption && apFinConfigs && prepaymentAllowedStates) {
    const selectedStates = findSelectedStates(apFinConfigs, selectedFinanceOption);

    if (selectedStates.length === 1 && selectedStates[0]) {
      const selectedState = selectedStates[0].toUpperCase();
      apIsFullPrepaymentAllowed = prepaymentAllowedStates.indexOf(selectedState) > -1;
    } else {
      apIsFullPrepaymentAllowed = false;
    }
  }
  return {
    apIsFullPrepaymentAllowed
  };
};
