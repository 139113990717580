import React from 'react';
import PropTypes from 'prop-types';
import { routerShape } from 'react-router';
import cx from 'classnames';
import { gaTrackEvent } from '../utils/googleAnalytics';
import Link from './Link';

export default function NavLink({ to, name, router, disabled = false }) {
  return (
    <div className="nav-items">
      <div className={cx('nav-item', { active: router.isActive(to) })}>
        <div
          className={cx('nav-item-title', { opened: router.isActive(to) })}
        >
          <div>
            {disabled ? (
              <a className={cx({ disabled })}>
                {name}
              </a>
            ) : (
              <Link
                onClick={() => {
                  gaTrackEvent(`quotePage:navBar:tab:${name}`);
                }}
                to={to}
              >
                {name}
              </Link>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
NavLink.propTypes = {
  router: routerShape.isRequired,
  to: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  disabled: PropTypes.bool
};
