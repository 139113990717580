export function closeAddStorageModal() {
  return {
    type: 'closeAddStorageModal',
    payload: {
      design: {
        isAddStorageModalOpen: false
      },
      isAddStorageModalOpen: {
      }
    }
  };
}
