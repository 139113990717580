import { findSelectedStates } from '../../utils/admin_portal/updateHelpers';

export default (state) => {
  const { selectedFinanceOption, financialOfferings, allAvailableStates, apFinConfigs } = state;
  let availableModuleSeries = [];

  if (selectedFinanceOption && financialOfferings && allAvailableStates) {
    const selectedStates = findSelectedStates(apFinConfigs, selectedFinanceOption);

    if (selectedStates.length > 0) {
      let moduleSeries = [];
      const stateArray = financialOfferings[selectedFinanceOption];
      selectedStates.forEach((selectedState) => {
        let seriesArray = stateArray[selectedState].module.map((details) => { return details.series; });
        seriesArray = seriesArray.filter((series) => series !== null);
        moduleSeries = [ ...moduleSeries, ...seriesArray ];
      });
      availableModuleSeries = Array.from(new Set(moduleSeries));
    }
  }
  return {
    availableModuleSeries
  };
};
