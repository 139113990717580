import PropTypes from 'prop-types';
import { pvModuleType } from '../types';

export const quoteType = PropTypes.shape({
  sfid: PropTypes.string.isRequired,
  name: PropTypes.string,
  roofDesignId: PropTypes.number.isRequired,
  locked: PropTypes.bool.isRequired,
  pvModuleItemId: PropTypes.string.isRequired,
  lockedPvModuleItem: pvModuleType,
  dirty: PropTypes.bool.isRequired,
  shadingMeasurementDate: PropTypes.string,
  lockedAnnualUsage: PropTypes.number,
  publicAccessToken: PropTypes.string,
  patExpiresAt: PropTypes.string,
  manualMode: PropTypes.bool
});
