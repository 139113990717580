import React from 'react';
import PropTypes from 'prop-types';
import { register } from '../utils/redux';
import { quoteType } from '../types';

export function FeedbackModalBar({ toggleModal, closeFeedbackModalBar }) {
  return (
    <div className="feedback-label">
      <a onClick={() => toggleModal('feedback', null)} className="feedback-show">
        <div className="header">
          <div className="thought-bubble" />
          <div>
            <label>Rate your experience.</label> We value your feedback...
          </div>
        </div>
        <div className="right-arrow-icon" />
      </a>
      <div className="buttons">
        <a onClick={() => closeFeedbackModalBar()}>
          <div className="close-button" />
        </a>
      </div>
    </div>
  );
}

FeedbackModalBar.propTypes = {
  toggleModal: PropTypes.func.isRequired,
  closeFeedbackModalBar: PropTypes.func.isRequired,
  quote: quoteType.isRequired
};

export default register(
  [],
  [],
  FeedbackModalBar
);
