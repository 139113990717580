import { saveAs } from 'file-saver';
import Papa from 'papaparse';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { pvModuleType } from '../../../types';
import {
  cleanFile,
  generateCSV,
  parseFile,
  validateFile
} from '../../../utils/uploaderHelper';
import { DownloadBar } from './DownloadBar';
import { DownloadListModal } from './DownloadListModal';
import { EmptyFile } from './EmptyFile';
import { FileUploaded } from './FileUploaded';
import { HelpGuideBtn } from './HelpGuideBtn';
import { HelpGuideModal } from './HelpGuideModal';
import { PreviewModal } from './PreviewModal';
import { ResetBar } from './ResetBar';
import { UploadModal } from './UploadModal';

export function PricingUploader({
  pricingControls,
  uploadPricingFile,
  updateAdminPortal,
  deletePricingFile,
  allAvailableStates,
  utilities,
  financialOfferings
}) {
  const [ file, setFile ] = useState(null);
  const [ showLoading, setLoading ] = useState(false);
  const [ showModal, setModal ] = useState(false);
  const [ showReset, setReset ] = useState(false);
  const [ showPreview, setPreview ] = useState(false);
  const [ showDownloadList, setDownloadList ] = useState(false);
  const [ showSuccess, setSuccess ] = useState(false);
  const [ showHelpText, setHelpText ] = useState(false);
  const [ error, setError ] = useState({});
  const [ rows, setRows ] = useState([]);
  const UTILITY_LIST = utilities || [];
  const AVAILABLE_STATES = [];
  Object.keys(allAvailableStates).map((financial) => {
    allAvailableStates[financial].map((state) => {
      const abbreviation = state.abbreviation.toLowerCase();
      if (!AVAILABLE_STATES.includes(abbreviation)) {
        AVAILABLE_STATES.push(abbreviation);
        return abbreviation;
      }
      return null;
    });
    return financial;
  });

  useEffect(() => {
    if (!file && pricingControls) {
      Papa.parse(pricingControls.fileUrl, {
        download: true,
        header: true,
        complete: (csv) => {
          setRows(cleanFile(csv.data));
          const csvFile = generateCSV(csv.data, pricingControls.fileName);
          setFile(csvFile);
        }
      });
    }
  }, [ pricingControls, file ]);

  const onError = (title, message, errors = []) => {
    setLoading(false);
    setReset(false);
    setSuccess(false);
    setError({
      title,
      message,
      errors
    });
  };

  const onDelete = async () => {
    const afterDelete = () => {
      setModal(false);
      setReset(false);
      setLoading(false);
      setSuccess(false);
      setError({});
      setFile('');
    };
    deletePricingFile(afterDelete);
  };

  const onSuccess = (rawFile, parsed) => {
    setLoading(false);
    setReset(false);
    setSuccess(true);
    setFile(rawFile);
    setRows(parsed);
    setError({});
  };

  const openModal = () => {
    setModal(true);
    setLoading(true);
    setPreview(false);
    setSuccess(false);
    setReset(false);
    setError({});
  };

  const afterUpload = (rawFile, parsed, response) => {
    if (response.status === 'FAILED') {
      onError(
        'File upload failed.',
        'Please retry the file upload.',
        response.backendStack
      );
    } else {
      onSuccess(rawFile, parsed);
    }
  };

  const upload = async (event) => {
    event.preventDefault();
    openModal();
    const rawFile = event.target.files[0];
    if (rawFile) {
      const maximumSize = 500 * 1024 * 1024;
      if (rawFile.size > maximumSize) {
        onError('File size limit exceed (500Mb).', 'Please check and upload a new file.');
      } else {
        const parsed = await parseFile(rawFile);
        if (parsed && parsed.length > 0) {
          const fileErrors = validateFile(parsed, financialOfferings, AVAILABLE_STATES, UTILITY_LIST);
          if (!fileErrors.length) {
            uploadPricingFile(rawFile, parsed, afterUpload);
          } else {
            onError('File format is invalid.', 'Please check and upload a new file.', fileErrors);
          }
        } else {
          onError('File is empty.', 'Please upload a new file.');
        }
      }
    }
  };

  const downloadOriginal = () => {
    saveAs(file, pricingControls.fileName);
  };

  const onReset = () => {
    setReset(true);
    setModal(true);
    setLoading(false);
    setSuccess(false);
    setError({});
  };

  return (
    <div id="file-uploader" className="admin-table-container">
      <div className="table-heading">
        <div className="header">
          <span>
            <b>Upload price default file</b>
            & EDDIE will reflect these prices
          </span>
          <DownloadBar
            setDownloadList={setDownloadList}
          />
        </div>
        <HelpGuideBtn setHelpText={setHelpText} />
      </div>
      <div className="admin-container">
        <div className="admin-section-data-container border-bottom">
          {pricingControls && file ?
            (
              <FileUploaded
                pricingControls={pricingControls}
                download={downloadOriginal}
                upload={upload}
                setPreview={setPreview}
                rows={rows}
              />
            ) :
            (<EmptyFile upload={upload} />)
          }
        </div>
      </div>
      {pricingControls && file && <ResetBar onReset={onReset} />}
      {/* MODALS */}
      {showHelpText && (
        <HelpGuideModal
          setHelpText={setHelpText}
          financialOfferings={financialOfferings}
          utilities={utilities}
        />
      )}
      {showDownloadList && (
        <DownloadListModal
          setDownloadList={setDownloadList}
          financialOfferings={financialOfferings}
          utilities={utilities}
        />
      )}
      {showPreview && (
        <PreviewModal
          rows={rows}
          upload={upload}
          download={downloadOriginal}
          close={() => setPreview(false)}
        />
      )}
      {showModal && (
        <UploadModal
          deletePricingFile={onDelete}
          updateAdminPortal={updateAdminPortal}
          showLoading={showLoading}
          showReset={showReset}
          setReset={setReset}
          setModal={setModal}
          showSuccess={showSuccess}
          setSuccess={setSuccess}
          upload={upload}
          error={error}
          rows={rows}
          setError={setError}
          setPreview={setPreview}
        />
      )}
    </div>
  );
}

PricingUploader.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  pricingControls: PropTypes.object,
  uploadPricingFile: PropTypes.func,
  updateAdminPortal: PropTypes.func,
  deletePricingFile: PropTypes.func,
  allAvailableStates: PropTypes.arrayOf(PropTypes.object),
  utilities: PropTypes.arrayOf(PropTypes.object),
  financialOfferings: PropTypes.arrayOf(pvModuleType)
};
