import d3 from 'd3';
import last from 'lodash/last';
import compact from 'lodash/compact';
import sortBy from 'lodash/sortBy';

const ceilTo = (v, magnitude = 2) => Math.ceil(v / (10 ** magnitude)) * (10 ** magnitude);

const calculateRoundMagnitude = (v) => Math.min(
  Math.floor(Math.log10(v)) - 1,
  2
);

// Find max of series or 0.00001 if max <= 0.00001.
const getSeriesMaxOrNegligible = (values) => {
  const definedValues = compact(values);
  const valueOrNegligibles = definedValues.map((value) => Math.max(0.00001, value));
  return Math.max(...valueOrNegligibles);
};

/**
 * Get the absolute maximum of all seriesValues and the smallest series
 * maximum above the lowestRatio threshold.
 * @param {number[][]} seriesValues
 * @param {number} lowestRatio Ratio (relative to absolute maximum) below
 * which we ignore values and do not break.
 * @return {number[]} absolute maximum, least maximum.
 */
export const getMaxLeastMax = (seriesValues, lowestRatio = 0.05) => {
  let maxima = seriesValues.map(getSeriesMaxOrNegligible);
  if (maxima.length === 0) {
    throw new Error('No seriesValues provided');
  }
  maxima = sortBy(maxima);
  const mostMax = last(maxima);
  let leastIndex = 0;
  let leastMax = maxima[leastIndex];
  while (leastMax / mostMax < lowestRatio) {
    leastIndex += 1;
    if (leastIndex < maxima.length) {
      leastMax = maxima[leastIndex];
    } else {
      leastMax = mostMax;
    }
  }

  return [ mostMax, leastMax ];
};

/**
 * @callback scale https://github.com/d3/d3/blob/master/API.md#scales-d3-scale
 * @param {number} input Raw input value.
 * @return {number} Scaled input value.
 */

/**
 * @typedef {Object} ScaledTicks Data for creating broken axes.
 * @property {scale} scale
 * @property {number[]} ticks Scaled tickmark values.
 * @property {number} max The unscaled max tick mark value.
 * @property {number} breakValue The unscaled break value.
 */

/**
 * makeScaledTicks will take two arrays of values. It will return data for creating
 * an axis with two separate scales.
 * @param {number[][]} seriesValues
 * @param {Object} opts
 * @param {Object} opts.nTickMarks Approximate number of tickmarks to generate.
 * (See https://github.com/d3/d3-scale#continuous_ticks).
 * @param {Object} opts.maxBrokenHeighRatio Max allowable ratio of the max breakable value
 * to max unbreakable value before scale is broken.
 * @returns {ScaledTicks}
 */
export default (seriesValues, { nTickMarks = 5, maxBrokenHeightRatio = 1.25, visualBreakRatio = 1.25 } = {}) => {
  const [ max, leastMax ] = getMaxLeastMax(seriesValues);

  const maxMagnitude = calculateRoundMagnitude(max);
  const ceiledMax = ceilTo(max, maxMagnitude);
  const leastMaxMagnitude = calculateRoundMagnitude(leastMax);
  const ceiledLeastMax = ceilTo(leastMax, leastMaxMagnitude);

  if (max / leastMax <= maxBrokenHeightRatio) {
    // No need to break.
    const scale = d3.scale.linear()
      .domain([ 0, ceiledMax ])
      .range([ 0, ceiledMax ]);
    const ticks = scale.ticks(nTickMarks);
    return { scale, ticks, max };
  }

  const scaleDomain = [ 0, ceiledLeastMax, ceiledLeastMax, ceiledMax ];
  const scaleBreakValue = ceilTo(
    ceiledMax / visualBreakRatio, maxMagnitude
  );
  const scaleRange = [ 0, scaleBreakValue, scaleBreakValue, ceiledMax ];
  const scale = d3.scale.linear().domain(scaleDomain).range(scaleRange);
  let ticks = d3.scale.linear()
    .domain([ 0, ceiledLeastMax ])
    .ticks(nTickMarks);
  if (last(ticks) < ceiledLeastMax) {
    ticks.pop();
    ticks.push(ceiledLeastMax);
  }
  ticks = ticks.map(scale);
  ticks.push(ceiledMax);
  return { scale, ticks, max: ceiledMax, breakValue: ceiledLeastMax };
};
