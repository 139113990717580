import { asyncPayload } from './apiHelpers';
import Api from '../utils/Api';

export function savePvModule(quote, pvModuleItemId) {
  return {
    type: 'savePvModule',
    payload: asyncPayload({
      request: Api.update(`quotes/${quote.sfid}/select_pv_module`, {
        quote: { pvModuleItemId }
      }).then((data) => {
        return data;
      })
    })
  };
}
