import React from 'react';
import PropTypes from 'prop-types';
import { register } from '../../../utils/redux';

export function CustomCheckBox({ label, value, isChecked, callBack }) {
  return (
    <label className="option-container">
      {label}
      <input
        type="checkbox"
        value={value}
        checked={isChecked}
        onChange={(e) => callBack(e.target.checked, value)}
      />
      <span className="checkmark" />
    </label>
  );
}

CustomCheckBox.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  isChecked: PropTypes.bool.isRequired,
  callBack: PropTypes.func
};

export default register(
  [],
  [],
  CustomCheckBox
);
