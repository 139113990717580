import get from 'lodash/get';
import { asyncPayload } from './apiHelpers';
import Api from '../utils/Api';
import visualizationToImage from '../utils/visualizationToImage';
import { datadogTrackLoadingTime } from '../utils/datadogUserMonitoring';
import { fetchGraphs } from './fetchGraphs';

export function updateRoofDesign(
  quoteSfid,
  design,
  settings,
  adders,
  quote,
  instantQuoteMode,
  productConfiguration,
  channel,
  moduleChanged,
  storageChanged
) {
  const startTime = Date.now();

  const getSaveDesignCallPromise = (data) => {
    let path;

    if (instantQuoteMode) {
      path = `instant_quote/quotes/${quoteSfid}`;
      return Api.updateWithPolling(path, { ...data, design, settings, adders, quote, moduleChanged });
    }
    const timeoutTimeInMin = 3;
    const afterSuccPath = `quotes/${quoteSfid}/quote_details`;

    path = `quotes/${quoteSfid}`;
    return Api.updateWithPollingAndTimeout(
      path,
      afterSuccPath,
      { ...data, design, settings, adders, quote, moduleChanged: true, storageChanged },
      timeoutTimeInMin
    );
  };

  const promiseWithSystemDesignImg = visualizationToImage()
    .then((dataUri) => {
      return getSaveDesignCallPromise({ systemDesignEncodedPng: dataUri });
    });

  return (dispatch) => {
    const response = dispatch({
      type: 'updateRoofDesign',
      payload: asyncPayload({
        request: promiseWithSystemDesignImg,
        afterRequest: {
          design: {
            hasChanges: false
          },
          dirtySettings: false,
          moduleChanged: false
        }
      })
    });

    response.then((data) => {
      if (!get(data, 'value.error', null)) {
        dispatch(fetchGraphs(quoteSfid));

        const loadingTime = Date.now() - startTime;

        // tracking only successful loading time for manual and dynamic
        if (!instantQuoteMode && !get(data, 'value.quote.dirty', true)) {
          datadogTrackLoadingTime(
            'Save Design',
            loadingTime,
            quote,
            settings,
            channel,
            productConfiguration
          );
        }
      }
      return data;
    });
  };
}
