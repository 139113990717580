import PropTypes from 'prop-types';

export const notificationsType = PropTypes.shape({
  active: PropTypes.bool,
  totalCost: PropTypes.number,
  notifications: PropTypes.arrayOf(PropTypes.shape({
    cost: PropTypes.number.isRequired,
    notification: PropTypes.shape({
      header: PropTypes.string,
      body: PropTypes.string
    })
  }))
});
