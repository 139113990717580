import React from 'react';
import PropTypes from 'prop-types';
import RoofPreviewVisualization from '../dynamic_design/RoofPreviewVisualization';
import DesignMap from '../dynamic_design/DesignMap';
import { accountType, quoteType } from '../../types';
import { gaTrackEvent } from '../../utils/googleAnalytics';
import { register } from '../../utils/redux';

import {
  ROOF_PREVIEW_VISUALIZATION, ROOF_PREVIEW_MAP
} from '../../config';

const mapEventNameForTracking = (mode) => {
  switch (mode) {
    case ROOF_PREVIEW_VISUALIZATION:
      return 'quotePage:designTab:roofPreviewVisualization';
    case ROOF_PREVIEW_MAP:
      return 'quotePage:designTab:roofPreviewMap';
    default:
      return null;
  }
};

const generateOnClickHandler = (toggleMode, currentMode, targetMode, setRoofPreviewMode) => (e) => {
    // Ensure RoofPreviewVisualization and DesignMap do not recive when they are in toggle mode.
  e.stopPropagation();
  toggleMode(null);

  if (currentMode === targetMode) return;

  const eventName = mapEventNameForTracking(targetMode);
  gaTrackEvent(eventName);

  setRoofPreviewMode(targetMode);
};

export const ToggleRoofPreviewMode = ({
  toggleMode, roofPreviewMode, setRoofPreviewMode, account, interactive,
  quote
}) => {
  const isRoofVisualizationActive = roofPreviewMode === null || roofPreviewMode === ROOF_PREVIEW_VISUALIZATION;

  return (
    <div className="toggle_preview_mode">
      {isRoofVisualizationActive ? (
        <div className="roof_preview_toggle_container">
          <div
            className="roof_preview_toggle"
            onClick={generateOnClickHandler(toggleMode, roofPreviewMode, ROOF_PREVIEW_MAP, setRoofPreviewMode)}
          >&nbsp;</div>
          <DesignMap latitude={account.latitude} longitude={account.longitude} height={'125px'} />
        </div>
      ) : (
        <div className="roof_preview_toggle_container roof_visulalization">
          <div
            className="roof_preview_toggle"
            onClick={generateOnClickHandler(
              toggleMode,
              roofPreviewMode,
              ROOF_PREVIEW_VISUALIZATION,
              setRoofPreviewMode
            )}
          >&nbsp;</div>
          <RoofPreviewVisualization
            interactive={interactive}
            quote={quote}
          />
        </div>
      )}
    </div>
  );
};

ToggleRoofPreviewMode.propTypes = {
  toggleMode: PropTypes.func.isRequired,
  quote: quoteType,
  roofPreviewMode: PropTypes.oneOf([
    ROOF_PREVIEW_VISUALIZATION,
    ROOF_PREVIEW_MAP
  ]),
  setRoofPreviewMode: PropTypes.func.isRequired,
  account: accountType.isRequired,
  interactive: PropTypes.bool.isRequired
};


export default register(
  [ 'visualizationSelector', 'roofPreviewModeSelector', 'modeSelector', 'quoteSelector' ],
  [ 'setRoofPreviewMode', 'toggleMode' ],
  ToggleRoofPreviewMode
);
