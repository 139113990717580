import React from 'react';
import PropTypes from 'prop-types';
import { shallowEqual } from 'recompose';
import { compose } from 'redux';
import { connect } from 'react-redux';
import getErrors from './getErrors';
import { validationErrorsSelector } from '../../selectors';

export function withValidation(Component) {
  class FieldValidator extends React.Component {
    constructor(props, context) {
      super(props, context);

      this.context = context;
      this.name = props.name;
      this.validators = props.validators;
    }

    componentDidMount() {
      if (this.context.attachToValidatorContainer) {
        this.context.attachToValidatorContainer(this);
      }
    }

    componentDidUpdate(prevProps) {
      if (!shallowEqual(prevProps.value, this.props.value)) {
        this.context.validateField(this);
      }
    }

    componentWillUnmount() {
      if (this.context.detachFromValidatorContainer) {
        this.context.detachFromValidatorContainer(this);
      }
    }

    getValue() {
      return this.props.value;
    }

    validate() {
      return getErrors(this.validators, this.props.value);
    }

    render() {
      const { validationErrors } = this.props;
      const fieldErrors = validationErrors[this.name];
      const hasErrors = fieldErrors && fieldErrors.length > 0;
      const showErrors = this.props.showErrors || false;

      return Component({ ...this.props, showErrors, fieldErrors, hasErrors });
    }
  }

  FieldValidator.contextTypes = {
    validateField: PropTypes.func,
    validateAllFields: PropTypes.func,
    attachToValidatorContainer: PropTypes.func,
    detachFromValidatorContainer: PropTypes.func
  };

  FieldValidator.propTypes = {
    name: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([ PropTypes.string, PropTypes.number, PropTypes.bool ]).isRequired,
    validators: PropTypes.arrayOf(PropTypes.func).isRequired,
    validationErrors: PropTypes.objectOf(PropTypes.arrayOf(PropTypes.string)),
    showErrors: PropTypes.bool
  };

  return FieldValidator;
}

export default compose(
  connect((state) => validationErrorsSelector(state)),
  withValidation
);

// connect(withValidation(args))

// export default (Component) => connect((state) => validationErrorsSelector(state))(withValidation(Component))
