import React from 'react';
import PropTypes from 'prop-types';
import Modal from './Modal';
import { register } from '../utils/redux';

export function ValidatedModal({ name, onSave, toggleModal, ...restProps }, context) {
  const onSaveFn = (...args) => {
    if (context.isAllValid()) {
      onSave(...args);
      toggleModal(name);
    }

    return null;
  };

  return (
    <Modal {...restProps} name={name} onSave={onSaveFn} closeModalAfterOnSave={false} />
  );
}

ValidatedModal.propTypes = {
  name: PropTypes.string.isRequired,
  onSave: PropTypes.func.isRequired,
  toggleModal: PropTypes.func.isRequired
};

ValidatedModal.contextTypes = { isAllValid: PropTypes.func };

export default register(
  [],
  ['toggleModal'],
  ValidatedModal
);
