import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import ValidatedModal from '../ValidatedModal';
import { register } from '../../utils/redux';
import {
  designType,
  quoteType,
  manualModeRoofType,
  selectedInverterType,
  accountType,
  settingsType
} from '../../types';
import {
  FormGroup,
  Label,
  Select,
  TextInput,
  ValidationContainer,
  GroupValidator,
  GroupErrorMessages
} from '../forms';
import {
  required,
  isInteger,
  minMax,
  valuesNotTheSame,
  allRequired,
  allValuesBetween,
  minMaxFunction
} from '../../customValidators';
import { partnerIsNewHomesSelector } from '../../selectors';

export function RoofModal({
  addNewRoof,
  updateRoof,
  updateRoofMonthlySolarAccess,
  updateRoofData,
  updateRoofValidationErrors,
  design,
  roof,
  settings,
  dcModule,
  selectedInverters,
  validationErrors,
  account,
  quote
}) {
  const azimuthTooltip = 'Enter the direction, or azimuth of this roof, in degrees (º)<br /> ' +
    'as a number where 0 is north, 90 is east, 180 is south, 270 is west.';
  const onSave = () => {
    if (roof.id) {
      updateRoof(roof, design.id, quote, settings);
    } else {
      addNewRoof(roof, design.id, quote, settings);
    }
  };

  // We can't use isInt from validator, because it doesn't work for integers
  // (it works only for strings), and Monthly Solar Access values are Integers
  const maxModuleCount = partnerIsNewHomesSelector({ account }).partnerIsNewHomes ? 100 : 200;
  const isInt = (value) => value === '' || !isNaN(parseInt(value, 10));
  const toInt = (value) => value === '' ? '' : parseInt(value, 10);
  const monthlySolarAccessInput = (label, id) => {
    const name = `monthly-solar-access-${id}`;
    const hasErrors = validationErrors[name] && validationErrors[name].length > 0;
    const classes = cx('row month-solar-access', { 'has-error': hasErrors });

    return (
      <div className={classes}>
        <Label className="col-md-12">{label}</Label>
        <TextInput
          name={`monthly-solar-access-${id}`}
          validators={[ required, minMax ]}
          value={roof.monthlySolarAccess[id]}
          disabled={quote.locked}
          onFocus={(e) => e.target.select()}
          onChange={(e) => isInt(e.target.value) && updateRoofMonthlySolarAccess(id, toInt(e.target.value))}
        />
      </div>
    );
  };

  const inverterName = (selectedInverter) => {
    return `${selectedInverter.name} - ${selectedInverter.inverterName}`;
  };

  let title;
  if (quote.locked) {
    title = 'Roof Preview';
  } else if (roof.id) {
    title = 'Edit Roof';
  } else {
    title = 'New Roof';
  }

  return (
    <ValidationContainer storeUpdaterAction={updateRoofValidationErrors}>
      <ValidatedModal
        name="new-roof"
        onSave={onSave}
        closeModalAfterOnSave={false}
        showButtonsTop={!quote.locked}
        showButtons={!quote.locked}
        showCloseButtonOnly={quote.locked}
        title={title}
      >
        <hr />

        <GroupErrorMessages
          for="group-system-solar-access"
          title="Roof Solar Access Percentages"
        />

        <div className="row">
          <div className="col-md-6">
            <FormGroup inline={true} className="inverter-model-wrapper">
              <Label>Pitch</Label>
              <Select
                name="roof-form-pitch"
                value={roof.pitch}
                validators={[required]}
                showErrors={true}
                onChange={(e) => updateRoofData('pitch', e.target.value)}
                disabled={quote.locked}
              >
                {/* When changing the values, check also Local::ModuleArray#PITCH_OPTIONS */}
                <option value="0">Horiz. (0°)</option>
                <option value="5">1:12 (5°)</option>
                <option value="9">2:12 (9°)</option>
                <option value="14">3:12 (14°)</option>
                <option value="18">4:12 (18°)</option>
                <option value="23">5:12 (23°)</option>
                <option value="27">6:12 (27°)</option>
                <option value="30">7:12 (30°)</option>
                <option value="34">8:12 (34°)</option>
                <option value="37">9:12 (37°)</option>
                <option value="40">10:12 (40°)</option>
                <option value="43">11:12 (43°)</option>
                <option value="45">12:12 (45°)</option>
              </Select>
            </FormGroup>
          </div>

          <div className="col-md-6">
            <FormGroup inline={true} className="azimuth-wrapper">
              <Label data-tip={azimuthTooltip}>Azimuth (degrees)</Label>
              <TextInput
                name="roof-form-azimuth"
                validators={[ required, minMaxFunction(0, 360) ]}
                value={roof.azimuth}
                disabled={quote.locked}
                showErrors={true}
                onChange={(e) => isInt(e.target.value) && updateRoofData('azimuth', toInt(e.target.value))}
              />
            </FormGroup>
          </div>
        </div>

        <div className="row">
          {dcModule && (
          <div className="col-md-6">
            <FormGroup inline={true}>
              <Label>Parallel Strings</Label>
              <TextInput
                name="roof-form-panel-string-count"
                validators={[ isInteger, required, minMaxFunction(1, 100) ]}
                showErrors={true}
                value={roof.panelStringCount}
                disabled={quote.locked}
                onChange={(e) => isInt(e.target.value) && updateRoofData('panelStringCount', e.target.value)}
              />
            </FormGroup>
          </div>
          )}

          {dcModule && (
          <div className="col-md-6">
            <FormGroup inline={true}>
              <Label>Modules per String</Label>
              <TextInput
                name="roof-form-panel-per-string-count"
                validators={[ isInteger, required, minMaxFunction(1, 100) ]}
                showErrors={true}
                value={roof.panelPerStringCount}
                disabled={quote.locked}
                onChange={(e) => isInt(e.target.value) && updateRoofData('panelPerStringCount', e.target.value)}
              />
            </FormGroup>
          </div>
          )}
        </div>

        <div className="row">
          {dcModule && (
          <div className="col-md-12">
            <FormGroup inline={true}>
              <Label>Module Count</Label>
              <span style={{ paddingLeft: 0 }} className="col-md-12">{roof.panelCount}</span>
            </FormGroup>
          </div>
          ) || (
          <div className="col-md-6">
            <FormGroup inline={true}>
              <Label>Module Count</Label>
              <TextInput
                name="roof-form-panel-count"
                validators={[ isInteger, required, minMaxFunction(1, maxModuleCount) ]}
                showErrors={true}
                value={roof.panelCount}
                disabled={quote.locked}
                onChange={(e) => isInt(e.target.value) && updateRoofData('panelCount', e.target.value)}
              />
            </FormGroup>
          </div>
          )}
        </div>


        {dcModule && (
        <hr />
        )}

        {dcModule && (
        <div className="form-group row inverter-model-wrapper input-group">
          <label className="col-md-12">Inverter Model</label>
          <select
            id="roof-form-selected-inverter-id"
            value={roof.selectedInverterId}
            onChange={(e) => updateRoofData('selectedInverterId', e.target.value)}
            className="col-md-12"
            disabled={quote.locked}
          >
            { selectedInverters.map((selectedInverter) => {
              return (
                <option value={selectedInverter.id} key={selectedInverter.id}>
                  { inverterName(selectedInverter) }
                </option>
              );
            })
            }
          </select>
        </div>
        )}

        <hr />

        <GroupValidator
          name="group-system-solar-access"
          validators={[ valuesNotTheSame, allValuesBetween, allRequired ]}
          style={{ display: 'flex' }}
        >
          <div className="row form-group">
            <h3 className="col-md-12">Roof Solar Access Percentages</h3>
            <div className="row col-md-12 center-md solar-access-percentages-row">
              <div className="col-md-1">
                {monthlySolarAccessInput('JAN', 0)}
              </div>
              <div className="col-md-1 col-md-offset-1">
                {monthlySolarAccessInput('FEB', 1)}
              </div>
              <div className="col-md-1 col-md-offset-1">
                {monthlySolarAccessInput('MAR', 2)}
              </div>
              <div className="col-md-1 col-md-offset-1">
                {monthlySolarAccessInput('APR', 3)}
              </div>
              <div className="col-md-1 col-md-offset-1">
                {monthlySolarAccessInput('MAY', 4)}
              </div>
              <div className="col-md-1 col-md-offset-1">
                {monthlySolarAccessInput('JUN', 5)}
              </div>
            </div>
            <div className="row col-md-12 center-md">
              <div className="col-md-1">
                {monthlySolarAccessInput('JUL', 6)}
              </div>
              <div className="col-md-1 col-md-offset-1">
                {monthlySolarAccessInput('AUG', 7)}
              </div>
              <div className="col-md-1 col-md-offset-1">
                {monthlySolarAccessInput('SEP', 8)}
              </div>
              <div className="col-md-1 col-md-offset-1">
                {monthlySolarAccessInput('OCT', 9)}
              </div>
              <div className="col-md-1 col-md-offset-1">
                {monthlySolarAccessInput('NOV', 10)}
              </div>
              <div className="col-md-1 col-md-offset-1">
                {monthlySolarAccessInput('DEC', 11)}
              </div>
            </div>
          </div>
        </GroupValidator>
      </ValidatedModal>
    </ValidationContainer>
  );
}

RoofModal.propTypes = {
  addNewRoof: PropTypes.func.isRequired,
  updateRoof: PropTypes.func.isRequired,
  updateRoofData: PropTypes.func.isRequired,
  updateRoofValidationErrors: PropTypes.func.isRequired,
  updateRoofMonthlySolarAccess: PropTypes.func.isRequired,
  design: designType.isRequired,
  roof: manualModeRoofType,
  quote: quoteType,
  settings: settingsType,
  dcModule: PropTypes.bool.isRequired,
  selectedInverters: PropTypes.arrayOf(selectedInverterType).isRequired,
  account: accountType.isRequired,
  partnerIsNewHomes: PropTypes.bool.isRequired,
  validationErrors: PropTypes.objectOf(PropTypes.arrayOf(PropTypes.string))
};

export default register(
  [ 'designSelector', 'roofSelector', 'dcModuleSelector', 'selectedInvertersSelector', 'settingsSelector',
    'validationErrorsSelector', 'quoteSelector', 'accountSelector', 'partnerIsNewHomesSelector' ],
  [
    'addNewRoof', 'updateRoof', 'updateRoofData',
    'updateRoofMonthlySolarAccess', 'updateRoofValidationErrors'
  ],
  RoofModal
);

