import { saveAs } from 'file-saver';
import PropTypes from 'prop-types';
import React from 'react';
import BackIcon from '../../../../images/back.svg';
import DownloadIcon from '../../../../images/download.svg';
import HelperImg from '../../../../images/help-guide.svg';
import { Modal } from '../../Modal';
import { DownloadableList } from './DownloadableList';
import { GuideStep } from './GuideStep';

export function HelpGuideModal({
  setHelpText,
  financialOfferings,
  utilities
}) {
  const downloadTemplate = () => {
    const fileName = 'Pricing Controls Template.xlsx';
    fetch(`/${fileName}`).then((res) => {
      if (!res.ok) {
        const responseStatusText = res.statusText;
        const errorMessage = `${responseStatusText}`;
        throw new Error(errorMessage);
      }
      return res.arrayBuffer();
    }).then((ab) => {
      const byteArray = new Uint8Array(ab);
      const blob = window.URL.createObjectURL(
        new Blob([byteArray], {
          type:
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        }),
      );
      saveAs(blob, fileName);
    });
  };

  const Sidebar = () => {
    return (
      <span className="download-bar">
        You can download the template here
        <button
          className="btn btn-secondary download"
          onClick={downloadTemplate}
        >
          <DownloadIcon />
        </button>
      </span>
    );
  };

  const Content = () => (
    <div className="step-content">
      <HelperImg />
    </div>
  );

  return (
    <Modal
      id="help-text-modal"
      name="help-text-modal"
      containerClass="container"
      showButtons={false}
      modals={{}}
    >
      <div className="header">
        <div className="left-head">
          <a className="back" onClick={() => setHelpText(false)}>
            <BackIcon width={12} heigth={12} />
            Back
          </a>
        </div>
        <span className="title">
          HELP GUIDE
        </span>
      </div>
      <div className="container">
        <h1>
          <b>
            Price defaults are set at the Utility or State level
          </b>
          and are specific to panel equipment and financial
          <br />option (Cash / Lease / Loan / PPA)
        </h1>
        <h2>How it works</h2>
        <div className="guide-steps">
          <GuideStep
            index={1}
            title="Create spreadsheet file with the following columns:"
            sidebar={Sidebar}
            content={Content}
          />
          <GuideStep
            index={2}
            title="Save the file as a .csv"
            subtitle="to a local folder on your computer."
          />
          <GuideStep
            index={3}
            title="Click the 'Upload File' button"
            subtitle="within the pricing inputs section and select your saved .csv file."
          />
          <GuideStep
            index={4}
            title="Once upload is complete, a preview will show."
            subtitle={`Any duplicates or other errors will be identified.
              If found, review the file uploaded, make any adjustments
              and replace with new file.
            `}
          />
          <GuideStep
            index={5}
            title="These price settings will remain in place until a new file is uploaded"
            subtitle="or the settings are reset via the 'Reset' option."
          />
        </div>
        <h2 className="download-title">Downloadable lists</h2>
        <DownloadableList
          financialOfferings={financialOfferings}
          utilities={utilities}
        />
      </div>
    </Modal>
  );
}

HelpGuideModal.propTypes = {
  setHelpText: PropTypes.func,
  utilities: PropTypes.arrayOf(PropTypes.object),
  financialOfferings: PropTypes.arrayOf(PropTypes.object)
};
