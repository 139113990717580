
export function updateIQDesign({ moduleArrays, roof }) {
  return {
    type: 'updateIQDesign',
    payload: {
      design: {
        moduleArrays,
        roof,
        hasChanges: true
      },
      quote: {
        finEng: null
      },
      moduleChanged: true
    }
  };
}

export function markDesignDirty() {
  return {
    type: 'markDesignDirty',
    payload: {
      design: {
        hasChanges: true
      },
      quote: {
        finEng: null
      }
    }
  };
}
