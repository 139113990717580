import { datadogRum } from '@datadog/browser-rum';
import { getSelectedFinancialOptions } from './selectedModuleValidationUtility';
import { CASH, LEASE, LOAN, PPA } from './EddieCalculations/helpers/financeTypes';

export const datadogTrackLoadingTime = (
  actionName,
  loadingTime,
  quote,
  settings,
  channel,
  productConfiguration = null,
  customOptions = {}
  ) => {
  const selectedFinanceOptions = productConfiguration ?
    getSelectedFinancialOptions({ productConfiguration, settings })
    : null;
  const storageSelected = quote.storageQuantity > 0;
  const designType = quote.isInstantQuote ? 'instant' : (quote.manualMode ? 'manual' : 'dynamic');

  const cashSelected = selectedFinanceOptions && selectedFinanceOptions.indexOf(CASH) > -1;
  const leaseSelected = selectedFinanceOptions && selectedFinanceOptions.indexOf(LEASE) > -1;
  const ppaSelected = selectedFinanceOptions && selectedFinanceOptions.indexOf(PPA) > -1;
  const loanSelected = selectedFinanceOptions && selectedFinanceOptions.indexOf(LOAN) > -1;

  datadogRum.addAction(actionName, {
    loading: loadingTime,
    quote_id: quote.sfid,
    design_type: designType,
    storage_selected: storageSelected,
    cash_selected: cashSelected,
    lease_selected: leaseSelected,
    ppa_selected: ppaSelected,
    loan_selected: loanSelected,
    channel,
    ...customOptions
  });
};
