function toHex(number) {
  return `00${number.toString(16)}`.slice(-2);
}

function parseColor(hexColor) {
  return hexColor.match(/#(..)(..)(..)/).slice(1)
    .map((component) => parseInt(component, 16));
}

function toColor([ red, green, blue ]) {
  return `#${toHex(red)}${toHex(green)}${toHex(blue)}`;
}

export default function([ minColor, maxColor ], [ minValue, maxValue ], value) {
  if (minValue === maxValue) {
    return maxColor;
  }

  const [ r1, g1, b1 ] = parseColor(minColor);
  const [ r2, g2, b2 ] = parseColor(maxColor);
  const ratio = (value - minValue) / (maxValue - minValue);
  return toColor([
    Math.floor(ratio * (r2 - r1) + r1),
    Math.floor(ratio * (g2 - g1) + g1),
    Math.floor(ratio * (b2 - b1) + b1)
  ]);
}
