export function setAcknowledgeIdentityOfCustomer(value) {
  return {
    type: 'setAcknowledgeIdentityOfCustomer',
    payload: {
      dynamicProposal: {
        acknowledgeIdentityOfCustomer: value
      }
    }
  };
}
