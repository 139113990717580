import React from 'react';
import PropTypes from 'prop-types';
import uniqueId from 'lodash/uniqueId';
import { register } from '../utils/redux';
import environment from '../utils/environment';
import { errorType, quoteType } from '../types';
import GenericShowHide from './GenericShowHide';
import Link from './Link';

function ErrorOverlay({ error, quote, markErrorAsShownInModal, expireLocking }) {
  const pricePerKwhError = error.message && error.message.includes('Price per kWh');
  const showStack = environment.isDevelopment() && error.stack;
  const defaultButton = !error.isFatal && (error.status !== 'GOBACK');
  const redirectButton = !error.isFatal && (error.status === 'GOBACK');
  const hideModalAndMarkQuoteAsDirty = () => expireLocking(error);
  const hideModalAndStop = (e) => {
    e.preventDefault();
    markErrorAsShownInModal(error);
  };
  const designPageUrl = () => {
    const urlPrefix = `/${quote.manualMode ? 'manual_mode/' : ''}`;
    return `${urlPrefix}quote/${quote.sfid}/design`;
  };

  return (
    <div className="modal is-centered">
      <div id="overlay" />
      <div id="error" className="popover">
        { !pricePerKwhError && (
          <h1>Something went wrong...</h1>
        )}
        <p>{error.message}</p>
        { showStack && !pricePerKwhError &&
          <div>
            { error.stack.map((line) =>
              <div key={uniqueId('error-stack')}><small>{line}</small></div>
            )}
          </div>
        }
        { error.backendStack && !pricePerKwhError &&
          <GenericShowHide>
            <div>
              { error.backendStack.map((line) =>
                <div key={uniqueId('error-backend-stack')}><small>{line}</small></div>
              )}
            </div>
          </GenericShowHide>
        }
        {
          defaultButton && (
            <a href="#" className="btn btn-primary" onClick={(e) => hideModalAndStop(e)}>OK</a>
          )
        }
        {
          redirectButton && (
            <Link className="btn btn-primary" onClick={hideModalAndMarkQuoteAsDirty} to={designPageUrl()}>
              OK
            </Link>
          )
        }
      </div>
    </div>
  );
}

ErrorOverlay.propTypes = {
  error: errorType,
  quote: quoteType.isRequired,
  markErrorAsShownInModal: PropTypes.func.isRequired,
  expireLocking: PropTypes.func
};

export default register(
  [ 'errorSelector', 'quoteSelector' ],
  [ 'markErrorAsShownInModal', 'expireLocking' ],
  ErrorOverlay
);
