import { datadogRum } from '@datadog/browser-rum';
import { clearError, setError } from './errors';
import { updateQuote } from './updateQuote';
import { toggleModal } from './toggleModal';
import {
  validatePricePerWattBasedOffTotalSystemPrice,
  validateStoragePartnerPermission,
  validateStoragePrice,
  validateSystemPrice,
  validateStorageDealerCommission
} from '../utils/validations/validateSystemPrice';
import {
  validateIfModuleAvailableForSelectedFinanceOptions,
  validateMaxEvChargerDealerFee,
  validateSelectedModuleAndFinanceOptions
} from '../utils/validations/validateSettings';
import * as s from '../selectors';
import * as actions from '../actions';
import store from '../store';
import { update } from './update';
import { filterPvModulesByFinanceOfferings } from '../utils/pvModuleFiltersAndTags';
import { getModuleUpdateRequestObject } from '../utils/selectedModuleValidationUtility';

export const validateAndSaveSettingsModal = (modalElement) => (dispatch, getState) => {
  dispatch(clearError());
  const {
    settings,
    productConfiguration,
    quote,
    design,
    account,
    systemCapacityWatts,
    partnerIsTps,
    annualKwhProduction,
    notifications,
    partnerIsRvar,
    selectedStorage,
    selectedStorageWithExpansionPack,
    partnerIsNewHomes,
    partnerIsSpd,
    partnerIsDirect,
    pvModule,
    pvModules,
    selectedEvChargerConfiguration
  } = s.select(getState(), [
    s.settingsSelector,
    s.productConfigurationSelector,
    s.quoteSelector,
    s.designSelector,
    s.accountSelector,
    s.systemCapacityWattsSelector,
    s.partnerIsTpsSelector,
    s.annualKwhProductionSelector,
    s.notificationsSelector,
    s.partnerIsRvarSelector,
    s.selectedStorageSelector,
    s.selectedStorageWithExpansionPackSelector,
    s.partnerIsNewHomesSelector,
    s.partnerIsSpdSelector,
    s.partnerIsDirectSelector,
    s.pvModuleSelector,
    s.pvModulesSelector,
    s.designSelector,
    s.selectedEvChargerConfigurationSelector
  ]);

  const error = validateStoragePartnerPermission({
    quote,
    settings,
    productConfiguration,
    partnerIsNewHomes,
    selectedStorage
  }) || validateStoragePrice({
    quote,
    settings,
    partnerIsRvar,
    partnerIsTps,
    partnerIsSpd,
    partnerIsNewHomes,
    selectedStorage,
    selectedStorageWithExpansionPack
  }) || validateStorageDealerCommission({
    account,
    settings
  }) || validateSystemPrice({
    quote,
    design,
    account,
    settings,
    productConfiguration,
    systemCapacityWatts,
    partnerIsTps,
    partnerIsSpd,
    partnerIsDirect,
    annualKwhProduction
  }) || validatePricePerWattBasedOffTotalSystemPrice({
    quote,
    settings,
    productConfiguration,
    systemCapacityWatts,
    partnerIsTps,
    notifications
  }) || validateMaxEvChargerDealerFee({ settings, partnerIsRvar });

  const moduleUnavailableForFinanceOptionError =
    validateSelectedModuleAndFinanceOptions({ pvModule, settings, productConfiguration });

  if (error !== null) {
    dispatch(setError(error));
    return;
  }
  const modulesData = (modules) => {
    return modules.map((module) => {
      return {
        key: module.itemId,
        value: module.displayName,
        tags: module.financialOfferings
      };
    });
  };

  if (moduleUnavailableForFinanceOptionError) {
    if (pvModules) {
      const selectedPvModules = filterPvModulesByFinanceOfferings(modulesData(pvModules), settings);
      const error = validateIfModuleAvailableForSelectedFinanceOptions(
        { productConfiguration, settings, pvModules, quote }, selectedPvModules);
      if (error) {
        dispatch(setError(error));
        return;
      }
      let selectedPvModuleId = selectedPvModules[0].key;
      const designType = quote.isInstantQuote ? 'instant' : (quote.manualMode ? 'manual' : 'dynamic');
      if (designType === 'dynamic') {
        const nocash = settings.cash && !settings.cash.showCustomer;
        const noloan = settings.loan && !settings.loan.showCustomer;
        const lease = settings.lease && settings.lease.showCustomer;
        const ppa = settings.ppa && settings.ppa.showCustomer;
        const leaseOrPpa = (lease || ppa) && (nocash && noloan);
        if (leaseOrPpa) {
          const checkU365 = selectedPvModules.filter((item) => item.value.indexOf('365W') > -1);
          if (checkU365.length > 0) {
            selectedPvModuleId = checkU365[0].key;
          }
        }
        store.dispatch(actions.saveModuleMonitoringRacking({
          quote,
          pvModuleItemId: selectedPvModuleId,
          monitoringConfigurationId: quote.monitoringConfigurationId,
          rackingType: quote.selectedRacking,
          isSkirtGuard: quote.isSkirtGuard,
          isCritterGuard: quote.isCritterGuard
        }));
      }
      const requestObject = getModuleUpdateRequestObject({
        quote,
        pvModules,
        selectedEvChargerConfiguration,
        selectedStorage,
        selectedStorageWithExpansionPack
      }, selectedPvModuleId);
      dispatch(updateQuote(quote.sfid, requestObject, true, true));
    }
    dispatch(setError(moduleUnavailableForFinanceOptionError));
  }

  datadogRum.addAction('save settings', { settings });
  if (!quote.locked) {
    store.dispatch(update('update Settings', {
      quote: { dirty: true }
    }));
    dispatch(toggleModal('settings', modalElement));
  }
};
