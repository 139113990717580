import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

export default function Tab({ activeTab, toggle, tabName, tabValue, text }) {
  const classTabName = activeTab === tabValue ? `${tabValue}-active` : tabValue;
  return (
    <div
      className={cx(
        'product-selection-modal-tab',
        { active: activeTab === tabValue }
      )}
      onClick={() => {
        toggle(tabName, tabValue);
      }}
    >
      <div
        className={cx(
          'product-selection-modal-tab-image',
          classTabName
        )}
      >
      &nbsp;
      </div>
      {text}
    </div>
  );
}

Tab.propTypes = {
  activeTab: PropTypes.string.isRequired,
  toggle: PropTypes.func.isRequired,
  tabName: PropTypes.string.isRequired,
  tabValue: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired
};
