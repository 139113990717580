import { cloneDeep } from 'lodash';
import { findApFinConfigIndexById } from '../../utils/admin_portal/updateHelpers';

/**
 *
 * @param {string} financeType
 * @param {object} key
 * @param {object} val
 * @returns updated state
 */
export const updateApFinConfig = (configId, key, val) => (dispatch, getState) => {
  const currentState = getState();
  const apFinConfigsToUpdate = cloneDeep(currentState.apFinConfigs);
  const finConfigIndex = findApFinConfigIndexById(apFinConfigsToUpdate, configId);
  if (finConfigIndex > -1) {
    apFinConfigsToUpdate[finConfigIndex][key] = val;

    // only one finance option can be set as default
    if (key === 'default' && val) {
      apFinConfigsToUpdate.forEach((apFinConfig, index) => {
        if (index !== finConfigIndex) {
          apFinConfig[key] = false;
        }
      });
    }
  }

  dispatch({
    type: 'updateApFinConfig',
    payload: {
      apFinConfigs: apFinConfigsToUpdate
    }
  });
};
