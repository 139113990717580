import React from 'react';
import PropTypes from 'prop-types';
import ReactTooltip from 'react-tooltip';
import { datadogRum } from '@datadog/browser-rum';

import { register } from '../utils/redux';
import { quoteType, designType, modalsType, errorType, messagesType } from '../types';
import Navigation, { LoadingNavigation } from './Navigation';
import SettingsModal from './SettingsModal';
import PanelTypesModal from './PanelTypesModal';
import PanelDetailsModal from './PanelDetailsModal';
import EditLeaseModal from './EditLeaseModal';
import EditPpaModal from './EditPpaModal';
import EditLoanModal from './EditLoanModal';
import EditMortgageModal from './EditMortgageModal';
import RoofModal from './manual_mode_design/RoofModal';
import FeedbackModal from './FeedbackModal';
import SelectedInverterModal from './manual_mode_design/SelectedInverterModal';
import ErrorOverlay from './ErrorOverlay';
import MessagesOverlay from './MessagesOverlay';
import { NOT_FOUND_URL } from '../config';

function showModal(name) {
  switch (name) {
    case 'settings': { return <SettingsModal />; }
    case 'panel-types': { return <PanelTypesModal />; }
    case 'panel-details': { return <PanelDetailsModal />; }
    case 'edit-lease': { return <EditLeaseModal />; }
    case 'edit-ppa': { return <EditPpaModal />; }
    case 'edit-loan': { return <EditLoanModal />; }
    case 'edit-mortgage': { return <EditMortgageModal />; }
    case 'feedback': { return <FeedbackModal />; }
    case 'new-roof': { return <RoofModal />; }
    case 'new-selected-inverter': { return <SelectedInverterModal />; }
    default: { return null; }
  }
}

export function Page({
  quote,
  design,
  modals,
  loading,
  error,
  messages,
  instantQuoteMode,
  canceledInstantQuoteLoading,
  instantQuoteDesignError,
  children,
  params,
  dirtySettings,
  currentTabName
}) {
  if (quote && quote.sfid && design && design.id) {
    datadogRum.addRumGlobalContext('quote', {
      sfid: quote.sfid,
      locked: quote.locked,
      type: design.isInstant ? 'instant' : design.designType,
      version: quote.version
    });
  }

  if (loading || canceledInstantQuoteLoading || instantQuoteDesignError) {
    return (
      <div id="page">
        <LoadingNavigation
          loadingText={instantQuoteMode ? 'Generating System Layout...' : 'Loading...'}
          subTitleText={instantQuoteMode ? 'CAN TAKE 1 MINUTE' : ''}
          canceledLoadingText={instantQuoteMode ? 'System Layout Generation Cancelled' : undefined}
          forInstantQuote={instantQuoteMode}
          forceCanceledStatus={canceledInstantQuoteLoading}
          designError={instantQuoteDesignError}
          currentTabName={currentTabName}
        />
      </div>
    );
  }

  if (error && error.errorClass === 'ActiveRecord::RecordNotFound') {
    window.location.href = NOT_FOUND_URL;
    return (
      <div id="page">
        <LoadingNavigation loadingText="Loading..." currentTabName={currentTabName} />
      </div>
    );
  }

  if (dirtySettings) {
    window.onbeforeunload = () => {
      return true;
    };
  } else {
    window.onbeforeunload = null;
  }

  if (error && error.isFatal) {
    return (
      <div id="page">
        <Navigation params={params} alwaysOnFront={true} />
        <ErrorOverlay />
      </div>
    );
  }

  return (
    <div id="page">
      <Navigation params={params} />

      { children }

      { modals.name && showModal(modals.name) }

      { error && (!error.display || error.display === 'both' || error.display === 'modal') && <ErrorOverlay /> }
      { messages && messages.length > 0 && <MessagesOverlay /> }

      <ReactTooltip html={true} />
    </div>
  );
}

Page.propTypes = {
  quote: quoteType,
  design: designType,
  modals: modalsType,
  loading: PropTypes.bool.isRequired,
  children: PropTypes.element.isRequired,
  params: PropTypes.oneOfType([
    PropTypes.shape({ quoteId: PropTypes.string }),
    PropTypes.shape({ designId: PropTypes.string })
  ]),
  error: errorType,
  messages: messagesType,
  instantQuoteMode: PropTypes.bool,
  canceledInstantQuoteLoading: PropTypes.bool,
  instantQuoteDesignError: PropTypes.bool,
  dirtySettings: PropTypes.bool,
  currentTabName: PropTypes.string
};

Page.defaultProps = {
  modals: {}
};

export default register(
  [
    'quoteSelector',
    'designSelector',
    'modalsSelector',
    'loadingSelector',
    'errorSelector',
    'messagesSelector',
    'instantQuoteModeSelector',
    'canceledInstantQuoteLoadingSelector',
    'instantQuoteDesignErrorSelector',
    'dirtySettingsSelector',
    'currentTabNameSelector'
  ],
  [],
  Page
);
