export function isPDFProcessing(attributes) {
  return {
    type: 'isPDFProcessing',
    payload: {
      account: {
        isPDFProcessing: attributes
      }
    }
  };
}
