// TODO: add specs once requirement is finalized
import get from 'lodash/get';
import u from 'updeep';
import { asyncPayload } from './apiHelpers';
import Api from '../utils/Api';
import { updateSalesforceQuote } from './updateSalesforceQuote';
import { setDrafSessionInLocalStorage } from '../utils/common';

export const fetchOrCreateStorageQuote = (quoteSfid, query = {}) => (dispatch) => {
  let state;
  const promise = Api.fetchOrCreateStorageQuote('storage/quote', quoteSfid, Object.assign({
    id: quoteSfid
  }, query))
  .then((response) => {
    if (get(response, 'storageQuote.locked')) {
      dispatch(updateSalesforceQuote(quoteSfid, false, 'storage'));
    }
    setDrafSessionInLocalStorage();
    return u(response, state);
  });

  dispatch({
    type: 'fetchOrCreateStorageQuote',
    payload: asyncPayload({
      beforeRequest: { loading: true },
      request: promise
    })
  });
};
