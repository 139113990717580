import { get } from 'lodash';

export default (state) => {
  const financialOfferings = get(state, 'productConfiguration.financialOfferings', null);
  const availableFinanceTypes = Object.keys(financialOfferings).filter((key) => financialOfferings[key] === true);
  const activeFinanceTypes = availableFinanceTypes.filter((tab) => get(state, `settings.${tab}.showCustomer`, false));
  let activeTab;

  if (!state.savings.activeTab && state.quote.locked) {
    activeTab = state.quote.selectedFinanceOption;
  } else {
    activeTab = state.savings.activeTab && activeFinanceTypes.includes(state.savings.activeTab) ?
    state.savings.activeTab : activeFinanceTypes[0];
  }

  return {
    savings: {
      ...state.savings,
      activeTab
    }
  };
};
