import PropTypes from 'prop-types';

export const pvModuleType = PropTypes.shape({
  itemId: PropTypes.string.isRequired,
  inverterName: PropTypes.string,
  monitoringName: PropTypes.string,
  monitoringConfigurationId: PropTypes.string,
  item: PropTypes.shape({
    productLine: PropTypes.string,
    summaryDescription: PropTypes.string,
    moduleBackSheetType: PropTypes.string,
    tagLine: PropTypes.string,
    identifier: PropTypes.string
  })
});
