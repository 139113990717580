import createSelector from '../utils/createSelector';

export function selectedDesign(state) {
  const design = state.design;

  return {
    selectedDesign: state.selectedDesign || { id: design.id, name: design.maxfitSfid }
  };
}

export default createSelector(
  selectedDesign
);
