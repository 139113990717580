import { trim } from 'lodash';
import { asyncPayload } from './apiHelpers';
import Api from '../utils/Api';
import logger from '../utils/logger';
import inputValidation from '../utils/inputValidation';

// NOTE: This action must be dispatched separately before
// reinitializeAdobeSignWidget to effectively prevent double
// widget reinitialization. Also, reinitialization should only
// happen every 7 days max, so set once here - no need to set
// false after success.
export function setAdobeSignWidgetRefreshing() {
  return {
    type: 'setAdobeSignWidgetRefreshing',
    payload: {
      adobeSign: { ui: { refreshingWidget: true } }
    }
  };
}

export function reinitializeAdobeSignWidget(quoteSfid) {
  const request = Api.refreshAdobeSignWidget(quoteSfid)
    .then(({ adobeSign }) => ({ adobeSign }))
    .catch((err) => {
      logger.warn(err);
    });
  return {
    type: 'reinitializeAdobeSignWidget',
    payload: asyncPayload({
      request
    })
  };
}

export function emailAdobeSignAgreement(quoteSfid, emailValue) {
  const recipientEmails = emailValue.split(',').map(trim);
  if (recipientEmails.some((email) => !inputValidation.isValidEmail(email))) {
    return {
      type: 'emailAdobeSignAgreement',
      payload: {
        emailProposalUi: { error: 'You have entered an invalid email address.', display: 'both' }
      }
    };
  }
  const request = Api.create(`adobe_sign/quotes/${quoteSfid}/email_agreement`, { recipientEmails })
    .then(() => ({ emailProposalUi: { sent: true, sending: false } }))
    .catch((error) => {
      logger.warn(error);
      return {
        emailProposalUi: {
          error: 'Something went wrong sending the proposal agreement.',
          sending: false,
          display: 'both'
        }
      };
    });
  return {
    type: 'emailAdobeSignAgreement',
    payload: asyncPayload({
      beforeRequest: { emailProposalUi: { error: null, sending: true, display: null } },
      request
    })
  };
}
