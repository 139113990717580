/* eslint no-underscore-dangle: 0 */

import { findApFinConfig } from '../../utils/admin_portal/updateHelpers';

export default (state) => {
  const { selectedFinanceOption, apFinConfigs } = state;
  let selectedModuleSeries = [];

  if (selectedFinanceOption && apFinConfigs) {
    const apFinConfig = findApFinConfig(apFinConfigs, selectedFinanceOption);
    selectedModuleSeries = apFinConfig
      ? apFinConfig.moduleConfigsAttributes.filter((config) =>
        !config._destroy).map((config) => config.moduleSeries) : [];
  }

  return {
    selectedModuleSeries
  };
};
