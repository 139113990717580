import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-bootstrap-modal';
import ProductSelectionModalTabs from './ProductSelectionModalTabs';
import MainContent from './MainContent';
import SidebarContent from './SidebarContent';
import { register } from '../../../utils/redux';
import { designType, productSelectionModalType } from '../../../types';

export function ProductSelectionModal({
  design,
  productSelectionModal,
  toggleProductSelectionModal,
  updateProductSelectionModal
}) {
  return (
    <Modal
      className="product-selection-modal"
      show={design.productSelectionModal}
      onHide={toggleProductSelectionModal}
      aria-labelledby="ModalHeader"
    >
      <Modal.Header closeButton={true}>
        <ProductSelectionModalTabs
          activeTab={productSelectionModal.activeTab}
          toggle={updateProductSelectionModal}
        />
      </Modal.Header>
      <Modal.Body>
        <MainContent />
        <SidebarContent />
      </Modal.Body>
    </Modal>
  );
}

ProductSelectionModal.propTypes = {
  design: designType.isRequired,
  productSelectionModal: productSelectionModalType.isRequired,
  toggleProductSelectionModal: PropTypes.func.isRequired,
  updateProductSelectionModal: PropTypes.func.isRequired
};

export default register(
  [
    'designSelector', 'productSelectionModalSelector'
  ],
  [
    'toggleProductSelectionModal',
    'updateProductSelectionModal'
  ],
  ProductSelectionModal
);
