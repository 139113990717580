import C3Chart from 'react-c3js';
import { findDOMNode } from 'react-dom';

export default class TotalSavingsGraphChart extends C3Chart {
  // Redraw the whole graph when props change
  componentDidUpdate() {
    if (this.chart) {
      // redraw chart when resizeGraph value changes which is controlled by collapsible cost breakout summary on savings page
      this.chart.destroy();
      this.chart = this.generateChart(findDOMNode(this), this.props);
      this.chart.flush();
      setTimeout(() => {
        this.chart.resize();
      }, 500);
    }
  }
}
