export function setSoAcknowledgeNotifiedToCustomer(value) {
  return {
    type: 'setSoAcknowledgeNotifiedToCustomer',
    payload: {
      soDynamicProposal: {
        acknowledgeNotifiedToCustomer: value
      }
    }
  };
}
