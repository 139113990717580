import PropTypes from 'prop-types';

export const pvModuleForManualModeType = PropTypes.shape({
  financialOfferings: PropTypes.array.isRequired,
  item: PropTypes.shape({
    itemCategory: PropTypes.string
  }),
  itemId: PropTypes.string.isRequired,
  displayName: PropTypes.string.isRequired
});
