import React from 'react';
import PropTypes from 'prop-types';
import MainContentTabs from './MainContentTabs';
import { register } from '../../../utils/redux';
import { designType, productSelectionModalType } from '../../../types';

export function MainContent({
  productSelectionModal,
  updateProductSelectionModal
}) {
  const lorem = (<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt
          ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco
          laboris nisi ut aliquip ex ea commodo consequat.</p>);

  return (
    <div className="main-content">
      <div className="content">
        {lorem}
        <br />
        {lorem}
        <br />
        {lorem}
        <br />
        {lorem}
        <br />
      </div>
      <MainContentTabs
        activeTab={productSelectionModal.mainContentTab}
        toggle={updateProductSelectionModal}
      />
    </div>
  );
}

MainContent.propTypes = {
  design: designType.isRequired,
  productSelectionModal: productSelectionModalType.isRequired,
  updateProductSelectionModal: PropTypes.func.isRequired
};

export default register(
  [
    'designSelector', 'productSelectionModalSelector'
  ],
  [
    'updateProductSelectionModal'
  ],
  MainContent
);
