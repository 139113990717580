import AbortController from 'abort-controller';
import { asyncPayload } from './apiHelpers';
import Api from '../utils/Api';
import constants from '../utils/EddieCalculations/constants';

const {
  PPA_FINANCE_TYPE
} = constants;

const beforeRequestState = (controller, settings, selectedFinanceOption) => {
  if (selectedFinanceOption === PPA_FINANCE_TYPE) {
    return {
      settings: {
        ppa: {
          fetchingFinEng: true,
          fetchingFinEngController: controller,
          fetchingFinEngPricePerKwh: settings.pricePerKwh
        }
      }
    };
  }
  return {
    settings: {
      lease: {
        fetchingFinEng: true,
        fetchingFinEngController: controller,
        fetchingFinEngPricePerKwh: settings.pricePerKwh
      }
    }
  };
};

const afterRequestState = (controller, settings, selectedFinanceOption) => {
  if (selectedFinanceOption === PPA_FINANCE_TYPE) {
    return {
      settings: {
        ppa: {
          needsFinEng: false,
          fetchingFinEng: false,
          fetchingFinEngAdders: settings.adders,
          fetchingFinEngDiscount: settings.discount,
          fetchingFinEngOverrideRebate: settings.overrideRebate
        }
      }
    };
  }
  return {
    settings: {
      lease: {
        needsFinEng: false,
        fetchingFinEng: false,
        fetchingFinEngAdders: settings.adders,
        fetchingFinEngDiscount: settings.discount,
        fetchingFinEngOverrideRebate: settings.overrideRebate
      }
    }
  };
};

const errorState = (selectedFinanceOption) => {
  if (selectedFinanceOption === PPA_FINANCE_TYPE) {
    return {
      settings: {
        ppa: {
          fetchingFinEng: false
        }
      }
    };
  }
  return {
    settings: {
      lease: {
        fetchingFinEng: false
      }
    }
  };
};

export function fetchFinEng(quote, design, settings, adders, selectedFinanceOption) {
  const controller = new AbortController();
  const options = {
    signal: controller.signal
  };

  return {
    type: 'fetchFinEng',
    payload: asyncPayload({
      beforeRequest: beforeRequestState(controller, settings, selectedFinanceOption),
      request: Api.createWithErrorHandling(`quotes/${quote.sfid}/fetch_fin_eng`, {
        design,
        settings,
        adders,
        selectedFinanceOption,
        quote
      }, options),
      afterRequest: afterRequestState(controller, settings, selectedFinanceOption),
      errorRequest: errorState(selectedFinanceOption)
    })
  };
}
