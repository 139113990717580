import * as actions from '../actions';
import store from '../store';

// TODO: This is an action not an util. This should be refactored to
// function as other validators to return error.
export default function validateLoanAmount(amount, onSuccess) {
  if (amount >= 10000) {
    onSuccess();
  } else {
    store.dispatch(actions.setError({
      message: 'The loan product requires a minimum loan amount of $10,000. Please update the quote and try again.'
    }));
  }
}
