import React from 'react';
import ChartInfoBox from './ChartInfoBox';
import { register } from '../../utils/redux';
import { wrapWithTransitionGroup } from '../../utils/animations';
import {
  savingsChartInfoBoxType
} from '../../types';
import ChartSlider from './ChartSlider';

function ChartInfo(props) {
  const { savingsChartInfoBoxData } = props;

  return wrapWithTransitionGroup(
      'chart-info-box',
      350,
      350,
      (
        <div className="chart-info-box-group">
          <ChartSlider />
          {
            savingsChartInfoBoxData.x !== null ? (
              <ChartInfoBox {...props} />
            ) : null
          }
        </div>
      )
  );
}

ChartInfo.propTypes = {
  savingsChartInfoBoxData: savingsChartInfoBoxType
};

export default register(
  ['savingsChartInfoBoxDataSelector'],
  [],
  ChartInfo
);
