export function toggleAddEvChargerModalFlag() {
  return {
    type: 'toggleAddEvChargerModalFlag',
    payload: {
      design: {
        isAddEvChargerModalOpen: (currentValue) => !currentValue
      }
    }
  };
}
