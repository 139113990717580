import { get as _get } from 'lodash';
import get from './get';
import createSelector from '../utils/createSelector';
import { filterPvModules } from '../utils/pvModuleFiltersAndTags';
import { pvModulesSelector } from '../selectors';
import {
  isCellularMonitoringConfiguration,
  isInvisiMountType,
  isRackingGroundType,
  isRackingSolarMountType
} from '../utils/EddieCalculations/helpers/monitoringConfiguration';

const cellularSuffix = ' + Cellular Monitoring';

function processModelName(name) {
  return name.replace(/ Type .*/, '');
}

function matchingMonitoring(monitoringName, monitoring, cellularMonitoring) {
  const sameType = withoutCellular(monitoringName) === withoutCellular(monitoring);
  const hasCellularSuffix = monitoringName.includes(cellularSuffix);
  const matchesCellular = cellularMonitoring ? hasCellularSuffix : !hasCellularSuffix;

  return sameType && matchesCellular;
}

function withoutCellular(monitoring) {
  return monitoring.includes(cellularSuffix) ? monitoring.slice(0, -cellularSuffix.length) : monitoring;
}

function filteredPvModules(pvModules, settings) {
  const data = pvModules.map((module) => {
    return {
      ...module,
      tags: module.financialOfferings
    };
  });
  return filterPvModules(data, settings);
}

function colorOf(pv) {
  return pv.item.moduleBackSheetType;
}

function manufacturerOf(pv) {
  return _get(pv, 'manufacturer', 'sunpower') || 'sunpower';
}

function modelOf(pv) {
  return processModelName(pv.item.summaryDescription);
}

function inverterOf(pv) {
  return {
    name: pv.inverterName,
    monitoring: {
      name: pv.monitoringName,
      id: pv.monitoringConfigurationId
    }
  };
}

function matchesItemId(pv, itemId) {
  return pv.itemId === itemId;
}

function matchesSeries(pv, series) {
  return pv.item.moduleSeries === series;
}

function matchesColor(pv, color) {
  return colorOf(pv) === color;
}

function matchesModel(pv, model) {
  return modelOf(pv) === model;
}

function seriesValues(validPvModules) {
  const series = validPvModules.map((pv) =>
    pv.item.moduleSeries
  );
  return [...new Set(series)];
}

function manufacturerValues(validPvModules, selectedSeries) {
  const filter = (pv) => matchesSeries(pv, selectedSeries);
  const mapper = (pv) => manufacturerOf(pv);
  const modules = validPvModules.filter(filter).map(mapper);
  return [...new Set(modules)];
}

function colorValues(validPvModules, selectedSeries) {
  const filter = (pv) => matchesSeries(pv, selectedSeries);
  const mapper = (pv) => colorOf(pv);
  const modules = validPvModules.filter(filter).map(mapper);
  return [...new Set(modules)];
}

function modelValues(validPvModules, selectedSeries, selectedColor) {
  const filter = (pv) => matchesSeries(pv, selectedSeries)
    && matchesColor(pv, selectedColor);
  const mapper = (pv) => modelOf(pv);
  const modules = validPvModules.filter(filter).map(mapper);
  return [...new Set(modules)];
}

function inverterValues(validPvModules, selectedSeries, selectedColor, selectedModel) {
  const filter = (pv) => matchesSeries(pv, selectedSeries)
    && matchesColor(pv, selectedColor)
    && matchesModel(pv, selectedModel);
  const mapper = (pv) => inverterOf(pv);
  const modules = validPvModules.filter(filter).map(mapper);
  return [...new Set(modules)];
}

export function totalValues(state) {
  const {
    quote,
    pvModules,
    settings,
    productConfiguration,
    moduleSelectionModal,
    account
  } = state;

  const pvModulesWithSeries = pvModulesSelector({ pvModules }).pvModules;

  let { selectedSeries, selectedColor, selectedModel, selectedMonitoring, selectedRacking,
    selectedCellularMonitoring, selectedInverter, isCritterGuard, isSkirtGuard, showAdders } = moduleSelectionModal;
  const validPvModules = filteredPvModules(pvModulesWithSeries, settings);
  const selectedPvModule = pvModulesWithSeries.find((pvModule) => pvModule.itemId === quote.pvModuleItemId);
  if (!selectedPvModule) {
    return {};
  }

  const initialSeries = selectedPvModule.item.moduleSeries;
  selectedSeries = selectedSeries || initialSeries;
  const series = seriesValues(validPvModules);

  const manufacturers = manufacturerValues(validPvModules, selectedSeries);
  const isSunPowerModule = manufacturers && manufacturers[0] && manufacturers[0].toLowerCase() === 'sunpower';
  showAdders = true;

  const initialColor = colorOf(selectedPvModule);
  selectedColor = selectedColor || initialColor;
  const colors = colorValues(validPvModules, selectedSeries);
  if (!colors.includes(selectedColor)) {
    selectedColor = colors[0];
  }

  const initialModel = modelOf(selectedPvModule);
  selectedModel = selectedModel || initialModel;
  const models = modelValues(validPvModules, selectedSeries, selectedColor);
  if (!models.includes(selectedModel)) {
    selectedModel = models[0];
  }

  const inverters = inverterValues(validPvModules, selectedSeries, selectedColor, selectedModel);
  const monitorings = inverters.map((inv) => inv.monitoring);

  const initialMonitoring = selectedPvModule.monitoringName && withoutCellular(selectedPvModule.monitoringName);
  selectedMonitoring = selectedMonitoring || initialMonitoring;
  const availableMonitorings = inverters.map((inverter) => withoutCellular(inverter.monitoring.name));
  if (!availableMonitorings.includes(selectedMonitoring)) {
    if (availableMonitorings.includes(initialMonitoring) || availableMonitorings.length === 0) {
      selectedMonitoring = withoutCellular(initialMonitoring);
    } else {
      selectedMonitoring = availableMonitorings[0];
    }
  }

  if (selectedCellularMonitoring === null) {
    selectedCellularMonitoring = isCellularMonitoringConfiguration({ name: selectedMonitoring });
  }

  const monitoringFilter = selectedCellularMonitoring ?
    selectedMonitoring.concat(cellularSuffix) : selectedMonitoring;

  const cellularMonitoringExists = monitorings.some(isCellularMonitoringConfiguration);

  let rackings;
  const channel = _get(account, 'partner.channel');

  if (!isSunPowerModule && channel && !channel.toLowerCase().includes('indirect')) {
    rackings = productConfiguration.rackings.map((r) => r.type)
      .filter((r) => isInvisiMountType(r));
    if (rackings) {
      selectedRacking = rackings[0];
    }
  } else {
    rackings = productConfiguration.rackings.map((r) => r.type)
      .filter((r) => !isRackingGroundType(r) && !isRackingSolarMountType(r));
  }

  const initialRacking = quote.rackingType;
  selectedRacking = selectedRacking || initialRacking;

  const matchingInverters = inverters.filter((inv) =>
    matchingMonitoring(inv.monitoring.name, selectedMonitoring, selectedCellularMonitoring));
  const previouslySelectedInverter = matchingInverters.find((inv) =>
    selectedPvModule.inverterName === inv.name);
  const initialInverter = previouslySelectedInverter || matchingInverters[0];
  const selectedMatchingInverter = selectedInverter && matchingInverters.find((inv) =>
    selectedInverter.name === inv.name);
  selectedInverter = selectedMatchingInverter || initialInverter;

  const validPvModule = validPvModules.length > 0 ? validPvModules.find((pv) => {
    return matchesSeries(pv, selectedSeries)
      && matchesColor(pv, selectedColor)
      && matchesModel(pv, selectedModel)
      && matchingMonitoring(pv.monitoringName, selectedMonitoring, selectedCellularMonitoring)
      && pv.inverterName === selectedInverter.name;
  }) : null;
  const pvModuleItemId = validPvModule ? validPvModule.itemId : (validPvModules.length > 0 ?
    validPvModules[0].itemId : null);

  const selectedMonitoringConfiguration = monitorings.find((mc) => mc.name.includes(monitoringFilter));

  const currentModule = validPvModules.length > 0 ?
    validPvModules.find((pv) => pv.itemId === pvModuleItemId) : null;
  const currentModuleMonitoringConfigurationId = currentModule ?
    currentModule.monitoringConfigurationId : null;

  const monitoringConfigurationId = selectedMonitoringConfiguration ?
    selectedMonitoringConfiguration.id : currentModuleMonitoringConfigurationId;

  const initialCritterGuard = quote.isCritterGuard;
  isCritterGuard = showAdders ? (isCritterGuard !== '' ? isCritterGuard : initialCritterGuard) : false;
  const initialSkirtGuard = quote.isSkirtGuard;
  isSkirtGuard = showAdders ? (isSkirtGuard !== '' ? isSkirtGuard : initialSkirtGuard) : false;

  return {
    moduleSelectionModal: {
      series,
      selectedSeries,
      selectedColor,
      colors,
      selectedModel,
      models,
      selectedMonitoring,
      inverters,
      selectedInverter,
      selectedCellularMonitoring,
      cellularMonitoringExists,
      selectedRacking,
      rackings,
      pvModuleItemId,
      monitoringConfigurationId,
      isCritterGuard,
      isSkirtGuard,
      showAdders
    }
  };
}

export default createSelector(
  get('moduleSelectionModal'),
  totalValues
);
