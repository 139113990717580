import get from 'lodash/get';

const NAME_AND_ADDRESS_MISMATCHED = 'Primary contact does not exist with this owner name and address';
const NAME_MISMATCHED = 'Primary contact does not exist with this owner name';
const ADDRESS_MISMATCHED = 'Primary contact does not exist with this address';

const BOTH_MATCHED = 'Both matches found';
const NO_RECORD_FOUND = 'No record found for search criteria submitted.';
const DECLINED_TITLE_CHECK = 'Declined Title Check';

export const constants = {
  ACTIONS: {
    cancel: 'cancel',
    overwrite: 'overwrite',
    add: 'add',
    decline: 'decline'
  }
};

const titleCheckMessage = (titleCheckDetails) => {
  return get(titleCheckDetails, 'message', null);
};

export const titleCheckRecord = (titleCheckDetails) => {
  return get(titleCheckDetails, 'titlecheck', null);
};

export const isNameMisMatched = (titleCheckDetails) => {
  return titleCheckMessage(titleCheckDetails) === NAME_MISMATCHED;
};

export const isAddressMisMatched = (titleCheckDetails) => {
  return titleCheckMessage(titleCheckDetails) === ADDRESS_MISMATCHED;
};

export const isNameAndAddressMisMatched = (titleCheckDetails) => {
  return titleCheckMessage(titleCheckDetails) === NAME_AND_ADDRESS_MISMATCHED;
};

export const isNameAndAddressMatched = (titleCheckDetails) => {
  return titleCheckMessage(titleCheckDetails) === BOTH_MATCHED;
};

export const isTitleCheckRecordNotFound = (titleCheckDetails) => {
  return titleCheckMessage(titleCheckDetails) === NO_RECORD_FOUND;
};

export const isTitleCheckDeclined = (titleCheckDetails) => {
  return titleCheckMessage(titleCheckDetails) === DECLINED_TITLE_CHECK;
};

export const isNullMessage = (titleCheckDetails) => {
  return titleCheckMessage(titleCheckDetails) === null;
};

export const showTitleCheck = (titleCheckDetails) => {
  return isNameMisMatched(titleCheckDetails) || isAddressMisMatched(titleCheckDetails) ||
    isNameAndAddressMisMatched(titleCheckDetails);
};

export const titleCheckNotAvailable = (titleCheckDetails) => {
  return isNullMessage(titleCheckDetails) && !titleCheckRecord(titleCheckDetails);
};

export const skipTitleCheckModal = (titleCheckDetails) => {
  return !showTitleCheck(titleCheckDetails);
};

export const getTitle = (titleCheckDetails) => {
  if (titleCheckDetails) {
    if (isNameAndAddressMisMatched(titleCheckDetails)) {
      return 'Name & Address doesn\'t match';
    } else if (isNameMisMatched(titleCheckDetails)) {
      return 'Name doesn\'t match';
    } else if (isAddressMisMatched(titleCheckDetails)) {
      return 'Address doesn\'t match';
    }
  }
  return null;
};

export const getSubTitle = (titleCheckDetails) => {
  if (titleCheckDetails) {
    if (isNameAndAddressMisMatched(titleCheckDetails)) {
      return 'Please review name and address on title';
    } else if (isNameMisMatched(titleCheckDetails)) {
      return 'Please review the name on title';
    } else if (isAddressMisMatched(titleCheckDetails)) {
      return 'Please review the address on title';
    }
  }
  return null;
};

export const getButtonTitle = (titleCheckDetails) => {
  return titleCheckMessage(titleCheckDetails) === ADDRESS_MISMATCHED ?
    'Use this address' : 'Use this information';
};

export const getUpdateType = (titleCheckDetails) => {
  if (titleCheckDetails) {
    if (isNameAndAddressMisMatched(titleCheckDetails)) {
      return 'allinfo';
    } else if (isNameMisMatched(titleCheckDetails)) {
      return 'ownername';
    } else if (isAddressMisMatched(titleCheckDetails)) {
      return 'address';
    }
  }
  return null;
};

export const titleCheckAddress = (titleCheckDetails) => {
  const titleCheckStreet = get(titleCheckDetails, 'titlecheck.propertyStreetC', '').trim();
  const titleCheckCity = get(titleCheckDetails, 'titlecheck.propertyCityC', '').trim();
  const titleCheckState = get(titleCheckDetails, 'titlecheck.propertyStateC', '').trim();
  const titleCheckPostalCode = get(titleCheckDetails, 'titlecheck.propertyZipC', '').trim();
  return `${titleCheckStreet} ${titleCheckCity}, ${titleCheckState} ${titleCheckPostalCode}`;
};

export const titleCheckName = (titleCheckDetails) => {
  const titleCheckFirstName = get(titleCheckDetails, 'titlecheck.ownerFirstNameC', '').trim();
  const titleCheckLastName = get(titleCheckDetails, 'titlecheck.ownerLastNameC', '').trim();
  return `${titleCheckFirstName} ${titleCheckLastName}`;
};
