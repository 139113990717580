import {
  ENABLE_PRICE_PER_KWH,
  ENABLE_MORTGAGE,
  ENABLE_GUARD_ACCESSORIES,
  ENABLE_ADOBE_SIGN,
  VALIDATE_PRICE_PER_WATT_FOR_CASH_LEASE_FOR_ND,
  ENABLE_STORAGE_EXPANSION_PACKS,
  ENABLE_STORAGE_EXPANSION_PACKS_SO,
  ENABLE_H_INVERTER_SO,
  ENABLE_DYNAMIC_PROPOSAL,
  ENABLE_PPA_FINANCE_OPTION,
  ENABLE_EV_CHARGER,
  ENABLE_EV_CHARGER_SO,
  ENABLE_LEASE_PPA_FULL_PREPAYMENT,
  ENABLE_LEASE_PPA_DOWN_PAYMENT,
  ENABLE_CREDIT_APP_IN_EDDIE,
  ENABLE_DYNAMIC_PROPOSAL_SO,
  ENABLE_DYNAMIC_PROPOSAL_ACKNOWLEDGEMENT,
  ENABLE_SEND_MORE_FIELDS_TO_SFDC,
  ENABLE_INSTALL_DATE,
  ENABLE_CUSTOM_ADDERS,
  ENABLE_DESIGN_REVISION,
  ENABLE_DOWNLOAD_DP_PDF,
  ENABLE_GOODLEAP_CHECKBOX
} from '../config';
import environment from './environment';
import { isFeatureFlagOn } from './featureFlags';

export function pricePerKwhEnabled() {
  return !!(ENABLE_PRICE_PER_KWH === 'true');
}

export function mortgageEnabled() {
  return !!(ENABLE_MORTGAGE === 'true');
}

export function guardAccessoriesEnabled() {
  return !!(ENABLE_GUARD_ACCESSORIES === 'true');
}

export function adobeSignEnabled() {
  return !!(ENABLE_ADOBE_SIGN === 'true');
}

export function storageExpansionPackEnabled() {
  if (environment.isDevelopment()) {
    return (ENABLE_STORAGE_EXPANSION_PACKS === 'true' ||
      isFeatureFlagOn('ENABLE_STORAGE_EXPANSION_PACKS'));
  }
  return !!(ENABLE_STORAGE_EXPANSION_PACKS === 'true');
}

export function storageExpansionPackEnabledForStorage() {
  if (environment.isDevelopment()) {
    return (ENABLE_STORAGE_EXPANSION_PACKS_SO === 'true' ||
      isFeatureFlagOn('ENABLE_STORAGE_EXPANSION_PACKS_SO'));
  }
  return !!(ENABLE_STORAGE_EXPANSION_PACKS_SO === 'true');
}

export function validatePricePerWattForCashLeaseForND() {
  return !!(VALIDATE_PRICE_PER_WATT_FOR_CASH_LEASE_FOR_ND === 'true');
}

export function hInverterEnabledForSO() {
  return !!(ENABLE_H_INVERTER_SO === 'true');
}

export function dynamicProposalEnabled() {
  if (environment.isDevelopment()) {
    return (ENABLE_DYNAMIC_PROPOSAL === 'true' ||
      isFeatureFlagOn('ENABLE_DYNAMIC_PROPOSAL'));
  }
  return !!(ENABLE_DYNAMIC_PROPOSAL === 'true');
}

export function ppaEnabled() {
  if (environment.isDevelopment()) {
    return (ENABLE_PPA_FINANCE_OPTION === 'true' ||
      isFeatureFlagOn('ENABLE_PPA_FINANCE_OPTION'));
  }
  return !!(ENABLE_PPA_FINANCE_OPTION === 'true');
}

export function evChargerEnabled() {
  return !!(ENABLE_EV_CHARGER === 'true');
}

export function evChargerEnabledForStorageOnly() {
  return !!(ENABLE_EV_CHARGER_SO === 'true');
}

export function leasePpaFullPrepaymentEnabled() {
  return !!(ENABLE_LEASE_PPA_FULL_PREPAYMENT === 'true');
}

export function leasePpaDownPaymentEnabled() {
  return !!(ENABLE_LEASE_PPA_DOWN_PAYMENT === 'true');
}

export function enableCreditAppInEddie() {
  return !!(ENABLE_CREDIT_APP_IN_EDDIE === 'true');
}

export function storageOnlyDynamicProposalEnabled() {
  if (environment.isDevelopment()) {
    return (ENABLE_DYNAMIC_PROPOSAL_SO === 'true' ||
      isFeatureFlagOn('ENABLE_DYNAMIC_PROPOSAL_SO'));
  }
  return !!(ENABLE_DYNAMIC_PROPOSAL_SO === 'true');
}

export function acknowledgementEnabled() {
  return !!(ENABLE_DYNAMIC_PROPOSAL_ACKNOWLEDGEMENT === 'true');
}

export function sendMoreFieldsToSFDC() {
  return !!(ENABLE_SEND_MORE_FIELDS_TO_SFDC === 'true');
}

export function enableInstallDate() {
  return !!(ENABLE_INSTALL_DATE === 'true');
}

export function enableCustomAdders() {
  return !!(ENABLE_CUSTOM_ADDERS === 'true');
}

export function enableDesignRevision() {
  return !!(ENABLE_DESIGN_REVISION === 'true');
}
export function enableDownloadDPPDF() {
  return !!(ENABLE_DOWNLOAD_DP_PDF === 'true');
}

export function enableGoodleapCheckbox() {
  return !!(ENABLE_GOODLEAP_CHECKBOX === 'true');
}
