import 'babel-polyfill';
import React from 'react';
import reactGuard from 'react-guard';
import ReactDOM from 'react-dom';
import { browserHistory, IndexRedirect, Route, Router } from 'react-router';
import { Provider } from 'react-redux';
import { setOptions } from 'react-pdf';
import { datadogRum } from '@datadog/browser-rum';
import { enableDesignRevision } from './utils/backendFeatureFlags';
import logger from './utils/logger';
import {
  fetchAdminPortal,
  fetchCsatFeedback,
  fetchInstantQuoteQuote,
  fetchManualModeQuote,
  fetchOrCreateDynamicQuote,
  fetchOrCreateMaxfitDesign,
  fetchOrCreateStorageQuote,
  fetchPublicDynamicQuote,
  setCurrentTabName,
  update
} from './actions';
import { publicAuth, withAdminPortalAuth, withAuth } from './utils/withAuth';
import { gaTrackEvent } from './utils/googleAnalytics';
import store from './store';
import { createHistoryListener } from './utils/historyListener';
import Page from './components/Page';
import StorageQuoteContainer from './components/storage_quote/StorageQuoteContainer';
import MatchPage from './components/MatchPage';
import NewDesignPage from './components/dynamic_design/NewDesignPage';
import PublicDesignPage from './components/public/PublicDesignPage';
import SavingsPage from './components/SavingsPage';
import ManualModeDesignPage from './components/ManualModeDesignPage';
import PresentationPage from './components/public/presentation_page/PresentationPage';
import InstantQuoteDesignPage from './components/InstantQuoteDesignPage';
import StorageQuoteHomePage from './components/storage_quote/StorageQuoteHomePage';
import { AutoselectInputContent } from './utils/AutoselectInputContent';
import StorageSelectionPageContainer from './components/storage_quote/StorageSelectionPageContainer';
import ProposalContainer from './components/ProposalContainer';
import AdminPortalContainer from './components/admin_portal/BaseContainer';
import AdminHomePage from './components/admin_portal/HomePage';
import StorageOnlyProposalContainer from './components/storage_quote/StorageOnlyProposalContainer';

import { CODE_ENV, CODE_VERSION, DD_RUM_APP_ID, DD_RUM_SERVICE, DD_RUM_SITE, DD_RUM_TOKEN } from './config';

datadogRum.init({
  applicationId: DD_RUM_APP_ID,
  clientToken: DD_RUM_TOKEN,
  site: DD_RUM_SITE,
  service: DD_RUM_SERVICE,
  env: CODE_ENV,
  version: CODE_VERSION,
  sessionSampleRate: 100,
  sessionReplaySampleRate: 0,
  trackResources: true,
  trackUserInteractions: true,
  trackLongTask: false,
  trackFrustrations: true,
  defaultPrivacyLevel: 'mask-user-input',
  allowedTracingUrls: [/https:\/\/eddie*\.mysunpower\.com/],
  beforeSend: (event) => {
    // Exclude any URL for google analytics / launchdarkly stream / CDN assets
    if (event.resource && (event.resource.url.match('google') || event.resource.url.match('launchdarkly'))) {
      return false;
    }
    return true;
  },
  excludedActivityUrls: [
    // Exclude any URL for google analytics / launchdarkly stream / CDN assets
    (url) => {
      const parsedUrl = new URL(url);
      return [ 'google-analytics.com', 'fonts.googleapis.com', 'www.googletagmanager.com',
        'assets-dev.mysunpower.com', 'assets.mysunpower.com', 'events.launchdarkly.com',
        'app.launchdarkly.com', 'clientstream.launchdarkly.com' ].indexOf(parsedUrl.hostname) > -1;
    }
  ]
});

setOptions({
  workerSrc: '/build/pdf.worker.js'
});

function Logout() {
  global.sessionStorage.clear();
  global.localStorage.clear();
  return null;
}

let isDataLoaded = false;
function loadData(action) {
  if (isDataLoaded) {
    return;
  }

  store.dispatch(action());
  isDataLoaded = true;
}

reactGuard(React, (err, componentInfo) => {
  logger.error({
    message: err.message,
    stack: err.stack,
    componentInfo
  });
  return null;
});

try {
  createHistoryListener(document, browserHistory, store);

  ReactDOM.render(
    <AutoselectInputContent>
      <Provider store={store}>
        <Router history={browserHistory}>
          <Route path="logout" component={Logout} />
          <Route
            path="/public/" component={Page} onEnter={(nextState) => {
              if (!publicAuth(nextState)) {
                return;
              }

              const params = nextState.params;
              const { query } = nextState.location;
              store.dispatch(update('initialize', {
                isPublic: true,
                returnURL: query.returnURL
              }));

              loadData(() => fetchPublicDynamicQuote(params.quoteId, query));
            }}
          >
            <Route path="quote/:quoteId">
              <Route
                path="presentation"
                component={PresentationPage}
                onEnter={() => {
                  store.dispatch(setCurrentTabName('WHY SUNPOWER'));
                }}
              />
              <IndexRedirect to="design" />
              <Route
                path="design"
                component={PublicDesignPage}
                onEnter={() => {
                  gaTrackEvent('quotePage:designTab');
                  store.dispatch(setCurrentTabName('DESIGN'));
                }}
              />
              <Route
                path="savings"
                component={SavingsPage}
                onEnter={() => {
                  gaTrackEvent('quotePage:savingsTab');
                  store.dispatch(setCurrentTabName('SAVINGS'));
                }}
              />
              <Route
                path="proposal"
                component={ProposalContainer}
                onEnter={() => {
                  gaTrackEvent('quotePage:proposalTab');
                  store.dispatch(setCurrentTabName('PROPOSAL'));
                }}
              />
            </Route>
          </Route>
          <Route
            path="/" component={Page} onEnter={(nextState) => {
              withAuth(nextState);

              const params = nextState.params;
              const { query } = nextState.location;
              store.dispatch(update('initialize', {
                returnURL: query.returnURL
              }));

              if (params.quoteId) {
                loadData(() => fetchOrCreateDynamicQuote(params.quoteId, query));
              } else if (params.designId) {
                loadData(() => fetchOrCreateMaxfitDesign(params.designId));
              }
            }}
          >
            <Route path="design/:designId">
              <IndexRedirect to="match" />
              <Route
                path="match"
                component={MatchPage}
                onEnter={() => {
                  store.dispatch(setCurrentTabName('MATCH'));
                }}
              />
            </Route>

            <Route path="quote/:quoteId">
              <IndexRedirect to="design" />
              <Route
                path="design"
                component={NewDesignPage}
                onEnter={() => {
                  gaTrackEvent('quotePage:designTab');
                  store.dispatch(setCurrentTabName('DESIGN'));
                }}
              />
              <Route
                path="savings"
                component={SavingsPage}
                onEnter={() => {
                  gaTrackEvent('quotePage:savingsTab');
                  store.dispatch(setCurrentTabName('SAVINGS'));
                }}
              />
              <Route
                path="proposal"
                component={ProposalContainer}
                onEnter={() => {
                  gaTrackEvent('quotePage:proposalTab');
                  store.dispatch(setCurrentTabName('PROPOSAL'));
                }}
              />
            </Route>
          </Route>

          <Route
            path="/"
            component={Page}
            onEnter={(nextState) => {
              withAuth(nextState);
              const params = nextState.params;
              const { search, query } = nextState.location;
              loadData(() => fetchManualModeQuote(params.quoteId, search));
              store.dispatch(update('initialize', {
                returnURL: query.returnURL
              }));
              store.dispatch(fetchCsatFeedback(params.quoteId));
            }}
          >
            <Route path="manual_mode">
              <Route path="quote/:quoteId">
                <IndexRedirect to="design" />
                <Route
                  path="design"
                  component={ManualModeDesignPage}
                  onEnter={() => {
                    gaTrackEvent('quotePage:designTab');
                    store.dispatch(setCurrentTabName('MANUAL_DESIGN'));
                  }}
                />
                <Route
                  path="savings"
                  component={SavingsPage}
                  onEnter={() => {
                    gaTrackEvent('quotePage:savingsTab');
                    store.dispatch(setCurrentTabName('MANUAL_SAVINGS'));
                  }}
                />
                <Route
                  path="proposal"
                  component={ProposalContainer}
                  onEnter={() => {
                    gaTrackEvent('quotePage:proposalTab');
                    store.dispatch(setCurrentTabName('PROPOSAL'));
                  }}
                />
              </Route>
            </Route>
          </Route>
          <Route
            path="/"
            component={Page}
            onEnter={(nextState) => {
              withAuth(nextState);
              const params = nextState.params;
              const { search, query } = nextState.location;
              loadData(() => fetchInstantQuoteQuote(params.quoteId, search));
              store.dispatch(fetchCsatFeedback(params.quoteId));
              store.dispatch(update('initialize', {
                returnURL: query.returnURL,
                instantQuoteMode: true
              }));
            }}
          >
            <Route path="instant_quote">
              <Route path="quote/:quoteId">
                <IndexRedirect to="design" />
                <Route
                  path="design"
                  component={InstantQuoteDesignPage}
                  onEnter={() => {
                    gaTrackEvent('quotePage:designTab');
                    store.dispatch(setCurrentTabName('INSTANT_QUOTE_DESIGN'));
                  }}
                />
                <Route
                  path="savings"
                  component={SavingsPage}
                  onEnter={() => {
                    gaTrackEvent('quotePage:savingsTab');
                    store.dispatch(setCurrentTabName('INSTANT_QUOTE_SAVINGS'));
                  }}
                />
                <Route
                  path="proposal"
                  component={ProposalContainer}
                  onEnter={() => {
                    gaTrackEvent('quotePage:proposalTab');
                    store.dispatch(setCurrentTabName('INSTANT_QUOTE_PROPOSAL'));
                  }}
                />
              </Route>
            </Route>
          </Route>
          <Route
            path="/storage/quote/:quoteId"
            component={StorageQuoteContainer}
            onEnter={(nextState, replace) => {
              withAuth(nextState);
              const params = nextState.params;
              const { quoteId } = params;
              const { query } = nextState.location;
              const { cloneQuoteSFID, variant } = query;
              if (cloneQuoteSFID && variant) {
                store.dispatch(update('initialize', {
                  returnURL: query.returnURL
                }));
                replace({
                  pathname: `/storage/quote/${quoteId}/selection`
                });
              }
              loadData(() => fetchOrCreateStorageQuote(params.quoteId, query));
              store.dispatch(fetchCsatFeedback(params.quoteId));
            }}
          >
            <IndexRedirect to="home" />
            <Route
              path="home"
              component={StorageQuoteHomePage}
              onEnter={() => {
                store.dispatch(setCurrentTabName('STORAGE'));
              }}
            />
            <Route
              path="selection"
              component={StorageSelectionPageContainer}
              onEnter={() => {
                store.dispatch(setCurrentTabName('STORAGE'));
              }}
            />
            <Route
              path="proposal"
              component={StorageOnlyProposalContainer}
              onEnter={() => {
                store.dispatch(setCurrentTabName('PROPOSAL'));
              }}
            />
          </Route>
          <Route
            path="/admin-portal"
            component={AdminPortalContainer}
            onEnter={(nextState) => {
              withAdminPortalAuth(nextState);
              loadData(() => fetchAdminPortal());
              store.dispatch(update('initialize', {
              }));
            }}
          >
            <IndexRedirect to="home" />
            <Route
              path="home"
              component={AdminHomePage}
            />
          </Route>
        </Router>
      </Provider>
    </AutoselectInputContent>,
    window.root
  );
} catch (err) {
  logger.error(err);
}
