import get from 'lodash/get';
import constants from './constants';
import { isSpwrCash, isLease, isCash, isLoan, isPpa } from './helpers/financeTypes';
import { isSunpowerStorage, getStorageName } from '../sunPowerStorage';
import { partnerIsTps, partnerIsSpd, partnerChannelIsIndirect } from './helpers/account';
import { storageExpansionPackEnabled } from '../backendFeatureFlags';
import { showStorageWithExpPack } from '../StorageWithMultiInverters/helpers';
import {
  isGroundMountRackingSelected
} from './adders';
import { getEvChargerItemPrice } from '../EvChargers/evChargers';

const {
  RACKING_FEE_PER_WATT,
  DEFAULT_BASE_PRICE_PER_WATT,
  LEASE_CELL_MONITORING_COST,
  PPA_CELL_MONITORING_COST,
  DEFAULT_CELL_MONITORING_COST,
  EPW_FEE,
  EPW_MIN_WATTS,
  EPW_MAX_WATTS,
  DEFAULT_INCENTIVES_HAIRCUT_PERCENTAGE,
  LARGE_SYSTEM_DISCOUNT_SYSTEM_SIZE,
  CASH_FINANCE_TYPE
} = constants;

export const addEnhancedPerformanceWarrantyCost = (watts) => (watts >= EPW_MIN_WATTS && watts <= EPW_MAX_WATTS);

export const getMonitoringCost = (financeType, isCellularMonitoring) => {
  if (!isCellularMonitoring) return 0;
  if (isLease(financeType)) return LEASE_CELL_MONITORING_COST;
  if (isPpa(financeType)) return PPA_CELL_MONITORING_COST;
  return DEFAULT_CELL_MONITORING_COST;
};

export const getRackingFee = (systemCapacityWatts, financeType, selectedRacking) => {
  return selectedRacking.sunpower ? 0 :
  RACKING_FEE_PER_WATT[financeType] * systemCapacityWatts;
};

export const getMinPricePerWatt = (financeType, partner, finEngMinPrice) => {
  if (isSpwrCash(financeType)) return DEFAULT_BASE_PRICE_PER_WATT;
  if (!partnerIsTps(partner)) return DEFAULT_BASE_PRICE_PER_WATT;
  return get(finEngMinPrice, `${financeType}.minPricePerWatt`) || DEFAULT_BASE_PRICE_PER_WATT;
};

export const applyHaircutPercentage = (rebate) => {
  return rebate - (rebate * DEFAULT_INCENTIVES_HAIRCUT_PERCENTAGE / 100);
};

export const expansionPackStoragePrice =
(quote, storageDealerFee) => {
  return Number(storageDealerFee || 0) + Number(quote.storagePrice || 0);
};

export const getEnhancedPerformanceWarrantyCost = (enhancedPerformanceWarranty, systemCapacityWatts) => {
  return enhancedPerformanceWarranty ? (EPW_FEE * systemCapacityWatts) : 0;
};

export const getStoragePrice = ({
  storagePriceSelection,
  storagePrice,
  storageDealerFee
}, quote, selectedStorage, partnerIsRvar, partnerIsNewHomes, activeTab, selectedStorageWithExpansionPack
) => {
  if (showStorageWithExpPack(storageExpansionPackEnabled(), quote)) {
    if (partnerIsNewHomes && activeTab === 'cash' && storagePriceSelection === 'storagePrice') {
      return Number(storagePrice || 0);
    }
    return expansionPackStoragePrice(quote, storageDealerFee);
  }
  let totalStoragePrice;

  if (partnerIsNewHomes && activeTab === 'cash') {
    return storagePriceSelection === 'storagePrice' &&
    isSunpowerStorage(getStorageName(selectedStorage, selectedStorageWithExpansionPack, quote)) ?
      Number(storagePrice || 0) : 0;
  }

  switch (storagePriceSelection) {
    case 'storagePrice':
      totalStoragePrice = isSunpowerStorage(getStorageName(selectedStorage, selectedStorageWithExpansionPack, quote))
        ? 0 : Number(storagePrice || 0);
      break;
    case 'storageDealerFee':
      totalStoragePrice = isSunpowerStorage(getStorageName(selectedStorage, selectedStorageWithExpansionPack, quote))
        ? Number(storageDealerFee || 0) : 0;
      break;
    default:
      totalStoragePrice = 0;
  }
  if (isSunpowerStorage(getStorageName(selectedStorage, selectedStorageWithExpansionPack, quote)) || !partnerIsRvar) {
    totalStoragePrice += quote.storagePrice;
  }
  if (isSunpowerStorage(getStorageName(selectedStorage, selectedStorageWithExpansionPack, quote)) && !partnerIsRvar) {
    if (quote.storageQuantity === 1) {
      totalStoragePrice += quote.sunpowerStorageRelatedPrices.generalInstallationLabor;
    }
    if (quote.storageQuantity === 2 && quote.storageBackup === 'essential') {
      totalStoragePrice += quote.sunpowerStorageRelatedPrices.sunvaultStorage26kw1InverterInstallation;
    }
    if (quote.storageQuantity === 2 && quote.storageBackup === 'entireHome') {
      totalStoragePrice += quote.sunpowerStorageRelatedPrices.sunvaultStorage26kw2InverterInstallation;
    }
  }
  return totalStoragePrice;
};

export const getEvChargerPrice = ({ evChargerDealerFee }, quote, partnerIsRvar, evmChargerConfigurations) => {
  const evCharConfigs = get(quote, 'evChargerConfigs', []);
  let totalEvChargerPrice = 0;
  if (evCharConfigs.length > 0) {
    evCharConfigs.forEach((config) => {
      const itemPrice = getEvChargerItemPrice(
        config.configId,
        evmChargerConfigurations,
        quote,
        partnerIsRvar,
        config.quantity
      );
      totalEvChargerPrice += itemPrice;
    });
  }
  totalEvChargerPrice += Number(evChargerDealerFee || 0);
  return totalEvChargerPrice;
};

export const largeSystemDiscount = (quote, partner, systemCapacityWatts) => {
  if (quote && !isGroundMountRackingSelected(quote) && (partnerIsTps(partner) || partnerIsSpd(partner))) {
    if (systemCapacityWatts <= LARGE_SYSTEM_DISCOUNT_SYSTEM_SIZE) { return 0; }
    if (systemCapacityWatts < 14000) { return systemCapacityWatts * Number(0.06); }
    if (systemCapacityWatts < 18000) { return systemCapacityWatts * Number(0.09); }
    if (systemCapacityWatts < 22000) { return systemCapacityWatts * Number(0.12); }
    if (systemCapacityWatts < 26000) { return systemCapacityWatts * Number(0.15); }
    if (systemCapacityWatts < 30000) { return systemCapacityWatts * Number(0.18); }
    if (systemCapacityWatts < 34000) { return systemCapacityWatts * Number(0.21); }

    return systemCapacityWatts * Number(0.24);
  // eslint-disable-next-line no-else-return
  } else {
    return 0;
  }
};

export const getSmartPackPrice = (financeType, finEngData) => {
  return get(finEngData, `${financeType}.smartPackPrice`) || 0;
};

export const isPPIDealer = (financeType, finEngData) => {
  return get(finEngData, `${financeType}.isPpiDealer`) || false;
};

export const getCashIncentiveDiscount = (financeType, finEngData) => {
  return get(finEngData, `${financeType}.cashIncentiveDiscount`) || 0;
};

export const dealerFeePerWattCalculation = (
  dealerFeePerWattCalculationValue,
  dealerFeePerWatt,
  systemCapacityWatts,
  minPricePerWatt,
  partner,
  financeType,
  finEngData
  ) => {
  const smartPackPrice = getSmartPackPrice(financeType, finEngData);
  const cashIncentiveDiscount = getCashIncentiveDiscount(financeType, finEngData);
  const value = dealerFeePerWattCalculationValue || dealerFeePerWatt;

  if ((isLease(financeType) || isLoan(financeType) || isPpa(financeType)) && partnerChannelIsIndirect(partner)) {
    return (Number(value) + smartPackPrice - cashIncentiveDiscount) * systemCapacityWatts;
  }
  return (Number(value) + minPricePerWatt) * systemCapacityWatts;
};

export const calculateSystemPrice = ({
  pricePerWatt,
  pricePerWattCalculationValue,
  systemPriceSelection,
  grossPrice,
  dealerFeePerWatt,
  dealerFeePerWattCalculationValue,
  pricePerKwh,
  pricePerKwhCalculationValue,
  enhancedPerformanceWarranty
},
  systemCapacityWatts, financeType, selectedRacking, partner, finEngMinPrice,
  isCellularMonitoring, annualKwhProduction, finEngData
) => {
  const minPricePerWatt = getMinPricePerWatt(financeType, partner, finEngMinPrice);
  const monitoringCost = isCash(financeType) ? getMonitoringCost(financeType, isCellularMonitoring) : 0;
  const epwCost = getEnhancedPerformanceWarrantyCost(enhancedPerformanceWarranty, systemCapacityWatts);
  let value;
  switch (systemPriceSelection) {
    case 'pricePerWatt':
      value = pricePerWattCalculationValue || pricePerWatt;
      return Number(value) * systemCapacityWatts + monitoringCost + epwCost;
    case 'dealerFeePerWatt':
      return dealerFeePerWattCalculation(
          dealerFeePerWattCalculationValue,
          dealerFeePerWatt,
          systemCapacityWatts,
          minPricePerWatt,
          partner,
          financeType,
          finEngData
        );
    case 'pricePerKwh':
      return 0;
    default:
      return Number(grossPrice) + monitoringCost;
  }
};

export const calculateSystemPriceWithAdders = (...args) => {
  const settings = args[0];
  return calculateSystemPrice(...args) + Number(settings.adders || 0);
};

export const calculateSystemPriceWithAddersAndDiscounts = (...args) => {
  const settings = args[0];
  return calculateSystemPrice(...args) +
    Number(settings.adders || 0) -
    Number(settings.discount || 0);
};
