export function toggleSoComplianceCheckModal(value) {
  return {
    type: 'setSoComplianceCheckModalStatus',
    payload: {
      soDynamicProposal: {
        showComplianceCheckModal: value
      }
    }
  };
}
