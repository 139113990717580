import { asyncPayload } from './apiHelpers';
import Api from '../utils/Api';

export function generatePublicAccessToken(quoteSfid) {
  return {
    type: 'generatePublicAccessToken',
    payload: asyncPayload({
      request: Api.create(`quotes/${quoteSfid}/generate_pat`)
    })
  };
}
