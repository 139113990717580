/**
 * tryPromiseUntil will execute fn within a loop for a maximum of n times,
 * or until it satisfactorily resolves to a result that condition accepts.
 * @param {Function} fn Should return promise.
 * @param {Function} condition Evaluates promise result. Return true when
 * you want to exit loop.
 * @param {number} n Maximum number of times to retry fn.
 */
export default async (fn, condition, n) => {
  let ct = 0;
  let result;
  do {
    // eslint-disable-next-line no-await-in-loop
    result = await fn();
    ct += 1;
  } while (!condition(result) && ct < n);
  if (!condition(result)) {
    throw new Error('Promise not resolved.');
  }
  return result;
};
