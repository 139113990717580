// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._1tHB50MvaXWHh5THep53it{width:100%;height:200px;position:relative;margin:auto}\n", "",{"version":3,"sources":["webpack://scss/charts/SavingsOverTimeChart.module.scss"],"names":[],"mappings":"AAAA,yBACE,UAAW,CACX,YAAa,CACb,iBAAkB,CAClB,WAAY","sourcesContent":[".chartContainer {\n  width: 100%;\n  height: 200px;\n  position: relative;\n  margin: auto;\n\n  canvas {\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"chartContainer": "_1tHB50MvaXWHh5THep53it"
};
export default ___CSS_LOADER_EXPORT___;
