import PropTypes from 'prop-types';
import { pathType } from './path';
import { pairedModuleArrayType } from './moduleArray';

export const visualizationType = PropTypes.shape({
  roofPlanes: PropTypes.arrayOf(PropTypes.shape({
    coordinates: pathType,
    moduleArray: pairedModuleArrayType
  }))
});
