import React from 'react';
import cx from 'classnames';
import { MODE_CHANGE_SLIDER, MODE_CHANGE_PANELS, MODE_CHANGE_ARRAYS } from '../config';
import { designType, modeType } from '../types';
import { register } from '../utils/redux';

export function VisualizationLegend({ mode }) {
  const energyGradientClass = cx('energy-gradient-key', {
    invisible: (mode !== MODE_CHANGE_SLIDER && mode !== MODE_CHANGE_PANELS && mode !== MODE_CHANGE_ARRAYS)
  });
  return (
    <div id="visualization_legend">
      <div
        className={energyGradientClass}
      >
        <span className="text">More Energy</span>
        <img alt="low to high energy" src={'/build/images/low-to-high-energy-visualization.svg'} />
        <span className="text">Less Energy</span>
      </div>
    </div>
  );
}

VisualizationLegend.propTypes = {
  design: designType.isRequired,
  mode: modeType
};

export default register(
  [ 'designSelector', 'modeSelector' ],
  [],
  VisualizationLegend
);
