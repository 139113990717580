import React from 'react';
import PropTypes from 'prop-types';
import { register } from '../../utils/redux';
import { formatCurrency } from '../../utils/formatNumber';
import LineItem from './LineItem';
import ValueBox from '../ValueBox';
import {
  spwrCashSystemCostType,
  totalSavingsChartType
} from '../../types';

// TODO: This is very messy. We need to move savings calculations to backend
// and cleanup these data structures.
export function SpwrCashView({
  spwrCashSystemCost,
  pricingDisclaimer
}) {
  // NOTE: It is save to use an arbitrary savings key here for solarSavings values
  // on TotalChartService.
  const systemPrice = spwrCashSystemCost.totalContractPrice;
  const netCost = systemPrice - spwrCashSystemCost.credit;

  return (
    <div className="cash">
      <div className="breakout-summary">
        <ValueBox
          label="Net cost"
          currency={true}
          rounded={true}
          value={netCost}
        />
        <span className="breakout-summary-breakdown supporting-text">
          {
            `After ${formatCurrency(systemPrice, 0)} upfront cost, collect
            ${formatCurrency(spwrCashSystemCost.credit, 0)} in incentives and tax
            credits * (if qualified)`
          }
        </span>
      </div>
      <div className="breakout-line-items">
        <LineItem label="System price" value={systemPrice} rounded={true} />
        { spwrCashSystemCost.stateAndLocalIncentives > 0 ?
          <LineItem
            label="State & local incentives (est.)*"
            value={spwrCashSystemCost.stateAndLocalIncentives} sign="-"
          /> :
          null }
        <LineItem label="Federal tax credit (est.)*" value={spwrCashSystemCost.federalTaxCredit} sign="-" />
        { spwrCashSystemCost.stateTaxCredit > 0 ?
          <LineItem label="State tax credit (est.)*" value={spwrCashSystemCost.stateTaxCredit} sign="-" /> :
          null }
        <hr />
        <LineItem label="Net cost (est.)" value={netCost} />
      </div>
      { pricingDisclaimer }
    </div>
  );
}

SpwrCashView.propTypes = {
  pricingDisclaimer: PropTypes.element,
  buttonLabel: PropTypes.string,
  typeKey: PropTypes.string,
  spwrCashSystemCost: spwrCashSystemCostType.isRequired,
  totalSavingsChart: totalSavingsChartType.isRequired
};

export default register(
  [
    'spwrCashSystemCostSelector', 'savingsSelector', 'quoteSelector', 'totalSavingsChartSelector'
  ],
  [],
  SpwrCashView
);
