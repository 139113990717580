import PropTypes from 'prop-types';

export const proposalType = PropTypes.shape({
  pdfFilename: PropTypes.string.isRequired,
  docxFilename: PropTypes.string.isRequired,
  toolbar: PropTypes.shape({
    activeItemId: PropTypes.string
  }),
  signedName: PropTypes.string,
  pdfUrl: PropTypes.string,
  pdfDataUrl: PropTypes.string
});
