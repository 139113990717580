import createSelector from '../utils/createSelector';
import { pvModuleSelected } from './pvModule';

function dcModuleSelected({ quote, pvModules }) {
  const { pvModule } = pvModuleSelected({ quote, pvModules });
  return {
    dcModule: pvModule ? pvModule.dc : true
  };
}

export default createSelector(
  dcModuleSelected
);

