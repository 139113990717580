import { asyncPayload } from '../apiHelpers';
import Api from '../../utils/Api';

export function updateSoEvChargerConfig(storageQuote, params) {
  return {
    type: 'updateSoEvChargerConfig',
    payload: asyncPayload({
      request: Api.update(`storage/quotes/${storageQuote.sfid}/ev_charger_configs`, {
        ev_charger_configs: params
      }).then((result) => {
        return {
          storageQuote: result.storageQuote
        };
      })
    })
  };
}
