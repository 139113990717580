import { cloneDeep } from 'lodash';

/**
 *
 * @param {string} financeType
 * @param {object} key
 * @param {object} val
 * @returns updated state
 */

function updateObject(obj, val) {
  if (obj) {
    const keys = Object.keys(val);
    keys.forEach((key) => { obj[key] = val[key]; }
    );
  }
  return obj;
}

export const updateApCustomAdders = (financeType, key, val) => (dispatch, getState) => {
  const currentState = getState();
  const apFinConfigsToUpdate = cloneDeep(currentState.apFinConfigs);
  const finConfigIndex = apFinConfigsToUpdate
    .map((config) => config.finType.toLowerCase()).indexOf(financeType.toLowerCase());

  if (finConfigIndex > -1) {
    const customAddersAttributes = apFinConfigsToUpdate[finConfigIndex].customAddersAttributes;
    if (customAddersAttributes) {
      if (key === null) {
        customAddersAttributes.push(val);
      } else {
        const oldObject = customAddersAttributes.find((adder) => adder.id === key || adder.adderId === key);
        if (val === '') {
          customAddersAttributes.splice(customAddersAttributes.indexOf(oldObject), 1);
        } else {
          updateObject(oldObject, val);
        }
      }
    } else {
      apFinConfigsToUpdate[finConfigIndex].customAddersAttributes = {
        [key]: val
      };
    }
  }

  dispatch({
    type: 'updateApCustomAdders',
    payload: {
      apFinConfigs: apFinConfigsToUpdate
    }
  });
};
