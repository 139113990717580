import { cloneDeep } from 'lodash';
import { findApFinConfigIndexById } from '../../utils/admin_portal/updateHelpers';

/**
 *
 * @param {string} financeType
 * @param {string} product
 * @param {object} key
 * @param {object} val
 * @returns updated state
 */
export const updateApProduct = (configId, product, key, val) => (dispatch, getState) => {
  const currentState = getState();
  const apFinConfigsToUpdate = cloneDeep(currentState.apFinConfigs);
  const finConfigIndex = findApFinConfigIndexById(apFinConfigsToUpdate, configId);
  const productIndex = apFinConfigsToUpdate[finConfigIndex].productConfigsAttributes
    .map((config) => config.product).indexOf(product);

  if (productIndex > -1) {
    apFinConfigsToUpdate[finConfigIndex].productConfigsAttributes[productIndex][key] = val;
  } else {
    apFinConfigsToUpdate[finConfigIndex].productConfigsAttributes = [
      ...apFinConfigsToUpdate[finConfigIndex].productConfigsAttributes,
      {
        product,
        [key]: val
      }
    ];
  }

  dispatch({
    type: 'updateApProduct',
    payload: {
      apFinConfigs: apFinConfigsToUpdate
    }
  });
};
