import React from 'react';
import PropTypes from 'prop-types';
import { formatCurrency } from '../utils/formatNumber';

export default function CurrencyInput({ id = null, readOnly = false,
rounded = false, value, onChange, onBlur = null, onFocus, positive = false, disabled = false }) {
  const props = id === null ? {} : { id };
  const roundValue = id === 'lease_settings_price_per_kwh' ? 3 : 2;
  const handleOnChange = (value) => {
    let newValue = value.toString();
    if (positive) newValue = newValue.replace(/[^\d.]/g, '');
    newValue = newValue.replace(/^0/, '');
    onChange(newValue || 0);
  };
  return readOnly ? (
    <input
      className="read-only"
      type="text"
      value={Number.isNaN(value) || value === null || value === '' ?
        'N/A' : formatCurrency(value, rounded ? 0 : roundValue)}
      readOnly={true}
      {...props}
    />
  ) : (
    <input
      type="text"
      value={value}
      onChange={(e) => onChange && handleOnChange(e.target.value)}
      onBlur={(e) => onBlur && onBlur(e.target.value)}
      onFocus={onFocus}
      disabled={disabled}
      {...props}
    />
  );
}

CurrencyInput.propTypes = {
  id: PropTypes.string,
  readOnly: PropTypes.bool,
  rounded: PropTypes.bool,
  value: PropTypes.oneOfType([ PropTypes.number, PropTypes.string ]),
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  positive: PropTypes.bool,
  disabled: PropTypes.bool
};
