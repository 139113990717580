import cx from 'classnames';
import _ from 'lodash';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import ReactTooltip from 'react-tooltip';
import { partnerIsTpsSelector } from '../selectors';
import {
  accountType,
  businessFunctionsType,
  designType,
  energyDataType,
  errorType,
  newHomesDetailsType,
  notificationsType,
  productConfigurationType,
  pvModuleType,
  quoteType,
  selectedStorageType,
  selectedStorageWithExpansionPackType,
  settingsType
} from '../types';
import constants from '../utils/EddieCalculations/constants';
import { addEnhancedPerformanceWarrantyCost, applyHaircutPercentage } from '../utils/EddieCalculations/finance';
import { isCash, isLease, isPpa } from '../utils/EddieCalculations/helpers/financeTypes';
import { showEvCharger } from '../utils/EvChargers/evChargers';
import { showStorageWithExpPack } from '../utils/StorageWithMultiInverters/helpers';
import {
  invalidateAdderNameAndPrice,
  optionalAdders
} from '../utils/admin_portal/quoteHelpers';
import { enableCustomAdders, pricePerKwhEnabled, storageExpansionPackEnabled } from '../utils/backendFeatureFlags';
import { formatNumber } from '../utils/formatNumber';
import getAdders from '../utils/getAdders';
import { gaTrackEvent } from '../utils/googleAnalytics';
import { register } from '../utils/redux';
import { getStorageName, isSunpowerStorage } from '../utils/sunPowerStorage';
import { validateModuleAndFinanceOption } from '../utils/validations/validateSettings';
import FinanceTabs from './FinanceTabs';
import LineInput from './LineInput';
import LineRadioInput from './LineRadioInput';
import Modal from './Modal';
import ShareableLinkSection from './settings_modal/ShareableLinkSection';
import ShowCustomerCheckbox from './settings_modal/ShowCustomerCheckbox';

const {
  LOAN_FINANCE_TYPE,
  LEASE_FINANCE_TYPE,
  CASH_FINANCE_TYPE,
  PPA_FINANCE_TYPE
} = constants;

const CT_MA_BUY_ALL_ID = [ '3398759', '3394817', '3398760', '3398788', '3398787',
  '2610', '3290446', '3385609', '81002', '3287514', '81192', '3318181' ];

/* eslint no-underscore-dangle: 0 */
export function SettingsModal({
  account,
  businessFunctions,
  closeModalAndRevertState,
  credit,
  currentTabName,
  design,
  energyData,
  error,
  fetchFinEng,
  isApplyHaircutState,
  isPartnerEnabledForAnyStorage,
  isPublic,
  manualMode,
  newHomesDetails,
  notifications,
  partnerIsNewHomes,
  partnerIsRvar,
  partnerIsSpd,
  partnerIsTps,
  partnerSectorLeaseIsNewHomes,
  productConfiguration,
  pvModule,
  pvModules,
  quote,
  selectedStorage,
  selectedStorageWithExpansionPack,
  setError,
  settings,
  showDealerFeePerWatt,
  systemCapacityWatts,
  toggleSettingsTab,
  updateDirtySettings,
  updateOptionalAddersSelection,
  updateQuoteName,
  updateSettings,
  validateAndSaveSettingsModal
}) {
  const partnerIsDirect = partnerIsTpsSelector({ account }).partnerIsTps;
  const { activeTab } = settings;
  const { monitoringCost, systemPriceSelection, systemPrice } = settings[activeTab];
  const financeTypes = Object.keys(productConfiguration.financialOfferings);
  const isSavingsPage = ([ 'MANUAL_SAVINGS', 'SAVINGS' ].includes(currentTabName));
  const [ dealerFee, setDealerFee ] = useState(settings[activeTab].storageDealerFee);
  const [ nhMortgageStorageGrossPrice, setNHMortgageStorageGrossPrice ] = useState(settings[activeTab].storagePrice);
  const [ isDirty, setIsDirty ] = useState(false);
  const [ evChargerInputSelected, setEvChargerInputSelected ] = useState(!!settings[activeTab].evChargerDealerFee);
  const optionalAddersFromAp = optionalAdders(
    activeTab, quote.adminFinConfigs,
    settings[activeTab].optionalAddersAttributes);
  const oldAdderPrice = settings[activeTab].oldAdderPrice;
  const [ adHocAdder, setAdHocAdder ] = useState(settings[activeTab].optionalAddersAttributes || []);
  const selectedOptionalAdderNames = (settings[activeTab].optionalAddersAttributes || [])
    .filter((optionalAdder) => !optionalAdder._destroy && optionalAdder.isLive).map((adder) => adder.adderName);
  const availableFinanceTabsForInstantQuote = {
    cash: true,
    lease: true,
    loan: true,
    spwrCash: false,
    ppa: true
  };

  useEffect(() => {
    const adhocdAdders = (settings[activeTab].optionalAddersAttributes || []).filter(
      (optionalAdder) => optionalAdder.adderType === 'adhoc' && !optionalAdder._destroy);
    setAdHocAdder(adhocdAdders);
  }, [ activeTab, settings[activeTab].optionalAddersAttributes ]);

  const toggleOptionalAdderSelection = (val, adderName) => {
    setIsDirty(true);
    updateOptionalAddersSelection(
      settings[activeTab],
      activeTab,
      adderName,
      'isLive',
      val,
      oldAdderPrice,
      optionalAddersFromAp
    );
  };

  const handleValueChange = (key, value, adderId) => {
    if (!invalidateAdderNameAndPrice(key, value)) {
      setIsDirty(true);
      updateSettings(activeTab, 'userInput', true);
      updateOptionalAddersSelection(
        settings[activeTab],
        activeTab,
        adderId,
        key,
        value,
        oldAdderPrice
      );
    }
  };

  const getAvailableFinanceTabsForInstantQuote = (productConfiguration) => {
    return Object.keys(availableFinanceTabsForInstantQuote).reduce((a, key) => {
      a[key] = availableFinanceTabsForInstantQuote[key] && productConfiguration.financialOfferings[key];
      return a;
    }, {});
  };
  const { upfrontRebate, specialRebate } = energyData.incentives[activeTab] || {};
  const upfrontRebateEstimateAmount = upfrontRebate && upfrontRebate.amount
  ? Math.floor(upfrontRebate.amount * 100) / 100
  : null;
  const specialRebateEstimateAmount = specialRebate && specialRebate.amount
  ? Math.floor(specialRebate.amount * 100) / 100
  : null;

  // set rebateEstimate to null when not returned (no value); needs to be distinct from returning 0
  let rebateEstimate;
  if (upfrontRebateEstimateAmount && specialRebateEstimateAmount) {
    rebateEstimate = upfrontRebateEstimateAmount + specialRebateEstimateAmount;
  } else if (upfrontRebateEstimateAmount || specialRebateEstimateAmount) {
    rebateEstimate = specialRebateEstimateAmount || upfrontRebateEstimateAmount;
  } else {
    rebateEstimate = null;
  }
  const applyHaircut = isApplyHaircutState && (activeTab === CASH_FINANCE_TYPE || activeTab === LOAN_FINANCE_TYPE);
  if (rebateEstimate && applyHaircut) rebateEstimate = applyHaircutPercentage(rebateEstimate);

  const updatePrice = (newValue) => {
    setIsDirty(true);
    if (settings[activeTab].isChanging && activeTab === CASH_FINANCE_TYPE) {
      if (systemPriceSelection === 'pricePerWatt') {
        updateSettings(activeTab, 'pricePerWatt', newValue);
        updateSettings(activeTab, 'pricePerWattCalculationValue', newValue);
        const pricePerWattWithMonitoringCost = systemCapacityWatts > 0 ?
         ((Number(newValue) * systemCapacityWatts + monitoringCost) / systemCapacityWatts)
         : Number(newValue);
        updateSettings(activeTab, 'pricePerWattWithMonitoringCost', pricePerWattWithMonitoringCost.toFixed(2));
      }
      if (systemPriceSelection === 'grossPrice') {
        updateSettings(activeTab, 'grossPrice', newValue);
        const grossPriceWithMonitoringCost = Number(newValue) + Number(monitoringCost);
        updateSettings(activeTab, 'grossPriceWithMonitoringCost', grossPriceWithMonitoringCost.toFixed());
      }
    }
    updateSettings(activeTab, 'isChanging', false);
  };

  const onCheckboxChange = (event) => {
    setIsDirty(true);
    if (error) {
      const newFieldErrors = { ...error.fieldErrors, [activeTab]: null };
      setError({ ...error, fieldErrors: newFieldErrors });
    }

    updateSettings(activeTab, 'userInput', true);
    updateSettings(activeTab, 'showCustomer', event.target.checked);
  };

  const stateIsHI = () => {
    return account.state === 'HI';
  };

  const errorPricePerWatt = get(error, `fieldErrors.${activeTab}.pricePerWatt`, null);
  const errorDealerFeePerWatt = get(error, `fieldErrors.${activeTab}.dealerFeePerWatt`, null);
  const errorPricePerKwh = get(error, `fieldErrors.${activeTab}.pricePerKwh`, null);
  const errorGrossPrice = get(error, `fieldErrors.${activeTab}.grossPrice`, null);
  const showSPWRCash = !manualMode || quote.locked && (quote.selectedFinanceOption === 'spwrCash');
  const showGrossPrice = !get(account, 'features.dealerFeePerWatt', false) || isCash(activeTab);
  const showPricePerKhw = (isLease(activeTab) || isPpa(activeTab)) &&
    !partnerSectorLeaseIsNewHomes && pricePerKwhEnabled() && !stateIsHI() &&
    !partnerIsTps && !partnerIsRvar;
  const showShareableLink = !manualMode && account.features.eddieJr && !quote.locked;

  const selectedPvModule = pvModules.find((pvModule) => pvModule.itemId === quote.pvModuleItemId);

  let storages;
  if (showStorageWithExpPack(storageExpansionPackEnabled(), quote)) {
    storages = selectedPvModule && selectedPvModule.storageConfigurations ? [...new Set(
      selectedPvModule.storageConfigurations.map((s) => {
        return { storageConfigId: s.storageConfigId, storageName: s.storageName };
      })
    )] : [];
  } else {
    storages = selectedPvModule && selectedPvModule.storageUnits ? [...new Set(
      selectedPvModule.storageUnits.map((s) => {
        return { id: s.id, name: s.name };
      })
    )] : [];
  }
  let storageEnabled = businessFunctions && businessFunctions.includes('Storage Enabled')
    && isPartnerEnabledForAnyStorage;
  // rvar == Indirect
  // Spd == Direct
  // Newhomes is always direct
  let showStorageOptions =
    (activeTab === CASH_FINANCE_TYPE || activeTab === LOAN_FINANCE_TYPE || activeTab === LEASE_FINANCE_TYPE
      || activeTab === PPA_FINANCE_TYPE) &&
    storageEnabled && storages.length > 0 && (partnerIsRvar || partnerIsTps || partnerIsSpd);

  let evChargerOfferings = _.flatten(get(selectedPvModule, 'evmChargerConfigurations', []).map((c) => c.offerings));
  evChargerOfferings = Array.from(new Set(evChargerOfferings));
  const showEvChargerOptions = showEvCharger(partnerIsNewHomes, quote, businessFunctions)
    && (evChargerOfferings || []).map((offering) =>
    offering.toLowerCase()).includes(activeTab.toLowerCase());

  const isNHStorageAllowed = get(newHomesDetails, 'storageAllowed');

  if (activeTab === CASH_FINANCE_TYPE && partnerIsNewHomes && isNHStorageAllowed) {
    storageEnabled = isNHStorageAllowed;
    showStorageOptions = true;
  }

  const nhStorageGrossPriceDisabled = quote.storageQuantity > 0 &&
    !isSunpowerStorage(getStorageName(selectedStorage, selectedStorageWithExpansionPack, quote));

  let pricePerWattValue;
  let grossPriceValue;
  let pricePerWattSelector;
  let grossPriceSelector;
  let systemPriceTooltip = '';

  if (isCash(activeTab)) {
    pricePerWattValue = settings[activeTab].pricePerWattWithMonitoringCost;
    grossPriceValue = settings[activeTab].grossPriceWithMonitoringCost;
    pricePerWattSelector = 'pricePerWattWithMonitoringCost';
    grossPriceSelector = 'grossPriceWithMonitoringCost';
  } else {
    pricePerWattValue = settings[activeTab].pricePerWatt;
    grossPriceValue = settings[activeTab].grossPrice;
    pricePerWattSelector = 'pricePerWatt';
    grossPriceSelector = 'grossPrice';
    systemPriceTooltip = 'Note: this System Price may not match the final contract price to the customer.<br />' +
    'It is simply a placeholder value used to indicate the desired fee for this quote.';
  }

  const systemAdders = getAdders(account, notifications);
  const systemAddersTotalCost = get(systemAdders, 'totalCost', 0);
  let sumTotal;

  const lease = financeTypes.includes('lease') && settings.lease;
  const ppa = financeTypes.includes('ppa') && settings.ppa;

  const needsFinEng = settings[activeTab].needsFinEng;
  const finEngData = quote.finEng || {};
  const finEngHasLease = !needsFinEng || !isEmpty(finEngData) && finEngData.lease;
  const finEngHasPpa = !needsFinEng || !isEmpty(finEngData) && finEngData.ppa;

  const leaseKwhSelected = lease && lease.showCustomer && lease.systemPriceSelection === 'pricePerKwh';
  const ppaKwhSelected = ppa && ppa.showCustomer && ppa.systemPriceSelection === 'pricePerKwh';
  const leaseWattPrice = lease && !!lease.pricePerWatt;
  const ppaWattPrice = ppa && !!ppa.pricePerWatt;
  const leaseKwhCheck = !leaseKwhSelected || leaseWattPrice;
  const ppaKwhCheck = !ppaKwhSelected || ppaWattPrice;

  /*
  * TODO
  * Refactor this code as it is duplicate of sumTotal on validateSystemPrice.js.
  * Move adding systemAddersTotalCost to sumTotal logic to settings.js. It requires updating many tests.
  */
  if (!needsFinEng && ((leaseKwhSelected && finEngHasLease) || (ppaKwhSelected && finEngHasPpa))) {
    sumTotal = settings[activeTab].sumTotal;
  } else {
    sumTotal = settings[activeTab].sumTotal + systemAddersTotalCost;
  }

  const saveDisabled = () => {
    if (isLease(activeTab) && lease && lease.systemPriceSelection === 'pricePerKwh') {
      return !ppaKwhCheck || !leaseWattPrice;
    }
    if (isPpa(activeTab) && ppa && ppa.systemPriceSelection === 'pricePerKwh') {
      return !leaseKwhCheck || !ppaWattPrice;
    }
    return !(leaseKwhCheck && ppaKwhCheck);
  };

  const updateDealerFee = (dealerFee) => {
    setIsDirty(true);
    setDealerFee(dealerFee);
    updateSettings(activeTab, 'storageDealerFee', dealerFee);
  };

  const updateEvChargerDealerFee = (evChargerDealerFee) => {
    setIsDirty(true);
    updateSettings(activeTab, 'evChargerDealerFee', evChargerDealerFee);
  };

  const isThereSmartPackPrice = () => {
    if (activeTab === LOAN_FINANCE_TYPE && !isEmpty(finEngData) && finEngData.loan && finEngData.loan.smartPackPrice) {
      return true;
    }
    if ((activeTab === LEASE_FINANCE_TYPE)) {
      return get(finEngData, 'lease.smartPackPrice');
    }
    if (activeTab === PPA_FINANCE_TYPE) {
      return get(finEngData, 'ppa.smartPackPrice');
    }
    return false;
  };


  const getSystemPrice = () => {
    if ((activeTab === LOAN_FINANCE_TYPE || activeTab === LEASE_FINANCE_TYPE || activeTab === PPA_FINANCE_TYPE) &&
    partnerIsRvar &&
    !isThereSmartPackPrice() &&
    systemPriceSelection === 'dealerFeePerWatt') {
      return '';
    }
    return systemPrice;
  };

  const updateNHMortgageStorageGrossPrice = (price) => {
    setIsDirty(true);
    setNHMortgageStorageGrossPrice(price);
    updateSettings(activeTab, 'userInput', true);
    updateSettings(activeTab, 'storagePrice', price);
  };

  const showStoragePrice = () => {
    return settings[activeTab].storageTotal;
  };

  const showEvChargerPrice = () => {
    return settings[activeTab].evChargerTotal;
  };

  const showSumTotal = () => {
    if ((activeTab === LOAN_FINANCE_TYPE || activeTab === LEASE_FINANCE_TYPE || activeTab === PPA_FINANCE_TYPE) &&
    partnerIsRvar &&
    !isThereSmartPackPrice() &&
    systemPriceSelection === 'dealerFeePerWatt') {
      return '';
    }
    if (partnerIsNewHomes && activeTab === CASH_FINANCE_TYPE) {
      return sumTotal;
    }
    if (partnerIsRvar || partnerIsTps) {
      return sumTotal;
    }
    if (partnerIsSpd) {
      return sumTotal;
    }
    return sumTotal;
  };

  let disabled = false;

  if (activeTab === LOAN_FINANCE_TYPE) {
    const watts = parseInt(selectedPvModule.item.summaryDescription.replace(/\D/g, ''), 10);
    disabled = !addEnhancedPerformanceWarrantyCost(watts) || quote.locked;
  }

  // misc
  const runButtonClass = `price-per-kwh-run \
btn \
btn-primary \
btn--small \
${(settings[activeTab].fetchingFinEng ||
  design.enabledArrayCount === 0 ||
  systemPriceSelection !== 'pricePerKwh' ||
  Number(settings[activeTab].pricePerKwh) <= 0) ? 'disabled' : ''}`;

  useEffect(() => {
    if (settings.lease.leaseChanged) {
      updateSettings(LEASE_FINANCE_TYPE, 'leaseChanged', false);
    } else if (settings.ppa && settings.ppa.ppaChanged) {
      updateSettings(PPA_FINANCE_TYPE, 'ppaChanged', false);
    }
  }, []);

  useEffect(() => {
    if (oldAdderPrice > 0 && settings[activeTab].adders === 0) {
      updateOptionalAddersSelection(
        settings[activeTab],
        activeTab,
        '',
        '',
        '',
        oldAdderPrice
      );
    }
  }, [activeTab]);

  useEffect(() => {
    setDealerFee(settings[activeTab].storageDealerFee);
    if ((activeTab === LEASE_FINANCE_TYPE || activeTab === PPA_FINANCE_TYPE) && settings[activeTab].pricePerKwh > 0) {
      updateSettings(activeTab, 'pricePerKwh', Number(settings[activeTab].pricePerKwh).toFixed(3));
    }
  }, [activeTab]);

  useEffect(() => {
    if ((activeTab === LEASE_FINANCE_TYPE || activeTab === PPA_FINANCE_TYPE) &&
      account && (account.state === 'CT' || account.state === 'MA') &&
      CT_MA_BUY_ALL_ID.includes(account.proposedRate)) {
      updateSettings(activeTab, 'showCustomer', false);
    }
  }, []);

  useEffect(() => {
    if (systemPriceSelection === 'grossPrice') {
      updateSettings(activeTab, 'pricePerWatt', '');
      updateSettings(activeTab, 'pricePerWattWithMonitoringCost', '');
      updateSettings(activeTab, 'pricePerWattCalculationValue', '');
      if (activeTab === LEASE_FINANCE_TYPE || activeTab === PPA_FINANCE_TYPE) {
        updateSettings(activeTab, 'pricePerKwh', '');
        updateSettings(activeTab, 'pricePerKwhCalculationValue', '');
      }
    }

    if (systemPriceSelection === 'pricePerKwh') {
      if (showGrossPrice) {
        updateSettings(activeTab, 'grossPrice', '');
        updateSettings(activeTab, 'grossPriceWithMonitoringCost', '');
      } else {
        updateSettings(activeTab, 'dealerFeePerWatt', '');
        updateSettings(activeTab, 'dealerFeePerWattCalculationValue', '');
      }
      if (partnerIsTps || partnerIsRvar) {
        updateSettings(activeTab, 'systemPriceSelection', 'pricePerWatt');
      } else {
        updateSettings(activeTab, 'pricePerWatt', '');
        updateSettings(activeTab, 'pricePerWattWithMonitoringCost', '');
        updateSettings(activeTab, 'pricePerWattCalculationValue', '');
      }
    }

    if (systemPriceSelection === 'pricePerWatt') {
      if (showGrossPrice) {
        updateSettings(activeTab, 'grossPrice', '');
        updateSettings(activeTab, 'grossPriceWithMonitoringCost', '');
      } else {
        updateSettings(activeTab, 'dealerFeePerWatt', '');
        updateSettings(activeTab, 'dealerFeePerWattCalculationValue', '');
      }
      if (activeTab === LEASE_FINANCE_TYPE || activeTab === PPA_FINANCE_TYPE) {
        updateSettings(activeTab, 'pricePerKwh', '');
        updateSettings(activeTab, 'pricePerKwhCalculationValue', '');
      }
    }

    if (systemPriceSelection === 'dealerFeePerWatt') {
      updateSettings(activeTab, 'pricePerWatt', '');
      updateSettings(activeTab, 'pricePerWattWithMonitoringCost', '');
      updateSettings(activeTab, 'pricePerWattCalculationValue', '');
      if (activeTab === LEASE_FINANCE_TYPE || activeTab === PPA_FINANCE_TYPE) {
        updateSettings(activeTab, 'pricePerKwh', '');
        updateSettings(activeTab, 'pricePerKwhCalculationValue', '');
      }
    }
  }, [settings]);

  const saveSettings = (evt) => {
    updateDirtySettings(isDirty);
    if (isDirty) {
      validateAndSaveSettingsModal(evt.target.parentElement);
    } else {
      closeModalAndRevertState();
    }
  };

  const onPricePerKhwChange = (newValue) => {
    setIsDirty(true);
    updateSettings(activeTab, 'pricePerKwh', newValue);
    updateSettings(activeTab, 'pricePerKwhCalculationValue', newValue);
    if (activeTab === LEASE_FINANCE_TYPE) {
      updateSettings(activeTab, 'leaseChanged', activeTab === LEASE_FINANCE_TYPE);
    } else if (activeTab === PPA_FINANCE_TYPE) {
      updateSettings(activeTab, 'ppaChanged', activeTab === PPA_FINANCE_TYPE);
    }
  };

  const onPricePerWattChange = (newValue) => {
    setIsDirty(true);
    updateSettings(activeTab, 'isChanging', true);
    updateSettings(activeTab, pricePerWattSelector, newValue);
    updateSettings(activeTab, 'pricePerWattCalculationValue', newValue);
    if (activeTab === LEASE_FINANCE_TYPE) {
      updateSettings(activeTab, 'leaseChanged', activeTab === LEASE_FINANCE_TYPE);
    } else if (activeTab === PPA_FINANCE_TYPE) {
      updateSettings(activeTab, 'ppaChanged', activeTab === PPA_FINANCE_TYPE);
    }
  };

  const onDealerFeePerWattChange = (newValue) => {
    setIsDirty(true);
    updateSettings(activeTab, 'dealerFeePerWatt', newValue);
    updateSettings(activeTab, 'dealerFeePerWattCalculationValue', newValue);
    if (activeTab === LEASE_FINANCE_TYPE) {
      updateSettings(activeTab, 'leaseChanged', activeTab === LEASE_FINANCE_TYPE);
    } else if (activeTab === PPA_FINANCE_TYPE) {
      updateSettings(activeTab, 'ppaChanged', activeTab === PPA_FINANCE_TYPE);
    }
  };

  const onGrossPriceChange = (newValue) => {
    setIsDirty(true);
    updateSettings(activeTab, 'isChanging', true);
    updateSettings(activeTab, grossPriceSelector, newValue);
    if (activeTab === LEASE_FINANCE_TYPE) {
      updateSettings(activeTab, 'leaseChanged', activeTab === LEASE_FINANCE_TYPE);
    } else if (activeTab === PPA_FINANCE_TYPE) {
      updateSettings(activeTab, 'ppaChanged', activeTab === PPA_FINANCE_TYPE);
    }
  };

  const creditCheckDeclined = (credit, activeTab) => {
    const foType = `${activeTab}IsDeclined`;
    if (credit[foType] &&
        (activeTab === LEASE_FINANCE_TYPE || activeTab === PPA_FINANCE_TYPE || activeTab === LOAN_FINANCE_TYPE)) {
      updateSettings(activeTab, 'showCustomer', false);
      const availableFinancialOptions = quote.adminFinConfigs;
      for (let i = 0; i < availableFinancialOptions.length; i++) {
        if (!credit[`${availableFinancialOptions[i].finType}IsDeclined`]) {
          updateSettings(availableFinancialOptions[i].finType, 'showCustomer', true);
        }
      }
      return true;
    }
    return false;
  };

  const isCTMABuyAll = (activeTab) => {
    if (account && (account.state === 'CT' || account.state === 'MA') &&
      CT_MA_BUY_ALL_ID.includes(account.proposedRate)) {
      updateSettings(LEASE_FINANCE_TYPE, 'showCustomer', false);
      updateSettings(PPA_FINANCE_TYPE, 'showCustomer', false);
      return [ 'lease', 'ppa' ].includes(activeTab);
    }
    return false;
  };

  const validateAndFetchFinEng = (quote, design, setting, systemAdders, activeTab) => {
    const message = validateModuleAndFinanceOption(pvModule, activeTab);
    if (message) {
      setError({ message });
      return;
    }
    fetchFinEng(quote, design, setting, systemAdders, activeTab);
  };

  const addCustomAdder = () => {
    setAdHocAdder([
      ...adHocAdder,
      {
        adderId: `Adhoc${Math.floor(Math.random() * 9000)}`,
        adderName: '',
        adderPrice: 0,
        adderType: 'adhoc',
        financialOption: activeTab,
        isLive: false,
        status: 'new'
      }
    ]);
  };

  const isPartnerNH = partnerIsNewHomes && activeTab === CASH_FINANCE_TYPE;

  return (
    <Modal
      name="settings"
      title="Quote Settings"
      closeModalAfterOnSave={false}
      onSave={(evt) => saveSettings(evt)}
      controller={settings[activeTab].fetchingFinEngController}
      saveDisabled={saveDisabled}
    >
      <div className="container-overflow">

        { showShareableLink && (
          <div className="form-group labeled">
            <label>Shareable link</label>
            <ShareableLinkSection />
          </div>
        )}
        <div className="form-group labeled">
          <label>Quote Name</label>
          <div className="input-container">
            {quote.locked ? (
              <input
                name="quote-name"
                className="read-only"
                type="text"
                value={quote.name ? quote.name : '-'}
                readOnly={true}
              />
            ) : (
              <input
                name="quote-name"
                type="text"
                value={quote.name}
                onChange={(e) => {
                  setIsDirty(true);
                  updateQuoteName(e.target.value);
                }}
              />
            )}
          </div>
        </div>

        <div className="tabs">
          <FinanceTabs
            activeTab={activeTab}
            toggle={toggleSettingsTab}
            showSpwrCash={showSPWRCash}
            availableTabs={
              quote.isInstantQuote ? getAvailableFinanceTabsForInstantQuote(productConfiguration)
                : productConfiguration.financialOfferings
            }
            isPublic={isPublic}
          />
          <div className="tabs__content-container">
            <div className="tabs__content">
              <div className="tabs__content-item tabs__content-item--active">
                <form className="settings-modal-form">
                  { (creditCheckDeclined(credit, activeTab) || isCTMABuyAll(activeTab)) ?
                    (isCTMABuyAll(activeTab) ? (<p className="credit-check-fail-message">
                      {account && account.state && account.state === 'CT' &&
                      `CT Buy-All Financial Product Restriction. ${activeTab.toUpperCase()} is unavailable.`}
                      {account && account.state && account.state === 'MA' &&
                      `MA R-2 Financial Product Restriction. ${activeTab.toUpperCase()} is unavailable.`}
                    </p>) : (<p className="credit-check-fail-message">
                      Customer credit check failed. {activeTab.toUpperCase()} quote unavailable.
                     </p>)) :
                     (<ShowCustomerCheckbox
                       isSavingsPage={isSavingsPage}
                       isQuoteLocked={quote.locked}
                       financeTypes={financeTypes}
                       activeSettingsTab={settings[activeTab]}
                       settings={settings}
                       onChange={onCheckboxChange}
                     />) }
                  <div className="row settings-row">
                    <div className="price-customizations">
                      <LineInput
                        id={`${activeTab}_settings_system_price`}
                        label="System Price"
                        value={settings[activeTab].systemPriceSelection !== 'pricePerKwh' ? getSystemPrice() : ''}
                        rounded={true}
                        readOnly={true}
                        tooltip={systemPriceTooltip}
                        disabled={creditCheckDeclined(credit, activeTab) || isCTMABuyAll(activeTab)}
                      />
                      { storageEnabled && storages.length > 0 && (
                        <LineInput
                          id={`${activeTab}_settings_storage_total`}
                          label="Storage Price"
                          value={showStoragePrice()}
                          rounded={true}
                          readOnly={true}
                          disabled={creditCheckDeclined(credit, activeTab) || isCTMABuyAll(activeTab)}
                        />
                      )}
                      { showEvChargerOptions && (
                        <LineInput
                          id={`${activeTab}_settings_ev_charger_total`}
                          label="EV Charger Price"
                          value={showEvChargerPrice()}
                          rounded={true}
                          readOnly={true}
                          disabled={creditCheckDeclined(credit, activeTab) || isCTMABuyAll(activeTab)}
                        />
                      )}
                      <LineInput
                        label={partnerIsRvar ? '+ Custom Adders' : '+ SP Custom Adders'}
                        value={settings[activeTab].adders}
                        onChange={(newValue) => {
                          setIsDirty(true);
                          updateSettings(activeTab, 'adders', newValue);
                        }}
                        rounded={true}
                        readOnly={true}
                        positive={true}
                        tooltipText={
                          partnerIsDirect ? '<b>SunPower Adders:</b> $ value <br> used to construct project' : ''
                        }
                        disabled={creditCheckDeclined(credit, activeTab) || isCTMABuyAll(activeTab)}
                      />
                      { account.features.addersInEddie && (
                        <LineInput
                          id="system-adders"
                          label="+ System Adders"
                          value={systemAddersTotalCost}
                          rounded={true}
                          readOnly={true}
                          disabled={creditCheckDeclined(credit, activeTab) || isCTMABuyAll(activeTab)}
                        />
                      )}
                      <LineInput
                        label="- Discount"
                        value={settings[activeTab].discount}
                        onChange={(newValue) => {
                          setIsDirty(true);
                          updateSettings(activeTab, 'discount', newValue);
                        }}
                        readOnly={quote.locked}
                        positive={true}
                        disabled={creditCheckDeclined(credit, activeTab) || isCTMABuyAll(activeTab)}
                      />
                      <hr />
                      <LineInput
                        id={`${activeTab}_settings_total_price`}
                        className="sum-total"
                        readOnly={true}
                        rounded={true}
                        value={showSumTotal()}
                        disabled={creditCheckDeclined(credit, activeTab) || isCTMABuyAll(activeTab)}
                      />
                      { activeTab === CASH_FINANCE_TYPE &&
                      (<div className="form-group">
                        <label>Non-SunPower Loan</label>
                        <div className="input-container">
                          <input
                            className={cx({ 'read-only': quote.locked })}
                            type="text"
                            value={settings.cash.nonSunpowerLoan}
                            readOnly={quote.locked}
                            onChange={(e) => {
                              setIsDirty(true);
                              updateSettings(activeTab, 'nonSunpowerLoan', e.target.value);
                            }}
                            disabled={creditCheckDeclined(credit, activeTab) || isCTMABuyAll(activeTab)}
                          />
                        </div>
                      </div>)
                      }
                      { account.features.enhancedPerformanceWarranty &&
                      activeTab === LOAN_FINANCE_TYPE && !manualMode &&
                      (<div className="form-group checkbox">
                        <label className="epw-label">
                          <input
                            type="checkbox"
                            checked={settings.loan.enhancedPerformanceWarranty}
                            onChange={(e) => {
                              setIsDirty(true);
                              updateSettings(activeTab, 'enhancedPerformanceWarranty', e.target.checked);
                            }}
                            disabled={disabled}
                          />
                          { /* 'Enhanced Production Warranty' is actually 'Enhanced Performance Warranty'. */ }
                          { /* There's a divergence between this codebase and Salesforce. */ }
                          <span className={cx({ disabled })}>Enhanced Production Warranty</span>
                        </label>
                      </div>)
                      }
                    </div>
                    <div className="price-spacing">
                      <hr />
                    </div>
                    <div className="choices">
                      <div className="price-choices">
                        { showPricePerKhw &&
                          <LineRadioInput
                            id={`${activeTab}_settings_price_per_kwh`}
                            label="Price per kWh"
                            value={settings[activeTab].pricePerKwh}
                            checked={systemPriceSelection === 'pricePerKwh'}
                            onChange={(newValue) => {
                              updateSettings(activeTab, 'userInput', true);
                              onPricePerKhwChange(newValue);
                            }}
                            onBlur={(newValue) => {
                              setIsDirty(true);
                              updateSettings(activeTab, 'pricePerKwh', Number(newValue).toFixed(3));
                            }}
                            positive={true}
                            onSelect={() => {
                              setIsDirty(true);
                              updateSettings(activeTab, 'systemPriceSelection', 'pricePerKwh');
                              if (settings[activeTab].pricePerKwh === '') {
                                updateSettings(activeTab, 'needsFinEng', true);
                              }
                            }}
                            readOnly={quote.locked}
                            textInputClass={errorPricePerKwh ? 'has-error' : ''}
                            additionalComponent={
                              <a
                                href="#"
                                onClick={() => {
                                  setIsDirty(true);
                                  gaTrackEvent('quotePage:designTab:pricePerKwhRun');
                                  validateAndFetchFinEng(quote, design, settings[activeTab], systemAdders, activeTab);
                                }}
                                className={runButtonClass}
                                id="price-per-kwh-run-button"
                              >
                                {settings[activeTab].fetchingFinEng ? (
                                  <img
                                    className="price-per-kwh-run-spinner"
                                    src="/build/images/loading-spinner.svg"
                                    alt="Loading Spinner"
                                  />
                                ) : (
                                  <span>Run</span>
                                )}
                              </a>
                            }
                            disabled={creditCheckDeclined(credit, activeTab) || isCTMABuyAll(activeTab)}
                          />
                        }
                        <LineRadioInput
                          id={`${activeTab}_settings_price_per_watt`}
                          label="Price per Watt"
                          value={pricePerWattValue}
                          additionalText={`x ${formatNumber(systemCapacityWatts)} W`}
                          checked={systemPriceSelection === 'pricePerWatt'}
                          onChange={(newValue) => {
                            updateSettings(activeTab, 'userInput', true);
                            onPricePerWattChange(newValue);
                          }}
                          onBlur={(newValue) => updatePrice(newValue)}
                          positive={true}
                          onSelect={() => {
                            setIsDirty(true);
                            updateSettings(activeTab, 'systemPriceSelection', 'pricePerWatt');
                          }}
                          readOnly={quote.locked}
                          textInputClass={errorPricePerWatt ? 'has-error' : ''}
                          disabled={creditCheckDeclined(credit, activeTab) || isCTMABuyAll(activeTab)}
                        />
                        { showDealerFeePerWatt &&
                          <LineRadioInput
                            id={`${activeTab}_settings_dealer_fee_per_watt`}
                            label="Dealer Commission per Watt"
                            value={settings[activeTab].dealerFeePerWatt}
                            additionalText={`x ${formatNumber(systemCapacityWatts)} W`}
                            checked={systemPriceSelection === 'dealerFeePerWatt'}
                            onChange={(newValue) => {
                              updateSettings(activeTab, 'userInput', true);
                              onDealerFeePerWattChange(newValue);
                            }}
                            positive={true}
                            onSelect={() => {
                              setIsDirty(true);
                              updateSettings(activeTab, 'systemPriceSelection', 'dealerFeePerWatt');
                            }}
                            readOnly={quote.locked}
                            textInputClass={errorDealerFeePerWatt ? 'has-error' : ''}
                            disabled={creditCheckDeclined(credit, activeTab) || isCTMABuyAll(activeTab)}
                          />
                        }
                        { showGrossPrice &&
                          <LineRadioInput
                            id={`${activeTab}_settings_gross_price`}
                            label="Gross Price"
                            value={grossPriceValue}
                            checked={systemPriceSelection === 'grossPrice'}
                            onChange={(newValue) => {
                              updateSettings(activeTab, 'userInput', true);
                              onGrossPriceChange(newValue);
                            }}
                            onBlur={(newValue) => updatePrice(newValue)}
                            positive={true}
                            rounded={true}
                            onSelect={() => {
                              setIsDirty(true);
                              updateSettings(activeTab, 'systemPriceSelection', 'grossPrice');
                            }}
                            readOnly={quote.locked}
                            textInputClass={errorGrossPrice ? 'has-error' : ''}
                            disabled={creditCheckDeclined(credit, activeTab) || isCTMABuyAll(activeTab)}
                          />
                        }
                      </div>
                      { showStorageOptions && (
                        <div className="storage-choices" id="storage-section">
                          { (partnerIsRvar || isPartnerNH) && (<h5>Storage</h5>) }
                          { isPartnerNH ? (
                            <LineRadioInput
                              id={`${activeTab}_storage_gross_price`}
                              label="Gross Price"
                              value={nhMortgageStorageGrossPrice}
                              checked={settings[activeTab].storagePriceSelection === 'storagePrice'}
                              onChange={(newValue) => {
                                updateSettings(activeTab, 'userInput', true);
                                updateNHMortgageStorageGrossPrice(newValue);
                              }}
                              positive={true}
                              onSelect={() => {
                                setIsDirty(true);
                                updateSettings(activeTab, 'storagePriceSelection', 'storagePrice');
                              }}
                              readOnly={quote.locked}
                              disabled={nhStorageGrossPriceDisabled ||
                                creditCheckDeclined(credit, activeTab) || isCTMABuyAll(activeTab)}
                            />) :
                            <React.Fragment>
                              { partnerIsRvar && (
                                <LineRadioInput
                                  id={`${activeTab}_storage_dealer_fee`}
                                  label="Storage Dealer Commission"
                                  value={settings[activeTab].storageDealerFee}
                                  checked={settings[activeTab].storagePriceSelection === 'storageDealerFee'}
                                  onChange={(newValue) => {
                                    updateSettings(activeTab, 'userInput', true);
                                    updateDealerFee(newValue);
                                  }}
                                  positive={true}
                                  onSelect={() => {
                                    setIsDirty(true);
                                    updateSettings(activeTab, 'storagePriceSelection', 'storageDealerFee');
                                  }}
                                  readOnly={quote.locked}
                                  disabled={creditCheckDeclined(credit, activeTab) || isCTMABuyAll(activeTab)}
                                />)
                            }
                            </React.Fragment>
                          }
                        </div>
                      )}
                      { showEvChargerOptions && (
                        <div>
                          <h5>EV Charger</h5>
                          <LineRadioInput
                            id={`${activeTab}_ev_charger_dealer_fee`}
                            label={partnerIsRvar ? 'EV Charger Additional Cost' : 'EV Charger Dealer Commission'}
                            value={settings[activeTab].evChargerDealerFee}
                            checked={evChargerInputSelected}
                            onChange={(newValue) => {
                              updateSettings(activeTab, 'userInput', true);
                              updateEvChargerDealerFee(newValue);
                            }}
                            positive={true}
                            onSelect={() => {
                              setIsDirty(true);
                              setEvChargerInputSelected(true);
                            }}
                            readOnly={quote.locked}
                            disabled={false || creditCheckDeclined(credit, activeTab) || isCTMABuyAll(activeTab)}
                          />
                        </div>
                      )}
                      <div className="rebate-choices">
                        <h5>State and Local Rebates</h5>
                        <LineRadioInput
                          label="Rebate Estimate"
                          value={rebateEstimate}
                          checked={settings[activeTab].rebateSelection === 'rebateEstimate'}
                          onSelect={() => {
                            setIsDirty(true);
                            updateSettings(activeTab, 'userInput', true);
                            updateSettings(activeTab, 'rebateSelection', 'rebateEstimate');
                          }}
                          readOnly={quote.locked}
                          disabled={creditCheckDeclined(credit, activeTab) || isCTMABuyAll(activeTab)}
                        />
                        <LineRadioInput
                          label="Override Rebate"
                          value={settings[activeTab].overrideRebate}
                          checked={settings[activeTab].rebateSelection === 'overrideRebate'}
                          onChange={(newValue) => {
                            setIsDirty(true);
                            updateSettings(activeTab, 'userInput', true);
                            updateSettings(activeTab, 'overrideRebate', newValue);
                          }}
                          onSelect={() => {
                            setIsDirty(true);
                            updateSettings(activeTab, 'rebateSelection', 'overrideRebate');
                          }}
                          readOnly={quote.locked}
                          positive={true}
                          disabled={creditCheckDeclined(credit, activeTab) || isCTMABuyAll(activeTab)}
                        />
                        { activeTab === CASH_FINANCE_TYPE &&
                         quote.version >= 12 &&
                         partnerIsSpd && (
                         <div className="goodleap-checkbox form-group">
                           <div className="input-container">
                             <input
                               type="checkbox"
                               checked={settings[activeTab].goodleapFinancing}
                               onChange={(e) => {
                                 setIsDirty(true);
                                 updateSettings(activeTab, 'userInput', true);
                                 updateSettings(activeTab, 'goodleapFinancing', e.target.checked);
                               }}
                               disabled={quote.locked || quote.cloneVariant === 'amendment'}
                             />
                             <span className="radio-label">Goodleap Financing</span>
                           </div>
                         </div>
                        )}
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        {enableCustomAdders() && optionalAddersFromAp.length > 0 &&
        (<div className="form-group labeled adder-library">
          <h5 className="label">Adder Library</h5>
          <p className="sub-label">Add dollar values to projects or create custom values:</p>
          <div className="checkbox-container">
            {optionalAddersFromAp.map((adder) => (
              <div className="option-container">
                <span>{adder.adderName}</span>
                <input
                  type="checkbox"
                  value={adder.adderName}
                  checked={selectedOptionalAdderNames.indexOf(adder.adderName.trim()) > -1}
                  onChange={(e) => {
                    updateSettings(activeTab, 'userInput', true);
                    toggleOptionalAdderSelection(e.target.checked, (adder.adderName));
                  }}
                  disabled={quote.locked || isSavingsPage}
                />
                <span className="checkmark" />
              </div>
            ))}
          </div>
        </div>)}
        <div className="form-group labeled adder-library ad-hoc">
          {adHocAdder.length > 0 && (
              adHocAdder.map((adder) => (
                <div className="ad-hoc-container">
                  <div className="new-adder">
                    <input
                      type="text"
                      placeholder="Type adder name"
                      className="adder-input adder-name-input"
                      value={adder.adderName}
                      onChange={(e) => handleValueChange('adderName', e.target.value, (adder.id || adder.adderId))}
                      disabled={quote.locked}
                    />
                    <div className="adder-price-container">$
                      <input
                        type="text"
                        placeholder="0"
                        className="adder-input adder-price-input"
                        value={adder.adderPrice || 0}
                        onChange={(e) =>
                          handleValueChange('adderPrice', parseInt(e.target.value, 10), (adder.id || adder.adderId))
                        }
                        disabled={quote.locked}
                      />
                    </div>
                    <input
                      type="checkbox"
                      checked={adder.isLive || false}
                      onChange={(e) => handleValueChange('isLive', e.target.checked, (adder.id || adder.adderId))}
                      disabled={quote.locked || isSavingsPage}
                    />
                    {!quote.locked && !isSavingsPage && (
                      <div
                        className="clear"
                        onClick={() => handleValueChange('deleteAdder', true, (adder.id || adder.adderId))}
                      >
                        +
                      </div>
                    )}
                  </div>
                </div>
              )))}
          {!quote.locked && (
            <div className="new-adder" onClick={addCustomAdder} data-dd-action-name="Add Custom Adders">
              <label>Custom <div className="plus">+</div> Adder</label>
            </div>
              )}
        </div>
      </div>
      <ReactTooltip offset={{ top: -50, left: -80 }} html={true} />
    </Modal>
  );
}

SettingsModal.propTypes = {
  showDealerFeePerWatt: PropTypes.bool.isRequired,
  settings: settingsType.isRequired,
  productConfiguration: productConfigurationType.isRequired,
  quote: quoteType.isRequired,
  account: accountType.isRequired,
  energyData: energyDataType.isRequired,
  design: designType.isRequired,
  currentTabName: PropTypes.string.isRequired,
  updateSettings: PropTypes.func.isRequired,
  setError: PropTypes.func.isRequired,
  toggleSettingsTab: PropTypes.func.isRequired,
  updateQuoteName: PropTypes.func.isRequired,
  manualMode: PropTypes.bool,
  error: errorType,
  isPublic: PropTypes.bool,
  validateAndSaveSettingsModal: PropTypes.func.isRequired,
  systemCapacityWatts: PropTypes.number.isRequired,
  notifications: notificationsType.isRequired,
  partnerIsRvar: PropTypes.bool.isRequired,
  partnerIsTps: PropTypes.bool.isRequired,
  partnerSectorLeaseIsNewHomes: PropTypes.bool.isRequired,
  pvModules: PropTypes.arrayOf(pvModuleType).isRequired,
  businessFunctions: businessFunctionsType,
  selectedStorage: selectedStorageType.isRequired,
  isApplyHaircutState: PropTypes.bool,
  fetchFinEng: PropTypes.func,
  newHomesDetails: newHomesDetailsType,
  partnerIsSpd: PropTypes.bool.isRequired,
  partnerIsNewHomes: PropTypes.bool.isRequired,
  isPartnerEnabledForAnyStorage: PropTypes.bool.isRequired,
  closeModalAndRevertState: PropTypes.func.isRequired,
  updateDirtySettings: PropTypes.func,
  credit: PropTypes.instanceOf(PropTypes.object),
  selectedStorageWithExpansionPack: selectedStorageWithExpansionPackType.isRequired,
  pvModule: pvModuleType.isRequired,
  updateOptionalAddersSelection: PropTypes.func
};

export default register(
  [ 'settingsSelector', 'productConfigurationSelector', 'quoteSelector', 'accountSelector',
    'designSelector', 'energyDataSelector', 'manualModeSelector', 'currentTabNameSelector',
    'errorSelector', 'showDealerFeePerWatt', 'isPublicSelector', 'systemCapacityWattsSelector',
    'notificationsSelector', 'partnerIsRvarSelector', 'partnerIsTpsSelector', 'partnerSectorLeaseIsNewHomesSelector',
    'pvModulesSelector', 'businessFunctionsSelector', 'partnerIsNewHomesSelector',
    'selectedStorageSelector', 'isApplyHaircutState', 'newHomesDetailsSelector',
    'isPartnerEnabledForAnyStorageSelector', 'partnerIsSpdSelector', 'selectedStorageWithExpansionPackSelector',
    'creditSelector', 'pvModuleSelector'
  ],
  [ 'updateSettings', 'toggleSettingsTab', 'setError', 'updateDirtySettings',
    'updateQuoteName', 'validateAndSaveSettingsModal', 'fetchFinEng', 'closeModalAndRevertState',
    'updateOptionalAddersSelection' ],
  SettingsModal
);
