import PropTypes from 'prop-types';
import cx from 'classnames';
import React, { createRef } from 'react';
import CloseIcon from '../../../../images/close.svg';
import DownloadIcon from '../../../../images/download.svg';
import ErrorIcon from '../../../../images/error.svg';
import CheckIcon from '../../../../images/grey-check.svg';
import constants from '../../../utils/admin_portal/constants';
import { Modal } from '../../Modal';
import { PricingDefaultsError } from './PricingDefaultsError';

export function PreviewModal({ rows, download, upload, close }) {
  const { PREVIEW_COLUMNS } = constants;
  const uploadRef = createRef();

  const TableHeaders = () => (
    <tr>
      {PREVIEW_COLUMNS.map((header) => {
        return <th>{header}</th>;
      })}
    </tr>
  );

  const TableRows = () => (
    <React.Fragment>
      { rows.map((row) => {
        const dollarValue = Number(row['Dollar Value']).toLocaleString('en-US', {
          minimumFractionDigits: 2, maximumFractionDigits: 2
        });
        const minDollarValue = row['Min Dollar Value'] ? Number(row['Min Dollar Value']).toLocaleString('en-US', {
          minimumFractionDigits: 2, maximumFractionDigits: 2
        }) : '';
        const maxDollarValue = row['Max Dollar Value'] ? Number(row['Max Dollar Value']).toLocaleString('en-US', {
          minimumFractionDigits: 2, maximumFractionDigits: 2
        }) : '';
        const systemMin = row['System Size Min (kW)'] ?
          Number(row['System Size Min (kW)']).toLocaleString('en-US', {
            minimumFractionDigits: 2, maximumFractionDigits: 2
          }) : '';
        const systemMax = row['System Size Max (kW)'] ?
          Number(row['System Size Max (kW)']).toLocaleString('en-US', {
            minimumFractionDigits: 2, maximumFractionDigits: 2
          }) : '';
        return (
          <tr>
            <td>
              {(row.isDuplicate || row.hasOverlappingSize || row.hasMultiplePo) ? (
                <ErrorIcon width={20} height={20} />
              ) : (
                <CheckIcon width={20} height={20} />
              )}
            </td>
            <td className={cx({ 'duplicate-error': row.isDuplicate })}>{row.Market}</td>
            <td className={cx({ 'duplicate-error': row.isDuplicate })}>{row['Financial Option']}</td>
            <td className={cx({ 'duplicate-error': row.isDuplicate })}>{row.SKU}</td>
            <td className={cx({ 'multiple-po-error': row.hasMultiplePo })}>{row['Pricing Option']}</td>
            <td>{dollarValue}</td>
            <td>{minDollarValue}</td>
            <td>{maxDollarValue}</td>
            <td
              className={cx({ 'overlapping-size-error': row.hasOverlappingSize, 'duplicate-error': row.isDuplicate })}
            >
              {systemMin}
            </td>
            <td
              className={cx({ 'overlapping-size-error': row.hasOverlappingSize, 'duplicate-error': row.isDuplicate })}
            >
              {systemMax}
            </td>
          </tr>
        );
      })}
    </React.Fragment>
  );

  const duplicates = rows.filter((row) => row.isDuplicate).length;
  const overlappingSizes = rows.filter((row) => row.hasOverlappingSize).length;
  const multiplePo = rows.filter((row) => row.hasMultiplePo).length;

  return (
    <Modal
      id="preview-modal"
      name="preview-modal"
      showButtons={false}
      modals={{}}
    >
      <div className="header">
        <div className="left-head">
          <a className="close" onClick={close}>
            <CloseIcon />
          </a>
          {(duplicates > 0 || multiplePo > 0 || overlappingSizes > 0) && (
            <PricingDefaultsError
              duplicates={duplicates}
              multiplePo={multiplePo}
              overlappingSizes={overlappingSizes}
              id="preview-tooltip"
            />
          )}
        </div>
        <span className="title">
          PRICING INPUTS
        </span>
        <div className="file-actions">
          <button
            className="btn btn-secondary download"
            onClick={download}
          >
            <DownloadIcon />
          </button>
          <button
            className="btn btn-secondary replace"
            onClick={() => uploadRef.current.click()}
          >
            Replace
          </button>
          <input
            type="file"
            accept="text/csv"
            ref={uploadRef}
            onChange={(event) => upload(event)}
            hidden={true}
          />
        </div>
      </div>
      <div className="table-container">
        <table className="table">
          <thead>
            <TableHeaders />
          </thead>
          <tbody>
            <TableRows />
          </tbody>
        </table>
      </div>
    </Modal>
  );
}

PreviewModal.propTypes = {
  rows: PropTypes.arrayOf(PropTypes.any),
  download: PropTypes.func,
  upload: PropTypes.func,
  close: PropTypes.func
};
