export function setRoofPreviewMode(previewMode) {
  return {
    type: 'setRoofPreviewMode',
    payload: {
      design: {
        roofPreviewMode: previewMode
      }
    }
  };
}
