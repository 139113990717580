import get from 'lodash/get';
import monitoringConfigurationsSelector from './monitoringConfigurations';

/**
 * Returns selected or default monitoring configuration for quote.
 */
export default (state) => {
  const monitoringConfigurationId = get(state, 'quote.monitoringConfigurationId');
  const { monitoringConfigurations } = monitoringConfigurationsSelector(state);

  const monitoringConfiguration =
    monitoringConfigurations.find((config) => config.id === monitoringConfigurationId) ||
    monitoringConfigurations[0];

  return {
    monitoringConfiguration
  };
};
