/**
 * tryUntil will re-try condition until it is true
 * for maxM ms every intervalMs ms. Promise throw
 * if the condition is not met.
 * @param {number} intervalMs
 * @param {number} maxMs
 * @param {func} condition
 * @returns {Promise}
 */
export default (intervalMs, maxMs, condition) => {
  if (condition()) { return Promise.resolve(); }
  let elapsed = 0;
  return new Promise((fnResolve, fnReject) => {
    const interval = setInterval(() => {
      if (condition()) {
        clearInterval(interval);
        fnResolve();
      } else {
        elapsed += intervalMs;
        if (elapsed >= maxMs) {
          clearInterval(interval);
          fnReject('tryCatch condition not met.');
        }
      }
    }, intervalMs);
  });
};
