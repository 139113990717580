import PropTypes from 'prop-types';
import React from 'react';
import ReactTooltip from 'react-tooltip';
import CloseIcon from '../../../../images/close.svg';
import ErrorIcon from '../../../../images/error.svg';

export function PricingDefaultsError({ duplicates, multiplePo, overlappingSizes, id }) {
  return (
    <div className="duplicates">
      <ErrorIcon
        width={20}
        height={20}
        data-tip={true}
        data-event="click"
        data-for={id}
        className="error-icon"
      />
      <ReactTooltip
        id={id}
        className="tooltip-box"
        aria-haspopup="true"
        globalEventOff="click"
        effect="solid"
        event="click"
        type="light"
        place="right"
        clickable={true}
      >
        {duplicates > 0 && (
          <p className="tooltip-text">
            Duplicate records for market/financial option/sku are found.
          </p>
        )}
        {multiplePo > 0 && (
          <p className="tooltip-text">
            Records with different Pricing Options are found.
          </p>
        )}
        {overlappingSizes > 0 && (
          <p className="tooltip-text">
            Records with overlapping System Sizes are found.
          </p>
        )}
        <p className="tooltip-text">
          If file is used, the latest record in the file will be kept. Any previous will be ignored.
        </p>
        <CloseIcon
          onClick={() => {
            document.querySelector('.tooltip-box').classList.remove('show');
          }}
        />
      </ReactTooltip>
      <span>
        {duplicates + multiplePo + overlappingSizes} INVALIDS FOUND
      </span>
      <span />
    </div>
  );
}


PricingDefaultsError.propTypes = {
  duplicates: PropTypes.number,
  multiplePo: PropTypes.number,
  overlappingSizes: PropTypes.number,
  id: PropTypes.string
};
