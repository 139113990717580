/* eslint no-underscore-dangle: 0 */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import cx from 'classnames';
import { register } from '../../../utils/redux';
import { partnerIsIndirect } from '../../../utils/admin_portal/quoteHelpers';
import { InputFieldWithCurrencySymbol } from './InputFieldWithCurrencySymbol';
import validateStorageCommission from '../../../utils/validateStorageCommission';

export function CommissionPriceTableView({ apFinConfigs, financeType, updateApProduct,
  apSalesforce, storageProductConfig, updateValidationErrors, partnerChannel }) {
  let apFinConfig;
  let isFOEnabled;
  const account = {
    partner: {
      channel: partnerChannel
    } };

  if (financeType && apFinConfigs) {
    apFinConfig = apFinConfigs ?
      apFinConfigs.findLast((config) => config.finType.toLowerCase() === financeType.toLowerCase()) :
      null;
    isFOEnabled = get(apFinConfig, 'show');
  }

  const selectedStates = apFinConfig ? (apFinConfig.applicableStatesAttributes || [])
  .filter((stateConfig) => !stateConfig._destroy).map((stateConfig) => stateConfig.state) : [];

  const storagesForSeletedStates = [];
  const productsHash = {};

  const storagePermissionForFin = financeType === 'ppa'
    ? 'storagePPA' : `storage${financeType.charAt(0).toUpperCase() + financeType.slice(1)}`;
  const storagePermission = get(apSalesforce, `storagePermission.${storagePermissionForFin}`, false);
  const teslaCertified = apSalesforce.specialProgram.includes('Tesla Certified');
  const showAdders = get(apSalesforce, 'specialProgram', [])
    .findIndex((program) => program.toLowerCase() === 'adders in eddie') !== -1;

  if (storagePermission) {
    selectedStates.forEach((state) => {
      apSalesforce.financialOfferings[financeType][state].storagesAvailable.forEach((config) => {
        if (teslaCertified || (!teslaCertified && config.storageManufacturer.toLowerCase() !== 'tesla')) {
          productsHash[config.storageId] = {
            storageName: config.storageName,
            storageManufacturer: config.storageManufacturer,
            desc: 'storage'
          };
        }
      });
    });
  }

  if (showAdders) {
    productsHash.critter_guard = {
      storageName: 'Critter Guard',
      storageManufacturer: 'Accessories',
      desc: 'Accessories'
    };
    productsHash.skirt_guard = {
      storageName: 'Front Edge Skirt',
      storageManufacturer: 'Accessories',
      desc: 'Accessories'
    };
  }

  const storageManufacturer =
    [...new Set(Object.keys(productsHash).map((item) => productsHash[item].storageManufacturer))];
  const [ selectedManufacturer, setSelectedManufacturer ] = useState(storageManufacturer[0]);
  const [ showTooltip, setShowTooltip ] = useState(false);
  useEffect(() => {
    setSelectedManufacturer(storageManufacturer[0]);
  }, [ financeType, selectedStates.length ]);
  const storagesId = Object.keys(productsHash);
  const manufacturerIds = storagesId.filter((id) => productsHash[id].storageManufacturer === selectedManufacturer);

  const getProductRecords = (productId) => {
    const product = apFinConfig ?
      apFinConfig.productConfigsAttributes.find((config) => config.product === productId)
      : null;
    return product;
  };

  const sortingProducts = (productObj1, productObj2) => {
    if (productObj1.label < productObj2.label) { return -1; }
    if (productObj1.label > productObj2.label) { return 1; }
    return 0;
  };

  const getProductConfigs = () => {
    if (!financeType) {
      return [];
    }

    const products = [];
    manufacturerIds.forEach((id) => {
      const productRecord = getProductRecords(id);
      if (productRecord) {
        const storageProduct = {
          label: productsHash[id].storageName,
          configName: id,
          desc: productsHash[id].storageManufacturer,
          sell: productRecord.sell,
          commissionPerUnit: productRecord.commissionPerUnit,
          show: ((storagePermission && productsHash[id].desc === 'storage') ||
            (showAdders && productsHash[id].desc === 'Accessories'))
        };
        products.push(storageProduct);
      }
    });

    return products.filter((product) => product.show).sort(sortingProducts);
  };

  const products = getProductConfigs();
  let applyButton = products.length;

  const updateAllProductsSelection = (val, attribute, sellOnlyProducts = false) => {
    products.forEach((product) => {
      if (!sellOnlyProducts || (sellOnlyProducts && product.sell)) {
        updateApProduct(apFinConfig.id, product.configName, attribute, val);
      }
    });
  };

  const updateSellProduct = (apFinConfigId, configName, key, val) => {
    updateApProduct(apFinConfigId, configName, key, val);
    if (!val) {
      updateApProduct(apFinConfigId, configName, 'commissionPerUnit', 0);
    }
  };

  const allProductSelected = () => {
    const selectedProducts = products.filter((product) => product.desc !== 'storage' && product.sell);
    const availableProducts = products.filter((product) => product.desc !== 'storage');
    return availableProducts.length > 0 && selectedProducts.length === availableProducts.length;
  };

  const storageCommissionValidator = (value) => {
    const storageCommissionMsg = validateStorageCommission(value);
    return storageCommissionMsg;
  };

  const handleMouseEnter = () => {
    setShowTooltip(true);
  };

  const handleMouseLeave = () => {
    setShowTooltip(false);
  };

  return (
    <div className="admin-table-container">
      <div className="table-heading">
        <b>Choose products to sell & set preferred commission price</b> per product:
      </div>
      <div className="products-headings">
        {storageManufacturer.map((manufacturer) => {
          return (
            <span
              className={cx('products-heading-tab', { selected: manufacturer === selectedManufacturer })}
              onClick={() => setSelectedManufacturer(manufacturer)}
            >
              {manufacturer}
            </span>
          );
        })}
      </div>
      <table className="table admin-table">
        <tbody>
          <tr className="header">
            <th>
              <label className="checkbox-container">
              SELL
              <input
                type="checkbox"
                checked={allProductSelected()}
                onChange={(e) => updateAllProductsSelection(e.target.checked, 'sell')}
                disabled={!isFOEnabled}
              />
                <span className="checkmark" />
              </label>
            </th>
            <th className="product-column">PRODUCT:</th>
            {partnerIsIndirect(partnerChannel) && (
              <React.Fragment>
                <th>
                  <div className="header-cell">
                    <div>COMMISSION PRICE PER UNIT:</div>
                    <img src="/build/images/admin-info-icon.svg" className="info-icon" alt="info" />
                  </div>
                </th>
              </React.Fragment>
            )}
          </tr>
          {products.map((product, index) => {
            const storageCommissionMsg = validateStorageCommission(parseFloat(product.commissionPerUnit), account);
            if (product.sell && index - 1 < applyButton) {
              applyButton = index;
            }
            return (
              <tr className={`body ${index < products.length - 1 ? 'border-bottom' : ''}`}>
                <td>
                  <label className="checkbox-container">
                    SELL
                    <input
                      id={product.configName}
                      name={product.label}
                      type="checkbox"
                      checked={product.sell}
                      onChange={(e) => updateSellProduct(apFinConfig.id, product.configName, 'sell', e.target.checked)}
                      disabled={!isFOEnabled}
                    />
                    <span className="checkmark" />
                  </label>
                </td>
                <td className="product-name">
                  <label>{product.label}</label>
                  {partnerIsIndirect(partnerChannel) && product.desc !== 'Accessories' && (
                    <span
                      className="hyper-link"
                      onMouseEnter={() => handleMouseEnter(true)}
                      onMouseLeave={() => handleMouseLeave(false)}
                    >
                      { applyButton === index &&
                        <div>
                          <a
                            onClick={() =>
                              updateAllProductsSelection(product.commissionPerUnit, 'commissionPerUnit', true)
                            }
                          >
                          Apply Price to All
                        </a>
                          { showTooltip &&
                            <div className="tooltip-msg">
                              Will change all {selectedManufacturer} fields to match this price.
                            </div>
                          }
                        </div>
                      }
                    </span>
                  )}
                </td>
                <td>
                  {partnerIsIndirect(partnerChannel) && product.desc !== 'Accessories' && product.sell && (
                  <React.Fragment>
                    <InputFieldWithCurrencySymbol
                      currency={'$'}
                      placeHolder={'0.00'}
                      value={product.commissionPerUnit || 0}
                      onChange={(value) => {
                        updateApProduct(apFinConfig.id, product.configName, 'commissionPerUnit', value);
                        storageCommissionValidator(value, account);
                      }}
                      disabled={!isFOEnabled}
                      key={`commission-input-${financeType}`}
                    />
                    { storageCommissionMsg && (
                    <div className="error-message">{storageCommissionMsg}</div>
                      )
                    }
                  </React.Fragment>
                  )}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}
CommissionPriceTableView.propTypes = {
  financeType: PropTypes.string,
  apFinConfigs: PropTypes.arrayOf(PropTypes.object),
  updateApProduct: PropTypes.func,
  updateValidationErrors: PropTypes.func,
  financialOfferings: PropTypes.objectOf(PropTypes.object),
  storageProductConfig: PropTypes.objectOf(PropTypes.object),
  apSalesforce: PropTypes.objectOf(PropTypes.object),
  partnerChannel: PropTypes.string
};
export default register(
  [],
  [],
  CommissionPriceTableView
);
