import React from 'react';
import PropTypes from 'prop-types';
import { gaTrackEvent } from '../../utils/googleAnalytics';
import { register } from '../../utils/redux';

export const ToolbarItem = ({ item, activeItemId, toggleProposalToolbarActiveItem }) => {
  const action = (...args) => {
    if (item.eventName) {
      gaTrackEvent(item.eventName);
    }

    if (item.canActivate) {
      toggleProposalToolbarActiveItem(item.id, activeItemId);
    }

    if (item.onClickAction) {
      item.onClickAction(...args);
    }
  };

  return (
    <a
      key={item.id}
      className={`toolbar-icon toolbar-icon--${item.id}${activeItemId === item.id ? ' toolbar-icon--active' : ''}`}
      onClick={action}
      {...item.iconProps}
    >
      {item.icon}
      <div className="toolbar-icon__label">{item.label}</div>
    </a>
  );
};
ToolbarItem.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    iconProps: PropTypes.shape({}),
    icon: PropTypes.element.isRequired,
    canActivate: PropTypes.bool,
    eventName: PropTypes.string,
    onClick: PropTypes.func
  }),
  activeItemId: PropTypes.string,
  toggleProposalToolbarActiveItem: PropTypes.func.isRequired
};
ToolbarItem.defaultProps = {
  activeItemId: null,
  canActivate: false,
  eventName: null
};

export default register(
  [],
  [
    'toggleProposalToolbarActiveItem'
  ],
  ToolbarItem
);
