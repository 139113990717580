
import { get } from 'lodash';
import dynamicPdfText from '../dynamicPdfText';

const { VIEW_WEB_VERSION } = dynamicPdfText;

const viewWebVersion = () => {
  const webVersion = document.createElement('div');
  webVersion.innerHTML = VIEW_WEB_VERSION;
  webVersion.style.paddingTop = '50px';
  webVersion.style.paddingBottom = '50px';
  return webVersion;
};

export const onWelcome = (clone, account, userData, productConfiguration, quote, instantQuoteMode) => {
  const welcomeNode = clone.querySelector('#welcome');
  welcomeNode.style.height = '964px';
  const titles = welcomeNode.querySelectorAll('#welcome div div div div');
  if (titles && titles[2]) titles[2].style.fontSize = '20px';
  const ECAccount = get(userData, 'accountName');
  const ECEmail = get(userData, 'email');
  const ECPhone = get(userData, 'phone');
  const contractorLicenseNumber = instantQuoteMode || !quote.graphSelections ? true : get(
    productConfiguration,
    'contractorLicenseNumber'
  );
  const addressNode = clone.createElement('div');
  const preparedFor = clone.createElement('div');
  const preparedBy = clone.createElement('div');
  addressNode.style = `
    display: flex;
    color: #404040;
    justify-content: space-between;
    width: 100%;
    padding: 50px 250px;
    paddingTop: 0;
    font-size: 20px;
  `;

  preparedFor.innerHTML = `<h3> PREPARED FOR </h3>
    <h3 style="font-weight: bold; padding:0;margin:0 0 5px 0">${account.name}</h3>
    ${account.street}</br>
    ${account.state} ${account.city} ${account.postalCode}</br>
  `;

  preparedBy.innerHTML = `<h3> PREPARED BY </h3>
    <h3 style="font-weight: bold; padding:0;margin:0 0 5px 0">
      ${get(userData, 'firstName') || ''} ${get(userData, 'lastName') || ''}
    </h3>
  `;

  if (ECAccount) {
    const account = clone.createElement('div');
    account.innerHTML = `${ECAccount}`;
    preparedBy.appendChild(account);
  }
  if (ECEmail) {
    const email = clone.createElement('div');
    email.innerHTML = `${ECEmail}`;
    preparedBy.appendChild(email);
  }
  if (ECPhone) {
    const phone = clone.createElement('div');
    phone.innerHTML = `${ECPhone}`;
    preparedBy.appendChild(phone);
  }
  const contractor = clone.createElement('span');
  contractor.innerHTML = `Contractor License Number:</br>${contractorLicenseNumber}`;
  preparedBy.append(contractor);
  addressNode.appendChild(preparedFor);
  addressNode.appendChild(preparedBy);
  clone
  .querySelector(
    '#welcome>:first-child>:first-child>:first-child>:last-child'
    )
    .remove();
  clone.querySelector('#welcome>div>div:first-child').append(addressNode);
  clone.querySelector('#welcome>:first-child>:first-child').style.paddingTop =
  '50px';
  clone.querySelector('#welcome>div>div:first-child').append(viewWebVersion());
};

export const onSystemDetails = (clone, systemDesign) => {
  const systemDetails = clone.querySelector('#systemDetails');
  systemDetails.style.background = 'white';
  systemDetails.style.padding = '0 50px';
  const systemStorage = clone.querySelector('#systemStorageModule>div');
  systemStorage.style.boxShadow = 'none';
  const manualDesign = clone.querySelector('#image5');
  if (manualDesign) manualDesign.style.maxWidth = '100%';
  const systemContainer = clone.querySelector('#systemStorageModule div');
  if (systemContainer) {
    systemContainer.style.display = 'flex';
    systemContainer.style.flexDirection = 'column';
    systemContainer.style.alignItems = 'center';
  }
  if (!systemDesign) {
    const title = systemStorage.querySelector(':scope > div:first-child');
    title.style.alignSelf = 'start';
  }
  const chartButton = clone.querySelector('[data-for="monthlyProductionChart"]');
  if (chartButton) chartButton.remove();
  const arrowButton = systemDetails.querySelector('.MuiGrid-container>div:not(.MuiGrid-item)');
  if (arrowButton) arrowButton.remove();
  const kwhInfo = systemDetails.querySelector('span[data-for="kwhInfo"]');
  if (kwhInfo) kwhInfo.remove();
};

export const onBatteryDetails = (clone) => {
  const batteryDetails = clone.querySelector('#storageDetails');
  batteryDetails.style.background = 'white';
  batteryDetails.querySelector('.MuiGrid-container').style.flexWrap = 'nowrap';
  batteryDetails.querySelectorAll('div+img').forEach((item) => {
    item.style.height = '22px';
  });
  clone.querySelectorAll('div').forEach((item) => {
    item.style.backgroundColor = 'white';
    item.style.boxShadow = 'none';
  });

  const storageDetailsImages = clone.querySelectorAll('#storageDetails img');
  storageDetailsImages.forEach((item) => {
    if (item.src.match('tesla-storage.png') || item.src.match('backup.jpg')) {
      item.parentElement.style.backgroundColor = '#949494';
    }
  });

  const storageDetails = clone.querySelector('#storageDetails>:first-child>:first-child');
  const firstElement = storageDetails.querySelector(':first-child>:nth-child(2)');
  if (firstElement) firstElement.remove();
  const query = ':nth-child(2)>:nth-child(3)>:first-child>:first-child>:last-child';
  const secondElement = storageDetails.querySelector(query);
  if (secondElement) secondElement.remove();
};

const removeActionBtns = (clone) => {
  const acknowledgement = clone.querySelector('.acknowledgement-container');
  if (acknowledgement) acknowledgement.remove();
  const sendOptions = clone.querySelector('#send_options_dropdown');
  if (sendOptions) sendOptions.parentElement.parentElement.remove();
  const lock = clone.querySelector('.locked-quote-placeholder');
  const actionBtns = clone.querySelector('.action-button-view-container');
  if (!lock && actionBtns) actionBtns.style.border = 0;
};

export const onFinance = (clone, notSimpleState, is3pSelected, currentPage) => {
  const finance = clone.querySelector('#finance>div');

  if (is3pSelected) {
    finance.style.paddingTop = '0';
    clone.querySelector('#finance>:first-child').style.paddingTop = '0';
  }
  clone.querySelector('#finance>:last-child>:last-child').style = `
    flex-wrap: nowrap;
    padding-right: 50px !important;
  `;
  if (currentPage === 'proposal') {
    const creditAppContainer = clone.querySelector('.begin-credit-app-container');
    const creditDiv = creditAppContainer ? creditAppContainer.parentElement : null;
    const mainCreditDiv = creditDiv ? creditDiv.parentElement : null;
    if (mainCreditDiv) mainCreditDiv.remove();
  }

  const solarCanvas = clone.querySelector('#solar-production-chart-canvas');
  if (solarCanvas) {
    solarCanvas.style.paddingRight = '50px';
    const labels = clone.querySelectorAll('#finance .MuiGrid-grid-sm-9 div div div:has(span) div:last-child');
    labels.forEach((item) => {
      item.style.fontSize = '28px';
    });
    const percentage = clone.querySelector('#finance .MuiGrid-grid-sm-3 div div:last-child');
    percentage.style.fontSize = '28px';
    const kwhs = clone.querySelectorAll('#finance .MuiGrid-grid-sm-9 div div div:has(span) span');
    kwhs.forEach((item) => {
      item.style.fontSize = '22px';
    });
  }
  const financeElements = clone.querySelectorAll('div');
  financeElements.forEach((item) => {
    item.style.backgroundColor = 'white';
    item.style.boxShadow = 'none';
  });

  if (currentPage === 'proposal') {
    const utilityPerMoAndKwhToggle =
      clone.querySelector('#savings-tabpanel-1>div:last-child>div:first-child>div:first-child');
    if (utilityPerMoAndKwhToggle) utilityPerMoAndKwhToggle.remove();

    const totalSavingsGraphTab = clone.querySelector('#savings-tab-0');
    if (totalSavingsGraphTab) totalSavingsGraphTab.remove();

    const savingsOverTimeTab = clone.querySelector('#savings-tab-2');
    if (savingsOverTimeTab) savingsOverTimeTab.remove();
  }

  if (notSimpleState) {
    const indicator = clone.querySelector('.MuiTabs-indicator');
    if (indicator) indicator.remove();
    const ledger = clone.querySelector('#ledgerDetails');
    if (ledger) ledger.style.paddingRight = '';
  }
  removeActionBtns(clone);
  if (is3pSelected) {
    const enphaseRemove = clone.querySelector('#finance>:last-child>:last-child');
    enphaseRemove.remove();
  } else {
    clone.querySelector('#finance>:last-child').append(viewWebVersion());
  }
};

export const onUtility = (clone, notSimpleState, currentPage) => {
  if (currentPage === 'proposal') {
    const qSerch = '#finance>:last-child>:last-child>:first-child>:nth-child(3)>:first-child>:first-child>:first-child';
    const utilityToggle = clone.querySelector(qSerch);
    if (utilityToggle) utilityToggle.remove();
  }
  const utility = clone.querySelector('#finance>:last-child>:last-child');
  utility.style = `
    padding: 100px !important;
  `;
  utility.querySelectorAll(':scope > div').forEach((item) => {
    item.style.paddingTop = '50px';
  });
  const childrens = utility.querySelectorAll(':scope > div');
  if (childrens) {
    childrens.forEach((child) => {
      child.style.flex = 1;
    });
  }
  const utilityElements = clone.querySelectorAll('div');
  utilityElements.forEach((item) => {
    item.style.backgroundColor = 'white';
    item.style.boxShadow = 'none';
  });
  const utilityTab = clone.querySelector('#savings-tabpanel-1>div:last-child>div:first-child>div:first-child');
  if (notSimpleState && utilityTab) {
    utilityTab.style.paddingRight = '50px';
  }
  removeActionBtns(clone);
  const utilityNode = clone.querySelector('#finance>:last-child>:last-child');
  if (utilityNode) utilityNode.append(viewWebVersion());
};

export const onMonthly = (clone, currentPage) => {
  if (currentPage === 'proposal') {
    const closeBtn = clone.getElementsByClassName('fa-times')[0];
    if (closeBtn) closeBtn.remove();
  }

  clone.querySelectorAll('div').forEach((item) => {
    if (currentPage === 'proposal') item.style.backgroundColor = 'white';
    item.style.boxShadow = 'none';
  });
};

export const onSavings = (clone, currentPage) => {
  const base = currentPage === 'proposal'
    ? clone.querySelector('#savings-tabpanel-2')
    : clone.querySelector('.year-savings');
  base.style.width = '1000px';
  base.style.padding = '50px';

  if (currentPage === 'proposal') {
    base.style.textAlign = 'center';
    base.style.padding = '100px';
  }

  const totalLabel = clone.querySelector('#totalSavingsGraph');
  if (totalLabel) totalLabel.remove();

  if (currentPage === 'proposal') {
    const title = clone.createElement('span');
    title.innerHTML = 'SAVINGS OVER TIME';
    title.style.fontWeight = 'bold';
    title.style.border = '0';
    title.style.fontSize = '14px';

    clone.querySelector('#savings-tabpanel-2').prepend(title);
  }
  const overTimeLabel = clone.querySelector('#savingsOverTimeGraph > span');
  if (overTimeLabel) {
    overTimeLabel.style.fontWeight = 'bold';
    overTimeLabel.style.border = '0';
  }
  const savingsGraph = clone.querySelector('#estimated-savings').parentElement;
  savingsGraph.style.width = '800px';
  savingsGraph.style.paddingRight = '100px';
  savingsGraph.querySelectorAll('div').forEach((box) => {
    box.style.whiteSpace = 'nowrap';
  });
  const icons = savingsGraph.querySelectorAll(':scope>div>div:nth-child(4) img');
  icons.forEach((img) => {
    img.style.width = '14px';
  });
  const labels = savingsGraph.querySelectorAll(':scope>div>div:nth-child(4) div');
  labels.forEach((box) => {
    box.style.fontSize = '9px';
  });
  const canvas = clone.querySelector('#savings-over-time-chart-canvas');
  if (canvas) {
    canvas.classList.remove('.chartjs-render-monitor');
    canvas.style.paddingRight = '75px';
  }
  clone.querySelectorAll('div').forEach((item) => {
    item.style.backgroundColor = 'white';
    item.style.boxShadow = 'none';
  });
};

export const onTotalGraph = (clone, currentPage) => {
  const base = currentPage === 'proposal'
    ? clone.querySelector('#savings-tabpanel-0')
    : clone.querySelector('.year-savings');
  base.style.width = '1000px';
  base.style.padding = '50px';

  if (currentPage === 'proposal') {
    base.style.textAlign = 'center';
    base.style.padding = '100px';
  }

  const overtimeLabel = clone.querySelector('#savingsOverTimeGraph');
  if (overtimeLabel) overtimeLabel.remove();
  const totalLabel = clone.querySelector('#totalSavingsGraph > span');

  if (currentPage === 'proposal') {
    const title = clone.createElement('span');
    title.innerHTML = 'TOTAL SAVINGS';
    title.style.fontWeight = 'bold';
    title.style.border = '0';
    title.style.fontSize = '14px';

    clone.querySelector('#savings-tabpanel-0').prepend(title);
  }

  if (totalLabel) {
    totalLabel.style.fontWeight = 'bold';
    totalLabel.style.border = '0';
  }
  const totalsGraph = currentPage === 'proposal'
    ? clone.querySelector('#savings-tabpanel-0>:first-child')
    : clone.querySelector('#total-savings').parentElement;
  totalsGraph.style.width = '800px';
  totalsGraph.style.paddingRight = '100px';
  totalsGraph.querySelectorAll('div').forEach((box) => {
    box.style.whiteSpace = 'nowrap';
  });
  const icons = totalsGraph.querySelectorAll(':scope>div>div:nth-child(4) img');
  icons.forEach((img) => {
    img.style.width = '14px';
  });
  const labels = totalsGraph.querySelectorAll(':scope>div>div:nth-child(4) div');
  labels.forEach((box) => {
    box.style.fontSize = '9px';
  });
  const canvas = clone.querySelector('#total-savings-chart-canvas');
  if (canvas) {
    canvas.classList.remove('.chartjs-render-monitor');
    canvas.style.paddingRight = '75px';
  }
  clone.querySelectorAll('div').forEach((item) => {
    item.style.backgroundColor = 'white';
    item.style.boxShadow = 'none';
  });
};
