import React from 'react';
import { register } from '../../utils/redux';
import StorageSelectionPage from './StorageSelectionPage';
import StorageSelectionPageWithMultiInverter from './StorageSelectionPageWithMultiInverters';
import { storageExpansionPackEnabledForStorage } from '../../utils/backendFeatureFlags';
import { storageQuoteType } from '../../types';
import { showStorageWithExpPack } from '../../utils/StorageWithMultiInverters/helpers';

export function StorageSelectionPageContainer({ storageQuote }) {
  return (
    <div>
      {showStorageWithExpPack(storageExpansionPackEnabledForStorage(), storageQuote) ? (
            storageQuote.sfid && (
              <StorageSelectionPageWithMultiInverter />
            )
      ) : (
        <StorageSelectionPage />
        )}
    </div>
  );
}

StorageSelectionPageContainer.propTypes = {
  storageQuote: storageQuoteType.isRequired
};

export default register(
  ['storageQuoteSelector'],
  [],
  StorageSelectionPageContainer
);
