import createSelector from '../utils/createSelector';

export function designVersion(state) {
  return {
    designVersion: state.designVersion
  };
}

export default createSelector(
  designVersion
);
