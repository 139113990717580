import React from 'react';
import PropTypes from 'prop-types';
import Tab from './Tab';

export default function ProductSelectionModalTabs({
  activeTab,
  toggle
}) {
  return (
    <div className="product-selection-modal-tabs">
      <Tab
        activeTab={activeTab}
        toggle={toggle}
        tabName="activeTab"
        tabValue="modules"
        text="Modules"
      />
      <Tab
        activeTab={activeTab}
        toggle={toggle}
        tabName="activeTab"
        tabValue="inverters"
        text="Inverters & Racking"
      />
      <Tab
        activeTab={activeTab}
        toggle={toggle}
        tabName="activeTab"
        tabValue="monitoring"
        text="Monitoring"
      />
      <Tab
        activeTab={activeTab}
        toggle={toggle}
        tabName="activeTab"
        tabValue="storage"
        text="Storage"
      />
      <Tab
        activeTab={activeTab}
        toggle={toggle}
        tabName="activeTab"
        tabValue="accessories"
        text="Accessories"
      />
    </div>
  );
}

ProductSelectionModalTabs.propTypes = {
  activeTab: PropTypes.string.isRequired,
  toggle: PropTypes.func.isRequired
};
