import d3 from 'd3';

const getGraphWidth = (node) => {
  try {
    return node.getBBox().width;
  } catch (_e) {
    // getBBox() fails in Firefox when node is hidden
    return 0;
  }
};

export default () => {
  const node = d3.select('g.c3-axis-y').node();
  /**
   * This condition is added because:
   * - after user locks the quote an overlay appears
   * - after overlay disappears the chart is re-drawn
   * - when a user (or, most likely, a test) clicks "Show Proposal"
   *   fast enough, then redrawing is not done, and we get an error:
   *   Uncought TypeError: Cannot read property 'getBBox' of null
   */
  if (node) {
    const width = getGraphWidth(node);
    const legends = document.getElementsByClassName('two-lines-legend');
    legends[0].style.paddingLeft = `${Math.round(width) + 20}px`;
    legends[1].style.paddingLeft = `${Math.round(width) + 20}px`;
  }
};
