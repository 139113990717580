export function toggleModuleSelectionModal() {
  return {
    type: 'toggleModuleSelectionModal',
    payload: {
      design: {
        moduleSelectionModal: (currentValue) => !currentValue
      }
    }
  };
}
