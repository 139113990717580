import { get, has } from 'lodash';
import u from 'updeep';
import { extractError } from './errors';
import initializeSurvicateIfNecessary from '../utils/survicate';
import { padInstantDesign } from '../utils/InstantQuote/helpers';

export async function requestWithFinally(request, afterRequest, errorRequest) {
  let result;
  try {
    result = await request;
    if (has(result, 'userData.sfid')) {
      initializeSurvicateIfNecessary(get(result, 'userData.sfid'));
    }
    if (has(result, 'design.isInstant') && result.design.isInstant) {
      result = u(result, padInstantDesign(result.design));
    }
    result = u(afterRequest, result);
  } catch (error) {
    result = u(errorRequest, extractError(error));
  }

  return u({ mode: afterRequest.mode || null, loading: false }, result);
}

export async function requestWithNoExplicitUpdate(request, afterRequest, errorRequest) {
  let result;
  try {
    result = await request;
    if (has(result, 'userData.sfid')) {
      initializeSurvicateIfNecessary(get(result, 'userData.sfid'));
    }
    result = u(afterRequest, result);
  } catch (error) {
    result = u(errorRequest, extractError(error));
  }

  return result;
}

export function asyncPayload({ beforeRequest = {}, request, afterRequest = {}, errorRequest = {} }) {
  return {
    data: beforeRequest,
    promise: requestWithFinally(request, afterRequest, errorRequest)
  };
}

export function asyncPayloadWithNoExplicitUpdate({
  beforeRequest = {},
  request, afterRequest = {},
  errorRequest = {}
}) {
  return {
    data: beforeRequest,
    promise: requestWithNoExplicitUpdate(request, afterRequest, errorRequest)
  };
}
