import camelCase from 'lodash/camelCase';

export const CASH = 'cash';
export const SPWR_CASH = 'spwrCash';
export const LEASE = 'lease';
export const LOAN = 'loan';
export const PPA = 'ppa';

export const isCash = (financeType) => camelCase(financeType) === CASH;
export const isSpwrCash = (financeType) => camelCase(financeType) === SPWR_CASH;
export const isLease = (financeType) => camelCase(financeType) === LEASE;
export const isLoan = (financeType) => camelCase(financeType) === LOAN;
export const isPpa = (financeType) => camelCase(financeType) === PPA;
