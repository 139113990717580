import { asyncPayloadWithNoExplicitUpdate } from './apiHelpers';
import Api from '../utils/Api';

export const updateSalesforceQuote = (quoteSfid, showLoader = false, quoteWizard = null) => (dispatch) => {
  const promise = Api.updateWithErrorHandling(`salesforce_quotes/${quoteSfid}`, {
    quoteWizard
  })
    .then((response) => {
      return response;
    });

  dispatch({
    type: 'updateSalesforceQuote',
    payload: asyncPayloadWithNoExplicitUpdate({
      beforeRequest: {
        showLoader
      },
      request: promise,
      afterRequest: {
        showLoader: false
      }
    })
  });
};
