import React from 'react';
import PropTypes from 'prop-types';
import Selection from '../Selection';
import { register } from '../../../../utils/redux';
import { productSelectionModalType } from '../../../../types';

export function ModuleSelection() {
  const lorem = (<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt
          ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco
          laboris nisi ut aliquip ex ea commodo consequat.</p>);

  return (
    <div className="sidebar-selections">
      <Selection title="Module Series">
        {lorem}
      </Selection>
      <Selection title="Back Sheet Type">
        {lorem}
      </Selection>
      <Selection title="Model" option="350W">
        {lorem}
      </Selection>
    </div>
  );
}

ModuleSelection.propTypes = {
  productSelectionModal: productSelectionModalType.isRequired,
  updateProductSelectionModal: PropTypes.func.isRequired
};

export default register(
  [
    'productSelectionModalSelector'
  ],
  [
    'updateProductSelectionModal'
  ],
  ModuleSelection
);
