import u from 'updeep';

export function toggleArray({ arrayNumber }) {
  return {
    type: 'toggleArray',
    payload: {
      design: {
        moduleArrays: {
          [arrayNumber - 1]: {
            panels: u.ifElse(
              (panels) => panels.some((p) => p.enabled),
              u.updateIn('*.enabled', false),
              u.updateIn('*.enabled', true)
            )
          }
        },
        hasChanges: true
      },
      quote: {
        finEng: null
      },
      moduleChanged: true
    }
  };
}
