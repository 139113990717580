export const filterCreditProducts = (products, aprPercentage, term) => {
  return products.filter((product) => (aprPercentage === product.choiceRateC) && (term === product.loanMonthC));
};

export const filterCreditProductsByTerm = (products, term) => {
  return products.filter((product) => term === product.loanMonthC);
};

export const findCreditProductByTermAndAprType = (products, term, aprType) => {
  return products.find((product) => term === product.loanMonthC && aprType === product.aprTypeC);
};

export const filterAprsByTerm = (products, term) => {
  products = filterCreditProductsByTerm(products, term);
  return products.map((product) => {
    return product.aprTypeC;
  }).sort();
};

export const filterAprPercentagesByTerm = (products, term) => {
  products = products.filter((product) => term === product.loanMonthC);
  return products.map((product) => {
    return product.choiceRateC;
  }).sort();
};

export const isValidProduct = (products, apr, aprPercentage, term) => {
  products = products.filter((product) =>
    (apr === product.aprTypeC) &&
    (aprPercentage === product.choiceRateC) &&
    (term === product.loanMonthC));
  return products.length > 0;
};
