import { setError } from '../actions//errors';
import { fetchFinEng } from '../actions/fetchFinEng';
import notifications from '../selectors/notifications';
import constants from '../utils/EddieCalculations/constants';
import { isCash } from '../utils/EddieCalculations/helpers/financeTypes';
import getAdders from '../utils/getAdders';
import { validateModuleAndFinanceOption } from '../utils/validations/validateSettings';

const {
  LEASE_FINANCE_TYPE,
  PPA_FINANCE_TYPE
} = constants;

const validateAndFetchFinEng = (
  quote,
  design,
  setting,
  systemAdders,
  activeTab,
  selectedPvModule
) => {
  const message = validateModuleAndFinanceOption(selectedPvModule, activeTab);
  if (message) {
    setError({ message });
    return;
  }
  fetchFinEng(quote, design, setting, systemAdders, activeTab);
};

const onPricePerKhwChange = (
  design,
  financialOption,
  newValue,
  quote,
  selectedPvModule,
  setting,
  systemAdders,
  updateSettings
) => {
  updateSettings(financialOption, 'pricePerKwh', newValue);
  updateSettings(financialOption, 'pricePerKwhCalculationValue', newValue);
  if (financialOption === LEASE_FINANCE_TYPE) {
    updateSettings(financialOption, 'leaseChanged', financialOption === LEASE_FINANCE_TYPE);
  } else if (financialOption === PPA_FINANCE_TYPE) {
    updateSettings(financialOption, 'ppaChanged', financialOption === PPA_FINANCE_TYPE);
  }
  updateSettings(financialOption, 'needsFinEng', true);
  validateAndFetchFinEng(quote, design, setting, systemAdders, financialOption, selectedPvModule);
};

const onPricePerWattChange = (
  newValue,
  financialOption,
  updateSettings
) => {
  updateSettings(financialOption, 'isChanging', true);
  if (isCash(financialOption)) updateSettings(financialOption, 'pricePerWattWithMonitoringCost', newValue);
  updateSettings(financialOption, 'pricePerWatt', newValue);
  updateSettings(financialOption, 'pricePerWattCalculationValue', newValue);
  if (financialOption === LEASE_FINANCE_TYPE) {
    updateSettings(financialOption, 'leaseChanged', financialOption === LEASE_FINANCE_TYPE);
  } else if (financialOption === PPA_FINANCE_TYPE) {
    updateSettings(financialOption, 'ppaChanged', financialOption === PPA_FINANCE_TYPE);
  }
};

const onDealerFeePerWattChange = (
  newValue,
  financialOption,
  updateSettings
) => {
  updateSettings(financialOption, 'dealerFeePerWatt', newValue);
  updateSettings(financialOption, 'dealerFeePerWattCalculationValue', newValue);
  if (financialOption === LEASE_FINANCE_TYPE) {
    updateSettings(financialOption, 'leaseChanged', financialOption === LEASE_FINANCE_TYPE);
  } else if (financialOption === PPA_FINANCE_TYPE) {
    updateSettings(financialOption, 'ppaChanged', financialOption === PPA_FINANCE_TYPE);
  }
};


const updateDefaults = ({
  design,
  dollarValue,
  financialOption,
  quote,
  selectedPvModule,
  settings,
  systemAdders,
  updateSettings,
  selector
}) => {
  const setting = settings[financialOption];
  const previous = setting.userInput;
  if (!previous) {
    const isShown = setting.showCustomer;
    if (!isShown) updateSettings(financialOption, 'showCustomer', true);
    const isSelected = setting.systemPriceSelection === selector;
    if (!isSelected) updateSettings(financialOption, 'systemPriceSelection', selector);
    if (selector === 'pricePerKwh') {
      onPricePerKhwChange(
        design,
        financialOption,
        dollarValue,
        quote,
        selectedPvModule,
        setting,
        systemAdders,
        updateSettings
      );
    }
    if (selector === 'pricePerWatt') {
      onPricePerWattChange(dollarValue, financialOption, updateSettings);
    }
    if (selector === 'dealerFeePerWatt') {
      onDealerFeePerWattChange(dollarValue, financialOption, updateSettings);
    }
  }
};

export const setPricingsControls = (
  account,
  design,
  quote,
  pvModules,
  settings,
  updateSettings
) => {
  if (quote.pricingControlsConfigs && quote.pricingControlsConfigs.length > 0 && !quote.locked) {
    const setDefaults = [];
    const reversedControls = [...quote.pricingControlsConfigs].reverse();
    const utilitiesFilter = (financial, po, utility, sku) => {
      return reversedControls.filter((control) => {
        const sameUtility = control.market.toLowerCase() === utility.toLowerCase();
        const sameFinancial = control.finType.toLowerCase() === financial.toLowerCase();
        const samePO = control.pricingOption.toLowerCase() === po.toLowerCase();
        const sameSku = control.sku.toLowerCase() === sku.toLowerCase();
        return sameUtility && sameFinancial && samePO && sameSku;
      });
    };
    reversedControls.forEach((control) => {
      const {
        market,
        finType,
        sku,
        dollarValue,
        pricingOption,
        minSystemSize,
        maxSystemSize
      } = control;
      const financialOption = finType.toLowerCase();
      const systemSizeKW = design.systemSize / 1000;
      const hasLimits = minSystemSize || maxSystemSize;
      const minValidation = !minSystemSize || systemSizeKW > minSystemSize;
      const maxValidation = !maxSystemSize || systemSizeKW < maxSystemSize;
      const rangeValidation = systemSizeKW && minValidation && maxValidation;
      const inSizeRange = !hasLimits || rangeValidation;
      const selectedPvModule = pvModules.find((pvModule) => pvModule.itemId === quote.pvModuleItemId);
      const samePVModule = selectedPvModule && selectedPvModule.itemId && sku === selectedPvModule.itemId;
      const isState = market.length === 2;
      const quoteMarket = isState ? account.state : account.utilityDescription;
      const stateUtility = isState ?
        utilitiesFilter(financialOption, pricingOption, account.utilityDescription, selectedPvModule.itemId)
        : [];
      const sameMarket = stateUtility.length === 0 && market.toLowerCase() === quoteMarket.toLowerCase();
      if (inSizeRange && sameMarket && samePVModule && !setDefaults.includes(financialOption)) {
        const systemAdders = getAdders(account, notifications);
        const options = {
          design,
          dollarValue,
          financialOption,
          quote,
          selectedPvModule,
          settings,
          systemAdders,
          updateSettings
        };
        if (pricingOption.toLowerCase() === 'pricePerWatt'.toLowerCase()) {
          updateDefaults({
            ...options,
            selector: 'pricePerWatt'
          });
          setDefaults.push(financialOption);
        }
        const isActivePricing = pricingOption === 'pricePerKwh'.toLowerCase();
        const isLeaseOrPpa = [ 'lease', 'ppa' ].includes(financialOption);
        if (isActivePricing && isLeaseOrPpa) {
          updateDefaults({
            ...options,
            selector: 'pricePerKwh'
          });
          setDefaults.push(financialOption);
        }
        if (pricingOption === 'dealerFeePerWatt'.toLowerCase()) {
          updateDefaults({
            ...options,
            selector: 'dealerFeePerWatt'
          });
          setDefaults.push(financialOption);
        }
      }
    });
  }
};

const isSizeInRange = (minSystemSize, systemSize, maxSystemSize) => {
  if (!minSystemSize && !maxSystemSize) {
    return true;
  }
  if (!minSystemSize) {
    return systemSize <= maxSystemSize;
  }
  if (!maxSystemSize) {
    return systemSize >= minSystemSize;
  }
  return systemSize >= minSystemSize && systemSize <= maxSystemSize;
};

export const hasPriceControlConfig = ({
  quote,
  financeType,
  pricingOption,
  account,
  design
}) => {
  const market = account && account.state;
  const systemSize = design && design.systemSize / 1000;
  return quote && quote.pricingControlsConfigs && quote.pricingControlsConfigs.filter((pc) => {
    const sameMarket = pc.market.toLowerCase() === market.toLowerCase();
    const sameSku = pc.sku === quote.pvModuleItemId;
    const samePO = pc.pricingOption.toLowerCase() === pricingOption.toLowerCase();
    const sameFO = pc.finType.toLowerCase() === financeType.toLowerCase();
    const inSizeRange = isSizeInRange(Number(pc.minSystemSize), Number(systemSize), Number(pc.maxSystemSize));
    return sameMarket && sameSku && sameFO && samePO && inSizeRange;
  });
};
