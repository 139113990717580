import browser from './browser';

export default { printBlob };

/**
 * printBlob will create if not exists an iframe to load
 * the pdf. Once the window is loaded, the PDF is printed.
 * It then creates a one-time event to remove the iframe from
 * the window.
 * @param {string} src Blob or any printable url.
 */
function printBlob(src) {
  if (typeof window === 'undefined') {
    throw new Error('You cannot print url without defined window.');
  } else if (browser.isFirefox) {
    // firefox incorrectly interprets window.print as a x-origin
    // vioation, so best we can do here is open pdf in new tab.
    window.open(src);
    return;
  }
  const iframeId = 'pdf-print-iframe';
  let iframe = document.getElementById(iframeId);
  if (!iframe) {
    iframe = document.createElement('iframe');
    iframe.setAttribute('id', iframeId);
    iframe.setAttribute('style', 'position:absolute;left:-9999px');
    document.body.append(iframe);
  }
  iframe.setAttribute('src', src);
  iframe.addEventListener('load', () => {
    iframe.contentWindow.focus();
    iframe.contentWindow.print();
    const removeIframe = () => {
      iframe.parentElement.removeChild(iframe);
      window.removeEventListener('focus', removeIframe);
    };
    window.addEventListener('focus', removeIframe);
  });
}
