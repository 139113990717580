import PropTypes from 'prop-types';
import React from 'react';
import DownloadIcon from '../../../../images/download.svg';
import { downloadCSV } from '../../../utils/uploaderHelper';

export function DownloadableList({
  financialOfferings,
  utilities
}) {
  const skuKeys = Object.keys(financialOfferings);
  const parsedSKUs = skuKeys.map((finance) => {
    const skuFOs = Object.keys(financialOfferings[finance]);
    return skuFOs ? skuFOs.map((state) => {
      const sku = financialOfferings[finance][state].module;
      return sku ? sku.map((item) => {
        return {
          financeOption: finance.toLowerCase(),
          state: state.toUpperCase(),
          sku: item.moduleItemId,
          series: item.series,
          name: item.name
        };
      }) : [];
    }) : [];
  }).flat().flat().sort((a, b) => {
    return (a.financeOption && a.financeOption.localeCompare(b.financeOption)) ||
           (a.state && a.state.localeCompare(b.state)) ||
           (a.series && a.series.localeCompare(b.series)) ||
           (a.name && a.name.localeCompare(b.name));
  });

  const parsedUtilities = utilities ? utilities.map((utility) => {
    return {
      state: utility.state,
      name: utility.name
    };
  }).sort((a, b) => {
    return a.state.localeCompare(b.state) || a.name.localeCompare(b.name);
  }) : [];


  const DownloadUtilityList = () => (
    <span className="download-bar">
      Download Utility List
      <button
        className="btn btn-secondary download"
        onClick={() => {
          downloadCSV(parsedUtilities, 'Available Markets.csv');
        }}
      >
        <DownloadIcon />
      </button>
    </span>
  );

  const DownloadSKUList = () => (
    <span className="download-bar">
      Download SKU List
      <button
        className="btn btn-secondary download"
        onClick={() => {
          downloadCSV(parsedSKUs, 'Available SKUs.csv');
        }}
      >
        <DownloadIcon />
      </button>
    </span>
  );

  return (
    <div className="downloads-list">
      <div className="download-element download-utility">
        <b>Utility List</b>
        <span>
          Full list of utilities with appropriate ID numbers.
        </span>
        <DownloadUtilityList />
      </div>
      <div className="download-element download-sku">
        <b>SKU List</b>
        <span>
          Full list of modules to sell and the appropriate SKU number
        </span>
        <DownloadSKUList />
      </div>
    </div>
  );
}

DownloadableList.propTypes = {
  utilities: PropTypes.arrayOf(PropTypes.object),
  financialOfferings: PropTypes.arrayOf(PropTypes.object)
};
