import { get } from 'lodash';

import { evChargerEnabled } from '../backendFeatureFlags';
import constants from '../EddieCalculations/constants';

const {
  EV_CHARGER_TYPE,
  EV_OUTLET_TYPE,
  EV_CHARGER,
  EV_OUTLET
} = constants;

const OUTLET_PRICE = 0;

export const MAX_WALLBOX_CHARGERS_ALLOWED = 2;
export const MAX_OUTLETS_ALLOWED = 2;

export const getEvSelectionImageName = (chargerSelected) => {
  return chargerSelected ? 'ev_charger_logo' : 'ev_outlet_logo';
};

export const getEvChargerAllowedQuantityList = () => {
  return Array.from({ length: MAX_WALLBOX_CHARGERS_ALLOWED }, (v, i) => i + 1);
};

export const getEvOutletAllowedQuantityList = (evmChargerConfigurations, evChargerQuantity) => {
  const allowedValues = Array.from({ length: MAX_OUTLETS_ALLOWED }, (v, i) => i + 1);
  switch (evChargerQuantity) {
    case 1:
      allowedValues.splice(allowedValues.indexOf(2), 1);
      return allowedValues;
    case 2:
      allowedValues.splice(allowedValues.indexOf(1), 1);
      allowedValues.splice(allowedValues.indexOf(2), 1);
      return allowedValues;
    default:
      return allowedValues;
  }
};

export const getEvChargerList = (evmChargerConfigurations) => {
  return evmChargerConfigurations.filter((configuration) => {
    return configuration.type && configuration.type.toLowerCase() === EV_CHARGER_TYPE;
  });
};

export const getEvOutletList = (evmChargerConfigurations) => {
  return evmChargerConfigurations.filter((configuration) => {
    return configuration.type && configuration.type.toLowerCase() === EV_OUTLET_TYPE;
  });
};


export const getEvChargerConfigs = (evmChargerConfigurations) => {
  const configuration = evmChargerConfigurations.filter((configuration) => {
    return configuration.type &&
      configuration.type.toLowerCase() === EV_CHARGER_TYPE;
  });
  return configuration.length > 0 ? configuration : null;
};

export const getEvChargerConfigId = (evmChargerConfigurations) => {
  const configuration = evmChargerConfigurations.filter((configuration) => {
    return configuration.type &&
        configuration.type.toLowerCase() === EV_CHARGER_TYPE;
  });
  return configuration.length > 0 ? configuration[0].configId : null;
};

export const getEvChargerConfigIds = (evmChargerConfigurations, selectedWallbox) => {
  const configuration = evmChargerConfigurations.filter((configuration) => {
    if (configuration.configName) {
      return configuration.type &&
        configuration.type.toLowerCase() === EV_CHARGER_TYPE &&
        configuration.configName.includes(`${selectedWallbox}A`);
    }
    return configuration.type &&
        configuration.type.toLowerCase() === EV_CHARGER_TYPE;
  });
  return configuration.length > 0 ? configuration[0].configId : null;
};

export const getEvChargerConfigName = (evmChargerConfigurations, selectedWallbox) => {
  const configuration = evmChargerConfigurations.filter((configuration) => {
    if (configuration.configName) {
      return configuration.type &&
      configuration.type.toLowerCase() === EV_CHARGER_TYPE &&
      configuration.configName.includes(`${selectedWallbox}A`);
    }
    return configuration.type &&
      configuration.type.toLowerCase() === EV_CHARGER_TYPE;
  });
  return configuration.length > 0 ? configuration[0].configName : null;
};

export const getOutletConfigId = (evmChargerConfigurations) => {
  const configuration = evmChargerConfigurations.filter((configuration) => {
    return configuration.type && configuration.type.toLowerCase() === EV_OUTLET_TYPE;
  });
  return configuration.length > 0 ? configuration[0].configId : null;
};

export const getOutletConfigName = (evmChargerConfigurations) => {
  const configuration = evmChargerConfigurations.filter((configuration) => {
    return configuration.type &&
    configuration.type.toLowerCase() === EV_OUTLET_TYPE;
  });
  return configuration.length > 0 ? configuration[0].configName : null;
};

export const getWallBoxAllowedTransitions = (evmChargerConfigurations, wallBoxQuantity, outletQuantity) => {
  const wallBoxId = getEvChargerConfigId(evmChargerConfigurations);
  let additionAllowed = !!wallBoxId;
  let subtractionAllowed = !!wallBoxId;
  if (!wallBoxId) {
    return {
      additionAllowed,
      subtractionAllowed
    };
  }

  switch (wallBoxQuantity) {
    case 0:
      subtractionAllowed = false;
      additionAllowed = outletQuantity !== MAX_OUTLETS_ALLOWED;
      break;
    case 1:
      subtractionAllowed = true;
      additionAllowed = outletQuantity === 0;
      break;
    case 2:
      subtractionAllowed = true;
      additionAllowed = false;
      break;
    default:
      subtractionAllowed = false;
      additionAllowed = false;
  }

  return {
    additionAllowed,
    subtractionAllowed
  };
};

export const getOutletAllowedTransitions = (evmChargerConfigurations, wallBoxQuantity, outletQuantity) => {
  const outletId = getOutletConfigId(evmChargerConfigurations);
  let additionAllowed = !!outletId;
  let subtractionAllowed = !!outletId;
  if (!outletId) {
    return {
      additionAllowed,
      subtractionAllowed
    };
  }

  switch (outletQuantity) {
    case 0:
      subtractionAllowed = false;
      additionAllowed = (wallBoxQuantity < MAX_WALLBOX_CHARGERS_ALLOWED);
      break;
    case 1:
      subtractionAllowed = true;
      additionAllowed = (wallBoxQuantity < 1);
      break;
    case 2:
      subtractionAllowed = true;
      additionAllowed = false;
      break;
    default:
      subtractionAllowed = false;
      additionAllowed = false;
  }

  return {
    additionAllowed,
    subtractionAllowed
  };
};

export const showEvCharger = (partnerIsNewHomes, quote, businessFunctions) => {
  return evChargerEnabled() && !partnerIsNewHomes && quote.version > 7 &&
    businessFunctions && businessFunctions.includes('EV Charger Enabled');
};

export const getEvChargerItemPrice = (configId, evmChargerConfigurations, quote, partnerIsRvar, quantity) => {
  const selectedConfig = evmChargerConfigurations.find((configuration) => {
    return configuration.configId === configId;
  });

  if (selectedConfig) {
    const evChargerPriceItem = quote.evChargerPrice.find((i) => i.inventoryItemId === selectedConfig.inventoryItemId);

    const laborCost = parseFloat(selectedConfig.laborCost);
    const price = selectedConfig.type.toLowerCase() === EV_OUTLET_TYPE
      ? OUTLET_PRICE : get(evChargerPriceItem, 'price', 0);

    return quantity * (price + (partnerIsRvar ? 0 : laborCost));
  }
  return 0;
};

export const isEvChargerSelected = (quote) => {
  return get(quote, 'evChargerConfigs', []).filter((c) =>
    c.productName === EV_CHARGER && c.quantity > 0).length > 0;
};

export const isEvOutletSelected = (quote) => {
  return get(quote, 'evChargerConfigs', []).filter((c) =>
    c.productName === EV_OUTLET && c.quantity > 0).length > 0;
};

export const calculatedEvChargerSize = (quote, constants) => {
  const selectedEvConfigs = get(quote, 'evChargerConfigs', []).filter((c) => c.productName === EV_CHARGER);
  const quantity = selectedEvConfigs.length > 0 && selectedEvConfigs[0].quantity;
  let evChargerSize = constants.evChargerSize40;
  if (selectedEvConfigs[0] && selectedEvConfigs[0].configName) {
    if (selectedEvConfigs[0].configName.includes('48A')) { evChargerSize = constants.evChargerSize48; }
  }
  return constants && (evChargerSize * quantity);
};
