import React, { useState } from 'react';
import PropTypes from 'prop-types';

import Grid from '@material-ui/core/Grid';

import get from 'lodash/get';
import omit from 'lodash/omit';
import cn from 'classnames';

import SavingsOverTimeChart from '../charts/SavingsOverTimeChart';

import { formatCurrency } from '../../utils/formatNumber';
import { isSpecialState } from '../../utils/chart';

import EscalatorSelection from './EscalatorSelection';

import styles from '../../../scss/charts/SavingsPerYearModule.module.scss';

const legendText = {
  cash: {
    with: 'Cost of Electricity With Solar',
    without: 'Cost of Electricity Without Solar',
    savings: 'Net Savings',
    gridSize: 4,
    downLanguage: 'Upfront investment',
    paymentIncludes: 'Includes net investment'
  },
  loan: {
    with: 'Electricity Cost With Solar',
    without: 'Electricity Cost Without Solar',
    savings: 'Net Savings',
    endTerm: 'End of Loan Term',
    gridSize: 3,
    downLanguage: 'Co-payment',
    paymentIncludes: 'Includes co-payment'
  },
  lease: {
    with: 'Electricity Cost With Solar',
    without: 'Electricity Cost Without Solar',
    savings: 'Net Savings',
    endTerm: 'End of Lease Term',
    gridSize: 3,
    downLanguage: 'Down-payment',
    paymentIncludes: 'Includes down payment'
  },
  ppa: {
    with: 'Electricity Cost With Solar',
    without: 'Electricity Cost Without Solar',
    savings: 'Net Savings',
    endTerm: 'End of PPA Term',
    gridSize: 3,
    downLanguage: 'Down-payment',
    paymentIncludes: 'Includes down payment'
  }
};

const savingsPerYearModule = ({
  savingsChart,
  selectedFinanceOption,
  selectedInflationRate,
  readOnlyInflationRate,
  inflationRateSelectOptions,
  onChangeRate,
  isPDFProcessing,
  selectedStateOption,
  energyOffsetPc,
  pricePerKwh,
  term,
  systemCost,
  isStorageSelected,
  chartTerm
}) => {
  const allSavingsChartData = get(savingsChart, `${selectedInflationRate}.${selectedFinanceOption}`, {});
  const annualBillsAfter = get(allSavingsChartData, 'graphSeries.annualBillsAfter');
  const maxChartXIndex = (annualBillsAfter && annualBillsAfter.length) || 25;
  const [ chartXIndex, setChartXIndex ] = useState(maxChartXIndex - 1);
  const withStorageText = isStorageSelected ? ' + Storage' : '';

  // TODO: don't send these values from backend
  const savingsChartData = {
    ...allSavingsChartData,
    graphSeries: omit(allSavingsChartData.graphSeries, [ 'rebatesAndCredits', 'savings', 'costs', 'ratePerKwh' ])
  };

  const cumulativeSavings = () => {
    let sum = 0;
    if (selectedFinanceOption === 'cash') {
      sum -= systemCost.upfrontCost;
      sum += savingsChartData.legend.rebatesAndCredits;
    }
    if (selectedFinanceOption === 'loan') {
      sum -= systemCost.coPayment;
    }
    if (selectedFinanceOption === 'lease') {
      sum -= systemCost.downPayment;
      sum += savingsChartData.legend.rebatesAndCredits;
    }
    if (selectedFinanceOption === 'ppa') {
      sum -= systemCost.downPayment;
      sum += savingsChartData.legend.rebatesAndCredits;
    }
    for (let index = 0; index < chartXIndex + 1; index++) {
      sum +=
        savingsChartData.graphSeries.annualBillsBefore[index]
        - savingsChartData.graphSeries.annualBillsAfter[index];
    }
    return sum;
  };

  const cumulativeSavingsIncludes = () => {
    if (selectedFinanceOption === 'cash') {
      return (
        <div className={styles.cumulativeSubText}>
          {legendText[`${selectedFinanceOption}`].paymentIncludes}
        </div>
      );
    }
    if (selectedFinanceOption === 'loan' && systemCost.coPayment !== 0) {
      return (
        <div className={styles.cumulativeSubText}>
          {legendText[`${selectedFinanceOption}`].paymentIncludes}
        </div>
      );
    }
    if (selectedFinanceOption === 'lease' && systemCost.downPayment !== 0) {
      return (
        <div className={styles.cumulativeSubText}>
          {legendText[`${selectedFinanceOption}`].paymentIncludes}
        </div>
      );
    }
    if (selectedFinanceOption === 'ppa' && systemCost.downPayment !== 0) {
      return (
        <div className={styles.cumulativeSubText}>
          {legendText[`${selectedFinanceOption}`].paymentIncludes}
        </div>
      );
    }
    return null;
  };

  const inflationRateView = () => {
    return (
      <EscalatorSelection
        rates={inflationRateSelectOptions}
        inflationRate={selectedInflationRate}
        onChangeRate={onChangeRate}
        refNumber={1}
        readOnly={readOnlyInflationRate || isPDFProcessing}
      />
    );
  };

  return (
    <div className={styles.wrapper}>
      { isSpecialState(selectedStateOption)
        ? (<div className={styles.tagLineAZ}>
            Offset an estimated
            <span className={styles.tagLineSavings}>
              {energyOffsetPc}%
            </span>
            of your home usage with solar energy produced at ${pricePerKwh} per kWh.
            <sup className={styles.superscript}>3</sup>
        </div>
          )
        : <div className={styles.tagLine} id="estimated-savings">
            Save an estimated <span className={styles.tagLineSavings}>
              {formatCurrency(savingsChartData.legend.totalSavingsRounded, 0)}
            </span> over {chartTerm} years<sup className={styles.superscript}> 2</sup>
        </div>
      }
      <Grid
        container={true}
        justifyContent="center"
        className={styles.graphContainer}
      >
        <Grid item={true} xs={false} sm={1}>
          <div className={styles.graphName}>
            ANNUAL COST
          </div>
        </Grid>
        <Grid item={true} xs={12} sm={11}>
          <SavingsOverTimeChart
            data={savingsChartData}
            selectedInflationRate={selectedInflationRate}
            setChartXIndex={setChartXIndex}
            selectedFinanceOption={selectedFinanceOption}
            term={term}
            chartTerm={chartTerm}
            isPDFProcessing={isPDFProcessing}
          />
        </Grid>
      </Grid>
      <Grid
        container={true}
        className={styles.savingsContainer}
      >
        <Grid item={true} xs={12} className={styles.savingsYear}>
          <div>
            {`YEAR ${chartXIndex + 1} (estimates)`}
          </div>
        </Grid>
        <Grid item={true} xs={12} sm={9} className={styles.savingsFormula}>
          <Grid
            container={true}
          >
            <Grid item={true} xs={12} sm={8} className={styles.savingsFormulaLeft}>
              <div className={styles.itemContainer}>
                <div className={styles.itemContainerName}>
                  Annual Cost Without Solar
                </div>
                <div className={styles.itemContainerAmount} id="annual-cost-without-solar">
                  {formatCurrency(savingsChartData.graphSeries.annualBillsBefore[chartXIndex], 0)}
                </div>
              </div>
              <div className={styles.mathSymbol}>
                -
              </div>
              <div className={styles.itemContainer}>
                <div className={styles.itemContainerName}>
                  Annual Cost With Solar {withStorageText}
                </div>
                <div className={styles.itemContainerAmount} id="annual-cost-with-solar">
                  {formatCurrency(savingsChartData.graphSeries.annualBillsAfter[chartXIndex], 0)}
                </div>
              </div>
            </Grid>
            <Grid item={true} xs={12} sm={4} className={styles.savingsFormulaRight}>
              <div className={styles.mathSymbol}>
                =
              </div>
              <div className={styles.itemContainer}>
                <div className={styles.itemContainerName}>
                  Net Savings <sup>2</sup>
                </div>
                <div className={cn(styles.itemContainerAmount, styles.itemContainerSaving)} id="net-savings">
                  {formatCurrency(
                    savingsChartData.graphSeries.annualBillsBefore[chartXIndex]
                    - savingsChartData.graphSeries.annualBillsAfter[chartXIndex], 0
                  )}
                </div>
              </div>
            </Grid>
          </Grid>
        </Grid>
        <Grid item={true} xs={12} sm={3} className={styles.savingsCumulative}>
          <div className={styles.itemContainer}>
            <div className={styles.itemContainerName}>
              Cumulative Savings
            </div>
            <div className={cn(styles.itemContainerAmount, styles.itemContainerSaving)} id="cumulative-savings">
              {formatCurrency(cumulativeSavings(), 0)}
            </div>
            {cumulativeSavingsIncludes()}
          </div>
        </Grid>
      </Grid>
      <Grid
        container={true}
        className={styles.legendContainer}
        id="savings-legends"
      >
        <div className={styles.legendItem}>
          <div className={styles.legendItemImg}>
            <img src="/build/images/without_solar_cash_legend.svg" alt="without solar" />
          </div>
          <div className={styles.legendItemText}>
            {legendText[`${selectedFinanceOption}`].without}
          </div>
        </div>
        <div className={styles.legendItem}>
          <div className={styles.legendItemImg}>
            <img src="/build/images/with_solar_legend.svg" alt="with solar" />
          </div>
          <div className={styles.legendItemText}>
            {legendText[`${selectedFinanceOption}`].with}{withStorageText}
          </div>
        </div>
        {[ 'loan', 'lease', 'ppa' ].indexOf(selectedFinanceOption) > -1 && (
          <div className={styles.legendItem}>
            <div className={styles.legendItemImg}>
              <img src="/build/images/end_term_legend.svg" alt="end term" />
            </div>
            <div className={styles.legendItemText}>
              {legendText[`${selectedFinanceOption}`].endTerm}
            </div>
          </div>
        )}
        <div className={styles.legendItem}>
          <div className={styles.legendItemImg}>
            <img src="/build/images/net_savings_legend.svg" alt="net savings" />
          </div>
          <div className={styles.legendItemText}>
            {legendText[`${selectedFinanceOption}`].savings} <sup>2</sup>
          </div>
        </div>
      </Grid>
      <Grid
        container={true}
        className={styles.notesContainer}
        alignItems="center"
        id="savings-disclaimer"
      >
        <Grid item={true} xs={12} sm={7}>
          <div>
            {inflationRateView()}
          </div>
        </Grid>
        <Grid item={true} xs={12} sm={5}>
          <div>
            <b>{legendText[`${selectedFinanceOption}`].downLanguage}</b> is not represented in the graph
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

savingsPerYearModule.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  savingsChart: PropTypes.object,
  term: PropTypes.number,
  selectedFinanceOption: PropTypes.string,
  selectedInflationRate: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  systemCost: PropTypes.object,
  readOnlyInflationRate: PropTypes.bool,
  // eslint-disable-next-line react/forbid-prop-types
  inflationRateSelectOptions: PropTypes.array,
  onChangeRate: PropTypes.func,
  isPDFProcessing: PropTypes.bool,
  selectedStateOption: PropTypes.string,
  energyOffsetPc: PropTypes.number,
  pricePerKwh: PropTypes.number,
  chartTerm: PropTypes.number,
  isStorageSelected: PropTypes.bool
};

export default savingsPerYearModule;
