import React from 'react';
import { TransitionGroup, CSSTransition } from 'react-transition-group';

export function wrapWithTransitionGroup(name, enterTimeout, leaveTimeout, content) {
  // It's important to keep process.env.NODE_ENV variable as a whole, so webpack
  // DefinePlugin can inject the proper value
  const animations = process.env.NODE_ENV !== 'test';
  return animations ? (
    <TransitionGroup>
      { content ?
        <CSSTransition
          classNames={name}
          timeout={{ enter: enterTimeout, exit: leaveTimeout }}
        >
          {content}
        </CSSTransition>
        : null }
    </TransitionGroup>
  ) : content;
}
