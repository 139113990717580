import get from 'lodash/get';
import logger from '../utils/logger';

// When a locked quote does not have monitoringCongiruations saved
// to productConfiguration, fallback to just displaying the
// lockedMonitoringName.
const legacyLockedQuoteFauxMonitoringConfigurations = (quote) =>
  [{ name: get(quote, 'lockedMonitoringName', 'Not available'), id: '' }];

/**
 * Returns all possible monitoring configurations for quote from SFDC
 * product configuration service.
 */
export default (state) => {
  const { quote } = state;
  let monitoringConfigurations = get(state, 'productConfiguration.monitoringConfigurations', []);
  if (monitoringConfigurations.length === 0 && quote.locked) {
    monitoringConfigurations = legacyLockedQuoteFauxMonitoringConfigurations(quote);
  } else if (monitoringConfigurations.length === 0) {
    logger.error(`monitoringConfigurations empty for unlocked quote ${quote.sfid}.`);
    monitoringConfigurations = [{ name: 'Not available', id: '' }];
  }
  return {
    monitoringConfigurations
  };
};
