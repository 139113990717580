import React, { useEffect, useState } from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import { register } from '../../utils/redux';
import { currentStorageConfigurationType, pvModuleType, quoteType } from '../../types';
import {
  calculatedRatings,
  storageWithInverterTransitions
} from '../../utils/StorageWithMultiInverters/storageWithMultiInverters';
import { getBatteryImageName, getHomeImageName } from '../../utils/StorageWithMultiInverters/imageNames';
import RemoveStorageModalButton from '../RemoveStorageModalButton';

function StorageWithMultiInvertersSettings({
                                                    quote,
                                                    toggleAddStorageModalFlag,
                                                    updateStorage,
                                                    pvModule,
                                                    instantQuoteMode,
                                                    constantValues,
                                                    currentStorageConfiguration,
                                                    updateCurrentStorageConfiguration
                                                  }) {
  const [ storageQuantity, setStorageQuantity ] = useState(quote.storageQuantity || 1);
  const [ storageExpansionPackQuantity, setStorageExpansionPackQuantity ] =
    useState(quote.storageExpansionPackQuantity || 0);
  const [ powerBoost, setPowerBoost ] = useState(quote.powerBoost);
  const storageRatings = calculatedRatings(constantValues, storageQuantity, storageExpansionPackQuantity,
    currentStorageConfiguration);
  const [ energyRating, setEnergyRating ] = useState(storageRatings.energyRating);
  const [ powerRating, setPowerRating ] = useState(storageRatings.powerRating);
  const [ allowedTransition, setAllowedTransition ] =
    useState(storageWithInverterTransitions(pvModule.storageConfigurations,
      storageQuantity, storageExpansionPackQuantity, energyRating));
  const updateRatings = (storageQuantity, storageExpansionPackQuantity) => {
    if (currentStorageConfiguration && !currentStorageConfiguration.batterySize) {
      const updatedRatings = calculatedRatings(constantValues, storageQuantity, storageExpansionPackQuantity,
        currentStorageConfiguration);
      setEnergyRating(updatedRatings.energyRating);
      setPowerRating(updatedRatings.powerRating);
    }
  };

  useEffect(() => {
    setAllowedTransition(storageWithInverterTransitions(pvModule.storageConfigurations,
      storageQuantity, storageExpansionPackQuantity, energyRating));
    updateRatings(storageQuantity, storageExpansionPackQuantity);
  }, [ storageQuantity, storageExpansionPackQuantity, energyRating ]);

  const findStorageConfigById = (storageConfigId) => {
    return pvModule.storageConfigurations.find(
      (storage) => storage.storageConfigId === storageConfigId);
  };

  useEffect(() => {
    if (allowedTransition.storageQuantity !== storageQuantity) {
      setStorageQuantity(allowedTransition.storageQuantity);
    }
    if (allowedTransition.expansionPackQuantity !== storageExpansionPackQuantity) {
      setStorageExpansionPackQuantity(allowedTransition.expansionPackQuantity);
    }
    if (!allowedTransition.powerBoostAllowed) {
      setPowerBoost(false);
    } else {
      setPowerBoost(allowedTransition.powerBoostEnabled);
    }
    const storageConfig = findStorageConfigById(allowedTransition.storageConfigId);
    updateCurrentStorageConfiguration(storageConfig);
  }, [allowedTransition]);

  const onCancel = (event) => {
    event.preventDefault();
    toggleAddStorageModalFlag();
  };

  const findStorageConfig = () => {
    return pvModule.storageConfigurations.find(
      (storage) => storage.storageQuantity === storageQuantity &&
        storage.expansionPacks === storageExpansionPackQuantity &&
        (storage.batterySize === null || storage.batterySize === energyRating));
  };

  const findStorageConfigId = () => {
    const storageConfig = findStorageConfig();
    return (storageConfig ? storageConfig.storageConfigId : null);
  };

  const findStorageLaborCost = () => {
    const storageConfig = findStorageConfig();
    return (storageConfig ? storageConfig.laborCost : null);
  };

  const onSave = (event) => {
    event.preventDefault();
    if (quote.locked) return;
    const storageConfigId = findStorageConfigId();
    const storageLaborCost = findStorageLaborCost();
    const params = {
      storageConfigId,
      storageQuantity,
      storageBackup: quote.storageBackup || 'essential',
      storageExpansionPackQuantity,
      powerBoost,
      storageLaborCost
    };
    updateStorage(quote, params);
    toggleAddStorageModalFlag();
  };

  const removeStorage = () => {
    if (allowedTransition && allowedTransition.subtraction) {
      setStorageQuantity(allowedTransition.subtraction[0]);
      setStorageExpansionPackQuantity(allowedTransition.subtraction[1]);
      setEnergyRating(allowedTransition.subtraction[2]);
      setPowerRating(allowedTransition.subtraction[3]);
    }
  };

  const addStorage = () => {
    if (allowedTransition && allowedTransition.addition) {
      setStorageQuantity(allowedTransition.addition[0]);
      setStorageExpansionPackQuantity(allowedTransition.addition[1]);
      setEnergyRating(allowedTransition.addition[2]);
      setPowerRating(allowedTransition.addition[3]);
    }
  };

  const handlePowerBoostChange = (event) => {
    if (event) {
      if (event.target.checked) {
        if (allowedTransition.powerBoostEnabledState) {
          setPowerBoost(true);
          setStorageQuantity(allowedTransition.powerBoostEnabledState[0]);
          setPowerRating(allowedTransition.powerBoostEnabledState[3]);
          setStorageExpansionPackQuantity(allowedTransition.powerBoostEnabledState[1]);
        }
      } else if (allowedTransition.powerBoostDisabledState) {
        setPowerBoost(false);
        setStorageQuantity(allowedTransition.powerBoostDisabledState[0]);
        setPowerRating(allowedTransition.powerBoostDisabledState[3]);
        setStorageExpansionPackQuantity(allowedTransition.powerBoostDisabledState[1]);
      }
    }
  };
  return (
    <div className="modal-data-outer-container">
      <div className="modal-data-container">
        <div className="home-image-container">
          <img
            src={`${getHomeImageName(storageQuantity, storageExpansionPackQuantity)}.png`}
            alt="Home Visualization"
          />
        </div>
        <div className="storage-container">
          <div className="image-container">
            <img
              src={`${getBatteryImageName(storageQuantity, storageExpansionPackQuantity)}.png`}
              alt="Battery and Expansion Pack"
            />
          </div>
          <div className="storage-action-container">
            <button
              id="remove-storage"
              className={cx('action-btn delete', { disabled: !allowedTransition.subtractionAllowed || quote.locked })}
              onClick={() => !quote.locked && removeStorage()}
            >
              &#8722;
            </button>
            <div
              id="add-storage"
              className={cx('action-btn add', { disabled: !allowedTransition.additionAllowed || quote.locked })}
              onClick={() => !quote.locked && addStorage()}
            >
              &#43;
            </div>
          </div>
          <div className="power-boost-section">
            <div
              className={cx('heading-container',
              { disabled: !allowedTransition.powerBoostAllowed || quote.locked })}
            >
              { allowedTransition.showPowerBoost &&
                <div className="power-boost-heading">
                  <div className="heading">Power Boost</div>
                  <label className="switch">
                    <input
                      type="checkbox"
                      checked={powerBoost}
                      onChange={(event) => handlePowerBoostChange(event)}
                    />
                    <span className="slider round" />
                  </label>
                </div>
              }
            </div>
            <div className="information-text">
              Turn optimize for power on to back up more of the home’s
              needs for a longer period of time during an outage.
            </div>
          </div>
        </div>
        <div
          className={cx('system-breakdown-container', {
            'instant-quote-mode': instantQuoteMode
          })}
        >
          <div className="system-breakdown-heading">System Breakdown</div>
          <div className="system-breakdown">
            <div className="breakdown-unit border-right border-top">
              <div className="heading">Batteries</div>
              <div id="storage-count" className="value">{storageQuantity}</div>
            </div>
            <div className="breakdown-unit border-top">
              <div className="heading">Expansion Packs</div>
              <div id="expansion-count" className="value">{storageExpansionPackQuantity}</div>
            </div>
            <div className="breakdown-unit border-right border-top border-bottom">
              <div className="heading">Energy Rating</div>
              <div id="energy-rating" className="value">{energyRating}kWh</div>
            </div>
            <div className="breakdown-unit border-top border-bottom">
              <div className="heading">Power Rating</div>
              <div id="power-rating" className="value">{powerRating}kW</div>
            </div>
          </div>
          <div className="action-container">
            <button className="cancel-btn" onClick={onCancel}>Cancel</button>
            <button
              id="storage-confirm-btn"
              className={cx('confirm-btn', { disabled: quote.locked })}
              onClick={onSave}
            >
              Save
            </button>
          </div>

          {instantQuoteMode ? (
            <RemoveStorageModalButton />
          ) : null}
        </div>
      </div>
    </div>
  );
}

StorageWithMultiInvertersSettings.propTypes = {
  quote: quoteType.isRequired,
  toggleAddStorageModalFlag: PropTypes.func.isRequired,
  updateStorage: PropTypes.func.isRequired,
  pvModule: pvModuleType.isRequired,
  instantQuoteMode: PropTypes.bool,
  constantValues: PropTypes.arrayOf(PropTypes.number),
  currentStorageConfiguration: currentStorageConfigurationType.isRequired,
  updateCurrentStorageConfiguration: PropTypes.func.isRequired
};

export default register(
  [
    'instantQuoteModeSelector'
  ],
  [],
  StorageWithMultiInvertersSettings
);
