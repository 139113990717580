export const nyStateEnergyOffsetCheck = (account, energyOffset,
  partnerIsTps, partnerIsRvar, settings, quote) => {
  const check = (account.state.toUpperCase() === 'NY' && energyOffset > 110 && !quote.locked) &&
              ((partnerIsTps && settings.cash.showCustomer) ||
              (partnerIsTps && settings.loan.showCustomer) ||
              (partnerIsRvar && settings.loan.showCustomer));
  return check;
};

export const nyStateEnergyOffsetMsg = (check, setMessage) => {
  if (check) {
    setMessage({ message: 'Systems producing more than 110% offset may not qualify for some incentives.\n' +
  'Please consider reducing your system size or visit https://www.nyserda.ny.gov/ to learn more about\n' +
  'program limitations.' });
  }
};
