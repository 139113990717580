export function updateSettings(financeType, key, value) {
  return {
    type: 'updateSettings',
    payload: {
      settings: {
        [financeType]: {
          [key]: value
        }
      }
    }
  };
}
