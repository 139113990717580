import get from 'lodash/get';
import constants from '../EddieCalculations/constants';

const INSTALLATION_DISTANCE_ADDER_ZONE_1 = 'zone 1';
const INSTALLATION_DISTANCE_ADDER_ZONE_2 = 'zone 2';

const { NON_SUNPOWER_GROUND_MOUNT_RACKING } = constants;

export const installationDistanceAdderCost = (schema, quote) => {
  const installationDistanceAdderZone1Cost = get(schema, 'properties.installationDistanceAdder.x-item-zone1-cost');
  const installationDistanceAdderZone2Cost = get(schema, 'properties.installationDistanceAdder.x-item-zone2-cost');

  if (quote.distanceAdder) {
    if (quote.distanceAdder.toLowerCase() === INSTALLATION_DISTANCE_ADDER_ZONE_1) {
      return installationDistanceAdderZone1Cost;
    }
    if (quote.distanceAdder.toLowerCase() === INSTALLATION_DISTANCE_ADDER_ZONE_2) {
      return installationDistanceAdderZone2Cost;
    }
  }
  return 0;
};

export const skirtGuardAdderCost = (schema, enabledPanelCount) => {
  const skirtGuardPrice = get(schema, 'properties.skirtGuardAdder.x-item-price');
  return skirtGuardPrice * enabledPanelCount;
};

export const critterGuardAdderCost = (schema, enabledPanelCount) => {
  const critterGuardPrice = get(schema, 'properties.critterGuardAdder.x-item-price');
  return critterGuardPrice * enabledPanelCount;
};

export const groundMountAdderCost = (schema, systemCapacityWatts) => {
  if (systemCapacityWatts <= 0) return 0;

  const groundMountAdderSystemSizeForFlatPrice = get(schema, 'properties.groundMountAdder.x-flat-cost-system-size');
  const groundMountAdderFlatCost = get(schema, 'properties.groundMountAdder.x-flat-cost');
  const groundMountAdderPerWattPriceAfterFlat = get(schema, 'properties.groundMountAdder.x-per-watt-price-after-flat');

  if (systemCapacityWatts <= groundMountAdderSystemSizeForFlatPrice) {
    return groundMountAdderFlatCost;
  }

  return Math.round(
    groundMountAdderFlatCost + (
      systemCapacityWatts - groundMountAdderSystemSizeForFlatPrice
    ) * groundMountAdderPerWattPriceAfterFlat
  );
};

export const isSkirtGuardAdderSelected = (design, moduleSelectionModal, quote) => {
  return ((design && moduleSelectionModal && moduleSelectionModal.isSkirtGuard) || (quote && quote.isSkirtGuard));
};

export const isCritterGuardAdderSelected = (design, moduleSelectionModal, quote) => {
  return ((design && moduleSelectionModal && moduleSelectionModal.isCritterGuard) || (quote && quote.isCritterGuard));
};

export const isGroundMountRackingSelected = (quote) => {
  const pattern = new RegExp(NON_SUNPOWER_GROUND_MOUNT_RACKING, 'i');

  return pattern.test(quote.rackingType || '');
};

export const applyInstallationDistanceAdder = (quote) => {
  return [
    INSTALLATION_DISTANCE_ADDER_ZONE_1,
    INSTALLATION_DISTANCE_ADDER_ZONE_2
  ].includes((quote.distanceAdder || '').toLowerCase());
};
