import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { register } from '../../utils/redux';
import { moduleSelectionModalType } from '../../types';
import ModuleOption from './ModuleOption';

export function ModuleSection({
  moduleSelectionModal,
  updateModuleSelectionModal,
  title,
  section,
  prop,
  getText,
  noImage,
  getImageUrl
}) {
  return (
    <div className={cx('module-section', section)}>
      <div className="module-section-title">
        {title}
      </div>
      <div className="module-section-options">
        {moduleSelectionModal[section].map((value) =>
          <ModuleOption
            key={value}
            section={section}
            active={moduleSelectionModal[prop]}
            update={updateModuleSelectionModal}
            prop={prop}
            value={value}
            text={getText(value)}
            noImage={noImage}
            imageUrl={getImageUrl ? getImageUrl(value) : null}
          />
        )}
      </div>
    </div>
  );
}

ModuleSection.propTypes = {
  moduleSelectionModal: moduleSelectionModalType.isRequired,
  updateModuleSelectionModal: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  section: PropTypes.string.isRequired,
  prop: PropTypes.string.isRequired,
  getText: PropTypes.func.isRequired,
  noImage: PropTypes.bool,
  getImageUrl: PropTypes.func
};

export default register(
  [
    'moduleSelectionModalSelector'
  ],
  [
    'updateModuleSelectionModal'
  ],
  ModuleSection
);
