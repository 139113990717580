import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import {
  quoteType,
  selectedStorageType,
  selectedStorageWithExpansionPackType
} from '../../../types';

import { showStorageWithExpPack } from '../../../utils/StorageWithMultiInverters/helpers';
import { getStorageName } from '../../../utils/sunPowerStorage';
import { numberOfInverters } from '../../../utils/EddieCalculations/design';
import {
  getStorageCombinationImageName,
  storageMaxKwh,
  storageNameImage,
  getStorageUnitsImage,
  storageNameOnlyText
} from '../../../utils/storage';
import { storageExpansionPackEnabled } from '../../../utils/backendFeatureFlags';

export function InstantQuoteStorageDetails({
  quote,
  selectedStorage,
  selectedStorageWithExpansionPack,
  openStorageModal
}) {
  const hasExpansion = showStorageWithExpPack(storageExpansionPackEnabled(), quote);
  const imageName = storageNameImage(getStorageName(selectedStorage, selectedStorageWithExpansionPack, quote), quote);
  const name = storageNameOnlyText(getStorageName(selectedStorage, selectedStorageWithExpansionPack, quote));
  const maxKwh = storageMaxKwh(quote, selectedStorage, selectedStorageWithExpansionPack);

  const storageUnitsImage = getStorageUnitsImage(quote);
  const inverters = numberOfInverters(quote);
  const inverterText = inverters === 1 ? 'Inverter' : 'Inverters';

  const quantityImageName = hasExpansion
    ? `${getStorageCombinationImageName(quote)}.svg`
    : `storage-${storageUnitsImage}-units.svg`;

  return (
    <div className="storage-details">
      <div className="storage-name storage-box">
        <div
          className={cx(`image${hasExpansion ? ' expansion' : ''}`, imageName)}
        />

        <div className="info">
          <div className="sub-info">
            {hasExpansion ? 'SUNVAULT™' : name}
          </div>

          <div className="sub-info">
            {hasExpansion ? 'Storage System' : (
              <React.Fragment>
                <strong>{inverters}</strong>&nbsp;{inverterText}
              </React.Fragment>
            )}
          </div>
        </div>
      </div>

      <div className={`storage-quantity storage-box ${hasExpansion ? 'expansion' : ''}`}>
        <div
          className={`image${hasExpansion ? ' expansion' : ''}`}
          style={{
            backgroundImage: `url("/build/images/${quantityImageName}")`
          }}
        />

        <div className="info">
          <div className="sub-info">
            {hasExpansion ? 'Batteries' : 'Quantity'}:&nbsp;<b>{quote.storageQuantity}</b>
          </div>

          {hasExpansion ? (
            <div className="sub-info">
              Expansion Packs:&nbsp;<b>{quote.storageExpansionPackQuantity}</b>
            </div>
          ) : null}
        </div>
      </div>

      <div className="storage-kwh storage-box">
        <div className="main">
          <span>{maxKwh}</span>
          <span>kWh</span>
        </div>

        <div className="info">Storage Size</div>

        <div className="update-storage-btn" onClick={openStorageModal} />
      </div>
    </div>
  );
}

InstantQuoteStorageDetails.propTypes = {
  quote: quoteType,
  selectedStorage: selectedStorageType,
  selectedStorageWithExpansionPack: selectedStorageWithExpansionPackType,
  openStorageModal: PropTypes.func
};
