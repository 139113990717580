import u from 'updeep';
import { initialState, MODE_CHANGE_SLIDER } from '../config';
import { asyncPayload } from './apiHelpers';
import { autoSelectPanels } from '../utils/publicMode';
import { toggleMode } from './toggleMode';
import { deleteItemFunction } from '../utils/collectionUpdater';
import Api from '../utils/Api';

export function ensureValidId(currentId, items, itemIdName = 'id') {
  if (items.length === 0) {
    return '';
  }
  const currentlyAssignedItem = items.find((item) => item[itemIdName] === currentId);
  return currentlyAssignedItem ? currentId : items[0][itemIdName];
}

function prepareRoofForm(roof, selectedInverters) {
  const newRoof = Object.assign({}, initialState.roof, roof);
  newRoof.selectedInverterId = ensureValidId(newRoof.selectedInverterId, selectedInverters);
  return newRoof;
}

function normalizePanelCounts(roof, modifiedKey) {
  const newRoof = Object.assign({}, roof);
  if (modifiedKey === 'panelPerStringCount' || modifiedKey === 'panelStringCount') {
    const panelCount = parseInt(newRoof.panelPerStringCount, 10) * parseInt(newRoof.panelStringCount, 10);
    if (!isNaN(panelCount)) {
      newRoof.panelCount = panelCount;
    }
  } else if (modifiedKey === 'panelCount') {
    newRoof.panelPerStringCount = '';
    newRoof.panelStringCount = '';
  }
  return newRoof;
}

function autoSelectPanelsCreator(value) {
  return (design) => u({ hasChanges: true }, autoSelectPanels(value, design));
}

export function clearRoof(selectedInverters) {
  const updatedRoof = prepareRoofForm(initialState.roof, selectedInverters);
  return {
    type: 'clearRoof',
    payload: {
      roof: updatedRoof
    }
  };
}

export function fillInRoof(roof, selectedInverters) {
  const updatedRoof = prepareRoofForm(roof, selectedInverters);
  return {
    type: 'fillInRoof',
    payload: { roof: updatedRoof }
  };
}

export function updateRoofData(key, value) {
  const roofUpdate = (oldRoof) => {
    const newRoof = Object.assign({}, oldRoof);
    newRoof[key] = value;
    const normalizedNewRoof = normalizePanelCounts(newRoof, key);
    return normalizedNewRoof;
  };

  return {
    type: 'updateRoofData',
    payload: {
      roof: roofUpdate
    }
  };
}

export function deleteRoof(roof, designId) {
  const roofId = roof.id;
  const requestPath = `manual_mode/designs/${designId}/roofs/${roofId}`;
  const promise = Api.delete(requestPath)
    .then((result) => {
      return {
        design: { moduleArrays: deleteItemFunction(result.roof) },
        selectedInverterItems: result.selectedInverterItems,
        quote: { dirty: true, finEng: null }
      };
    });

  return {
    type: 'deleteRoof',
    payload: asyncPayload({
      request: promise
    })
  };
}

export const setPanelSlider = (value) => (dispatch, getState) => {
  dispatch({
    type: 'setPanelSlider',
    payload: {
      panelSliderValue: value,
      design: autoSelectPanelsCreator(value),
      mode: MODE_CHANGE_SLIDER,
      quote: {
        finEng: null
      }
    }
  });
  // Only toggle MODE_CHANGE_SLIDER off, if panel value has not changed.
  setTimeout(() => {
    const { panelSliderValue, mode } = getState();
    if (panelSliderValue === value && mode === MODE_CHANGE_SLIDER) {
      dispatch(toggleMode(MODE_CHANGE_SLIDER));
    }
  }, 1500);
};

export function updateRoofMonthlySolarAccess(id, value) {
  return {
    type: 'updateRoofMonthlySolarAccess',
    payload: u.updateIn(`roof.monthlySolarAccess.${id}`, value)
  };
}

export function toggleRoof({ arrayNumber }) {
  return {
    type: 'toggleRoof',
    payload: {
      design: {
        activeModule: arrayNumber,
        hasChanges: true
      },
      quote: {
        finEng: null
      },
      moduleChanged: true
    }
  };
}

