import React from 'react';
import PropTypes from 'prop-types';
import uniqueId from 'lodash/uniqueId';
import { register } from '../../utils/redux';
import environment from '../../utils/environment';
import { errorType } from '../../types';
import GenericShowHide from '../GenericShowHide';

/**
* TODO: Add spec once requirement is finalized
* */
function ErrorOverlay({ error, markErrorAsShownInModal }) {
  const hideModalAndStop = (e) => {
    e.preventDefault();
    markErrorAsShownInModal(error);
  };

  return (
    <div className="modal is-centered">
      <div id="overlay" />
      <div id="error" className="popover">
        <h1>Something went wrong...</h1>
        <p>{error.message}</p>
        { environment.isDevelopment() && error.stack &&
          <div>
            { error.stack.map((line) =>
              <div key={uniqueId('error-stack')}><small>{line}</small></div>
            )}
          </div>
        }
        { environment.isDevelopment() && error.backendStack &&
          <GenericShowHide>
            <div>
              { error.backendStack.map((line) =>
                <div key={uniqueId('error-backend-stack')}><small>{line}</small></div>
              )}
            </div>
          </GenericShowHide>
        }

        <a href="#" className="btn btn-primary" onClick={(e) => hideModalAndStop(e)}>OK</a>
      </div>
    </div>
  );
}

ErrorOverlay.propTypes = {
  error: errorType,
  markErrorAsShownInModal: PropTypes.func.isRequired
};

export default register(
  ['errorSelector'],
  ['markErrorAsShownInModal'],
  ErrorOverlay
);
