import PropTypes from 'prop-types';
import React from 'react';

export function GuideStep({
  index,
  title,
  subtitle,
  sidebar,
  content
}) {
  return (
    <div className={`step step-${index}`}>
      <div className="step-header">
        <div className="title-box">
          <span className="step-index">{index}</span>
          <h3>
            {title}
            <span className="subtitle">{subtitle}</span>
          </h3>
        </div>
        {sidebar ? sidebar() : null}
      </div>
      {content ? content() : null}
    </div>
  );
}

GuideStep.propTypes = {
  index: PropTypes.number,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  sidebar: PropTypes.element,
  content: PropTypes.element
};
