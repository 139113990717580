export default (userSfid) => {
  /* eslint-disable no-underscore-dangle */
  if (window._sv) return;

  window._sv = {
    trackingCode: '81a6590d584699a6c29916c1abc723cb',
    identity: userSfid
  };
  const s = document.createElement('script');
  s.src = '//survey.survicate.com/workspaces/81a6590d584699a6c29916c1abc723cb/web_surveys.js';
  s.async = true;
  const e = document.getElementsByTagName('script')[0];
  e.parentNode.insertBefore(s, e);
  /* eslint-disable no-underscore-dangle */
};
