import u from 'updeep';

export function rePairMaxfitDesign() {
  return {
    type: 'PATCH',
    payload: {
      design: {
        moduleArrays: u.updateIn('*.roofPlaneNumber', null),
        paired: false
      }
    }
  };
}
