import get from 'lodash/get';
import createSelector from '../utils/createSelector';
import { savingsSelector } from '../selectors';

function addInfoBoxData(state) {
  const { savingsChartSelection, savingsChart, inflationRate } = state;
  const { savings } = savingsSelector(state);

  const dataSeries = get(savingsChart, `${inflationRate}.${savings.activeTab}`, {});
  const annualProductionData = get(dataSeries, 'metaSeries.annualProduction', []);
  const paymentsData = get(dataSeries, 'graphSeries.costs', []);
  const savingsLabels = get(dataSeries, 'graphSeries.labels', []);
  const ratePerKwh = get(dataSeries, 'graphSeries.ratePerKwh', []);
  const savingsAmount = get(dataSeries, `graphSeries.savings.${savingsChartSelection}`, 0);

  return {
    savingsChartInfoBoxData: {
      x: savingsChartSelection,
      label: savingsLabels[savingsChartSelection],
      savings: savingsAmount,
      annualProduction: annualProductionData[savingsChartSelection],
      payment: paymentsData[savingsChartSelection] || 0,
      labelsCount: savingsLabels.length,
      ratePerKwh: ratePerKwh[savingsChartSelection]
    }
  };
}

export default createSelector(
  addInfoBoxData
);
