import React from 'react';

export default function InstantQuoteDesignErrorLabel() {
  return (
    <div className="instant-quote-design-error-label">
      Instant Quote is not available for this location;
      there is no roof data to generate the design from.
      Please proceed with a different type of quote.
    </div>
  );
}

InstantQuoteDesignErrorLabel.propTypes = {};
