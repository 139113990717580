import _ from 'lodash';
import { partnerIsRvar, partnerIsNewHomes, partnerIsTps } from './EddieCalculations/helpers/account';
import { getEvChargerList, getEvOutletList } from './EvChargers/evChargers';

export const paymentOptionsForStorage = (account) => {
  if (partnerIsRvar(account.partner) || partnerIsTps(account.partner)) {
    return [ 'cash', 'loan' ];
  }
  if (partnerIsNewHomes(account.partner)) {
    return ['lease'];
  }
  return [ 'cash', 'loan' ];
};

export const paymentOptionForEvCharger = (evmChargerConfigurations, selectedEvConfigId) => {
  if (selectedEvConfigId) {
    const offerings = evmChargerConfigurations.filter((configuration) =>
      configuration.configId === selectedEvConfigId)[0].offerings;
    return offerings.map((offering) => offering.toLowerCase());
  }
  let evChargerOfferings = _.flatten(getEvChargerList(evmChargerConfigurations).map((c) => c.offerings));
  evChargerOfferings = Array.from(new Set(evChargerOfferings));
  return evChargerOfferings.map((offering) => offering.toLowerCase());
};

export const paymentOptionForEvOutlet = (evmChargerConfigurations, selectedEvConfigId) => {
  if (selectedEvConfigId) {
    const offerings = evmChargerConfigurations.filter((configuration) =>
      configuration.configId === selectedEvConfigId)[0].offerings;
    return offerings.map((offering) => offering.toLowerCase());
  }
  let evChargerOfferings = _.flatten(getEvOutletList(evmChargerConfigurations).map((c) => c.offerings));
  evChargerOfferings = Array.from(new Set(evChargerOfferings));
  return evChargerOfferings.map((offering) => offering.toLowerCase());
};
