import { asyncPayload } from './apiHelpers';
import Api from '../utils/Api';

export function updateEvChargerConfig(quote, params) {
  return {
    type: 'updateEvChargerConfig',
    payload: asyncPayload({
      request: Api.update(`quotes/${quote.sfid}/ev_charger_configs`, {
        ev_charger_configs: params
      }).then((result) => {
        return {
          quote: {
            evChargerConfigs: result.evChargerConfigs,
            dirty: true
          }
        };
      }),
      afterRequest: {
        quote: {
          finEng: null
        }
      }
    })
  };
}
