import get from 'lodash/get';
import { asyncPayload } from '../apiHelpers';
import Api from '../../utils/Api';

export function updateStorageQuote(quote, params, showLoader = true, browserHistory, successUrl) {
  const requestPath = `storage/quotes/${quote.sfid}`;
  const promise = Api.update(requestPath, params);

  return (dispatch) => {
    const response = dispatch({
      type: 'updateStorageQuote',
      payload: asyncPayload({
        beforeRequest: {
          loading: showLoader
        },
        request: promise,
        afterRequest: {
          loading: false
        },
        errorRequest: {
          loading: false
        }
      })
    });

    response.then((data) => {
      if (!get(data, 'value.error', null)) {
        if (successUrl) {
          browserHistory.push(successUrl);
        }
        return ({ storageQuote: data.storageQuote });
      }
      return (data);
    });
  };
}
