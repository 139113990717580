import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { isSunpowerStorage } from '../../utils/sunPowerStorage';
import { register } from '../../utils/redux';
import { moduleSelectionModalType, selectedStorageType } from '../../types';
import { pvS6MonitoringInverterName } from '../../utils/EddieCalculations/design';

function getInverterName(inverter, series, pvModuleSeriesDetails) {
  switch (inverter.monitoring.name) {
    case 'PVS5 Production Monitoring':
      return 'SunPower Type D Microinverter';
    case 'PVS6 Monitoring Kit':
      return inverter.name.search('Type-D') >= 0
        ? 'SunPower Type D Microinverter'
        : pvS6MonitoringInverterName(series, pvModuleSeriesDetails);
    default:
      return inverter.name;
  }
}

function getMonitoringName(monitoring, storage) {
  if (isSunpowerStorage(storage.name)) {
    return 'Hub+ Production Monitoring';
  }
  return monitoring.name;
}

function escape(string) {
  return string.replace(/[ +]/g, '-');
}

export function InverterSection({
  moduleSelectionModal,
  selectedStorage,
  updateModuleSelectionModal,
  pvModuleSeriesDetails
}) {
  const selected = (inverter) =>
    (moduleSelectionModal.selectedInverter.name === inverter.name) &&
    (moduleSelectionModal.selectedMonitoring === inverter.monitoring.name);
  return (
    <div className="module-section inverters">
      <div className="module-section-title">
        Inverter + Monitoring
      </div>
      <div className="module-section-options">
        {moduleSelectionModal.inverters.map((inverter) =>
          <div
            key={inverter.name}
            className={cx(
              'module-option',
              'inverter',
              { active: selected(inverter) }
            )}
            onClick={() => {
              updateModuleSelectionModal('selectedMonitoring', inverter.monitoring.name);
              updateModuleSelectionModal('selectedInverter', inverter);
            }}
          >
            <div className="module-option-inverter">
              <div
                className={cx(
                  'module-option-image',
                  escape(getInverterName(inverter, moduleSelectionModal.selectedSeries, pvModuleSeriesDetails))
                )}
              >
                &nbsp;
              </div>
              <div className="module-option-text">
                {getInverterName(inverter, moduleSelectionModal.selectedSeries, pvModuleSeriesDetails)}
              </div>
            </div>
            <div className="module-option-inverter monitoring">
              <div
                className={cx(
                  'module-option-image',
                  escape(getMonitoringName(inverter.monitoring, selectedStorage))
                )}
              >
                &nbsp;
              </div>
              <div className="module-option-text">
                {getMonitoringName(inverter.monitoring, selectedStorage)}
              </div>
            </div>
          </div>
        )}
      </div>
      { moduleSelectionModal.cellularMonitoringExists && (
        <div className="checkbox cellular-option">
          <label className="cellular-label">
            <input
              className="cellular-checkbox"
              type="checkbox"
              checked={moduleSelectionModal.selectedCellularMonitoring}
              onChange={(e) => updateModuleSelectionModal('selectedCellularMonitoring', e.target.checked)}
            />
            <span className="cellular-span">Include Cellular Monitoring</span>
          </label>
        </div>
      )}
    </div>
  );
}

InverterSection.propTypes = {
  moduleSelectionModal: moduleSelectionModalType.isRequired,
  updateModuleSelectionModal: PropTypes.func.isRequired,
  selectedStorage: selectedStorageType.isRequired,
  pvModuleSeriesDetails: PropTypes.arrayOf(PropTypes.object)
};

export default register(
  [
    'moduleSelectionModalSelector', 'selectedStorageSelector', 'pvModuleSeriesDetailsSelector'
  ],
  [
    'updateModuleSelectionModal'
  ],
  InverterSection
);
