import React from 'react';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import { register } from '../../../utils/redux';
import { CustomCheckBox } from '../shared/CustomCheckBox';

/* eslint no-underscore-dangle: 0 */
export function StateSelectionView({
  apFinConfig,
  financeType,
  availableStates,
  storageProductConfig,
  updateStateSelection,
  apSalesforce
}) {
  const showAdders = get(apSalesforce, 'specialProgram', [])
    .findIndex((program) => program.toLowerCase() === 'adders in eddie') !== -1;

  const selectedStates = apFinConfig ? (apFinConfig.applicableStatesAttributes || [])
    .filter((stateConfig) => !stateConfig._destroy).map((stateConfig) => stateConfig.state) : [];

  const isAllStatesSelected = () => {
    const availableStatesCount = availableStates && availableStates.length;
    const selectedStatesCount = selectedStates.length;

    return availableStatesCount === selectedStatesCount;
  };

  const toggleAllStates = () => {
    const value = !isAllStatesSelected();
    availableStates.forEach((state) => {
      updateStateSelection(apFinConfig.id, financeType, state.abbreviation, value, storageProductConfig, showAdders);
    });
  };

  const toggleState = (value, abbreviation) => {
    updateStateSelection(apFinConfig.id, financeType, abbreviation, value, storageProductConfig, showAdders);
  };

  const showSingleStateMessage = apFinConfig && apFinConfig.selectSingleState;

  const showStateErrorMessage = apFinConfig && apFinConfig.show
    && ((apFinConfig.applicableStatesAttributes.length === 0) ||
    (apFinConfig.applicableStatesAttributes.filter((state) => { return state._destroy === true; }).length ===
    apFinConfig.applicableStatesAttributes.length));

  return (
    <div className="admin-section-data-container border-bottom">
      <div className="admin-section-data-heading-container space-between">
        <div className="heading-label"> SELECT STATES:</div>
        <div className="select-all-button" onClick={toggleAllStates}>
          {isAllStatesSelected() ? 'UNSELECT ALL' : 'SELECT ALL'}</div>
      </div>
      <div className="admin-options-container width-80">
        {
          availableStates && availableStates.map((state) =>
            <CustomCheckBox
              label={state.name}
              value={state.abbreviation}
              isChecked={selectedStates.indexOf(state.abbreviation) > -1}
              callBack={toggleState}
            />
          )
        }
      </div>
      { showStateErrorMessage && (
        <div className="state-selection-error-container">
          Please select a state. If no state is selected, Admin Portal changes will not be available in Quoting
        </div>
      )}
      { showSingleStateMessage && (
        <div className="single-state-selection">
          To see state level price defaults, select the individual state
        </div>
      )}
    </div>
  );
}

StateSelectionView.propTypes = {
  apFinConfig: PropTypes.objectOf(PropTypes.object),
  financeType: PropTypes.string,
  availableStates: PropTypes.arrayOf(PropTypes.object),
  storageProductConfig: PropTypes.objectOf(PropTypes.object),
  apSalesforce: PropTypes.arrayOf(PropTypes.object),
  updateStateSelection: PropTypes.func.isRequired
};

export default register(
  [],
  [],
  StateSelectionView
);
