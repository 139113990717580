import * as Bowser from 'bowser';
import { get } from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import { saveAs } from 'file-saver';
import PropTypes from 'prop-types';
import { routerShape, withRouter } from 'react-router';
import cx from 'classnames';
import { register } from '../utils/redux';
import {
  accountType,
  creditType,
  designType,
  loanSystemCostType,
  modeType,
  proposalType,
  quoteType,
  savingsType,
  proposalDetailType,
  csatFeedbackType,
  userDataType,
  productConfigurationType,
  allSystemCostsType,
  energyDataType,
  selectedStorageType,
  pvModuleType,
  selectedStorageWithExpansionPackType,
  storageSelectionModalType
} from '../types';
import NavLink from './NavLink';
import { CALCULATING_SAVINGS, SALESFORCE_INSTANCE_URL } from '../config';
import { gaTrackEvent } from '../utils/googleAnalytics';
import store from './../store';
import { cancelInstantQuoteLoading } from '../actions';
import InstantQuoteDesignErrorLabel from './instant_quote/InstantQuoteDesignErrorLabel';
import { adProductionType } from '../types/adDesign';
import Link from './Link';
import useDebounce from '../utils/useDebounce';
import validateLoanAmount from '../utils/validateLoanAmount';
import { getDegradation } from '../utils/EddieCalculations/design';
import SelectFinanceTypeButton from './savings_page/SelectFinanceTypeButton';
import { dynamicProposalEnabled, mortgageEnabled, enableDownloadDPPDF } from '../utils/backendFeatureFlags';
import constants from '../utils/EddieCalculations/constants';
import { datadogTrackLoadingTime } from '../utils/datadogUserMonitoring';
import { unsetDraftSessionInLocalStorage, getDrafSessionInLocalStorage } from '../utils/common';
import FeedbackModalBar from './FeedbackModalBar';
import ProposalGenerator from './savings_page/ProposalGenerator';

const {
  LOAN_FINANCE_TYPE,
  LEASE_FINANCE_TYPE,
  CASH_FINANCE_TYPE,
  SPWR_CASH_FINANCE_TYPE,
  MORTGAGE_FINANCE_TYPE,
  PPA_FINANCE_TYPE,
  COMPLETED,
  HIDDEN
} = constants;

function GearButton({ toggleModal, instantQuoteMode,
                      setInstantQuoteSyncDesign }) {
  const onClick = (e) => {
    gaTrackEvent('quotePage:navBar:gear');
    if (instantQuoteMode) {
      setInstantQuoteSyncDesign(true);
    }
    toggleModal('settings', e.target.parentElement);
  };

  return (
    <div className="nav-action-icon">
      <a
        href="#"
        className="image-icon image-icon-navigation-gear"
        onClick={(e) => {
          onClick(e);
        }}
      />
    </div>
  );
}

GearButton.propTypes = {
  toggleModal: PropTypes.func.isRequired,
  instantQuoteMode: PropTypes.bool,
  setInstantQuoteSyncDesign: PropTypes.func
};

export function LoadingNavigation({
  loadingText,
  subTitleText,
  canceledLoadingText,
  forInstantQuote,
  forceCanceledStatus,
  designError,
  currentTabName
}) {
  const [ instantQuoteLoadingStatus, setInstantQuoteLoadingStatus ] = useState('loading'); // 'loading' | 'canceled'

  const handleCancelButtonClick = useCallback(() => {
    if (instantQuoteLoadingStatus !== 'canceled') {
      setInstantQuoteLoadingStatus('canceled');
      store.dispatch(cancelInstantQuoteLoading());
    }
  }, [ setInstantQuoteLoadingStatus, instantQuoteLoadingStatus, canceledLoadingText ]);

  useEffect(() => {
    if ((designError || forceCanceledStatus) && instantQuoteLoadingStatus !== 'canceled') {
      setInstantQuoteLoadingStatus('canceled');
    }
  }, [ forceCanceledStatus, designError, instantQuoteLoadingStatus, setInstantQuoteLoadingStatus ]);

  const isProposalTab = currentTabName && (currentTabName.includes('PROPOSAL') ||
    currentTabName.includes('INSTANT_QUOTE_PROPOSAL'));

  const loadingMessage = isProposalTab
    ? 'Preparing Proposal...'
    : (instantQuoteLoadingStatus === 'loading'
      ? loadingText
      : canceledLoadingText);

  const loadingAnimation = isProposalTab
    ? 'loading-proposal-animation'
    : `loading-design-animation ${instantQuoteLoadingStatus}-state`;

  if (forInstantQuote) {
    return (
      <div>
        {designError ? <InstantQuoteDesignErrorLabel /> : null}

        <div className={`instant-quote-loading-screen ${isProposalTab ? 'proposal-tab' : ''}`}>
          <i className={`${loadingAnimation}`} />
          <div className="loading-message">{loadingMessage}</div>

          {subTitleText && subTitleText.length > 0 && instantQuoteLoadingStatus !== 'canceled' ? (
            <div className="loading-subtitle">{subTitleText}</div>
          ) : null}

          {!isProposalTab && instantQuoteLoadingStatus !== 'canceled' ? (
            <div className="loading-cancel-button" onClick={handleCancelButtonClick}>CANCEL</div>
          ) : null}
        </div>
      </div>
    );
  }

  return (
    <div className="navigation-container is-loading">
      <nav className="navigation">
        <div className="brand-item">
          <div className="logo-item">
            <a href="#" className="sunpower-logo-black" />
          </div>
        </div>

        <div className="nav-actions">
          <span className="loading-roof spinner" />
          <span className="supporting-text">{loadingText}</span>
        </div>
      </nav>
    </div>
  );
}

LoadingNavigation.propTypes = {
  loadingText: PropTypes.string.isRequired,
  subTitleText: PropTypes.string,
  canceledLoadingText: PropTypes.string,
  forInstantQuote: PropTypes.bool,
  forceCanceledStatus: PropTypes.bool,
  designError: PropTypes.bool,
  currentTabName: PropTypes.string
};

export function Navigation({
  alwaysOnFront, router, params, account, quote, mode, toggleModal, design, manualMode, currentTabName,
  returnURL, isPublic, instantQuoteMode, isSaved, instantQuoteProduction,
  newInstantQuoteSaveDesignRequest, finalizePublicQuote, lockDynamicQuote,
  lockManualQuote, loanSystemCost, savings, partnerSectorLeaseIsNewHomes,
  credit, proposal, setInstantQuoteSyncDesign, lockingQuote, showLoader,
  saveFeedback, userData, csatFeedback, partnerIsNewHomes,
  generateProposalFile, generateDynamicProposalFile, allSystemCosts, energyData, inflationRate,
  isSpecialState, selectedStorage, pvModule, selectedStorageWithExpansionPack,
  constantValues, defaultChartTab, productConfiguration, storageSelectionModal, utilitySavingsType
}) {
  const degradationRate = getDegradation(pvModule);
  const selectedStorageQuantity = get(quote, 'storageQuantity', 0);
  const [ device, setDevice ] = useState(undefined);
  const [ isPDFLoading, setPDFLoading ] = useState(false);
  const isPDFProcessing = get(account, 'isPDFProcessing');
  const [ pdfUrl, setPdfUrl ] = useState('');
  const [ isProposalGenerating, setIsProposalGenerating ] = useState(false);
  const [ skipUploadToS3, setSkipUploadToS3 ] = useState(false);
  const [ windowSize, setWindowSize ] = useState({
    width: 0,
    height: 0
  });
  const { execute: onWindowResize } = useDebounce(500, (setWindowSize) => {
    if (setWindowSize) {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight
      });
    }
  });
  const getOrientation = useCallback(() => {
    return window.innerHeight > window.innerWidth ? 'portrait' : 'landscape';
  }, [windowSize]);

  const downloadDpPdf = () => {
    return !!(enableDownloadDPPDF() && (quote.version >= 13) && !partnerIsNewHomes);
  };

  useEffect(() => {
    const isCurrentTabSavings = currentTabName && currentTabName.includes('SAVINGS');
    if (quote.dirty && isCurrentTabSavings) {
      const urlPrefix = `/${isPublic ? 'public/' : ''}${quote.manualMode ? 'manual_mode/' : ''}${
        instantQuoteMode ? 'instant_quote/' : ''}`;
      const urlSuffix = isPublic ? `?pat=${quote.publicAccessToken}` : '';
      const pathname = `${urlPrefix}quote/${quote.sfid}/design${urlSuffix}`;

      router.push(pathname);
    }
  }, []);

  useEffect(() => {
    if (!device) {
      const browser = Bowser.getParser(window.navigator.userAgent);

      if (browser) {
        setDevice({
          ...browser.parsedResult,
          orientation: getOrientation(),
          screen: {
            height: window.screen.height,
            width: window.screen.width
          },
          window: {
            height: window.innerHeight,
            width: window.innerWidth
          }
        });
      }
    } else if (
      device.screen.width !== window.screen.width
      || device.screen.height !== window.screen.height
      || device.window.width !== window.innerWidth
      || device.window.height !== window.innerHeight
    ) {
      setDevice({
        ...device,
        orientation: getOrientation(),
        screen: {
          height: window.screen.height,
          width: window.screen.width
        },
        window: {
          height: window.innerHeight,
          width: window.innerWidth
        }
      });
    }
  }, [ device, setDevice, windowSize, getOrientation ]);

  useEffect(() => {
    const onResize = () => {
      onWindowResize(setWindowSize);
    };

    onResize();

    window.addEventListener('resize', onResize);

    return () => {
      window.removeEventListener('resize', onResize);
    };
  }, [setWindowSize]);

  useEffect(() => {
    if (!isPDFProcessing) {
      setIsProposalGenerating(false);
    }
  }, [isPDFProcessing]);

  const quoteDirty = quote.dirty;
  const disabledSavingsForManualMode = quoteDirty;
  const disabledSavingsForDynamicMode = design.hasChanges || quoteDirty || design.enabledPanelCount === 0;
  const disabledSavingsTab = !quote.locked &&
    (manualMode ? disabledSavingsForManualMode : disabledSavingsForDynamicMode);
  const showQuoteSettingsButton = ![ 'PROPOSAL', 'INSTANT_QUOTE_PROPOSAL' ].includes(currentTabName);
  const isDesignTab = currentTabName && currentTabName.includes('DESIGN');
  const isSavingsTab = currentTabName && currentTabName.includes('SAVINGS');
  const isProposalTab = currentTabName && (currentTabName.includes('PROPOSAL') ||
    currentTabName.includes('INSTANT_QUOTE_PROPOSAL'));
  const backUrl = returnURL || `${SALESFORCE_INSTANCE_URL}/${account.sfid}`;
  const urlPrefix = `/${isPublic ? 'public/' : ''}${manualMode ? 'manual_mode/' : ''}${
    instantQuoteMode ? 'instant_quote/' : ''}`;
  const urlSuffix = isPublic ? `?pat=${quote.publicAccessToken}` : '';
  const isTabletInPortrait = !!device && device.platform.type === 'tablet' && device.orientation === 'portrait';
  const lockQuote = manualMode ? lockManualQuote : (isPublic ? finalizePublicQuote : lockDynamicQuote);
  const isDynamicProposalTab = isProposalTab && dynamicProposalEnabled();
  const proposalDownloadLinkProps = { target: '_blank', rel: 'noopener noreferrer' };

  useEffect(() => {
    const unloadCallback = () => {
      unsetDraftSessionInLocalStorage();
      return null;
    };

    if (quote.locked && isDynamicProposalTab) {
      window.addEventListener('beforeunload', unloadCallback);

      return () => {
        window.removeEventListener('beforeunload', unloadCallback);
      };
    }
    return () => {

    };
  }, []);

  useEffect(() => {
    const isCurrentTabProposal = currentTabName && currentTabName.includes('PROPOSAL');
    if (isCurrentTabProposal && !pdfUrl) {
      const isNewHomes = account && account.partner && account.partner.sectorLease &&
        account.partner.sectorLease.toLowerCase() === 'new homes';
      if (isNewHomes && proposal.pdfUrl) setPdfUrl(proposal.pdfUrl);
      if (quote.dpS3Url) setPdfUrl(quote.dpS3Url);
    }
  }, [ quote, proposal ]);

  const trackProposalDownloadInDD = () => {
    datadogTrackLoadingTime(
      'Download PDF Proposal',
      null,
      quote,
      null,
      null,
      null
    );
  };

  const pdfFileSource = () => {
    const proposalUrl = get(proposal, 'pdfDataUrl');
    return downloadDpPdf() ? pdfUrl : proposalUrl;
  };

  const saveDownload = () => {
    const fileName = downloadDpPdf() ? `Proposal_${quote.sfid}.pdf` : proposal.pdfFilename;
    saveAs(pdfFileSource(), fileName);
    trackProposalDownloadInDD();
    setPDFLoading(false);
  };

  const downloadFile = async () => {
    setPDFLoading(true);
    const isNewHomes = account && account.partner && account.partner.sectorLease &&
    account.partner.sectorLease.toLowerCase() === 'new homes';
    if (isNewHomes) {
      if (pdfUrl) {
        saveDownload();
      } else {
        generateProposalFile(quote, isPublic, 'pdf').then(() => {
          saveDownload();
        });
      }
    } else {
      if (utilitySavingsType === 'perKWH') {
        setSkipUploadToS3(true);
        setIsProposalGenerating(true);
        setPDFLoading(false);
        return;
      }
      if (quote.dpS3Data) saveDownload();
      if (!quote.dpS3Data && pdfUrl) {
        generateDynamicProposalFile(pdfUrl).then(() => {
          saveDownload();
        });
      }
    }
  };

  if (mode === CALCULATING_SAVINGS || lockingQuote || showLoader || isPDFProcessing) {
    const loadingText = isProposalGenerating ? 'Generating PDF...' : 'Calculating savings...';
    return (
      <div>
        <div id="overlay" />
        <LoadingNavigation loadingText={loadingText} currentTabName={currentTabName} />
      </div>
    );
  }

  const containerClasses = cx('navigation-container', {
    'always-on-front': !!alwaysOnFront,
    'optimization-modal-open': design.notificationModal,
    'instant-quote-nav': instantQuoteMode,
    'instant-quote-tablet-portrait': instantQuoteMode && isTabletInPortrait
  });

  const locked = isPublic ? quote.selectedByHomeowner : quote.locked;

  const lockQuoteBtn = () => {
    switch (savings.activeTab) {
      case LOAN_FINANCE_TYPE:
        if (credit.loanIsDeclined) {
          return null;
        }
        return (
          <SelectFinanceTypeButton
            name={savings.activeTab}
            activeTab={savings.activeTab}
            onClick={() => validateLoanAmount(loanSystemCost.loanAmount, () => lockQuote(quote, LOAN_FINANCE_TYPE))}
          />
        );
      case LEASE_FINANCE_TYPE:
        return (
          <SelectFinanceTypeButton
            name={savings.activeTab}
            activeTab={savings.activeTab}
            onClick={() => lockQuote(quote, LEASE_FINANCE_TYPE)}
          />
        );
      case PPA_FINANCE_TYPE:
        return (
          <SelectFinanceTypeButton
            name={savings.activeTab}
            activeTab={savings.activeTab}
            onClick={() => lockQuote(quote, PPA_FINANCE_TYPE)}
          />
        );
      case SPWR_CASH_FINANCE_TYPE:
        return (
          <SelectFinanceTypeButton
            name={'SPWR Cash'}
            activeTab={savings.activeTab}
            onClick={() => lockQuote(quote, SPWR_CASH_FINANCE_TYPE)}
          />
        );
      case CASH_FINANCE_TYPE:
        if (mortgageEnabled() && partnerSectorLeaseIsNewHomes) {
          return (
            <SelectFinanceTypeButton
              name={MORTGAGE_FINANCE_TYPE}
              activeTab={savings.activeTab}
              onClick={() => lockQuote(quote, CASH_FINANCE_TYPE)}
            />
          );
        }
        return (
          <SelectFinanceTypeButton
            name={savings.activeTab}
            activeTab={savings.activeTab}
            onClick={() => lockQuote(quote, CASH_FINANCE_TYPE)}
          />
        );
      default:
        return null;
    }
  };

  const savingsPageButtons = () => {
    return (
      <div className="nav-action-button-container">
        { lockQuoteBtn() }
        <div className="nav-action-icon">
          <a
            href={backUrl}
            className="image-icon image-icon-navigation-close"
            data-tip="Close Quote"
            onClick={() => {
              gaTrackEvent('quotePage:navBar:close');
              return true;
            }}
          />
        </div>
      </div>
    );
  };

  const csatFeedbackStatus = get(csatFeedback, 'status');
  const draftQuoteSession = getDrafSessionInLocalStorage();
  const showFeedbackModalLabel = quote.version > 8 && !partnerSectorLeaseIsNewHomes
  && isDynamicProposalTab && quote.cloneVariant !== 'amendment'
    && !(csatFeedbackStatus === COMPLETED || csatFeedbackStatus === HIDDEN) && draftQuoteSession;

  const closeFeedbackModalBar = () => {
    saveFeedback({
      quoteSfid: quote.sfid,
      userId: userData.sfid,
      status: HIDDEN
    });
  };

  const generateProposal = () => {
    setIsProposalGenerating(true);
  };

  const pdfGenerator = (
    <ProposalGenerator
      account={account}
      allSystemCosts={allSystemCosts}
      design={design}
      energyData={energyData}
      inflationRate={inflationRate}
      instantQuoteMode={instantQuoteMode}
      isSpecialState={isSpecialState}
      productConfiguration={productConfiguration}
      quote={quote}
      selectedFinanceOption={quote.selectedFinanceOption}
      selectedStorage={selectedStorage}
      degradationRate={degradationRate}
      selectedStorageQuantity={selectedStorageQuantity}
      selectedStorageWithExpansionPack={selectedStorageWithExpansionPack}
      storageSelectionModal={storageSelectionModal}
      userData={userData}
      constantValues={constantValues}
      defaultChartTab={defaultChartTab}
      currentPage="proposal"
      utilitySavingsType={utilitySavingsType}
      skipUploadToS3={skipUploadToS3}
    />
  );

  const downloadClass = 'btn btn-primary nav-action-btn proposal-download-btn';
  const proposalDownloadBtn = () => {
    if (pdfUrl) {
      if (isPDFLoading) {
        return (
          <a
            className={downloadClass}
            {...proposalDownloadLinkProps}
          >
            <img
              className="download-spinner"
              src="/build/images/loading-spinner.svg"
              alt="Loading Spinner"
            />
            Downloading
          </a>
        );
      }
      return (
        <a
          className={downloadClass}
          onClick={downloadFile}
          {...proposalDownloadLinkProps}
        >
          Download
        </a>
      );
    }
    if (isPDFProcessing) {
      return (
        <a
          className={downloadClass}
        >
          <img
            className="download-spinner"
            src="/build/images/loading-spinner.svg"
            alt="Loading Spinner"
          />
            Generating
        </a>
      );
    }
    return (
      <a
        className={downloadClass}
        onClick={generateProposal}
      >
        Generate PDF
      </a>
    );
  };

  return (
    <div className={containerClasses}>
      <nav className={cx('navigation', { new: true })}>
        <div className="brand-item">
          <div className="logo-item">
            <a href="#" className="sunpower-logo-black" />
          </div>
        </div>

        {params.quoteId && (
          <div className="nav-items-group">
            {isPublic && (
              <NavLink
                router={router}
                to={`${urlPrefix}quote/${params.quoteId}/presentation`}
                name="Why Sunpower"
                isPublic={isPublic}
              />
            )}
            <NavLink
              router={router}
              to={`${urlPrefix}quote/${params.quoteId}/design${urlSuffix}`}
              name="Design"
              isPublic={isPublic}
            />
            <NavLink
              disabled={disabledSavingsTab}
              router={router}
              to={`${urlPrefix}quote/${params.quoteId}/savings${urlSuffix}`}
              name="Savings"
              isPublic={isPublic}
            />
            <NavLink
              disabled={!locked}
              router={router}
              to={`${urlPrefix}quote/${params.quoteId}/proposal${urlSuffix}`}
              name="Proposal"
              isPublic={isPublic}
            />
          </div>
        )}

        {params.designId && (
          <div className="nav-items-group">
            <div className="nav-items">
              <div className="nav-item">
                <div className="nav-item-title">
                  <div>
                    <a href="#">Match Shade Data</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

        <div className="nav-actions">
          <span className="supporting-text">{account.street}</span>
          {!isPublic && params.quoteId && showQuoteSettingsButton &&
          GearButton({ toggleModal,
            instantQuoteMode: instantQuoteMode && isDesignTab,
            setInstantQuoteSyncDesign })
          }
          {!isPublic && (
            <div className="nav-action-icon-separator" />
          )}
          {!isPublic ? (!instantQuoteMode ? (
                isSavingsTab ? (
                  savingsPageButtons()
                ) : (
                  !isDynamicProposalTab && (
                    <div className="nav-action-icon">
                      <a
                        href={backUrl}
                        className="image-icon image-icon-navigation-close"
                        data-tip="Close Quote"
                        onClick={() => {
                          gaTrackEvent('quotePage:navBar:close');
                          return true;
                        }}
                      />
                    </div>
                  )
                )
          ) : (
            !isDynamicProposalTab && (
              <div
                className={cx('nav-action-buttons', { 'padding-right-16': instantQuoteMode },
                (isSavingsTab ? 'nav-action-button-container' : ''))}
              >
                <div className="nav-action-icon">
                  <a
                    href={backUrl}
                    className="nav-cancel-design-button image-icon image-icon-navigation-close"
                    data-tip="Close Quote"
                    onClick={() => {
                      gaTrackEvent('quotePage:navBar:close');
                      return true;
                    }}
                  />
                </div>
                {isDesignTab ? (
                  <React.Fragment>
                    <button
                      className="nav-save-design-button btn btn-secondary"
                      disabled={!instantQuoteProduction || instantQuoteProduction.systemSize < 0.001 || isSaved}
                      onClick={newInstantQuoteSaveDesignRequest}
                    >
                      Save Design
                    </button>
                    <Link
                      className={`view-savings btn btn-primary ${!isSaved ? 'disabled' : ''}`}
                      onClick={() => {
                        gaTrackEvent('instantQuotePage:designTab:viewEstimatedSavings');
                      }}
                      to={isSaved ? `/instant_quote/quote/${quote.sfid}/savings` : '#'}
                    >
                      View Savings <i className="arrow-right-icon" />
                    </Link>
                  </React.Fragment>
                ) : null}
                {
                  isSavingsTab ? lockQuoteBtn() : null
                }
              </div>
            )
          )) : null}
          {
            isDynamicProposalTab && (
              <div className={cx('nav-action-buttons', 'nav-action-button-container')}>
                {proposalDownloadBtn()}
                {isProposalGenerating ? pdfGenerator : null}
                <div className="nav-action-icon">
                  <a
                    href={backUrl}
                    className="nav-cancel-design-button image-icon image-icon-navigation-close"
                    data-tip="Close Quote"
                    onClick={() => {
                      gaTrackEvent('quotePage:navBar:close');
                      return true;
                    }}
                  />
                </div>
              </div>
            )
          }
        </div>

        {instantQuoteMode ? (
          <div className="non-supported-screen">
            <div className="animation-container" />
            <div className="caption-container">
              Please rotate the screen
              <br />to use this application
            </div>
          </div>
        ) : null}
      </nav>
      {showFeedbackModalLabel &&
        <FeedbackModalBar
          closeFeedbackModalBar={closeFeedbackModalBar}
          toggleModal={toggleModal}
        />}
    </div>
  );
}

Navigation.propTypes = {
  alwaysOnFront: PropTypes.bool,
  params: PropTypes.objectOf(PropTypes.string).isRequired,
  router: routerShape.isRequired,
  account: accountType.isRequired,
  mode: modeType,
  quote: quoteType.isRequired,
  design: designType.isRequired,
  toggleModal: PropTypes.func.isRequired,
  manualMode: PropTypes.bool,
  currentTabName: PropTypes.string,
  returnURL: PropTypes.string,
  isPublic: PropTypes.bool,
  instantQuoteMode: PropTypes.bool,
  isSaved: PropTypes.bool,
  instantQuoteProduction: adProductionType,
  newInstantQuoteSaveDesignRequest: PropTypes.func,
  setInstantQuoteSyncDesign: PropTypes.func,
  lockManualQuote: PropTypes.func.isRequired,
  lockDynamicQuote: PropTypes.func.isRequired,
  generateDynamicProposalFile: PropTypes.func,
  generateProposalFile: PropTypes.func,
  finalizePublicQuote: PropTypes.func.isRequired,
  loanSystemCost: loanSystemCostType,
  savings: savingsType.isRequired,
  partnerSectorLeaseIsNewHomes: PropTypes.bool.isRequired,
  credit: creditType.isRequired,
  proposal: proposalType,
  proposalDetails: proposalDetailType,
  lockingQuote: PropTypes.bool,
  showLoader: PropTypes.bool,
  csatFeedback: csatFeedbackType,
  saveFeedback: PropTypes.func.isRequired,
  userData: userDataType,
  partnerIsNewHomes: PropTypes.bool,
  productConfiguration: productConfigurationType.isRequired,
  allSystemCosts: allSystemCostsType,
  energyData: energyDataType,
  inflationRate: PropTypes.string,
  isSpecialState: PropTypes.bool,
  selectedStorage: selectedStorageType,
  pvModule: pvModuleType,
  selectedStorageWithExpansionPack: selectedStorageWithExpansionPackType,
  storageSelectionModal: storageSelectionModalType,
  constantValues: PropTypes.arrayOf(PropTypes.number),
  defaultChartTab: PropTypes.string,
  utilitySavingsType: PropTypes.string,
  updateUtilitySavingsType: PropTypes.func
};

export default register(
  [
    'quoteSelector', 'productConfigurationSelector', 'accountSelector', 'modeSelector', 'designSelector',
    'currentTabNameSelector', 'returnURLSelector', 'isPublicSelector', 'manualModeSelector',
    'instantQuoteModeSelector', 'isSavedSelector', 'instantQuoteProductionSelector',
    'loanSystemCostSelector', 'savingsSelector', 'partnerSectorLeaseIsNewHomesSelector', 'creditSelector',
    'proposalSelector', 'lockingQuoteSelector', 'showLoaderSelector', 'proposalDetailsSelector',
    'csatFeedbackSelector', 'userDataSelector', 'partnerIsNewHomesSelector', 'allSystemCostsSelector',
    'energyDataSelector', 'inflationRateSelector', 'isSpecialStateSelector', 'productConfigurationSelector',
    'selectedStorageSelector', 'pvModuleSelector', 'selectedStorageWithExpansionPackSelector',
    'storageSelectionModalSelector', 'constantValuesSelector', 'defaultChartTabSelector',
    'utilitySavingsTypeSelector'
  ],
  [ 'toggleModal', 'newInstantQuoteSaveDesignRequest', 'lockDynamicQuote', 'lockManualQuote',
    'finalizePublicQuote', 'setInstantQuoteSyncDesign', 'saveFeedback',
    'generateProposalFile', 'generateDynamicProposalFile', 'updateUtilitySavingsType'
  ],
  withRouter(Navigation)
);
