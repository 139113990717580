export function setViewMode(viewMode) {
  return {
    type: 'setViewMode',
    payload: {
      design: {
        viewMode
      }
    }
  };
}
