import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { gaTrackEvent } from '../../../utils/googleAnalytics';
import { getRackingName, getSystemName } from '../../../utils/normalizedNames';
import { pvModuleType } from '../../../types';

export function InstantQuoteEquipmentSection({
  isQuoteLocked,
  toggleModuleSelectionModal,
  moduleImageSrc,
  mountingImageSrc,
  rackingType,
  monitoringName,
  selectedPvModule
}) {
  const handleOnClick = useCallback(() => {
    if (!isQuoteLocked) {
      toggleModuleSelectionModal();
      gaTrackEvent('instantQuotePage:designTab:editEquipment');
    }
  }, [ toggleModuleSelectionModal, isQuoteLocked ]);

  const boxes = [
    {
      title: 'MODULE',
      info: `${selectedPvModule.item.productLine} - ${getSystemName(selectedPvModule.item.summaryDescription)}`,
      imgClass: cx('image', moduleImageSrc)
    },
    {
      title: 'MOUNTING',
      info: getRackingName(rackingType),
      imgClass: cx('image', mountingImageSrc)
    },
    {
      title: 'MONITORING',
      info: monitoringName,
      imgClass: 'image monitoring'
    }
  ];

  return (
    <div className="section new equipment-section">

      {boxes.map((box) => (
        <div key={box.title} className={`details-box ${isQuoteLocked ? 'locked' : ''}`} onClick={handleOnClick}>
          <div className="details-title">
            {box.title}
          </div>
          <div className="details-info">
            {box.info}
          </div>
          <div className={box.imgClass} />
        </div>
      ))}
    </div>
  );
}

InstantQuoteEquipmentSection.propTypes = {
  isQuoteLocked: PropTypes.bool,
  toggleModuleSelectionModal: PropTypes.func,
  moduleImageSrc: PropTypes.string,
  mountingImageSrc: PropTypes.string,
  rackingType: PropTypes.string,
  monitoringName: PropTypes.string,
  selectedPvModule: pvModuleType
};
