/* eslint no-underscore-dangle: 0 */
import { cloneDeep } from 'lodash';
import { findApFinConfigIndexById } from '../../utils/admin_portal/updateHelpers';

/**
 *
 * @param {string} financeType
 * @param {string} series
 * @param {object} val
 * @returns updated state
 */
export const updateApModuleSeries = (configId, series, val) => (dispatch, getState) => {
  const currentState = getState();
  const apFinConfigsToUpdate = cloneDeep(currentState.apFinConfigs);
  const finConfigIndex = findApFinConfigIndexById(apFinConfigsToUpdate, configId);
  const moduleIndex = apFinConfigsToUpdate[finConfigIndex].moduleConfigsAttributes
    .map((config) => config.moduleSeries).indexOf(series);

  if (moduleIndex > -1) {
    if (val) {
      if (apFinConfigsToUpdate[finConfigIndex].moduleConfigsAttributes[moduleIndex].id) {
        apFinConfigsToUpdate[finConfigIndex].moduleConfigsAttributes[moduleIndex]._destroy = false;
      }
    } else if (apFinConfigsToUpdate[finConfigIndex].moduleConfigsAttributes[moduleIndex].id) {
      apFinConfigsToUpdate[finConfigIndex].moduleConfigsAttributes[moduleIndex]._destroy = true;
    } else {
      apFinConfigsToUpdate[finConfigIndex].moduleConfigsAttributes.splice(moduleIndex, 1);
    }
  } else {
    apFinConfigsToUpdate[finConfigIndex].moduleConfigsAttributes = [
      ...apFinConfigsToUpdate[finConfigIndex].moduleConfigsAttributes,
      {
        moduleSeries: series
      }
    ];
  }

  dispatch({
    type: 'updateApModuleSeries',
    payload: {
      apFinConfigs: apFinConfigsToUpdate
    }
  });
};
