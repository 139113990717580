import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-bootstrap-modal';
import { register } from '../../utils/redux';
import { currentStorageConfigurationType, designType, pvModuleType, quoteType } from '../../types';
import StorageWith3pSetting from './StorageWith3pSetting';

export function AddStorageWith3pModal({
  design,
  quote,
  closeAddStorageModal,
  toggleAddStorageModalFlag,
  updateStorage,
  pvModule,
  constantValues,
  currentStorageConfiguration,
  updateCurrentStorageConfiguration
}) {
  return (
    <Modal
      className="add-storage-with-3p"
      show={design.isAddStorageModalOpen}
      onHide={closeAddStorageModal}
      aria-labelledby="ModalHeader"
    >
      <Modal.Body>
        <StorageWith3pSetting
          quote={quote}
          toggleAddStorageModalFlag={toggleAddStorageModalFlag}
          updateStorage={updateStorage}
          pvModule={pvModule}
          constantValues={constantValues}
          currentStorageConfiguration={currentStorageConfiguration}
          updateCurrentStorageConfiguration={updateCurrentStorageConfiguration}
        />
      </Modal.Body>
    </Modal>
  );
}

AddStorageWith3pModal.propTypes = {
  design: designType.isRequired,
  quote: quoteType.isRequired,
  closeAddStorageModal: PropTypes.func.isRequired,
  toggleAddStorageModalFlag: PropTypes.func.isRequired,
  updateStorage: PropTypes.func.isRequired,
  pvModule: pvModuleType.isRequired,
  constantValues: PropTypes.arrayOf(PropTypes.number),
  currentStorageConfiguration: currentStorageConfigurationType.isRequired,
  updateCurrentStorageConfiguration: PropTypes.func.isRequired
};

export default register(
  [
    'designSelector', 'quoteSelector', 'pvModuleSelector', 'constantValuesSelector',
    'currentStorageConfigurationSelector'
  ],
  [
    'closeAddStorageModal', 'toggleAddStorageModalFlag', 'updateStorage',
    'updateCurrentStorageConfiguration'
  ],
  AddStorageWith3pModal
);
