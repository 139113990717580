import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { register } from '../../utils/redux';
import { dropdownsType, designType, quoteMaxFitRoofDesignType } from '../../types';

export function DesignDropdown({
  value,
  onChange,
  designList,
  maxFitDesignList,
  data,
  dropdowns,
  toggleDesignsListDropdown,
  disabled
}) {
  const defaultContainerClasses = 'input-container input-container--icon icon-right dropdown-with-tags';
  const designData = data.map((design, index) => {
    const maxFitDesign = maxFitDesignList.find((maxFitDesign) => {
      return designList[index].maxfitSfid === maxFitDesign.qmRoofDesignId;
    });
    const date = new Date(maxFitDesign.roofdesign.createddate).toLocaleString();
    return {
      key: design.key,
      value: design.value,
      name: `${maxFitDesign.roofdesign.moduleSeriesC} - ${date}`,
      updatedAt: maxFitDesign.roofdesign.createddate
    };
  });
  const sortedDesignData = designData.length > 0 ? designData.sort((a, b) => {
    return new Date(b.updatedAt) - new Date(a.updatedAt);
  }) : [];
  const selected = sortedDesignData.find((item) => item.key === value);
  const isSelected = (item) => item.key === value;
  const options = (<ul>
    {sortedDesignData.map((item) => {
      return (
        <li
          className={cx('cs-option-container', { 'cs-selected': isSelected(item) })}
          onClick={() => {
            toggleDesignsListDropdown();
            onChange({ id: item.key, name: item.value }, designList, maxFitDesignList);
          }}
          key={item.key}
        >
          <span className="cs-option">
            <div className="cs-option-name">{item.name}</div>
          </span>
        </li>
      );
    })}
  </ul>);
  if (sortedDesignData.length < 1) {
    return null;
  }
  return (
    <div id="design_list_dropdown" className={cx(defaultContainerClasses, { 'is-disabled': disabled })}>
      <i id="design_list_icon" className="icon icon-chevron-down" />
      <div
        id="select_design_dropdown"
        className={`select-dropdown cs-skin-border ${dropdowns.isDesignListOpen ? 'cs-active' : ''}`}
        tabIndex="0"
      >
        <span className="cs-placeholder" onClick={toggleDesignsListDropdown}>
          <div className="dropdown-tag">
            {'DESIGN OPTION'}
          </div>
          <div className="cs-option-name">{selected.name}</div>
        </span>
        <div className="cs-options">
          { options }
        </div>
      </div>
    </div>
  );
}

DesignDropdown.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  data: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.number.isRequired,
    value: PropTypes.string.isRequired
  })).isRequired,
  dropdowns: dropdownsType,
  toggleDesignsListDropdown: PropTypes.func,
  disabled: PropTypes.bool,
  designList: PropTypes.arrayOf(designType),
  maxFitDesignList: PropTypes.arrayOf(quoteMaxFitRoofDesignType)
};

export default register(
  [ 'dropdownsSelector', 'selectedDesign' ],
  ['toggleDesignsListDropdown'],
  DesignDropdown
);

