import { get } from 'lodash';
import isEmpty from 'lodash/isEmpty';
import {
  getSelectedFinancialOptions,
  getUnAvailableModuleFinanceOptions,
  moduleAvailableForAllSelectedPaymentOptionsValidation,
  moduleAvailableForSelectedPaymentOptionsValidation
} from '../selectedModuleValidationUtility';
import constants from '../EddieCalculations/constants';

const { MAX_EV_CHARGER_DEALER_FEE } = constants;

export const validateSelectedModuleAndFinanceOptions = ({ productConfiguration, settings, pvModule }) => {
  const selectedFinancialOptions = getSelectedFinancialOptions({ productConfiguration, settings });
  const moduleFinancialOptions = pvModule.financialOfferings;
  if (moduleAvailableForAllSelectedPaymentOptionsValidation({ moduleFinancialOptions, selectedFinancialOptions })) {
    return null;
  }
  return {
    message: 'The currently selected module is not available for one of your selected finance options. ' +
      'We have updated the selection to an available module.'
  };
};

export const validateIfModuleAvailableForSelectedFinanceOptions =
  ({ productConfiguration, settings, pvModules, quote }, selectedPvModule) => {
    const unavailableFinanceOptions = getUnAvailableModuleFinanceOptions(
      { pvModules, quote, productConfiguration, settings });

    if (isEmpty(selectedPvModule)) {
      return {
        message: `The selected module is not available for finance option(s): ${unavailableFinanceOptions.join(', ')}`
      };
    }
    return null;
  };

export const validateMaxEvChargerDealerFee = ({ settings, partnerIsRvar }) => {
  const cashEvChargerDealerFee = get(settings.cash, 'evChargerDealerFee', 0);
  const loanEvChargerDealerFee = get(settings.loan, 'evChargerDealerFee', 0);
  const leaseEvChargerDealerFee = get(settings.lease, 'evChargerDealerFee', 0);
  const ppaEvChargerDealerFee = get(settings.ppa, 'evChargerDealerFee', 0);
  if (cashEvChargerDealerFee > MAX_EV_CHARGER_DEALER_FEE ||
    loanEvChargerDealerFee > MAX_EV_CHARGER_DEALER_FEE ||
    leaseEvChargerDealerFee > MAX_EV_CHARGER_DEALER_FEE ||
    ppaEvChargerDealerFee > MAX_EV_CHARGER_DEALER_FEE) {
    const msg = partnerIsRvar ? 'Additional Cost' : 'commission';
    return {
      message: `The EV Charger ${msg} can not be greater than ${MAX_EV_CHARGER_DEALER_FEE}.`
    };
  }
  return null;
};

export const validateModuleAndFinanceOption = (pvModule, activeTab) => {
  const moduleFinancialOptions = pvModule.financialOfferings;
  if (moduleAvailableForSelectedPaymentOptionsValidation(moduleFinancialOptions, activeTab)) {
    return null;
  }
  return `The currently selected module is not available for ${activeTab}. `;
};

export default ({
                  settings, productConfiguration, pvModule
                }) => (
  validateSelectedModuleAndFinanceOptions({ pvModule, settings, productConfiguration })
);
