import createSelector from '../utils/createSelector';
import { pvModulesSelector } from '../selectors';

export function pvModuleSelected(state) {
  const quote = state.quote;
  const { pvModules } = pvModulesSelector({ pvModules: state.pvModules });
  const selectedPvModule = pvModules.find((pvModule) => pvModule.itemId === quote.pvModuleItemId);

  return {
    pvModule: selectedPvModule
  };
}

export default createSelector(
  pvModuleSelected
);

