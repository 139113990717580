import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Modal from './Modal';
import { register } from '../utils/redux';
import { gaTrackEvent } from '../utils/googleAnalytics';
import { settingsType, quoteType, creditType } from '../types';
import LineInput from './LineInput';
import constants from '../utils/EddieCalculations/constants';
import { filterAprPercentagesByTerm } from '../utils/creditProductsHelper';

const {
  CREDIT_PENDING,
  CREDIT_NOT_PRESENT
} = constants;

export function EditLoanModal({
  settings,
  quote,
  credit,
  updateSettings,
  updateLoan
}) {
  const maxAmountApproved = credit.maxAmounts[settings.loan.termInMonths] ||
    credit.maxAmounts[credit.terms[credit.terms.length - 1]];
  const selectedLoanTerm = settings.loan.termInMonths;
  const selectedAprPercentage = settings.loan.aprPercentage;
  const [ availableAprPercentages, setAvailableAprPercentages ] =
    useState(filterAprPercentagesByTerm(credit.products, selectedLoanTerm));

  const updateTerm = (termInMonths) => {
    const filteredAprs = filterAprPercentagesByTerm(credit.products, termInMonths);

    setAvailableAprPercentages(filteredAprs);
    updateSettings('loan', 'aprPercentage', filteredAprs[0]);
    updateSettings('loan', 'termInMonths', termInMonths);
  };

  const updateAprPercentage = (aprPercentage) => {
    updateSettings('loan', 'aprPercentage', aprPercentage);
  };

  const save = () => {
    updateLoan(quote, {
      downPayment: settings.loan.coPayment,
      aprPercentage: selectedAprPercentage,
      term: selectedLoanTerm
    }, 'editLoan');
  };

  return (
    <Modal
      name="edit-loan"
      onSave={save}
      showButtonsTop={false}
    >
      <div className="co-payment">
        <LineInput
          label="Co-Payment"
          value={settings.loan.coPayment}
          onChange={(newValue) => updateSettings('loan', 'coPayment', newValue)}
          onFocus={() => gaTrackEvent('quotePage:savingsTab:loanCoPaymentOption')}
          readOnly={quote.locked}
        />
      </div>

      <div className="form-group term">
        <label>Term</label>

        <div className="input-container">
          <select
            name="term"
            value={selectedLoanTerm}
            disabled={quote.locked}
            onChange={(e) => updateTerm(parseInt(e.target.value, 10))}
            onFocus={() => gaTrackEvent('quotePage:savingsTab:loanTermSelection')}
          >
            { credit.terms.map((term, key) => <option key={key} value={term}>{parseInt(term, 10) / 12}</option>) }
          </select>
        </div>
      </div>

      <div className="form-group apr-type">
        <label>APR</label>

        <div className="input-container">
          <select
            name="aprPercentage"
            value={selectedAprPercentage}
            disabled={quote.locked}
            onChange={(e) => updateAprPercentage(e.target.value)}
          >
            { availableAprPercentages.map((apr, key) => <option key={key} value={apr}>{apr}</option>) }
          </select>
        </div>
      </div>

      <div className="max-amount-approved">
        {
          credit.status.loan === CREDIT_PENDING || credit.status.loan === CREDIT_NOT_PRESENT ? (
            <div className="form-group">
              <label>Max amount approved</label>
              <div className="input-container">
                <input
                  className="read-only pending-credit-check"
                  type="text"
                  value="Pending Credit Check"
                  readOnly={true}
                />
              </div>
            </div>
            ) : (
              <LineInput
                label="Max amount approved"
                value={maxAmountApproved}
                readOnly={true}
              />
            )
        }
      </div>
    </Modal>
  );
}

EditLoanModal.propTypes = {
  settings: settingsType.isRequired,
  quote: quoteType.isRequired,
  credit: creditType.isRequired,
  updateSettings: PropTypes.func.isRequired,
  toggleMode: PropTypes.func.isRequired,
  updateLoan: PropTypes.func.isRequired
};

export default register(
  [ 'settingsSelector', 'quoteSelector', 'creditSelector' ],
  [ 'updateSettings', 'toggleMode', 'updateLoan' ],
  EditLoanModal
);
