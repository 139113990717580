import PropTypes from 'prop-types';
import React from 'react';
import constants from '../../../utils/admin_portal/constants';
import { partnerIsIndirect, partnerIsTps } from '../../../utils/admin_portal/quoteHelpers';
import { enableCustomAdders, enableInstallDate } from '../../../utils/backendFeatureFlags';
import { register } from '../../../utils/redux';
import { CommonInputFieldsView } from '../FinanceTabSubViews/CommonInputFieldsView';
import { CustomAddersView } from '../FinanceTabSubViews/CustomAddersView';
import { InstallDaysSelectionView } from '../FinanceTabSubViews/InstallDaysSelectionView';
import { LoanInputFieldsView } from '../FinanceTabSubViews/LoanInputFieldsView';
import { ModuleSelectionView } from '../FinanceTabSubViews/ModuleSelectionView';
import { PaymentConfigView } from '../FinanceTabSubViews/PaymentConfigView';
import { StateSelectionView } from '../FinanceTabSubViews/StateSelectionView';
import { OutOfStockModal } from './OutOfStockModal';

const {
  LOAN,
  LEASE,
  PPA
} = constants;

export function FinanceTabView({ financeType, availableStates, updateStateSelection,
  availableModuleSeries, selectedModuleSeries,
  updateApDefaultModule, availableModules, selectedModule,
  apFinConfigs, updateApPricing, updateApFinConfig, updateApModuleSeries, apSalesforce,
  updateApDefaultLoanProduct, apAvailableDownPayments, apIsFullPrepaymentAllowed,
  updateApPaymentConfig, financialOfferings, selectedFinanceOption, updateValidationErrors,
  updateApInstallDays, partnerChannel, updateApCustomAdders, updateAdminPortal, pvModuleSeriesDetails,
  newPricingDetails, storageProductConfig
}) {
  const showKwhField = [ LEASE, PPA ].includes(financeType) &&
    !(partnerIsIndirect(partnerChannel) || partnerIsTps(partnerChannel));
  const apFinConfig = (apFinConfigs && financeType) ?
      apFinConfigs.findLast((config) => config.finType.toLowerCase() === financeType.toLowerCase()) : null;

  return (
    <React.Fragment>
      <StateSelectionView
        apFinConfig={apFinConfig}
        financeType={financeType}
        availableStates={availableStates}
        updateStateSelection={updateStateSelection}
        storageProductConfig={storageProductConfig}
        apSalesforce={apSalesforce}
      />
      <CommonInputFieldsView
        financeType={financeType}
        showKwhField={showKwhField}
        apFinConfig={apFinConfig}
        updateApPricing={updateApPricing}
        updateApFinConfig={updateApFinConfig}
        updateValidationErrors={updateValidationErrors}
        apSalesforce={apSalesforce}
        newPricingDetails={newPricingDetails}
      />
      {
        financeType === LOAN && (
          <LoanInputFieldsView
            financeType={financeType}
            apSalesforce={apSalesforce}
            updateApDefaultLoanProduct={updateApDefaultLoanProduct}
            apFinConfig={apFinConfig}
            updateValidationErrors={updateValidationErrors}
          />
        )
       }
      {
        (financeType === LEASE || financeType === PPA) && (
          <PaymentConfigView
            financeType={financeType}
            availablePaymentEscalators={[ 0, 2.4, 2.9 ]}
            apAvailableDownPayments={apAvailableDownPayments}
            apIsFullPrepaymentAllowed={apIsFullPrepaymentAllowed}
            updateApPaymentConfig={updateApPaymentConfig}
            apFinConfig={apFinConfig}
          />
        )
       }
      <ModuleSelectionView
        availableModuleSeries={availableModuleSeries}
        availableModules={availableModules}
        selectedModuleSeries={selectedModuleSeries}
        updateApDefaultModule={updateApDefaultModule}
        selectedModule={selectedModule}
        updateApModuleSeries={updateApModuleSeries}
        financeType={financeType}
        apFinConfigs={apFinConfigs}
        financialOfferings={financialOfferings}
        selectedFinanceOption={selectedFinanceOption}
        updateValidationErrors={updateValidationErrors}
        pvModuleSeriesDetails={pvModuleSeriesDetails}
      />
      { partnerIsIndirect(partnerChannel) && enableInstallDate() && (
        <InstallDaysSelectionView
          financeType={financeType}
          apFinConfig={apFinConfig}
          updateApInstallDays={updateApInstallDays}
        />
        )
      }
      {
        (apFinConfig && apFinConfig.expiredProducts) ?
          <OutOfStockModal
            updateApFinConfig={updateApFinConfig}
            financeType={financeType}
            apFinConfig={apFinConfig}
            updateAdminPortal={updateAdminPortal}
          /> : ''
      }
      {enableCustomAdders() && (
        <CustomAddersView
          updateApFinConfig={updateApFinConfig}
          updateApCustomAdders={updateApCustomAdders}
          financeType={financeType}
          apFinConfig={apFinConfig}
        />
      )}
    </React.Fragment>
  );
}

FinanceTabView.propTypes = {
  financeType: PropTypes.string.isRequired,
  availableStates: PropTypes.arrayOf(PropTypes.object),
  updateStateSelection: PropTypes.func.isRequired,
  updateApCustomAdders: PropTypes.func.isRequired,
  availableModuleSeries: PropTypes.arrayOf(PropTypes.string),
  availableModules: PropTypes.arrayOf(PropTypes.string),
  selectedModuleSeries: PropTypes.arrayOf(PropTypes.string),
  apFinConfigs: PropTypes.arrayOf(PropTypes.object),
  updateApDefaultModule: PropTypes.func.isRequired,
  selectedModule: PropTypes.objectOf(PropTypes.object),
  updateApPricing: PropTypes.func.isRequired,
  updateValidationErrors: PropTypes.func.isRequired,
  updateApFinConfig: PropTypes.func.isRequired,
  updateApModuleSeries: PropTypes.func.isRequired,
  apSalesforce: PropTypes.arrayOf(PropTypes.object),
  financialOfferings: PropTypes.objectOf(PropTypes.object),
  updateApDefaultLoanProduct: PropTypes.func,
  updateApInstallDays: PropTypes.func,
  apAvailableDownPayments: PropTypes.arrayOf(PropTypes.number),
  apIsFullPrepaymentAllowed: PropTypes.bool,
  newPricingDetails: PropTypes.bool,
  updateApPaymentConfig: PropTypes.func,
  partnerChannel: PropTypes.string,
  selectedFinanceOption: PropTypes.string,
  updateAdminPortal: PropTypes.func,
  storageProductConfig: PropTypes.objectOf(PropTypes.object),
  pvModuleSeriesDetails: PropTypes.arrayOf(PropTypes.object)
};

export default register(
  [],
  [],
  FinanceTabView
);
