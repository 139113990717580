import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { register } from '../../utils/redux';
import { formatCurrency } from '../../utils/formatNumber';
import { mortgageEnabled } from '../../utils/backendFeatureFlags';
import { isEvChargerSelected, showEvCharger } from '../../utils/EvChargers/evChargers';
import LineItem from './LineItem';
import ValueBox from '../ValueBox';

import {
  businessFunctionsType,
  cashSystemCostType,
  mortgageSystemCostType,
  quoteType,
  settingsType
} from '../../types';

export function CashView({
  settings,
  cashSystemCost,
  mortgageSystemCost,
  toggleModal,
  pricingDisclaimer,
  isV2CalculationState,
  typeKey = 'cash',
  partnerSectorLeaseIsNewHomes,
  quote,
  partnerIsNewHomes,
  businessFunctions
}) {
  const cashSettings = settings[typeKey];
  const evChargerSelected = isEvChargerSelected(quote);
  const showEvChargerInCost = showEvCharger(partnerIsNewHomes, quote, businessFunctions) && evChargerSelected;
  const editMortgageClassName = cx('edit-mortgage supporting-text');

  const supportingText = () => {
    let systemCost;
    if (mortgageEnabled() && partnerSectorLeaseIsNewHomes) {
      systemCost = mortgageSystemCost;
    } else {
      systemCost = cashSystemCost;
    }
    return `After ${formatCurrency(systemCost.upfrontCost, 0)} \
      upfront cost, collect ${formatCurrency(systemCost.credit, 0)} in \
      ${systemCost.stateAndLocalIncentives > 0 ? 'incentives and' : ''} tax credits* (if qualified)`;
  };

  if (mortgageEnabled() && partnerSectorLeaseIsNewHomes) {
    return (
      <div className="cash">
        <div className="breakout-summary">
          <div className="mortgage-summary">
            <ValueBox
              label="Down Payment"
              currency={true}
              rounded={true}
              value={mortgageSystemCost.downPayment}
            />
            <ValueBox
              label="Monthly"
              currency={true}
              rounded={true}
              value={mortgageSystemCost.year1MonthlySolarWithStorageMortgagePayment}
            />
          </div>
          <span className="breakout-summary-breakdown supporting-text">
            {supportingText()}
          </span>
          <a
            href="#"
            className={editMortgageClassName}
            onClick={(e) => toggleModal('edit-mortgage', e.target)}
          >
            Edit Mortgage
          </a>
        </div>
        { !isV2CalculationState &&
        <div className="breakout-line-items">
          <LineItem
            label="Purchase Price"
            value={mortgageSystemCost.systemPriceWithAddersStorageEvcharger}
            rounded={true}
          />
          {cashSettings.discount !== 0 &&
          <LineItem label="Discount" value={cashSettings.discount} sign="-" />
          }
          <LineItem label="Down Payment" value={mortgageSystemCost.downPayment} />
          <hr />
          <LineItem label="Amount Financed" value={mortgageSystemCost.amountFinanced} />
          { mortgageSystemCost.stateAndLocalIncentives > 0 ?
            <LineItem
              label="State & local incentives (est.)*"
              value={cashSystemCost.stateAndLocalIncentives} sign="-"
            /> : null
            }
          <LineItem label="Federal tax credit (est.)*" value={mortgageSystemCost.federalTaxCredit} sign="-" />
          { mortgageSystemCost.stateTaxCredit > 0 ?
            <LineItem label="State tax credit (est.)*" value={mortgageSystemCost.stateTaxCredit} sign="-" /> :
            null }
          <hr />
          <LineItem label="Net investment (est.)" value={mortgageSystemCost.netCostRounded} />
        </div>
        }
        { isV2CalculationState &&
        <div className="breakout-line-items">
          <LineItem label="System price" value={mortgageSystemCost.systemPriceWithAdders} rounded={true} />
          {cashSettings.discount !== 0 &&
          <LineItem label="Discount" value={cashSettings.discount} sign="-" />
          }
          <LineItem label="Down Payment" value={mortgageSystemCost.downPayment} />
          { mortgageSystemCost.stateAndLocalIncentives > 0 ?
            <LineItem
              label="State & local incentives (est.)*"
              value={mortgageSystemCost.stateAndLocalIncentives} sign="-"
            /> : null
          }
          <hr />
          <LineItem label="Amount Financed" value={mortgageSystemCost.amountFinanced} />
          <LineItem label="Federal tax credit (est.)*" value={mortgageSystemCost.federalTaxCredit} sign="-" />
          { mortgageSystemCost.stateTaxCredit > 0 ?
            <LineItem label="State tax credit (est.)*" value={mortgageSystemCost.stateTaxCredit} sign="-" /> :
            null }
          <hr />
          <LineItem label="Net investment (est.)" value={mortgageSystemCost.netCostRounded} />
        </div>
        }
        { pricingDisclaimer }
      </div>
    );
  }
  return (
    <div className="cash">
      <div className="breakout-summary">
        <ValueBox
          label="Net investment"
          currency={true}
          rounded={true}
          value={cashSystemCost.netCostRounded}
        />
        <span className="breakout-summary-breakdown supporting-text">
          {supportingText()}
        </span>
      </div>
      { !isV2CalculationState &&
      <div className="breakout-line-items">
        <LineItem label="System price" value={cashSystemCost.systemPriceWithAddersStorageEvcharger} rounded={true} />
        {cashSettings.discount !== 0 &&
        <LineItem label="Discount" value={cashSettings.discount} sign="-" />
        }
        <hr />
        <LineItem label="Upfront cost" value={cashSystemCost.upfrontCost} />
        { cashSystemCost.stateAndLocalIncentives > 0 ?
          <LineItem
            label="State & local incentives (est.)*"
            value={cashSystemCost.stateAndLocalIncentives} sign="-"
          /> : null
          }
        <LineItem label="Federal tax credit (est.)*" value={cashSystemCost.federalTaxCredit} sign="-" />
        { cashSystemCost.stateTaxCredit > 0 ?
          <LineItem label="State tax credit (est.)*" value={cashSystemCost.stateTaxCredit} sign="-" /> :
          null }
        <hr />
        <LineItem
          label={showEvChargerInCost ? 'Net Cost (est. w/EV Charger)' : 'Net investment (est.)'}
          value={cashSystemCost.netCostRounded}
          labelClass={showEvChargerInCost ? 'label bold' : 'label'}
        />
      </div>
      }
      { isV2CalculationState &&
      <div className="breakout-line-items">
        <LineItem label="System price" value={cashSystemCost.systemPriceWithAdders} rounded={true} />
        {cashSettings.discount !== 0 &&
        <LineItem label="Discount" value={cashSettings.discount} sign="-" />
        }
        { cashSystemCost.stateAndLocalIncentives > 0 ?
          <LineItem
            label="State & local incentives (est.)*"
            value={cashSystemCost.stateAndLocalIncentives} sign="-"
          /> : null
        }
        <hr />
        <LineItem label="Upfront cost" value={cashSystemCost.upfrontCost} />
        <LineItem label="Federal tax credit (est.)*" value={cashSystemCost.federalTaxCredit} sign="-" />
        { cashSystemCost.stateTaxCreditWithoutTaxAbatement > 0 ?
          <LineItem
            label="State tax credit (est.)*"
            value={cashSystemCost.stateTaxCreditWithoutTaxAbatement}
            sign="-"
          /> :
          null }
        {cashSystemCost.year1TaxAbatement > 0 &&
          <LineItem
            label="Yr. 1 property tax abatement (est.)*"
            value={cashSystemCost.year1TaxAbatement} sign="-"
          />
        }
        {cashSystemCost.year2To4TaxAbatement > 0 &&
          <LineItem
            label="Yr. 2-4 property tax abatement (est.)*"
            value={cashSystemCost.year2To4TaxAbatement} sign="-"
          />
        }
        <hr />
        <LineItem
          label={showEvChargerInCost ? 'Net Cost (est. w/EV Charger)' : 'Net investment (est.)'}
          value={cashSystemCost.netCostRounded}
          labelClass={showEvChargerInCost ? 'label bold' : 'label'}
        />
      </div>
      }
      { pricingDisclaimer }
    </div>
  );
}

CashView.propTypes = {
  settings: settingsType.isRequired,
  cashSystemCost: cashSystemCostType.isRequired,
  mortgageSystemCost: mortgageSystemCostType,
  toggleModal: PropTypes.func,
  pricingDisclaimer: PropTypes.element,
  isV2CalculationState: PropTypes.bool.isRequired,
  typeKey: PropTypes.string,
  quote: quoteType.isRequired,
  partnerSectorLeaseIsNewHomes: PropTypes.bool.isRequired,
  partnerIsNewHomes: PropTypes.bool,
  businessFunctions: businessFunctionsType
};

export default register(
  [
    'settingsSelector',
    'cashSystemCostSelector',
    'mortgageSystemCostSelector',
    'isV2CalculationState',
    'partnerSectorLeaseIsNewHomesSelector',
    'partnerIsNewHomesSelector',
    'businessFunctionsSelector'
  ],
  [
    'toggleModal'
  ],
  CashView
);
