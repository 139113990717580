import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { CALCULATING_SAVINGS } from '../../config';
import { register } from '../../utils/redux';
import { formatCurrency } from '../../utils/formatNumber';
import ValueBox from '../ValueBox';
import { gaTrackEvent } from '../../utils/googleAnalytics';
import {
  ppaSystemCostType,
  settingsType,
  quoteType,
  modeType,
  accountType
} from '../../types';

export function PpaView({
  ppaSystemCost,
  settings,
  quote,
  toggleModal,
  mode,
  pricingDisclaimer,
  isPublic,
  account
}) {
  const escalatorText = !settings.ppa.fullPrepayment ?
    `(escalating ${parseFloat(settings.ppa.paymentEscalator).toFixed(1)}% annually)` : '';

  const shouldDisableClick = mode === CALCULATING_SAVINGS || quote.locked;

  const editPpaClassNames = cx('edit-ppa supporting-text', { disabled: shouldDisableClick });
  const stateTaxCalculationForUI = () => {
    if (account.state === 'HI' && ppaSystemCost && ppaSystemCost.stateTaxCredit) {
      return formatCurrency(parseFloat(0.7 * ppaSystemCost.stateTaxCredit));
    }

    return formatCurrency(ppaSystemCost.stateTaxCredit, 0);
  };
  const nyVerbiage = `SunPower retains the federal investment tax credit
    and certain other incentives (${formatCurrency(ppaSystemCost.stateAndLocalIncentives, 0)})
    on PPA systems and uses them to provide PPA customers a lower monthly payment. If
    SunPower passed these incentives onto the customer, then the
    payments shown here would be much higher.
    Customers receive a state tax credit (${stateTaxCalculationForUI()}).
    SunPower does not offer a pass-through option to its PPA customers.`;
  const otherStateVerbiage = `SunPower retains the federal investment tax credit,
    state tax credit (${stateTaxCalculationForUI()})
    and certain other incentives (${formatCurrency(ppaSystemCost.stateAndLocalIncentives, 0)})
    on PPA systems and uses them to provide PPA customers a lower monthly payment. If
    SunPower passed these incentives onto the customer, then the
    payments shown here would be much higher. SunPower does not offer a
    pass-through option to its PPA customers.`;
  const ppaVerbiage = account.state === 'NY' ? nyVerbiage : otherStateVerbiage;

  const ppaDownPaymentMessage = `After ${formatCurrency(ppaSystemCost.downPayment, 0)} down payment,`;

  return (
    <div className="ppa">
      <div className="breakout-summary">
        <div className="ppa-summary">
          {ppaSystemCost.downPayment > 0 && (<ValueBox
            label="Down Payment" currency={true}
            rounded={true}
            value={ppaSystemCost.downPayment}
          />)}
          <ValueBox
            label="Monthly"
            currency={true}
            rounded={true}
            value={ppaSystemCost.monthlyPayments[0].costWithTax}
          />
        </div>
        <div className="breakout-summary-breakdown-container">
          <span className="breakout-summary-breakdown supporting-text">
            {`${ppaSystemCost.downPayment > 0 ? ppaDownPaymentMessage : ''}
            Pay ${formatCurrency(ppaSystemCost.monthlyPayments[0].costWithTax, 0)}/month
            ${escalatorText} for ${ppaSystemCost.ppaTerm} years`}
          </span>
          { !isPublic && !quote.locked && (
            <a
              id="ppa_view_edit_lease_toggle"
              href="#"
              className={editPpaClassNames}
              onClick={(e) => {
                if (!shouldDisableClick) {
                  gaTrackEvent('quotePage:savingsTab:ppaSubTab:editLink');
                  toggleModal('edit-ppa', e.target);
                }
              }}
            >
              Edit PPA
            </a>
          )}
          { pricingDisclaimer }
          <div className="breakout-line-items">
            <hr />
            <span className="line-item breakout-summary-breakdown supporting-text">
              <div className="label">
                {ppaVerbiage}
              </div>
            </span>
            <hr />
          </div>
        </div>
      </div>
    </div>
  );
}

PpaView.propTypes = {
  ppaSystemCost: ppaSystemCostType.isRequired,
  settings: settingsType.isRequired,
  quote: quoteType.isRequired,
  toggleModal: PropTypes.func.isRequired,
  mode: modeType,
  pricingDisclaimer: PropTypes.element,
  isPublic: PropTypes.bool,
  account: accountType
};

export default register(
  [
    'ppaSystemCostSelector', 'settingsSelector', 'quoteSelector', 'modeSelector',
    'isPublicSelector', 'accountSelector'
  ],
  [
    'toggleModal'
  ],
  PpaView
);
