import React from 'react';
import PropTypes from 'prop-types';
import ClickOutside from 'react-click-outside-component';
import get from 'lodash/get';
import SavingsOverTimeGraphChart from './SavingsOverTimeGraphChart';
import ChartInfo from './ChartInfo';
import { formatCurrency } from '../../utils/formatNumber';
import makeC3Options from '../../utils/SavingsChart/makeC3Options';
import EscalatorToggle from './EscalatorToggle';
import {
  savingsChartLegendType,
  savingsChartType
} from '../../types';

export default function SavingsOverTimeGraphView({
  activeTab,
  breakout,
  hasEscalators,
  savingsChart,
  inflationRate,
  onChangeRate,
  setSavingsChartSelection
}) {
  const savingsChartData = get(savingsChart, `${inflationRate}.${activeTab}`, {});
  const c3Options = makeC3Options(savingsChartData, '.year-savings svg', {
    showInfoBox: setSavingsChartSelection,
    financeType: activeTab
  });
  const yearsLabel = get(savingsChartData, 'graphSeries.savings', []).length;
  const rates = Object.keys(savingsChart);
  return (
    <div>
      <div className="legend">
        <div className="secondary-labels">
          <div className="legend-wrapper">
            <div className="legend-label utility-bill-savings">Utility bill savings</div>
            <div className="legend-value">
              {formatCurrency(breakout.totalAnnualBillSavings, 0)}</div>
            <div className="clearfix" />
          </div>
          {breakout.rebatesAndCredits !== '-' ? (
            <div className="legend-wrapper">
              <div className="legend-label rebates">Incentives & credits</div>
              <div className="legend-value">
                {formatCurrency(breakout.rebatesAndCredits, 0)}
              </div>
              <div className="clearfix" />
            </div>
          ) : null}
          <div className="legend-wrapper">
            <div className="legend-label payments">Payments</div>
            <div className="legend-value">
              {formatCurrency(breakout.payments * -1, 0)}
            </div>
            <div className="clearfix" />
          </div>
        </div>
      </div>
      <div className="legend">
        <div className="primary-label">
          <span className="label">{yearsLabel}-year savings</span>
          <span className="value">
            {formatCurrency(breakout.totalSavingsRounded, 0)}
          </span>
        </div>
      </div>
      <div>
        <ClickOutside onClickOutside={() => setSavingsChartSelection(null)}>
          <SavingsOverTimeGraphChart {...c3Options} />
          <ChartInfo />
          <EscalatorToggle
            rates={rates}
            hasEscalators={hasEscalators}
            inflationRate={inflationRate}
            onChangeRate={onChangeRate}
          />
        </ClickOutside>
      </div>
    </div>
  );
}

SavingsOverTimeGraphView.propTypes = {
  activeTab: PropTypes.string.isRequired,
  breakout: savingsChartLegendType,
  hasEscalators: PropTypes.bool.isRequired,
  savingsChart: savingsChartType.isRequired,
  inflationRate: PropTypes.string.isRequired,
  onChangeRate: PropTypes.func.isRequired,
  setSavingsChartSelection: PropTypes.func.isRequired
};
