import isEmpty from 'lodash/isEmpty';

export default (account, notifications) => {
  if (isEmpty(account) || isEmpty(notifications)) return {};

  return account.features.addersInEddie && {
    totalCost: notifications.totalCost,
    items: notifications.notifications.filter((item) => {
      return item.isAdder;
    }).map((item) => {
      return {
        header: item.notification.header,
        cost: item.cost,
        itemId: item.itemId
      };
    })
  };
};
