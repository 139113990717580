import createSelector from '../utils/createSelector';
import { designSelector } from '../selectors';

function isSaved({ quote, design, manualMode, account, pvModules }) {
  const processedDesign = designSelector({ quote, design, account, manualMode, pvModules }).design;
  const isSavedDesign = !processedDesign.hasChanges && processedDesign.enabledPanelCount !== 0;
  const isSavedQuote = !quote.dirty;

  return {
    isSaved: quote.locked || (isSavedDesign && isSavedQuote)
  };
}

export default createSelector(
  isSaved
);
