import React from 'react';
import PropTypes from 'prop-types';

export class Selection extends React.Component {
  constructor(props) {
    super(props);
    this.state = { expanded: false };
    this.onClick = this.onClick.bind(this);
  }

  onClick() {
    this.setState({
      expanded: !this.state.expanded
    });
  }

  render() {
    const classNames = `sidebar-selection-container${this.state.expanded ? ' expanded' : ''}`;

    return (
      <div className="sidebar-selection">
        <div className={classNames} onClick={this.onClick}>
          <div className="sidebar-selection-icon">
            {this.state.expanded ?
              (
                <i className="icon icon-chevron-down" />
              ) : (
                <i className="icon icon-chevron-right" />
              )
            }
            {this.props.title}
          </div>
          {this.props.option && (
            <div className="sidebar-selection-option">
              <div className="option">
                {this.props.option}
              </div>
            </div>
          )}
        </div>
        {this.state.expanded ? (
          <div className="show-hide-content">
            {this.props.children}
          </div>
        ) : null}
      </div>
    );
  }
}

Selection.propTypes = {
  children: PropTypes.element.isRequired,
  title: PropTypes.string,
  option: PropTypes.string
};

export default Selection;
