import React from 'react';
import PropTypes from 'prop-types';
import Modal from '../Modal';
import { register } from '../../utils/redux';
import { designType, selectedInverterType, productConfigurationType } from '../../types';

export function SelectedInverterModal({
  addNewSelectedInverter,
  updateSelectedInverter,
  updateSelectedInverterAssociation,
  design,
  selectedInverter,
  productConfiguration
}) {
  const inverters = productConfiguration.inverters;
  const onSave = () => {
    if (selectedInverter.id) {
      updateSelectedInverter(selectedInverter, design.id);
    } else {
      addNewSelectedInverter(selectedInverter, design.id);
    }
  };
  const assigned = selectedInverter.id && design.moduleArrays.find(
    (moduleArray) => moduleArray.selectedInverterId === selectedInverter.id
  );

  return (
    <Modal
      name="new-selected-inverter"
      onSave={onSave}
      showButtonsTop={true}
      title={selectedInverter.id ? 'Edit Inverter' : 'New Inverter'}
    >

      {assigned && (
        <div className="alert alert--info">
          <span>
            This inverter is associated with one or more configured roof array,
            modifying it will be reflected on the roof detail.
          </span>
        </div>
      )}
      <div className="form-group row inverter-model-wrapper input-group">
        <label className="col-md-12">Inverter Model</label>
        <select
          id="selected-inverter-form-inverter-id"
          value={selectedInverter.inverterItemId}
          onChange={(e) => updateSelectedInverterAssociation(e.target.value, inverters)}
          className="col-md-12"
        >
          { inverters.map((inverter) => {
            return <option value={inverter.id} key={inverter.id}>{ inverter.name }</option>;
          })
          }
        </select>
      </div>
    </Modal>
  );
}

SelectedInverterModal.propTypes = {
  addNewSelectedInverter: PropTypes.func.isRequired,
  updateSelectedInverter: PropTypes.func.isRequired,
  updateSelectedInverterAssociation: PropTypes.func.isRequired,
  design: designType.isRequired,
  selectedInverter: selectedInverterType,
  productConfiguration: productConfigurationType.isRequired
};

export default register(
  [ 'designSelector', 'selectedInverterSelector', 'productConfigurationSelector' ],
  [ 'addNewSelectedInverter', 'updateSelectedInverter', 'updateSelectedInverterAssociation' ],
  SelectedInverterModal
);
