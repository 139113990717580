import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

export default function ModuleOption({ section, active, update, prop, value, text, noImage = false, imageUrl = null }) {
  const imageClassName = !imageUrl && value ? value.replace(/ /g, '-') : '';

  return (
    <div
      className={cx(
        'module-option',
        section,
        { active: active === value }
      )}
      onClick={() => {
        update(prop, value);
      }}
    >
      <div
        className={cx(
          'module-option-image',
          // TODO: don't remove this imageClassName instead remove all the series mentioned in css file
          imageClassName,
          { series: section === 'series' },
          { hidden: noImage }
        )}
      >
        {section === 'series' ? <img src={imageUrl} alt={`${value} series`} /> : ''}
      </div>
      { text && (
        <div className="module-option-text">
          {text}
        </div>
      )}
    </div>
  );
}

ModuleOption.propTypes = {
  section: PropTypes.string.isRequired,
  active: PropTypes.string.isRequired,
  update: PropTypes.func.isRequired,
  prop: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  imageUrl: PropTypes.string,
  text: PropTypes.string,
  noImage: PropTypes.bool
};
