import u from 'updeep';
import gradient from '../utils/gradient';
import { roofEnergyMin, roofEnergyMax, moduleEnergyMin, moduleEnergyMax } from '../config';

function visualizationSelector(state) {
  const roofPlanes = state.design.roof.roofPlanes;
  const moduleArrayCoords = state.design.roof.moduleArrays;

  let moduleArrays = {};
  state.design.moduleArrays.forEach((moduleArray) => {
    moduleArrays = u.updateIn(`${moduleArray.roofPlaneNumber}`, moduleArray, moduleArrays);
  });

  return {
    visualization: {
      dimensions: state.design.roof.dimensions,
      roofPlanes: roofPlanes.map((roofPlane, roofPlaneNumber) => ({
        coordinates: roofPlane,
        moduleArray: u.if(Boolean, {
          panels: u.map((panel, panelNumber) => u({
            coordinates: moduleArrayCoords[roofPlaneNumber][panelNumber]
          }, panel))
        }, moduleArrays[!state.design.isInstant ? roofPlaneNumber :
          state.design.roof.moduleArraysIndexesToRoofPlaneNumbers[roofPlaneNumber]])
      }))
    }
  };
}

function roofPlaneColorsSelector({ visualization }) {
  const tsrfs = visualization.roofPlanes.map((rp) => rp.moduleArray).filter(Boolean).map((ma) => ma.annualTsrf);
  const minTsrf = Math.min(...tsrfs);
  const maxTsrf = Math.max(...tsrfs);

  return {
    visualization: u({
      roofPlanes: u.map((rp) => {
        const tsrf = rp.moduleArray ? rp.moduleArray.annualTsrf : (maxTsrf + minTsrf) / 2;
        return u({
          fillColor: gradient([ roofEnergyMin, roofEnergyMax ], [ minTsrf, maxTsrf ], tsrf),
          moduleArray: u.if(Boolean, {
            fillColor: gradient([ moduleEnergyMin, moduleEnergyMax ], [ minTsrf, maxTsrf ], tsrf)
          })
        }, rp);
      })
    }, visualization)
  };
}

export default function(state) {
  const visualization = visualizationSelector(state);
  return roofPlaneColorsSelector(visualization);
}

