import numeral from 'numeral';

// This helps support legacy props and options.
export const getDisplayDigits = (currency, rounded, override) => {
  if (override === undefined) {
    if (currency) {
      return rounded ? 0 : 2;
    } else if (rounded) {
      return 0;
    }
    return undefined;
  }
  return override;
};

export const formatNumber =
  (number, places = 0) => numeral(number).format(`0,0.${'0'.repeat(places)}`);

const removeLeadingZero = (displayValue) => displayValue.replace(/^(-?)\$0\./, '$1$.');

export const formatCurrency = (number, places = 0) => {
  const display = numeral(number).format(`$0,0.${'0'.repeat(places)}`);
  return removeLeadingZero(display);
};
