export function toggleProductSelectionModal() {
  return {
    type: 'toggleProductSelectionModal',
    payload: {
      design: {
        productSelectionModal: (currentValue) => !currentValue
      }
    }
  };
}
