import { asyncPayload } from './apiHelpers';
import Api from '../utils/Api';
import historyLog from '../selectors/historyLog';

export const fetchHistoryLogs = (indexPage, usersPerPage) => (dispatch) => {
  const promise = Api.fetchForAdmin(`admin_portals/history_logs?page=${indexPage}&users_per_page=${usersPerPage}`)
  .then((resp) => {
    return ({ historyLog: [resp.data.historyLog] });
  });

  dispatch({
    type: 'fetchHistoryLogs',
    payload: asyncPayload({
      request: promise
    })
  });
};
