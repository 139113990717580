import get from 'lodash/get';
import { asyncPayload } from '../apiHelpers';
import Api from '../../utils/Api';
import constants from '../../utils/EddieCalculations/constants';

const {
  ACKNOWLEDGEMENT_ACTION
} = constants;

export function updateSoProposal(quoteSfid, action, proposalSentVia) {
  const requestPath = `storage/quotes/${quoteSfid}/update_proposal`;
  let data = { proposalAction: action };
  const showLoader = action === ACKNOWLEDGEMENT_ACTION;
  if (proposalSentVia) {
    data = {
      ...data,
      sentVia: proposalSentVia
    };
  }
  const beforeRequestProposalStatus = action === 'send' ? 'sending' : null;
  const afterRequestProposalStatus = action === 'send' ? 'sent' : null;
  const promise = Api.update(requestPath, data);

  return (dispatch) => {
    const response = dispatch({
      type: 'updateProposal',
      payload: asyncPayload({
        beforeRequest: {
          soDynamicProposal: {
            status: beforeRequestProposalStatus,
            showComplianceCheckModal: beforeRequestProposalStatus === 'sending'
          },
          loading: showLoader
        },
        request: promise,
        afterRequest: {
          soDynamicProposal: {
            status: afterRequestProposalStatus,
            showComplianceCheckModal: afterRequestProposalStatus === 'sent'
          },
          loading: false
        },
        errorRequest: {
          soDynamicProposal: {
            status: null,
            showComplianceCheckModal: false
          },
          loading: false
        }
      })
    });

    response.then((data) => {
      if (!get(data, 'value.error', null)) {
        return ({ proposalDetails: data.proposalDetails });
      }
      return (data);
    });
  };
}
