import React from 'react';
import { register } from '../../utils/redux';

export function StorageQuoteLoader() {
  return (
    <div className="storage-quote-loader">
      <img src={'/build/images/storage-loading.gif'} alt="Loading" />
      <span className="loader-text">Loading...</span>
    </div>
  );
}

StorageQuoteLoader.propTypes = {};

export default register(
  [],
  [],
  StorageQuoteLoader
);
