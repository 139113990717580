export function signProposal(name) {
  return {
    type: 'signProposal',
    payload: {
      proposal: {
        signedName: name
      }
    }
  };
}
