import cx from 'classnames';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { accountType, modalsType } from '../../types';
import { register } from '../../utils/redux';
import { Banner } from './Banner';
import { HistoryView } from './HistoryView';
import { PricingUploader } from './PricingUploader/PricingUploader';
import { CommissionPriceTableView } from './shared/CommissionPriceTableView';
import { FinanceTabView } from './shared/FinanceTabView';
import { GraphSelectionView } from './shared/GraphSelectionView';
import constants from '../../utils/EddieCalculations/constants';

const { PRICING_CONTROLS } = constants;

export function HomePage({
  selectedFinanceOption,
  financialOptions,
  partnerChannel,
  uploadPricingFile,
  selectFinanceOption,
  allAvailableStates,
  updateStateSelection,
  updateApCustomAdders,
  availableModuleSeries,
  selectedModuleSeries,
  updateApInstallDays,
  availableModules,
  updateApDefaultModule,
  toggleModal,
  closeModalAndRevertState,
  selectedModule,
  updateApPricing,
  apFinConfigs,
  updateApFinConfig,
  deletePricingFile,
  updateApProduct,
  updateApModuleSeries,
  historyLog,
  apSalesforce,
  updateAdminPortal,
  updateApDefaultLoanProduct,
  apAvailableDownPayments,
  apIsFullPrepaymentAllowed,
  updateApPaymentConfig,
  financialOfferings,
  updateValidationErrors,
  modals,
  fetchHistoryLogs,
  graphSelections,
  updateGraphSelection,
  pvModuleSeriesDetails,
  pricingControls,
  dealersFeatureFlags,
  storageProductConfig
}) {
  const newPricingDetails = dealersFeatureFlags && dealersFeatureFlags.toLowerCase() === PRICING_CONTROLS.toLowerCase();

  useEffect(() => {
    selectFinanceOption(financialOptions ? financialOptions[0] : null);
  }, [financialOptions]);

  const toggleFinanceOption = (financeType) => {
    selectFinanceOption(financeType);
  };

  return (
    <div className="admin-page-body-container">
      {
        financialOptions && (
          <React.Fragment>
            <Banner />
            <div className="container">
              <div className="admin-container">
                <div className="admin-section-heading">
                  <div className="heading-text">Finance Type:</div>
                  <div className="finance-options">
                    {financialOptions &&
                      financialOptions.map((financialOption) =>
                        <div
                          className={cx('finance-tab', { selected: selectedFinanceOption === financialOption })}
                          onClick={() => toggleFinanceOption(financialOption)}
                        >
                          <div className="text">{financialOption.toUpperCase()}</div>
                          <div className={cx({ selected: selectedFinanceOption === financialOption })} />
                        </div>)
                    }
                  </div>
                  <div className="info-icon-container">
                    <img src="/build/images/admin-info-icon.svg" alt="info" />
                  </div>
                </div>
                {allAvailableStates &&
                  (<FinanceTabView
                    financeType={selectedFinanceOption}
                    availableStates={allAvailableStates[selectedFinanceOption]}
                    updateStateSelection={updateStateSelection}
                    availableModuleSeries={availableModuleSeries}
                    selectedModuleSeries={selectedModuleSeries}
                    updateApDefaultModule={updateApDefaultModule}
                    updateApInstallDays={updateApInstallDays}
                    selectedModule={selectedModule}
                    availableModules={availableModules}
                    updateApPricing={updateApPricing}
                    apFinConfigs={apFinConfigs}
                    updateApFinConfig={updateApFinConfig}
                    updateApModuleSeries={updateApModuleSeries}
                    apSalesforce={apSalesforce}
                    updateApDefaultLoanProduct={updateApDefaultLoanProduct}
                    financialOfferings={financialOfferings}
                    apAvailableDownPayments={apAvailableDownPayments}
                    apIsFullPrepaymentAllowed={apIsFullPrepaymentAllowed}
                    updateApPaymentConfig={updateApPaymentConfig}
                    selectedFinanceOption={selectedFinanceOption}
                    updateValidationErrors={updateValidationErrors}
                    partnerChannel={partnerChannel}
                    updateApCustomAdders={updateApCustomAdders}
                    updateAdminPortal={updateAdminPortal}
                    newPricingDetails={newPricingDetails}
                    pvModuleSeriesDetails={pvModuleSeriesDetails}
                    storageProductConfig={storageProductConfig}
                  />)
                }
              </div>
              {newPricingDetails &&
                <PricingUploader
                  pricingControls={pricingControls}
                  uploadPricingFile={uploadPricingFile}
                  updateAdminPortal={updateAdminPortal}
                  deletePricingFile={deletePricingFile}
                  allAvailableStates={allAvailableStates}
                  utilities={apSalesforce.utilities}
                  financialOfferings={financialOfferings}
                />
              }
              {selectedFinanceOption &&
                <CommissionPriceTableView
                  financeType={selectedFinanceOption}
                  apFinConfigs={apFinConfigs}
                  updateApProduct={updateApProduct}
                  apSalesforce={apSalesforce}
                  partnerChannel={partnerChannel}
                />
              }
              <GraphSelectionView
                graphSelections={graphSelections}
                updateGraphSelection={updateGraphSelection}
              />
              <HistoryView
                historyLog={historyLog}
                modals={modals}
                toggleModal={toggleModal}
                closeModalAndRevertState={closeModalAndRevertState}
                fetchHistoryLogs={fetchHistoryLogs}
              />
            </div>
          </React.Fragment>
        )
      }
    </div>
  );
}

HomePage.propTypes = {
  historyLog: PropTypes.arrayOf(PropTypes.object),
  selectedFinanceOption: PropTypes.string,
  selectedModuleSeries: PropTypes.arrayOf(PropTypes.string),
  allAvailableStates: PropTypes.arrayOf(PropTypes.object),
  financialOptions: PropTypes.arrayOf(PropTypes.string),
  selectFinanceOption: PropTypes.func.isRequired,
  updateStateSelection: PropTypes.func.isRequired,
  updateApCustomAdders: PropTypes.func.isRequired,
  availableModuleSeries: PropTypes.arrayOf(PropTypes.string),
  availableModules: PropTypes.arrayOf(PropTypes.string),
  selectedModule: PropTypes.objectOf(PropTypes.object),
  updateApPricing: PropTypes.func,
  apFinConfigs: PropTypes.arrayOf(PropTypes.object),
  updateApFinConfig: PropTypes.func.isRequired,
  updateApProduct: PropTypes.func.isRequired,
  updateApModuleSeries: PropTypes.func.isRequired,
  updateApDefaultModule: PropTypes.func.isRequired,
  apSalesforce: PropTypes.arrayOf(PropTypes.object),
  financialOfferings: PropTypes.objectOf(PropTypes.object),
  updateApDefaultLoanProduct: PropTypes.func,
  updateValidationErrors: PropTypes.func,
  apAvailableDownPayments: PropTypes.arrayOf(PropTypes.number),
  apIsFullPrepaymentAllowed: PropTypes.bool,
  toggleModal: PropTypes.func.isRequired,
  closeModalAndRevertState: PropTypes.func.isRequired,
  modals: modalsType.isRequired,
  fetchHistoryLogs: PropTypes.func,
  updateApPaymentConfig: PropTypes.func,
  partnerChannel: PropTypes.string,
  accountSelector: accountType,
  updateApInstallDays: PropTypes.func,
  updateAdminPortal: PropTypes.func,
  updateGraphSelection: PropTypes.func,
  isMobile: PropTypes.bool,
  dealersFeatureFlags: PropTypes.bool,
  graphSelections: PropTypes.objectOf(PropTypes.object),
  pvModuleSeriesDetails: PropTypes.arrayOf(PropTypes.object),
  storageProductConfig: PropTypes.objectOf(PropTypes.object),
  // eslint-disable-next-line react/forbid-prop-types
  pricingControls: PropTypes.object,
  uploadPricingFile: PropTypes.func,
  deletePricingFile: PropTypes.func
};

export default register(
  [ 'financialOfferingsSelector', 'modalsSelector', 'partnerChannelSelector', 'selectedModuleSelector',
    'selectedFinanceOptionSelector', 'allAvailableStatesSelector', 'financialOptionsSelector',
    'availableModuleSeriesSelector', 'selectedModuleSeriesSelector', 'availableModulesSelector', 'historyLogSelector',
    'apAvailableDownPaymentsSelector', 'apIsFullPrepaymentAllowedSelector', 'apFinConfigsSelector',
    'apSalesforceSelector', 'graphSelectionsSelector', 'pvModuleSeriesDetailsSelector', 'pricingControlsSelector',
    'dealersFeatureFlagsSelector', 'storageProductConfigSelector'
  ],
  [ 'selectFinanceOption', 'updateStateSelection', 'updateApPricing', 'updateApFinConfig', 'updateApInstallDays',
    'updateApProduct', 'updateApModuleSeries', 'updateApDefaultModule', 'toggleModal', 'closeModalAndRevertState',
    'updateApDefaultLoanProduct', 'updateApPaymentConfig', 'updateValidationErrors', 'fetchHistoryLogs',
    'updateApCustomAdders', 'updateAdminPortal', 'updateGraphSelection', 'uploadPricingFile', 'deletePricingFile'
  ],
  HomePage
);
