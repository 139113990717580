import get from './get';
import createSelector from '../utils/createSelector';
import {
  pvModulesSelector
} from '../selectors';
import { storageExpansionPackEnabled } from '../utils/backendFeatureFlags';

export function totalValues(state) {
  const quote = state.quote;
  let { selectedStorageQuantity } = state.storageSelectionModal;
  const { pvModules } = pvModulesSelector({ pvModules: state.pvModules });
  const selectedPvModule = pvModules.find((pvModule) => pvModule.itemId === quote.pvModuleItemId);
  if (!selectedPvModule) {
    return {};
  }

  const initialStorageQuantity = quote.storageQuantity;
  selectedStorageQuantity = selectedStorageQuantity == null ? initialStorageQuantity : selectedStorageQuantity;
  let storages;
  if (storageExpansionPackEnabled()) {
    storages = [...new Set(
      selectedPvModule.storageConfigurations.map((s) => {
        return { id: s.storageConfigId, name: s.storageName };
      })
    )];
  } else {
    storages = [...new Set(
      selectedPvModule.storageUnits.map((s) => {
        return { id: s.id, name: s.name };
      })
    )];
  }

  if (storages.length === 0) {
    selectedStorageQuantity = null;
  }

  if (!selectedStorageQuantity) {
    selectedStorageQuantity = 1; // defaults to 1
  }

  return {
    storageSelectionModal: {
      selectedStorageQuantity,
      storages
    }
  };
}

export default createSelector(
  get('storageSelectionModal'),
  totalValues
);
