import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-bootstrap-modal';
import { register } from '../../utils/redux';
import { currentStorageConfigurationType, designType, pvModuleType, quoteType } from '../../types';
import StorageWithMultiInvertersSettings from './StorageWithMultiInvertersSettings';

export function AddStorageWithMultiInvertersModal({
                                                    design,
                                                    quote,
                                                    closeAddStorageModal,
                                                    toggleAddStorageModalFlag,
                                                    updateStorage,
                                                    pvModule,
                                                    constantValues,
                                                    currentStorageConfiguration,
                                                    updateCurrentStorageConfiguration
                                                  }) {
  return (
    <Modal
      className="add-storage-with-multi-inverters-modal"
      show={design.isAddStorageModalOpen}
      onHide={closeAddStorageModal}
      aria-labelledby="ModalHeader"
    >
      <Modal.Header closeButton={true}>
        <div className="modal-title">SunVault&trade; Storage</div>
      </Modal.Header>
      <Modal.Body>
        <StorageWithMultiInvertersSettings
          quote={quote}
          toggleAddStorageModalFlag={toggleAddStorageModalFlag}
          updateStorage={updateStorage}
          pvModule={pvModule}
          constantValues={constantValues}
          currentStorageConfiguration={currentStorageConfiguration}
          updateCurrentStorageConfiguration={updateCurrentStorageConfiguration}
        />
      </Modal.Body>
    </Modal>
  );
}

AddStorageWithMultiInvertersModal.propTypes = {
  design: designType.isRequired,
  quote: quoteType.isRequired,
  closeAddStorageModal: PropTypes.func.isRequired,
  toggleAddStorageModalFlag: PropTypes.func.isRequired,
  updateStorage: PropTypes.func.isRequired,
  pvModule: pvModuleType.isRequired,
  constantValues: PropTypes.arrayOf(PropTypes.number),
  currentStorageConfiguration: currentStorageConfigurationType.isRequired,
  updateCurrentStorageConfiguration: PropTypes.func.isRequired
};

export default register(
  [
    'designSelector', 'quoteSelector', 'pvModuleSelector', 'constantValuesSelector',
    'currentStorageConfigurationSelector'
  ],
  [
    'closeAddStorageModal', 'toggleAddStorageModalFlag', 'updateStorage',
    'updateCurrentStorageConfiguration'
  ],
  AddStorageWithMultiInvertersModal
);
