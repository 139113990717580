import { storageExpansionPackEnabled } from './backendFeatureFlags';
import { showStorageWithExpPack } from './StorageWithMultiInverters/helpers';

export const SUNPOWER_STORAGE_PREFIX = 'sunvault';
export const ENPHASE_STORAGE_PREFIX = 'enphase';
export const TESLA_STORAGE_PREFIX = 'tesla';

export const isSunpowerStorage = (storageName) => {
  return storageName && (
    storageName.toLowerCase().startsWith(SUNPOWER_STORAGE_PREFIX)
    || storageName.toLowerCase().startsWith(ENPHASE_STORAGE_PREFIX)
    || storageName.toLowerCase().startsWith(TESLA_STORAGE_PREFIX)
  );
};

export const getStorageName = (selectedStorage, selectedStorageWithExpansionPack, quote) => {
  return showStorageWithExpPack(storageExpansionPackEnabled(), quote)
    ? selectedStorageWithExpansionPack.storageName
    : selectedStorage.name;
};
