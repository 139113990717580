import React, { useEffect, useState } from 'react';
import { get } from 'lodash';
import cx from 'classnames';
import PropTypes from 'prop-types';
import { register } from '../../utils/redux';
import { pvModuleType, quoteType, selectedEvChargerConfigurationType } from '../../types';
import {
  getEvChargerConfigId,
  getEvChargerConfigIds,
  getEvChargerConfigName,
  getOutletAllowedTransitions,
  getOutletConfigId,
  getOutletConfigName,
  getEvChargerConfigs,
  getWallBoxAllowedTransitions
} from '../../utils/EvChargers/evChargers';
import constants from '../../utils/EddieCalculations/constants';

const {
  EV_CHARGER,
  EV_OUTLET
} = constants;

function EvChargerSettings({ quote, pvModule, toggleAddEvChargerModalFlag,
  updateEvChargerConfig, selectedEvChargerConfiguration, partnerIsSpd }) {
  const selectedEvChargerQuantity = get(selectedEvChargerConfiguration, 'evChargerQuantity', 0);
  const selectedEvOutletQuantity = get(selectedEvChargerConfiguration, 'evOutletQuantity', 0);
  const selectedEvChargerId = get(selectedEvChargerConfiguration, 'evChargerId', null);
  const selectedEvChargerName = get(selectedEvChargerConfiguration, 'evChargerConfigName', null);
  const selectedEvOutletId = get(selectedEvChargerConfiguration, 'evOutletId', null);
  const evmChargerConfigurations = get(pvModule, 'evmChargerConfigurations', []);
  const [ selectedWallbox, setSelectedWallbox ] = selectedEvChargerName &&
    selectedEvChargerName.includes('48A') ? useState('48') : useState('40');
  const evChargerConfigId = getEvChargerConfigIds(evmChargerConfigurations, selectedWallbox);
  const evChargerConfigName = getEvChargerConfigName(evmChargerConfigurations, selectedWallbox);
  const evChargerConfigs = getEvChargerConfigs(evmChargerConfigurations);
  const evOutletConfigId = getOutletConfigId(evmChargerConfigurations);
  const evOutletConfigName = getOutletConfigName(evmChargerConfigurations);
  const [ evChargerQuantity, setEvChargerQuantity ] = useState(selectedEvChargerQuantity);
  const [ outletQuantity, setOutletQuantity ] = useState(selectedEvOutletQuantity);
  const [ wallBoxTransition, setWallBoxTransition ] =
    useState(getWallBoxAllowedTransitions(evmChargerConfigurations,
      evChargerQuantity, outletQuantity));
  const [ outletTransition, setOutletTransition ] =
    useState(getOutletAllowedTransitions(evmChargerConfigurations,
      evChargerQuantity, outletQuantity));
  const saveDisabled = (evChargerQuantity === 0 && outletQuantity === 0) || quote.locked;

  useEffect(() => {
    setWallBoxTransition(getWallBoxAllowedTransitions(evmChargerConfigurations,
      evChargerQuantity, outletQuantity));
    setOutletTransition(getOutletAllowedTransitions(evmChargerConfigurations,
      evChargerQuantity, outletQuantity));
  }, [ evChargerQuantity, outletQuantity ]);

  const addWallBox = () => {
    if (wallBoxTransition.additionAllowed) {
      setEvChargerQuantity(evChargerQuantity + 1);
    }
  };

  const subtractWallBox = () => {
    if (wallBoxTransition.subtractionAllowed) {
      setEvChargerQuantity(evChargerQuantity - 1);
    }
  };

  const addOutlet = () => {
    if (outletTransition.additionAllowed) {
      setOutletQuantity(outletQuantity + 1);
    }
  };

  const subtractOutlet = () => {
    if (outletTransition.subtractionAllowed) {
      setOutletQuantity(outletQuantity - 1);
    }
  };

  const onCancel = (event) => {
    event.preventDefault();
    toggleAddEvChargerModalFlag();
  };

  const onSave = (event) => {
    if (saveDisabled) return;
    const params = [ {
      id: selectedEvChargerId,
      quantity: evChargerQuantity,
      config_id: evChargerConfigId,
      config_name: evChargerConfigName,
      product_name: EV_CHARGER
    },
    {
      id: selectedEvOutletId,
      quantity: outletQuantity,
      config_id: evOutletConfigId,
      config_name: evOutletConfigName,
      product_name: EV_OUTLET
    }
    ];
    if (evChargerQuantity === 0) {
      if (selectedEvChargerId) {
        // eslint-disable-next-line no-underscore-dangle
        params[0]._destroy = true;
      } else {
        params.splice(0, 1);
      }
    }
    if (outletQuantity === 0) {
      if (selectedEvOutletId) {
        // eslint-disable-next-line no-underscore-dangle
        params[1]._destroy = true;
      } else {
        params.splice(1, 1);
      }
    }
    updateEvChargerConfig(quote, params);
    event.preventDefault();
    toggleAddEvChargerModalFlag();
  };

  return (
    <div className="modal-data-outer-container">
      <div className="modal-data-container">
        <div className="main-image-container">
          <img
            src={'/build/images/ev_charger_add_main_image.svg'}
            alt="Charger Visualization"
          />
        </div>
        <div>
          <div className="ev-wallbox-selector">
            {evChargerConfigs.map((evCharger) => {
              const evChargerType = evCharger.configName && evCharger.configName.includes('48') ? '48' : '40';
              return (
                <div
                  className={cx('wallbox-container', { selected: selectedWallbox === evChargerType })}
                  onClick={() => setSelectedWallbox(evChargerType)}
                >
                  {evChargerType} Amp
                </div>
              );
            })}
          </div>
          <div className="ev-charger-container">
            <div className="image-container">
              <img
                src={selectedWallbox === '40' ?
                  '/build/images/ev_charger_wallbox_hardwire.png' :
                  '/build/images/ev_charger_wallbox_plug_image.png'}
                alt="EV Charger Wallbox"
              />
            </div>
            <div className="action-container">
              <div
                className={cx('action-btn delete',
                  { disabled: !wallBoxTransition.subtractionAllowed || quote.locked })}
                onClick={subtractWallBox}
              >
                &#8722;
              </div>
              <div
                className={cx('action-btn add',
                  { disabled: !wallBoxTransition.additionAllowed || quote.locked })}
                onClick={addWallBox}
              >
                &#43;
              </div>
            </div>
            <div className="details-container">
              <div className="quantity">Quantity: <span className="value">{evChargerQuantity}</span></div>
              <div className="name">{selectedWallbox === '40' ?
              'Wallbox / 40-AMP / 9.6kW' : 'Wallbox / 48-AMP / 11.5kW'}</div>
              <div className="detail">Each Wallbox includes a 240 volt outlet</div>
            </div>
          </div>
          <div className="ev-outlet-container">
            <div className="label-container">
              <div className="title">Outlet Only</div>
              <div className="details">240 volt outlet</div>
            </div>
            <div className="action-container">
              <div
                className={cx('action-btn delete',
                  { disabled: !outletTransition.subtractionAllowed || quote.locked })}
                onClick={subtractOutlet}
              >
                &#8722;
              </div>
              <div className="counter-label">{outletQuantity}</div>
              <div
                className={cx('action-btn add',
                  { disabled: !outletTransition.additionAllowed || quote.locked })}
                onClick={addOutlet}
              >
                &#43;
              </div>
            </div>
          </div>
        </div>
        <div className="system-breakdown-container">
          <div className="system-breakdown-heading">System Breakdown</div>
          <div className="system-breakdown">
            <div className="breakdown-unit border-right border-top border-bottom">
              <div className="heading">Wallbox EV Charger</div>
              <div id="ev-charger-count" className="value">{evChargerQuantity}</div>
            </div>
            <div className="breakdown-unit border-top border-bottom">
              <div className="heading">Additional Outlets</div>
              <div id="outlet-count" className="value">{outletQuantity}</div>
            </div>
          </div>
          <div className="action-container">
            <button className="cancel-btn" onClick={onCancel}>Cancel</button>
            <button
              id="ev-charger-confirm-btn"
              className={cx('confirm-btn', { disabled: saveDisabled })}
              onClick={onSave}
            >
              Save
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

EvChargerSettings.propTypes = {
  quote: quoteType.isRequired,
  pvModule: pvModuleType.isRequired,
  toggleAddEvChargerModalFlag: PropTypes.func.isRequired,
  updateEvChargerConfig: PropTypes.func,
  selectedEvChargerConfiguration: selectedEvChargerConfigurationType,
  partnerIsSpd: PropTypes.bool
};

export default register(
  [],
  ['updateEvChargerConfig'],
  EvChargerSettings
);
