export function updateModuleSelectionModal(prop, newValue) {
  return {
    type: 'updateModuleSelectionModal',
    payload: {
      moduleSelectionModal: {
        [prop]: newValue
      }
    }
  };
}
