import { get } from 'lodash';
import { asyncPayload } from './apiHelpers';
import Api from '../utils/Api';

export function updateCreditApp(quoteSfid, payload, partnerUserId) {
  const promise = Api.update(`credit_app_request/?quote_sfid=${quoteSfid}`, payload)
  .then((result) => {
    return result;
  });

  return {
    type: 'updateCreditApp',
    payload: asyncPayload({
      request: promise
    })
  };
}
