import get from 'lodash/get';
import { asyncPayload } from './apiHelpers';
import { datadogTrackLoadingTime } from '../utils/datadogUserMonitoring';
import Api from '../utils/Api';
import { CALCULATING_SAVINGS } from '../config';
import { fetchGraphs } from './fetchGraphs';

export function updateManualModeRoofDesign(
  quoteSfid,
  settings,
  quote,
  adders,
  productConfiguration,
  channel,
  moduleChanged,
  storageChanged) {
  const timeoutTimeInMin = 3;
  const path = `quotes/${quoteSfid}`;
  const afterSuccPath = `quotes/${quoteSfid}/quote_details`;
  const promise = Api.updateWithPollingAndTimeout(
    path,
    afterSuccPath,
    { adders, settings, quote, moduleChanged, storageChanged },
    timeoutTimeInMin);
  const startTime = Date.now();

  return (dispatch) => {
    const response = dispatch({
      type: 'updateManualModeRoofDesign',
      payload: asyncPayload({
        beforeRequest: {
          mode: CALCULATING_SAVINGS
        },
        request: promise,
        afterRequest: {
          design: {
            hasChanges: false
          },
          dirtySettings: false,
          moduleChanged: false,
          storageChanged: false
        }
      })
    });

    response.then((data) => {
      if (!get(data, 'value.error', null)) {
        dispatch(fetchGraphs(quoteSfid));

        const loadingTime = Date.now() - startTime;

        // tracking only successful loading time
        if (!get(data, 'value.quote.dirty', true)) {
          datadogTrackLoadingTime(
            'Save Design',
            loadingTime,
            quote,
            settings,
            channel,
            productConfiguration
          );
        }
      }
      return data;
    });
  };
}
