import React from 'react';
import PropTypes from 'prop-types';

import cx from 'classnames';
import { formatNumber, formatCurrency, getDisplayDigits } from '../utils/formatNumber';
import AnimatedValueView from './AnimatedValueView';

function isNumber(string) {
  return !Number.isNaN(Number(string));
}

export default function SecondaryValueBox(
  { id, label, value, className, currency = false,
    rounded = false, centered = false,
    places, valueColor, valueWeight, animate = false
  }) {
  const classes = cx(
    'secondary-value-box',
    className,
    {
      'is-centered': centered
    }
  );

  const digits = getDisplayDigits(currency, rounded, places);

  const displayValue = isNumber(value) ?
    (currency ? formatCurrency(value, digits) : formatNumber(value, digits)) :
    value;

  return (
    <div id={id} className={classes}>
      <div className={cx('value-container', valueColor, valueWeight)}>
        { animate ?
          (
            <AnimatedValueView
              id={`${id}-value`}
              value={value}
              className="value"
              currency={currency}
              rounded={rounded}
            />
          ) :
            <div className="value">{displayValue}</div>
        }
      </div>
      <div className="label">{label}</div>
    </div>
  );
}

SecondaryValueBox.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([ PropTypes.number, PropTypes.string ]).isRequired,
  className: PropTypes.string,
  places: PropTypes.number,
  currency: PropTypes.bool,
  rounded: PropTypes.bool,
  centered: PropTypes.bool,
  id: PropTypes.string,
  valueColor: PropTypes.string,
  valueWeight: PropTypes.string,
  animate: PropTypes.bool
};

SecondaryValueBox.defaultProps = {
  id: null
};
