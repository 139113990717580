import createSelector from '../utils/createSelector';

function buildSkipPanelsList(validPanels) {
  return Array(validPanels.slice(-1).pop())
    .fill()
    .reduce((skipPanels, _, index) => [
      ...skipPanels,
      validPanels.includes(index) ? null : index
    ], [])
    .filter((val) => !!val);
}

function panels({ design }) {
  const arrays = design.moduleArrays || [];
  const skipPanels = buildSkipPanelsList(design.validPanels || []);
  const totalPanels = arrays
    .map((a) => (a.panels || []))
    .reduce((count, panels) => count + panels.length, 0);

  const totalEnabledPanels = arrays
    .reduce((total, arr) => total + arr.panels
      .filter((p) => p.enabled)
      .length
    , 0);

  return {
    panels: {
      skipPanels,
      totalEnabledPanels,
      totalPanels
    }
  };
}

export default createSelector(
  panels
);

