import React from 'react';
import PropTypes from 'prop-types';
import { quoteType, emailProposalUiType } from '../../../types';
import { register } from '../../../utils/redux';
import MailIcon from '../../../../images/icon-mail.svg';

export function ProposalToolbarEmailView({
  emailAdobeSignAgreement,
  setEmailProposalValue,
  emailProposalUi,
  quote
}) {
  const submit = () => {
    emailAdobeSignAgreement(quote.sfid, emailProposalUi.value);
  };
  return (
    <div className="toolbar-slidedown" id="proposal_email_content">
      <form className="form-horizontal">
        <MailIcon className="toolbar-slidedown__icon" />
        <div className="form-group">
          <h2 className="toolbar-slidedown__title">Send proposal via email</h2>
          {emailProposalUi.error &&
            <div className="alert alert--info">{emailProposalUi.error}</div>
          }
          {emailProposalUi.sent &&
            <div className="alert alert--success">Email sent successfully.</div>
          }
          <div className="input-container input-container--white">
            <input
              id="proposal_recipient_emails"
              type="email"
              placeholder="example@domain.com"
              value={emailProposalUi.value}
              disabled={emailProposalUi.sending || emailProposalUi.sent}
              onChange={(e) => setEmailProposalValue(e.target.value)}
            />
          </div>
        </div>
        <div className="input-container">
          <button
            id="email_proposal_agreement"
            className="btn btn-primary btn--block toolbar-slidedown__btn"
            type="button"
            disabled={emailProposalUi.sending || emailProposalUi.sent}
            onClick={submit}
          >
            Send proposal
          </button>
        </div>
        {!emailProposalUi.sent &&
          <p className="toolbar-slidedown__text toolbar-slidedown__text--small">
            A PDF copy of this proposal will be sent to the above email address.
          </p>
        }
      </form>
    </div>
  );
}

ProposalToolbarEmailView.propTypes = {
  emailProposalUi: emailProposalUiType.isRequired,
  quote: quoteType.isRequired,
  emailAdobeSignAgreement: PropTypes.func.isRequired,
  setEmailProposalValue: PropTypes.func.isRequired
};

export default register(
  [
    'emailProposalUiSelector',
    'quoteSelector'
  ],
  [
    'emailAdobeSignAgreement',
    'setEmailProposalValue'
  ],
  ProposalToolbarEmailView
);

