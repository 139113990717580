export function toggleNotificationModal() {
  return {
    type: 'toggleSavingsGraphTab',
    payload: {
      design: {
        notificationModal: (currentValue) => !currentValue
      }
    }
  };
}
