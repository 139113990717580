import AJV from 'ajv';
import get from 'lodash/get';

/*
  Gets the value from the schema, and if it's not there,
  gets it from the upper level, e.g.
  if we have lodashPath = 'properties.enabledPanelCount.allOf.2.not'
  and attr = 'x-is-optimization', this function will check the following lodash paths:
  - properties.enabledPanelCount.allOf.2.not.x-is-optimization
  - properties.enabledPanelCount.allOf.2.x-is-optimization
  - properties.enabledPanelCount.allOf.x-is-optimization
  - properties.enabledPanelCount.x-is-optimization
  - properties.x-is-optimization
  - x-is-optimization
*/
const getLowestLevel = (schema, lodashPath, attr) => {
  let value = get(schema, `${lodashPath}.${attr}`);
  const parts = lodashPath.split('.');

  while (value === undefined && parts.length > 0) {
    value = get(schema, `${parts.join('.')}.${attr}`);
    parts.pop();
  }
  if (value === undefined) value = get(schema, attr);
  return value;
};

const notificationsSorter = (notification1, notification2) => {
  // Sort by notification cost.
  if (notification1.cost && !notification2.cost) return -1;
  if (!notification1.cost && notification2.cost) return 1;
  if (notification1.cost && notification2.cost && notification1.cost !== notification2.cost) {
    return notification2.cost - notification1.cost;
  }
  // Sort by notification header.
  const notification1Header = get(notification1, 'notification.header');
  const notification2Header = get(notification2, 'notification.header');
  if (notification1Header && !notification2Header) return -1;
  if (!notification1Header && notification2Header) return 1;
  if (notification1Header && notification2Header) {
    return notification1Header < notification2Header ? -1 : 1;
  }
  return 1;
};

const filterErrors = (errors, schema, accountData) => {
  return errors.filter((error) => {
    const lodashPath = error.schemaPath.replace(/^#\//, '').replace(/\//g, '.');
    if (accountData.features.addersInEddie && getLowestLevel(schema, lodashPath, 'x-is-adder')) {
      return getLowestLevel(schema, lodashPath, 'x-is-adder');
    } else if (accountData.features.designOptimization && getLowestLevel(schema, lodashPath, 'x-is-optimization')) {
      return getLowestLevel(schema, lodashPath, 'x-is-optimization');
    }
    return [];
  });
};

/*
  Returns the list of notifications with the cost which is calculated from designData cost if x-calculate-cost is present for e.g.
  installationDistanceAdderCost for installationDistanceAdder else 'x-cost', if x-unit is false,
  or 'x-cost * value' otherwise, where 'value' is the result of the call of the method
  with the same name (as the notification key, e.g. 'flatRoofPanelCount')
  in the design redux state after being processed by the designSelector.
  The selector adds this function along other redux state attributes.
  The function has to take only one attribute: roof_design.

  Using the AJV naming, we iterate here over errors, but using EDDiE naming
  the errors are notifications (a.k.a. Design Optimizations)
*/
export default (schema, designData, accountData) => {
  const ajv = new AJV({ allErrors: true });
  if (!ajv.validate(schema, designData)) {
    const { errors } = ajv;
    const filteredErrors = filterErrors(errors, schema, accountData);
    const notifications = filteredErrors.map((error) => {
      const lodashPath = error.schemaPath.replace(/^#\//, '').replace(/\//g, '.');
      const errorMessage = getLowestLevel(schema, lodashPath, 'x-error');
      let cost;
      let notification;
      let showNegativeCost = false;
      let isAdder = false;
      let costForDisplayOnly = false;

      if (!errorMessage) {
        cost = getLowestLevel(schema, lodashPath, 'x-cost');
        showNegativeCost = getLowestLevel(schema, lodashPath, 'x-show-negative-cost');
        costForDisplayOnly = getLowestLevel(schema, lodashPath, 'x-cost-for-display-only');

        if (accountData.features.addersInEddie) {
          if (getLowestLevel(schema, lodashPath, 'x-calculate-cost')) {
            cost = designData[`${error.dataPath.slice(1)}Cost`];
          } else if (getLowestLevel(schema, lodashPath, 'x-unit')) cost *= designData[error.dataPath.slice(1)];
        } else if (getLowestLevel(schema, lodashPath, 'x-calculate-cost')) {
          cost = designData[`${error.dataPath.slice(1)}Cost`];
        } else {
          cost = 0;
        }
        notification = getLowestLevel(schema, lodashPath, 'x-notification');
        isAdder = getLowestLevel(schema, lodashPath, 'x-is-adder');
      }
      const itemId = getLowestLevel(schema, lodashPath, 'x-item-id');
      return {
        itemId,
        cost,
        notification,
        error: errorMessage,
        validation: error,
        showNegativeCost,
        isAdder,
        costForDisplayOnly
      };
    });
    return notifications.sort(notificationsSorter);
  }
  return [];
};
