import React from 'react';
import PropTypes from 'prop-types';
import Tab from './Tab';

export default function MainContentTabs({
  activeTab,
  toggle
}) {
  return (
    <div className="main-content-tabs">
      <Tab
        activeTab={activeTab}
        toggle={toggle}
        tabName="mainContentTab"
        tabValue="info"
        text="Product Info"
      />
      <Tab
        activeTab={activeTab}
        toggle={toggle}
        tabName="mainContentTab"
        tabValue="gallery"
        text="Gallery"
      />
    </div>
  );
}

MainContentTabs.propTypes = {
  activeTab: PropTypes.string.isRequired,
  toggle: PropTypes.func.isRequired
};
