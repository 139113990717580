import React from 'react';
import PropTypes from 'prop-types';
import ShowHideSectionComponent from './ShowHideSectionComponent';

export class GenericShowHide extends React.Component {
  constructor(props) {
    super(props);
    this.state = { expanded: false };
    this.onClick = this.onClick.bind(this);
  }

  onClick() {
    this.setState({
      expanded: !this.state.expanded
    });
  }

  render() {
    return (
      <ShowHideSectionComponent
        expanded={this.state.expanded}
        onClick={this.onClick}
      >
        {this.props.children}
      </ShowHideSectionComponent>
    );
  }
}

GenericShowHide.propTypes = {
  children: PropTypes.element.isRequired
};

export default GenericShowHide;
