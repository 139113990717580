import { cloneDeep } from 'lodash';
import { findApFinConfigIndexById } from '../../utils/admin_portal/updateHelpers';

/**
 *
 * @param {string} financeType
 * @param {string} moduleItemId
 * @param {string} moduleName
 * @param {string} moduleSeries
 * @returns updated state
 */
export const updateApDefaultModule = (configId, moduleItemId, moduleName, moduleSeries) => (dispatch, getState) => {
  const currentState = getState();
  const apFinConfigsToUpdate = cloneDeep(currentState.apFinConfigs);
  const finConfigIndex = findApFinConfigIndexById(apFinConfigsToUpdate, configId);

  if (apFinConfigsToUpdate[finConfigIndex].defaultModuleAttributes) {
    apFinConfigsToUpdate[finConfigIndex].defaultModuleAttributes = {
      ...apFinConfigsToUpdate[finConfigIndex].defaultModuleAttributes,
      moduleItemId,
      moduleName,
      moduleSeries
    };
  } else {
    apFinConfigsToUpdate[finConfigIndex].defaultModuleAttributes = {
      moduleItemId,
      moduleName,
      moduleSeries
    };
  }

  dispatch({
    type: 'updateApDefaultModule',
    payload: {
      apFinConfigs: apFinConfigsToUpdate
    }
  });
};
