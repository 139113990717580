import u from 'updeep';

export const closeModalAndRevertState = () => (dispatch, getState) => {
  const currentState = getState();
  const previousState = JSON.parse(currentState.modals.stateOnOpen);
  const quote = currentState.quote;
  // if you open the dialog, generate a public link, and click cancel
  // and then open the dialog again, the link should still be there
  // TODO: change the way we cancel modals. Reverting the previous state
  // for the whole application is 1) unnecessarly slow, 2) affects
  // unrelated part os the app in an unexpected way (if we trigger an action
  // that doesn't trigger a spinner, so the gear icon is still visible,
  // and then click the gear icon quickly enough, we would then be able
  // to cancel the original action by clicking "cancel" on the modal).
  const preserved = {
    quote: {
      publicAccessToken: quote.publicAccessToken,
      patExpiresAt: quote.patExpiresAt
    }
  };
  const previousStateAltered = u(preserved, previousState);
  return dispatch({
    type: 'closeModalAndRevertState',
    payload: u.constant(previousStateAltered)
  });
};
