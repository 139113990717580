import PropTypes from 'prop-types';

export const validationErrorType = PropTypes.shape({
  cash: PropTypes.shape({
    valid: PropTypes.bool
  }),
  lease: PropTypes.shape({
    valid: PropTypes.bool
  }),
  ppa: PropTypes.shape({
    valid: PropTypes.bool
  }),
  loan: PropTypes.shape({
    valid: PropTypes.bool
  })
});

