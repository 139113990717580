import d3 from 'd3';
import get from 'lodash/get';
import merge from 'lodash/merge';
import range from 'lodash/range';
import omit from 'lodash/omit';
import makeScaledC3Options from './makeScaledC3Options';

const allValues = (graphSeries, keys) => {
  return keys.map((key) => graphSeries[key]).reduce((acc, arr) => acc.concat(arr), []);
};

const makeDataColumns = (graphSeries, keys) => {
  return keys.map((key) => [ key, ...graphSeries[key] ]);
};

// lodash.merge deep merge treats arrays as objects and thus merges
// array by index. We need to completely overwrite axis.y.tick.values
// if scaledOptions includes them.
const mergeOptionsWithScaledOptions = (options, scaledOptions) => {
  const mergedOptions = merge(options, scaledOptions);
  if (get(scaledOptions, 'axis.y.tick.values')) {
    mergedOptions.axis.y.tick.values = get(scaledOptions, 'axis.y.tick.values');
  }
  return mergedOptions;
};

const tickCount = 5;

/**
 * makeC3Options accepts chartSeriesData and will
 * return options that can be passed to render a C3
 * chart. Will return null if chart should not render.
 * @param {Object} chartSeriesData
 * @param {Object} chartSeriesData.legend
 * @param {Object} chartSeriesData.graphSeries
 * @param {string} chartSelector d3 selector for svg for breakmark post-processing.
 * @param {Object} options
 * @param {String} options.financeType cash, loan, lease or spwrCash (activeTab).
 * @returns {Object} C3 chart options.
 */
export default ({ graphSeries = {} }, chartSelector, options = {}) => {
  const dataKeys = Object.keys(omit(graphSeries, ['labels']));

  const columns = makeDataColumns(graphSeries, dataKeys);
  const data = {
    columns,
    type: 'bar',
    colors: { savings: '#0093BF', costs: '#D8D8D8', rebatesAndCredits: '#00BF29' },
    onmouseover: (barData) => {
      // this event is called for each data series
      // that's why we execute action only for `savings`
      if ((barData.id === 'savings') && options.showInfoBox) {
        options.showInfoBox(barData.x);
      }
    }
  };
  const legendProp = { show: false };
  const tooltip = { show: false };

  const values = allValues(graphSeries, dataKeys);
  const max = Math.max(...values);
  const ticks = d3.scale.linear().domain([ 0, max ]).ticks(tickCount);

  const axis = {
    x: {
      tick: {
        outer: false,
        values: range(graphSeries.labels.length),
        format: (i) => graphSeries.labels[i]
      }
    },
    y: {
      padding: 0,
      min: 0,
      max,
      tick: {
        format: d3.format('$,'),
        values: ticks,
        outer: false
      }
    }
  };
  const scaledOptions = makeScaledC3Options(graphSeries, chartSelector, tickCount);
  return mergeOptionsWithScaledOptions({
    data,
    legend: legendProp,
    tooltip,
    axis
  }, scaledOptions);
};
