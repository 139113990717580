import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { register } from '../../utils/redux';
import Link from '../Link';

const logLabelMapping = {
  cash: 'cash',
  loan: 'loan',
  lease: 'lease',
  ppa: 'ppa'
};

const usersPerPage = 10;

export function HistoryLogPagination({ historyLog, fetchHistoryLogs }) {
  const [ activePageTab, setactivePageTab ] = useState(0);

  const handlePaginationClick = (indexPage) => {
    if (indexPage >= 0 && indexPage < historyLog.paginationRange) {
      fetchHistoryLogs(indexPage, usersPerPage);
      setactivePageTab(indexPage);
    }
  };

  const rendorPaginationNumbers = (activePageTab, paginationRange) => {
    const paginationNumbers = [];
    let minNumber = activePageTab - 2 > 1 ? activePageTab - 2 : 0;
    let maxNumber = activePageTab + 3 < paginationRange ? activePageTab + 3 : paginationRange;
    minNumber = (maxNumber === paginationRange) && (paginationRange - 5 >= 0) ? paginationRange - 5 : minNumber;
    maxNumber = (minNumber === 0) && (paginationRange - 5 >= 0) ? 5 : maxNumber;
    if (minNumber !== 0) { paginationNumbers.push(<Link onClick={() => handlePaginationClick(0)}>{1}</Link>); }
    if (minNumber > 1) { paginationNumbers.push('...'); }

    for (let index = minNumber; index < maxNumber; index++) {
      paginationNumbers.push(
        <Link className={index === activePageTab ? 'active' : ''} onClick={() => handlePaginationClick(index)}>
          {index + 1}
        </Link>);
    }

    if (maxNumber < paginationRange - 1) { paginationNumbers.push('...'); }
    if (maxNumber !== paginationRange) {
      paginationNumbers.push(
        <Link onClick={() => handlePaginationClick(paginationRange - 1)}>{paginationRange}</Link>
    );
    }
    return (
      <div className="pagination-numbers">
        {paginationNumbers}
      </div>
    );
  };

  return (
    <div className="history-log-pagination">
      <div className="pagination-numbers">
        <Link onClick={() => handlePaginationClick(0)}>
          <i className="icon double-icons icon-chevron-left" />
          <i className="icon double-icons icon-chevron-left" />
        </Link>
        <Link onClick={() => handlePaginationClick(activePageTab - 1)}>
          <i className="icon icon-chevron-left" />
        </Link>
      </div>
      {rendorPaginationNumbers(activePageTab, historyLog.paginationRange)}
      <div className="pagination-numbers">
        <Link onClick={() => handlePaginationClick(activePageTab + 1)}>
          <i className="icon icon-chevron-right" />
        </Link>
        <Link onClick={() => handlePaginationClick(historyLog.paginationRange - 1)}>
          <i className="icon double-icons icon-chevron-right" />
          <i className="icon double-icons icon-chevron-right" />
        </Link>
      </div>
    </div>
  );
}

HistoryLogPagination.propTypes = {
  historyLog: PropTypes.arrayOf(PropTypes.object),
  fetchHistoryLogs: PropTypes.func.isRequired
};

export default register(
  [],
  [],
  HistoryLogPagination
);
