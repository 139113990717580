import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import cx from 'classnames';
import { register } from '../../../utils/redux';
import { validateSelectedModuleSeries, validateSelectedModule } from '../../../utils/admin_portal/validationHelper';
import { moduleErrorDisplay } from '../../../utils/admin_portal/moduleErrorHelper';

export function ModuleSelectionView({ availableModuleSeries, selectedModuleSeries,
  updateApDefaultModule, availableModules, selectedModule,
  updateApModuleSeries, financeType, apFinConfigs, financialOfferings, selectedFinanceOption,
  updateValidationErrors, pvModuleSeriesDetails
}) {
  const [ isModuleDropDownOpen, setIsModuleDropDownOpen ] = useState(false);
  const [ isDefaultModuleDropDownOpen, setIsDefaultModuleDropDownOpen ] = useState(false);
  const [ moduleSeriesError, setModuleSeriesError ] = useState(null);
  const [ noModuleError, setNoModuleError ] = useState(null);
  const MODULE_DROPDOWN_ID = 'moduleDropDown';
  const DEFAULT_MODULE_DROPDOWN_ID = 'defaultModuleDropDown';
  const moduleDropDownRef = useRef(MODULE_DROPDOWN_ID);
  const defaultModuleDropDownRef = useRef(DEFAULT_MODULE_DROPDOWN_ID);
  const seriesCaptions = Object.fromEntries(
    pvModuleSeriesDetails.map((e) => [ e.series.toLowerCase(), e.desc ])
  );

  const seriesIconImageUrls = Object.fromEntries(
    pvModuleSeriesDetails.map((e) => [ e.series.toLowerCase(), e.iconImg ])
 );

  const selectedSeries = selectedModuleSeries;

  useEffect(() => {
    setModuleSeriesError(validateSelectedModuleSeries({ apFinConfigs,
      financialOfferings,
      selectedFinanceOption,
      selectedModuleSeries }));
    setNoModuleError(validateSelectedModule({ apFinConfigs, financialOfferings, selectedFinanceOption }));
  }, [ apFinConfigs, financeType ]);

  let apFinConfig;
  let isFOEnabled;

  if (financeType && apFinConfigs) {
    apFinConfig = apFinConfigs ?
      apFinConfigs.findLast((config) => config.finType.toLowerCase() === financeType.toLowerCase()) :
      null;
    isFOEnabled = get(apFinConfig, 'show');
  }

  useEffect(() => {
    updateValidationErrors(financeType, 'module', !!noModuleError);
  }, [noModuleError]);

  const handleFocusChange = (ref) => {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          if (ref.current.id === MODULE_DROPDOWN_ID) {
            setIsModuleDropDownOpen(false);
          } else if (ref.current.id === DEFAULT_MODULE_DROPDOWN_ID) {
            setIsDefaultModuleDropDownOpen(false);
          }
        }
      }

      // Bind the event listener
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, [ref]);
  };

  handleFocusChange(moduleDropDownRef);
  handleFocusChange(defaultModuleDropDownRef);

  const toggleDropDown = (isDefault) => {
    if (isDefault) {
      setIsDefaultModuleDropDownOpen(!isDefaultModuleDropDownOpen);
    } else {
      setIsModuleDropDownOpen(!isModuleDropDownOpen);
    }
  };

  const toggleModule = (moduleItemId, moduleName, moduleSeries) => {
    updateApDefaultModule(apFinConfig.id, moduleItemId, moduleName, moduleSeries);
    toggleDropDown(true);
  };

  const updateModuleSeries = (apFinConfig, option, checked) => {
    updateApModuleSeries(apFinConfig.id, option, checked);
    updateApDefaultModule(apFinConfig.id, null, null, null);
  };

  const moduleSeriesOptions = (<ul>
    { availableModuleSeries.map((series) => {
      return (
        <li
          className={cx('cs-option-container')}
        >
          <label className="cs-option">
            <input
              type="checkbox"
              checked={selectedSeries.indexOf(series) > -1}
              onChange={(e) => updateModuleSeries(apFinConfig, series, e.target.checked)}
            />
            <span className="checkmark" />
          </label>
          <div
            className={cx('module-image', { 'module-label': !seriesCaptions[series.toLowerCase()] })}
            style={{ backgroundImage: `url(${seriesIconImageUrls[series.toLowerCase()]})` }}
          >
            {seriesCaptions[series.toLowerCase()] ? '' : `${series}-SERIES`}
          </div>
          <div className="cs-option-detail">{seriesCaptions[series.toLowerCase()]}</div>
        </li>
      );
    })}
  </ul>);

  const moduleOptions = (<ul>
    {availableModules.length > 0 && (
      <li
        className={cx('cs-module-option-container', { 'cs-selected': get(selectedModule, 'moduleItemId') === null })}
        onClick={() => {
          if (!isFOEnabled) {
            return;
          }
          toggleModule(null, null);
        }}
      >
        <span className="cs-option">
          <div className="cs-option-name">-- Select --</div>
        </span>
      </li>
    )}
    { availableModules.map((option) => {
      return (
        <li
          className={
            cx('cs-module-option-container',
              { 'cs-selected': option.moduleItemId === get(selectedModule, 'moduleItemId') }
            )
          }
          onClick={() => {
            if (!isFOEnabled) {
              return;
            }
            toggleModule(option.moduleItemId, option.name, option.moduleSeries);
          }}
        >
          <span className="cs-option">
            <div className="cs-option-name">{option.name}</div>
          </span>
        </li>
      );
    })}
  </ul>);

  return (
    <div className="admin-section-data-container border-bottom">
      <div className="admin-section-data-heading-container flex-start">
        <div className="module-section-heading-label">
          <b>Select the module type(s)</b> you want to sell and what module will show by default:
        </div>
      </div>
      <div className="details-container width-90">
        <div className="select-container">
          <div className="label">MODULE TYPE TO SELL:</div>
          <div
            id={MODULE_DROPDOWN_ID}
            ref={moduleDropDownRef}
            className={cx('input-container input-container--icon icon-right dropdown-with-tags option-select',
              { disabled: !isFOEnabled }
            )}
          >
            <i className="icon icon-chevron-down" />
            <div className={cx('select-dropdown cs-skin-border', { 'cs-active': isModuleDropDownOpen })}>
              <span
                className="cs-placeholder" onClick={() => {
                  if (!isFOEnabled) {
                    setIsModuleDropDownOpen(false);
                    return;
                  }
                  toggleDropDown(false);
                }}
              >
                <div className="cs-option-name">Multiple Modules</div>
              </span>
              <div className="cs-options">
                {moduleSeriesOptions}
              </div>
            </div>
          </div>
          <div className="tags-container">
            {
              selectedSeries && selectedSeries.map((series) => {
                return (
                  <div
                    className={cx('tag cs-skin-border',
                      { error: moduleSeriesError && Object.keys(moduleSeriesError).includes(series) })}
                  >
                    <div
                      data-tip={true} data-event="click focus"
                      data-for={`${series}-error`}
                      ß={true}
                    >{series}-series</div>
                    {moduleErrorDisplay(moduleSeriesError, series)}
                    <div
                      className="delete-btn"
                      onClick={() => {
                        if (!isFOEnabled) {
                          return;
                        }
                        updateModuleSeries(apFinConfig, series, false);
                      }}
                    >&#x2715;</div>
                  </div>
                );
              })
            }
          </div>
        </div>
        <div className="select-container">
          <div className="label">DEFAULT MODULE TYPE:</div>
          <div
            id={DEFAULT_MODULE_DROPDOWN_ID}
            ref={defaultModuleDropDownRef}
            className={cx('input-container input-container--icon icon-right dropdown-with-tags option-select',
              { disabled: !isFOEnabled }
            )}
          >
            <i className="icon icon-chevron-down" />
            <div className={cx('select-dropdown cs-skin-border', { 'cs-active': isDefaultModuleDropDownOpen })}>
              <span
                className="cs-placeholder" onClick={() => {
                  if (!isFOEnabled) {
                    setIsDefaultModuleDropDownOpen(false);
                    return;
                  }
                  toggleDropDown(true);
                }}
              >
                <div className="cs-option-name">{get(selectedModule, 'moduleName') || 'Select Default'}</div>
              </span>
              <div className="cs-options">
                {moduleOptions}
              </div>
            </div>
          </div>
          {
            noModuleError && (
              <div className="error-message">
                *{noModuleError}
              </div>
            )
          }
        </div>
      </div>
    </div>
  );
}

ModuleSelectionView.propTypes = {
  availableModuleSeries: PropTypes.arrayOf(PropTypes.string),
  availableModules: PropTypes.arrayOf(PropTypes.string),
  selectedModuleSeries: PropTypes.arrayOf(PropTypes.string),
  selectedModule: PropTypes.objectOf(PropTypes.object),
  updateApModuleSeries: PropTypes.func.isRequired,
  updateValidationErrors: PropTypes.func.isRequired,
  financeType: PropTypes.string,
  apFinConfigs: PropTypes.arrayOf(PropTypes.object),
  updateApDefaultModule: PropTypes.func,
  financialOfferings: PropTypes.objectOf(PropTypes.object),
  selectedFinanceOption: PropTypes.string,
  pvModuleSeriesDetails: PropTypes.arrayOf(PropTypes.object)
};

export default register(
  [],
  [],
  ModuleSelectionView
);
