export function addItemFunction(item) {
  return (items) => [].concat(items, item);
}

export function updateItemFunction(item, idName = 'id') {
  return (items) => items.map((i) => i[idName] === item[idName] ? item : i);
}

export function deleteItemFunction(item, idName = 'id') {
  return (items) => items.filter((i) => i[idName] !== item[idName]);
}
