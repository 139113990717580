import React, { useEffect, useState } from 'react';
import { browserHistory } from 'react-router';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { register } from '../../utils/redux';
import { accountType, storageQuoteType } from '../../types';
import LineInput from '../LineInput';
import { getDealerPortalUrl } from '../../utils/url';

export function SystemCheck({
                              storageQuote,
                              showSystemCheckPage, cancelUrl, updateStorageQuote, account
                            }) {
  const [ isSystemSizeValid, setIsSystemSizeValid ] = useState(null);
  const [ systemSize, setSystemSize ] = useState(null);

  const MINIMUM_SYSTEM_SIZE = 4;
  const SUCCESS_REDIRECT_URL = `/storage/quote/${storageQuote.sfid}/selection`;

  const showError = () => {
    return (isSystemSizeValid === false) && (systemSize !== null);
  };

  const handleSystemSizeChange = (value) => {
    setIsSystemSizeValid(value && parseFloat(value) >= MINIMUM_SYSTEM_SIZE);
    setSystemSize(value);
  };

  useEffect(() => {
    handleSystemSizeChange(storageQuote.systemSize);
  }, [storageQuote]);

  const handleBackNavigation = () => {
    showSystemCheckPage(false);
  };

  const continueAction = () => {
    updateStorageQuote(storageQuote, { system_size: systemSize }, true, browserHistory, SUCCESS_REDIRECT_URL);
  };

  const openDealerPortal = () => {
    window.location.href = getDealerPortalUrl(account.sfid);
  };

  return (
    <React.Fragment>
      <img className="heading-image" src={'/build/images/storage-static-icon.svg'} alt="" />
      <div className="heading-text margin-bottom-top-20">SYSTEM SIZE COMPATIBILITY</div>
      <div className="sub-heading-text margin-bottom-top-10">Input the system size:</div>
      <div
        className={cx('system-size-input-container', 'margin-bottom-top-10', { 'error-field': showError() })}
      >
        <div className="input-container">
          <LineInput
            id="system-size-input"
            disabled={storageQuote.locked}
            value={systemSize || ''}
            positive={true}
            onChange={(newValue) => {
              handleSystemSizeChange(newValue);
            }}
          />
        </div>
        <div className="input-unit-container"><span>kilowatts</span></div>
      </div>
      <div
        className={cx('recommendation-text', 'margin-bottom-top-10', { 'error-text': showError() })}
      >
          We recommend installing additional modules for systems under {MINIMUM_SYSTEM_SIZE} kW
        </div>
      <div className="help-text margin-bottom-top-10">
        <span className="not-sure-text">Not sure?</span> Chat us in the
        <span
          className="action-text"
          onClick={() => {
            openDealerPortal();
          }}
        > Dealer Portal.</span>
      </div>
      {showError() &&
        <div className="recommendation-text margin-bottom-top-10">
          Use <span>EDDiE</span> to quote additional modules and storage
        </div>
        }
      <div className="storage-quote-action-btn-container margin-bottom-top-10">
        <button
          id="back-btn"
          className="btn btn-secondary back-btn"
          onClick={() => {
            handleBackNavigation();
          }}
        >
            Back
          </button>
        <button
          id="continue-btn"
          className="btn btn-primary next-btn"
          disabled={!isSystemSizeValid}
          onClick={() => continueAction()}
        >
            Continue
          </button>
      </div>
      <a
        href={cancelUrl}
        className="cancel-btn"
      >
          CANCEL
        </a>
    </React.Fragment>
  );
}

SystemCheck.propTypes = {
  storageQuote: storageQuoteType.isRequired,
  showSystemCheckPage: PropTypes.func,
  cancelUrl: PropTypes.string,
  updateStorageQuote: PropTypes.func,
  account: accountType.isRequired
};

export default register(
  [ 'storageQuoteSelector', 'accountSelector' ],
  ['updateStorageQuote'],
  SystemCheck
);
