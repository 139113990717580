import { hInverterEnabledForSO } from '../backendFeatureFlags';

const AVAILABLE_INVERTERS = [
  'SunPower Type E',
  'SunPower Type G',
  'SunPower Type C',
  'SunPower Type D',
  'SunPower SMA-XX-US-40',
  'SunPower SMA-XX-US-41',
  'SunPower SMA',
  'SunPower TL Series',
  'SunPower ABB',
  'SunPower Fronius',
  'Other'
];

export const getAvailableInverters = () => {
  if (hInverterEnabledForSO()) {
    return ['SunPower Type H'].concat(AVAILABLE_INVERTERS);
  }
  return AVAILABLE_INVERTERS;
};

export const COMPATIBLE_INVERTERS =
  [ 'SunPower Type E', 'SunPower Type G', 'SunPower Type H' ];

export const INCOMPATIBLE_INVERTERS = [
  'SunPower Type C',
  'SunPower Type D',
  'SunPower SMA-XX-US-40',
  'SunPower SMA-XX-US-41',
  'SunPower SMA',
  'SunPower TL Series',
  'SunPower ABB',
  'SunPower Fronius',
  'Other'
];
