
export function updateSelectedInverterAssociation(value, inverters) {
  const inverter = inverters.find((inverter) => inverter.id === value);
  const change = { inverterItemId: value, inverterName: inverter.name };

  return {
    type: 'updateSelectedInverterAssociation',
    payload: {
      selectedInverter: change
    }
  };
}
