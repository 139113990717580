import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import cx from 'classnames';
import { browserHistory } from 'react-router';
import { register } from '../../utils/redux';
import {
  accountType,
  evChargerConfigurationType,
  selectedSoEvChargerConfigurationType,
  storageConfigurationType,
  storageQuoteType
} from '../../types';
import {
  calculatedRatings,
  findStorageConfiguration,
  storageWithInverterTransitions
} from '../../utils/StorageWithMultiInverters/storageWithMultiInverters';
import { getBatteryImageName, getHomeImageName } from '../../utils/StorageWithMultiInverters/imageNames';
import { getPartnerPortalUrl } from '../../utils/url';
import { formatCurrency } from '../../utils/formatNumber';
import Link from '../Link';
import { isEvChargerSelected, showEvChargerForStorageOnly } from '../../utils/storageQuote/evChargers';
import { AddEvChargerForSOModal } from './add_ev_charger/AddEvChargerForSOModal';
import { getEvSelectionImageName } from '../../utils/EvChargers/evChargers';
import { partnerChannelIsIndirect, partnerIsSpd } from '../../utils/EddieCalculations/helpers/account';

export function StorageSelectionPageWithMultiInverter(
  {
    storageQuote,
    account,
    updateStorageQuote,
    lockStorageQuoteAndGenerateProposal,
    setError,
    storageConfigurations,
    selectedStorageConfiguration,
    constantValues,
    toggleAddEvChargerForSOModal,
    evChargerConfigurations,
    selectedEvChargerConfigurations,
    updateSoEvChargerConfig
  }) {
  const HOME_URL = `/storage/quote/${storageQuote.sfid}/home`;
  const PROPOSAL_URL = `/storage/quote/${storageQuote.sfid}/proposal`;
  const cancelUrl = getPartnerPortalUrl(account.sfid);
  const [ storageQuantity, setStorageQuantity ] = useState(storageQuote.storageQuantity || 1);
  const [ storageExpansionPackQuantity, setStorageExpansionPackQuantity ] =
    useState(storageQuote.storageExpansionPackQuantity || 0);
  const [ powerBoost, setPowerBoost ] = useState(storageQuote.powerBoost);
  const storageRatings = calculatedRatings(constantValues, storageQuantity, storageExpansionPackQuantity,
    selectedStorageConfiguration);
  const [ energyRating, setEnergyRating ] = useState(storageRatings.energyRating);
  const [ powerRating, setPowerRating ] = useState(storageRatings.powerRating);
  const [ allowedTransition, setAllowedTransition ] =
    useState(storageWithInverterTransitions(storageConfigurations,
      storageQuantity, storageExpansionPackQuantity, energyRating));
  const chargerSelected = get(selectedEvChargerConfigurations, 'evChargerQuantity', 0) > 0;

  const updateRatings = (storageQuantity, storageExpansionPackQuantity) => {
    if (!selectedStorageConfiguration.batterySize) {
      const updatedRatings = calculatedRatings(constantValues, storageQuantity, storageExpansionPackQuantity,
        selectedStorageConfiguration);
      setEnergyRating(updatedRatings.energyRating);
      setPowerRating(updatedRatings.powerRating);
    }
  };

  useEffect(() => {
    setAllowedTransition(storageWithInverterTransitions(storageConfigurations,
      storageQuantity, storageExpansionPackQuantity, energyRating));
    updateRatings(storageQuantity, storageExpansionPackQuantity);
  }, [ storageQuantity, storageExpansionPackQuantity, energyRating ]);

  const updateStorageQuoteValues = (storageQuantity, storageExpansionPackQuantity, powerBoost) => {
    const storageConfiguration =
      findStorageConfiguration(storageQuantity, storageExpansionPackQuantity,
        storageConfigurations, energyRating);
    updateStorageQuote(storageQuote,
      {
        storage_quantity: storageQuantity,
        storage_expansion_pack_quantity: storageExpansionPackQuantity,
        power_boost: powerBoost,
        storage_config_id: storageConfiguration.storageConfigId,
        storage_expansion_pack_model_name: storageConfiguration.storageExpansionPackModelName,
        storage_model_name: storageConfiguration.storageModelName,
        storage_labor_cost: storageConfiguration.storageLaborCost
      },
      false);
  };

  useEffect(() => {
    if (allowedTransition.storageQuantity !== storageQuantity ||
      allowedTransition.expansionPackQuantity !== storageExpansionPackQuantity) {
      setStorageQuantity(allowedTransition.storageQuantity);
      setStorageExpansionPackQuantity(allowedTransition.expansionPackQuantity);
    }
    if (!allowedTransition.powerBoostAllowed) {
      setPowerBoost(false);
    } else {
      setPowerBoost(allowedTransition.powerBoostEnabled);
    }
    updateStorageQuoteValues(allowedTransition.storageQuantity, allowedTransition.expansionPackQuantity,
      allowedTransition.powerBoostEnabled);
  }, [allowedTransition]);

  if (storageQuote.systemSize === null) {
    window.location.href = HOME_URL;
  }

  const removeStorage = () => {
    if (allowedTransition.subtraction) {
      setStorageQuantity(allowedTransition.subtraction[0]);
      setStorageExpansionPackQuantity(allowedTransition.subtraction[1]);
      setEnergyRating(allowedTransition.subtraction[2]);
      setPowerRating(allowedTransition.subtraction[3]);
      updateStorageQuoteValues(allowedTransition.subtraction[0], allowedTransition.subtraction[1], powerBoost);
    }
  };

  const addStorage = () => {
    if (allowedTransition.addition) {
      setStorageQuantity(allowedTransition.addition[0]);
      setStorageExpansionPackQuantity(allowedTransition.addition[1]);
      setEnergyRating(allowedTransition.addition[2]);
      setPowerRating(allowedTransition.addition[3]);
      updateStorageQuoteValues(allowedTransition.addition[0], allowedTransition.addition[1], powerBoost);
    }
  };

  const handlePowerBoostChange = (event) => {
    if (event) {
      if (event.target.checked) {
        setPowerBoost(true);
        if (allowedTransition.powerBoostEnabledState) {
          setStorageQuantity(allowedTransition.powerBoostEnabledState[0]);
          setStorageExpansionPackQuantity(allowedTransition.powerBoostEnabledState[1]);
          setPowerRating(allowedTransition.powerBoostEnabledState[3]);
          updateStorageQuoteValues(allowedTransition.powerBoostEnabledState[0],
            allowedTransition.powerBoostEnabledState[1], true);
        }
      } else {
        setPowerBoost(false);
        if (allowedTransition.powerBoostDisabledState) {
          setStorageQuantity(allowedTransition.powerBoostDisabledState[0]);
          setStorageExpansionPackQuantity(allowedTransition.powerBoostDisabledState[1]);
          setPowerRating(allowedTransition.powerBoostDisabledState[3]);
          updateStorageQuoteValues(allowedTransition.powerBoostDisabledState[0],
            allowedTransition.powerBoostDisabledState[1], false);
        }
      }
    }
  };

  const handleBackNavigation = () => {
    browserHistory.push(HOME_URL);
  };

  const openSettingsModal = () => {
    document.getElementById('settingModalToggleGearButton').click();
  };

  const viewProposalBtnClickHandler = () => {
    if (storageQuote.dealerCommission === null) {
      setError({ message: 'Please go to the settings icon and enter the dealer commission.' });
      return;
    }
    if (showEvChargerForStorageOnly(storageQuote) && isEvChargerSelected(storageQuote)
      && (partnerChannelIsIndirect(account.partner))) {
      if (storageQuote.evDealerCommission === null) {
        setError({ message: 'Please go to the settings icon and enter the EV dealer commission.' });
        return;
      }
    }
    lockStorageQuoteAndGenerateProposal(storageQuote, browserHistory, PROPOSAL_URL);
  };

  const viewProposalButtonHtml = () => {
    if (storageQuote.locked) {
      return (
        <Link
          className="btn btn-primary view-proposal-btn left-margin"
          to={PROPOSAL_URL}
        >
          View Proposal
        </Link>
      );
    }
    return (
      <button
        className="btn btn-primary view-proposal-btn left-margin"
        onClick={() => {
          viewProposalBtnClickHandler();
        }}
      >
        View Proposal
      </button>
    );
  };

  const deleteSelectedEvConfigs = () => {
    // eslint-disable-next-line no-alert
    if (confirm('Are you sure you want to delete this EV Charger selection?')) {
      const params = [];
      if (selectedEvChargerConfigurations.evChargerId) {
        params.push({
          id: selectedEvChargerConfigurations.evChargerId,
          _destroy: true
        });
      }
      if (selectedEvChargerConfigurations.evOutletId) {
        params.push({
          id: selectedEvChargerConfigurations.evOutletId,
          _destroy: true
        });
      }
      updateSoEvChargerConfig(storageQuote, params);
    }
  };

  const evChargerOutputDetails = () => {
    if (chargerSelected) {
      return (
        <React.Fragment>
          <div className="values">
            { selectedEvChargerConfigurations.evChargerConfigName ?
              <div>{selectedEvChargerConfigurations.evChargerConfigName.includes('40A') ? 9.6 : 11.5}</div>
            : <div>9.6</div>
          }
          </div>
          <div className="details">
            EV Charger Size
          </div>
        </React.Fragment>
      );
    }
    return (
      <React.Fragment>
        <div className="values">
          <div>240</div>
          <div className="unit">Volts</div>
        </div>
        <div className="details">
          Outlet Size
        </div>
      </React.Fragment>
    );
  };

  return (
    <div
      className={cx('container-fluid storage-selection-container expansion-pack-container',
        { 'ev-charger-enabled': showEvChargerForStorageOnly(storageQuote) })}
    >
      <div
        className={
          cx('multi-inverter-container', { 'ev-charger-enabled': showEvChargerForStorageOnly(storageQuote) })
      }
      >
        <div
          className={
            cx('home-image-container', { 'ev-charger-enabled': showEvChargerForStorageOnly(storageQuote) })
        }
        >
          <img
            src={`${getHomeImageName(storageQuantity, storageExpansionPackQuantity)}.png`}
            alt="Home Visualization"
          />
        </div>
        <div
          className={
            cx('storage-data-container', { 'ev-charger-enabled': showEvChargerForStorageOnly(storageQuote) })
        }
        >
          <div className="storage-container">
            <div className="image-container">
              <img
                src={`${getBatteryImageName(storageQuantity, storageExpansionPackQuantity)}.png`}
                alt="Battery and Expansion Pack"
              />
            </div>
            <div className="storage-action-container">
              <button
                id="remove-storage"
                className={cx('action-btn delete', { disabled: !allowedTransition.subtractionAllowed ||
                  storageQuote.locked })}
                onClick={() => !storageQuote.locked && removeStorage()}
              >
              &#8722;
            </button>
              <div
                id="add-storage"
                className={cx('action-btn add', { disabled: !allowedTransition.additionAllowed ||
                  storageQuote.locked })}
                onClick={() => !storageQuote.locked && addStorage()}
              >
              &#43;
            </div>
            </div>
            <div className="power-boost-section">
              <div
                className={cx('heading-container',
              { disabled: !allowedTransition.powerBoostAllowed || storageQuote.locked })}
              >
                {allowedTransition.showPowerBoost &&
                <div className="power-boost-heading">
                  <div className="heading">Power Boost</div>
                  <label className="switch">
                    <input
                      type="checkbox"
                      checked={powerBoost}
                      onChange={(event) => handlePowerBoostChange(event)}
                    />
                    <span className="slider round" />
                  </label>
                </div>
              }
              </div>
              <div className="information-text">
              Turn optimize for power on to back up more of the home’s
              needs for a longer period of time during an outage.
            </div>
            </div>
          </div>
        </div>
        <div
          className={
          cx('system-breakdown-container', { 'ev-charger-enabled': showEvChargerForStorageOnly(storageQuote) })
        }
        >
          <div className="system-breakdown-heading">System Breakdown</div>
          <div
            className={
            cx('system-breakdown', { 'ev-charger-enabled': showEvChargerForStorageOnly(storageQuote) })
          }
          >
            <div className="breakdown-unit border-right border-top">
              <div className="heading">Batteries</div>
              <div id="storage-count" className="value">{storageQuantity}</div>
            </div>
            <div className="breakdown-unit border-top">
              <div className="heading">Expansion Packs</div>
              <div id="expansion-count" className="value">{storageExpansionPackQuantity}</div>
            </div>
            <div className="breakdown-unit border-right border-top border-bottom">
              <div className="heading">Energy Rating</div>
              <div id="energy-rating" className="value rating-value">
                {energyRating}kWh
              </div>
            </div>
            <div className="breakdown-unit border-top border-bottom">
              <div className="heading">Power Rating</div>
              <div id="power-rating" className="value rating-value">
                {powerRating}kW
              </div>
            </div>
          </div>
          <div
            className={
            cx('price-detail-container', { 'ev-charger-enabled': showEvChargerForStorageOnly(storageQuote) })
          }
          >
            {storageQuote.dealerCommission !== null &&
            <div className="price-details">
              <div className="price-detail-row">
                <div id="price-detail-name" className="price-detail-name bold">
                  {selectedStorageConfiguration.storageName} {
                    storageQuote.storageQuantity > 0 && `(${storageQuote.storageQuantity})`}
                  {storageQuote.storageExpansionPackQuantity > 0 &&
                  ` & Expansion Pack (${storageQuote.storageExpansionPackQuantity})`}
                </div>
                <div className="dotted-line" />
                <div id="price-detail-value" className="price-detail-value bold">
                  {formatCurrency(storageQuote.systemCost, 0)}
                </div>
              </div>
              <div className="price-detail-row net-price-row">
                <div className="price-detail-name">Net Investment</div>
                <div className="dotted-line" />
                <div className="price-detail-value">
                  {formatCurrency(storageQuote.systemCost, 0)}
                </div>
              </div>
            </div>
            }
            {storageQuote.dealerCommission === null &&
            <div
              id="view-price"
              className="view-price-btn"
              onClick={() => {
                openSettingsModal();
              }}
            >VIEW PRICE</div>
            }
          </div>
          {
            showEvChargerForStorageOnly(storageQuote) && evChargerConfigurations && (
              <div className="ev-charger-config-container">
                <h4 className="title">EV ACCESSORIES
                  { selectedEvChargerConfigurations && !storageQuote.locked && (
                    <React.Fragment>
                      <img
                        className="icon edit" onClick={toggleAddEvChargerForSOModal}
                        src="/build/images/storage-edit.svg" height="15" alt="Edit Icon"
                      />
                      <img
                        className="icon delete"
                        src="/build/images/storage-delete.svg" height="15" alt="Delete Icon"
                        onClick={(evt) => {
                          evt.preventDefault();
                          // eslint-disable-next-line no-alert
                          deleteSelectedEvConfigs();
                        }}
                      />
                    </React.Fragment>
                  )}
                  { selectedEvChargerConfigurations && storageQuote.locked && (
                    <img
                      className="icon view" onClick={toggleAddEvChargerForSOModal}
                      src="/build/images/storage-view.svg" height="15" alt="View Icon"
                    />
                  )}
                </h4>
                { selectedEvChargerConfigurations ? (
                  <div className="ev-charger-details">
                    <div className="item-detail">
                      <div className="image ev_charger" />
                      <div className="description">
                        <div className="item-label"> WALLBOX</div>
                        <div className="item-name">
                          EV Charger
                        </div>
                      </div>
                    </div>
                    <div className="item-quantity">
                      <div className="image-container">
                        <img
                          src={`/build/images/${getEvSelectionImageName(chargerSelected)}.svg`}
                          alt="EV Selection Units"
                        />
                      </div>
                      <div className="details">
                        <div>EV Chargers: <span className="value">&nbsp;
                          {selectedEvChargerConfigurations.evChargerQuantity || 0}</span></div>
                        <div>Additional Outlets: <span className="value">&nbsp;
                          {selectedEvChargerConfigurations.evOutletQuantity || 0}</span></div>
                      </div>
                    </div>
                    <div className="item-size">
                      { evChargerOutputDetails() }
                    </div>
                  </div>
                ) : (
                  <React.Fragment>
                    <div className="no-ev-charger-text-container">
                      <div className="text">No chargers selected</div>
                      <div
                        className="btn-sunpower"
                        onClick={() => {
                          toggleAddEvChargerForSOModal();
                        }}
                      >
                        Add EV Chargers
                      </div>
                    </div>
                  </React.Fragment>
                )
                }
              </div>
            )
          }
          <div
            className={
            cx('action-btn-container', { 'ev-charger-enabled': showEvChargerForStorageOnly(storageQuote) })
          }
          >
            <div className="solid-btn-container margin-bottom-top-10">
              <button
                className="btn btn-secondary back-btn right-margin"
                onClick={() => handleBackNavigation()}
              >
                    Back
                  </button>
              {viewProposalButtonHtml()}
            </div>
            <a
              href={cancelUrl}
              className="cancel-btn"
              onClick={() => {
                return true;
              }}
            >
                  CANCEL
                </a>
          </div>
        </div>
      </div>
      <AddEvChargerForSOModal
        storageQuote={storageQuote}
        evChargerConfigurations={evChargerConfigurations}
        toggleAddEvChargerForSOModal={toggleAddEvChargerForSOModal}
        selectedEvChargerConfigurations={selectedEvChargerConfigurations}
        partnerIsSpd={partnerIsSpd(account.partner)}
      />
    </div>
  );
}

StorageSelectionPageWithMultiInverter.propTypes = {
  storageQuote: storageQuoteType.isRequired,
  account: accountType.isRequired,
  updateStorageQuote: PropTypes.func,
  lockStorageQuoteAndGenerateProposal: PropTypes.func,
  setError: PropTypes.func,
  storageConfigurations: PropTypes.arrayOf(storageConfigurationType).isRequired,
  selectedStorageConfiguration: storageConfigurationType.isRequired,
  constantValues: PropTypes.arrayOf(PropTypes.number),
  toggleAddEvChargerForSOModal: PropTypes.func,
  evChargerConfigurations: PropTypes.arrayOf(evChargerConfigurationType).isRequired,
  selectedEvChargerConfigurations: selectedSoEvChargerConfigurationType,
  updateSoEvChargerConfig: PropTypes.func
};

export default register(
  [
    'storageQuoteSelector',
    'accountSelector',
    'storageConfigurationsSelector',
    'selectedStorageConfigurationSelector',
    'constantValuesSelector',
    'evChargerConfigurationsSelector',
    'selectedSoEvChargerConfigurationsSelector'
  ],
  [ 'updateStorageQuote', 'lockStorageQuoteAndGenerateProposal', 'setError', 'toggleAddEvChargerForSOModal',
    'updateSoEvChargerConfig' ],
  StorageSelectionPageWithMultiInverter
);
