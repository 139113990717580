import React from 'react';
import Slider from 'react-slick';
import Modal from './Modal';
import { register } from '../utils/redux';
import { pvModuleType } from '../types';

export function PanelDetailsModal({ pvModule }) {
  const imgPrefix = `/build/images/modules-${pvModule.series}-series-`;
  return (
    <Modal
      name="panel-details"
      title="Panel Details"
      showCloseButtonOnly={true}
    >
      <span className="heading-text">{pvModule.series}-Series Solar Panels</span>
      <Slider dots={true} >
        <div>
          <img className="module-image" alt="Solar Panel" src={`${imgPrefix}img1.jpg`} />
        </div>
        <div>
          <img className="module-image" alt="Solar Panel" src={`${imgPrefix}img2.jpg`} />
        </div>
        <div>
          <img className="module-image" alt="Solar Panel" src={`${imgPrefix}img3.jpg`} />
        </div>
      </Slider>
      {(pvModule.series === 'X') && (
        <div className="panel-description">
          <div className="heading h4">
            Unmatched Performance, Reliability and Aesthetics
          </div>

          <p>
            <strong>Most energy from less space:</strong> Deliver 44% more power
            per panel than convential panels and produce 75% more energy per square foot over the first 25 years.
          </p>

          <p>
            <strong>Most peace of mind:</strong> More guaranteed power with the best combined power and
            product warranty over 25 years. And because they’re built on a solid copper foundation,
            SunPower solar cells deliver unmatched reliability over the lifetime of your
            solar energy system.
          </p>

          <p>
            <strong>Most beautiful:</strong> X-Series panels are offered in SunPower® Signature™ Black, designed
            to blend harmoniously into your roof. Built using all-black solar cells and anti-reflective
            glass to reduce glare, the premium aesthetics can accommodate a variety of architectural
            styles.
          </p>
        </div>
      )}

      {(pvModule.series === 'E') && (
        <div className="panel-description">
          <div className="heading h4">
            High Performance, Excellent Durability
          </div>

          <p>
            <strong>More energy from less space:</strong> Deliver 36% more power per panel than conventional
            panels and produce 60% more energy over 25 years, providing you more savings.
          </p>

          <p>
            <strong>More flexibility:</strong> Because E-Series residential solar panels generate more electricity from
            a smaller area, you can expand your footpring to product more energy or conserve roof
            space based on electricity needs.
          </p>

          <p>
            <strong>More peace of mind:</strong> More guaranteed power with the best combined power and product
            warranty over 25 years.
          </p>
        </div>
      )}
    </Modal>
  );
}

PanelDetailsModal.propTypes = {
  pvModule: pvModuleType.isRequired
};

export default register(
  ['pvModuleSelector'],
  [],
  PanelDetailsModal
);
