import { every } from 'lodash';
import { isInt } from 'validator';

export function required(value) {
  let isValid = false;

  if (typeof value === 'number') {
    isValid = true;
  } else {
    isValid = !!value;
  }
  return isValid ? [] : ['cannot be empty'];
}

export function isInteger(value) {
  const isValid = isInt(`${value}`);

  return isValid ? [] : ['must be a number'];
}

export function minMaxFunction(min, max) {
  return (value) => value >= min && value <= max ? [] : [`must be between ${min} and ${max}`];
}

export function minMax(value) {
  const funct = minMaxFunction(0, 100);
  return funct(value);
}

export function valuesNotTheSame(values) {
  const [ first, ...rest ] = values;
  const isValid = !every(rest, (v) => `${first}` === `${v}`);

  return isValid ? [] : ['values cannot all be the same'];
}

export function allRequired(values) {
  return values.reduce((m, v) => m && required(v).length === 0, true) ? [] : ['all fields are required'];
}

export function allValuesBetween(values) {
  return values.reduce((m, v) => m && minMax(v).length === 0, true) ? [] : ['values must be between 0 and 100'];
}
