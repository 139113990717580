import React from 'react';
import PropTypes from 'prop-types';
import withErrors from '../forms/withErrors';
import { register } from '../../utils/redux';

export function GroupErrorMessages({ title, fieldErrors, hasErrors }) {
  if (!hasErrors) {
    return null;
  }

  return (
    <div className="group-validation-errors">
      { title ? (
        <strong>{title}:</strong>
      ) : null }
      <ul>
        { fieldErrors.map((error, key) => <li key={key}>{error}</li>)}
      </ul>
    </div>

  );
}

GroupErrorMessages.propTypes = {
  title: PropTypes.string,
  fieldErrors: PropTypes.arrayOf(PropTypes.string),
  hasErrors: PropTypes.bool
};

export default register(
  [],
  [],
  withErrors(GroupErrorMessages)
);
