import React from 'react';
import get from 'lodash/get';
import { showStorageWithExpPack } from './StorageWithMultiInverters/helpers';
import { storageExpansionPackEnabled } from './backendFeatureFlags';
import constants from './EddieCalculations/constants';

const { ENPHASE, TESLA, SUNPOWER } = constants;

export const storageMaxKwh = (quote, selectedStorage, selectedStorageWithExpansionPack) => {
  if (showStorageWithExpPack(storageExpansionPackEnabled(), quote)) {
    if (selectedStorageWithExpansionPack.storageName &&
      selectedStorageWithExpansionPack.storageName.toLowerCase().includes('sunvault')) {
      return (quote.storageQuantity + quote.storageExpansionPackQuantity) *
        Number(selectedStorageWithExpansionPack.storageName.split(' ').pop());
    }
  } else if (selectedStorage.name && selectedStorage.name.toLowerCase().includes('sunvault')) {
    return quote.storageQuantity * Number(selectedStorage.name.split(' ').pop());
  }
  return quote.storageKwhProduction;
};

export const storageImage = (manufacturer, backupCapable) => {
  let ImageName = `${manufacturer.toLowerCase()}-3p`;
  ImageName = manufacturer.toLowerCase() === ENPHASE && backupCapable ? `${ImageName}-with-backup` : ImageName;
  return ImageName;
};

export const storageNameImage = (name, quote) => {
  if (showStorageWithExpPack(storageExpansionPackEnabled(), quote)) {
    return 'equinox-with-multi-inverter';// '/build/images/sunpower_battery_with_expansion_pack';
  }
  if (name.includes('Sonnen')) return 'sonnen';// '/build/images/Sonnen.png';
  if (name.includes('Powerwall')) return 'tesla';// '/build/images/Tesla.png';
  return 'equinox';// '/build/images/Sunpower_battery.svg';
};

export const storageName = (name) => {
  let title = name.split(' ')[0].toUpperCase();
  let details = name.slice(name.indexOf(' '));
  if (title === 'EQUINOX') {
    title = (<span>SunVault&trade;</span>);
    details = details.substring(0, details.lastIndexOf(' '));
  }
  return (<p>{title}<br /><span>{details}</span></p>);
};

export const storageNameOnlyText = (name) => {
  let title = name.split(' ')[0].toUpperCase();
  let details = name.slice(name.indexOf(' '));
  if (title === 'EQUINOX') {
    title = (<span>SunVault&trade;</span>);
    details = details.substring(0, details.lastIndexOf(' '));
  }
  return `${title} ${details}`;
};

export const getStorageCombinationImageName = (quote) => {
  return (quote.storageQuantity > 0 && quote.storageExpansionPackQuantity > 0) ?
    'storage-with-expansion' : (quote.storageQuantity > 0 ? 'storage-only' : '');
};

export const getStorageUnitsImage = (quote) => quote.storageQuantity > 2 ? 2 : quote.storageQuantity;

export const isStorageEnabled = (businessFunctions, isPartnerEnabledForAnyStorage) =>
  businessFunctions && businessFunctions.includes('Storage Enabled') && isPartnerEnabledForAnyStorage;

export const isStorageSelected = (storageSelectionModal, quote, selectedStorageWithExpansionPack, selectedStorage) =>
  storageSelectionModal.storages.length > 0 &&
  (showStorageWithExpPack(storageExpansionPackEnabled(), quote) ?
    selectedStorageWithExpansionPack.storageConfigId != null :
    selectedStorage.id != null);

export const totalQuantity = (battery) => {
  return battery.batterySize * battery.storageQuantity;
};

export const storage3pImage = (manufacturer, totalQuantity, backupCapable) => {
  let imageClass = 'sunpower-3p';
  if (manufacturer.toLowerCase() === ENPHASE) {
    const enphaseQuantites = [ 5, 10, 15, 20 ];
    const imageSize = enphaseQuantites.includes(totalQuantity) ? totalQuantity : 5;
    imageClass = `enphase-${imageSize}`;
    imageClass += backupCapable ? '-backup' : '-nonbackup';
  } else if (manufacturer.toLowerCase() === TESLA) {
    imageClass = 'tesla-3p';
  }

  return imageClass;
};

export const getStorageSize = (quote, storageQuantity) => {
  return (quote.batteryManufacturer && quote.batteryManufacturer.toLowerCase() === SUNPOWER) ?
   get(quote, 'storageSize') : quote.batterySize * storageQuantity;
};
