import React from 'react';
import PropTypes from 'prop-types';
import Clipboard from 'react-clipboard.js';
import moment from 'moment';
import { register } from '../../utils/redux';
import { quoteType } from '../../types';

export function ShareableLinkSection({
  quote,
  generatePublicAccessToken,
  isSaved
}) {
  const shareableUrl = `${window.location.origin}/public/quote/${quote.sfid}/design?pat=${quote.publicAccessToken}`;
  const patExpiresAt = moment(quote.patExpiresAt);
  const isTokenActive = patExpiresAt > moment();
  const generateShareableLink = (event) => {
    event.preventDefault();
    generatePublicAccessToken(quote.sfid);
  };
  const hasToken = quote.publicAccessToken;

  const showPublicLink = hasToken && isTokenActive;
  const showRegenerateLink = hasToken && !isTokenActive;
  const showGenerateLink = !hasToken && isSaved;
  const showNotification = !hasToken && !isSaved;

  return (
    <div className="input-container">
      { showPublicLink && (
        <div>
          <Clipboard
            className="btn btn-secondary btn--small"
            data-clipboard-text={shareableUrl}
            button-title={shareableUrl}
          >
            Copy to clipboard
          </Clipboard>
          <a
            href={shareableUrl}
            className="btn btn-secondary btn--small row-item"
            target="_blank"
            rel="noopener noreferrer"
          >
            Open
          </a>
          <span className="expires-at row-item">(expires {patExpiresAt.fromNow()})</span>
        </div>
      )}
      { showGenerateLink && (
        <a href="#" className="btn btn-secondary btn--small" onClick={generateShareableLink}>
          Generate shareable quote link
        </a>
      )}
      { showRegenerateLink && (
        <div>
          <a href="#" className="btn btn-secondary btn--small" onClick={generateShareableLink}>
            Regenerate shareable quote link
          </a>
          <span className="expires-at row-item">(link expired)</span>
        </div>
      )}
      { showNotification && (
        <div>
          You need to save the design to be able to generate the link.
        </div>
      )}
    </div>
  );
}

ShareableLinkSection.propTypes = {
  quote: quoteType.isRequired,
  generatePublicAccessToken: PropTypes.func.isRequired,
  isSaved: PropTypes.bool.isRequired
};

export default register(
  [ 'quoteSelector', 'isSavedSelector' ],
  ['generatePublicAccessToken'],
  ShareableLinkSection
);
