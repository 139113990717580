import { keys, pick, pickBy } from 'lodash';

export const PRICE_PER_WATT = 'pricePerWatt';
export const GROSS_PRICE = 'grossPrice';
export const DEALER_FEE_PER_WATT = 'dealerFeePerWatt';
export const PRICE_PER_KWH = 'pricePerKwh';
export const REBATE_ESTIMATE = 'rebateEstimate';
export const OVERRIDE_REBATE = 'overrideRebate';
export const ALL_PRICE_INPUT_TYPES = [
  PRICE_PER_WATT,
  GROSS_PRICE,
  DEALER_FEE_PER_WATT,
  PRICE_PER_KWH
];
export const DEFAULT_TERM_IN_MONTH_FOR_ULTRA_LOW_APR = 300;
export const TCU = 'TCU';
export const MOSAIC = 'Mosaic';
export const SPF = 'SPF';
export const PENFED = 'PenFed';
export const UNDETERMINED = 'Undetermined';
export const ULTRA_LOW = 'Ultra Low';
export const LOW = 'Low';
export const STANDARD = 'Standard';

export const getAvailableAndEnabledFinanceSettings = ({ settings, productConfiguration }) => {
  const productsAvailable = keys(pickBy(productConfiguration.financialOfferings, (x) => x));
  const availableProducts = pick(settings, productsAvailable);

  const keysOfAvailableProductsWithShowCustomer = keys(pickBy(availableProducts, (x) => x.showCustomer));

  return pick(availableProducts, keysOfAvailableProductsWithShowCustomer);
};
