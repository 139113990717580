import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { register } from '../utils/redux';
import { monitoringConfigurationType, quoteType, dropdownsType } from '../types';

const defaultContainerClasses = 'input-container input-container--icon icon-right dropdown-with-tags';

export function MonitoringConfigurationsDropdown({
  monitoringConfigurations,
  monitoringConfiguration,
  dropdowns: { isMonitoringConfigurationOpen },
  onSelect,
  toggleMonitoringConfigurationDropdown,
  quote
}) {
  const options = (<ul>
    {monitoringConfigurations.map((option) => {
      return (
        <li
          className={cx('cs-option-container', { 'cs-selected': option.id === monitoringConfiguration.id })}
          onClick={() => {
            toggleMonitoringConfigurationDropdown();
            onSelect(option.id);
          }}
          key={option.id}
        >
          <span className="cs-option">
            <div className="cs-option-name">{option.name}</div>
          </span>
        </li>
      );
    })}
  </ul>);

  return (
    <div
      id="monitoring_configurations_dropdown"
      className={cx(defaultContainerClasses, { 'is-disabled': quote.locked })}
    >
      <i className="icon icon-chevron-down" />
      <div
        className={cx('select-dropdown cs-skin-border', { 'cs-active': isMonitoringConfigurationOpen })}
        tabIndex="0"
      >
        <span className="cs-placeholder" onClick={toggleMonitoringConfigurationDropdown}>
          <div className="cs-option-name">{monitoringConfiguration.name}</div>
        </span>
        <div className="cs-options">
          { options }
        </div>
      </div>
    </div>
  );
}

MonitoringConfigurationsDropdown.propTypes = {
  monitoringConfigurations: PropTypes.arrayOf(monitoringConfigurationType).isRequired,
  monitoringConfiguration: monitoringConfigurationType.isRequired,
  dropdowns: dropdownsType.isRequired,
  quote: quoteType.isRequired,
  onSelect: PropTypes.func.isRequired,
  toggleMonitoringConfigurationDropdown: PropTypes.func.isRequired
};

export default register(
  [
    'monitoringConfigurations',
    'monitoringConfiguration',
    'dropdownsSelector',
    'quoteSelector'
  ],
  ['toggleMonitoringConfigurationDropdown'],
  MonitoringConfigurationsDropdown
);

