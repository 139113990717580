import React from 'react';
import PresentationViewer from './PresentationViewer';

export default function PresentationPage() {
  return (
    <div
      id="main-content" className="presentation-page"
      style={{ overflow: 'hidden', WebkitOverflowScrolling: 'touch' }}
    >
      <div className="pdf-viewer-container">
        <PresentationViewer url={'/public/static/presentation'} />
      </div>
    </div>
  );
}
