import PropTypes from 'prop-types';

export const modalsType = PropTypes.shape({
  name: PropTypes.string,
  coordinates: PropTypes.shape({
    top: PropTypes.number,
    left: PropTypes.number,
    right: PropTypes.number
  })
});
