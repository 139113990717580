import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { register } from '../../../utils/redux';
import { dropdownsType, quoteType, selectedEvChargerConfigurationType } from '../../../types';
import {
  getEvChargerAllowedQuantityList
} from '../../../utils/EvChargers/evChargers';

const defaultContainerClasses = 'input-container input-container--icon icon-right dropdown-with-tags quantity-field';

export function EvChargerQuantityDropDown({
  selectedEvChargerConfiguration,
  dropdowns: { isEvChargerQuantityOpen },
  onSelect,
  toggleEvChargerQuantityDropdown,
  quote
}) {
  const evChargerQuantities = getEvChargerAllowedQuantityList();
  const evChargerQuantity = selectedEvChargerConfiguration.evChargerQuantity || 0;
  const options = (<ul>
    {
      evChargerQuantities.map((i) => (
        <li
          className={cx('cs-option-container', { 'cs-selected': evChargerQuantity === i })}
          onClick={() => {
            toggleEvChargerQuantityDropdown();
            onSelect(selectedEvChargerConfiguration.evChargerId,
              selectedEvChargerConfiguration.evChargerConfigId,
              selectedEvChargerConfiguration.evChargerConfigName,
              i);
          }}
          key={`quantity${i}`}
        >
          <span className="cs-option">
            <div className="cs-option-name">{i}</div>
          </span>
        </li>
      ))
    }
  </ul>);
  return options.props.children.length > 0 && selectedEvChargerConfiguration.evChargerId ? (
    <div
      id="ev_charger_quantity_dropdown"
      className={cx(defaultContainerClasses, { 'is-disabled': quote.locked })}
    >
      <i className="icon icon-chevron-down" />
      <div
        className={cx('select-dropdown cs-skin-border', { 'cs-active': isEvChargerQuantityOpen })}
        tabIndex="0"
      >
        <span className="cs-placeholder" onClick={toggleEvChargerQuantityDropdown}>
          <div className="cs-option-name">{evChargerQuantity}</div>
        </span>
        <div className="cs-options">
          {options}
        </div>
      </div>
    </div>
  ) : (
    <div id="ev_charger_quantity_dropdown" className="input-container">
      <input type="text" value={evChargerQuantity} disabled={true} />
    </div>
  );
}

EvChargerQuantityDropDown.propTypes = {
  selectedEvChargerConfiguration: selectedEvChargerConfigurationType,
  dropdowns: dropdownsType.isRequired,
  quote: quoteType.isRequired,
  onSelect: PropTypes.func.isRequired,
  toggleEvChargerQuantityDropdown: PropTypes.func.isRequired
};

export default register(
  [
    'selectedEvChargerConfigurationSelector',
    'dropdownsSelector',
    'quoteSelector'
  ],
  ['toggleEvChargerQuantityDropdown'],
  EvChargerQuantityDropDown
);

