import { partnerIsSpd, partnerIsNewHomes, partnerIsTps } from '../utils/EddieCalculations/helpers/account';
import constants from '../utils/EddieCalculations/constants';

const { NON_SUNPOWER_GROUND_MOUNT_RACKING } = constants;

const restrictToInvisimountRacking = ({ partner }) =>
  partnerIsNewHomes(partner);

const restrictToInvisimountOrGroundMountRacking = ({ partner }) =>
  partnerIsTps(partner) || partnerIsSpd(partner);

export default ({ productConfiguration, account }) => {
  let { rackings } = productConfiguration;
  if (rackings) {
    // This logic of restricting TPS and SPD to invisimount and ground mount is only for
    // manual quotes.
    // If this selector is used for dynamic quote then update this condition.
    if (restrictToInvisimountRacking(account)) {
      rackings = rackings.filter((racking) => /invisimount/i.test(racking.type));
    } else if (restrictToInvisimountOrGroundMountRacking(account)) {
      const pattern = new RegExp(`invisimount|${NON_SUNPOWER_GROUND_MOUNT_RACKING}`, 'i');
      rackings = rackings.filter((racking) => pattern.test(racking.type));
    }
  }

  return ({
    productConfiguration: {
      ...productConfiguration,
      rackings
    }
  });
};
