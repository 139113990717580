import get from './get';
import createSelector from '../utils/createSelector';

function getDeclined({ credit }) {
  return {
    credit: {
      loanIsDeclined: credit.status.loan === 'declined',
      leaseIsDeclined: credit.status.lease === 'declined',
      ppaIsDeclined: credit.status.ppa === 'declined'
    }
  };
}

export default createSelector(
  get('credit'),
  getDeclined
);
