import React, { useRef, useEffect } from 'react';
import Chart from 'chart.js';
import PropTypes from 'prop-types';

import { formatCurrency } from '../../utils/formatNumber';

import { totalSavingsChartType } from '../../types';

import styles from '../../../scss/charts/TotalSavingsChart.module.scss';

Chart.defaults.global.defaultFontColor = '#000000';
Chart.defaults.global.defaultFontFamily = 'Open Sans';
Chart.defaults.global.defaultFontSize = 14;

let barChart;

/* eslint no-underscore-dangle: 0 */

const TotalSavingsChart = ({
  data,
  selectedInflationRate,
  selectedFinanceOption,
  legendText,
  isPDFProcessing = false
}) => {
  const isMobile = false;
  const chartRef = useRef(null);
  const tickFontSize = isMobile ? 12 : 14;
  const ticks = {
    suggestedMin: 0,
    stepSize: 10000,
    fontColor: '#000000',
    fontFamily: 'Open Sans',
    fontSize: 10,
    beginAtZero: true,
    maxTicksLimit: 20,
    callback: (value, _index, _values) => {
      return formatCurrency(value, 0);
    }
  };

  const options = {
    tooltips: {
      enabled: false,
      custom: (tooltipModel) => {
        // Tooltip Element
        let tooltipEl = document.getElementsByClassName('chartjs-tooltip')
          ? document.getElementsByClassName('chartjs-tooltip')[0] : null;

        // Create element on first render
        if (!tooltipEl) {
          tooltipEl = document.createElement('div');
          tooltipEl.classList.add('chartjs-tooltip');
          // eslint-disable-next-line max-len
          tooltipEl.innerHTML = '<ul style="background-color: rgba(255, 255, 255, 1); border-radius: 4px; list-style: none; padding: 0; margin: 0; width: 210px; box-shadow: 4px 4px 14px rgba(120, 125, 134, 0.35);"></ul>';
          document.body.appendChild(tooltipEl);
        }

        // Hide if no tooltip
        if (tooltipModel.opacity === 0) {
          tooltipEl.style.opacity = 0;
          return;
        }

        // Set caret position
        tooltipEl.classList.remove('top', 'bottom', 'no-transform');
        if (tooltipModel.yAlign) {
          tooltipEl.classList.add(tooltipModel.yAlign);
        } else {
          tooltipEl.classList.add('no-transform');
        }

        // Set Text
        if (tooltipModel.body) {
          let innerHtml = '';
          const datasetIndex = tooltipModel.dataPoints[0].index;

          const gradient = [
            'linear-gradient(180deg, #F2F1F1 0%, #E3E3E3 100%)',
            'linear-gradient(180deg, #545A5A 0%, #B9B4B4 180.88%, #000000 180.88%)',
            'linear-gradient(180deg, #6AA442 0%, #6BC42D 100%)'
          ];
          for (let i = barChart.data.datasets.length - 1; i >= 0; i--) {
            // eslint-disable-next-line max-len
            innerHtml += '<li style="border-bottom: 0.5px solid #E6E6E6; padding: 10px 5px; display: flex; align-items: center">';
            // eslint-disable-next-line max-len
            innerHtml += `<div style="background: ${gradient[i]}; width: 7px; height: 7px; margin-right: 5px; line-height: 20px"></div>`;
            if (barChart.data.datasets[i].label) {
              const savingsTooltip = legendText[`${selectedFinanceOption}`].savings === barChart.data.datasets[i].label;
              // eslint-disable-next-line max-len
              innerHtml += `<div style="font-size: 12px; color: #808080; opacity: 0.9;">${barChart.data.datasets[i].label}${savingsTooltip ? ' <sup style="font-size: 5x;">4</sup>' : ''}</div>`;
              // eslint-disable-next-line max-len
              innerHtml += `<div style="margin-left: auto; font-size: 12px; color: #404040; text-align: right;${savingsTooltip ? ' font-weight: bold; color: #6AA442;' : ''}">${formatCurrency(barChart.data.datasets[i].data[datasetIndex], 0)}</div>`;
            }
            innerHtml += '</li>';
          }
          const ulRoot = tooltipEl.querySelector('ul');
          ulRoot.innerHTML = innerHtml;
        }

        // get chart canvas position
        const positionX = chartRef.current.offsetParent.offsetLeft;
        const positionY = chartRef.current.offsetParent.offsetTop;

        // Display, position, and set styles for font
        tooltipEl.style.opacity = 1;
        tooltipEl.style.position = 'absolute';
        tooltipEl.style.left = `${positionX + tooltipModel.caretX - 100}px`;
        tooltipEl.style.top = `${positionY + tooltipModel.caretY + 10}px`;
        tooltipEl.style.fontFamily = tooltipModel._bodyFontFamily;
        tooltipEl.style.fontSize = `${tooltipModel.bodyFontSize}px`;
        tooltipEl.style.fontStyle = tooltipModel._bodyFontStyle;
        tooltipEl.style.padding = `${tooltipModel.yPadding}px ${tooltipModel.xPadding}px`;
        tooltipEl.style.pointerEvents = 'none';
      }
    },
    scales: {
      xAxes: [{
        stacked: true,
        gridLines: {
          drawOnChartArea: false
        },
        ticks: {
          fontSize: tickFontSize
        }
      }],
      yAxes: [{
        stacked: true,
        gridLines: {
          borderDash: [ 1, 3 ],
          drawBorder: true
        },
        ticks
      }]
    },
    responsive: true,
    maintainAspectRatio: false,
    legend: {
      display: false
    }
  };
  if (isPDFProcessing) options.animation = { duration: 0 };

  Chart.pluginService.register({
    afterUpdate(chart) {
      const canvasElm = document.getElementById('total-savings-chart-canvas');
      const updateChartRef = canvasElm ? canvasElm.getContext('2d') : null;
      if (updateChartRef) {
        const yAxis = chart.scales['y-axis-0'];
        for (let i = 0; i < chart.config.data.datasets.length; i++) {
          const dsMeta = chart.getDatasetMeta(i);
          const dataset = chart.config.data.datasets[i];
          for (let j = 0; j < dataset.data.length; j++) {
            if (dataset && dataset.data[j]) {
              const model = dsMeta.data[j]._model;

              const height = Math.round(yAxis.bottom - yAxis.getPixelForValue(dataset.data[j]));
              const start = model.x;
              const chartContext = canvasElm.getContext('2d');

              if (isNaN(start) || isNaN(model.y) || isNaN(height)) {
                // eslint-disable-next-line no-continue
                continue;
              } else {
                const gradient = chartContext.createLinearGradient(start, model.y, start, model.y + height) || null;

                if (gradient) {
                  gradient.addColorStop(0, dataset.backgroundColor[0]);
                  gradient.addColorStop(1, dataset.backgroundColor[1]);
                  model.backgroundColor = gradient;
                }
              }
            }
          }
        }
      }
    }
  });

  const buildChart = () => {
    const canvasElm = document.getElementById('total-savings-chart-canvas');
    const myChartRef = canvasElm ? canvasElm.getContext('2d') : null;

    if (myChartRef) {
      if (typeof barChart !== 'undefined') {
        barChart.destroy();
      }

      const datasets = [ {
        label: legendText[`${selectedFinanceOption}`].electricBill,
        backgroundColor: [ 'rgba(242, 241, 241, 1)', 'rgba(227, 227, 227, 1)' ],
        data: [ data.withoutSolarElectricBill, data.withSolarElectricBill ]
      }, {
        label: legendText[`${selectedFinanceOption}`].solarPayments,
        backgroundColor: [ 'rgba(67, 70, 70, 1)', 'rgba(185, 180, 180, 1)' ],
        data: [ 0, selectedFinanceOption === 'cash' ? data.netCost : data.solarPayments ]
      }, {
        label: legendText[`${selectedFinanceOption}`].savings,
        backgroundColor: [ 'rgba(106, 164, 66, 1)', 'rgba(107, 196, 45, 1)' ],
        data: [ 0, selectedFinanceOption === 'cash' ? data.solarSavingsForCash : data.solarSavings ]
      } ];

      barChart = new Chart(myChartRef, {
        type: 'bar',
        data: {
          labels: [ 'No Solar', 'With SunPower' ],
          datasets
        },
        options
      });

      return barChart;
    }
    return null;
  };

  useEffect(() => {
    buildChart();
  }, []);

  useEffect(() => {
    buildChart();
  }, [selectedInflationRate]);

  return (
    <div className={styles.chartContainer}>
      <canvas
        id="total-savings-chart-canvas"
        ref={chartRef}
      />
    </div>
  );
};

TotalSavingsChart.propTypes = {
  data: totalSavingsChartType,
  selectedInflationRate: PropTypes.string,
  selectedFinanceOption: PropTypes.string,
  isPDFProcessing: PropTypes.bool,
  // eslint-disable-next-line react/forbid-prop-types
  legendText: PropTypes.object
};

export default TotalSavingsChart;
