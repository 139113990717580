import createSelector from '../../utils/createSelector';

export function selectedStorageConfiguration(state) {
  const storageQuote = state.storageQuote;
  const storageConfigurations = state.storageConfigurations;
  const storageConfiguration = storageConfigurations.find((storage) =>
    storage.storageConfigId === storageQuote.storageConfigId) || null;
  return {
    selectedStorageConfiguration: storageConfiguration ||
      { storageConfigId: null, storageName: 'None' }
  };
}

export default createSelector(
  selectedStorageConfiguration
);

