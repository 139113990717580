import PropTypes from 'prop-types';

export const savingsChartLegendType = PropTypes.shape({
  totalSavings: PropTypes.number,
  totalSavingsRounded: PropTypes.number,
  payments: PropTypes.number,
  rebatesAndCredits: PropTypes.number,
  totalAnnualBillSavings: PropTypes.number
});

const savingsChartGraphSeriesType = PropTypes.shape({
  labels: PropTypes.arrayOf(PropTypes.number),
  savings: PropTypes.arrayOf(PropTypes.number),
  costs: PropTypes.arrayOf(PropTypes.number),
  rebatesAndCredits: PropTypes.arrayOf(PropTypes.number),
  annualBillsBefore: PropTypes.arrayOf(PropTypes.number),
  annualBillsAfter: PropTypes.arrayOf(PropTypes.number)
});

const savingsChartVariantType = PropTypes.shape({
  legend: savingsChartLegendType,
  graphSeries: savingsChartGraphSeriesType
});

export const savingsChartType = PropTypes.objectOf(PropTypes.shape({
  cash: savingsChartVariantType,
  lease: savingsChartVariantType,
  loan: savingsChartVariantType
}));
