import { connect } from 'react-redux';
import React from 'react';
import PropTypes from 'prop-types';
import { assignModuleArray, hoverModuleArray } from '../actions';
import { moduleType } from '../types';

import Module from './Module';

function calculateCenter(points) {
  const length = points.length;

  return points.reduce((runningAverage, point) => {
    return { x: runningAverage.x + point.x / length, y: runningAverage.y + point.y / length };
  }, { x: 0, y: 0 });
}

function arrayCenter(modules) {
  const allPoints = modules.reduce((points, module) => [].concat(points, module.slice(0, module.length - 1)));

  return calculateCenter(allPoints);
}

export function ModuleArray({
  modules, onClick, onMouseOver, onMouseOut,
  pairNumber, id, nextArrayNumberToPair, isHovered = false
}) {
  const center = arrayCenter(modules);
  const isSelected = Number.isInteger(pairNumber);

  return (
    <g
      className="module-array"
      onClick={() => onClick(nextArrayNumberToPair, { roofPlaneNumber: id })}
      onMouseOver={onMouseOver}
      onMouseOut={onMouseOut}
    >
      {modules.map((module, index) =>
        <Module
          key={index}
          module={module}
          on={isSelected}
          isHovered={isHovered}
        />
      )}

      <text x={center.x} y={center.y} stroke="#fff" fill="#fff">{pairNumber}</text>
    </g>
  );
}

export default connect(
  (state, ownProps) => {
    const index = state.design.moduleArrays
      .findIndex((moduleArray) => moduleArray.roofPlaneNumber === ownProps.id);
    const pairNumber = index > -1 ? index + 1 : null;

    const nextModuleArray = state.design.moduleArrays
      .find((a) => !Number.isInteger(a.roofPlaneNumber));

    return {
      pairNumber,
      nextArrayNumberToPair: nextModuleArray && nextModuleArray.arrayNumber,
      isHovered: state.hoveredModuleArray === ownProps.id
    };
  },
  (dispatch, ownProps) => {
    return {
      onClick: (...args) => dispatch(assignModuleArray(...args)),
      onMouseOver: () => dispatch(hoverModuleArray(ownProps.id)),
      onMouseOut: () => dispatch(hoverModuleArray())
    };
  }
)(ModuleArray);

ModuleArray.propTypes = {
  id: PropTypes.number.isRequired,
  modules: PropTypes.arrayOf(moduleType).isRequired,
  isHovered: PropTypes.bool,
  pairNumber: PropTypes.number,
  nextArrayNumberToPair: PropTypes.number,
  onClick: PropTypes.func,
  onMouseOver: PropTypes.func,
  onMouseOut: PropTypes.func
};
