import React, { useState } from 'react';
import cx from 'classnames';
import { withRouter } from 'react-router';
import PropTypes from 'prop-types';
import { register } from '../../utils/redux';
import { validationErrorType, modalsType } from '../../types';
import { inValidApConfig } from '../../utils/admin_portal/validationHelper';
import { Modal } from '../Modal';
import constants from '../../utils/admin_portal/constants';

export function Navigation({ updateAdminPortal, resetAdminPortal, loading, apFinConfigs,
  validationErrors, modals, updateApProduct }) {
  const {
    LOAN,
    LEASE,
    PPA,
    CASH
  } = constants;
  const [ showModal, setShowModal ] = useState(false);
  const navigationView = () => {
    return (
      <nav className="navigation">
        <div className="navigation-action-container left">
          <button
            className="btn btn-reset"
            onClick={() => { setShowModal(true); }}
          >
              Reset All
          </button>
        </div>
        <div className="navigation-title">
          EDDIE ADMIN SETTINGS
        </div>
        <div className="navigation-action-container">
          <button className="btn btn-cancel">Cancel</button>
          <button
            className="btn btn-save margin-20"
            disabled={inValidApConfig({ validationErrors })}
            onClick={() => {
              if (inValidApConfig({ validationErrors })) {
                return;
              }
              updateAdminPortal();
            }}
          >
            Save
          </button>
        </div>
      </nav>
    );
  };
  const loadingNavigationView = () => {
    return (
      <nav className="navigation">
        <span className="loading-roof spinner" />
      </nav>
    );
  };

  const resetProductInput = () => {
    apFinConfigs.forEach((apFinConfig) => {
      updateApProduct(apFinConfig.id, 'sunvault', 'commissionPerUnit', '');
    });
  };

  const resetAllModal = () => {
    return (
      <Modal
        modals={modals}
        name="reset-all-modal"
      >
        <div>
          Are you sure you want to reset all settings?
        </div>
        <div className="navigation-action-container">
          <button
            className="btn btn-cancel"
            onClick={() => { setShowModal(false); }}
          >
            Cancel
          </button>
          <button
            className="btn btn-save margin-20"
            onClick={() => {
              resetAdminPortal();
              setShowModal(false);
              resetProductInput();
            }
            }
          >
            Yes
          </button>
        </div>
      </Modal>
    );
  };

  return (
    <div className={cx('navigation-container', { 'is-loading': loading })}>
      {loading ? loadingNavigationView() : navigationView()}
      {showModal ? resetAllModal() : ''}
    </div>
  );
}

Navigation.propTypes = {
  updateAdminPortal: PropTypes.func.isRequired,
  updateApProduct: PropTypes.func.isRequired,
  resetAdminPortal: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  modals: modalsType.isRequired,
  apFinConfigs: PropTypes.objectOf(PropTypes.object),
  validationErrors: validationErrorType
};

export default register(
  [ 'loadingSelector', 'validationErrorsSelector', 'modalsSelector', 'apFinConfigsSelector' ],
  [ 'updateAdminPortal', 'resetAdminPortal', 'toggleModal', 'closeModalAndRevertState', 'updateApProduct' ],
  withRouter(Navigation)
);
