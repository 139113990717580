import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { formatCurrency, formatNumber, getDisplayDigits } from '../utils/formatNumber';

export default function AnimatedValueView(
  {
    id, value, className, currency = false, rounded = false, places
  }) {
  function changeValuesWithAnimation(finalValue: number) {
    const object = document.getElementById(id);

    if (object) {
      const currentValue = object.innerText;
      animateValue(object, currentValue, finalValue, 1500);
    }
  }

  changeValuesWithAnimation(value);

  const classes = cx(
    className
  );
  const digits = getDisplayDigits(currency, rounded, places);

  const formatValue = (value) => {
    return currency ? formatCurrency(value, digits) : formatNumber(value, digits);
  };

  function animateValue(obj: { innerHTML: any }, start: any, end: number, duration: number) {
    let startTimestamp: number | null = null;
    if (currency) {
      start = parseInt(start.substring(1).replace(/,/g, ''), 10);
    }
    const step = (timestamp: number) => {
      if (!startTimestamp) startTimestamp = timestamp;
      const progress = Math.min((timestamp - startTimestamp) / duration, 1);
      obj.innerHTML = formatValue(Math.floor(progress * (end - start) + start));
      if (progress < 800) {
        window.requestAnimationFrame(step);
      }
    };
    window.requestAnimationFrame(step);
  }

  return (
    <div id={id} className={classes}>{ formatValue(value) }</div>
  );
}

AnimatedValueView.propTypes = {
  value: PropTypes.number.isRequired,
  className: PropTypes.string,
  currency: PropTypes.bool,
  rounded: PropTypes.bool,
  id: PropTypes.string.isRequired,
  places: PropTypes.number
};
