import { get } from 'lodash';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { CASH } from '../../../utils/EddieCalculations/helpers/financeTypes';
import constants from '../../../utils/admin_portal/constants';
import { register } from '../../../utils/redux';
import { InputFieldWithUnit } from '../shared/InputFieldWithUnit';
import { RangeInputFieldWithUnit } from '../shared/RangeInputFieldWithUnit';

export function CommonInputFieldsView({ financeType, showKwhField, apSalesforce,
  apFinConfig, updateApPricing, updateApFinConfig, updateValidationErrors, newPricingDetails }) {
  let pricePerWattDefault;
  let pricePerKwhDefault;
  let dealerFeePerWattDefault;
  const { ERROR_MESSAGE } = constants;
  const [ isFODisabled, setIsFODisabled ] = useState(false);
  const showDealerFeePerWatt = get(apSalesforce, 'specialProgram', [])
      .findIndex((program) => program.toLowerCase() === 'dealer fee per watt pricing') !== -1;

  if (financeType && apFinConfig) {
    pricePerWattDefault = apFinConfig ?
      apFinConfig.pricingDefaultsAttributes.find((pricing) => pricing.pricingType === 'price_per_watt')
      : {};
    pricePerKwhDefault = apFinConfig ?
      apFinConfig.pricingDefaultsAttributes.find((pricing) => pricing.pricingType === 'price_per_kwh')
      : {};
    dealerFeePerWattDefault = apFinConfig ?
      apFinConfig.pricingDefaultsAttributes.find((pricing) => pricing.pricingType === 'dealer_fee_per_watt')
      : {};
  }

  const minMaxFail = (val, mini, maxi, inputField = '') => {
    const min = parseFloat(mini);
    const max = parseFloat(maxi);
    const value = parseFloat(val);
    let bool = false;
    if (min > max) { bool = true; }
    if ((min && min < 1.5) && (inputField !== 'dealer_min')) { bool = true; }
    if ((min < 0.1) && (inputField === 'dealer_min')) { bool = true; }
    if (value) {
      if (min && value < min) { bool = true; }
      if (max && value > max) { bool = true; }
    }
    return bool;
  };

  const atLeastTwo = (a, b, c) => {
    return a && (b || c) || (b && c);
  };

  let pricePerWattValue = get(pricePerWattDefault, 'price', '');
  let pricePerKwattValue = get(pricePerKwhDefault, 'price', '');
  let pricePerWattMin = get(pricePerWattDefault, 'min', '');
  let pricePerWattMax = get(pricePerWattDefault, 'max', '');
  let dealerPerWattValue = get(dealerFeePerWattDefault, 'price', '');
  let dealerPerWattMin = get(dealerFeePerWattDefault, 'min', '');
  let dealerPerWattMax = get(dealerFeePerWattDefault, 'max', '');

  let hasBothDefaultSet = !!atLeastTwo(pricePerWattValue, dealerPerWattValue, pricePerKwattValue);
  let isPpwMinInvalid = Boolean(pricePerWattValue && pricePerWattValue < 1.5);
  let isPpkwMinInvalid = Boolean(pricePerKwattValue && pricePerKwattValue < 0.1);
  let isDealerMinInvalid = Boolean(dealerPerWattValue && dealerPerWattValue < 0.1);
  let priceMinMaxInvalid = minMaxFail(pricePerWattValue, pricePerWattMin, pricePerWattMax);
  let dealerMinMaxInvalid = minMaxFail(dealerPerWattValue, dealerPerWattMin, dealerPerWattMax, 'dealer_min');

  const toggleSaveButton = () => {
    updateValidationErrors(financeType, 'pricing',
      (isPpwMinInvalid || hasBothDefaultSet || isDealerMinInvalid ||
      isPpkwMinInvalid || priceMinMaxInvalid || dealerMinMaxInvalid));
  };
  toggleSaveButton();

  const errorMessage = () => {
    if (hasBothDefaultSet) {
      return ERROR_MESSAGE.DEFAULT_PRICES_ERROR_MESSSAGE;
    } else if (isPpwMinInvalid) {
      return ERROR_MESSAGE.MINIMUM_DEFAULT_VALUE_ERROR;
    } else if (isDealerMinInvalid || isPpkwMinInvalid) {
      return ERROR_MESSAGE.MINIMUM_DEALER_VALUE_ERROR;
    }
    return null;
  };

  const validateDefaultPrice = (value, key) => {
    switch (key) {

      case 'default_watt_value':
        pricePerWattValue = parseFloat(value);
        isPpwMinInvalid = pricePerWattValue < 1.5;
        hasBothDefaultSet = Boolean(atLeastTwo(pricePerWattValue, dealerPerWattValue, pricePerKwattValue));
        priceMinMaxInvalid = minMaxFail(pricePerWattValue, pricePerWattMin, pricePerWattMax);
        break;

      case 'dealer_fee_per_watt':
        dealerPerWattValue = parseFloat(value);
        isDealerMinInvalid = Boolean(dealerPerWattValue < 0.1);
        hasBothDefaultSet = Boolean(atLeastTwo(pricePerWattValue, dealerPerWattValue, pricePerKwattValue));
        break;

      case 'default_kwatt_value':
        pricePerKwattValue = parseFloat(value);
        isPpkwMinInvalid = pricePerKwattValue < 0.1;
        hasBothDefaultSet = Boolean(atLeastTwo(pricePerWattValue, dealerPerWattValue, pricePerKwattValue));
        break;

      case 'price_min_value':
        pricePerWattMin = parseFloat(value);
        priceMinMaxInvalid = minMaxFail(pricePerWattValue, pricePerWattMin, pricePerWattMax);
        break;

      case 'price_max_value':
        pricePerWattMax = parseFloat(value);
        priceMinMaxInvalid = minMaxFail(pricePerWattValue, pricePerWattMin, pricePerWattMax);
        break;

      case 'dealer_min_value':
        dealerPerWattMin = parseFloat(value);
        dealerMinMaxInvalid = minMaxFail(dealerPerWattValue, dealerPerWattMin, dealerPerWattMax, 'dealer_min');
        break;

      case 'dealer_max_value':
        dealerPerWattMax = parseFloat(value);
        dealerMinMaxInvalid = minMaxFail(dealerPerWattValue, dealerPerWattMin, dealerPerWattMax, 'dealer_min');
        break;

      default:
        break;
    }
    toggleSaveButton();
  };

  const renderDealerCommission = () => {
    return (
      (financeType !== CASH) &&
      <React.Fragment>
        <InputFieldWithUnit
          label={'SET DEFAULT DEALER COMMISSION PER WATT:'}
          unit={'PER WATT'}
          placeHolder={'0.00'}
          symbol={'$'}
          onChange={(value) => {
            updateApPricing(apFinConfig.id, 'dealer_fee_per_watt', 'price', value);
            validateDefaultPrice(value, 'dealer_fee_per_watt');
          }}
          errorMessageLabel={(dealerPerWattValue && hasBothDefaultSet) || isDealerMinInvalid ? errorMessage() : ''}
          value={dealerPerWattValue}
          disabled={!get(apFinConfig, 'show')}
          key={`default_dealer_commission_value-${financeType}`}
        />
        <RangeInputFieldWithUnit
          label={'SET COMMISSION RANGE FOR SALES REPS:'}
          minUnit={'MIN'}
          minPlaceHolder={'0'}
          maxUnit={'MAX'}
          maxPlaceHolder={'0'}
          minValueIndicatorLabel={ERROR_MESSAGE.MINIMUM_DEALER_VALUE_ERROR}
          maxValueIndicatorLabel={'Must be greater than Min value'}
          symbol={'$'}
          onMinChange={(value) => {
            updateApPricing(apFinConfig.id, 'dealer_fee_per_watt', 'min', value);
            validateDefaultPrice(value, 'dealer_min_value');
          }}
          onMaxChange={(value) => {
            updateApPricing(apFinConfig.id, 'dealer_fee_per_watt', 'max', value);
            validateDefaultPrice(value, 'dealer_max_value');
          }}
          minValue={dealerPerWattMin}
          maxValue={dealerPerWattMax}
          key={`dealer_fee_per_watt-range-${financeType}`}
          minValueValidator={0.1}
          updateValidationErrors={updateValidationErrors}
          financeType={financeType}
        />
      </React.Fragment>
    );
  };

  const renderPricePerWatt = () => {
    return (
      <React.Fragment>
        <InputFieldWithUnit
          label={'SET DEFAULT PRICE PER WATT:'}
          unit={'PER WATT'}
          placeHolder={'0.00'}
          symbol={'$'}
          onChange={(value) => {
            updateApPricing(apFinConfig.id, 'price_per_watt', 'price', value);
            validateDefaultPrice(value, 'default_watt_value');
          }}
          errorMessageLabel={isPpwMinInvalid || (pricePerWattValue && hasBothDefaultSet) ? errorMessage() : ''}
          value={pricePerWattValue}
          disabled={!get(apFinConfig, 'show')}
          key={`default-price-per-watt-${financeType}`}
        />
        <RangeInputFieldWithUnit
          label={'SET PRICE PER WATT RANGE FOR SALES REPS:'}
          minUnit={'MIN'}
          minPlaceHolder={'0'}
          maxUnit={'MAX'}
          maxPlaceHolder={'0'}
          minValueIndicatorLabel={ERROR_MESSAGE.MINIMUM_DEFAULT_VALUE_ERROR}
          maxValueIndicatorLabel={'Must be greater than Min value'}
          symbol={'$'}
          onMinChange={(value) => {
            updateApPricing(apFinConfig.id, 'price_per_watt', 'min', value);
            validateDefaultPrice(value, 'price_min_value');
          }}
          onMaxChange={(value) => {
            updateApPricing(apFinConfig.id, 'price_per_watt', 'max', value);
            validateDefaultPrice(value, 'price_max_value');
          }}
          minValue={pricePerWattMin}
          maxValue={pricePerWattMax}
          minDisabled={!get(apFinConfig, 'show')}
          maxDisabled={!get(apFinConfig, 'show')}
          key={`price-per-watt-range-${financeType}`}
          minValueValidator={1.5}
          updateValidationErrors={updateValidationErrors}
          financeType={financeType}
        />
      </React.Fragment>
    );
  };

  return (
    <div className="admin-section-data-container border-bottom">
      <div className="admin-section-data-heading-container flex-start">
        <div className="toggle-btn-with-label-container">
          <div className="default-toggle-label">{financeType} OPTION</div>
          <div className="toggle-btn-container">
            <label className="toggle-btn">
              <input
                type="checkbox"
                checked={get(apFinConfig, 'show')}
                onChange={(e) => {
                  if (!e.target.checked) {
                    setIsFODisabled(true);
                    updateApFinConfig(apFinConfig.id, 'default', e.target.checked);
                  }
                  updateApFinConfig(apFinConfig.id, 'show', e.target.checked);
                }}
              />
              <span className="slider round" />
            </label>
            <label className="toggle-btn-status-label">{get(apFinConfig, 'show') ? 'ON' : 'OFF'}</label>
          </div>
        </div>
        <div className="toggle-btn-with-label-container">
          <div className="default-toggle-label">SET AS DEFAULT</div>
          <div className="toggle-btn-container">
            <label className="toggle-btn">
              <input
                type="checkbox"
                checked={get(apFinConfig, 'default')}
                onChange={(e) => updateApFinConfig(apFinConfig.id, 'default', e.target.checked)}
                disabled={!get(apFinConfig, 'show')}
              />
              <span className="slider round" />
            </label>
            <label className="toggle-btn-status-label">{get(apFinConfig, 'default') ? 'ON' : 'OFF'}</label>
          </div>
        </div>
      </div>
      {!newPricingDetails && (
        <div className="admin-finance-details-container width-80">
          <div className="admin-finance-input-container">
            {renderPricePerWatt()}
            {
              showKwhField && (
                <React.Fragment>
                  <InputFieldWithUnit
                    label={'SET DEFAULT PRICE PER KWH:'}
                    unit={'PER KWH'}
                    placeHolder={'0.00'}
                    symbol={'$'}
                    onChange={(value) => {
                      updateApPricing(apFinConfig.id, 'price_per_kwh', 'price', value);
                      validateDefaultPrice(value, 'default_kwatt_value');
                    }}
                    errorMessageLabel={
                      isPpkwMinInvalid || pricePerKwattValue && hasBothDefaultSet ? errorMessage() : ''
                    }
                    value={pricePerKwattValue}
                    disabled={!get(apFinConfig, 'show')}
                    key={`default-price-per-kwh-${financeType}`}
                  />
                  <div className="input-field-container-with-label" />
                </React.Fragment>
              )
            }
            {showDealerFeePerWatt && renderDealerCommission()}
          </div>
        </div>
      )}
    </div>
  );
}

CommonInputFieldsView.propTypes = {
  apSalesforce: PropTypes.arrayOf(PropTypes.object),
  financeType: PropTypes.string,
  showKwhField: PropTypes.bool.isRequired,
  updateApPricing: PropTypes.func.isRequired,
  apFinConfig: PropTypes.objectOf(PropTypes.object),
  updateApFinConfig: PropTypes.func.isRequired,
  newPricingDetails: PropTypes.bool,
  updateValidationErrors: PropTypes.func
};

export default register(
  [],
  [],
  CommonInputFieldsView
);
