import React, { useState, useEffect } from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import { register } from '../../../utils/redux';
import { validateInput } from '../../../utils/admin_portal/validationHelper';

export function RangeInputFieldWithUnit({
  label, minPlaceHolder, minValue, minUnit,
  maxPlaceHolder, maxValue, maxUnit, symbol,
  onMinChange, onMaxChange, minValueIndicatorLabel,
  maxValueIndicatorLabel, minDisabled, maxDisabled, key,
  minValueValidator, updateValidationErrors, financeType
}) {
  const [ inputMin, setInputMin ] = useState(minValue);
  const [ inputMax, setInputMax ] = useState(maxValue);
  const [ isMinInvalid, setIsMinInvalid ] = useState(minValueValidator ?
    parseFloat(inputMin) < minValueValidator : false);
  const [ isMaxInvalid, setIsMaxInvalid ] = useState(parseFloat(inputMax) <= parseFloat(inputMin));

  useEffect(() => {
    updateValidationErrors(financeType, 'pricing', Boolean(isMaxInvalid || isMinInvalid));
  }, [ isMinInvalid, isMaxInvalid ]);

  useEffect(() => {
    setInputMin(minValue);
  }, [minValue]);

  useEffect(() => {
    setInputMax(maxValue);
  }, [maxValue]);

  const handleMinOnChange = (value) => {
    const newValue = validateInput(value);
    setInputMin(newValue);
    onMinChange(newValue);
  };

  const handleMaxOnChange = (value) => {
    const newValue = validateInput(value);
    setInputMax(newValue);
    onMaxChange(newValue);
  };

  const validateMaxValue = () => {
    setIsMaxInvalid(parseFloat(inputMax) <= parseFloat(inputMin));
  };

  const validateMinValue = () => {
    setIsMinInvalid(parseFloat(inputMin) < minValueValidator);
  };

  return (
    <div
      key={key}
      className="input-field-container-with-label"
    >
      <label className="label">{label}</label>
      <div className="range-input-container">
        <div>
          <div className={cx('input-field-container', { disabled: minDisabled }, { 'error-input': isMinInvalid })}>
            {
            symbol &&
            <label className="input-symbol-field">{symbol}</label>
          }
            <input
              type="text"
              placeholder={minPlaceHolder}
              className="input-field"
              value={inputMin}
              onChange={(e) => handleMinOnChange(e.target.value)}
              disabled={minDisabled}
              onBlur={() => validateMinValue()}
            />
            {
            minUnit &&
            <label className="input-unit-field">{minUnit}</label>
          }
          </div>
          <span className="indicator-label error">
            { isMinInvalid ? minValueIndicatorLabel : null}
          </span>
        </div>
        <div className="separator">-</div>
        <div>
          <div className={cx('input-field-container', { disabled: maxDisabled }, { 'error-input': isMaxInvalid })}>
            {
            symbol &&
            <label className="input-symbol-field">{symbol}</label>
          }
            <input
              type="text"
              placeholder={maxPlaceHolder}
              className="input-field"
              value={inputMax}
              onChange={(e) => handleMaxOnChange(e.target.value)}
              disabled={maxDisabled}
              onBlur={() => validateMaxValue()}
            />
            {
            maxUnit &&
            <label className="input-unit-field">{maxUnit}</label>
          }
          </div>
          <span className="indicator-label error">
            { isMaxInvalid ? maxValueIndicatorLabel : null }
          </span>
        </div>
      </div>
    </div>
  );
}

RangeInputFieldWithUnit.propTypes = {
  label: PropTypes.string.isRequired,
  minPlaceHolder: PropTypes.string,
  minValue: PropTypes.string,
  minUnit: PropTypes.string,
  maxPlaceHolder: PropTypes.string,
  maxValue: PropTypes.string,
  maxUnit: PropTypes.string,
  onMinChange: PropTypes.func,
  onMaxChange: PropTypes.func,
  minValueIndicatorLabel: PropTypes.string,
  maxValueIndicatorLabel: PropTypes.string,
  symbol: PropTypes.string,
  minDisabled: PropTypes.bool,
  maxDisabled: PropTypes.bool,
  key: PropTypes.string,
  minValueValidator: PropTypes.number,
  updateValidationErrors: PropTypes.func,
  financeType: PropTypes.string
};

export default register(
  [],
  [],
  RangeInputFieldWithUnit
);
