import React from 'react';
import PropTypes from 'prop-types';
import { gaTrackEvent } from '../../utils/googleAnalytics';

export default function EscalatorToggle({
  rates,
  hasEscalators,
  inflationRate,
  onChangeRate
}) {
  return hasEscalators ? (
    <div className="savings-escalator">
      <div className="title">
          Estimates based on your utility bill rate growing
        </div>
      <div className="input-group">
        <select
          value={inflationRate}
          onChange={(env) => {
            onChangeRate(env);
            gaTrackEvent('quotePage:savingsTab:EstimatesBasedOnUtilityRateGrowing');
          }}
        >
          {rates.map((item) =>
            <option value={item} key={`rate_${item}`}>{item}% annually</option>
          )}
        </select>
      </div>
    </div>
  ) : (
    <div />
  );
}
EscalatorToggle.propTypes = {
  rates: PropTypes.arrayOf(PropTypes.string).isRequired,
  hasEscalators: PropTypes.bool.isRequired,
  inflationRate: PropTypes.string.isRequired,
  onChangeRate: PropTypes.func.isRequired
};
