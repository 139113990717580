import React from 'react';
import PropTypes from 'prop-types';
import { register } from '../../utils/redux';
import { notificationsType } from '../../types';
import LightBulbIcon from '../../../images/icon-bulb.svg';
import LightBulbActiveIcon from '../../../images/icon-bulb-highlighted.svg';

export function OptimizationLightBulb({
  notifications,
  toggleNotificationModal
}) {
  const { active } = notifications;
  const circleCssClass = active === true ? 'c100 active' : 'c100';

  return (
    <div>
      <div className={circleCssClass}>
        {active === true ?
          <span className="lightbulb-active" onClick={toggleNotificationModal}>
            <LightBulbActiveIcon className="bulb-icon" />
          </span> :
          <span><LightBulbIcon className="bulb-icon" /></span>
        }
        <div className="slice">
          <div className="bar" />
          <div className="fill" />
        </div>
      </div>
    </div>
  );
}

OptimizationLightBulb.propTypes = {
  notifications: notificationsType.isRequired,
  toggleNotificationModal: PropTypes.func.isRequired
};

export default register(
  ['notificationsSelector'],
  ['toggleNotificationModal'],
  OptimizationLightBulb
);
