import { findSelectedStates } from '../../utils/admin_portal/updateHelpers';

export default (state) => {
  const {
    apFinConfigs,
    selectedFinanceOption,
    apDownPayments
  } = state;
  let apAvailableDownPayments = [];

  if (selectedFinanceOption && apFinConfigs && apDownPayments) {
    const selectedStates = findSelectedStates(apFinConfigs, selectedFinanceOption);

    if (selectedStates.length === 1) {
      const selectedState = selectedStates[0];
      apAvailableDownPayments = apDownPayments[selectedState] || [];
    }
  }
  return {
    apAvailableDownPayments
  };
};
