import React, { useEffect, useState } from 'react';
import PropTypes, { object } from 'prop-types';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import get from 'lodash/get';
import { register } from '../../utils/redux';
import {
  accountType,
  businessFunctionsType,
  inverterType,
  newHomesDetailsType,
  productConfigurationType,
  pvModuleForManualModeType,
  quoteType,
  selectedEvChargerConfigurationType,
  selectedStorageType,
  selectedStorageWithExpansionPackType,
  settingsType,
  designType
} from '../../types';
import ModuleListDropdown from './ModuleListDropdown';
import MonitoringConfigurationsDropdown from '../MonitoringConfigurationsDropdown';
import StorageListDropdown from '../StorageListDropdown';
import StorageListForMultiInvertersDropdown
  from '../manual_mode_design/expansion_pack/StorageListForMultiInvertersDropdown';
import StorageQuantityInputForMultiInverters
  from '../manual_mode_design/expansion_pack/StorageQuantityInputForMultiInverters';
import ExpansionPackQuantityInput from './expansion_pack/ExpansionPackQuantityInput';
import StorageQuantityInput from '../StorageQuantityInput';
import { addEnhancedPerformanceWarrantyCost } from '../../utils/EddieCalculations/finance';
import { INVERTER_QUANTITY_STORAGE_BACKUP_MAPPINGS } from '../../utils/EddieCalculations/design';
import { guardAccessoriesEnabled, storageExpansionPackEnabled } from '../../utils/backendFeatureFlags';
import {
  getAvailableStorageExpansionPacks,
  getAvailableStorageQuantities
} from '../../utils/storageWithMultiInverter';
import { showStorageWithExpPack } from '../../utils/StorageWithMultiInverters/helpers';
import { accessoriesToShow, updateStorageCommission } from '../../utils/admin_portal/quoteHelpers';
import constants from '../../utils/EddieCalculations/constants';
import EvChargerListDropDown from './ev_charger/EvChargerListDropDown';
import EvChargerQuantityDropDown from './ev_charger/EvChargerQuantityDropDown';
import EvOutletListDropDown from './ev_charger/EvOutletListDropDown';
import EvOutletQuantityDropDown from './ev_charger/EvOutletQuantityDropDown';
import { showEvCharger } from '../../utils/EvChargers/evChargers';
import { isSunPowerModule } from '../../utils/selectedModuleValidationUtility';
import { isInvisiMountType } from '../../utils/EddieCalculations/helpers/monitoringConfiguration';
import { filterPvModulesByAdminSettings } from '../../utils/pvModuleFiltersAndTags';
import { minimumSystemSizeForStorage } from '../../utils/statesFeatureFlag';

const {
  CRITTER_GUARD,
  SKIRT_GUARD,
  EV_CHARGER,
  EV_OUTLET
} = constants;
export function SystemInformation({ account, pvModules, productConfiguration,
  quote, savePvModuleForManualMode, updateQuote, selectedStorage,
  settings, updateSettings, saveSettings, businessFunctions, newHomesDetails, partnerIsNewHomes,
  isPartnerEnabledForAnyStorage, selectedStorageWithExpansionPack, selectedEvChargerConfiguration,
  updateEvChargerConfig, design }) {
  const accessories = accessoriesToShow(quote.adminFinConfigs, settings);
  const shadingMeasurementDateTooltip = 'The date serves as confirmation of shading analysis ' +
    'and is required to generate a quote.';
  let rackings = productConfiguration.rackings;
  const [ selectedStorageName, setSelectedStorageName ] = useState(selectedStorageWithExpansionPack.storageName);
  const [ selectedStorageQuantity, setSelectedStorageQuantity ] = useState(quote.storageQuantity);
  const [ showStorageBySystemSize, setShowStorageBySystemSize ] = useState(true);

  const filteredPvModules = filterPvModulesByAdminSettings(pvModules, settings, quote, account);
  let selectedPvModule = filteredPvModules.find((pvModule) => pvModule.itemId === quote.pvModuleItemId);
  if (!selectedPvModule) {
    const data = {};
    selectedPvModule = filteredPvModules[0];
    data.pvModuleItemId = filteredPvModules[0].itemId;
    updateQuote(quote.sfid, data);
  }
  const channel = get(account, 'partner.channel');

  const onChangeModule = (itemId) => {
    selectedPvModule = filteredPvModules.find((pvModule) => pvModule.itemId === itemId);
    let storage;
    const evChargerConfigs = [];
    if (showStorageWithExpPack(storageExpansionPackEnabled(), quote)) {
      storage = selectedPvModule.storageConfigurations.find((storage) =>
        storage.storageConfigId === selectedStorageWithExpansionPack.storageConfigId);
    } else {
      storage = selectedPvModule.storageUnits.find((storage) => storage.id === selectedStorage.id);
    }
    const storageConfigId = storage ? quote.storageConfigId : 'null';
    const storageQuantity = storage ? quote.storageQuantity : 0;
    const storageExpansionPackQuantity = storage ? quote.storageExpansionPackQuantity : null;
    const storageLaborCost = storage ? quote.storageLaborCost : null;

    if (selectedEvChargerConfiguration.evChargerId || selectedEvChargerConfiguration.evOutletId) {
      const evCharger = selectedPvModule.evmChargerConfigurations.find((config) =>
        config.configId === selectedEvChargerConfiguration.evChargerConfigId);
      const evOutlet = selectedPvModule.evmChargerConfigurations.find((config) =>
        config.configId === selectedEvChargerConfiguration.evOutletConfigId);
      if (!evCharger && selectedEvChargerConfiguration.evChargerId) {
        evChargerConfigs.push({ id: selectedEvChargerConfiguration.evChargerId, _destroy: true });
      }
      if (!evOutlet && selectedEvChargerConfiguration.evOutletId) {
        evChargerConfigs.push({ id: selectedEvChargerConfiguration.evOutletId, _destroy: true });
      }
    }
    savePvModuleForManualMode(quote, itemId, storageConfigId, storageQuantity,
      storageExpansionPackQuantity, storageLaborCost, evChargerConfigs);

    if (!isSunPowerModule(selectedPvModule)) {
      const data = {};
      if (quote.isSkirtGuard) {
        data.isSkirtGuard = false;
      }
      if (Object.keys(data).length !== 0) {
        updateQuote(quote.sfid, data);
      }
    }
    if (storageConfigId === 'null') {
      setSelectedStorageName('None');
    }
    setSelectedStorageQuantity(storageQuantity);
    if (settings.loan.enhancedPerformanceWarranty) {
      const watts = parseInt(selectedPvModule.item.summaryDescription.replace(/\D/g, ''), 10);
      if (!addEnhancedPerformanceWarrantyCost(watts)) {
        updateSettings('loan', 'enhancedPerformanceWarranty', false);
        const loan = { enhancedPerformanceWarranty: false };
        saveSettings(quote, { ...settings, loan });
      }
    }
  };

  if (!isSunPowerModule(selectedPvModule) && channel && !channel.toLowerCase().includes('indirect')) {
    rackings = productConfiguration.rackings.map((r) => r)
      .filter((r) => isInvisiMountType(r.type));
  }

  const onChangeRacking = (value) => {
    updateQuote(quote.sfid, { rackingType: value }, false);
  };

  useEffect(() => {
    if (quote.locked) return;
    if (productConfiguration.rackings.length === 1 &&
      productConfiguration.rackings[0].id !== quote.rackingType) {
      onChangeRacking(productConfiguration.rackings[0].id);
    } else if (productConfiguration.rackings.length > 1) {
      const isSelectedRackingAvailable = productConfiguration.rackings.some((configuration) => {
        return configuration.id === quote.rackingType;
      });
      if (!isSelectedRackingAvailable) {
        onChangeRacking(productConfiguration.rackings[0].id);
      }
    }
  }, [productConfiguration]);

  const selectedPaymentOptions = [ 'cash', 'lease', 'loan', 'spwrCash', 'ppa' ];
  const [ quoteVariantIsCloned, setQuoteVariantIsCloned ] = useState(quote.cloneVariant);

  useEffect(() => {
    if (!quoteVariantIsCloned) {
      if (quote.locked === false) {
        const storageConfigs = updateStorageCommission(quote, settings);
        if (Object.keys(storageConfigs).length > 0) {
          Object.keys(storageConfigs).forEach((finType) => {
            if (storageConfigs[finType].sell === true) {
              updateSettings(finType, 'storageDealerFee', storageConfigs[finType].commissionPerUnit);
              updateSettings(finType, 'storagePriceSelection', 'storageDealerFee');
            } else {
              updateSettings(finType, 'storageDealerFee', 0);
              updateSettings(finType, 'storagePriceSelection', 'storagePrice');
            }
          });
        } else {
          selectedPaymentOptions.forEach((finType) => {
            updateSettings(finType, 'storageDealerFee', 0);
            updateSettings(finType, 'storagePriceSelection', 'storagePrice');
          });
        }
      }
    } else {
      setQuoteVariantIsCloned(false);
    }
  }, [quote.storageConfigId]);

  useEffect(() => {
    if (!partnerIsNewHomes) {
      const systemSizeCheck = minimumSystemSizeForStorage(quote, design, settings);
      setShowStorageBySystemSize(!!systemSizeCheck);
      if (!systemSizeCheck && quote.storageConfigId) {
        setSelectedStorageQuantity(null);
        setSelectedStorageName('None');
        updateQuote(quote.sfid,
          { storageConfigId: null,
            storageQuantity: null,
            storageExpansionPackQuantity: null,
            storageLaborCost: null,
            batterySize: null,
            batteryManufacturer: null,
            backupCapable: null,
            batteryWarranty: null
          },
          true);
      }
    }
  }, [ design.systemSize, settings ]);

  const onChangeShadingMeasurementDate = (momentDate) => {
    const dateString = momentDate ? momentDate.format('YYYY-MM-DD') : null;
    updateQuote(quote.sfid, { shadingMeasurementDate: dateString });
  };

  const onChangeCritterData = (value) => {
    updateQuote(quote.sfid, { isCritterGuard: value });
  };

  const onChangeSkirtData = (value) => {
    updateQuote(quote.sfid, { isSkirtGuard: value });
  };

  const modulesData = (modules) => {
    return modules.map((module) => {
      return {
        key: module.itemId,
        value: module.displayName,
        tags: module.financialOfferings,
        series: module.series
      };
    });
  };

  const shadingMeasurementDate = quote.shadingMeasurementDate ?
    moment(quote.shadingMeasurementDate, 'YYYY-MM-DD') :
    null;

  const storageEnabled = () => {
    return partnerIsNewHomes ?
      get(newHomesDetails, 'storageAllowed') && showStorageBySystemSize :
      businessFunctions && businessFunctions.includes('Storage Enabled') &&
      showStorageBySystemSize && isPartnerEnabledForAnyStorage;
  };

  const equinoxStorageSelected = () => {
    return selectedStorageName && selectedStorageName.toLowerCase().includes('sunvault');
  };

  const onMonitoringSelect = (optionId) => {
    updateQuote(quote.sfid, { monitoringConfigurationId: optionId }, true);
  };

  const storageQuantity = (optionId) => optionId ? 1 : 0;
  const onStorageSelect = (optionId) => {
    updateQuote(quote.sfid,
      { storageConfigId: optionId, storageQuantity: storageQuantity(optionId) },
      true,
      false,
      true);
  };

  const onExpansionSelect = (optionId, storageName, storageQuantity) => {
    const configuration = selectedPvModule.storageConfigurations.filter((configuration) =>
      (configuration.storageName === storageName) &&
      (configuration.storageQuantity === storageQuantity)
      && (configuration.expansionPacks === optionId))[0];
    if (configuration) {
      updateQuote(quote.sfid,
        { storageConfigId: configuration.storageConfigId,
          storageQuantity: configuration.storageQuantity,
          storageExpansionPackQuantity: configuration.expansionPacks,
          storageLaborCost: configuration.laborCost,
          batterySize: configuration.batterySize,
          batteryManufacturer: configuration.batteryManufacturer,
          backupCapable: configuration.backupCapable,
          batteryWarranty: configuration.batteryWarranty
        }, true, false, true);
    } else {
      updateQuote(quote.sfid,
        { storageConfigId: null,
          storageQuantity: null,
          storageExpansionPackQuantity: null,
          storageLaborCost: null,
          batterySize: null,
          batteryManufacturer: null,
          backupCapable: null,
          batteryWarranty: null
        },
        true);
    }
  };

  const onStorageWithExpansionSelect = (storageName) => {
    setSelectedStorageName(storageName);
    if (!storageName) {
      setSelectedStorageQuantity(null);
      onExpansionSelect(null, null, null);
    } else {
      const availableQuantities = getAvailableStorageQuantities(selectedPvModule, storageName, quote, settings);
      setSelectedStorageQuantity(availableQuantities[0]);
      const availableExpansionPacks = getAvailableStorageExpansionPacks(selectedPvModule, storageName,
        availableQuantities[0], quote, settings);
      onExpansionSelect(availableExpansionPacks[0], storageName, availableQuantities[0] || null);
    }
  };

  const onStorageQuantityWithExpansionSelect = (quantity) => {
    setSelectedStorageQuantity(quantity);
    const availableExpansionPacks = getAvailableStorageExpansionPacks(selectedPvModule, selectedStorageName,
      quantity, settings);
    onExpansionSelect(availableExpansionPacks[0], selectedStorageName, quantity);
  };

  const onStorageQuantitySelect = (qty) => {
    const payload = {
      storageQuantity: qty
    };
    if (equinoxStorageSelected()) {
      payload.storageBackup = INVERTER_QUANTITY_STORAGE_BACKUP_MAPPINGS[1];
    }

    updateQuote(quote.sfid, payload, true, false, true);
  };

  const updateEvChargerConfigs = (params) => {
    updateEvChargerConfig(quote, params);
  };

  const onEvChargerSelect = (id, configId, configName, quantity = null) => {
    const params = [{ id, configId, configName, product_name: EV_CHARGER, quantity }];
    if (id && configId === null) {
      /* eslint no-underscore-dangle: 0 */
      params[0]._destroy = true;
      updateEvChargerConfigs(params);
    } else if (configId) {
      if (quantity === 2 && selectedEvChargerConfiguration.evOutletId) {
        params.push({ id: selectedEvChargerConfiguration.evOutletId, _destroy: true });
      }
      updateEvChargerConfigs(params);
    }
  };

  const onEvOutletSelect = (id, configId, configName, quantity = null) => {
    const params = [{ id, configId, configName, product_name: EV_OUTLET, quantity }];
    if (id && configId === null) {
      /* eslint no-underscore-dangle: 0 */
      params[0]._destroy = true;
      updateEvChargerConfigs(params);
    } else if (configId) {
      updateEvChargerConfigs(params);
    }
  };

  const onInverterQuantitySelect = (qty) => {
    updateQuote(quote.sfid, { storageBackup: INVERTER_QUANTITY_STORAGE_BACKUP_MAPPINGS[qty] });
  };

  const renderStorage = () => {
    if (!storageEnabled()) return null;

    if (showStorageWithExpPack(storageExpansionPackEnabled(), quote) && quote) {
      return (
        <div className="input-group" id="system-storage">
          <label>Storage</label>
          <StorageListForMultiInvertersDropdown
            onSelect={onStorageWithExpansionSelect}
            selectedStorageName={selectedStorageName}
          />
        </div>
      );
    }
    return (
      <div className="input-group" id="system-storage">
        <label>Storage</label>
        <StorageListDropdown onSelect={onStorageSelect} />
        <StorageQuantityInput onSelect={onStorageQuantitySelect} />
      </div>
    );
  };

  const renderInverterOrExpansionPack = () => {
    if (!storageEnabled()) return null;
    if (showStorageWithExpPack(storageExpansionPackEnabled(), quote)) {
      return (
        <div className="input-group" id="expansion-pack">
          <label>Storage Qty</label>
          <StorageQuantityInputForMultiInverters
            onSelect={onStorageQuantityWithExpansionSelect}
            selectedStorageName={selectedStorageName}
            selectedStorageQuantity={selectedStorageQuantity}
          />
          {equinoxStorageSelected() && (<label>Expansion Pack</label>)}
          {equinoxStorageSelected() && (
          <ExpansionPackQuantityInput
            onSelect={onExpansionSelect}
            selectedStorageName={selectedStorageName}
            selectedStorageQuantity={selectedStorageQuantity}
          />
        )}
        </div>
      );
    }
    return (
      <div className="input-group mt-9" id="expansion-pack" />
    );
  };

  const renderEvCharger = () => {
    if (!showEvCharger(partnerIsNewHomes, quote, businessFunctions)) return null;
    return (
      <div className="input-group" id="ev-charger">
        <label>EV Charger</label>
        <EvChargerListDropDown
          onSelect={onEvChargerSelect}
          selectedEvChargerConfiguration={selectedEvChargerConfiguration}
        />
        <EvChargerQuantityDropDown
          onSelect={onEvChargerSelect}
          selectedEvChargerConfiguration={selectedEvChargerConfiguration}
        />
      </div>
    );
  };

  const renderEvOutlet = () => {
    if (!showEvCharger(partnerIsNewHomes, quote, businessFunctions)) return null;
    return (
      <div className="input-group" id="ev-outlet">
        <label>EV Outlet</label>
        <EvOutletListDropDown
          onSelect={onEvOutletSelect}
          selectedEvChargerConfiguration={selectedEvChargerConfiguration}
        />
        <EvOutletQuantityDropDown
          onSelect={onEvOutletSelect}
          selectedEvChargerConfiguration={selectedEvChargerConfiguration}
        />
      </div>
    );
  };

  return (
    <div id="system-information-section" className="manual-mode-design-section">
      {quote.locked && (
        <div className="alert alert--info">
          <span>
            This quote is locked and cannot be edited.
          </span>
        </div>
      )}
      <div className="section-header">
        <span className="title h3">System Information</span>
      </div>
      <div className="all-inputs">
        <div className="column">
          <div className="inputs-row">
            <div className="input-group" id="system-module">
              <label>Module Type</label>
              {quote.locked ? (
                <div className="input-container input-container--icon icon-right dropdown-with-tags is-disabled">
                  <i className="icon icon-chevron-down" />
                  <div className="select-dropdown cs-skin-border" tabIndex="0">
                    <span className="cs-placeholder">
                      <div className="cs-option-name">{quote.lockedModuleName}</div>
                    </span>
                  </div>
                </div>
              ) : (
                <ModuleListDropdown
                  value={selectedPvModule.itemId}
                  onChange={(key) => onChangeModule(key)}
                  data={modulesData(filteredPvModules)}
                />
              )}
            </div>
          </div>
          <div className="inputs-row">
            <div className="input-group input-container" id="system-shading-measurement-date">
              <label data-tip={shadingMeasurementDateTooltip}>Shading Measurement Date</label>
              <DatePicker
                dateFormat="MM/DD/YYYY"
                selected={shadingMeasurementDate}
                onChange={(momentDate) => onChangeShadingMeasurementDate(momentDate)}
                disabled={quote.locked}
              />
            </div>
          </div>
          <div className="inputs-row">
            { renderStorage() }
          </div>
          <div className="inputs-row">
            { renderEvCharger() }
          </div>
          { account.features.addersInEddie && guardAccessoriesEnabled() && accessories.length > 0 &&
            <div className="inputs-row">
              <div className="input-group" id="system-accessories">
                <label>Accessories</label>
                <div className="system-accessories">
                  {accessories.indexOf(SKIRT_GUARD) > -1 && isSunPowerModule(selectedPvModule) && (
                    <span>
                      <input
                        type="checkbox"
                        checked={quote.isSkirtGuard}
                        onChange={(e) => onChangeSkirtData(e.target.checked)}
                        disabled={quote.locked || !isSunPowerModule(selectedPvModule)}
                      />
                      <span>Front Edge Skirt</span>
                    </span>
                  )}
                  {accessories.indexOf(CRITTER_GUARD) > -1 && (
                    <span>
                      <input
                        type="checkbox"
                        checked={quote.isCritterGuard}
                        onChange={(e) => onChangeCritterData(e.target.checked)}
                        disabled={quote.locked}
                      />
                      <span>Critter Guard</span>
                    </span>
                  )}
                </div>
              </div>
            </div>
          }
        </div>
        <div className="column">
          <div className="inputs-row">
            <div className="input-group" id="system-monitoring">
              <label>Monitoring</label>
              <MonitoringConfigurationsDropdown onSelect={onMonitoringSelect} />
            </div>
          </div>
          <div className="inputs-row">
            <div className="input-group" id="system-racking">
              <label>Mounting</label>
              { quote.locked ? (
                <select
                  value={quote.rackingType}
                  disabled={true}
                >
                  <option value={quote.rackingType} key={quote.rackingType}>{quote.rackingType}</option>;
                </select>
              ) : (
                <select
                  value={quote.rackingType}
                  onChange={(env) => onChangeRacking(env.target.value)}
                  disabled={rackings.length === 1}
                >
                  {
                    rackings.map((racking) => {
                      return <option value={racking.id} key={racking.id}>{ racking.type }</option>;
                    })
                  }
                </select>
              ) }
            </div>
          </div>
          <div className="inputs-row">
            { renderInverterOrExpansionPack()}
          </div>
          <div className="inputs-row">
            { renderEvOutlet()}
          </div>
        </div>
      </div>
    </div>
  );
}

SystemInformation.propTypes = {
  quote: quoteType.isRequired,
  account: accountType.isRequired,
  pvModules: PropTypes.arrayOf(pvModuleForManualModeType).isRequired,
  savePvModuleForManualMode: PropTypes.func.isRequired,
  updateQuote: PropTypes.func.isRequired,
  productConfiguration: productConfigurationType.isRequired,
  mainInverter: inverterType,
  selectedStorage: selectedStorageType.isRequired,
  settings: settingsType.isRequired,
  updateSettings: PropTypes.func.isRequired,
  saveSettings: PropTypes.func.isRequired,
  businessFunctions: businessFunctionsType,
  newHomesDetails: newHomesDetailsType,
  partnerIsNewHomes: PropTypes.bool.isRequired,
  design: designType.isRequired,
  isPartnerEnabledForAnyStorage: PropTypes.bool.isRequired,
  selectedStorageWithExpansionPack: selectedStorageWithExpansionPackType.isRequired,
  selectedEvChargerConfiguration: selectedEvChargerConfigurationType.isRequired,
  updateEvChargerConfig: PropTypes.func
};

export default register(
  [
    'pvModulesSelector',
    'quoteSelector',
    'accountSelector',
    'productConfigurationSelector',
    'mainInverterSelector',
    'selectedStorageSelector',
    'settingsSelector',
    'businessFunctionsSelector',
    'newHomesDetailsSelector',
    'partnerIsNewHomesSelector',
    'isPartnerEnabledForAnyStorageSelector',
    'selectedStorageWithExpansionPackSelector',
    'selectedEvChargerConfigurationSelector',
    'designSelector'
  ],
  [
    'savePvModuleForManualMode',
    'updateQuote',
    'updateSettings',
    'saveSettings',
    'updateEvChargerConfig',
    'toggleAddStorageModalFlag'
  ],
  SystemInformation
);
