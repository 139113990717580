import get from './get';
import {
  partnerIsTps,
  partnerSectorLeaseIsNewHomes,
  partnerIsRvar,
  partnerIsSpd,
  partnerIsNewHomes,
  partnerChannelIsDirect
} from '../utils/EddieCalculations/helpers/account';

export const partnerIsTpsSelector = ({ account }) => ({
  partnerIsTps: partnerIsTps(account.partner)
});

export const partnerIsRvarSelector = ({ account }) => ({
  partnerIsRvar: partnerIsRvar(account.partner)
});

export const partnerSectorLeaseIsNewHomesSelector = ({ account }) => ({
  partnerSectorLeaseIsNewHomes: partnerSectorLeaseIsNewHomes(account.partner)
});

export const partnerIsNewHomesSelector = ({ account }) => ({
  partnerIsNewHomes: partnerIsNewHomes(account.partner)
});

export const partnerIsSpdSelector = ({ account }) => ({
  partnerIsSpd: partnerIsSpd(account.partner)
});

export const partnerIsDirectSelector = ({ account }) => ({
  partnerIsDirect: partnerChannelIsDirect(account.partner)
});

export default get('account');
