export function toggleAddStorageModalFlag() {
  return {
    type: 'toggleAddStorageModalFlag',
    payload: {
      design: {
        isAddStorageModalOpen: (currentValue) => !currentValue
      }
    }
  };
}
