import { asyncPayload } from './apiHelpers';
import Api from '../utils/Api';

export function updateInflationRate(quote, params) {
  return {
    type: 'updateInflationRate',
    payload: asyncPayload({
      request: Api.update(`quotes/${quote.sfid}/update_inflation_rate`, {
        quote: params
      }),
      afterRequest: {
        quote: {
        }
      }
    })
  };
}
