import React from 'react';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import Modal from 'react-bootstrap-modal';
import { register } from '../../utils/redux';
import {
  getTitle, getSubTitle, getButtonTitle, isNameAndAddressMisMatched, titleCheckName,
  isNameMisMatched, isAddressMisMatched, getUpdateType, titleCheckAddress,
  constants
} from '../../utils/titleCheck';
import { accountType, dynamicProposalType, quoteType } from '../../types';

const {
  ACTIONS
} = constants;

export function TitleCheckModal({ quote, account, showTitleCheckModal, toggleTitleCheckModal,
  sendTitleCheck, dynamicProposal }) {
  const performAction = (actionType, updateType = '') => {
    sendTitleCheck(quote, account.sfid, dynamicProposal, actionType, updateType);
  };

  const closeModal = () => {
    performAction(ACTIONS.cancel);
    toggleTitleCheckModal(false);
  };

  const addressDiv = (account, quote) => {
    const showAddressDiv = isNameAndAddressMisMatched(get(quote, 'titleCheckDetails', null)) ||
      isAddressMisMatched(get(quote, 'titleCheckDetails', null));
    if (showAddressDiv) {
      return (
        <React.Fragment>
          <div className="data">
            <div className="heading">ADDRESS:</div>
            <div className="detail existing">
              {`${get(account, 'street', '')} ${get(account, 'city', '')},
              ${get(account, 'state', '')} ${get(account, 'postalCode', '')}`
              }
            </div>
          </div>
          <div className="data">
            <div className="heading">ADDRESS:</div>
            <div className="detail">
              {titleCheckAddress(get(quote, 'titleCheckDetails', null))}
            </div>
          </div>
        </React.Fragment>
      );
    }
    return null;
  };

  const nameDiv = (account, quote) => {
    const showNameDiv = isNameAndAddressMisMatched(get(quote, 'titleCheckDetails', null)) ||
      isNameMisMatched(get(quote, 'titleCheckDetails', null));
    if (showNameDiv) {
      return (
        <React.Fragment>
          <div className="data">
            <div className="heading">Name:</div>
            <div className="detail existing">
              {get(account, 'name', '')}
            </div>
          </div>
          <div className="data">
            <div className="heading">Name:</div>
            <div className="detail">
              {titleCheckName(get(quote, 'titleCheckDetails', ''))}
            </div>
          </div>
        </React.Fragment>
      );
    }
    return null;
  };

  return (
    <Modal
      className="title-check-modal"
      show={showTitleCheckModal}
      onHide={closeModal}
      aria-labelledby="ModalHeader"
      data-keyboard="false"
      data-backdrop="static"
    >
      <Modal.Header className="title-check-modal-header" closeButton={true}>
        <div className="heading">{getTitle(get(quote, 'titleCheckDetails', null))}</div>
        <div className="sub-heading">{getSubTitle(get(quote, 'titleCheckDetails', null))}:</div>
      </Modal.Header>
      <Modal.Body className="title-check-modal-body">
        <div className="body-detail-container">
          <div className="header-icon-container">
            <img src={'/build/images/icon-circle-user.svg'} alt="Title Check" />
          </div>
          <div className="center-icon-container">
            <img src={'/build/images/icon-diamond-arrow.svg'} alt="Title Check" />
          </div>
          <div className="data-container">
            <div className="column-heading">
              Currently on file
            </div>
            <div className="column-heading">
              Found on title
            </div>
            { nameDiv(account, quote) }
            { addressDiv(account, quote) }
            <div />
            <div className="data">
              <button
                className="btn action-button"
                onClick={() => performAction(ACTIONS.overwrite,
                  getUpdateType(get(quote, 'titleCheck', null)))}
              >
                <span>{getButtonTitle(get(quote, 'titleCheck', null))}</span>
                <img src={'/build/images/icon-button-arrow.svg'} alt="button" />
              </button>
            </div>
          </div>
        </div>
        <div
          className="action-container"
          onClick={() => performAction(ACTIONS.add)}
        >
          <div>
            <div className="co-signer-add">
              Add a co-signer
            </div>
            <div className="co-signer-sub-label">
              If information doesn’t match at all.
            </div>
          </div>
          <img
            src={'/build/images/icon-circle-secondary-user.svg'}
            alt="Title Check"
          />
        </div>
      </Modal.Body>
      <Modal.Footer className="title-check-modal-footer">
        <button
          className="btn cancel-btn"
          onClick={() => performAction(ACTIONS.cancel)}
        >
          Cancel Contract
        </button>
        <div
          className="continue-btn"
          onClick={() => performAction(ACTIONS.decline)}
        >
          Don’t change anything and continue
        </div>
      </Modal.Footer>
    </Modal>
  );
}

TitleCheckModal.propTypes = {
  quote: quoteType.isRequired,
  account: accountType.isRequired,
  showTitleCheckModal: PropTypes.bool.isRequired,
  toggleTitleCheckModal: PropTypes.func.isRequired,
  dynamicProposal: dynamicProposalType.isRequired,
  sendTitleCheck: PropTypes.func.isRequired
};

export default register(
  [],
  [],
  TitleCheckModal
);
