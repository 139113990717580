import PropTypes from 'prop-types';

const baseProperties = {
  monitoringCost: PropTypes.number.isRequired,
  minPricePerWatt: PropTypes.number.isRequired,
  systemPrice: PropTypes.number.isRequired,
  systemPriceWithAdders: PropTypes.number.isRequired,
  systemPriceWithDiscounts: PropTypes.number.isRequired,
  systemPriceWithAddersAndDiscounts: PropTypes.number.isRequired,
  systemPriceWithAddersAndStorage: PropTypes.number.isRequired,
  systemPriceWithAddersDiscountsStorage: PropTypes.number.isRequired,
  dealerFee: PropTypes.number.isRequired,
  rebate: PropTypes.number.isRequired,
  federalTaxCredit: PropTypes.number.isRequired,
  stateTaxCredit: PropTypes.number.isRequired,
  stateAndLocalIncentives: PropTypes.number.isRequired,
  credit: PropTypes.number.isRequired,
  netCost: PropTypes.number.isRequired,
  costPerKwhWithSolar: PropTypes.number,
  costPerKwhWithoutSolar: PropTypes.number,
  costPerKwhSavings: PropTypes.number
};

const TaxValue = PropTypes.shape({
  cost: PropTypes.number.isRequired,
  tax: PropTypes.number.isRequired,
  costWithTax: PropTypes.number.isRequired
});

export const spwrCashSystemCostType = PropTypes.shape({
  ...baseProperties,
  // Dependent on FinEng values.
  totalContractPrice: PropTypes.number,
  dealerFee: PropTypes.number
});

export const cashSystemCostType = PropTypes.shape({
  ...baseProperties,
  upfrontCost: PropTypes.number.isRequired,
  storagePrice: PropTypes.number,
  storageDealerFee: PropTypes.number
});

export const leaseSystemCostType = PropTypes.shape({
  ...baseProperties,
  // Dependent on FinEng values.
  dealerFee: PropTypes.number,
  partialPrepayment: PropTypes.number,
  fullPrepayment: PropTypes.number,
  monthlyPayments: PropTypes.arrayOf(TaxValue),
  totalMonthlyPayments: PropTypes.number,
  totalPayments: PropTypes.number,
  downPayment: PropTypes.number
});

export const ppaSystemCostType = PropTypes.shape({
  ...baseProperties,
  // Dependent on FinEng values.
  dealerFee: PropTypes.number,
  partialPrepayment: PropTypes.number,
  fullPrepayment: PropTypes.number,
  monthlyPayments: PropTypes.arrayOf(TaxValue),
  totalMonthlyPayments: PropTypes.number,
  totalPayments: PropTypes.number,
  downPayment: PropTypes.number
});

export const loanSystemCostType = PropTypes.shape({
  ...baseProperties,
  declined: PropTypes.bool.isRequired,
  storagePrice: PropTypes.number,
  storageDealerFee: PropTypes.number,
  // Dependent on FinEng values.
  termInMonths: PropTypes.number,
  monthlyPayments: PropTypes.arrayOf(TaxValue),
  totalMonthlyPayments: PropTypes.number,
  totalContractPrice: PropTypes.number,
  netContractPrice: PropTypes.number,
  coPayment: PropTypes.number,
  dealerFee: PropTypes.number,
  loanAmount: PropTypes.number
});

export const mortgageSystemCostType = PropTypes.shape({
  ...baseProperties,
  upfrontCost: PropTypes.number.isRequired,
  storagePrice: PropTypes.number,
  storageDealerFee: PropTypes.number,
  year1MonthlySolarMortgagePayment: PropTypes.number,
  year1MonthlySolarWithStorageMortgagePayment: PropTypes.number
});

export const allSystemCostsType = PropTypes.shape({
  cash: cashSystemCostType,
  spwrCash: spwrCashSystemCostType,
  lean: leaseSystemCostType,
  ppa: ppaSystemCostType,
  loan: loanSystemCostType,
  mortgage: mortgageSystemCostType
});
