import { useCallback, useEffect, useState } from 'react';

const useDebounce = (countdownInMilliseconds, action) => {
  const [ internalTimeout, setInternalTimeout ] = useState();
  const [ internalCountdown, setInternalCountdown ] = useState(0);

  useEffect(() => {
    setInternalCountdown(countdownInMilliseconds);
  }, [ countdownInMilliseconds, setInternalCountdown ]);

  const execute = useCallback(
    (...params) => {
      if (internalTimeout) {
        clearTimeout(internalTimeout);
      }

      setInternalTimeout(
        setTimeout(() => {
          action(...params);
        }, internalCountdown),
      );
    },
    [ internalCountdown, action, internalTimeout, setInternalTimeout ],
  );

  return { execute };
};

export default useDebounce;
