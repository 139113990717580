export function updateValidationErrors(financeType, field, error) {
  return {
    type: 'updateValidationErrors',
    payload: {
      validationErrors: {
        [financeType]: {
          [field]: error
        }
      }
    }
  };
}
