import { getStateNameByStateCode } from 'us-state-codes';
import { findSelectedModuleSeries, findSelectedModule, findSelectedStates } from './updateHelpers';

export const validateSelectedModuleSeries =
({ apFinConfigs, financialOfferings, selectedFinanceOption, selectedSeries }) => {
  const selectedStates = findSelectedStates(apFinConfigs, selectedFinanceOption);
  const selectedModuleSeries = findSelectedModuleSeries(apFinConfigs, selectedFinanceOption);
  const moduleSeriesError = {};
  if (selectedModuleSeries.length > 0) {
    selectedStates.map((state) => {
      const availableSeriesArray =
        financialOfferings[selectedFinanceOption][state].module.map((details) => {
          return details.series;
        });
      const invalidModuleSeries = selectedModuleSeries.filter((series) => !availableSeriesArray.includes(series));
      if (invalidModuleSeries.length > 0) {
        invalidModuleSeries.map((series) => {
          const stateName = getStateNameByStateCode(state);
          moduleSeriesError[series] = moduleSeriesError[series] ?
          [ ...moduleSeriesError[series], stateName ] : [stateName];
          return moduleSeriesError;
        });
      }
      return moduleSeriesError;
    });
  }
  return moduleSeriesError;
};

export const validateSelectedModule = ({ apFinConfigs, financialOfferings, selectedFinanceOption }) => {
  const selectedStates = findSelectedStates(apFinConfigs, selectedFinanceOption);
  const selectedModule = findSelectedModule(apFinConfigs, selectedFinanceOption);
  let noModuleError = null;
  if (selectedModule) {
    selectedStates.map((state) => {
      const availableModuleArray =
        financialOfferings[selectedFinanceOption][state].module.map((details) => {
          return details.moduleItemId;
        });
      const invalidModule = !availableModuleArray.includes(selectedModule);
      if (invalidModule) {
        const stateName = getStateNameByStateCode(state);
        noModuleError = `This Module is not available in ${stateName},
        please change your module or state selection to proceed.`;
        return noModuleError;
      }
      return noModuleError;
    });
  }
  return noModuleError;
};

export const validateInput = (value) => {
  return value.toString().replace(/[^\d.]/g, '');
};

export const inValidApConfig = ({ validationErrors }) => {
  let isInValid = false;
  if (validationErrors) {
    Object.keys(validationErrors).forEach((key) => {
      Object.keys(validationErrors[key]).forEach((obj) => {
        isInValid = isInValid || validationErrors[key][obj];
      });
    });
  }
  return isInValid;
};
