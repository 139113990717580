export function toggleSavingsGraphTab(newTab) {
  return {
    type: 'toggleSavingsGraphTab',
    payload: {
      savings: {
        activeGraphTab: newTab
      }
    }
  };
}
