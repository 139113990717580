import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-bootstrap-modal';
import { register } from '../../utils/redux';
import {
  designType
} from '../../types';
import StorageSettings from '../../components/add_storage/StorageSettings';

export function AddStorageModal({
  design,
  closeAddStorageModal
}) {
  return (
    <Modal
      className="add-storage-modal"
      show={design.isAddStorageModalOpen}
      onHide={closeAddStorageModal}
      aria-labelledby="ModalHeader"
    >
      <Modal.Header closeButton={true}>
        <div className="add-storage-modal-title">SunVault&trade; Storage</div>
      </Modal.Header>
      <Modal.Body className="add-storage-modal-body">
        <StorageSettings />
      </Modal.Body>
    </Modal>
  );
}

AddStorageModal.propTypes = {
  design: designType.isRequired,
  closeAddStorageModal: PropTypes.func.isRequired
};

export default register(
  [
    'designSelector'
  ],
  [
    'closeAddStorageModal'
  ],
  AddStorageModal
);
