

export function selectDesign(design, designList, maxFitDesignList) {
  const selectedDesign = designList.find((designItem) => {
    return designItem.id === design.id;
  });
  const maxFitDesign = maxFitDesignList.find((maxFitDesign) => {
    return selectedDesign.maxfitSfid === maxFitDesign.qmRoofDesignId;
  });
  const productConfig = maxFitDesign.productConfig;
  const nestedProductConfig = maxFitDesign.productConfig.productConfiguration;
  return {
    type: 'selectDesign',
    payload: {
      selectedDesign: {
        id: design.id,
        name: design.name
      },
      design: {
        ...selectedDesign,
        hasChanges: true
      },
      quote: {
        roofDesignId: design.id,
        pvModuleItemId: productConfig.pvModuleItemId,
        monitoringConfigurationId: productConfig.monitoringConfigurationId,
        updatedAt: productConfig.updatedAt,
        moduleChanged: true
      },
      productConfiguration: {
        inverters: nestedProductConfig.inverters,
        racking: nestedProductConfig.racking,
        monitoringConfigurations: nestedProductConfig.monitoringConfigurations,
        monitoringItemJunctions: nestedProductConfig.monitoringItemJunctions
      },
      inverterUnits: productConfig.inverterUnits
    }
  };
}
