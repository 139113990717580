import { get } from 'lodash';

import * as actions from '../actions';
import store from '../store';
import constants from './EddieCalculations/constants';

const {
  LOAN_DEFAULT_PRE_PAYMENT_PERCENTAGE
} = constants;

export const calculateLoanPrePayment = (loanAmount, prePaymentPercentage) => {
  return (loanAmount * prePaymentPercentage) / 100;
};

export const initialCalculatorInputs = (settings, credit, loanSystemCost) => {
  const initialLoanAmount = get(loanSystemCost, 'loanAmount', 0);
  const initialContractPrice = get(loanSystemCost, 'contractPrice', 0);
  const initialDownPayment = get(loanSystemCost, 'coPayment', 0);
  const initialPrePaymentPercentage = settings.loan.prePaymentPercentage || LOAN_DEFAULT_PRE_PAYMENT_PERCENTAGE;
  const initialPrePayment = calculateLoanPrePayment(
    initialLoanAmount,
    initialPrePaymentPercentage
  );

  return {
    initialDownPayment,
    initialPrePaymentPercentage,
    initialPrePayment,
    initialLoanAmount,
    initialContractPrice
  };
};

export const findCreditProductName = (credit, term, apr, aprPercentage) => {
  const product = aprPercentage ? (credit.products.find((product) =>
      product.loanMonthC === term && product.aprTypeC === apr && product.choiceRateC === aprPercentage))
  :
    (credit.products.find((product) =>
      product.loanMonthC === term && product.aprTypeC === apr));
  return get(product, 'name');
};

export const setDefaultLoanCalcValues = (data) => {
  const {
    quote,
    settings,
    credit,
    loanSystemCost,
    account,
    saveLoanCalcValuesInQuote
  } = data;
  const {
    initialDownPayment,
    initialPrePaymentPercentage,
    initialContractPrice
  } = initialCalculatorInputs(settings, credit, loanSystemCost);

  if (quote.locked) {
    store.dispatch(actions.setDefaultLoanCalcForLockedQuote(quote));
  } else {
    store.dispatch(actions.fetchFinEngLoanCalc({
      downPayment: initialDownPayment,
      contractPrice: initialContractPrice,
      prePaymentPercentage: initialPrePaymentPercentage,
      apr: settings.loan.aprType,
      term: settings.loan.termInMonths,
      creditProductName: findCreditProductName(credit, settings.loan.termInMonths,
          settings.loan.aprType, settings.loan.aprPercentage)
    }, quote, account, loanSystemCost, saveLoanCalcValuesInQuote));
  }
};
