import React, { useRef, useState } from 'react';
import ReactTooltip from 'react-tooltip';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { get } from 'lodash';
import { register } from '../../../utils/redux';
import { AddersList } from './AddersList';

export function CustomAddersView({ financeType, apFinConfig, updateApCustomAdders, updateApFinConfig }) {
  const [ selectedAdderType, setselectedAdderType ] = useState('standard');

  return (
    <div className="admin-section-data-container border-top">
      <div className="admin-section-data-heading-container flex-start">
        <div className="module-section-heading-label">
          <b>Create custom adders</b> to standardize your quotes:
        </div>
      </div>
      <div className="details-container width-90">
        <div className="select-container adders">
          <div className="flex items-center">
            <div
              onClick={() => setselectedAdderType('standard')}
              className={cx('adders-label ', { selected: selectedAdderType === 'standard' })}
            >
              Standard Adders
            </div>
            <div>
              <a data-tip={true} data-event="click focus" data-for="standard-adder"ß={true}>
                <img src="/build/images/admin-info-icon.svg" alt="info" className="info-img" />
              </a>
              <ReactTooltip
                id="standard-adder"
                globalEventOff="click"
                event="click"
                place="top"
                type="light"
                clickable={true}
                effect="solid"
                multiline={true}
              >
                <p>
                  <b>Standard Adders:</b>
                  <br />Will apply to every quote.
                  <br />Will increase the price of every system.
                  <br />Sales users will NOT see these.
                </p>
              </ReactTooltip>
            </div>
          </div>
          <div className="flex items-center">
            <div
              onClick={() => setselectedAdderType('optional')}
              className={cx('adders-label ', { selected: selectedAdderType === 'optional' })}
            >
                Optional Adders
              </div>
            <div>
              <a data-tip={true} data-event="click focus" data-for="optional-adder" >
                <img src="/build/images/admin-info-icon.svg" alt="info" className="info-img" />
              </a>
              <ReactTooltip
                id="optional-adder"
                globalEventOff="click"
                event="click"
                clickable={true}
                place="top"
                type="light"
                effect="solid"
                multiline={true}
              >
                <p>
                  <b>Optional Adders:</b>
                  <br />These adders will be “off” by default.
                  <br />Viewable within the settings icon.
                  <br />Users can add these to individual quotes.
                </p>
              </ReactTooltip>
            </div>
          </div>
        </div>
        <div className="select-container adders">
          <div className="flex items-center ad-hoc">
            <div className="adders-label ad-hoc">
              Ad Hoc Adders
            </div>
            <div>
              <a data-tip={true} data-event="click focus" data-for="adhoc-adder" >
                <img src="/build/images/admin-info-icon.svg" alt="info" className="info-img" />
              </a>
              <ReactTooltip
                id="adhoc-adder"
                globalEventOff="click"
                event="click"
                clickable={true}
                place="top"
                type="light"
                effect="solid"
                multiline={true}
              >
                <p>
                  <b>Ad Hoc Adders:</b>
                  <br />Ad Hoc Adders will allow EDDiE users
                  <br />to enter in additional adders with a price
                  <br />that will increase the adders amount.
                </p>
              </ReactTooltip>
            </div>
            <div className="adders-label ad-hoc">:</div>
            <div className="toggle-btn-with-label-container">
              <div className="toggle-btn-container">
                <label className="toggle-btn">
                  <input
                    type="checkbox"
                    checked={get(apFinConfig, 'adHocAddersEnabled')}
                    onChange={(e) => updateApFinConfig(financeType, 'adHocAddersEnabled', e.target.checked)}
                  />
                  <span className="slider round" />
                </label>
                <label className="toggle-btn-status-label">{get(apFinConfig, 'default') ? 'ON' : 'OFF'}</label>
              </div>
            </div>
          </div>
        </div>
      </div>
      <AddersList
        customAdder={get(apFinConfig, 'customAddersAttributes')}
        selectedAdderType={selectedAdderType}
        updateApCustomAdders={updateApCustomAdders}
        financeType={financeType}
      />
    </div>
  );
}

CustomAddersView.propTypes = {
  financeType: PropTypes.string,
  apFinConfig: PropTypes.arrayOf(PropTypes.object),
  updateApCustomAdders: PropTypes.func.isRequired,
  updateApFinConfig: PropTypes.func.isRequired
};

export default register(
  [],
  [],
  CustomAddersView
);
