import get from 'lodash/get';
import { datadogTrackLoadingTime } from '../utils/datadogUserMonitoring';
import { asyncPayload } from './apiHelpers';
import Api from '../utils/Api';
import store from '../store';
import { isPDFProcessing } from '../actions';

export function lockManualQuote(quote, selectedFinanceOption) {
  const startTime = Date.now();

  const promise = Api.lockQuoteWithPolling(`quotes/${quote.sfid}/lock`, {
    quote: {
      selectedFinanceOption
    }
  }, `manual_mode/quotes/${quote.sfid}`);

  return (dispatch) => {
    const response = dispatch({
      type: 'lockManualQuote',
      payload: asyncPayload({
        beforeRequest: {
          lockingQuote: true
        },
        request: promise,
        afterRequest: {
          lockingQuote: false
        },
        errorRequest: {
          lockingQuote: false
        }
      })
    });

    response.then((data) => {
      const noError = !get(data, 'value.error', null);
      if (noError) {
        const loadingTime = Date.now() - startTime;
        const locked = get(data, 'value.quote.locked', null);
        if (locked && noError) store.dispatch(isPDFProcessing(true));
        if (locked) {
          // tracking only successful loading time
          datadogTrackLoadingTime(
            'Lock Quote',
            loadingTime,
            quote,
            null,
            null,
            null,
            { fin_option_locked: selectedFinanceOption }
          );
        }
      }
      return data;
    });
  };
}
