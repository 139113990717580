export const getHomeImageName = (storageQuantity, storageExpansionPackQuantity) => {
  let homeImageName = '/build/images/storage_with_multi_inverters_home_battery_';
  if (storageExpansionPackQuantity) {
    homeImageName = `${homeImageName}${storageQuantity}_expansion_pack_${storageExpansionPackQuantity}`;
  } else {
    homeImageName = `${homeImageName}${storageQuantity}`;
  }
  return homeImageName;
};

export const getBatteryImageName = (storageQuantity, storageExpansionPackQuantity) => {
  let batteryImageName = '/build/images/storage_with_multi_inverters_battery_';
  if (storageExpansionPackQuantity) {
    batteryImageName = `${batteryImageName}${storageQuantity}_expansion_pack_${storageExpansionPackQuantity}`;
  } else {
    batteryImageName = `${batteryImageName}${storageQuantity}`;
  }
  return batteryImageName;
};
