import { asyncPayload } from './apiHelpers';
import Api from '../utils/Api';
import { CALCULATING_SAVINGS } from '../config';

export function saveModuleMonitoringRacking({
  quote,
  pvModuleItemId,
  monitoringConfigurationId,
  rackingType,
  isSkirtGuard,
  isCritterGuard,
  settings,
  roofDesignId = null
}) {
  let afterRequestBody = {
    design: {
      moduleSelectionModal: false
    },
    moduleSelectionModal: {
      selectedSeries: '',
      series: [],
      selectedColor: '',
      colors: [],
      selectedModel: '',
      models: [],
      selectedMonitoring: '',
      inverters: [],
      selectedCellularMonitoring: null,
      cellularMonitoringExists: null,
      selectedRacking: '',
      rackings: [],
      pvModuleItemId: '',
      roofDesignId: '',
      monitoringConfigurationId: ''
    },
    quote: {
      finEng: null
    }
  };

  let data = {
    quote: { pvModuleItemId, monitoringConfigurationId, rackingType, isSkirtGuard, isCritterGuard, roofDesignId }
  };

  if (quote.pvModuleItemId !== pvModuleItemId) {
    afterRequestBody = { ...afterRequestBody, moduleChanged: true };
    data = { ...data, moduleChanged: true };
  }

  const instantPath = quote.isInstantQuote ? 'instant_quote/' : '';
  const selectModulePath = `${instantPath}quotes/${quote.sfid}/select_module_monitoring_racking`;

  return {
    type: 'saveModuleMonitoringRacking',
    payload: asyncPayload({
      request: Api.update(selectModulePath, data).then((data) => data),
      beforeRequest: { mode: CALCULATING_SAVINGS },
      afterRequest: afterRequestBody,
      errorRequest: {
        design: {
          moduleSelectionModal: false
        }
      }
    })
  };
}
