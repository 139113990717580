import PropTypes from 'prop-types';
import { pathType } from './path';

export const pairedModuleArrayType = PropTypes.shape({
  arrayNumber: PropTypes.number.isRequired,
  panelCount: PropTypes.number.isRequired,
  azimuth: PropTypes.number.isRequired,
  monthlySolarAccess: PropTypes.arrayOf(PropTypes.number).isRequired,
  monthlyEnergyProduction: PropTypes.objectOf(
    PropTypes.arrayOf(PropTypes.number)
  ).isRequired,
  fillColor: PropTypes.string.isRequired,
  panels: PropTypes.arrayOf(PropTypes.shape({
    enabled: PropTypes.bool.isRequired,
    coordinates: pathType.isRequired
  })).isRequired
});

export const moduleArrayType = PropTypes.shape({
  arrayNumber: PropTypes.number,
  panelCount: PropTypes.number.isRequired,
  azimuth: PropTypes.number.isRequired,
  monthlySolarAccess: PropTypes.arrayOf(PropTypes.number).isRequired,
  monthlyEnergyProduction: PropTypes.objectOf(
    PropTypes.arrayOf(PropTypes.number)
  ),
  panels: PropTypes.arrayOf(PropTypes.shape({
    enabled: PropTypes.bool.isRequired
  })).isRequired,
  structure: PropTypes.number
});
