import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { get } from 'lodash';
import constants from '../../../utils/admin_portal/constants';
import { register } from '../../../utils/redux';

const {
  INSTALL_DAYS
} = constants;

export function InstallDaysSelectionView({ financeType, apFinConfig, updateApInstallDays }) {
  const [ isInstallDaysDropDownOpen, setIsInstallDaysDropDownOpen ] = useState(false);
  const INSTALL_DATE_DROPDOWN_ID = 'installDaysDropDown';
  const installDaysDropDownRef = useRef(INSTALL_DATE_DROPDOWN_ID);
  const selectedDays = get(apFinConfig, 'installDateAttributes', 'installDays');

  const toggleDropDown = (isDefault) => {
    if (!isDefault) {
      setIsInstallDaysDropDownOpen(!isInstallDaysDropDownOpen);
    }
  };

  const handleFocusChange = (ref) => {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setIsInstallDaysDropDownOpen(false);
        }
      }

      // Bind the event listener
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, [ref]);
  };

  handleFocusChange(installDaysDropDownRef);

  const installDaysOptions = (<ul>
    {INSTALL_DAYS.map((option) => {
      return (
        <li
          className={
            cx('cs-module-option-container',
              { 'cs-selected': option === selectedDays.installDays }
            )
          }
          onClick={() => {
            updateApInstallDays(apFinConfig.id, 'installDays', option);
            toggleDropDown(false);
          }}
        >
          <span className="cs-option">
            <div className="cs-option-name">{option} days</div>
          </span>
        </li>
      );
    })}
  </ul>);

  return (
    <div className="admin-section-data-container">
      <div className="admin-section-data-heading-container flex-start">
        <div className="module-section-heading-label">
          <b>Select Install Date</b> you want to default & show first:
        </div>
      </div>
      <div className="details-container width-90">
        <div className="select-container">
          <div className="toggle-btn-with-label-container opt-in">
            <div className="toggle-btn-container">
              <label className="toggle-btn">
                <input
                  type="checkbox"
                  checked={get(apFinConfig, 'installDateAttributes.optedIn')}
                  onChange={(e) => updateApInstallDays(apFinConfig.id, 'optedIn', e.target.checked)}
                />
                <span className="slider round" />
              </label>
              <label className="toggle-btn-status-label">{get(apFinConfig, 'installDateAttributes.optedIn') ?
              'OPTED IN' : 'OPTED OUT'}</label>
            </div>
          </div>
        </div>
        {get(apFinConfig, 'installDateAttributes.optedIn') && (
          <div className="select-container">
            <div className="label">INSTALL DATE:</div>
            <div
              id={INSTALL_DATE_DROPDOWN_ID}
              ref={installDaysDropDownRef}
              className={cx('input-container input-container--icon icon-right dropdown-with-tags option-select')}
            >
              <i className="icon icon-chevron-down" />
              <div className={cx('select-dropdown cs-skin-border', { 'cs-active': isInstallDaysDropDownOpen })}>
                <span
                  className="cs-placeholder" onClick={() => {
                    toggleDropDown(false);
                  }}
                >
                  <div className="cs-option-name">{selectedDays.installDays} days</div>
                </span>
                <div className="cs-options">
                  {installDaysOptions}
                </div>
              </div>
            </div>
          </div>)}
      </div>
    </div>
  );
}

InstallDaysSelectionView.propTypes = {
  financeType: PropTypes.string,
  apFinConfig: PropTypes.arrayOf(PropTypes.object),
  updateApInstallDays: PropTypes.func.isRequired
};

export default register(
  [],
  [],
  InstallDaysSelectionView
);
