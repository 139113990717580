import PropTypes from 'prop-types';

export const selectedSoEvChargerConfigurationType = PropTypes.shape({
  evChargerId: PropTypes.number,
  evChargerQuantity: PropTypes.number,
  evChargerConfigId: PropTypes.string,
  evOutletId: PropTypes.number,
  evOutletQuantity: PropTypes.number,
  evOutletConfigId: PropTypes.string
});
