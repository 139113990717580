import PropTypes from 'prop-types';
import React from 'react';

export function DownloadBar({
  setDownloadList
}) {
  return (
    <span id="download-list">
      <a
        onClick={() => setDownloadList(true)}
        className="reset"
      >
        Download
      </a> utilities and/or product list
    </span>
  );
}

DownloadBar.propTypes = {
  setDownloadList: PropTypes.func
};
