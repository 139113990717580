export function toggleSavingsTab(newTab) {
  return {
    type: 'toggleSavingsTab',
    payload: {
      savings: {
        activeTab: newTab
      }
    }
  };
}
