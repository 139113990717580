import PropTypes from 'prop-types';

export const savingsChartInfoBoxType = PropTypes.shape({
  x: PropTypes.number,
  label: PropTypes.number,
  savings: PropTypes.number,
  annualProduction: PropTypes.number,
  payment: PropTypes.number.isRequired,
  labelsCount: PropTypes.number.isRequired,
  ratePerKwh: PropTypes.number
});
