import paths from 'paths-js/path';

export default function(lines) {
  return lines
    .slice(1)
    .reduce(
      (path, line) => path.lineto(line.x, line.y),
      paths().moveto(lines[0].x, lines[0].y)
    )
    .closepath()
    .print();
}
