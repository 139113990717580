import { get } from 'lodash';
import { findApFinConfig } from '../../utils/admin_portal/updateHelpers';

export default (state) => {
  const { selectedFinanceOption, apFinConfigs } = state;
  let selectedModule;

  if (selectedFinanceOption && apFinConfigs) {
    const apFinConfig = findApFinConfig(apFinConfigs, selectedFinanceOption);

    selectedModule = get(apFinConfig, 'defaultModuleAttributes');
  }

  return {
    selectedModule
  };
};
