import { get, keys, pick, pickBy } from 'lodash';
import { showStorageWithExpPack } from './StorageWithMultiInverters/helpers';
import { storageExpansionPackEnabled } from './backendFeatureFlags';

export const getSelectedFinancialOptions = ({ productConfiguration, settings }) => {
  const availableFinanceTypes = keys(pickBy(productConfiguration.financialOfferings, (x) => x));
  const availableSettings = pick(settings, availableFinanceTypes);
  return keys(pickBy(availableSettings, (setting) => setting.showCustomer));
};

export const moduleAvailableForAllSelectedPaymentOptionsValidation =
  ({ moduleFinancialOptions, selectedFinancialOptions }) => {
    return selectedFinancialOptions.reduce((acc, paymentOption) => {
      return acc && moduleFinancialOptions.includes(paymentOption);
    }, true);
  };

export const moduleAvailableForSelectedPaymentOptionsValidation =
  (moduleFinancialOptions, financeOption) => {
    return moduleFinancialOptions.includes(financeOption);
  };

export const getUnAvailableModuleFinanceOptions = ({ pvModules, quote, productConfiguration, settings }) => {
  const selectedFinancialOptions = getSelectedFinancialOptions({ productConfiguration, settings });
  const selectedPvModule = pvModules.filter((pvModule) => pvModule.itemId === quote.pvModuleItemId)[0];
  const availableFinanceOptions = selectedPvModule.financialOfferings;

  return selectedFinancialOptions.filter((option) => !availableFinanceOptions.includes(option));
};

export const isSunPowerModule = (pvModule) => {
  const manufacturer = pvModule && get(pvModule, 'manufacturer', '');
  return (manufacturer === null || manufacturer === '') || (manufacturer && manufacturer.toLowerCase() === 'sunpower');
};

export const getModuleUpdateRequestObject = ({ quote, pvModules, selectedEvChargerConfiguration,
  selectedStorage, selectedStorageWithExpansionPack }, pvModuleItemId) => {
  const selectedPvModule = pvModules.find((pvModule) => pvModule.itemId === pvModuleItemId);
  let storage;
  const evChargerConfigs = [];
  if (showStorageWithExpPack(storageExpansionPackEnabled(), quote)) {
    storage = selectedPvModule.storageConfigurations.find((storage) =>
      storage.storageConfigId === selectedStorageWithExpansionPack.storageConfigId);
  } else {
    storage = selectedPvModule.storageUnits.find((storage) => storage.id === selectedStorage.id);
  }
  const storageConfigId = storage ? quote.storageConfigId : 'null';
  const storageQuantity = storage ? quote.storageQuantity : 0;
  const storageExpansionPackQuantity = storage ? quote.storageExpansionPackQuantity : null;
  const storageLaborCost = storage ? quote.storageLaborCost : null;

  if (selectedEvChargerConfiguration.evChargerId || selectedEvChargerConfiguration.evOutletId) {
    const evCharger = selectedPvModule.evmChargerConfigurations.find((config) =>
      config.configId === selectedEvChargerConfiguration.evChargerConfigId);
    const evOutlet = selectedPvModule.evmChargerConfigurations.find((config) =>
      config.configId === selectedEvChargerConfiguration.evOutletConfigId);
    if (!evCharger && selectedEvChargerConfiguration.evChargerId) {
      evChargerConfigs.push({ id: selectedEvChargerConfiguration.evChargerId, _destroy: true });
    }
    if (!evOutlet && selectedEvChargerConfiguration.evOutletId) {
      evChargerConfigs.push({ id: selectedEvChargerConfiguration.evOutletId, _destroy: true });
    }
  }
  return {
    pvModuleItemId,
    storageConfigId,
    storageQuantity,
    storageExpansionPackQuantity,
    storageLaborCost,
    evChargerConfigs
  };
};
