import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { register } from '../../../utils/redux';
import { validateInput } from '../../../utils/admin_portal/validationHelper';

export function InputFieldWithCurrencySymbol({ label, placeHolder, value, currency, onChange, disabled, onBlur, key }) {
  return (
    <div
      key={key}
      className="input-currency-field-container-with-label"
    >
      {
        label && (
          <label className="label">{label}</label>
        )
      }
      <div className={cx('input-field-container', { disabled })}>
        {
          currency &&
          <label className="input-currency-field">{currency}</label>
        }
        <input
          type="text"
          placeholder={placeHolder}
          className="input-field"
          value={value}
          onChange={(e) => {
            onChange(validateInput(e.target.value));
          }}
          disabled={disabled}
          onBlur={onBlur}
        />
      </div>
    </div>
  );
}

InputFieldWithCurrencySymbol.propTypes = {
  label: PropTypes.string,
  placeHolder: PropTypes.string,
  value: PropTypes.string,
  currency: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  validateInput: PropTypes.func,
  disabled: PropTypes.bool,
  key: PropTypes.string
};

export default register(
  [],
  [],
  InputFieldWithCurrencySymbol
);
