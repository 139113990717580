export function toggleTitleCheckModal(value) {
  return {
    type: 'setTitleCheckModalStatus',
    payload: {
      dynamicProposal: {
        showTitleCheckModal: value
      }
    }
  };
}
