import createSelector from '../utils/createSelector';
import { pvModuleSelected } from './pvModule';
import { storageExpansionPackEnabled } from '../utils/backendFeatureFlags';

export function storageSelectedWithExpansionPack(state) {
  let selectedStorageWithExpansionPack;
  if (storageExpansionPackEnabled()) {
    const quote = state.quote;
    const pvModules = state.pvModules;
    const { pvModule } = pvModuleSelected({ quote, pvModules });
    selectedStorageWithExpansionPack = pvModule ?
      pvModule.storageConfigurations.find((storage) => storage.storageConfigId === quote.storageConfigId)
      : null;
  }
  return {
    selectedStorageWithExpansionPack: selectedStorageWithExpansionPack ||
      { storageConfigId: null, storageName: 'None' }
  };
}

export default createSelector(
  storageSelectedWithExpansionPack
);

