import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import ProposalViewer from './ProposalViewer';
import ProposalToolbar from './ProposalToolbar';
import { register } from '../../utils/redux';
import { adobeSignType, quoteType, proposalType } from '../../types';
import { adobeSignEnabled } from '../../utils/backendFeatureFlags';

const ProposalPage = ({
  adobeSign,
  isPublic
}) => (
  <div id="main-content" className="proposal-page" style={{ overflow: 'hidden', WebkitOverflowScrolling: 'touch' }}>
    <div className="pdf-viewer-container">
      {!isPublic && (
        <ProposalToolbar />
      )}
      {adobeSignEnabled() && adobeSign.showWidget ?
        <iframe className="adobe-sign-widget" src={adobeSign.url} /> :
        <ProposalViewer />
      }
    </div>
  </div>
);

ProposalPage.propTypes = {
  adobeSign: adobeSignType.isRequired,
  isPublic: PropTypes.bool
};

export const RegisteredProposalPage = register(
  [
    'adobeSignSelector',
    'proposalSelector',
    'quoteSelector',
    'isPublicSelector'
  ],
  [],
  ProposalPage
);

const prepareDocuments = ({ quote, proposal, generateProposalFile, isPublic }) => {
  // if (quote.selectedFinanceOption === 'cash' && !proposal.docxUrl) {
  //   generateProposalFile(quote, 'docx');
  // }

  if (!proposal.pdfUrl) {
    generateProposalFile(quote, isPublic, 'pdf');
  }
};

const ProposalPageLifecycle = (props) => {
  useEffect(() => {
    const {
      setAdobeSignWidgetRefreshing,
      reinitializeAdobeSignWidget,
      adobeSign,
      quote
    } = props;
    if (adobeSign.willWidgetExpireWithinHour && !adobeSign.refreshingWidget) {
      setAdobeSignWidgetRefreshing();
      reinitializeAdobeSignWidget(quote.sfid);
    }
  }, []);

  useEffect(() => {
    prepareDocuments(props);
  });

  return (<RegisteredProposalPage />);
};

ProposalPageLifecycle.propTypes = {
  reinitializeAdobeSignWidget: PropTypes.func.isRequired,
  quote: quoteType.isRequired,
  adobeSign: adobeSignType.isRequired,
  generateProposalFile: PropTypes.func.isRequired,
  setAdobeSignWidgetRefreshing: PropTypes.func.isRequired,
  proposal: proposalType.isRequired,
  isPublic: PropTypes.bool
};

export default register(
  [
    'quoteSelector',
    'adobeSignSelector',
    'proposalSelector',
    'isPublicSelector'
  ],
  [
    'reinitializeAdobeSignWidget',
    'generateProposalFile',
    'setAdobeSignWidgetRefreshing'
  ],
  ProposalPageLifecycle
);
