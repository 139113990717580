/* eslint-disable max-len */
export default {
  VIEW_WEB_VERSION: 'View web-based version of proposal for all Information and Disclaimers about your solar proposal',
  INFORMATION_DISCLOSER_HEADER: 'Important Information About Assumptions Made in this Proposal',
  INFORMATION_DISCLOSER_1: '1. Actual escalation may differ or vary annually. The EIA estimates a nationwide annual escalation of 2% for the relevant time period (AEO, 2016).',
  INFORMATION_DISCLOSER_2: '2. Savings is estimated as the difference between the cost of a SunPower system and the value of solar, which includes projected energy bill savings. Savings are estimated and do not incorporate the effects of any possible taxes. Savings are shown for discussion purposes only and are not guaranteed. Actual consumption over time may vary based on electricity needs, impacting overall savings.',
  INFORMATION_DISCLOSER_3: '3. Post-Solar Rate Schedule is Pacific Gas and Electric Company (PG&E), 2017 Proposed Residential Service - 2017 Proposed Rate 3309493. The utility rates reflected in the estimated utility costs and savings in this Proposal are based on current rates but may be higher if a publicly announced proposed rate increase is expected to be in effect when your system is activated. Savings are based on your utility rate on the date of this proposal, during year one of your system’s activation.',
  INFORMATION_DISCLOSER_4: '4. Estimated solar electricity cost is based on projected system costs and estimated system production, assuming typical weather at your location. Your production, and therefore cost per kWh, may vary depending on actual weather experienced in any given year. Estimate includes projected sales tax where applicable.',
  INFORMATION_DISCLOSER_5: '5. Electricity Savings are estimated based on your utility rate on the date of this proposal, and represent savings on charges for electricity usage only. Actual monthly bills can include fixed charges that are unrelated to actual electricity usage, and may be subject to minimum monthly bill restrictions. SunPower does not warrant or guarantee savings.',
  INFORMATION_DISCLOSER_6: '6. Solar incentives and tax credits vary and are subject to change. Some incentives will be paid over time based on your system performance. SunPower does not warrant, guarantee, or otherwise advise its partners or customers about specific tax outcomes. Consult your tax advisor regarding the solar tax credit and how it applies to your specific circumstances. Please visit www.dsireusa.org for detailed solar policy information.',
  INFORMATION_DISCLOSER_7: '7. This proposal expires 15 days from date generated unless otherwise stipulated by SunPower or its representatives. Other terms and conditions may apply.',
  INFORMATION_DISCLOSER_8: '8. Solar cell technology varies by panel. SunPower M-, X-, A- and E- Series panels use interdigitated back contact solar cell technology. Equinox U-Series Panels use front-contact solar cell technology. All panels are manufactured by SunPower approved third-parties and are covered by our Limited Product and Power Warranty.',
  INFORMATION_DISCLOSER_9: '9. Solar installations require approval in communities managed by a Homeowners Association (HOA). HOA approval procedures vary greatly by state and management company. In most cases, HOAs will not work with outside parties to grant approval of construction project and the homeowner will be responsible for contacting and coordinating directly with the HOA. SunPower or your installer will provide the relevant documents needed for your application. Homeowners are strongly encouraged to start the HOA application process as soon as possible to avoid unnecessary delays. Please keep your install coordinator updated on your progress so that they can schedule your installation accordingly.',
  CPUC_HEADER: 'California Public Utility Commission (CPUC)',
  CPUC_LABEL: 'California Solar Consumer Protection Guide',
  CPUC_LINE_1: 'SunPower is a customer focused company and that applies to not just our customers, but any customer of the solar industry. We have worked with the CPUC to ensure customers understand their rights and responsibilities as it pertains to solar and as part of that we advise you to go to the below link from CA.gov to review the CPUCs consumer protection guide.',
  CPUC_LINE_2_HEADER: 'What is the California Solar Consumer Protection Guide?',
  CPUC_LINE_2: 'The California Solar Consumer Protection Guide is a brochure produced by the CPUC which is intended to provide information and guidance to homeowners who are interested in installing solar systems on their homes.',
  CPUC_LINE_3_HEADER: 'Why is this Guide important?',
  CPUC_LINE_3: 'This guide was created to provide information and guidelines to homeowners so that they can make an informed decision about solar. The Guide is required to be provided to all homeowners before any contract for solar is presented. The CPUC requires that the homeowner initial and sign certain pages within the Guide so that these can be filed with the Utility that the homeowner resides in.',
  CPUC_LINE_4_HEADER: 'Where can I find a copy of the Guide?',
  CPUC_LINE_4: 'A copy of the Guide can be found online at this link: https://www.cpuc.ca.gov/solarguide/'
};
/* eslint-enable max-len */
