import { asyncPayloadWithNoExplicitUpdate } from './apiHelpers';
import Api from '../utils/Api';

export const fetchGraphs = (quoteSfid) => (dispatch) => {
  const promise = Api.fetch(`quotes/${quoteSfid}/graphs`)
    .then((response) => ({
      savingsChart: response.savingsChart,
      totalSavingsChart: response.totalSavingsChart,
      savings: {
        activeGraphTab: response.defaultChartTab
      },
      chartTerm: response.chartTerm
    }));

  dispatch({
    type: 'fetchGraphs',
    payload: asyncPayloadWithNoExplicitUpdate({
      request: promise,
      beforeRequest: { graphLoading: true }
    })
  });
};
