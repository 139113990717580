const MAX_STORAGE_ALLOWED = 4;
const MAX_STORAGE_ALLOWED_FOR_INITIAL_RELEASE = 2;

export const isBatterySizePresent = (storageConfiguration) => {
  return storageConfiguration && storageConfiguration.batterySize;
};

// This function is used only for quotes version <= 13
const sortCombinations = (storageConfigurations) => {
  const sunvaultStorageConfigs = storageConfigurations.filter((config) =>
    config.storageName.toLowerCase().includes('sunvault'));
  if (isBatterySizePresent(sunvaultStorageConfigs[0])) {
    return sunvaultStorageConfigs.slice().sort(
      (a, b) => a.storageQuantity - b.storageQuantity || a.expansionPacks - b.expansionPacks
        || a.batterySize - b.batterySize)
      .map((configurations) => {
        return {
          storageQuantity: configurations.storageQuantity,
          expansionPackQuantity: configurations.expansionPacks,
          batterySize: configurations.batterySize,
          powerRating: configurations.powerRating,
          storageConfigId: configurations.storageConfigId
        };
      });
  }

  return sunvaultStorageConfigs.slice().sort(
    (a, b) => a.storageQuantity - b.storageQuantity || a.expansionPacks - b.expansionPacks)
    .map((configurations) => {
      return {
        storageQuantity: configurations.storageQuantity,
        expansionPackQuantity: configurations.expansionPacks
      };
    });
};

const createCombinationKey = (storageQuantity, expansionPackQuantity, batterySize) => {
  if (batterySize) {
    return `${storageQuantity}-${expansionPackQuantity}-${batterySize}`;
  }
  return `${storageQuantity}-${expansionPackQuantity}`;
};

const getSubtractTransition = (combinations, index) => {
  return index === 0 ? null :
  [ combinations[index - 1].storageQuantity, combinations[index - 1].expansionPackQuantity,
    combinations[index - 1].batterySize, combinations[index - 1].powerRating ];
};

const calculatedBatterySize = (storageQuantity, expansionPackQuantity, batterySize) => {
  return (storageQuantity + expansionPackQuantity) * batterySize;
};

const getPowerEnabledTransition = (storageQuantity, expansionPackQuantity, totalNumberOfBoxes, batterySize,
  powerRating) => {
  const remainderBoxes = storageQuantity + expansionPackQuantity - MAX_STORAGE_ALLOWED;
  const unitBatterySize = batterySize / (storageQuantity + expansionPackQuantity);
  return totalNumberOfBoxes < MAX_STORAGE_ALLOWED ?
  [ totalNumberOfBoxes, 0, calculatedBatterySize(totalNumberOfBoxes, 0, unitBatterySize),
    powerRating * totalNumberOfBoxes ] :
  [ MAX_STORAGE_ALLOWED, remainderBoxes, calculatedBatterySize(totalNumberOfBoxes, 0, unitBatterySize),
    powerRating ];
};

const getPowerDisabledTransition = (storageQuantity, expansionPackQuantity, batterySize, powerRating) => {
  const unitBatterySize = batterySize / (storageQuantity + expansionPackQuantity);
  return (storageQuantity - 1 === 3) && (expansionPackQuantity + 1 === 1) ?
  [ storageQuantity - 2, expansionPackQuantity + 2,
    calculatedBatterySize(storageQuantity - 2, expansionPackQuantity + 2, unitBatterySize),
    powerRating / storageQuantity ] :
  [ storageQuantity - 1, expansionPackQuantity + 1,
    calculatedBatterySize(storageQuantity - 1, expansionPackQuantity + 1, unitBatterySize),
    powerRating / storageQuantity ];
};

const getIndexOfCombinations = (combinations, storageQuantity, expansionPackQuantity, batterySize) => {
  if (combinations && combinations[0].batterySize) {
    if (batterySize === 39) {
      return -1;
    }
    return combinations.findIndex((item) =>
      item.storageQuantity === storageQuantity &&
      item.expansionPackQuantity === expansionPackQuantity &&
      item.batterySize === batterySize);
  }
  return combinations.findIndex((item) =>
    item.storageQuantity === storageQuantity &&
    item.expansionPackQuantity === expansionPackQuantity);
};

const getAddTransition = (combinations, index, isPowerBoost) => {
  if (isPowerBoost) {
    return [ combinations[index + 2].storageQuantity,
      combinations[index + 2].expansionPackQuantity,
      combinations[index + 2].batterySize,
      combinations[index + 2].powerRating ];
  }
  return index === combinations.length - 1 ? null :
  [ combinations[index + 1].storageQuantity, combinations[index + 1].expansionPackQuantity,
    combinations[index + 1].batterySize, combinations[index + 1].powerRating ];
};

const isPowerBoostState = (powerBoostStates, storageQuantity, expansionPackQuantity, unitBatterySize) => {
  const batterySize = calculatedBatterySize(storageQuantity, expansionPackQuantity, unitBatterySize);
  return powerBoostStates.some((item) =>
    item[0] === storageQuantity && item[1] === expansionPackQuantity && item[2] === batterySize);
};

const prepareAllowedTransitions = (storageConfigurations) => {
  const allowedCombination = {};
  const powerBoostStates = [];
  const sortedCombinations = sortCombinations(storageConfigurations);
  sortedCombinations.forEach((combination, index) => {
    let powerBoostEnabledState;
    let powerBoostDisabledState;
    let powerBoostEnabled;
    let powerBoostAllowed;
    let showPowerBoost;

    const storageQuantity = combination.storageQuantity;
    const expansionPackQuantity = combination.expansionPackQuantity;
    const batterySize = combination.batterySize;
    const unitBatterySize = batterySize / (storageQuantity + expansionPackQuantity);
    const powerRating = combination.powerRating;
    const totalNumberOfBoxes = storageQuantity + expansionPackQuantity;
    const storageConfigId = combination.storageConfigId;

    const combinationKey = createCombinationKey(storageQuantity, expansionPackQuantity, batterySize);
    const subtractTransition = getSubtractTransition(sortedCombinations, index);

    const powerBoostEnableTransition = getPowerEnabledTransition(storageQuantity, expansionPackQuantity,
      totalNumberOfBoxes, batterySize, powerRating);
    const indexOfPowerBoostCombination = getIndexOfCombinations(sortedCombinations, powerBoostEnableTransition[0],
      powerBoostEnableTransition[1], powerBoostEnableTransition[2]);

    let addTransition = getAddTransition(sortedCombinations, index, false);

    if (totalNumberOfBoxes > 1 && indexOfPowerBoostCombination > -1) {
      powerBoostEnabledState = powerBoostEnableTransition;
      powerBoostStates.push(powerBoostEnableTransition);
      addTransition = ((indexOfPowerBoostCombination === index + 1) && sortedCombinations.length - 1 >= index + 2) ?
        getAddTransition(sortedCombinations, index, true) : addTransition;
    }

    if (powerBoostStates && isPowerBoostState(powerBoostStates, storageQuantity,
      expansionPackQuantity, unitBatterySize)) {
      powerBoostEnabled = true;
      powerBoostAllowed = true;
      showPowerBoost = true;
      const powerBoostDisableTransition =
        getPowerDisabledTransition(storageQuantity, expansionPackQuantity, batterySize, powerRating);
      const indexOfPowerBoostDisableTransition = getIndexOfCombinations(sortedCombinations,
        powerBoostDisableTransition[0], powerBoostDisableTransition[1], powerBoostDisableTransition[2]);
      powerBoostDisabledState = indexOfPowerBoostDisableTransition > -1 ? powerBoostDisableTransition : null;
    } else {
      powerBoostEnabled = false;
      showPowerBoost = storageQuantity !== MAX_STORAGE_ALLOWED_FOR_INITIAL_RELEASE;
      powerBoostAllowed = (totalNumberOfBoxes > 1 && indexOfPowerBoostCombination > -1) &&
        (storageQuantity !== MAX_STORAGE_ALLOWED_FOR_INITIAL_RELEASE);
    }

    const additionAllowed = !!addTransition;
    const subtractionAllowed = !!subtractTransition;

    allowedCombination[combinationKey] = {
      addition: addTransition,
      subtraction: subtractTransition,
      powerBoostEnabledState,
      powerBoostDisabledState,
      powerBoostAllowed,
      additionAllowed,
      subtractionAllowed,
      showPowerBoost,
      powerBoostEnabled,
      storageQuantity,
      expansionPackQuantity,
      batterySize,
      powerRating,
      storageConfigId
    };
  });
  return allowedCombination;
};

export const storageWithInverterTransitions = (storageConfigurations, storageQuantity, expansionQuantity,
  energyRating) => {
  const combinationKey = createCombinationKey(storageQuantity, expansionQuantity, energyRating);
  const allowedCombination = prepareAllowedTransitions(storageConfigurations);
  return allowedCombination ? (allowedCombination[combinationKey] ?
    allowedCombination[combinationKey] : allowedCombination[Object.keys(allowedCombination)[0]]) : null;
};

export const findStorageConfiguration =
  (storageQuantity, storageExpansionPackQuantity, storageConfigurations, energyRating) => {
    const storage = storageConfigurations.find(
      (storage) => storage.storageQuantity === storageQuantity &&
        storage.expansionPacks === storageExpansionPackQuantity &&
        (storage.batterySize === null || storage.batterySize === energyRating));
    return (storage || null);
  };

export const calculatedRatings = (constants, storageQuantity, expansionPackQuantity, selectedStorageConfiguration) => {
  if (selectedStorageConfiguration && isBatterySizePresent(selectedStorageConfiguration)) {
    return {
      powerRating: selectedStorageConfiguration.powerRating,
      energyRating: selectedStorageConfiguration.batterySize
    };
  }

  const totalNumberOfBoxes = storageQuantity + expansionPackQuantity;

  const powerRating = constants && (storageQuantity * constants.powerRating);
  const energyRating = constants && (totalNumberOfBoxes * constants.energyRating);
  return {
    powerRating,
    energyRating
  };
};
