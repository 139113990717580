import Api from '../utils/Api';
import { asyncPayload } from './apiHelpers';

export function generateDynamicProposalFile(url) {
  const promise = Api.fetchFile(url)
    .then(async (fileBlob) => {
      const type = 'application/pdf';
      const blob = new Blob([fileBlob], { type });
      return new Promise((done, _fail) => {
        const reader = new FileReader();
        reader.onload = (_progressEvent) => {
          done({
            quote: {
              dpS3Data: reader.result
            }
          });
        };
        reader.readAsDataURL(blob);
      });
    }).catch(() => {
      return new Promise((done, _fail) => {
        done({
          quote: {
            dpS3Data: 'error'
          }
        });
      });
    });

  return {
    type: 'generateDynamicProposalFile',
    payload: asyncPayload({
      request: promise
    })
  };
}
