import cx from 'classnames';
import React from 'react';
import PropTypes from 'prop-types';

export default function FormGroup({ children, className, inline = false }) {
  const classes = cx('form-group', { 'form-group--inline': inline }, className);

  return (
    <div className={classes}>
      {children}
    </div>
  );
}
FormGroup.propTypes = {
  children: PropTypes.arrayOf(PropTypes.element).isRequired,
  className: PropTypes.string,
  inline: PropTypes.bool
};
