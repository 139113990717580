import get from 'lodash/get';
import has from 'lodash/has';
import moment from 'moment';

const widgetExists = (adobeSign) => has(adobeSign, 'widget.widgetCreationResponse');

const willWidgetExpireWithinHour = (adobeSign) => {
  if (!widgetExists(adobeSign)) return false;
  const widgetDocumentIndex = get(adobeSign, 'widget.documentIndex');
  const documents = get(adobeSign, 'documents', []);
  const widgetDocument = documents[widgetDocumentIndex];
  if (!widgetDocument) return true;
  const widgetExpiration = moment(widgetDocument.createdAt).add(7, 'days');
  return widgetExpiration.diff(moment(), 'hours', true) < 1;
};

const hasUserSignedAgreement = (adobeSign) =>
  get(adobeSign, 'agreements', [])
    .some((agreement) => agreement.status === 'SIGNED');

const hasUserSignedWidget = (adobeSign) =>
  get(adobeSign, 'widget.agreements', [])
    .some((agreement) => agreement.status === 'SIGNED');

/**
 * @param {Object} state Redux state.
 * @param {Object} state.adobeSign Local::Quote#adobe_sign directly from DB.
 * @param {Object} state.adobeSign.widget.widgetCreationResponse Adobe Sign createWidget response:
 * https://secure.na1.echosign.com/public/docs/restapi/v5#!/widgets/createWidget
 * @param {Object} state.adobeSign.widget.widgetInfo Latest Adobe Sign getWidgetInfo response, update everytime
 * Adobe Sign hits widget callback url:
 * https://secure.na1.echosign.com/public/docs/restapi/v5#!/widgets/getWidgetInfo
 * @param {{createdAt: String}[]} state.adobeSign.documents
 * @param {{documentIndex: Number, agreementCreationResponse: Object, agreementInfo: Object}[]} state.adobeSign.agreements
 * See reponses from https://secure.na1.echosign.com/public/docs/restapi/v5#!/agreements/createAgreement and
 * https://secure.na1.echosign.com/public/docs/restapi/v5#!/agreements/getAgreementInfo
 */
export default ({ proposal, adobeSign }) => ({
  adobeSign: {
    widgetId: get(adobeSign, 'widget.widgetCreationResponse.widgetId', null),
    widgetExists: widgetExists(adobeSign),
    hasBeenAccepted: hasUserSignedWidget(adobeSign) || hasUserSignedAgreement(adobeSign),
    url: get(adobeSign, 'widget.widgetCreationResponse.url', null),
    showWidget: widgetExists(adobeSign) &&
      get(proposal, 'toolbar.activeItemId') === 'signature',
    willWidgetExpireWithinHour: willWidgetExpireWithinHour(adobeSign),
    refreshingWidget: get(adobeSign, 'ui.refreshingWidget', false)
  }
});
