import { asyncPayload } from './apiHelpers';
import Api from '../utils/Api';

export function updatePublicRoofDesign(quote, design) {
  return {
    type: 'PATCH',
    payload: asyncPayload({
      request: Api.updateWithPolling(`public/quotes/${quote.sfid}?pat=${quote.publicAccessToken}`, { design }),
      afterRequest: {
        design: {
          hasChanges: false
        }
      }
    })
  };
}
