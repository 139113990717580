import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { register } from '../../utils/redux';
import { settingsType, dropdownsType, quoteType } from '../../types';
import { filterPvModulesByAdminSettings, tagList } from '../../utils/pvModuleFiltersAndTags';

export function ModuleListDropdown({
  value,
  onChange,
  data,
  dropdowns: { isModuleListOpen },
  toggleModulesDropdown,
  settings,
  quote,
  disabled
}) {
  const selected = data.find((item) => item.key === value);
  const isSelected = (item) => item.key === value;
  const defaultContainerClasses = 'input-container input-container--icon icon-right dropdown-with-tags';

  const options = (<ul>
    {data.map((item) => {
      return (
        <li
          className={cx('cs-option-container', { 'cs-selected': isSelected(item) })}
          onClick={() => {
            toggleModulesDropdown();
            onChange(item.key);
          }}
          key={item.key}
        >
          <span className="cs-option">
            <div className="cs-option-name">{item.value}</div>
            {tagList(item.tags, settings)}
          </span>
        </li>
      );
    })}
  </ul>);

  return (
    <div id="module_list_dropdown" className={cx(defaultContainerClasses, { 'is-disabled': disabled })}>
      <i className="icon icon-chevron-down" />
      <div className={`select-dropdown cs-skin-border ${isModuleListOpen ? 'cs-active' : ''}`} tabIndex="0">
        <span className="cs-placeholder" onClick={() => toggleModulesDropdown()}>
          <div className="cs-option-name">{selected.value}</div>
          {tagList(selected.tags, settings)}
        </span>
        <div className="cs-options">
          { options }
        </div>
      </div>
    </div>
  );
}

ModuleListDropdown.propTypes = {
  quote: quoteType,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  data: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    tags: PropTypes.arrayOf(PropTypes.string)
  })).isRequired,
  dropdowns: dropdownsType.isRequired,
  toggleModulesDropdown: PropTypes.func.isRequired,
  settings: settingsType,
  disabled: PropTypes.bool
};

export default register(
  [ 'dropdownsSelector', 'settingsSelector', 'quoteSelector' ],
  ['toggleModulesDropdown'],
  ModuleListDropdown
);

