import get from 'lodash/get';
import constants from '../utils/EddieCalculations/constants';

const { NON_SUNPOWER_GROUND_MOUNT_RACKING } = constants;
const SUNPOWER_TYPES = [ 'invisimount', 'solarmount' ];
const NON_SUNPOWER_TYPES = [ 'non-sunpower roof mount', NON_SUNPOWER_GROUND_MOUNT_RACKING ];

export default (state) => {
  const { quote, settings } = state;
  const rackingType = (quote.rackingType || '').toLowerCase();
  const isSunpower = quote.manualMode ?
    SUNPOWER_TYPES.includes(rackingType) :
    !get(settings, 'general.nonSunpowerRacking', false);
  const isNonSunpower = quote.manualMode ?
    NON_SUNPOWER_TYPES.includes(rackingType) :
    get(settings, 'general.nonSunpowerRacking', false);
  return {
    selectedRacking: {
      rackingType,
      isSunpower,
      isNonSunpower
    }
  };
};
