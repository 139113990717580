import get from 'lodash/get';

export const partnerChannelIsDirect = (partner) => /^direct$/i.test(get(partner, 'channel', ''));
export const partnerChannelIsIndirect = (partner) => /^indirect$/i.test(get(partner, 'channel', ''));
export const partnerSectorLeaseIsNewHomes =
  (partner) => /new homes/i.test(get(partner, 'sectorLease', ''));

export const partnerIsTps = (partner) => /tps/i.test(get(partner, 'channel', ''));
export const partnerIsSpd = (partner) =>
  partnerChannelIsDirect(partner) && !partnerSectorLeaseIsNewHomes(partner);
export const partnerIsNewHomes = (partner) =>
  partnerChannelIsDirect(partner) && partnerSectorLeaseIsNewHomes(partner);
export const partnerIsRvar = partnerChannelIsIndirect;

