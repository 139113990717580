import BrowserStorage from './BrowserStorage';
import { storeFeatureFlags } from './featureFlags';

function parseHash(hash) {
  return hash
    .slice(1)
    .split('&')
    .map((kv) => kv.split('='))
    .reduce(
      (obj, [ key, value ]) => Object.assign(obj, { [key]: value }),
      {}
    );
}

export function fetchLatestToken() {
  return BrowserStorage.getItem('id_token');
}
export function fetchLatestAdminPortalToken() {
  return BrowserStorage.getItem('admin_portal_token');
}

export function withAuth({ location }) {
  storeFeatureFlags(location);
  if (location.hash) {
    const params = parseHash(location.hash);
    const { id_token: idToken } = params;

    BrowserStorage.setSessionItem('id_token', idToken);
  }
}
export function withAdminPortalAuth({ location }) {
  storeFeatureFlags(location);
  if (location.hash) {
    const params = parseHash(location.hash);
    const { token } = params;

    BrowserStorage.setSessionItem('admin_portal_token', token);
  }
}

export function publicAuth({ location }) {
  storeFeatureFlags(location);
  if (location.query) {
    const { pat } = location.query;
    return pat;
  }
  return false;
}
