import { get } from 'lodash';
import createSelector from '../../utils/createSelector';
import constants from '../../utils/storageQuote/constants';

const {
  EV_CHARGER,
  EV_OUTLET
} = constants;

export function selectedEvChargerConfigurations(state) {
  const storageQuote = state.storageQuote;
  const evChargerConfigs = get(storageQuote, 'evChargerConfigs', []);
  const selectedConfigs = {};
  if (evChargerConfigs.length > 0) {
    evChargerConfigs.forEach((config) => {
      if (config.productName.toLowerCase() === EV_CHARGER) {
        selectedConfigs.evChargerId = config.id;
        selectedConfigs.evChargerQuantity = config.quantity;
        selectedConfigs.evChargerConfigId = config.configId;
        selectedConfigs.evChargerConfigName = config.configName;
      } else if (config.productName.toLowerCase() === EV_OUTLET) {
        selectedConfigs.evOutletId = config.id;
        selectedConfigs.evOutletQuantity = config.quantity;
        selectedConfigs.evOutletConfigId = config.configId;
        selectedConfigs.evOutletConfigName = config.configName;
      }
    });
    return { selectedEvChargerConfigurations: selectedConfigs };
  }

  return {};
}

export default createSelector(
  selectedEvChargerConfigurations
);

