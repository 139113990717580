import React from 'react';
import PropTypes from 'prop-types';

import Grid from '@material-ui/core/Grid';
import get from 'lodash/get';

import cn from 'classnames';

import TotalSavingsChart from '../charts/TotalSavingsChart';
import EscalatorSelection from './EscalatorSelection';

import { formatCurrency } from '../../utils/formatNumber';

import styles from '../../../scss/charts/TotalSavingsModule.module.scss';

const legendText = {
  cash: {
    solarPayments: 'Solar Net Payment',
    electricBill: 'Electric Bill',
    savings: 'Net Savings',
    gridSize: 4
  },
  loan: {
    solarPayments: 'Solar Loan Payments',
    electricBill: 'Electric Bill',
    savings: 'Net Savings',
    gridSize: 4
  },
  lease: {
    solarPayments: 'Solar Lease Payments',
    electricBill: 'Electric Bill',
    savings: 'Net Savings',
    gridSize: 4
  },
  ppa: {
    solarPayments: 'Solar PPA Payments',
    electricBill: 'Electric Bill',
    savings: 'Net Savings',
    gridSize: 4
  }
};

const totalSavingsModule = ({
  totalSavingsChart,
  selectedFinanceOption,
  selectedInflationRate,
  inflationRateSelectOptions,
  readOnlyInflationRate,
  onChangeRate,
  isPDFProcessing,
  chartTerm
}) => {
  const totalSavingsChartData = get(totalSavingsChart, `${selectedInflationRate}.${selectedFinanceOption}`, {});
  const totalSavings = selectedFinanceOption === 'cash'
    ? totalSavingsChartData.solarSavingsForCash : totalSavingsChartData.solarSavings;

  const inflationRateView = () => {
    return (
      <EscalatorSelection
        rates={inflationRateSelectOptions}
        inflationRate={selectedInflationRate}
        onChangeRate={onChangeRate}
        refNumber={1}
        readOnly={readOnlyInflationRate || isPDFProcessing}
      />
    );
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.tagLine} id="total-savings">
        Save an estimated <span className={styles.tagLineSavings}>
          {formatCurrency(totalSavings, 0)}
        </span> over {chartTerm} years<sup className={styles.superscript}> 2</sup>
      </div>
      <div className={styles.subtitle}>
        Estimated payments and savings over {chartTerm} years
      </div>
      <Grid
        container={true}
        justifyContent="center"
        className={styles.graphContainer}
      >
        <Grid item={true} xs={12}>
          <TotalSavingsChart
            data={totalSavingsChartData}
            selectedInflationRate={selectedInflationRate}
            selectedFinanceOption={selectedFinanceOption}
            legendText={legendText}
            isPDFProcessing={isPDFProcessing}
          />
        </Grid>
      </Grid>
      <Grid
        container={true}
        className={styles.legendContainer}
      >
        <div className={cn(styles.legendItem, styles.savingsLegendItem)}>
          <div className={styles.legendItemImg}>
            <img src="/build/images/net_savings_legend.svg" alt="net savings" />
          </div>
          <div className={cn(styles.legendItemText, styles.savingsLegendItemText)}>
            {legendText[`${selectedFinanceOption}`].savings} <sup>2</sup>
          </div>
        </div>
        <div className={cn(styles.legendItem, styles.solarLegendItem)}>
          <div className={styles.legendItemImg}>
            <img src="/build/images/solar_payments.svg" alt="solar payments" />
          </div>
          <div className={styles.legendItemText}>
            {legendText[`${selectedFinanceOption}`].solarPayments}
          </div>
        </div>
        <div className={cn(styles.legendItem, styles.electricLegendItem)}>
          <div className={styles.legendItemImg}>
            <img src="/build/images/electric_bill.svg" alt="electric bill" />
          </div>
          <div className={styles.legendItemText}>
            {legendText[`${selectedFinanceOption}`].electricBill}
          </div>
        </div>
      </Grid>
      <Grid
        container={true}
        className={styles.notesContainer}
      >
        <Grid item={true} xs={12} sm={8}>
          <div>
            {inflationRateView()}
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

totalSavingsModule.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  totalSavingsChart: PropTypes.object,
  selectedFinanceOption: PropTypes.string,
  selectedInflationRate: PropTypes.string,
  inflationRateSelectOptions: PropTypes.arrayOf(PropTypes.string),
  readOnlyInflationRate: PropTypes.bool,
  onChangeRate: PropTypes.func,
  isPDFProcessing: PropTypes.bool,
  chartTerm: PropTypes.number
};

export default totalSavingsModule;
