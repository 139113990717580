import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { chunk, isNil } from 'lodash';
import moment from 'moment';
import { designType, pvModuleType } from '../../../types';
import { adSolarPanelType } from '../../../types/adSolarPanel';
import { formatNumber } from '../../../utils/formatNumber';
import { adDesignType } from '../../../types/adDesign';

export const InstantQuoteDetailedViewTab = ({
  design,
  adDesign,
  selectedPanels,
  isSpecialState,
  specialStateFullName,
  selectedPvModule
}) => {
  const [months] = useState([
    'JAN',
    'FEB',
    'MAR',
    'APR',
    'MAY',
    'JUN',
    'JUL',
    'AUG',
    'SEP',
    'OCT',
    'NOV',
    'DEC'
  ]);
  const [monthChunckSize] = useState(4);
  const [ monthValues, setMonthValues ] = useState(new Array(12).fill(0));
  const [ pitch, setPitch ] = useState('-');
  const [ azimuth, setAzimuth ] = useState('-');
  const [ tsrf, setTsrf ] = useState('-');
  const [ showNewPanelsMessage, setShowNewPanelsMessage ] = useState(false);
  const [ roofPanels, setRoofPanels ] = useState({});

  const getPitchValue = useCallback((pitch) => {
    return pitch.toString().includes('°') ? pitch : `${pitch}°`;
  }, []);

  useEffect(() => {
    if (!selectedPanels || selectedPanels.length === 0 || Object.keys(roofPanels).length === 0) {
      setShowNewPanelsMessage(false);
      setMonthValues(new Array(12).fill(0));
      setPitch('-');
      setAzimuth('-');
      setTsrf('-');
      return;
    }

    const {
      roofIds,
      showNewPanelsMessage,
      monthlySolarAccess,
      totalPanelsWithSolarAccess
    } = selectedPanels.reduce((result, panel) => {
      if (!roofPanels[panel.roof_id]) {
        result.showNewPanelsMessage = true;
      } else {
        const roofIndex = parseInt(panel.roof_id.replace('R', ''), 10);
        const panelIndex = roofPanels[panel.roof_id].panels.findIndex((roofPanel) => roofPanel.id === panel.id);

        if (
          !isNil(design.roof.panelShading[roofIndex])
          && !isNil(design.roof.panelShading[roofIndex][panelIndex])
          && !roofPanels[panel.roof_id].panels[panelIndex].editedAt
        ) {
          result.monthlySolarAccess = result.monthlySolarAccess.map(
            (solarAccess, index) =>
              solarAccess + design.roof.panelShading[roofIndex][panelIndex][index]
          );
          result.totalPanelsWithSolarAccess += 1;
        } else {
          result.showNewPanelsMessage = true;
        }
      }

      if (!result.roofIds.includes(panel.roof_id)) {
        result.roofIds.push(panel.roof_id);
      }

      return result;
    }, {
      roofIds: [],
      showNewPanelsMessage: false,
      monthlySolarAccess: new Array(12).fill(0),
      totalPanelsWithSolarAccess: 0
    });

    setShowNewPanelsMessage(showNewPanelsMessage);

    if (totalPanelsWithSolarAccess > 0) {
      setMonthValues(monthlySolarAccess.map((solarAccess) => solarAccess / totalPanelsWithSolarAccess));
    } else {
      setMonthValues(new Array(12).fill(0));
    }

    if (roofIds.length === 1) {
      const roof = adDesign.layers.roofs.find((roof) => roof.id === roofIds[0]);
      if (roof) {
        setPitch(getPitchValue(roof.tilt));
        setAzimuth(roof.azimuth);

        if (roofPanels[roofIds[0]]) {
          setTsrf(roofPanels[roofIds[0]].tsrf);
        } else {
          setTsrf('-');
          setShowNewPanelsMessage(true);
        }
        return;
      }
    }

    setPitch('-');
    setAzimuth('-');
    setTsrf('-');
  }, [
    selectedPanels,
    selectedPvModule,
    setPitch,
    setAzimuth,
    getPitchValue,
    adDesign,
    design,
    setShowNewPanelsMessage,
    setMonthValues,
    roofPanels,
    setTsrf
  ]);

  useEffect(() => {
    setRoofPanels(adDesign.layers.modules.reduce((result, panel) => {
      if (panel.roof_id && panel.roof_id.length > 0) {
        if (!result[panel.roof_id]) {
          const roofIndex = parseInt(panel.roof_id.replace('R', ''), 10);
          const moduleArrayIndex = Object.keys(result).length;

          result[panel.roof_id] = {
            index: roofIndex,
            moduleArrayIndex,
            tsrf: '-',
            panels: []
          };

          if (design.moduleArrays[moduleArrayIndex]) {
            result[panel.roof_id].tsrf = design.moduleArrays[moduleArrayIndex].annualTsrf;
          }
        }

        result[panel.roof_id].panels.push({
          id: panel.id,
          active: panel.active,
          production: panel.production,
          editedAt: panel.editedAt
        });
      }

      return result;
    }, {}));
  }, [ adDesign, setRoofPanels, design ]);

  return (
    <div className="instant-quote-detailed-view div-relative">
      <div className="panels-power-production-section section">
        <div className="title">PANEL SOLAR ACCESS PERCENTAGES</div>
        {showNewPanelsMessage ? (
          <div className="new-panels-message">
            <span className="highlight">Save the design first</span> to see the solar access
            <br />of a new panel or any adjusted panels.
          </div>
        ) : null}
        <div className="months-groups-container">
          {chunk(months, monthChunckSize).map((groupOfMonths, groupIndex) => (
            <div className="months-list" key={`months-group-${groupIndex}`}>
              {groupOfMonths.map((month, monthIndex) => (
                <div key={`group-${groupIndex}-month-${monthIndex}`} className="month">
                  <div className="label">{month}</div>
                  <div className="value">{
                    formatNumber(monthValues[(groupIndex * monthChunckSize) + monthIndex], 0)
                  }</div>
                </div>
              ))}
            </div>
          ))}
        </div>
      </div>
      <div className="pitch-azimuth-section section">
        <div className="section-container">
          <div className="pitch">
            <div className="title">PITCH</div>
            <div className="value">{pitch}</div>
          </div>
          <div className="azimuth">
            <div className="title">AZIMUTH</div>
            <div className="value">{azimuth}</div>
          </div>
        </div>
      </div>
      <div className="shading-tsrf-section section">
        <div className="section-container">
          <div className="shading">
            <div className="title">SHADING MEASUREMENT DATE</div>
            <div className="value">{moment(design.updatedAt).format('MM/DD/YYYY')}</div>
          </div>
          <div className="tsrf">
            <div className="title">TSRF %</div>
            <div className="value">{tsrf}</div>
          </div>
        </div>
      </div>
      <div className="section">
        <div className="disclaimer legal-text">
          Electricity Production and Offset are preliminary estimates.
            Final estimated Electricity Offset will be displayed on the
            Savings page and Proposal.

          {isSpecialState ? (
            <React.Fragment>
              <div className="arizona-disclaimer-title">{specialStateFullName}</div>
              In the state of {specialStateFullName}, the Instant Quote app does not illustrate
                or describe potential financial savings associated with
                installing a SunPower solar system.
            </React.Fragment>
          ) : null}
        </div>
      </div>
    </div>
  );
};

InstantQuoteDetailedViewTab.propTypes = {
  selectedPanels: PropTypes.arrayOf(adSolarPanelType).isRequired,
  isSpecialState: PropTypes.bool,
  specialStateFullName: PropTypes.string,
  selectedPvModule: pvModuleType,
  adDesign: adDesignType,
  design: designType
};
