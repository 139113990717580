import createSelector from '../utils/createSelector';
import { pvModuleSelected } from './pvModule';

export function storageSelected(state) {
  const quote = state.quote;
  const pvModules = state.pvModules;
  const { pvModule } = pvModuleSelected({ quote, pvModules });
  const selectedStorage = pvModule ? pvModule.storageUnits.find((storage) => storage.id === quote.storageConfigId)
   : null;

  return {
    selectedStorage: selectedStorage || { id: null, name: 'None' }
  };
}

export default createSelector(
  storageSelected
);

