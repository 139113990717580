import PropTypes from 'prop-types';
import { inverterType } from './inverter';

export const moduleSelectionModalType = PropTypes.shape({
  selectedSeries: PropTypes.string,
  selectedColor: PropTypes.string.isRequired,
  colors: PropTypes.arrayOf(PropTypes.string.isRequired),
  selectedModel: PropTypes.string.isRequired,
  models: PropTypes.arrayOf(PropTypes.string.isRequired),
  selectedMonitoring: PropTypes.string.isRequired,
  inverters: PropTypes.arrayOf(inverterType),
  selectedCellularMonitoring: PropTypes.bool.isRequired,
  cellularMonitoringExists: PropTypes.bool.isRequired,
  selectedRacking: PropTypes.string.isRequired,
  rackings: PropTypes.arrayOf(PropTypes.string.isRequired),
  pvModuleItemId: PropTypes.string.isRequired,
  monitoringConfigurationId: PropTypes.string.isRequired,
  selectedStorage: PropTypes.string,
  selectedStorageQuantity: PropTypes.number,
  storageQuantity: PropTypes.number,
  storages: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired
  }))
});
