import { asyncPayload } from './apiHelpers';
import Api from '../utils/Api';

export const updateLoanCalcValuesInQuote = (quote, data) => (dispatch) => {
  const payload = {
    loan_calc_values: data
  };

  dispatch({
    type: 'updateLoanCalcValuesInQuote',
    payload: asyncPayload({
      request: Api.update(
        `quotes/${quote.sfid}/settings/update_loan_calc`,
        payload
      )
    })
  });
};
