import PropTypes from 'prop-types';
import React, { createRef } from 'react';
import DownloadIcon from '../../../../images/download.svg';
import { uploadDate } from '../../../utils/uploaderHelper';
import { PricingDefaultsError } from './PricingDefaultsError';

export function FileUploaded({ pricingControls, download, upload, setPreview, rows }) {
  const replaceRef = createRef();
  const duplicates = rows.filter((row) => row.isDuplicate).length;
  const overlappingSizes = rows.filter((row) => row.hasOverlappingSize).length;
  const multiplePo = rows.filter((row) => row.hasMultiplePo).length;

  return (
    <React.Fragment>
      <div className="upload uploaded-file">
        <b>{pricingControls.fileName}</b>
        <span>
          Last Updated: {uploadDate(pricingControls.updatedAt)}
        </span>
      </div>
      <div className="uploaded-btns">
        {(duplicates > 0 || multiplePo > 0 || overlappingSizes > 0) && (
          <PricingDefaultsError
            duplicates={duplicates}
            multiplePo={multiplePo}
            overlappingSizes={overlappingSizes}
            id="uploaded-tooltip"
          />
        )}
        <button
          className="btn btn-secondary download"
          onClick={download}
        >
          <DownloadIcon />
        </button>
        <button
          className="btn btn-secondary replace"
          onClick={() => replaceRef.current.click()}
        >
          Replace
        </button>
        <input
          type="file"
          accept="text/csv"
          ref={replaceRef}
          onChange={(event) => upload(event)}
          onClick={(event) => {
            event.target.value = null;
          }}
          hidden={true}
        />
        <button
          className="btn btn-primary view"
          onClick={() => setPreview(true)}
        >
          View file
        </button>
      </div>
    </React.Fragment>
  );
}

FileUploaded.propTypes = {
  rows: PropTypes.arrayOf(PropTypes.any),
  download: PropTypes.func,
  upload: PropTypes.func,
  // eslint-disable-next-line react/forbid-prop-types
  pricingControls: PropTypes.object,
  setPreview: PropTypes.func
};
