import uniq from 'lodash/uniq';
import get from 'lodash/get';
import compact from 'lodash/compact';

const sum = (x, y) => x + y;

const FLAT_ROOF_THRESHOLD = 8;
const STEEP_ROOF_THRESHOLD = 36;

// this was changed due to SFDC saving pitch with first number instead of degrees
export const pitchValue = (value) => {
  switch (value) {
    case 'Horiz. (0°)': return 0;
    case '1:12 (5°)': return 5;
    case '2:12 (9°)': return 9;
    case '3:12 (14°)': return 14;
    case '4:12 (18°)': return 18;
    case '5:12 (23°)': return 23;
    case '6:12 (27°)': return 27;
    case '7:12 (30°)': return 30;
    case '8:12 (34°)': return 34;
    case '9:12 (37°)': return 37;
    case '10:12 (40°)': return 40;
    case '11:12 (43°)': return 43;
    case '12:12 (45°)': return 45;
    default: return value;
  }
};

const pitchCondition = (pitch, condition) => {
  return (pitch < FLAT_ROOF_THRESHOLD && condition === 'flat')
      || (pitch > STEEP_ROOF_THRESHOLD && condition === 'steep');
};

const distance = (A, B) => {
  const x = A.x - B.x;
  const y = A.y - B.y;
  return Math.sqrt(x * x + y * y);
};

const angle = (A, B) => {
  if (A.x < B.x) [ A, B ] = [ B, A ];
  const x = A.x - B.x;
  const y = A.y - B.y;
  let theta = Math.atan2(y, x) * 180 / Math.PI;
  if (theta < 0) theta = 360 + theta;
  return Math.round(theta);
};

const compare = (azimuth, angle) => {
  const calculation = Math.abs((azimuth - angle + 180 + 360) % 360 - 180);
  return calculation === 0 || calculation === 180;
};

const isLandscape = (design, roofPlaneNumber, azimuth, panelIndex) => {
  const panel = !design.isInstant ?
    design.roof.moduleArrays[roofPlaneNumber][panelIndex] :
    design.roof.moduleArrays[Object.keys(design.roof.moduleArraysIndexesToRoofPlaneNumbers)
      .filter((idx) => design.roof.moduleArraysIndexesToRoofPlaneNumbers[idx] === roofPlaneNumber)][panelIndex];
  if (distance(panel[0], panel[1]) > distance(panel[1], panel[2])) {
    return compare(azimuth, angle(panel[0], panel[1]));
  }
  return compare(azimuth, angle(panel[1], panel[2]));
};

const pointOnTheLine = (point, panel) => {
  const panelLines = [
    [ panel[0], panel[1] ],
    [ panel[1], panel[2] ],
    [ panel[2], panel[3] ],
    [ panel[3], panel[4] ]
  ];
  return panelLines.some((line) => distance(line[0], point)
   + distance(point, line[1]) - distance(line[0], line[1]) < 5);
};

const isConnected = (panel1, panel2) => {
  return panel1.some((c) => pointOnTheLine(c, panel2));
};

const isIsland = (design, roofPlaneNumber, moduleArrayIndex, panelIndex) => {
  const moduleArray = design.moduleArrays[moduleArrayIndex];
  const roofModuleArray = !design.isInstant ? design.roof.moduleArrays[roofPlaneNumber] :
    design.roof.moduleArrays[Object.keys(design.roof.moduleArraysIndexesToRoofPlaneNumbers)
      .filter((idx) => design.roof.moduleArraysIndexesToRoofPlaneNumbers[idx] === roofPlaneNumber)];
  const panel = roofModuleArray[panelIndex];
  return !roofModuleArray.some((p, i) => i !== panelIndex &&
    moduleArray.panels[i].enabled && isConnected(p, panel));
};

const manualModePanelCount = (design) =>
  design.moduleArrays
    .map((ma) => parseInt(ma.panelCount, 10))
    .reduce(sum, 0);

const dynamicModePanelCount = (design) =>
  design.moduleArrays
    .map((ma) => ma.panels.filter((p) => p.enabled).length)
    .reduce(sum, 0);

const manualModePitchConditionPanelCount = (design, condition) =>
  design.moduleArrays.filter((ma) => pitchCondition(pitchValue(ma.pitch), condition))
    .map((ma) => parseInt(ma.panelCount, 10))
    .reduce(sum, 0);

const dynamicModePitchConditionPanelCount = (design, condition) =>
  design.moduleArrays.filter((ma) => pitchCondition(pitchValue(ma.pitch), condition))
    .map((ma) => ma.panels.filter((p) => p.enabled).length)
    .reduce(sum, 0);

const landscapePerModule = (count) => count > 6 ? count : 0;

const dynamicModeLandscapePanelCount = (design) =>
  design.moduleArrays
    .filter((ma) => ma.pitch >= FLAT_ROOF_THRESHOLD)
    .map((ma) => landscapePerModule(ma.panels.filter((p, pIndex) => p.enabled
      && isLandscape(design, ma.roofPlaneNumber, ma.azimuth, pIndex)).length))
    .reduce(sum, 0);

const dynamicModeIslandPanelCount = (design) =>
  design.moduleArrays
    .map((ma, mIndex) => ma.panels.filter((p, pIndex) => p.enabled
      && isIsland(design, ma.roofPlaneNumber, mIndex, pIndex)).length)
    .reduce(sum, 0);

export const isManual = (design) => get(design, 'designType') === 'manual';

export const enabledArrayCount = (design) =>
  (isManual(design) ?
    design.moduleArrays.length :
    design.moduleArrays.filter(({ panels }) => panels.some((p) => p.enabled)).length);

export const enabledPanelCount = (design) => (
  isManual(design) ?
    manualModePanelCount(design) :
    dynamicModePanelCount(design)
);

export const landscapePanelCount = (design) => (
  isManual(design) ? 0 : dynamicModeLandscapePanelCount(design)
);

export const islandPanelCount = (design) => (
  isManual(design) ? 0 : dynamicModeIslandPanelCount(design)
);

export const pitchConditionPanelCount = (design, condition) => (
  isManual(design) ?
    manualModePitchConditionPanelCount(design, condition) :
    dynamicModePitchConditionPanelCount(design, condition)
);

export const activeEnabledPanelCount = (design) => (
  design.activeModule ?
    design.moduleArrays[design.activeModule - 1].panels.filter((p) => p.enabled).length : 0
);

export const estimateAnnualEnergyProduction = (quote, design) => {
  const { pvModuleItemId } = quote;

  return design.moduleArrays
    .map((ma) => {
      const hasMonthlyEnergyProduction = ma.monthlyEnergyProduction
        && ma.monthlyEnergyProduction[pvModuleItemId];
      const maxProduction = hasMonthlyEnergyProduction ?
        ma.monthlyEnergyProduction[pvModuleItemId].reduce(sum, 0)
        : 0;
      const enabledPanels = isManual(design) ?
        ma.panelCount :
        ma.panels.filter((p) => p.enabled).length;
      return maxProduction * (enabledPanels / ma.panelCount);
    })
    .reduce(sum, 0);
};

export const systemCapacityWatts = (design, pvModule) => {
  const panelPowerValue = Number(pvModule.item.itemCategory);
  if (!panelPowerValue || isNaN(panelPowerValue)) return 0;
  return enabledPanelCount(design) * panelPowerValue;
};

export const systemPtcAcCapacityWatts = (design, inverterUnits, pvModule) => {
  const { cecPtcRating } = inverterUnits[0];
  return systemCapacityWatts(design, pvModule) * (cecPtcRating / 100);
};

export const acSystemCapacityAcWatts = (design, inverterUnits) => {
  const { wattage } = inverterUnits[0];
  return enabledPanelCount(design) * wattage;
};

export const dcSystemCapacityAcWatts = (selectedInverterUnits) => {
  return (selectedInverterUnits || []).reduce((previous, { wattage }) => previous + wattage, 0);
};

export const systemPtcDcCapacityWatts = (design, selectedInverterItems, pvModule) => {
  const panelPowerValue = Number(pvModule.item.itemCategory);
  if (!panelPowerValue || isNaN(panelPowerValue)) return 0;
  return design.moduleArrays.reduce((previous, moduleArray) => {
    const { panelCount } = moduleArray;
    const selectedInverter = design.selectedInverters.find((selectedInverter_) =>
      selectedInverter_.id === moduleArray.selectedInverterId
    );
    const selectedInverterItem = selectedInverterItems.find((item) =>
      selectedInverter.inverterItemId === item.itemId
    );
    const { cecPtcRating } = selectedInverterItem;
    return previous + panelPowerValue * panelCount * (cecPtcRating / 100);
  }, 0);
};

// FIXME: This calculation is currently based off of module capacity
// and PTC rating. Need to update it to use inverter capacity rating.
export const systemCapacityAcWatts = (design, pvModule, selectedInverterItems, inverterUnits) => {
  if (pvModule.dc) {
    return dcSystemCapacityAcWatts(selectedInverterItems);
  }
  return acSystemCapacityAcWatts(design, inverterUnits);
};

export const systemPtcCapacityWatts = (design, pvModule, selectedInverterItems, inverterUnits) => {
  const panelPowerValue = Number(pvModule.item.itemCategory);
  if (!panelPowerValue || isNaN(panelPowerValue)) return 0;
  if (pvModule.dc) {
    return systemPtcDcCapacityWatts(design, selectedInverterItems, pvModule);
  }
  return systemPtcAcCapacityWatts(design, inverterUnits, pvModule);
};

export const separateStructures = (design) =>
  uniq(compact(design.moduleArrays
    .map((ma) => ma.panels.some((p) => p.enabled) && ma.structure))).length - 1;

export const roofPlanes = (design) =>
  compact(design.moduleArrays
    .map((ma) => ma.panels.some((p) => p.enabled))).length;

export const isDc = (pvModule) => get(pvModule, 'dc', true);

export const numberOfInverters = (quote) => {
  if (quote.storageQuantity === 1) return 1;
  if (quote.storageQuantity === 2) {
    if (quote.storageBackup === 'entireHome') return 2;
    if (quote.storageBackup === 'essential') return 1;
  }
  return 0;
};

export const INVERTER_QUANTITY_STORAGE_BACKUP_MAPPINGS = {
  1: 'essential',
  2: 'entireHome'
};

export const getPvModuleDeatils = (model, pvModuleDetails) => {
  const details = pvModuleDetails.find((module) => module.model === model);

  if (details) return details;
  throw new Error('Module details do not exist.');
};

export const getPvModuleSeriesDeatils = (series, pvModuleSeriesDetails) => {
  const details = pvModuleSeriesDetails.find((d) => d.series.toLowerCase() === series.toLowerCase());
  if (details) return details;
  throw new Error('Module series details do not exist.');
};

export const pvModuleDetailBoxImgUrl = (selectedPvModule, pvModuleDetails) => {
  const moduleDetails = getPvModuleDeatils(selectedPvModule.item.model, pvModuleDetails);
  return moduleDetails.detailImg;
};

export const pvS6MonitoringInverterName = (series, pvModuleSeriesDetails) => {
  const moduleSeriesDetails = getPvModuleSeriesDeatils(series, pvModuleSeriesDetails);
  return moduleSeriesDetails.inverterName;
};

export const getDegradation = (moduleSelected) => {
  let degradationRate = 0.00;
  if (moduleSelected && moduleSelected.manufacturer && moduleSelected.manufacturer.toLowerCase() !== 'sunpower') {
    degradationRate = 0.02;
  }
  return (1 - degradationRate);
};
