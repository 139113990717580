import React from 'react';
import PropTypes from 'prop-types';
import { formatCurrency } from '../../utils/formatNumber';

export function Notification({
  cost,
  header,
  body,
  showNegativeCost
}) {
  let classesForNotification = 'optimization-notification-cost';

  if (showNegativeCost) {
    classesForNotification += ' optimization-notification-discount-color';
  } else {
    classesForNotification += ' optimization-notification-cost-color';
  }
  return (
    <div className="optimization-notification">
      <div className="optimization-notification-header">
        { header }
        { cost > 0 && <span className={classesForNotification}>
          { showNegativeCost ? '-' : '+'} { formatCurrency(cost) }</span>
        }
      </div>
      <div className="optimization-notification-message">
        { body }
      </div>
    </div>
  );
}

Notification.propTypes = {
  cost: PropTypes.number.isRequired,
  header: PropTypes.string.isRequired,
  body: PropTypes.string,
  showNegativeCost: PropTypes.bool
};

export default Notification;
