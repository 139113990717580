import React from 'react';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { register } from '../../../utils/redux';
import {
  quoteType,
  dropdownsType,
  pvModuleType,
  selectedEvChargerConfigurationType,
  settingsType
} from '../../../types';
import { tagList } from '../../../utils/pvModuleFiltersAndTags';
import { getEvOutletAllowedQuantityList, getEvOutletList } from '../../../utils/EvChargers/evChargers';
import { truncateString } from '../../../utils/common';
import { paymentOptionForEvOutlet } from '../../../utils/calculations';

const defaultContainerClasses = 'input-container input-container--icon icon-right dropdown-with-tags';

export function EvOutletListDropDown({
  pvModule,
  selectedEvChargerConfiguration,
  dropdowns: { isEvOutletListOpen },
  onSelect,
  toggleEvOutletListDropdown,
  settings,
  quote
}) {
  const selectedEvOutletConfigName = get(selectedEvChargerConfiguration, 'evOutletConfigName', null);
  const selectedEvOutletConfigId = get(selectedEvChargerConfiguration, 'evOutletConfigId', null);
  const selectedEvOutletId = get(selectedEvChargerConfiguration, 'evOutletId', null);
  const selectedEvOutletQuantity = get(selectedEvChargerConfiguration, 'evOutletQuantity',
    getEvOutletAllowedQuantityList()[0]);
  const selectedEvChargerQuantity = get(selectedEvChargerConfiguration, 'evChargerQuantity', null);
  const evOutletList = selectedEvChargerQuantity === 2 ? [] : getEvOutletList(pvModule.evmChargerConfigurations);
  const options = (<ul>
    <li
      className={cx('cs-option-container',
        { 'cs-selected': !selectedEvOutletConfigId })}
      onClick={() => {
        toggleEvOutletListDropdown();
        onSelect(selectedEvOutletId, null);
      }}
      key={`list${0}`}
    >
      <span className="cs-option">
        <div className="cs-option-name">None</div>
      </span>
    </li>
    {evOutletList.map((option) => {
      return (
        <li
          className={cx('cs-option-container',
            { 'cs-selected': option.configId === selectedEvOutletConfigId })}
          onClick={() => {
            toggleEvOutletListDropdown();
            onSelect(selectedEvOutletId, option.configId, option.configName, selectedEvOutletQuantity);
          }}
          key={`list${option}`}
        >
          <span className="cs-option">
            <div className="cs-option-name" data-tip={option.configName}>{truncateString(option.configName, 15)}</div>
            {tagList(paymentOptionForEvOutlet(pvModule.evmChargerConfigurations, option.configId), settings)}
          </span>
        </li>
      );
    })}
  </ul>);

  return evOutletList.length === 0 ? (
    <div
      id="ev_charger_dropdown"
      className={defaultContainerClasses}
    >
      <input type="text" value="None" disabled={true} />
    </div>
  ) : (
    <div
      id="ev_charger_dropdown"
      className={cx(defaultContainerClasses, { 'is-disabled': quote.locked })}
    >
      <i className="icon icon-chevron-down" />
      <div
        className={cx('select-dropdown cs-skin-border', { 'cs-active': isEvOutletListOpen })}
        tabIndex="0"
      >
        <span className="cs-placeholder" onClick={toggleEvOutletListDropdown}>
          <div className="cs-option-name" data-tip={selectedEvOutletConfigName}>
            {truncateString(selectedEvOutletConfigName, 15) || 'None'}
          </div>
          {tagList(paymentOptionForEvOutlet(pvModule.evmChargerConfigurations, selectedEvOutletConfigId), settings)}
        </span>
        <div className="cs-options">
          {options}
        </div>
      </div>
    </div>
  );
}

EvOutletListDropDown.propTypes = {
  pvModule: pvModuleType.isRequired,
  selectedEvChargerConfiguration: selectedEvChargerConfigurationType,
  dropdowns: dropdownsType.isRequired,
  quote: quoteType.isRequired,
  onSelect: PropTypes.func.isRequired,
  toggleEvOutletListDropdown: PropTypes.func.isRequired,
  settings: settingsType.isRequired
};

export default register(
  [
    'pvModuleSelector',
    'dropdownsSelector',
    'selectedEvChargerConfigurationSelector',
    'quoteSelector',
    'accountSelector',
    'settingsSelector'
  ],
  ['toggleEvOutletListDropdown'],
  EvOutletListDropDown
);

