import React from 'react';
import PropTypes from 'prop-types';
import ReactTooltip from 'react-tooltip';

import { register } from '../../utils/redux';

import { errorType, messagesType } from '../../types';
import MessagesOverlay from './MessagesOverlay';
import ErrorOverlay from './ErrorOverlay';
import Navigation from './Navigation';

export function BaseContainer({ error, messages, children }) {
  const showError = error && (!error.display || error.display === 'both' || error.display === 'modal');

  return (
    <div id="admin-portal-container" className="admin-portal-container">
      <Navigation />
      { children }
      { showError && <ErrorOverlay /> }
      { messages && messages.length > 0 && <MessagesOverlay /> }
      <ReactTooltip html={true} />
    </div>
  );
}

BaseContainer.propTypes = {
  children: PropTypes.element.isRequired,
  error: errorType,
  messages: messagesType
};

BaseContainer.defaultProps = {
  modals: {}
};

export default register(
  [ 'errorSelector', 'messagesSelector' ],
  [],
  BaseContainer
);
