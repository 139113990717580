import get from 'lodash/get';
import { datadogTrackLoadingTime } from '../utils/datadogUserMonitoring';
import { asyncPayload } from './apiHelpers';
import Api from '../utils/Api';
import { fetchGraphs } from './fetchGraphs';
import constants from '../utils/EddieCalculations/constants';

const {
  LEASE_FINANCE_TYPE
} = constants;

export const updateLease = ({ quote, data }) => (dispatch, getState) => {
  const startTime = Date.now();

  const currentState = getState();

  const existingSavingsChart = currentState.savingsChart;
  const existingTotalSavingsChart = currentState.totalSavingsChart;
  const initialSavingsChart = {
    0: {
      [LEASE_FINANCE_TYPE]: {
        legend: null,
        labels: {},
        metaSeries: {},
        graphSeries: {}
      }
    },
    2: {
      [LEASE_FINANCE_TYPE]: {
        legend: null,
        labels: {},
        metaSeries: {},
        graphSeries: {}
      }
    },
    4: {
      [LEASE_FINANCE_TYPE]: {
        legend: null,
        labels: {},
        metaSeries: {},
        graphSeries: {}
      }
    }
  };

  const beforeRequestState = () => {
    return {
      savingsChart: initialSavingsChart,
      totalSavingsChart: null,
      updatingLease: true
    };
  };

  const afterRequestState = () => {
    return {
      updatingLease: false
    };
  };

  const errorRequestState = () => {
    return {
      savingsChart: existingSavingsChart,
      totalSavingsChart: existingTotalSavingsChart,
      updatingLease: false
    };
  };

  const account = currentState.account;
  const channel = get(account, 'partner.channel');

  const {
    downPayment,
    paymentEscalator,
    fullPrepayment
  } = data;
  const payload = {
    down_payment: downPayment,
    payment_escalator: paymentEscalator,
    full_prepayment: fullPrepayment
  };

  dispatch({
    type: 'updateLease',
    payload: asyncPayload({
      request: Api.update(
        `quotes/${quote.sfid}/settings/update_lease?account_sfid=${account.sfid}`,
        payload
      )
      .then((result) => {
        dispatch(fetchGraphs(quote.sfid));

        const loadingTime = Date.now() - startTime;
        datadogTrackLoadingTime(
          `Save ${LEASE_FINANCE_TYPE.toUpperCase()} Attributes`,
          loadingTime,
          quote,
          currentState.settings,
          channel
        );

        return result;
      }),
      beforeRequest: beforeRequestState(),
      afterRequest: afterRequestState(),
      errorRequest: errorRequestState()
    })
  });
};
