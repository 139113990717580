import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { accountType, designType } from '../types';
import { updateMatch, resetArrayPairings, rePairMaxfitDesign } from '../actions';

import Visualization from './Visualization';
import SolarTable from './SolarTable';

function renderButtons(hasPaired, isPairingFinished, onReset, onSave) {
  return (
    <div className="match-page__submissions-container">
      <button className="reset btn btn-secondary btn--small" disabled={!hasPaired} onClick={onReset}>
        Reset
      </button>
      <button className="save btn btn-primary btn--small" disabled={!isPairingFinished} onClick={onSave}>
        Done
      </button>
    </div>
  );
}

function renderAlertMessage(message, level = 'info') {
  return (
    <p className={`alert alert--${level}`}>{message}</p>
  );
}

export function MatchPage({
  design, onSave, onReset, onRePairMaxfitDesign,
  maxfitDesignHasQuotes
}) {
  const arrayPairing = design.moduleArrays.map((ma) => ma.roofPlaneNumber).filter((n) => Number.isInteger(n));

  return (
    <div id="main-content">
      <div className="match-page" style={{ padding: '4rem' }}>
        <div className="column">
          <Visualization roof={design.roof} />
        </div>

        <div className="column">
          {design.paired && renderAlertMessage('This MaxFit Design is Locked and Read Only')}
          {(design.paired && maxfitDesignHasQuotes) &&
            renderAlertMessage(`This MaxFit Design has already been used to create Quotes. This Roof Design
              cannot be re-paired. Please create a new MaxFit Design.`,
              'danger'
            )
          }

          <SolarTable />

          {(!design.paired) &&
            renderButtons(
              arrayPairing.length > 0,
              arrayPairing.length === design.moduleArrays.length,
              onReset,
              () => onSave(design.maxfitSfid, design)
            )
          }
          {(design.paired && !maxfitDesignHasQuotes) &&
            <div className="match-page__submissions-container">
              <button className="re-pair btn btn-primary btn--small" onClick={onRePairMaxfitDesign}>
                Re-Pair
              </button>
            </div>
          }
        </div>
      </div>
    </div>
  );
}

export default connect(
  (state) => state,
  (dispatch) => {
    return {
      onReset: () => dispatch(resetArrayPairings()),
      onSave: (designId, design) => dispatch(updateMatch(designId, design)),
      onRePairMaxfitDesign: () => dispatch(rePairMaxfitDesign())
    };
  }
)(MatchPage);

MatchPage.propTypes = {
  account: accountType.isRequired,
  design: designType.isRequired,
  onSave: PropTypes.func,
  onReset: PropTypes.func,
  onRePairMaxfitDesign: PropTypes.func.isRequired,
  maxfitDesignHasQuotes: PropTypes.bool.isRequired
};
