import React from 'react';
import PropTypes from 'prop-types';
import { register } from '../utils/redux';
import svgPath from '../utils/svgPath';
import getAngle from '../utils/getAngle';
import {
  colorType,
  designType,
  modeType,
  pairedModuleArrayType,
  pathType,
  pvModuleType,
  quoteType,
  settingsType,
  visualizationType
} from '../types';
import {
  MODE_CHANGE_SLIDER,
  MODE_CHANGE_PANELS,
  MODE_CHANGE_ARRAYS,
  BASIC_VIEW,
  DETAILED_VIEW,
  moduleOffOpacity
} from '../config';
import { isPvModuleBlack } from './PanelTypesModal';

function panelOpacity({ enabled }, mode, viewMode) {
  return enabled ? 1 :
    mode === MODE_CHANGE_PANELS || mode === MODE_CHANGE_ARRAYS ||
    mode === MODE_CHANGE_SLIDER || viewMode === DETAILED_VIEW ?
      moduleOffOpacity :
      0;
}

function panelFill(mode, viewMode, fillColor) {
  return mode === MODE_CHANGE_PANELS || mode === MODE_CHANGE_ARRAYS ||
  mode === MODE_CHANGE_SLIDER || viewMode === DETAILED_VIEW ?
    fillColor :
    'url(#panel-pattern)';
}

function panelAngle(visualization) {
  if (visualization) {
    const roofPlane = visualization.roofPlanes.find((plane) => plane.moduleArray && plane.moduleArray.panels);
    const coordinates = roofPlane ? roofPlane.moduleArray.panels[0].coordinates : [];
    // if valid panel then coordinates.length === 5
    return coordinates.length === 5 ? Math.round(getAngle(coordinates[1], coordinates[2])) : null;
  }
  return null;
}

export function DesignPanel({ panel, strokeOpacity, fillColor, mode, viewMode, togglePanel }) {
  return (
    <path
      className="panel"
      d={svgPath(panel.coordinates)}
      strokeWidth="2"
      strokeOpacity={strokeOpacity}
      fill={panelFill(mode, viewMode, fillColor)}
      fillOpacity={panelOpacity(panel, mode, viewMode)}
      onClick={togglePanel}
    />
  );
}
DesignPanel.propTypes = {
  panel: PropTypes.shape({
    coordinates: pathType.isRequired,
    enabled: PropTypes.bool.isRequired
  }),
  strokeOpacity: PropTypes.string.isRequired,
  fillColor: colorType,
  mode: modeType,
  viewMode: PropTypes.oneOf([
    BASIC_VIEW,
    DETAILED_VIEW
  ]),
  togglePanel: PropTypes.func.isRequired
};

export function DesignModuleArray({ moduleArray, mode, design, viewMode,
  toggleRoof, togglePanel, toggleArray, interactive }) {
  const strokeOpacity = design && design.isInstant ? '0.3' : '1';
  const { arrayNumber } = moduleArray;
  const interactiveMode = (typeof interactive === 'undefined') || interactive;
  const enableRoofToggle = (viewMode === DETAILED_VIEW && design.activeModule !== moduleArray.arrayNumber);
  const enableArrayToggle = interactiveMode && (mode === MODE_CHANGE_ARRAYS && viewMode !== DETAILED_VIEW);
  const enablePanelToggle = interactiveMode && ((mode === MODE_CHANGE_PANELS && viewMode !== DETAILED_VIEW) ||
    (viewMode === DETAILED_VIEW && mode === MODE_CHANGE_PANELS && design.activeModule === moduleArray.arrayNumber)
    || mode === MODE_CHANGE_SLIDER);
  return (
    <g
      className="module-array" onClick={() => {
        if (enableArrayToggle) {
          toggleArray({
            arrayNumber
          });
        }
        if (enableRoofToggle) {
          toggleRoof({
            arrayNumber
          });
        }
      }}
    >
      {moduleArray.panels.map((panel, panelNumber) =>
        (
          <DesignPanel
            key={panelNumber}
            fillColor={moduleArray.fillColor}
            strokeOpacity={strokeOpacity}
            panel={panel}
            mode={mode}
            viewMode={viewMode}
            togglePanel={() => enablePanelToggle && togglePanel({
              arrayNumber,
              panelNumber
            })}
          />
        )
      )}
    </g>
  );
}
DesignModuleArray.propTypes = {
  moduleArray: pairedModuleArrayType.isRequired,
  mode: modeType,
  viewMode: PropTypes.oneOf([
    BASIC_VIEW,
    DETAILED_VIEW
  ]),
  design: designType,
  quote: quoteType,
  settings: settingsType,
  toggleRoof: PropTypes.func.isRequired,
  togglePanel: PropTypes.func.isRequired,
  toggleArray: PropTypes.func.isRequired,
  interactive: PropTypes.bool
};

export function DesignRoofPlane({
  quote,
  settings,
  roofPlane,
  design,
  viewMode,
  toggleRoof,
  ...restProps
}) {
  const enableRoofToggle = (roofPlane.moduleArray && viewMode === DETAILED_VIEW);
  const stroke = (enableRoofToggle && design.activeModule === roofPlane.moduleArray.arrayNumber) ?
    (<path id="active-path" d={svgPath(roofPlane.coordinates)} stroke="#F6DF1A" strokeWidth="4" fill="none" />) : '';
  const opacity = design && design.isInstant ? '0' : '1';

  return (
    <g className="roof-plane" stroke={roofPlane.fillColor}>
      <path
        d={svgPath(roofPlane.coordinates)} stroke="#FFF" strokeWidth="2" opacity={opacity}
        fill={roofPlane.fillColor} onClick={() => enableRoofToggle && toggleRoof({
          arrayNumber: roofPlane.moduleArray.arrayNumber
        })}
      />
      {stroke}
      {roofPlane.moduleArray && (
        <DesignModuleArray
          moduleArray={roofPlane.moduleArray}
          design={design}
          viewMode={viewMode}
          toggleRoof={toggleRoof}
          quote={quote}
          settings={settings}
          {...restProps}
        />
      )}
    </g>
  );
}
DesignRoofPlane.propTypes = {
  roofPlane: PropTypes.shape({
    coordinates: pathType.isRequired,
    moduleArray: pairedModuleArrayType
  }).isRequired,
  interactive: PropTypes.bool,
  quote: quoteType,
  settings: settingsType,
  design: designType.isRequired,
  viewMode: PropTypes.oneOf([
    BASIC_VIEW,
    DETAILED_VIEW
  ]),
  toggleRoof: PropTypes.func.isRequired
};

export function PanelPatternFill({ pvModule, scale = 1, angle = 24 }) {
  const isPatternPlain = isPvModuleBlack(pvModule);
  const patternSize = Math.round(5 * scale);
  const dotSize = Math.round(1 * scale);

  return (
    <defs>
      <pattern
        id="panel-pattern"
        x="0"
        y="0"
        width={patternSize}
        height={patternSize}
        patternUnits="userSpaceOnUse"
        patternTransform={`rotate(${angle})`}
      >
        <rect x="0" y="0" width={patternSize} height={patternSize} fill="#131832" />
        {
          isPatternPlain ?
            null :
            <rect x="0" y="0" width={dotSize} height={dotSize} fill="#ffffff" className="panel-pattern-dot" />
        }
      </pattern>
    </defs>
  );
}
PanelPatternFill.propTypes = {
  pvModule: pvModuleType,
  scale: PropTypes.number,
  angle: PropTypes.number
};

export function DesignVisualization({
  design,
  visualization,
  mode,
  viewMode,
  pvModule,
  instantQuoteMode,
  quote,
  settings,
  ...restProps
}) {
  const style = design.isInstant === true && design.satelliteImgUrl ? {
    backgroundImage: `url(${instantQuoteMode ? design.iqSatelliteImgUrl : design.satelliteImgUrl})`,
    backgroundSize: 'cover'
  } : {};
  const { width, height } = visualization.dimensions;
  const { svgHeight, svgWidth } = design.isInstant ?
    { svgHeight: 500, svgWidth: 500 } : restProps;
  const viewBox = `0 0 ${width} ${height}`;
  const patternScale = width / (svgWidth || 570);
  const angle = panelAngle(visualization);
  if (restProps.proposalView) {
    viewMode = BASIC_VIEW;
  }
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className="visualization"
      width={svgWidth || '80%'}
      height={svgHeight || '80%'}
      viewBox={viewBox}
      preserveAspectRatio="xMidYMin meet"
      style={style}
    >
      <PanelPatternFill pvModule={pvModule} scale={patternScale} angle={angle} />
      {visualization.roofPlanes.map((roofPlane, index) =>
        <DesignRoofPlane
          key={index}
          roofPlane={roofPlane}
          design={design}
          mode={mode}
          viewMode={viewMode}
          quote={quote}
          settings={settings}
          {...restProps}
        />
      )}
      <use href="#active-path" />
    </svg>
  );
}

DesignVisualization.propTypes = {
  design: designType.isRequired,
  visualization: visualizationType.isRequired,
  mode: modeType,
  quote: quoteType,
  settings: settingsType,
  viewMode: PropTypes.oneOf([
    BASIC_VIEW,
    DETAILED_VIEW
  ]),
  pvModule: pvModuleType,
  instantQuoteMode: PropTypes.bool,
  interactive: PropTypes.bool
};

export default register(
  [ 'designSelector', 'visualizationSelector', 'modeSelector', 'viewModeSelector', 'pvModuleSelector',
    'instantQuoteModeSelector', 'quoteSelector', 'settingsSelector' ],
  [ 'togglePanel', 'toggleArray', 'toggleRoof' ],
  DesignVisualization
);
