import React, { useState, useEffect } from 'react'; import html2canvas from 'html2canvas';
import { jsPDF } from 'jspdf';
import { get } from 'lodash';
import { saveAs } from 'file-saver';
import PropTypes from 'prop-types';
import { routerShape, withRouter } from 'react-router';
import cx from 'classnames';
import { register } from '../../utils/redux';
import NavLink from './NavLink';
import { SALESFORCE_INSTANCE_URL } from '../../config';
import {
  accountType,
  evChargerConfigurationType,
  proposalType,
  storageQuoteType,
  csatFeedbackType,
  userDataType,
  modalsType,
  productConfigurationType
 } from '../../types';
import { SettingsModal } from './modals/settingsModal';
import { storageOnlyDynamicProposalEnabled, enableDownloadDPPDF } from '../../utils/backendFeatureFlags';
import store from './../../store';
import { isPDFProcessing } from '../../actions';
import { gaTrackEvent } from '../../utils/googleAnalytics';
import { datadogTrackLoadingTime } from '../../utils/datadogUserMonitoring';
import { getDrafSessionInLocalStorage } from '../../utils/common';
import constants from '../../utils/EddieCalculations/constants';
import dynamicPdfText from '../../utils/dynamicPdfText';
import FeedbackModal from '../FeedbackModal';
import FeedbackModalBar from '../FeedbackModalBar';
import Api from '../../utils/Api';

const {
  HIDDEN,
  COMPLETED
} = constants;

const {
  VIEW_WEB_VERSION
} = dynamicPdfText;

function GearButton({ storageQuote, updateStorageQuote, setError,
   evChargerConfigurations, partnerIsRvar, toggleModal, modals }) {
  const closeModal = () => {
    toggleModal('', '');
  };

  const onClick = (e) => {
    e.preventDefault();
    toggleModal('settings', e.target.parentElement);
  };

  return (
    <div className="nav-action-icon storage-only-nav-action-icon">
      <a
        id="settingModalToggleGearButton"
        href="#"
        className="image-icon image-icon-navigation-gear"
        onClick={(e) => {
          onClick(e);
        }}
      />
      { modals.name === 'settings' &&
      <SettingsModal
        modalCoordinates={modals.coordinates}
        closeModal={closeModal}
        storageQuote={storageQuote}
        updateStorageQuote={updateStorageQuote}
        setError={setError}
        evChargerConfigurations={evChargerConfigurations}
        partnerIsRvar={partnerIsRvar}
      />
      }
    </div>
  );
}

GearButton.propTypes = {
  storageQuote: storageQuoteType.isRequired,
  updateStorageQuote: PropTypes.func,
  setError: PropTypes.func,
  evChargerConfigurations: PropTypes.arrayOf(evChargerConfigurationType).isRequired,
  toggleModal: PropTypes.func.isRequired,
  modals: modalsType,
  partnerIsRvar: PropTypes.bool.isRequired
};

export function Navigation({
                             alwaysOnFront, router, params, account, csatFeedback, saveFeedback, toggleModal,
                             currentTabName, returnURL, storageQuote, updateStorageQuote, setError, modals,
                             evChargerConfigurations, proposal, generateStorageQuoteProposalFile, userData,
                             partnerIsRvar, partnerIsNewHomes
                           }) {
  const isProposalTab = currentTabName === 'PROPOSAL';
  const isDynamicProposalTab = isProposalTab && storageOnlyDynamicProposalEnabled();
  const backUrl = returnURL || `${SALESFORCE_INSTANCE_URL}/${account.sfid}`;

  const [ download, setDownload ] = useState(false);
  const [ loadButton, setLoadButton ] = useState(false);
  const welcomeSection = document.getElementById('welcome');

  if (isProposalTab && storageQuote.sfid && !proposal.pdfUrl) {
    generateStorageQuoteProposalFile(storageQuote, 'pdf');
  }

  const proposalDownloadLinkProps = { target: '_blank', rel: 'noopener noreferrer' };
  const proposalDownloadUrl = isDynamicProposalTab && proposal ? proposal.pdfUrl : '';

  const trackProposalDownloadInDD = () => {
    datadogTrackLoadingTime(
      'Download PDF Proposal',
      null,
      storageQuote,
      null,
      null,
      null
    );
  };

  const uploadProposalPdf = (file) => {
    const formData = new FormData();
    formData.append('file', file);
    const requestPath = `storage/quotes/${storageQuote.sfid}/upload_pdf_s3`;
    Api.postFile(requestPath, formData);
  };

  const isPdfProcessing = get(account, 'isPDFProcessing');
  const dealerName = get(account, 'partner.name');
  const contractorLicenseNumber = get(storageQuote, 'contractorLicenseNumber');

  const downloadDpPdf = () => {
    return !!(enableDownloadDPPDF() && (storageQuote.version >= 7) && !partnerIsNewHomes && isDynamicProposalTab);
  };

  const downloadFile = async (argVal = false) => {
    if (downloadDpPdf()) {
      store.dispatch(isPDFProcessing(true));

      const userName = account.name.split(' ').join('_');
      const fileName = `Proposal_${storageQuote.sfid}.pdf`;
      const clientHeight = 1800;
      const clientWidth = document.body.clientWidth;
      const ECFirstName = get(userData, 'firstName');
      const ECLastName = get(userData, 'lastName');
      const ECEmail = get(userData, 'email');
      const ECPhone = get(userData, 'phone');
      const ECAccount = get(userData, 'accountName');

      const svgElements = document.body.querySelectorAll('svg');
      svgElements.forEach((item) => {
        item.setAttribute('width', item.getBoundingClientRect().width);
        item.setAttribute('height', item.getBoundingClientRect().height);
        item.style.width = null;
        item.style.height = null;
      });

      const ViewWebVerisonNode = document.createElement('div');
      ViewWebVerisonNode.style = `
        color: #404040;
        text-align: center;
        display: flex;
        justify-content: space-arround;
      `;
      ViewWebVerisonNode.innerHTML = `</br></br></br>
      <h4 style="font-weight: bold; padding:0;margin:0">${VIEW_WEB_VERSION}</h4>`;

      // WelcomePage
      const welcomeNode = document.getElementById('welcome');
      html2canvas(welcomeNode, {
        allowTaint: true,
        logging: true,
        useCORS: true,
        onclone(documentClone) {
          /* eslint-disable max-len */
          documentClone.querySelector('#welcome>:first-child>:first-child').style.paddingTop = '50px';
          const addressNode = document.createElement('div');
          const preparedFor = document.createElement('div');
          const preparedBy = document.createElement('div');
          addressNode.style = `
            display: flex;
            color: #ffffff;
            justify-content: space-between;
            width: 70%;
            font-size: 20px;
          `;

          preparedFor.innerHTML =
          `<h3> PREPARED FOR </h3>
           <h3 style="font-weight: bold; padding:0;margin:0 0 5px 0">${account.name}</h3>
           ${account.street}</br>
           ${account.state} ${account.city} ${account.postalCode}</br>
          `;

          preparedBy.innerHTML =
          `<h3> PREPARED BY </h3>
          <h3 style="font-weight: bold; padding:0;margin:0 0 5px 0">
          ${ECFirstName} ${ECLastName}
          </h3>`;

          if (ECAccount) {
            const account = document.createElement('div');
            account.innerHTML = `${ECAccount}`;
            preparedBy.appendChild(account);
          }

          if (ECEmail) {
            const email = document.createElement('div');
            email.innerHTML = `${ECEmail}`;
            preparedBy.appendChild(email);
          }

          if (ECPhone) {
            const phone = document.createElement('div');
            phone.innerHTML = `${ECPhone}`;
            preparedBy.appendChild(phone);
          }
          preparedBy.append(`Contractor License Number: ${contractorLicenseNumber}`);
          addressNode.appendChild(preparedFor);
          addressNode.appendChild(preparedBy);
          documentClone.querySelector('#welcome>div>div:last-child>div:last-child>div:last-child').remove();
          documentClone.querySelector('#welcome>div>div:first-child').append(addressNode);
          ViewWebVerisonNode.style = 'margin: 100px 0 0 150px;';
          documentClone.querySelector('#welcome>div>div:first-child').append(ViewWebVerisonNode);
        }
      }).then(async (welcomeCanvas) => {
        const systemDetailsNode = document.getElementById('storageDetails');
        html2canvas(systemDetailsNode, { allowTaint: false,
          logging: true,
          useCORS: true
        }).then(async (systemDetailsCanvas) => {
          const financeNode = document.getElementById('finance');
          html2canvas(financeNode, { allowTaint: false,
            logging: true,
            useCORS: true,
            onclone(documentClone) {
              const divsFinance = documentClone.querySelectorAll('div');
              divsFinance.forEach((item) => {
                item.style.boxShadow = 'none';
              });
              /* eslint-disable max-len */
              ViewWebVerisonNode.style = 'margin: 100px 0 0 250px;';
              documentClone.querySelector('#finance:last-child').append(ViewWebVerisonNode);
            }
          }).then(async (financeCanvas) => {
            const pdf = new jsPDF({
              orientation: 'portrait',
              unit: 'pt',
              format: [ clientWidth, clientHeight ]
            });
            pdf.setFontSize(20);
            pdf.addImage(welcomeCanvas, 'JPEG', 0, 0, clientWidth, clientHeight / 2.05);
            pdf.addImage(systemDetailsCanvas, 'JPEG', 0, clientHeight / 2, clientWidth, clientHeight / 2.5);
            pdf.addPage();
            pdf.addImage(financeCanvas, 'JPEG', 0, 0, clientWidth, 1500);

            const blob = pdf.output('blob');
            const file = new File([blob], fileName);

            if (argVal) {
              const downloadFileName = `${userName}.pdf`;
              pdf.save(downloadFileName);
            } else {
              uploadProposalPdf(file);
            }
            store.dispatch(isPDFProcessing(false));
            setLoadButton(true);
          });
        });
      });
    } else {
      setLoadButton(true);
      saveAs(proposal.pdfDataUrl, proposal.pdfFilename);
      trackProposalDownloadInDD();
    }
  };

  useEffect(() => {
    if (welcomeSection !== null) {
      if (downloadDpPdf() && storageQuote.dpS3Url === null) {
        downloadFile();
      } else {
        setLoadButton(true);
      }
    }
  }, [welcomeSection]);

  const containerClasses = cx('navigation-container', {
    'always-on-front': !!alwaysOnFront
  });

  const locked = storageQuote.locked;

  const closeButtonWithDivider = () => {
    return (
      <React.Fragment>
        <div className="nav-action-icon-separator" />
        <div className="nav-action-icon storage-only-nav-action-icon">
          <a
            href={backUrl}
            className="image-icon image-icon-navigation-close"
            data-tip="Close Quote"
            onClick={() => {
              return true;
            }}
          />
        </div>
      </React.Fragment>
    );
  };

  const csatFeedbackStatus = get(csatFeedback, 'status');
  const draftQuoteSession = getDrafSessionInLocalStorage();
  const showFeedbackModalLabel = storageQuote.version > 5
    && isDynamicProposalTab && storageQuote.cloneVariant !== 'amendment'
    && !(csatFeedbackStatus === COMPLETED || csatFeedbackStatus === HIDDEN) && draftQuoteSession;

  const closeFeedbackModalBar = () => {
    saveFeedback({
      quoteSfid: storageQuote.sfid,
      userId: userData.sfid,
      status: HIDDEN
    });
  };

  function showModal(name) {
    switch (name) {
      case 'feedback': { return <FeedbackModal storageQuote={storageQuote} />; }
      default: { return null; }
    }
  }

  return (
    <div className={containerClasses}>
      <nav className={cx('navigation', { new: true })}>
        <div className="brand-item">
          <div className="logo-item">
            <a href="#" className="sunpower-logo-black" />
          </div>
        </div>

        {params.quoteId && (
          <div className="nav-items-group">
            <NavLink
              router={router}
              to={`/storage/quote/${params.quoteId}/selection`}
              name="Storage"
            />
            <NavLink
              disabled={!locked}
              router={router}
              to={`/storage/quote/${params.quoteId}/proposal`}
              name="Proposal"
            />
          </div>
        )}

        <div className="nav-actions">
          <span className="supporting-text">{account.street}</span>
          {params.quoteId && !isProposalTab && (
            <React.Fragment>
              { GearButton({ storageQuote,
                updateStorageQuote,
                setError,
                evChargerConfigurations,
                partnerIsRvar,
                toggleModal,
                modals })}
              { closeButtonWithDivider() }
            </React.Fragment>
          )
          }
          { isProposalTab && (
          <div className={cx('nav-action-buttons', 'nav-action-button-container')}>
            {(download && isPdfProcessing) ?
                  (
                    <a
                      className="btn btn-primary nav-action-btn proposal-download-btn"
                      style={{ cursor: 'not-allowed' }}
                      {...proposalDownloadLinkProps}
                    >
                      <img
                        className="download-spinner"
                        src="/build/images/loading-spinner.svg"
                        alt="Loading Spinner"
                      />
                  Downloading
                    </a>
                  ) :
                  ((loadButton || partnerIsNewHomes) ?
                    (<a
                      className="btn btn-primary nav-action-btn proposal-download-btn"
                      {...proposalDownloadLinkProps}
                      onClick={() => {
                        setDownload(true);
                        downloadFile(true);
                      }}
                    >
                  Download
                </a>) :
                 (<a
                   className="btn btn-secondary nav-action-btn proposal-download-btn"
                   style={{ cursor: 'not-allowed' }}
                 >
                 Download
                </a>))
                }
            <div className="nav-action-icon">
              <a
                href={backUrl}
                className="nav-cancel-design-button image-icon image-icon-navigation-close"
                data-tip="Close Quote"
                onClick={() => {
                  gaTrackEvent('quotePage:navBar:close');
                  return true;
                }}
              />
            </div>
          </div>
            )
          }
        </div>
      </nav>
      {showFeedbackModalLabel &&
      <FeedbackModalBar
        closeFeedbackModalBar={closeFeedbackModalBar}
        toggleModal={toggleModal}
        quote={storageQuote}
      />}
      { modals.name && showModal(modals.name) }
    </div>
  );
}

Navigation.propTypes = {
  alwaysOnFront: PropTypes.bool,
  params: PropTypes.objectOf(PropTypes.string).isRequired,
  account: accountType.isRequired,
  router: routerShape.isRequired,
  currentTabName: PropTypes.string,
  returnURL: PropTypes.string,
  storageQuote: storageQuoteType.isRequired,
  updateStorageQuote: PropTypes.func,
  setError: PropTypes.func,
  proposal: proposalType,
  evChargerConfigurations: PropTypes.arrayOf(evChargerConfigurationType).isRequired,
  generateStorageQuoteProposalFile: PropTypes.func,
  csatFeedback: csatFeedbackType,
  saveFeedback: PropTypes.func.isRequired,
  toggleModal: PropTypes.func.isRequired,
  modals: modalsType,
  userData: userDataType,
  partnerIsRvar: PropTypes.bool.isRequired,
  partnerIsNewHomes: PropTypes.bool,
  productConfiguration: productConfigurationType.isRequired
};

export default register(
  [
    'accountSelector', 'designSelector', 'productConfigurationSelector', 'proposalSelector', 'userDataSelector',
    'currentTabNameSelector', 'csatFeedbackSelector', 'returnURLSelector', 'partnerIsNewHomesSelector',
    'storageQuoteSelector', 'evChargerConfigurationsSelector', 'modalsSelector', 'partnerIsRvarSelector',
    'modalsSelector'
  ],
  [ 'toggleModal', 'updateStorageQuote', 'setError', 'generateStorageQuoteProposalFile', 'saveFeedback' ],
  withRouter(Navigation)
);
