import React from 'react';
import PropTypes from 'prop-types';

export function InstantQuoteNoStorageSelected({ openStorageModal }) {
  return (
    <div className="no-storage-section">
      <div className="row">
        <p className="no-storage">No storage selected</p>
      </div>
      <div className="row last">
        <button
          id="add-storage-btn"
          className="btn-sunpower"
          onClick={openStorageModal}
        >
            Add Storage
        </button>
      </div>
    </div>
  );
}

InstantQuoteNoStorageSelected.propTypes = {
  openStorageModal: PropTypes.func
};
