export default ({ productConfiguration }) => {
  let isPartnerEnabledForAnyStorage;
  if (productConfiguration.storagePermission) {
    const storagePermissionValues = Object.values(productConfiguration.storagePermission);
    isPartnerEnabledForAnyStorage = storagePermissionValues.includes(true);
  } else {
    isPartnerEnabledForAnyStorage = false;
  }

  return ({
    isPartnerEnabledForAnyStorage
  });
};
