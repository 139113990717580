import get from 'lodash/get';
import fetch from 'isomorphic-fetch';
import { asyncPayloadWithNoExplicitUpdate } from './apiHelpers';
import { ApiError } from '../utils/Api';
import { updateLoanCalcValuesInQuote } from './updateLoanCalcValuesInQuote';

export const fetchFinEngLoanCalc = (data, quote, account, loanSystemCost, saveLoanCalcValuesInQuote = false) => {
  const path = '/loan_calculator';
  const {
    downPayment,
    contractPrice,
    prePaymentPercentage,
    apr,
    term,
    creditProductName
  } = data;
  const formattedApr = apr ? apr.toLowerCase().split(' ').join('_') : '';
  const termsInYears = term / 12;
  const loanStartDate = get(quote, 'loanStartDate');
  const dealerHic = !!get(quote, 'dealerHic');
  const loanBasePrice = get(quote, 'finEng.loan.loanBasePrice');
  const loanAdderFee = quote.cloneVariant === 'amendment' ? get(quote, 'finEng.loan.loanAdderFee') : null;
  const storageLoanAdderFee = quote.cloneVariant === 'amendment' ? get(quote, 'finEng.loan.storageLoanAdderFee') : null;
  const dealerHicAdderFee = quote.cloneVariant === 'amendment' ? get(quote, 'finEng.loan.dealerHicAdderFee') : null;
  const accountState = get(account, 'state');
  const utilityId = get(account, 'utilityId');
  const dealerId = get(account, 'partner.sfid');
  const pvModuleItemId = get(quote, 'pvModuleItemId');
  const channel = get(account, 'partner.channel');
  const largeSystemDiscount = get(loanSystemCost, 'largeSystemDiscount');
  const isV2State = account.state === 'NY' || account.state === 'CT'
  || account.state === 'IL' || account.state === 'OR';
  const stateAndLocalIncentives = isV2State ? get(loanSystemCost, 'stateAndLocalIncentives') : 0;
  let dataToSend = {
    down_payment: downPayment,
    loan_amount: contractPrice,
    prepayment_percent: prePaymentPercentage,
    product: formattedApr,
    send_amort_sch: false,
    term: termsInYears,
    credit_product_name: creditProductName,
    loan_start_date: loanStartDate,
    loan_base_price: loanBasePrice,
    state: accountState,
    utility: utilityId,
    dealer: dealerId,
    pv_model: pvModuleItemId,
    dealer_hic: dealerHic,
    state_rebate: stateAndLocalIncentives,
    large_system_discount: largeSystemDiscount,
    channel
  };

  if (loanAdderFee) {
    dataToSend = { ...dataToSend, loan_adder_fee: loanAdderFee };
  }
  if (storageLoanAdderFee) {
    dataToSend = { ...dataToSend, storage_loan_adder_fee: storageLoanAdderFee };
  }
  if (dealerHicAdderFee) {
    dataToSend = { ...dataToSend, dealer_hic_adder_fee: dealerHicAdderFee };
  }
  const requestData = {
    data: dataToSend
  };

  const promise = fetch(path, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(requestData)
  }).then(async (response) => {
    if (response.status === 200) {
      const jsonBody = await response.json();
      if (jsonBody.error) {
        throw new ApiError({
          status: jsonBody.status,
          message: jsonBody.error,
          errorClass: jsonBody.errorClass,
          options: jsonBody.options,
          backendStack: jsonBody.backendStack
        });
      }
      return jsonBody;
    }

    throw new ApiError({
      status: 'FAILED',
      message: 'We were unable to complete the request. Please retry or contact SunPower support.',
      errorClass: null
    });
  }).then((jsonResp) => {
    const data = jsonResp.result;

    return {
      loanCalcValues: {
        monthlyRent: data.monthly_rent,
        adjustedMonthlyRent: data.adjusted_monthly_rent,
        totalPayment: data.total_payment,
        payoffDate: data.payoff_date,
        newLoanAmountFromFinEng: data.financed_amount
      }
    };
  });

  return (dispatch) => {
    const response = dispatch({
      type: 'fetchFinEngLoanCalc',
      payload: asyncPayloadWithNoExplicitUpdate({
        request: promise
      })
    });

    response.then((data) => {
      const loanCalcValues = get(data, 'value.loanCalcValues');
      if (saveLoanCalcValuesInQuote && loanCalcValues) {
        dispatch(updateLoanCalcValuesInQuote(quote, loanCalcValues));
      }

      return data;
    });
  };
};
