import visualizationToImage from '../utils/visualizationToImage';
import { asyncPayload } from './apiHelpers';
import Api from '../utils/Api';
import { CALCULATING_SAVINGS } from '../config';

export function finalizePublicQuote(quote, selectedFinanceOption) {
  const promise = visualizationToImage()
    .then((dataUri) =>
      Api.lockQuoteWithPolling(`public/quotes/${quote.sfid}/finalize?pat=${quote.publicAccessToken}`, {
        quote: {
          locked: true,
          visualizationImage: dataUri,
          selectedFinanceOption
        }
      }, `public/quotes/${quote.sfid}?pat=${quote.publicAccessToken}`)
    );

  return {
    type: 'PATCH',
    payload: asyncPayload({
      beforeRequest: {
        mode: CALCULATING_SAVINGS
      },
      request: promise
    })
  };
}
