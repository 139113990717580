import { designSelector, schemaSelector, accountSelector } from '../selectors';
import getNotificationsFromSchema from '../utils/getNotificationsFromSchema';
import logger from '../utils/logger';

export default (state) => {
  const { schema } = schemaSelector(state);
  const { design } = designSelector(state);
  const { account } = accountSelector(state);
  let notifications = getNotificationsFromSchema(schema, design, account);
  notifications.forEach((n) => {
    if (n.error) logger.error(`[Design Optimization schema validation error] ${n.error}`);
  });
  notifications = notifications.filter((n) => !n.error);
  const active = notifications.length > 0;
  const totalCost = notifications.reduce((a, b) => { return a + (b.costForDisplayOnly ? 0 : b.cost); }, 0);

  return {
    notifications: {
      notifications,
      active,
      totalCost
    }
  };
};
