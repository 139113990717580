import React from 'react';
import { formatCurrency, formatNumber } from '../../utils/formatNumber';
import { register } from '../../utils/redux';
import {
  savingsChartInfoBoxType
} from '../../types';

export function ChartInfoBox(props) {
  const infoBoxData = props.savingsChartInfoBoxData;
  const hasRatePerKwh = infoBoxData.ratePerKwh !== null;

  return (
    <div className="chart-info-box-wrapper">
      <div className="chart-info-box">
        <table>
          <thead>
            <tr>
              <td>Savings</td>
              <td className="cost">Payment</td>
              <td className="cost">{hasRatePerKwh ? 'Cost per kWh' : ' '}</td>
              <td className="cost">Production</td>
            </tr>
          </thead>
          <tbody>
            <tr id="chart_info_savings" className="values">
              <td>
                {formatCurrency(infoBoxData.savings, 0)}
              </td>
              <td className="cost">
                {formatCurrency(infoBoxData.payment, 0)}
              </td>
              <td className="cost">
                {hasRatePerKwh ? formatCurrency(infoBoxData.ratePerKwh, 3) : ' '}
              </td>
              <td className="cost">
                {formatNumber(infoBoxData.annualProduction, 0)}
                <span className="unit">kWh/year</span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
}

ChartInfoBox.propTypes = {
  savingsChartInfoBoxData: savingsChartInfoBoxType
};

export default register(
  ['savingsChartInfoBoxDataSelector'],
  [],
  ChartInfoBox
);
