import { asyncPayload } from './apiHelpers';
import Api from '../utils/Api';

export function generateProposalFile(quote, isPublic, extension) {
  const prefix = isPublic ? '/public' : '';
  const suffix = isPublic ? `?pat=${quote.publicAccessToken}` : '';
  const url = `/quotes/${quote.sfid}/proposal.${extension}`;
  const promise = Api.fetchFile(`${prefix}${url}${suffix}`)
    .then(async (fileBlob) => {
      const types = {
        docx: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        pdf: 'application/pdf'
      };
      const type = types[extension];
      const blob = new Blob([fileBlob], { type });
      return new Promise((done, _fail) => {
        const reader = new FileReader();
        reader.onload = (_progressEvent) => {
          done({
            proposal: {
              [`${extension}Url`]: URL.createObjectURL(blob),
              [`${extension}DataUrl`]: reader.result
            }
          });
        };
        reader.readAsDataURL(blob);
      });
    });

  return {
    type: 'generateProposalFile',
    payload: asyncPayload({
      beforeRequest: {
        loading: true
      },
      request: promise
    })
  };
}
