import { polygon, union } from '@turf/turf';
import { populateWithArrays } from './helpers';

// the value of 0.1 restores design to be back in meters
const scaleFactor = 0.1;

const flattenPoints = (points) => {
  return points.map((p) => {
    return [ p.x * scaleFactor, p.y * scaleFactor ];
  });
};

const makePolygon = (points) => {
  return {
    points: flattenPoints(points),
    type: 'polygon'
  };
};

const makePolygonWithHoles = (points, holes) => {
  const poly = makePolygon(points);
  if (holes) {
    poly.holes = holes.map((hole) => flattenPoints(hole));
  }
  return poly;
};

const moduleSpec = (series, name, wattage, modelId) => {
  let size;
  let shortSeries;
  if (series === 'A Series') {
    size = [ 0.9906, 1.835 ];
    shortSeries = 'A';
  } else { // X/E Series
    size = [ 1.0206, 1.558 ];
    shortSeries = 'E/X';
  }
  // it is in meters
  return {
    name,
    size,
    series: shortSeries,
    spacing: [
      0.0254,
      0.0127
    ],
    wattage,
    id: modelId
  };
};

export const prepareModuleSpec = (quote, pvModules) => {
  let pvModuleItemId = '0';
  if (quote) {
    pvModuleItemId = quote.pvModuleItemId;
  }
  const pvModule = pvModules.find((obj) => {
    return obj.itemId === pvModuleItemId;
  });
  if (pvModule === undefined) {
    return moduleSpec('A Series', 'A400-G-AC', 400, pvModuleItemId);
  }
  return moduleSpec(pvModule.item.moduleSeries, pvModule.item.model, pvModule.item.wattage, pvModuleItemId);
};

const sum = (x, y) => x + y;

export const getRoofProduction = (moduleArrays, roofPlaneNumber, pvModuleItemId) => {
  const moduleArray = moduleArrays.find((obj) => {
    return obj.roofPlaneNumber === roofPlaneNumber;
  });
  if (moduleArray && moduleArray.monthlyEnergyProduction && moduleArray.monthlyEnergyProduction[pvModuleItemId]) {
    return moduleArray.monthlyEnergyProduction[pvModuleItemId].reduce(sum, 0) / moduleArray.panelCount;
  }
  return 0.0;
};

export const getPanelProduction = (roofProductions, roofShading, panelShading) => {
  return Object.fromEntries(Object.keys(roofProductions).map((moduleId) => {
    return [ moduleId, (roofProductions[moduleId] * panelShading / roofShading) ];
  }));
};

const mapXY = (point) => {
  return { x: point[0], y: point[1] };
};

const geometryToSetback = (polygon) => {
  return {
    points: polygon[0].map(mapXY),
    holes: polygon.length > 1 ? polygon.slice(1).map((hole) => hole.map(mapXY)) : null
  };
};

const complexGeometryToSetbacks = (complexPolygon) => {
  let setbacks = [];
  if (complexPolygon.geometry.type === 'MultiPolygon') {
    setbacks = setbacks.concat(complexPolygon.geometry.coordinates.map((polygon) => {
      return geometryToSetback(polygon);
    }));
  } else if (complexPolygon.geometry.type === 'Polygon') {
    setbacks.push(geometryToSetback(complexPolygon.geometry.coordinates));
  }
  return setbacks;
};

const mergeSetbacks = (setbacks) => {
  if (setbacks.length === 0) {
    return [];
  }

  const polygons = setbacks.map((setback) => {
    const points = setback.points.map((p) => {
      return [ p.x, p.y ];
    });
    return polygon([points]);
  });
  let skipped = [];
  const unionPolygon = polygons.reduce((currentUnion, polygon) => {
    try {
      currentUnion = union(currentUnion, polygon);
    } catch (error) {
      // in case setback is causing problems with computing new geometry we keep it as-is
      skipped = skipped.concat(complexGeometryToSetbacks(polygon));
    }
    return currentUnion;
  }, polygons[0]);
  let mergedSetbacks = complexGeometryToSetbacks(unionPolygon);
  mergedSetbacks = mergedSetbacks.concat(skipped);
  return mergedSetbacks;
};

export const convertFromAdvanceDesignFormat = (adDesign, design) => {
  const moduleArrays = [];
  const roofPlaneNumberToModuleArrayIndex = {};
  design.moduleArrays.forEach((moduleArray, idx) => {
    moduleArrays.push({ panels: [] });
    roofPlaneNumberToModuleArrayIndex[moduleArray.roofPlaneNumber] = idx;
  });
  const moduleArraysIndexesToRoofPlaneNumbers = design.roof.moduleArraysIndexesToRoofPlaneNumbers;
  const roofPlaneIdToIndex = {};
  adDesign.roofs.forEach((roof, idx) => {
    roofPlaneIdToIndex[roof.id] = idx;
  });
  const roofModuleArrays = populateWithArrays(Object.keys(moduleArraysIndexesToRoofPlaneNumbers));
  const roofObstructions = populateWithArrays(Object.keys(moduleArraysIndexesToRoofPlaneNumbers));
  const roofSetbacks = populateWithArrays(Object.keys(moduleArraysIndexesToRoofPlaneNumbers));
  const convertPoint = (point) => {
    return { x: point[0] / scaleFactor, y: point[1] / scaleFactor };
  };
  adDesign.modules.forEach((module) => {
    if (module.roof_id) {
      roofModuleArrays[roofPlaneIdToIndex[module.roof_id]].push(
        module.shape.points.map(convertPoint)
      );
      const arrayIdx = roofPlaneNumberToModuleArrayIndex[
        moduleArraysIndexesToRoofPlaneNumbers[roofPlaneIdToIndex[module.roof_id]]];
      moduleArrays[arrayIdx].panels.push({ enabled: !!module.active });
    }
  });
  moduleArrays.forEach((moduleArray) => {
    moduleArray.panelCount = moduleArray.panels.length;
  });
  adDesign.obstructions.forEach((obstruction) => {
    if (obstruction.roof_id) {
      roofObstructions[roofPlaneIdToIndex[obstruction.roof_id]].push({
        id: obstruction.id,
        points: obstruction.shape.points.map(convertPoint)
      });
    }
  });
  adDesign.setbacks.forEach((setback) => {
    const setbackObject = {
      type: 'PROCESSED',
      points: setback.shape.points.map(convertPoint)
    };
    if (setback.shape.holes) {
      setbackObject.holes = setback.shape.holes.map((hole) => hole.map(convertPoint));
    }
    if (setback.source_id) {
      setbackObject.originId = setback.source_id;
    }
    if (setback.roof_id) {
      roofSetbacks[roofPlaneIdToIndex[setback.roof_id]].push(setbackObject);
    }
  });
  return {
    design: {
      moduleArrays,
      roof: {
        // NOTE: roofPlanes are currently fixed, no need to re-export
        ...design.roof,
        moduleArrays: roofModuleArrays,
        obstructions: roofObstructions,
        setbacks: roofSetbacks
      }
    }
  };
};

export const exportToAdvanceDesignEditorFormat = (design, quote, pvModules, modulesSpec) => {
  const roofs = [];
  design.roof.roofPlanes.forEach((roofPlane, index) => {
    roofs.push({
      id: `R${index}`,
      type: 'roof_plane',
      shape: makePolygon(roofPlane),
      tilt: NaN,
      azimuth: NaN
    });
  });

  const modules = [];
  let index = 0;
  let modulesIndex = 0;
  const modulesIds = pvModules.map((module) => { return module.itemId; });
  // eslint-disable-next-line no-restricted-syntax
  for (const [ keyRoofIndex, roofModuleArray ] of Object.entries(design.roof.moduleArrays)) {
    const roofPlaneNumber = design.roof.moduleArraysIndexesToRoofPlaneNumbers[keyRoofIndex];
    const keyRoofIndexInt = parseInt(keyRoofIndex, 10);
    const moduleArray = design.moduleArrays.find((ma) => ma.roofPlaneNumber === roofPlaneNumber);
    roofs[keyRoofIndexInt].tilt = Number(moduleArray.pitch);
    roofs[keyRoofIndexInt].azimuth = Number(moduleArray.azimuth);

    const roofShading = moduleArray.monthlySolarAccess.reduce(sum, 0);
    const roofProductions = Object.fromEntries(modulesIds.map((id) => {
      return [ id, getRoofProduction(design.moduleArrays, roofPlaneNumber, id) ];
    }));
    // eslint-disable-next-line no-loop-func
    roofModuleArray.forEach((module, panelIdx) => {
      const panelShading = design.roof.panelShading[keyRoofIndex][panelIdx].reduce(sum, 0);
      modules.push({
        type: 'module',
        id: `M${modulesIndex}`,
        roof_id: roofs[keyRoofIndexInt].id,
        shape: makePolygon(module),
        production: getPanelProduction(roofProductions, roofShading, panelShading),
        active: moduleArray.panels[panelIdx].enabled
      });
      modulesIndex += 1;
    });

    index += 1;
  }

  const obstructions = [];
  let obstructionIndex = 0;
  const obstructionIdsMapping = {};
  // eslint-disable-next-line no-restricted-syntax
  for (const [ keyRoofIndex, obstructionsArray ] of Object.entries(design.roof.obstructions)) {
    const keyRoofIndexInt = parseInt(keyRoofIndex, 10);
    // eslint-disable-next-line no-loop-func
    obstructionsArray.forEach((obstruction) => {
      obstructionIdsMapping[obstruction.id] = `O${obstructionIndex}`;
      obstructions.push({
        type: 'obstruction',
        id: obstructionIdsMapping[obstruction.id],
        roof_id: roofs[keyRoofIndexInt].id,
        shape: makePolygon(obstruction.points)
      });
      obstructionIndex += 1;
    });
  }
  const setbacks = [];
  let setbacksIndex = 0;
  // eslint-disable-next-line no-restricted-syntax
  for (const [ keyRoofIndex, setbacksArray ] of Object.entries(design.roof.setbacks)) {
    const keyRoofIndexInt = parseInt(keyRoofIndex, 10);
    const mergeableSetbacks = [];
    let processedSetbacks = [];
    // eslint-disable-next-line no-loop-func
    setbacksArray.forEach((setback) => {
      if ([ 'FLAT ROOF', 'OBSTACLE', 'PROCESSED' ].includes(setback.type)) {
        processedSetbacks.push(setback);
      } else {
        mergeableSetbacks.push(setback);
      }
    });
    processedSetbacks = processedSetbacks.concat(mergeSetbacks(mergeableSetbacks));
    // eslint-disable-next-line no-loop-func
    processedSetbacks.forEach((setback) => {
      const setbackObject = {
        type: 'setback',
        id: `S${setbacksIndex}`,
        roof_id: roofs[keyRoofIndexInt].id,
        shape: makePolygonWithHoles(setback.points, setback.holes)
      };
      if (setback.originId) {
        setbackObject.source_id = obstructionIdsMapping[setback.originId];
      }
      setbacks.push(setbackObject);
      setbacksIndex += 1;
    });
  }
  return {
    layers: {
      roofs,
      modules,
      trees: [],
      obstructions,
      setbacks
    },
    source_timestamp: design.updatedAt,
    background_layers: {
      irradiance: {
        url: `/instant_quote/quotes/${quote.sfid}/irradiance_image`,
        size: [ design.roof.dimensions.height, design.roof.dimensions.width ]
      },
      satellite: {
        url: `/instant_quote/quotes/${quote.sfid}/satellite_image`,
        size: [ design.roof.dimensions.height, design.roof.dimensions.width ]
      }
    },
    modules_spec: modulesSpec
  };
};

