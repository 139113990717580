import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

export default function PercentCircle({ label, value, className, labelPosition, labelHorizontal, hideShadowAfter100 }) {
  const percent = Object.is(value, NaN) ? 'N/A' : value;

  const innerRadius = className === 'new half' ? 71 : 51;
  // const innerRadius = 71;// className === 'new' ? 71 : 51;
  const outerRadius = className === 'new half' ? 84 : 64;
  // const outerRadius = 84;// className === 'new' ? 84 : 64;
  const annulusWidth = className === 'new half' ? 10 : (outerRadius - innerRadius);
  const circleProps = {
    r: innerRadius,
    cx: outerRadius,
    cy: outerRadius,
    strokeWidth: annulusWidth,
    fill: 'transparent'
  };
  const circumference = 2 * Math.PI * innerRadius;
  const wrapperClassname = cx('percent-circle', className, {
    'percent-circle-horizontal': labelHorizontal
  });
  return (
    <div className={wrapperClassname}>
      {(labelPosition === 'before') && <span className="label">{label}</span>}

      <div className="circle-value">
        <svg className="circle" width={outerRadius * 2} height={outerRadius * 2}>
          <circle {...circleProps} className="background-circle" />
          <circle
            {...circleProps}
            className="foreground-circle-arc"
            strokeDasharray={`${circumference * percent / 100} ${circumference}`}
            transform={`rotate(-90.1 ${outerRadius} ${outerRadius})`}
          />
          {percent >= 100 && (
            <g transform={`rotate(${(percent / 100 * 360)} ${outerRadius} ${outerRadius})`}>
              <defs>
                <linearGradient x1="0%" y1="22.8338647%" x2="83.2528581%" y2="81.4237372%" id="linearGradient-1">
                  <stop stopColor="#000000" stopOpacity="0.201171875" offset="0%" />
                  <stop stopColor="#000000" stopOpacity="0" offset="100%" />
                </linearGradient>
              </defs>

              {!hideShadowAfter100 ? (
                <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                  <g fill="url(#linearGradient-1)">
                    <g transform={`translate(${outerRadius}, ${annulusWidth / 2})`}>
                      <path
                        d="M43.0703539,18.227403 C32.1655503,6.98596203 16.8986046,-2.4158453e-13 3.62376795e-12,
                      -2.4158453e-13 L3.62376795e-12,13 C13.2651437,13 25.2467605,18.4954321 33.7924174,
                      27.3338633 L43.0703539,18.227403 Z"
                      />
                    </g>
                  </g>
                </g>
              ) : null}
            </g>
          )}
        </svg>

        <div className="value-text">
          {isFinite(value) ? (
            <div>
              <span className="value">{value}</span>
              <span className="percent">%</span>
            </div>
          ) : (
            <div className="loading-dots"><span>.</span><span>.</span><span>.</span></div>
          )}
          {(labelPosition === 'inside') && <div className="label">{label}</div>}
        </div>
      </div>
      {(labelPosition === 'after') && <span className="label">{label}</span>}
    </div>
  );
}

PercentCircle.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.number.isRequired,
  className: PropTypes.string,
  labelPosition: PropTypes.oneOf([ 'before', 'after', 'inside' ]),
  labelHorizontal: PropTypes.bool,
  hideShadowAfter100: PropTypes.bool
};

PercentCircle.defaultProps = {
  labelPosition: 'before',
  labelHorizontal: false
};
