import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import { register } from '../utils/redux';
import { isStorageSelected } from '../utils/storage';
import {
  quoteType,
  selectedStorageType,
  storageSelectionModalType, selectedStorageWithExpansionPackType
} from '../types';

const RemoveStorageModalButton = ({
  updateStorage,
  toggleAddStorageModalFlag,
  storageSelectionModal,
  quote,
  selectedStorageWithExpansionPack,
  selectedStorage
}) => {
  const onDelete = useCallback(() => {
    /* eslint-disable no-alert */
    if (confirm('Are you sure you want to delete this storage?')) {
      const params = {
        storageConfigId: null,
        storageQuantity: null,
        storageLocation: null,
        storageBackup: null,
        storageExpansionPackQuantity: null,
        storageLaborCost: null
      };
      updateStorage(quote, params);
      toggleAddStorageModalFlag();
    }
    /* eslint-enable no-alert */
  }, [ updateStorage, toggleAddStorageModalFlag ]);

  const storageSelected = isStorageSelected(storageSelectionModal,
    quote, selectedStorageWithExpansionPack, selectedStorage);

  if (!storageSelected) {
    return null;
  }

  return (
    <div className="iq-delete-storage-btn" onClick={onDelete}>
      Remove Storage
    </div>
  );
};

RemoveStorageModalButton.propTypes = {
  updateStorage: PropTypes.func.isRequired,
  toggleAddStorageModalFlag: PropTypes.func.isRequired,
  storageSelectionModal: storageSelectionModalType.isRequired,
  quote: quoteType.isRequired,
  selectedStorageWithExpansionPack: selectedStorageWithExpansionPackType.isRequired,
  selectedStorage: selectedStorageType.isRequired
};

export default register(
  [
    'storageSelectionModalSelector', 'quoteSelector',
    'selectedStorageWithExpansionPackSelector', 'selectedStorageSelector'
  ],
  [
    'updateStorage', 'toggleAddStorageModalFlag'
  ],
  RemoveStorageModalButton
);
