import BrowserStorage from './BrowserStorage';

export const truncateString = (string, position) => {
  return string && string.length > position ? `${string.substring(0, position)}...` : string;
};

export const setDrafSessionInLocalStorage = () => {
  BrowserStorage.setPermanentItem('draft_quote_session', true);
};

export const getDrafSessionInLocalStorage = () => {
  return BrowserStorage.getItem('draft_quote_session');
};

export const unsetDraftSessionInLocalStorage = () => {
  BrowserStorage.removeItem('draft_quote_session');
};
