import PropTypes from 'prop-types';
import { moduleArrayType } from './moduleArray';
import { roofType } from './roof';

export const designType = PropTypes.shape({
  paired: PropTypes.bool.isRequired,
  roof: roofType.isRequired,
  moduleArrays: PropTypes.arrayOf(moduleArrayType).isRequired,
  totalEnergyProduction: PropTypes.number,
  enabledArrayCount: PropTypes.number,
  enabledPanelCount: PropTypes.number,
  activeEnabledPanelCount: PropTypes.number,
  hasChanges: PropTypes.bool.isRequired,
  activeModule: PropTypes.number,
  isInstant: PropTypes.bool,
  satelliteImgUrl: PropTypes.string
});
