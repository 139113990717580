import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { register } from '../utils/redux';
import {
  selectedStorageType,
  quoteType,
  dropdownsType,
  accountType,
  settingsType
} from '../types';
import { tagList } from '../utils/pvModuleFiltersAndTags';
import { paymentOptionsForStorage } from '../utils/calculations';

const defaultContainerClasses = 'input-container input-container--icon icon-right dropdown-with-tags';

export function StorageListDropdown({
  pvModule,
  selectedStorage,
  dropdowns: { isStorageListOpen },
  onSelect,
  toggleStoragesDropdown,
  quote,
  account,
  settings,
  partnerIsRvar
}) {
  const storagesForChannel = (storage) => partnerIsRvar || !storage.name.includes('Powerwall');

  const processStorageName = (storageName) => {
    // if (storageName && storageName.includes('Equinox')) {
    //   return storageName.replace('Equinox', 'SunVault');
    // }
    return storageName;
  };

  const tags = tagList(paymentOptionsForStorage(account), settings);
  const options = (<ul>
    <li
      className={cx('cs-option-container', { 'cs-selected': selectedStorage.id === null })}
      onClick={() => {
        toggleStoragesDropdown();
        onSelect(null);
      }}
      key={0}
    >
      <span className="cs-option">
        <div className="cs-option-name">None</div>
      </span>
    </li>
    {pvModule && pvModule.storageUnits.filter(storagesForChannel).map((option) => {
      return (
        <li
          className={cx('cs-option-container', { 'cs-selected': option.id === selectedStorage.id })}
          onClick={() => {
            toggleStoragesDropdown();
            onSelect(option.id);
          }}
          key={option.id}
        >
          <span className="cs-option">
            <div className="cs-option-name">{processStorageName(option.name)}</div>
            {tags}
          </span>
        </li>
      );
    })}
  </ul>);

  return pvModule.storageUnits.length === 0 ? (
    <div
      id="storage_dropdown"
      className={defaultContainerClasses}
    >
      <input type="text" value="None" disabled={true} />
    </div>
    ) : (
      <div
        id="storage_dropdown"
        className={cx(defaultContainerClasses, { 'is-disabled': quote.locked })}
      >
        <i className="icon icon-chevron-down" />
        <div
          className={cx('select-dropdown cs-skin-border', { 'cs-active': isStorageListOpen })}
          tabIndex="0"
        >
          <span className="cs-placeholder" onClick={toggleStoragesDropdown}>
            <div className="cs-option-name">{processStorageName(selectedStorage.name)}</div>
            {tags}
          </span>
          <div className="cs-options">
            { options }
          </div>
        </div>
      </div>
  );
}

StorageListDropdown.propTypes = {
  pvModule: PropTypes.shape({
    storageUnits: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string.isRequired
    })).isRequired
  }),
  selectedStorage: selectedStorageType.isRequired,
  dropdowns: dropdownsType.isRequired,
  quote: quoteType.isRequired,
  account: accountType.isRequired,
  settings: settingsType.isRequired,
  onSelect: PropTypes.func.isRequired,
  toggleStoragesDropdown: PropTypes.func.isRequired,
  partnerIsRvar: PropTypes.bool.isRequired
};

export default register(
  [
    'pvModuleSelector',
    'selectedStorageSelector',
    'dropdownsSelector',
    'quoteSelector',
    'accountSelector',
    'settingsSelector',
    'partnerIsRvarSelector'
  ],
  ['toggleStoragesDropdown'],
  StorageListDropdown
);

