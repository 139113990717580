import _ from 'lodash';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import AdvancedDesign from 'advanced-design';
import { register } from '../utils/redux';
import {
  quoteType,
  designType,
  energyDataType,
  pvModuleForManualModeType,
  pvModuleType,
  selectedStorageType,
  monitoringConfigurationType,
  storageSelectionModalType,
  settingsType,
  businessFunctionsType,
  selectedStorageWithExpansionPackType
} from '../types';
import { BASIC_VIEW, DETAILED_VIEW } from '../config';

import { getMonitoringName } from '../utils/normalizedNames';
import { pvModuleImageSrc } from '../utils/EddieCalculations/design';
import OptimizationModal from './dynamic_design/OptimizationModal';
import EquipmentSelectionDrawer from './instant_quote/equipment_selection_drawer/EquipmentSelectionDrawer';
import AddStorageModal from './add_storage/AddStorageModal';

import { adDesignType, adProductionType } from '../types/adDesign';
import {
  convertFromAdvanceDesignFormat,
  exportToAdvanceDesignEditorFormat,
  prepareModuleSpec
} from '../utils/InstantQuote/designDataFormatter';
import ExpandButton from './ExpandButton';
import { isStorageSelected } from '../utils/storage';
import { establishModulesTopology } from '../utils/InstantQuote/panelsTopology';
import { selectPanels } from '../../spec/utils/InstantQuote/selectPanels';
import { InstantQuoteVisualizationTabs } from './instant_quote/InstantQuoteVisualizationTabs';
import { InstantQuoteBasicViewTab } from './instant_quote/right_panel/InstantQuoteBasicViewTab';
import { InstantQuoteDetailedViewTab } from './instant_quote/right_panel/InstantQuoteDetailedViewTab';
import AddStorageWithMultiInvertersModal from './add_storage/AddStorageWithMultiInvertersModal';
import { showStorageWithExpPack } from '../utils/StorageWithMultiInverters/helpers';
import { storageExpansionPackEnabled } from '../utils/backendFeatureFlags';

export function InstantQuoteDesignPage({
                                         adDesign,
                                         design,
                                         quote,
                                         pvModule,
                                         pvModules,
                                         viewMode,
                                         annualUsage,
                                         setViewMode,
                                         setAdDesign,
                                         monitoringConfiguration,
                                         toggleModuleSelectionModal,
                                         toggleAddStorageModalFlag,
                                         selectedStorage,
                                         storageSelectionModal,
                                         updateStorage,
                                         businessFunctions,
                                         updateIQDesign,
                                         markDesignDirty,
                                         validateAndSaveSystemDesign,
                                         isPartnerEnabledForAnyStorage,
                                         rightPanelExpanded,
                                         instantQuoteProduction,
                                         setInstantQuoteProduction,
                                         instantQuoteSaveDesignRequest,
                                         instantQuoteSaveDesignPreviousRequest,
                                         instantQuoteSyncDesign,
                                         setInstantQuoteSaveDesignPreviousRequest,
                                         setInstantQuoteSyncDesign,
                                         selectedStorageWithExpansionPack,
                                         isSpecialState,
                                         specialStateFullName,
                                         isSaved
                                       }) {
  const designTabs = [
    { mode: BASIC_VIEW, label: 'Basic View' },
    { mode: DETAILED_VIEW, label: 'Detailed View' }
  ];
  const adRef = useRef(null);
  const [ existingDesignErrors, setExistingDesignErrors ] = useState([]);
  const [ showErrorModal, setShowErrorModal ] = useState(false);
  const [ currentVersionId, setCurrentVersionId ] = useState(-1);
  const [ isReloading, setIsReloading ] = useState(false);
  const [ selectedPanels, setSelectedPanels ] = useState([]);
  const [ currentAdDesign, setCurrentAdDesign ] = useState(adDesign);
  const [ currentDesign, setCurrentDesign ] = useState(design);

  const selectedPvModule = quote.locked ? quote.lockedPvModuleItem : pvModule;
  const moduleImageSrc = pvModuleImageSrc(selectedPvModule, pvModule);

  const getMountingImageSrc = (rackingType, moduleBackSheetType) => {
    if (rackingType === 'Invisimount') {
      if (moduleBackSheetType === 'White Back-Sheet') {
        return 'invisimount-white';
      }
      return 'invisimount-black';
    }
    return 'non-sunpower';
  };
  const mountingImageSrc = getMountingImageSrc(quote.rackingType, selectedPvModule.item.moduleBackSheetType);

  const monitoringName = getMonitoringName(monitoringConfiguration.name, selectedStorage.name);

  const storageSelected = isStorageSelected(storageSelectionModal,
    quote, selectedStorageWithExpansionPack, selectedStorage);

  const onProductionChange = useCallback((newProductionValue, systemSize) => {
    const productionKW = newProductionValue;

    setInstantQuoteProduction({
      production: productionKW,
      systemSize: systemSize / 1000,
      energyOffset: Math.floor(productionKW / annualUsage * 100)
    });
  }, [ annualUsage, setInstantQuoteProduction ]);

  const onErrorModulesChange = useCallback((errors) => {
    setExistingDesignErrors(errors);
  }, [setExistingDesignErrors]);

  const onCloseErrorModal = useCallback(() => {
    setShowErrorModal(false);
  }, [setShowErrorModal]);

  const onAdEdit = useCallback((versionId, versionsLength) => {
    if (isReloading) {
      setIsReloading(false);
    } else if (!quote.locked) {
      if (versionsLength > 1) {
        markDesignDirty();
      }

      setCurrentVersionId(versionId);

      if (adRef && adRef.current) {
        const updatedLayers = adRef.current.getDesignData();
        setCurrentAdDesign({
          ...currentAdDesign,
          layers: {
            ...currentAdDesign.layers,
            ...updatedLayers
          }
        });

        const exportedData = convertFromAdvanceDesignFormat(updatedLayers, design);
        setCurrentDesign({
          ...currentDesign,
          moduleArrays: currentDesign.moduleArrays.map((array, idx) => {
            return {
              ...array,
              panelCount: exportedData.design.moduleArrays[idx].panelCount,
              panels: exportedData.design.moduleArrays[idx].panels };
          }),
          roof: {
            ...currentDesign.roof,
            ...exportedData.design.roof
          }
        });
      }
    }
  }, [
    setCurrentVersionId,
    currentVersionId,
    markDesignDirty,
    isReloading,
    setIsReloading,
    adRef,
    setCurrentAdDesign,
    setCurrentDesign,
    currentAdDesign,
    currentDesign
  ]);

  const onAdSelectedPanelsChange = useCallback((panels) => {
    setSelectedPanels(panels);
  }, [setSelectedPanels]);

  function syncGlobalDesignWithIQ(design, adRef) {
    const exportedData = convertFromAdvanceDesignFormat(adRef.current.getDesignData(), design);
    const moduleArrays = design.moduleArrays.map((array, idx) => {
      return {
        ...array,
        panelCount: exportedData.design.moduleArrays[idx].panelCount,
        panels: exportedData.design.moduleArrays[idx].panels
      };
    });
    const roof = {
      ...design.roof,
      ...exportedData.design.roof
    };

    updateIQDesign({ moduleArrays, roof });
  }

  const saveDesign = useCallback(() => {
    if (existingDesignErrors.length > 0) {
      setShowErrorModal(true);
    } else {
      syncGlobalDesignWithIQ(design, adRef);
      validateAndSaveSystemDesign();
    }
  }, [
    existingDesignErrors,
    setShowErrorModal,
    validateAndSaveSystemDesign,
    adRef,
    design
  ]);

  useEffect(() => {
    if (instantQuoteSyncDesign) {
      syncGlobalDesignWithIQ(design, adRef);
      setInstantQuoteSyncDesign(false);
    }
  }, [ adRef, design ]);

  useEffect(() => {
    const newModuleSpec = prepareModuleSpec(quote, pvModules);
    if (
      adDesign === undefined ||
      (adDesign.modules_spec.series !== newModuleSpec.series) ||
      (design.updatedAt !== adDesign.source_timestamp)
    ) {
      if (adDesign && design.updatedAt !== adDesign.source_timestamp) {
        setIsReloading(true);
      }
      const newAdDesign = exportToAdvanceDesignEditorFormat(design, quote, pvModules, newModuleSpec);
      if (!newAdDesign.layers.modules.some((module) => module.active)) {
        const topology = establishModulesTopology(newAdDesign.layers.modules, newAdDesign.layers.roofs);
        selectPanels(newAdDesign.layers.modules, topology, annualUsage, newAdDesign.modules_spec);
      }
      setAdDesign(newAdDesign);
    } else if (adDesign.modules_spec.name !== newModuleSpec.name) {
      const newAdDesign = _.cloneDeep(adDesign);
      newAdDesign.modules_spec = newModuleSpec;
      setAdDesign(newAdDesign);
    }
  }, [ quote, design, pvModules, adDesign, setAdDesign, setIsReloading ]);

  useEffect(() => {
    if (adRef && adRef.current) {
      adRef.current.refreshCanvasSize();
    }
  }, [ rightPanelExpanded, adRef ]);

  useEffect(() => {
    if (instantQuoteSaveDesignRequest > instantQuoteSaveDesignPreviousRequest) {
      setInstantQuoteSaveDesignPreviousRequest(instantQuoteSaveDesignRequest);
      saveDesign();
    }
  }, [
    instantQuoteSaveDesignRequest,
    instantQuoteSaveDesignPreviousRequest,
    setInstantQuoteSaveDesignPreviousRequest,
    saveDesign
  ]);

  useEffect(() => {
    setCurrentAdDesign(adDesign);
    setCurrentDesign(design);
  }, [ design, adDesign, setCurrentAdDesign, setCurrentDesign ]);

  return (
    <div id="main-content" className="iq">
      <div id="design-editor-container" className="iq">
        { adDesign ?
          <AdvancedDesign
            ref={adRef}
            solarDesign={_.cloneDeep(adDesign)}
            onProductionChange={onProductionChange}
            onErrorModulesChange={onErrorModulesChange}
            onCloseErrorModal={onCloseErrorModal}
            showErrorModal={showErrorModal}
            onEdit={onAdEdit}
            onSelectedPanelsChange={onAdSelectedPanelsChange}
            isEditable={!quote.locked}
            offsetTop={64}
            showSatelliteMode={isSaved}
          /> : null }
      </div>
      <div id="value-box-container" className={`iq ${!rightPanelExpanded ? 'closed' : ''}`}>
        <ExpandButton />
        {rightPanelExpanded ? (
          <div id="instant-quote-right-panel">
            <InstantQuoteVisualizationTabs
              designTabs={designTabs}
              setViewMode={setViewMode}
              viewMode={viewMode}
            />

            {(viewMode === BASIC_VIEW) &&
              <InstantQuoteBasicViewTab
                quote={quote}
                annualUsage={annualUsage}
                instantQuoteProduction={instantQuoteProduction}
                toggleModuleSelectionModal={toggleModuleSelectionModal}
                moduleImageSrc={moduleImageSrc}
                mountingImageSrc={mountingImageSrc}
                monitoringName={monitoringName}
                selectedPvModule={selectedPvModule}
                storageSelected={storageSelected}
                toggleAddStorageModalFlag={toggleAddStorageModalFlag}
                updateStorage={updateStorage}
                storageSelectionModal={storageSelectionModal}
                selectedStorage={selectedStorage}
                selectedStorageWithExpansionPack={selectedStorageWithExpansionPack}
                businessFunctions={businessFunctions}
                isPartnerEnabledForAnyStorage={isPartnerEnabledForAnyStorage}
              />
            }
            {(viewMode === DETAILED_VIEW) &&
              <InstantQuoteDetailedViewTab
                design={currentDesign}
                adDesign={currentAdDesign}
                selectedPanels={selectedPanels}
                isSpecialState={isSpecialState}
                specialStateFullName={specialStateFullName}
                selectedPvModule={selectedPvModule}
              />
            }
          </div>
        ) : null}
      </div>
      <OptimizationModal
        show={design.notificationModal}
      />
      <EquipmentSelectionDrawer />
      {
        !showStorageWithExpPack(storageExpansionPackEnabled(), quote) && <AddStorageModal />
      }
      {
        showStorageWithExpPack(storageExpansionPackEnabled(), quote) && <AddStorageWithMultiInvertersModal />
      }
    </div>
  );
}


InstantQuoteDesignPage.propTypes = {
  design: designType.isRequired,
  adDesign: adDesignType,
  quote: quoteType.isRequired,
  energyData: energyDataType.isRequired,
  pvModules: PropTypes.arrayOf(pvModuleForManualModeType).isRequired,
  viewMode: PropTypes.oneOf([
    BASIC_VIEW,
    DETAILED_VIEW
  ]),
  setViewMode: PropTypes.func.isRequired,
  annualUsage: PropTypes.number.isRequired,
  toggleModuleSelectionModal: PropTypes.func.isRequired,
  toggleAddStorageModalFlag: PropTypes.func.isRequired,
  pvModule: pvModuleType.isRequired,
  selectedStorage: selectedStorageType.isRequired,
  monitoringConfiguration: monitoringConfigurationType.isRequired,
  storageSelectionModal: storageSelectionModalType.isRequired,
  updateStorage: PropTypes.func.isRequired,
  settings: settingsType.isRequired,
  isPartnerEnabledForAnyStorage: PropTypes.bool.isRequired,
  businessFunctions: businessFunctionsType,
  validateAndSaveSystemDesign: PropTypes.func.isRequired,
  updateIQDesign: PropTypes.func.isRequired,
  markDesignDirty: PropTypes.func.isRequired,
  setAdDesign: PropTypes.func.isRequired,
  rightPanelExpanded: PropTypes.bool,
  instantQuoteProduction: adProductionType,
  setInstantQuoteProduction: PropTypes.func,
  instantQuoteSaveDesignRequest: PropTypes.number.isRequired,
  instantQuoteSaveDesignPreviousRequest: PropTypes.number.isRequired,
  instantQuoteSyncDesign: PropTypes.bool.isRequired,
  setInstantQuoteSaveDesignPreviousRequest: PropTypes.func.isRequired,
  setInstantQuoteSyncDesign: PropTypes.func.isRequired,
  selectedStorageWithExpansionPack: selectedStorageWithExpansionPackType.isRequired,
  isSpecialState: PropTypes.bool,
  specialStateFullName: PropTypes.string,
  isSaved: PropTypes.bool
};

export default register(
  [
    'designSelector',
    'quoteSelector',
    'energyDataSelector',
    'pvModulesSelector',
    'viewModeSelector',
    'annualUsageSelector',
    'selectedStorageSelector',
    'monitoringConfiguration',
    'pvModuleSelector',
    'storageSelectionModalSelector',
    'businessFunctionsSelector',
    'settingsSelector',
    'isPartnerEnabledForAnyStorageSelector',
    'adDesignSelector',
    'rightPanelExpandedSelector',
    'instantQuoteProductionSelector',
    'instantQuoteSaveDesignRequestSelector',
    'instantQuoteSaveDesignPreviousRequestSelector',
    'instantQuoteSyncDesignSelector',
    'selectedStorageWithExpansionPackSelector',
    'isSpecialStateSelector',
    'accountSelector',
    'specialStateFullNameSelector',
    'isSavedSelector'
  ],
  [
    'setViewMode',
    'toggleModuleSelectionModal',
    'validateAndSaveSystemDesign',
    'toggleAddStorageModalFlag',
    'updateStorage',
    'setAdDesign',
    'updateIQDesign',
    'markDesignDirty',
    'setInstantQuoteProduction',
    'setInstantQuoteSaveDesignPreviousRequest',
    'setInstantQuoteSyncDesign'
  ],
  InstantQuoteDesignPage
);
