import { asyncPayload } from './apiHelpers';
import Api from '../utils/Api';

export function savePublicPvModule(quote, pvModuleItemId) {
  return {
    type: 'savePvModule',
    payload: asyncPayload({
      request: Api.update(`public/quotes/${quote.sfid}/select_pv_module?pat=${quote.publicAccessToken}`, {
        quote: { pvModuleItemId }
      })
    })
  };
}
