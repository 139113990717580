import { cloneDeep } from 'lodash';
import { findApFinConfigIndexById } from '../../utils/admin_portal/updateHelpers';

/**
 *
 * @param {object} object
 * @returns updated state
 */

export const updateApInstallDays = (configId, key, val) => (dispatch, getState) => {
  const currentState = getState();
  const apFinConfigsToUpdate = cloneDeep(currentState.apFinConfigs);
  const finConfigIndex = findApFinConfigIndexById(apFinConfigsToUpdate, configId);
  if (finConfigIndex > -1) {
    if (apFinConfigsToUpdate[finConfigIndex].installDateAttributes) {
      apFinConfigsToUpdate[finConfigIndex].installDateAttributes[key] = val;
    } else {
      apFinConfigsToUpdate[finConfigIndex].installDateAttributes = {
        [key]: val
      };
    }
  }

  dispatch({
    type: 'updateApInstallDays',
    payload: {
      apFinConfigs: apFinConfigsToUpdate
    }
  });
};
