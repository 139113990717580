import get from 'lodash/get';
import { asyncPayloadWithNoExplicitUpdate } from './apiHelpers';
import Api from '../utils/Api';
import { updateProposal } from './updateProposal';
import { skipTitleCheckModal } from '../utils/titleCheck';

export const fetchTitleCheck = (quote, account, dynamicProposal) => {
  const accountId = account.sfid;
  const promise = Api.fetchWithParams(`title_check/${quote.sfid}`, { accountId });

  return (dispatch) => {
    const response = dispatch({
      type: 'fetchTitleCheck',
      payload: asyncPayloadWithNoExplicitUpdate({
        request: promise,
        beforeRequest: {
          dynamicProposal: {
            showComplianceCheckModal: true,
            showModalLoader: true
          }
        }
      })
    });

    response.then((data) => {
      if (get(data, 'value.error', null)) {
        dispatch({
          type: 'fetchTitleCheckFailed',
          payload: {
            dynamicProposal: {
              showModalLoader: false,
              showComplianceCheckModal: false,
              showTitleCheckModal: false
            }
          }
        });
      } else if (skipTitleCheckModal(get(data, 'value.titleCheckDetails', null))) {
        dispatch({
          type: 'setTitleCheckValue',
          payload: {
            quote: {
              titleCheckDetails: get(data, 'value.titleCheckDetails', null)
            },
            dynamicProposal: {
              showModalLoader: false
            }
          }
        });
        dispatch(updateProposal(quote.sfid, 'send', get(dynamicProposal, 'sentVia', null)));
      } else {
        dispatch({
          type: 'setTitleCheckValue',
          payload: {
            quote: {
              titleCheckDetails: get(data, 'value.titleCheckDetails', null)
            },
            dynamicProposal: {
              showComplianceCheckModal: false,
              showModalLoader: false,
              showTitleCheckModal: true
            }
          }
        });
      }
      return data;
    });
  };
};
