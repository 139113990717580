import includes from 'lodash/includes';

const chartSeriesKeys = [ 'savings', 'costs', 'rebatesAndCredits' ];

const getGraphSeriesKeys = (graphSeries) =>
  Object.keys(graphSeries).filter((k) => includes(chartSeriesKeys, k));

export default ({ legend, graphSeries = {} }) => {
  const graphSeriesKeys = getGraphSeriesKeys(graphSeries);
  const result = legend && (graphSeriesKeys.length > 0);
  return !!result;
};
