import PropTypes from 'prop-types';
import { get, uniq } from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import cx from 'classnames';
import { getStateNameByStateCode } from 'us-state-codes';
import { register } from '../../../utils/redux';
import constants from '../../../utils/EddieCalculations/constants';
/* eslint no-underscore-dangle: 0 */
export function LoanInputFieldsView({
  financeType, apFinConfig, apSalesforce, updateApDefaultLoanProduct, updateValidationErrors
}) {
  let selectedChoiceRate;
  let selectedTerm;
  let isFOEnabled;
  let selectedStates = [];
  const { loanProducts } = apSalesforce;
  const { INTEREST_RATE_CA } = constants;
  const LOAN_TERM_DROPDOWN_ID = 'loanTermDropDown';
  const DEFAULT_APR_DROPDOWN_ID = 'defaultAPRDropDown';
  const loanTermDropDownRef = useRef(LOAN_TERM_DROPDOWN_ID);
  const defaultAPRDropDownRef = useRef(DEFAULT_APR_DROPDOWN_ID);

  if (financeType && apFinConfig) {
    selectedChoiceRate = get(apFinConfig, 'defaultLoanProductAttributes.choiceRate');
    selectedTerm = get(apFinConfig, 'defaultLoanProductAttributes.loanTerm');
    isFOEnabled = get(apFinConfig, 'show');
    selectedStates = (apFinConfig.applicableStatesAttributes || [])
    .filter((stateConfig) => !stateConfig._destroy).map((stateConfig) => stateConfig.state);
  }
  const availableTerms = uniq((loanProducts || []).map((product) => product.loanTerm));

  const choiceRatesForTerm = (term) => {
    const nonInterestCapStates = selectedStates.filter((x) => !Object.keys(INTEREST_RATE_CA).includes(x.toUpperCase()));
    const capRates = nonInterestCapStates.length > 0 ?
      [] : selectedStates.map((state) => INTEREST_RATE_CA[state.toUpperCase()]);
    const capRateFilter = Math.min(...capRates);
    return (loanProducts || []).filter((product) =>
      product.loanTerm === term && product.choiceRate <= capRateFilter).map((product) => product.choiceRate);
  };

  const [ isTermDropDownOpen, setIsTermDropDownOpen ] = useState(false);
  const [ isDefaultAprDropDownOpen, setIsDefaultAprDropDownOpen ] = useState(false);

  const handleFocusChange = (ref) => {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          if (ref.current.id === LOAN_TERM_DROPDOWN_ID) {
            setIsTermDropDownOpen(false);
          } else if (ref.current.id === DEFAULT_APR_DROPDOWN_ID) {
            setIsDefaultAprDropDownOpen(false);
          }
        }
      }

      // Bind the event listener
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, [ref]);
  };

  const capRateError = (selectedChoiceRate) => {
    const interestCapStates = selectedStates.filter((x) => Object.keys(INTEREST_RATE_CA).includes(x.toUpperCase()));
    const capRateFilter = interestCapStates.filter((state) =>
      INTEREST_RATE_CA[state.toUpperCase()] < selectedChoiceRate);
    if (capRateFilter.length > 0) {
      return (
        <span className="error-message">
          This APR is not available in following states
          {capRateFilter.map((x) =>
            <div>{getStateNameByStateCode(x)}</div>)
          }
        </span>
      );
    }
    return (<div />);
  };

  handleFocusChange(loanTermDropDownRef);
  handleFocusChange(defaultAPRDropDownRef);

  const toggleDropDown = (isTermDropDown) => {
    if (isTermDropDown) {
      setIsTermDropDownOpen(!isTermDropDownOpen);
    } else {
      setIsDefaultAprDropDownOpen(!isDefaultAprDropDownOpen);
    }
  };

  const termOptions = (<ul>
    {
      availableTerms.length > 0 && (
        <li
          className={cx('cs-module-option-container', { 'cs-selected': selectedTerm === null })}
          onClick={() => {
            if (!isFOEnabled) {
              return;
            }
            updateApDefaultLoanProduct(apFinConfig.id,
              { loanTerm: null, choiceRate: null, label: null, aprType: null }
            );
            updateValidationErrors(financeType, 'AprVal', false);
            toggleDropDown(true);
          }}
        >
          <span className="cs-option">
            <div className="cs-option-name">-- Select --</div>
          </span>
        </li>
      )
    }
    { availableTerms.map((option) => {
      return (
        <li
          className={cx('cs-module-option-container', { 'cs-selected': option === selectedTerm })}
          onClick={() => {
            if (!isFOEnabled) {
              return;
            }
            updateApDefaultLoanProduct(apFinConfig.id,
               { loanTerm: option, choiceRate: null, label: null, aprType: null }
            );
            updateValidationErrors(financeType, 'AprVal', true);
            toggleDropDown(true);
          }}
        >
          <span className="cs-option">
            <div className="cs-option-name">{option} Years</div>
          </span>
        </li>
      );
    })}
  </ul>);

  const updateChoiceRate = (rate) => {
    const loanProduct = (loanProducts)
      .find((product) => product.loanTerm === selectedTerm && product.choiceRate === rate);
    if (rate) updateValidationErrors(financeType, 'AprVal', false);
    else updateValidationErrors(financeType, 'AprVal', true);

    if (loanProduct) {
      updateApDefaultLoanProduct(apFinConfig.id, {
        ...loanProduct
      });
    }
  };

  const choiceRateOptions = () => {
    const availableChoiceRates = choiceRatesForTerm(selectedTerm) || [];

    return (<ul>
      {
        availableChoiceRates.length > 0 && (
          <li
            className={cx('cs-module-option-container', { 'cs-selected': selectedChoiceRate === null })}
            onClick={() => {
              if (!isFOEnabled) {
                return;
              }
              updateChoiceRate(null);
              toggleDropDown(true);
            }}
          >
            <span className="cs-option">
              <div className="cs-option-name">-- Select --</div>
            </span>
          </li>
        )
      }
      { availableChoiceRates.map((option) => {
        return (
          <li
            className={cx('cs-module-option-container', { 'cs-selected': option === selectedChoiceRate })}
            onClick={() => {
              if (!isFOEnabled) {
                return;
              }
              updateChoiceRate(option);
              toggleDropDown(false);
            }}
          >
            <span className="cs-option">
              <div className="cs-option-name">{option}%</div>
            </span>
          </li>
        );
      })}
    </ul>);
  };

  return (
    <div className="admin-section-data-container border-bottom">
      <div className="admin-section-data-heading-container flex-start">
        <div className="module-section-heading-label">
          <b>Select the default loan term</b> you want to show first within the loan calculator:
        </div>
      </div>
      <div className="details-container width-90">
        <div className="select-container">
          <div className="label">SELECT THE LOAN TERM</div>
          <div
            id={LOAN_TERM_DROPDOWN_ID}
            ref={loanTermDropDownRef}
            className={cx('input-container input-container--icon icon-right dropdown-with-tags option-select',
              { disabled: !isFOEnabled }
            )}
          >
            <i className="icon icon-chevron-down" />
            <div className={cx('select-dropdown cs-skin-border', { 'cs-active': isTermDropDownOpen })}>
              <span
                className="cs-placeholder" onClick={() => {
                  if (!isFOEnabled) {
                    return;
                  }
                  toggleDropDown(true);
                }}
              >
                <div className="cs-option-name">{selectedTerm ? `${selectedTerm} Years` : 'Select Term'}</div>
              </span>
              <div className="cs-options">
                {termOptions}
              </div>
            </div>
          </div>
        </div>
        <div className="select-container">
          <div className="label">DEFAULT APR</div>
          <div
            id={DEFAULT_APR_DROPDOWN_ID}
            ref={defaultAPRDropDownRef}
            className={cx('input-container input-container--icon icon-right dropdown-with-tags option-select',
              { disabled: !isFOEnabled }
            )}
          >
            <i className="icon icon-chevron-down" />
            <div className={cx('select-dropdown cs-skin-border', { 'cs-active': isDefaultAprDropDownOpen })}>
              <span
                className="cs-placeholder" onClick={() => {
                  if (!isFOEnabled) {
                    return;
                  }
                  toggleDropDown(false);
                }}
              >
                <div className="cs-option-name">
                  {selectedChoiceRate ? `${selectedChoiceRate}%` : 'Select APR'}
                </div>
              </span>
              <div className="cs-options">
                {choiceRateOptions()}
              </div>
            </div>
          </div>
          {capRateError(selectedChoiceRate)}
        </div>
      </div>
    </div>
  );
}

LoanInputFieldsView.propTypes = {
  financeType: PropTypes.string,
  apFinConfig: PropTypes.objectOf(PropTypes.object),
  apSalesforce: PropTypes.arrayOf(PropTypes.object),
  updateApDefaultLoanProduct: PropTypes.func,
  updateValidationErrors: PropTypes.func
};

export default register(
  [],
  [],
  LoanInputFieldsView
);
