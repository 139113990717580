import PropTypes from 'prop-types';

export const dropdownsType = PropTypes.shape({
  isModuleListOpen: PropTypes.bool.isRequired,
  isMonitoringConfigurationOpen: PropTypes.bool.isRequired,
  isStorageListOpen: PropTypes.bool.isRequired,
  isStorageQuantityOpen: PropTypes.bool.isRequired,
  isInverterQuantityOpen: PropTypes.bool.isRequired,
  isStorageListWithMultiInverterOpen: PropTypes.bool.isRequired,
  isStorageQuantityWithMultiInverterOpen: PropTypes.bool.isRequired,
  isExpansionPackQuantityOpen: PropTypes.bool.isRequired,
  isEvChargerListOpen: PropTypes.bool.isRequired,
  isEvChargerQuantityOpen: PropTypes.bool.isRequired,
  isEvOutletListOpen: PropTypes.bool.isRequired,
  isEvOutletQuantityOpen: PropTypes.bool.isRequired,
  isDesignListOpen: PropTypes.bool.isRequired
});
