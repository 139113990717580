import React from 'react';
import PropTypes from 'prop-types';
import { gaTrackEvent } from '../../utils/googleAnalytics';
import styles from '../../../scss/charts/EscalatorSelection.module.scss';

export default function EscalatorSelection({
  rates,
  inflationRate,
  onChangeRate,
  refNumber,
  readOnly
}) {
  return (
    <div className={styles.savingsEscalator} id="escalator-selection-container">
      <div className={styles.title}>
          Estimates are based on your utility bill growing annually by&nbsp;
        </div>
      <div className={styles.inputGroup}>
        { readOnly
          ? (<span>
            {inflationRate}% <sup>{refNumber}</sup>
          </span>)
          : (<select
            value={inflationRate}
            onChange={(env) => {
              onChangeRate(env);
              gaTrackEvent('quotePage:savingsTab:EstimatesBasedOnUtilityRateGrowing');
            }}
          >
            {rates.map((item) =>
              <option value={item} key={`rate_${item}`}>{item}%</option>
            )}
          </select>)
        }
      </div>
    </div>
  );
}
EscalatorSelection.propTypes = {
  readOnly: PropTypes.bool.isRequired,
  refNumber: PropTypes.number.isRequired,
  rates: PropTypes.arrayOf(PropTypes.string).isRequired,
  inflationRate: PropTypes.string.isRequired,
  onChangeRate: PropTypes.func.isRequired
};
