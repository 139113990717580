import { asyncPayload } from './apiHelpers';
import Api from '../utils/Api';

export function updateStorage(quote, params) {
  return {
    type: 'updateStorage',
    payload: asyncPayload({
      request: Api.update(`quotes/${quote.sfid}/select_storage`, {
        quote: params
      }),
      afterRequest: {
        quote: {
          finEng: null
        },
        storageChanged: true
      }
    })
  };
}
