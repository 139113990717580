export function toggleAddEvChargerForSOModal() {
  return {
    type: 'toggleAddEvChargerForSOModal',
    payload: {
      storageQuote: {
        isAddEvChargerForSOModalOpen: (currentValue) => !currentValue
      }
    }
  };
}
