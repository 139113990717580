import constants from './EddieCalculations/constants';
import { partnerChannelIsIndirect } from './EddieCalculations/helpers/account';


const validateStorageCommission = (value, account) => {
  const partner = account.partner;
  const isIndirect = partnerChannelIsIndirect(partner);
  const { MAX_STORAGE_COMMISSION_ID } = constants;
  const storageCommission = value && (parseFloat(value));
  let maxStorageCommission;
  if (isIndirect) {
    maxStorageCommission = MAX_STORAGE_COMMISSION_ID;
  }
  if (storageCommission > maxStorageCommission) {
    return `Storage commission exceeds the allowable amount. Please include a storage commission equal
            to or less than $${maxStorageCommission}`;
  }
  return null;
};

export default validateStorageCommission;
