export const toggleStorageQuoteModal = (modalName, target, defaultCoordinates = null) => (dispatch, getState) => {
  return dispatch({
    type: 'toggleModal',
    payload: {
      modals: ({ name: currentName }) => {
        if (modalName === currentName) {
          return { name: null, coordinates: null, stateOnOpen: null };
        }

        let coordinates = defaultCoordinates;

        if (target) {
          const {
            top: targetTop,
            left: targetLeft,
            width: targetWidth,
            height: targetHeight
          } = target.getBoundingClientRect();
          if (modalName === 'settings') {
            coordinates = {
              top: targetTop + targetHeight,
              left: targetLeft + targetWidth / 2
            };
          } else {
            coordinates = {
              top: targetTop + targetHeight + 10,
              left: (targetLeft + targetWidth / 2) - 300
            };
          }
        }

        return {
          name: modalName,
          coordinates,
          stateOnOpen: JSON.stringify(getState())
        };
      }
    }
  });
};
