export function closeModuleSelectionModal() {
  return {
    type: 'closeModuleSelectionModal',
    payload: {
      design: {
        moduleSelectionModal: false
      },
      moduleSelectionModal: {
        selectedSeries: '',
        series: [],
        selectedColor: '',
        colors: [],
        selectedModel: '',
        models: [],
        selectedMonitoring: '',
        isCritterGuardSelected: '',
        isFrontEdgeSkirtGuardSelected: '',
        inverters: [],
        selectedCellularMonitoring: null,
        cellularMonitoringExists: null,
        selectedRacking: '',
        rackings: [],
        selectedStorage: null,
        selectedStorageQuantity: null,
        storages: []
      }
    }
  };
}
