import u from 'updeep';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import * as selectors from '../selectors';
import * as actions from '../actions';

function read(keys, obj) {
  return keys.map((key) => {
    if (!(key in obj)) {
      throw new Error(`[register] Unknown selector or action: ${key}`);
    }

    return obj[key];
  });
}

function zip(keys, values) {
  const obj = {};
  for (let index = 0; index < keys.length; index++) {
    obj[keys[index]] = values[index];
  }
  return obj;
}

export function register(selectorNames, actionNames, Component) {
  const stateSelectors = !selectorNames.length ? null : createSelector(
    ...read(selectorNames, selectors),
    (...args) => args.reduce((result, item) => u(item, result))
  );


  return connect(
    stateSelectors,
    (dispatch) => bindActionCreators(
      zip(
        actionNames,
        read(actionNames, actions)
      ),
      dispatch
    )
  )(Component);
}
