import React from 'react';
import PropTypes from 'prop-types';
import TotalSavingsGraphChart from './TotalSavingsGraphChart';
import { register } from '../../utils/redux';
import { totalSavingsChartType } from '../../types';
import makeC3Options from '../../utils/TotalSavingsChart/makeC3Options';
import onRendered from '../../utils/TotalSavingsChart/onRendered';
import { mortgageEnabled } from '../../utils/backendFeatureFlags';
import EscalatorToggle from './EscalatorToggle';

export function TotalSavingsGraphView({
  activeTab,
  totalSavingsChart,
  hasEscalators,
  inflationRate,
  onChangeRate,
  partnerSectorLeaseIsNewHomes,
  leaseTerm
}) {
  const chartOptions = makeC3Options(
    totalSavingsChart,
    activeTab,
    inflationRate
  );
  const years =
    activeTab === 'lease'
      ? (leaseTerm || 20)
      : mortgageEnabled() && activeTab === 'cash' && partnerSectorLeaseIsNewHomes
      ? (leaseTerm <= 25 ? 25 : 30)
      : 25;
  const title = `Estimated Payments and Savings Over ${years} Years`;
  const rates = Object.keys(totalSavingsChart);

  return (
    <div>
      <div className="title-legend">{title}</div>
      <div className="two-lines-legend">
        <div className="col-2">
          {inflationRate}% Annual Electric Bill Growth
              </div>
        <div className="col-1">0% Annual Electric Bill Growth</div>
      </div>
      <div className="two-lines-legend">
        <div className="col-1">No Solar</div>
        <div className="col-1">With SunPower</div>
        <div className="col-1">With SunPower</div>
      </div>
      <TotalSavingsGraphChart
        key={activeTab}
        className="chart"
        {...chartOptions}
        onrendered={onRendered}
      />
      <EscalatorToggle
        rates={rates}
        hasEscalators={hasEscalators}
        inflationRate={inflationRate}
        onChangeRate={onChangeRate}
      />
    </div>
  );
}

TotalSavingsGraphView.propTypes = {
  activeTab: PropTypes.string.isRequired,
  totalSavingsChart: totalSavingsChartType.isRequired,
  hasEscalators: PropTypes.bool.isRequired,
  inflationRate: PropTypes.string.isRequired,
  onChangeRate: PropTypes.func.isRequired,
  partnerSectorLeaseIsNewHomes: PropTypes.bool.isRequired,
  leaseTerm: PropTypes.number
};

export default register(
  [ 'totalSavingsChartSelector', 'partnerSectorLeaseIsNewHomesSelector' ],
  [],
  TotalSavingsGraphView
);
