import React from 'react';
import ReactDOMServer from 'react-dom/server';
import { Provider } from 'react-redux';
import DesignVisualization from '../components/DesignVisualization';
import store from '../store';
import {
} from '../config';

function svgToDataUri(markup) {
  const encodedMarkup = window.btoa(markup);
  return `data:image/svg+xml;base64,${encodedMarkup}`;
}

function backgroundToDataUri(markup) {
  const parser = new DOMParser();
  const doc = parser.parseFromString(markup, 'text/html');
  const svg = doc.querySelector('.visualization');
  const backgroundImage = svg.style.backgroundImage;
  return backgroundImage.length > 0 ? backgroundImage.slice(4, -1).replace(/"/g, '') : null;
}

function dataUriToImageElement(dataUri) {
  const image = document.createElement('img');
  return new Promise((resolve, reject) => {
    image.onload = () => resolve(image);
    image.onerror = () => reject('Image failed to load');
    image.src = dataUri;
  });
}

function imageElementToPngDataUri(svgImageElement, satelliteImageElement) {
  const canvas = document.createElement('canvas');
  canvas.width = 500;
  canvas.height = 500;
  const ctx = canvas.getContext('2d');
  if (satelliteImageElement) ctx.drawImage(satelliteImageElement, 0, 0, 500, 500);
  ctx.drawImage(svgImageElement, 0, 0);
  return canvas.toDataURL();
}

export default async function() {
  const component = (
    <Provider store={store}>
      <DesignVisualization svgWidth={500} svgHeight={500} proposalView={true} />
    </Provider>
  );
  const staticMarkup = ReactDOMServer.renderToStaticMarkup(component);
  const svgDataUri = svgToDataUri(staticMarkup);
  const satelliteImgDataUri = backgroundToDataUri(staticMarkup);
  const svgImageElement = await dataUriToImageElement(svgDataUri);
  const satelliteImageElement = satelliteImgDataUri ? await dataUriToImageElement(satelliteImgDataUri) : null;
  const pngDataUri = imageElementToPngDataUri(svgImageElement, satelliteImageElement);
  return pngDataUri.replace('data:image/png;base64,', '');
}
