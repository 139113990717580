import PropTypes from 'prop-types';
import React from 'react';
import CloseIcon from '../../../../images/close.svg';
import { Modal } from '../../Modal';
import { DownloadableList } from './DownloadableList';

export function DownloadListModal({
  setDownloadList,
  financialOfferings,
  utilities
}) {
  return (
    <Modal
      id="download-list-modal"
      name="download-list-modal"
      containerClass="container"
      showButtons={false}
      modals={{}}
    >
      <div className="header">
        <div className="left-head">
          <a
            className="close"
            onClick={() => setDownloadList(false)}
          >
            <CloseIcon />
          </a>
        </div>
        <span className="title">
          Downloadable lists
        </span>
      </div>
      <DownloadableList
        financialOfferings={financialOfferings}
        utilities={utilities}
      />
    </Modal>
  );
}

DownloadListModal.propTypes = {
  setDownloadList: PropTypes.func,
  utilities: PropTypes.arrayOf(PropTypes.object),
  financialOfferings: PropTypes.arrayOf(PropTypes.object)
};
