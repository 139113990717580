import get from 'lodash/get';
import { setError } from './errors';
import validateSystem from '../utils/validations/validateSystem';
import getAdders from '../utils/getAdders';
import {
  validateEvChargerOfferings,
  validateEvChargerPrice,
  validateEvSelectionAndUpdateSettings,
  validateLoanMaxAmountMosaic,
  validatePricePerWattBasedOffTotalSystemPrice,
  validateStoragePartnerPermission,
  validateStoragePrice,
  validateStorageSelectionAndUpdateSettings,
  validateSystemPrice
} from '../utils/validations/validateSystemPrice';
import * as s from '../selectors';
import { gaTrackEvent } from '../utils/googleAnalytics';
import { updateManualModeRoofDesign } from './updateManualModeRoofDesign';
import { updateCompleteSettings } from './updateCompleteSettings';
import { updateSettings } from './updateSettings';

const CT_MA_BUY_ALL_ID = [ '3398759', '3394817', '3398760', '3398788', '3398787',
  '2610', '3290446', '3385609', '81002', '3287514', '81192', '3318181' ];

export const validateAndSaveManualModeDesignPage = () => (dispatch, getState) => {
  let { settings } = s.select(getState(), [s.settingsSelector]);
  const {
    productConfiguration, design, selectedInverters,
    quote, pvModule, systemCapacityWatts, partnerIsTps, partnerIsNewHomes,
    account, selectedInverterItems, inverterUnits, partnerIsRvar, selectedStorage,
    annualKwhProduction, partnerIsSpd, notifications, partnerIsDirect, selectedStorageWithExpansionPack,
    credit, allSystemCosts, moduleChanged, storageChanged
  } = s.select(getState(), [
    s.settingsSelector, s.productConfigurationSelector, s.designSelector,
    s.selectedInverterSelector, s.dcModuleSelector, s.quoteSelector,
    s.pvModuleSelector, s.selectedInvertersSelector, s.systemCapacityWattsSelector,
    s.partnerIsTpsSelector, s.partnerIsNewHomesSelector, s.accountSelector, s.selectedInverterItemsSelector,
    s.inverterUnitsSelector, s.partnerIsRvarSelector, s.selectedStorageSelector,
    s.annualKwhProductionSelector, s.partnerIsSpdSelector, s.notificationsSelector, s.partnerIsDirectSelector,
    s.selectedStorageWithExpansionPackSelector, s.creditSelector, s.allSystemCostsSelector, s.moduleChangedSelector,
    s.storageChangedSelector
  ]);

  if (settings && (settings.activeTab === 'lease' || settings.activeTab === 'ppa') &&
  account && (account.state === 'CT' || account.state === 'MA') &&
  CT_MA_BUY_ALL_ID.includes(account.proposedRate)) {
    updateSettings(settings.activeTab, 'showCustomer', false);
  }

  const updatedSettingsForStorage = validateStorageSelectionAndUpdateSettings({
    quote,
    settings,
    selectedStorage,
    selectedStorageWithExpansionPack
  });
  dispatch(updateCompleteSettings(updatedSettingsForStorage));
  settings = s.select(getState(), [s.settingsSelector]).settings;

  const updatedSettings = validateEvSelectionAndUpdateSettings({
    quote,
    settings
  });
  dispatch(updateCompleteSettings(updatedSettings));

  settings = s.select(getState(), [s.settingsSelector]).settings;

  const error =
    validateStoragePartnerPermission({
      quote,
      settings,
      productConfiguration,
      partnerIsNewHomes,
      selectedStorage
    }) ||
    validateStoragePrice({
      quote,
      settings,
      partnerIsRvar,
      partnerIsTps,
      partnerIsNewHomes,
      partnerIsSpd,
      selectedStorage,
      selectedStorageWithExpansionPack
    }) ||
    validateSystemPrice({
      quote,
      settings,
      productConfiguration,
      systemCapacityWatts,
      partnerIsTps,
      partnerIsSpd,
      partnerIsDirect,
      annualKwhProduction,
      design,
      account
    }) ||
    validatePricePerWattBasedOffTotalSystemPrice({
      quote,
      settings,
      productConfiguration,
      systemCapacityWatts,
      partnerIsTps,
      notifications
    }) ||
    validateSystem({
      settings,
      productConfiguration,
      design,
      selectedInverters,
      quote,
      pvModule,
      account,
      selectedInverterItems,
      inverterUnits,
      partnerIsRvar,
      partnerIsNewHomes
    }) ||
    validateEvChargerOfferings({
      quote,
      settings,
      pvModule
    }) ||
    validateEvChargerPrice({
      quote,
      settings,
      partnerIsRvar
    });

  if (error === null) {
    const adders = getAdders(account, notifications);

    gaTrackEvent('manualMode:quotePage:designTab:saveDesign');
    const partnerChannel = get(account, 'partner.channel');

    dispatch(updateManualModeRoofDesign(quote.sfid, settings, quote, adders,
      productConfiguration, partnerChannel, moduleChanged, storageChanged));
    return;
  }
  dispatch(setError(error));
};
