import get from 'lodash/get';
import { clearError, setError } from './errors';
import {
  validateSystemForAllPaymentOptions,
  validateSystemSize,
  validateStorageAndAccesoriesWithAdminSetting
} from '../utils/validations/validateSystem';
import getAdders from '../utils/getAdders';
import {
  validateEvChargerOfferings,
  validateEvChargerPrice,
  validateEvSelectionAndUpdateSettings,
  validateLoanMaxAmountMosaic,
  validatePricePerWattBasedOffTotalSystemPrice,
  validateStoragePartnerPermission,
  validateStoragePrice,
  validateStorageSelectionAndUpdateSettings,
  validateSystemPrice
} from '../utils/validations/validateSystemPrice';
import * as s from '../selectors';
import { updateRoofDesign } from './updateRoofDesign';
import { toggleMode } from './toggleMode';
import { gaTrackEvent } from '../utils/googleAnalytics';
import { CALCULATING_SAVINGS } from '../config';
import { updateCompleteSettings } from './updateCompleteSettings';
import { updateSettings } from './updateSettings';

const CT_MA_BUY_ALL_ID = [ '3398759', '3394817', '3398760', '3398788', '3398787',
  '2610', '3290446', '3385609', '81002', '3287514', '81192', '3318181' ];
export const validateAndSaveSystemDesign = () => (dispatch, getState) => {
  dispatch(clearError());
  let { settings } = s.select(getState(), [s.settingsSelector]);

  const {
    productConfiguration,
    design,
    quote,
    systemCapacityWatts,
    partnerIsTps,
    partnerIsNewHomes,
    partnerIsSpd,
    account,
    pvModule,
    selectedInverterItems,
    inverterUnits,
    notifications,
    partnerIsRvar,
    selectedStorage,
    annualKwhProduction,
    instantQuoteMode,
    selectedStorageWithExpansionPack,
    allSystemCosts,
    credit,
    moduleChanged,
    storageChanged,
    partnerIsDirect
  } = s.select(getState(), [
    s.settingsSelector, s.productConfigurationSelector, s.quoteSelector,
    s.designSelector, s.systemCapacityWattsSelector, s.partnerIsTpsSelector,
    s.accountSelector, s.pvModuleSelector, s.selectedInverterItemsSelector,
    s.inverterUnitsSelector, s.notificationsSelector, s.partnerIsRvarSelector,
    s.selectedStorageSelector, s.annualKwhProductionSelector, s.partnerIsNewHomesSelector, s.partnerIsSpdSelector,
    s.instantQuoteModeSelector, s.selectedStorageWithExpansionPackSelector, s.allSystemCostsSelector, s.creditSelector,
    s.moduleChangedSelector, s.storageChangedSelector, s.partnerIsDirectSelector
  ]);

  if (settings && (settings.activeTab === 'lease' || settings.activeTab === 'ppa') &&
  account && (account.state === 'CT' || account.state === 'MA') &&
  CT_MA_BUY_ALL_ID.includes(account.proposedRate)) {
    updateSettings(settings.activeTab, 'showCustomer', false);
  }

  const updatedSettingsForStorage = validateStorageSelectionAndUpdateSettings({
    quote,
    settings,
    selectedStorage,
    selectedStorageWithExpansionPack
  });
  dispatch(updateCompleteSettings(updatedSettingsForStorage));
  settings = s.select(getState(), [s.settingsSelector]).settings;

  const updatedSettings = validateEvSelectionAndUpdateSettings({
    quote,
    settings
  });
  dispatch(updateCompleteSettings(updatedSettings));

  settings = s.select(getState(), [s.settingsSelector]).settings;

  const error =
    validateStoragePartnerPermission({
      quote,
      settings,
      productConfiguration,
      partnerIsNewHomes,
      selectedStorage
    }) ||
    validateStoragePrice({
      quote,
      settings,
      partnerIsRvar,
      partnerIsTps,
      partnerIsSpd,
      partnerIsNewHomes,
      selectedStorage,
      selectedStorageWithExpansionPack
    }) ||
    validateSystemPrice({
      quote,
      settings,
      productConfiguration,
      systemCapacityWatts,
      partnerIsTps,
      partnerIsSpd,
      partnerIsDirect,
      annualKwhProduction,
      design,
      account
    }) || validatePricePerWattBasedOffTotalSystemPrice({
      quote,
      settings,
      productConfiguration,
      systemCapacityWatts,
      partnerIsTps,
      notifications
    }) ||
    validateSystemSize({
      quote, account, settings, design, pvModule, selectedInverterItems, inverterUnits, partnerIsRvar
    }) ||
    validateStorageAndAccesoriesWithAdminSetting({
      settings, quote
    }) ||
    validateSystemForAllPaymentOptions(
      { pvModule, settings, productConfiguration }
    ) ||
    validateEvChargerOfferings({
      quote,
      settings,
      pvModule
    }) ||
    validateEvChargerPrice({
      quote,
      settings,
      partnerIsRvar
    });
  if (error !== null) {
    dispatch(setError(error));
    return;
  }

  const adders = getAdders(account, notifications);
  const partnerChannel = get(account, 'partner.channel');

  gaTrackEvent('quotePage:designTab:saveDesign');
  dispatch(toggleMode(null));
  dispatch(updateRoofDesign(
    quote.sfid,
    design,
    settings,
    adders,
    quote,
    instantQuoteMode,
    productConfiguration,
    partnerChannel,
    moduleChanged,
    storageChanged)
  );
  dispatch(toggleMode(CALCULATING_SAVINGS));
};
