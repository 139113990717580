import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { CALCULATING_SAVINGS } from '../../config';
import { register } from '../../utils/redux';
import { formatCurrency } from '../../utils/formatNumber';
import ValueBox from '../ValueBox';
import { gaTrackEvent } from '../../utils/googleAnalytics';
import {
  leaseSystemCostType,
  settingsType,
  quoteType,
  modeType,
  accountType
} from '../../types';

export function LeaseView({
  leaseSystemCost,
  settings,
  quote,
  toggleModal,
  mode,
  pricingDisclaimer,
  isPublic,
  account
}) {
  const escalatorText = !settings.lease.fullPrepayment ?
    `(escalating ${parseFloat(settings.lease.paymentEscalator).toFixed(1)}% annually)` : '';

  const shouldDisableClick = mode === CALCULATING_SAVINGS || quote.locked;

  const editLeaseClassNames = cx('edit-lease supporting-text', { disabled: shouldDisableClick });
  const stateTaxCalculationForUI = () => {
    if (account.state === 'HI' && leaseSystemCost && leaseSystemCost.stateTaxCredit) {
      return formatCurrency(parseFloat(0.7 * leaseSystemCost.stateTaxCredit));
    }

    return formatCurrency(leaseSystemCost.stateTaxCredit, 0);
  };
  const nyVerbiage = `SunPower retains the federal investment tax credit
    and certain other incentives (${formatCurrency(leaseSystemCost.stateAndLocalIncentives, 0)})
    on leased systems and uses them to provide lease customers a lower monthly payment. If
    SunPower passed these incentives onto the customer, then the
    payments shown here would be much higher.
    Customers receive a state tax credit (${stateTaxCalculationForUI()}).
    SunPower does not offer a pass-through option to its lease customers.`;
  const otherStateVerbiage = `SunPower retains the federal investment tax credit,
    state tax credit (${stateTaxCalculationForUI()})
    and certain other incentives (${formatCurrency(leaseSystemCost.stateAndLocalIncentives, 0)})
    on leased systems and uses them to provide lease customers a lower monthly payment. If
    SunPower passed these incentives onto the customer, then the
    payments shown here would be much higher. SunPower does not offer a
    pass-through option to its lease customers.`;
  const leaseVerbiage = account.state === 'NY' ? nyVerbiage : otherStateVerbiage;

  const leaseDownPaymentMessage = `After ${formatCurrency(leaseSystemCost.downPayment, 0)} down payment,`;

  return (
    <div className="lease">
      <div className="breakout-summary">
        <div className="lease-summary">
          {leaseSystemCost.downPayment > 0 && (<ValueBox
            label="Down Payment" currency={true}
            rounded={true}
            value={leaseSystemCost.downPayment}
          />)}
          <ValueBox
            label="Monthly"
            currency={true}
            rounded={true}
            value={leaseSystemCost.monthlyPayments[0].costWithTax}
          />
        </div>
        <div className="breakout-summary-breakdown-container">
          <span className="breakout-summary-breakdown supporting-text">
            {`${leaseSystemCost.downPayment > 0 ? leaseDownPaymentMessage : ''}
            Pay ${formatCurrency(leaseSystemCost.monthlyPayments[0].costWithTax, 0)}/month
            ${escalatorText} for ${leaseSystemCost.leaseTerm} years`}
          </span>
          { !isPublic && !quote.locked && (
            <a
              id="lease_view_edit_lease_toggle"
              href="#"
              className={editLeaseClassNames}
              onClick={(e) => {
                if (!shouldDisableClick) {
                  gaTrackEvent('quotePage:savingsTab:leaseSubTab:editLink');
                  toggleModal('edit-lease', e.target);
                }
              }}
            >
              Edit Lease
            </a>
          )}
          { pricingDisclaimer }
          <div className="breakout-line-items">
            <hr />
            <span className="line-item breakout-summary-breakdown supporting-text">
              <div className="label">
                {leaseVerbiage}
              </div>
            </span>
            <hr />
          </div>
        </div>
      </div>
    </div>
  );
}

LeaseView.propTypes = {
  leaseSystemCost: leaseSystemCostType.isRequired,
  settings: settingsType.isRequired,
  quote: quoteType.isRequired,
  toggleModal: PropTypes.func.isRequired,
  mode: modeType,
  pricingDisclaimer: PropTypes.element,
  isPublic: PropTypes.bool,
  account: accountType
};

export default register(
  [
    'leaseSystemCostSelector', 'settingsSelector', 'quoteSelector', 'modeSelector',
    'isPublicSelector', 'accountSelector'
  ],
  [
    'toggleModal'
  ],
  LeaseView
);
