import { asyncPayload } from './apiHelpers';
import Api from '../utils/Api';

// We don't pass the selected module ID, because it's saved in the Quote
export function fetchConfigurations(quote) {
  return {
    type: 'fetchConfigurations',
    payload: asyncPayload({
      request: Api.fetch(`manual_mode/quotes/${quote.sfid}/configurations`)
    })
  };
}
