import { get, isEmpty, keys, pickBy } from 'lodash';
import toNumber from '../toNumber';
import { getStorageName, isSunpowerStorage } from '../sunPowerStorage';
import { formatCurrency } from '../formatNumber';
import { validatePricePerWattForCashLeaseForND } from '../backendFeatureFlags';
import validateStorageCommission from '../validateStorageCommission';
import {
  ALL_PRICE_INPUT_TYPES,
  DEALER_FEE_PER_WATT,
  getAvailableAndEnabledFinanceSettings,
  GROSS_PRICE,
  PENFED,
  PRICE_PER_KWH,
  PRICE_PER_WATT,
  UNDETERMINED
} from '../EddieCalculations/helpers/setting';
import { isLoan } from '../EddieCalculations/helpers/financeTypes';
import constants from '../EddieCalculations/constants';
import {
  getMaxPricePerWattOfAdmin,
  getMinPricePerWattOfAdmin,
  getMaxDealerFeePerWattOfAdmin,
  getMinDealerFeePerWattOfAdmin
} from '../admin_portal/quoteHelpers';
import { hasPriceControlConfig } from '../../utils/pricingDefaultsHelper';
import { isEvChargerSelected, isEvOutletSelected } from '../EvChargers/evChargers';

export const MIN_PRICE_PER_WATT = 2;
export const MIN_PRICE_PER_WATT_LEASE_PPA = 1.5;
export const MAX_PRICE_PER_WATT = 100;
export const MAX_PRICE_PER_WATT_WITH_STORAGE = 100;
export const TCU_MAX_PRICE_PER_WATT = 8;
export const TCU_MAX_PRICE_PER_WATT_WITH_STORAGE = 10;
export const MAX_DEALER_FEE_PER_WATT_TPS_LOAN = 4;

const {
  LOAN_FINANCE_TYPE,
  LEASE_FINANCE_TYPE,
  PPA_FINANCE_TYPE,
  CASH_FINANCE_TYPE
} = constants;

const pricingText = (settingType) => {
  let value = '';
  switch (settingType) {
    case 'pricePerKwh':
      value = 'Price per kWh';
      break;
    case 'pricePerWatt':
      value = 'Price per Watt';
      break;
    case 'grossPrice':
      value = 'Gross Price';
      break;
    case 'dealerFeePerWatt':
      value = 'Dealer Commission per Watt';
      break;
    default:
      value = 'valid';
  }
  return value;
};

const maxValidationMessage = (value) => `Please enter a ${value} value below the maximum system price.`;
const maxFieldMessage = 'must be smaller';
const minValidationMessage = (value) => `Please enter a ${value} value above the minimum system price.`;
const minFieldMessage = 'must be greater';

export const systemPriceGreaterThanZeroMessage = `Pricing information is required in order to calculate Savings.
Please go to settings and enter pricing (Gross Price, Price Per Watt, Price Per kWh or Dealer Fee Per Watt)
for all selected finance options.`;

/**
 * @param {String} systemPriceSelection dealerFeePerWatt, pricePerWatt, or grossPrice.
 * @param {Function} validator Runs against every settings input for systemPriceSelection
 * If it returns true, the financeType will be considered invalid.
 * @returns {Function} Receives necessary selector results and returns array of invalid
 * finance types for the given systemPriceSelection.
 */
const createGetInvalidFinanceTypes = (systemPriceSelection, validator) => (selections) => {
  const financeTypes = getAvailableAndEnabledFinanceSettings(selections);
  return keys(pickBy(financeTypes, (financeTypeSettings, financeType) =>
    financeTypeSettings.systemPriceSelection === systemPriceSelection &&
      validator(
        toNumber(financeTypeSettings[systemPriceSelection]),
        financeType,
        selections
      )
  ));
};

const priceConfigValidation = (
  priceConfig,
  dollarValue,
  isMin = true
) => {
  const limitValue = isMin ? priceConfig.minDollarValue : priceConfig.maxDollarValue;
  if (limitValue === undefined) {
    return true;
  }
  return isMin ? dollarValue >= limitValue : dollarValue <= limitValue;
};

/**
 * Dealer Fee per Watt
 */

const dealerFeePerWattMaxPriceValidator = (dealerFeePerWatt, financeType, {
  quote,
  account,
  design,
  partnerIsTps,
  settings,
  systemCapacityWatts
}) => {
  let minPricePerWatt = MIN_PRICE_PER_WATT;
  if (partnerIsTps) {
    minPricePerWatt = get(quote, `finEngMinPrice.${financeType}.minPricePerWatt`) || MIN_PRICE_PER_WATT;
  }
  if (isLoan(financeType) && partnerIsTps) {
    if (dealerFeePerWatt > MAX_DEALER_FEE_PER_WATT_TPS_LOAN) {
      return true;
    }
  }
  const discount = toNumber(get(settings, `${financeType}.discount`));
  const discountPerWatt = systemCapacityWatts === 0 ? 0 : (discount / systemCapacityWatts);
  const maxDealerFeePerWattOfAdmin = getMaxDealerFeePerWattOfAdmin(quote.adminFinConfigs, financeType);


  const priceConfigs = hasPriceControlConfig({
    quote,
    financeType,
    pricingOption: 'dealerFeePerWatt',
    account,
    design
  });
  if (!priceConfigs || priceConfigs.length === 0) {
    if (maxDealerFeePerWattOfAdmin) {
      return (dealerFeePerWatt - discountPerWatt) > (maxDealerFeePerWattOfAdmin || MAX_PRICE_PER_WATT);
    }
    return (minPricePerWatt + dealerFeePerWatt - discountPerWatt) > MAX_PRICE_PER_WATT;
  }
  const priceConfigValidationBool = priceConfigValidation(
    priceConfigs[priceConfigs.length - 1],
    dealerFeePerWatt,
    false
  );

  if (!priceConfigValidationBool) {
    return true;
  }

  return false;
};

const dealerFeePerWattMinPriceValidator = (dealerFeePerWatt, financeType, {
  settings, quote, systemCapacityWatts, account, design
}) => {
  const discount = toNumber(get(settings, `${financeType}.discount`));
  const discountPerWatt = systemCapacityWatts === 0 ? 0 : (discount / systemCapacityWatts);
  const minDealerFeePerWattOfAdmin = getMinDealerFeePerWattOfAdmin(quote.adminFinConfigs, financeType);

  const priceConfigs = hasPriceControlConfig({
    quote,
    financeType,
    pricingOption: 'dealerFeePerWatt',
    account,
    design
  });
  if (!priceConfigs || priceConfigs.length === 0) {
    if (minDealerFeePerWattOfAdmin) {
      return (dealerFeePerWatt - discountPerWatt) < minDealerFeePerWattOfAdmin;
    }
    return (dealerFeePerWatt - discountPerWatt) <= 0;
  }
  const priceConfigValidationBool = priceConfigValidation(
    priceConfigs[priceConfigs.length - 1],
    dealerFeePerWatt
  );

  if (!priceConfigValidationBool) {
    return true;
  }

  return false;
};
const dealerFeePerWattValidations = ({
  max: createGetInvalidFinanceTypes(DEALER_FEE_PER_WATT, dealerFeePerWattMaxPriceValidator),
  min: createGetInvalidFinanceTypes(DEALER_FEE_PER_WATT, dealerFeePerWattMinPriceValidator)
});

/**
 * Price per Watt
 */

const pricePerWattMaxPriceValidator = (pricePerWatt, financeType, {
  quote, settings, systemCapacityWatts, account, design
}) => {
  if (pricePerWatt > 100) {
    return true;
  }
  const discount = toNumber(get(settings, `${financeType}.discount`));
  const discountPerWatt = systemCapacityWatts === 0 ? 0 : (discount / systemCapacityWatts);

  const creditBureau = get(quote, 'creditBureau');
  const maxPricePerWattOfAdmin = getMaxPricePerWattOfAdmin(quote.adminFinConfigs, financeType);
  const priceConfigs = hasPriceControlConfig({
    quote,
    financeType,
    pricingOption: 'pricePerWatt',
    account,
    design
  });
  if (!priceConfigs || priceConfigs.length === 0) {
    return (pricePerWatt - discountPerWatt) > (maxPricePerWattOfAdmin || MAX_PRICE_PER_WATT);
  }
  const priceConfigValidationBool = priceConfigValidation(
    priceConfigs[priceConfigs.length - 1],
    pricePerWatt,
    false
  );

  if (!priceConfigValidationBool) {
    return true;
  }

  if (financeType && financeType.toLowerCase() === LOAN_FINANCE_TYPE.toLowerCase()) {
    if (
      creditBureau && (creditBureau.toLowerCase() === PENFED.toLowerCase() ||
      creditBureau.toLowerCase() === UNDETERMINED.toLowerCase())
    ) {
      return false;
    }
    if (creditBureau) {
      return maxPricePerWattOfAdmin ? (pricePerWatt - discountPerWatt) > (maxPricePerWattOfAdmin) : false;
    }
  }
  return false;
};

const pricePerWattMinPriceValidator = (pricePerWatt, financeType, {
  quote, design, account, partnerIsTps, settings, systemCapacityWatts, partnerIsSpd, partnerIsDirect
}) => {
  let minPricePerWatt;

  const discount = toNumber(get(settings, `${financeType}.discount`));
  const discountPerWatt = systemCapacityWatts === 0 ? 0 : (discount / systemCapacityWatts);

  const priceConfigs = hasPriceControlConfig({
    quote,
    financeType,
    pricingOption: 'pricePerWatt',
    account,
    design
  });
  if (!priceConfigs || priceConfigs.length === 0) {
    const minPricePerWattOfAdmin = getMinPricePerWattOfAdmin(quote.adminFinConfigs, financeType);
    if (minPricePerWattOfAdmin) {
      minPricePerWatt = minPricePerWattOfAdmin;
    } else {
      minPricePerWatt = MIN_PRICE_PER_WATT;
      if ((financeType.toLowerCase() === LEASE_FINANCE_TYPE.toLowerCase() ||
        financeType.toLowerCase() === PPA_FINANCE_TYPE.toLowerCase()) && (partnerIsSpd || partnerIsDirect)) {
        minPricePerWatt = MIN_PRICE_PER_WATT_LEASE_PPA;
      }
      if (partnerIsTps) {
        if (financeType && financeType.toLowerCase() === LEASE_FINANCE_TYPE.toLowerCase() ||
        financeType && financeType.toLowerCase() === PPA_FINANCE_TYPE.toLowerCase()) {
          minPricePerWatt = MIN_PRICE_PER_WATT_LEASE_PPA;
        } else {
          minPricePerWatt = get(quote, `finEngMinPrice.${financeType}.minPricePerWatt`) || MIN_PRICE_PER_WATT;
        }
      }
    }
    return (pricePerWatt - discountPerWatt) < minPricePerWatt;
  }

  const priceConfigValidationBool = priceConfigValidation(
    priceConfigs[priceConfigs.length - 1],
    pricePerWatt
  );

  if (!priceConfigValidationBool) {
    return true;
  }
  return false;
};
const pricePerWattValidations = ({
  max: createGetInvalidFinanceTypes(PRICE_PER_WATT, pricePerWattMaxPriceValidator),
  min: createGetInvalidFinanceTypes(PRICE_PER_WATT, pricePerWattMinPriceValidator)
});

/**
 * Gross Price
 */

const grossPriceMaxPriceValidator = (grossPrice, financeType, { settings, systemCapacityWatts }) => {
  if (systemCapacityWatts === 0) return false;
  const discount = toNumber(get(settings, `${financeType}.discount`));

  return ((grossPrice - discount) / systemCapacityWatts) > MAX_PRICE_PER_WATT;
};
const grossPriceMinPriceValidator = (grossPrice, financeType, {
  quote, partnerIsTps, settings, systemCapacityWatts, partnerIsSpd, partnerIsDirect
}) => {
  if (systemCapacityWatts === 0) return false;

  let minPricePerWatt;
  const minPricePerWattOfAdmin = getMinPricePerWattOfAdmin(quote.adminFinConfigs, financeType);

  if (minPricePerWattOfAdmin) {
    minPricePerWatt = minPricePerWattOfAdmin;
  } else {
    minPricePerWatt = MIN_PRICE_PER_WATT;
    if ((financeType.toLowerCase() === LEASE_FINANCE_TYPE.toLowerCase() ||
      financeType.toLowerCase() === PPA_FINANCE_TYPE.toLowerCase()) && (partnerIsSpd || partnerIsDirect)) {
      minPricePerWatt = MIN_PRICE_PER_WATT_LEASE_PPA;
    }
    if (partnerIsTps) {
      if (financeType && financeType.toLowerCase() === LEASE_FINANCE_TYPE.toLowerCase() ||
      financeType && financeType.toLowerCase() === PPA_FINANCE_TYPE.toLowerCase()) {
        minPricePerWatt = MIN_PRICE_PER_WATT_LEASE_PPA;
      } else {
        minPricePerWatt = get(quote, `finEngMinPrice.${financeType}.minPricePerWatt`) || MIN_PRICE_PER_WATT;
      }
    }
  }

  const discount = toNumber(get(settings, `${financeType}.discount`));
  return ((grossPrice - discount) / toNumber(systemCapacityWatts)) < minPricePerWatt;
};
const grossPriceValidations = {
  max: createGetInvalidFinanceTypes(GROSS_PRICE, grossPriceMaxPriceValidator),
  min: createGetInvalidFinanceTypes(GROSS_PRICE, grossPriceMinPriceValidator)
};

/**
 * Price Per kWh
 */

const pricePerKwhMaxPriceValidator = (pricePerKwh, financeType, {
  quote, account, design
}) => {
  const priceConfigs = hasPriceControlConfig({
    quote,
    financeType,
    pricingOption: 'pricePerKwh',
    account,
    design
  });
  if (!priceConfigs || priceConfigs.length === 0) {
    return false;
  }

  const priceConfigValidationBool = priceConfigValidation(
    priceConfigs[priceConfigs.length - 1],
    pricePerKwh,
    false
  );

  if (!priceConfigValidationBool) {
    return true;
  }

  return false;
};
const pricePerKwhMinPriceValidator = (pricePerKwh, financeType, {
  quote, account, design
}) => {
  const priceConfigs = hasPriceControlConfig({
    quote,
    financeType,
    pricingOption: 'pricePerKwh',
    account,
    design
  });
  if (!priceConfigs || priceConfigs.length === 0) {
    return false;
  }
  const priceConfigValidationBool = priceConfigValidation(
    priceConfigs[priceConfigs.length - 1],
    pricePerKwh
  );

  if (!priceConfigValidationBool) {
    return true;
  }

  return false;
};
const pricePerKwhValidations = ({
  max: createGetInvalidFinanceTypes(PRICE_PER_KWH, pricePerKwhMaxPriceValidator),
  min: createGetInvalidFinanceTypes(PRICE_PER_KWH, pricePerKwhMinPriceValidator)
});

const getInvalidFinanceTypes = {
  [DEALER_FEE_PER_WATT]: dealerFeePerWattValidations,
  [GROSS_PRICE]: grossPriceValidations,
  [PRICE_PER_WATT]: pricePerWattValidations,
  [PRICE_PER_KWH]: pricePerKwhValidations
};

/**
 * @param {Object} selections Object of selector results.
 * @param {Object} selections.settings
 * @param {Object} selections.productConfiguration
 */
export const validateSystemPriceGreaterThanZero = ({ settings, productConfiguration }) => {
  const financeTypes = getAvailableAndEnabledFinanceSettings({ settings, productConfiguration });
  const invalidFinanceTypes = keys(pickBy(financeTypes, (setting) => {
    switch (setting.systemPriceSelection) {
      case PRICE_PER_WATT: {
        return !toNumber(setting.pricePerWatt);
      }
      case PRICE_PER_KWH: {
        return !toNumber(setting.pricePerKwh);
      }
      case DEALER_FEE_PER_WATT: {
        return isNaN(parseFloat(setting.dealerFeePerWatt));
      }
      default: {
        return !toNumber(setting.grossPrice);
      }
    }
  }));
  if (invalidFinanceTypes.length === 0) return null;

  return {
    message: systemPriceGreaterThanZeroMessage,
    fieldErrors: invalidFinanceTypes.reduce((previous, financeType) => ({
      ...previous,
      [financeType]: {
        [settings[financeType].systemPriceSelection]: 'can\'t be blank'
      }
    }), {})
  };
};

export const validateStorageQuantity = ({ settings, quote }) => {
  const validateLoan = settings.loan && settings.loan.showCustomer;
  if (validateLoan && quote.storageQuantity && quote.storageQuantity > 1) {
    return {
      message:
        'The loan financial offering is not currently available for greater than one storage unit.' +
        ' Please update your Quote Settings before proceeding.'
    };
  }
  return null;
};

export const validateStoragePartnerPermission = ({ settings, quote, productConfiguration, partnerIsNewHomes }) => {
  if (partnerIsNewHomes) {
    return null;
  }
  if (productConfiguration.storagePermission && quote.storageQuantity > 0) {
    const validateCash = settings.cash && settings.cash.showCustomer;
    const validateLoan = settings.loan && settings.loan.showCustomer;
    const validateLease = settings.lease && settings.lease.showCustomer;
    const validatePpa = settings.ppa && settings.ppa.showCustomer;
    let validOptionsForPartner =
    Object.keys(productConfiguration.storagePermission).filter((sp) => productConfiguration.storagePermission[sp]);
    validOptionsForPartner = validOptionsForPartner.map((option) => option.replace(/storage/g, '').toLowerCase());
    if (validOptionsForPartner.length === 0) {
      return {
        message:
          'Storage is not enabled for this partner. Please update your Quote Settings before proceeding'
      };
    }
    if (validateCash && productConfiguration.storagePermission && !productConfiguration.storagePermission.storageCash) {
      return {
        message:
          `Storage is only available for ${validOptionsForPartner.join(', ')}. Please select another financial offering`
      };
    }
    if (validateLoan && productConfiguration.storagePermission && !productConfiguration.storagePermission.storageLoan) {
      return {
        message:
        `Storage is only available for ${validOptionsForPartner.join(', ')}. Please select another financial offering`
      };
    }
    if (validateLease &&
        productConfiguration.storagePermission &&
        !productConfiguration.storagePermission.storageLease) {
      return {
        message:
        `Storage is only available for ${validOptionsForPartner.join(', ')}. Please select another financial offering`
      };
    }
    if (validatePpa &&
        productConfiguration.storagePermission &&
        !productConfiguration.storagePermission.storagePPA) {
      return {
        message:
        `Storage is only available for ${validOptionsForPartner.join(', ')}. Please select another financial offering`
      };
    }
  }
  return null;
};

export const validateEvCharger = ({ settings, quote, productConfiguration }) => {
  // find the selected evConfig and check evmOfferings and see if selected fo are there or not
  // show the relevant message


  return null;
};

/**
 * @param {Object} selections Object of selector results.
 * @param {Object} selections.settings
 * @param {Object} selections.quote
 * @param {Object} selections.design
 * @param {Object} selections.account
 * @param {Boolean} selections.partnerIsRvar
 * @param {Boolean} selections.partnerIsTps
 * @param {Boolean} selections.partnerIsSpd
 * @param {Boolean} selections.partnerIsNewHomes
 * @param {Object} selections.selectedStorage
 */
export const validateStoragePrice = ({
  settings,
  quote,
  partnerIsRvar,
  partnerIsTps,
  partnerIsNewHomes,
  selectedStorage,
  selectedStorageWithExpansionPack
}) => {
  const isCashSelected = settings.cash && settings.cash.showCustomer;
  const validateNHMortgage = isCashSelected && partnerIsNewHomes;
  const sunPowerStorage = isSunpowerStorage(getStorageName(selectedStorage, selectedStorageWithExpansionPack, quote)); // true if storage is selected
  const validateMortgageStoragePrice =
    settings.cash.storagePriceSelection === 'storagePrice' && settings.cash.storagePrice < quote.storagePrice;
  if (validateNHMortgage && sunPowerStorage && validateMortgageStoragePrice) {
    return {
      message: `Storage Gross Price is less than SKU price ${formatCurrency(quote.storagePrice, 0)}.` +
      ' Please update your Quote Settings before proceeding.'
    };
  }
  return null;
};


/**
* This function is for validating CASH, LEASE for ND/TPS channel only
* when feature flag VALIDATE_PRICE_PER_WATT_FOR_CASH_LEASE_FOR_ND is enabled
*/
export const validatePricePerWattBasedOffTotalSystemPrice = (selections) => {
  if (!validatePricePerWattForCashLeaseForND()) return null;

  const maxValidationMsg = (maxValue, isStorageSelected) => {
    return `The system exceeds the maximum price of $${maxValue} per watt${isStorageSelected ?
      ' with storage.' : '.'}`;
  };

  if (selections.systemCapacityWatts > 0) {
    const systemAdders = get(selections, 'notifications.totalCost', 0);
    const financeTypes = keys(getAvailableAndEnabledFinanceSettings(selections));

    for (let i = 0; i < financeTypes.length; i++) {
      const financeType = financeTypes[i];
      const isFinanceTypeSelected = get(selections, `settings.${financeType}.showCustomer`);
      if (isFinanceTypeSelected) {
        const isStorageSelected = selections.quote &&
          selections.quote.storageQuantity && selections.quote.storageQuantity > 0;
        const systemPriceSelection = get(selections, `settings.${financeType}.systemPriceSelection`);
        const needsFinEng = get(selections, `settings${financeType}.needsFinEng`);
        const finEngData = get(selections, 'quote.finEng', {});
        const finEngHasLease = !isEmpty(finEngData) && finEngData.lease;
        const finEngHasPpa = !isEmpty(finEngData) && finEngData.ppa;
        const pricePerKwhSelected = systemPriceSelection === PRICE_PER_KWH;
        let sumTotal;

        /*
        * TODO
        * Refactor this code as it is duplicate of sumTotal on settingsModal.js.
        * Move adding systemAdders to sumTotal logic to settings.js. It requires updating many tests.
        */
        if (pricePerKwhSelected && !needsFinEng && (finEngHasLease || finEngHasPpa)) {
          sumTotal = Number(get(selections, `settings.${financeType}.sumTotal`, 0));
        } else {
          sumTotal = Number(get(selections, `settings.${financeType}.sumTotal`, 0)) + systemAdders;
        }

        const pricePerWattFromSumTotal = sumTotal / selections.systemCapacityWatts;

        if (isStorageSelected && pricePerWattFromSumTotal > MAX_PRICE_PER_WATT_WITH_STORAGE) {
          if (selections.partnerIsTps && !isLoan(financeType)) {
            return { message: maxValidationMsg(MAX_PRICE_PER_WATT_WITH_STORAGE, isStorageSelected) };
          }
        } else if (!isStorageSelected && pricePerWattFromSumTotal > MAX_PRICE_PER_WATT) {
          if (selections.partnerIsTps && !isLoan(financeType)) {
            return { message: maxValidationMsg(MAX_PRICE_PER_WATT, isStorageSelected) };
          }
        }
      }
    }
    return null;
  }
  return null;
};

export const validateLoanMaxAmountMosaic = (selections) => {
  const isLoanSelected = get(selections, 'settings.loan.showCustomer');
  if (!isLoanSelected) return null;
  const creditBureau = get(selections, 'quote.creditBureau');
  if (!creditBureau) return null;
  if (creditBureau.toLowerCase() !== 'mosaic') return null;

  const systemCostC = get(selections, 'settings.loan.sumTotal');
  const allTerms = get(selections, 'credit.terms');
  const loanTerm = allTerms[allTerms.length - 1];
  const maxAmounts = get(selections, 'credit.maxAmounts');
  const loanMaxAmount = maxAmounts[loanTerm] || 100000;

  if (systemCostC > loanMaxAmount) {
    return { message: `Loan Amount cannot be greater than ${loanMaxAmount}` };
  }
  return null;
};

/**
 * @param {Object} selections Object of selector results.
 * @param {Object} selections.quote
 * @param {Object} selections.design
 * @param {Object} selections.account
 * @param {Object} selections.settings
 * @param {Object} selections.productConfiguration
 * @param {Number} selections.systemCapacityWatts
 * @param {Boolean} selections.partnerIsTps
 * @param {[]String} settingTypes
 */
export const validateSystemPrice = (selections, settingTypes = ALL_PRICE_INPUT_TYPES) => {
  const error = validateSystemPriceGreaterThanZero(selections);
  if (error !== null) return error;

  for (let i = 0; i < settingTypes.length; i++) {
    const settingType = settingTypes[i];

    const validators = getInvalidFinanceTypes[settingType];
    const validationValue = pricingText(settingType);

    let invalidFinanceTypes = [];
    let errorMessage = null;
    let fieldError = null;
    if (validators.max) {
      invalidFinanceTypes = validators.max(selections);
      if (invalidFinanceTypes.length > 0) {
        errorMessage = maxValidationMessage(validationValue);
        fieldError = maxFieldMessage;
      }
    }
    if (invalidFinanceTypes.length === 0 && validators.min) {
      invalidFinanceTypes = validators.min(selections);
      if (invalidFinanceTypes.length > 0) {
        errorMessage = minValidationMessage(validationValue);
        fieldError = minFieldMessage;
      }
    }
    // eslint-disable-next-line no-continue
    if (invalidFinanceTypes.length === 0) continue;

    return {
      message: errorMessage,
      fieldErrors: invalidFinanceTypes.reduce((previous, financeType) => ({
        ...previous,
        [financeType]: { [settingType]: fieldError }
      }), {})
    };
  }
  return null;
};

export const validateEvChargerPrice = ({
  settings,
  quote,
  partnerIsRvar
}) => {
  const isCashSelected = settings.cash && settings.cash.showCustomer;
  const isLoanSelected = settings.loan && settings.loan.showCustomer;
  const isLeaseSelected = settings.lease && settings.lease.showCustomer;
  const isPpaSelected = settings.ppa && settings.ppa.showCustomer;
  const validateCash = isCashSelected && partnerIsRvar;
  const validateLoan = isLoanSelected && partnerIsRvar;
  const validateLease = isLeaseSelected && partnerIsRvar;
  const validatePpa = isPpaSelected && partnerIsRvar;
  const evChargerSelected = isEvChargerSelected(quote);
  const validCashEvChargerDealerFee = get(settings, 'cash.evChargerDealerFee', 0) > 0;
  const validLoanEvChargerDealerFee = get(settings, 'loan.evChargerDealerFee', 0) > 0;
  const validLeaseEvChargerDealerFee = get(settings, 'lease.evChargerDealerFee', 0) > 0;
  const validPpaEvChargerDealerFee = get(settings, 'ppa.evChargerDealerFee', 0) > 0;

  if (evChargerSelected && ((validateCash && !validCashEvChargerDealerFee) ||
    (validateLoan && !validLoanEvChargerDealerFee) ||
    (validateLease && !validLeaseEvChargerDealerFee) ||
    (validatePpa && !validPpaEvChargerDealerFee))) {
    const msg = partnerIsRvar ? 'Charger Additional Cost' : 'Dealer Commission';
    return {
      message: `EV Dealer ${msg} is required if EV charger is selected.` +
        ' Please update your Quote Settings before proceeding.'
    };
  }
  return null;
};

export const validateStorageDealerCommission = ({
  account,
  settings
}) => {
  const stoargeDealerFeeCash = get(settings, 'cash.storageDealerFee', 0);
  const stoargeDealerFeeLoan = get(settings, 'loan.storageDealerFee', 0);
  const stoargeDealerFeeLease = get(settings, 'lease.storageDealerFee', 0);
  const stoargeDealerFeePpa = get(settings, 'ppa.storageDealerFee', 0);
  const msgCash = validateStorageCommission(stoargeDealerFeeCash, account);
  const msgLoan = validateStorageCommission(stoargeDealerFeeLoan, account);
  const msgLease = validateStorageCommission(stoargeDealerFeeLease, account);
  const msgPpa = validateStorageCommission(stoargeDealerFeePpa, account);
  const msg = msgCash || msgLoan || msgLease || msgPpa;
  if (msg) {
    return {
      message: msg
    };
  }
  return null;
};

export const validateEvChargerOfferings = ({
  settings,
  quote,
  pvModule
}) => {
  const isCashSelected = settings.cash && settings.cash.showCustomer;
  const isLoanSelected = settings.loan && settings.loan.showCustomer;
  const isLeaseSelected = settings.lease && settings.lease.showCustomer;
  const isPpaSelected = settings.ppa && settings.ppa.showCustomer;

  const sfdcEvChargerConfigs = get(pvModule, 'evmChargerConfigurations', []);

  const selectedFos = [];
  if (isCashSelected) {
    selectedFos.push(CASH_FINANCE_TYPE);
  }
  if (isLoanSelected) {
    selectedFos.push(LOAN_FINANCE_TYPE);
  }
  if (isLeaseSelected) {
    selectedFos.push(LEASE_FINANCE_TYPE);
  }
  if (isPpaSelected) {
    selectedFos.push(PPA_FINANCE_TYPE);
  }

  for (let i = 0; i < quote.evChargerConfigs.length; i++) {
    if (get(quote.evChargerConfigs[i], 'quantity', 0) > 0) {
      const selectedConfigId = get(quote.evChargerConfigs[i], 'configId');
      const sfConfig = sfdcEvChargerConfigs.find((configuration) => {
        return configuration.configId === selectedConfigId;
      });
      const availableFos = (sfConfig.offerings || []).map((fo) => fo.toLowerCase());
      const notSupportedFos = selectedFos.filter((fo) => !availableFos.includes(fo.toLowerCase()));
      const evItemType = sfConfig.type;

      if (availableFos.length === 0) {
        return {
          message:
            `${evItemType} is not enabled for this partner. Please update your Quote Settings before proceeding.`
        };
      }

      if (notSupportedFos.length > 0) {
        return {
          message: `${evItemType} is only available for ${availableFos.join(', ')}. ` +
          'Please select another financial offering.'
        };
      }
    }
  }
  return null;
};

export const validateStorageSelectionAndUpdateSettings = ({ quote,
  settings, selectedStorage, selectedStorageWithExpansionPack }) => {
  const sunPowerStorageSelected = isSunpowerStorage(getStorageName(selectedStorage,
    selectedStorageWithExpansionPack, quote)); // true if storage is selected
  const newSettings = { ...settings };
  Object.keys(newSettings).forEach((key) => {
    if (newSettings[key] &&
        newSettings[key].storagePriceSelection === 'storageDealerFee' &&
        newSettings[key].storageDealerFee
        ) {
      if (!sunPowerStorageSelected) {
        newSettings[key] = { ...newSettings[key], storageDealerFee: null };
      }
    }
  });
  return newSettings;
};

export const validateEvSelectionAndUpdateSettings = ({ quote, settings }) => {
  const evChargerSelected = isEvChargerSelected(quote) || isEvOutletSelected(quote);
  const newSettings = { ...settings };
  Object.keys(newSettings).forEach((key) => {
    if (newSettings[key] && newSettings[key].evChargerDealerFee && !evChargerSelected) {
      newSettings[key] = { ...newSettings[key], evChargerDealerFee: null };
    }
  });
  return newSettings;
};

export default validateSystemPrice;
