export function updateProductSelectionModal(tabName, newTab) {
  return {
    type: 'updateProductSelectionModal',
    payload: {
      productSelectionModal: {
        [tabName]: newTab
      }
    }
  };
}
