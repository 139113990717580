import cx from 'classnames';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import React, { Fragment, useState } from 'react';
import { accountType, dynamicProposalType, proposalDetailType, storageQuoteType } from '../../../types';
import { acknowledgementEnabled } from '../../../utils/backendFeatureFlags';
import { register } from '../../../utils/redux';
import { showTitleCheck, skipTitleCheckModal, titleCheckNotAvailable } from '../../../utils/titleCheck';

export function ActionButtonView({ storageQuote, updateSoProposal, soProposalDetails, toggleSoComplianceCheckModal,
  toggleSoTitleCheckModal, showSoComplianceCheckModal,
  soDynamicProposal, updateSoProposalSentVia, account, fetchSoTitleCheck }) {
  const sendOptions = [ { value: 'email', label: 'Send By Email' }, { value: 'text', label: 'Send By Text' } ];
  const [ isDropdownOpen, setIsDropdownOpen ] = useState(false);
  const [ selectedOption, setSelectedOption ] = useState(null);
  const [ selectedOptionLabel, setSelectedOptionLabel ] = useState(null);
  const isProposalSent = get(soProposalDetails, 'isSent', false) || get(storageQuote, 'mysunpowerC', false);
  const isVerifiedCompliance = get(storageQuote, 'isVerifiedCompliance', false);
  const titleCheckDetails = get(storageQuote, 'titleCheckDetails', null);
  const isPdfProcessing = get(account, 'isPDFProcessing');

  const toggle = (value, label) => {
    setSelectedOption(value);
    setSelectedOptionLabel(label);
    updateSoProposalSentVia(value);
  };

  const sendToMySunpower = () => {
    updateSoProposal(storageQuote.sfid, 'send', soDynamicProposal.sentVia);
  };

  const toggleDropdown = () => {
    if ((storageQuote.cloneVariant !== 'amendment')) {
      setIsDropdownOpen(!isDropdownOpen);
    }
  };

  const toggleModal = () => {
    if (isVerifiedCompliance) {
      if (titleCheckNotAvailable(titleCheckDetails) || showTitleCheck(titleCheckDetails)) {
        if (titleCheckNotAvailable(titleCheckDetails)) {
          fetchSoTitleCheck(storageQuote, account, soDynamicProposal);
        } else if (skipTitleCheckModal(titleCheckDetails)) {
          sendToMySunpower();
        } else {
          toggleSoTitleCheckModal(true);
        }
      } else {
        sendToMySunpower();
      }
    } else {
      toggleSoComplianceCheckModal(!showSoComplianceCheckModal);
    }
  };

  const acknowledgeProposal = () => {
    updateSoProposal(storageQuote.sfid, 'acknowledge');
  };

  const options = (<ul>
    { sendOptions.map((option, index) => {
      return (
        <li
          className={cx('cs-option-container', { 'cs-selected': option.value === selectedOption })}
          onClick={() => {
            toggle(option.value, option.label);
            toggleDropdown();
          }}
          key={index}
        >
          <span className="cs-option">
            <div className="cs-option-name">{option.label}</div>
          </span>
        </li>
      );
    })}
  </ul>);

  const actionButtonContainer = () => {
    return (
      <Fragment>
        <div className={cx('action-button-view-container')}>
          <div className="action-btn-container">
            <div
              id="send_options_dropdown"
              className="input-container input-container--icon icon-right dropdown-with-tags send-option-select"
            >
              <i className="icon icon-chevron-down" />
              <div className={`select-dropdown cs-skin-border ${isDropdownOpen ? 'cs-active' : ''}`}>
                <span className="cs-placeholder" onClick={() => toggleDropdown()}>
                  <div className="cs-option-name">{selectedOptionLabel || 'Select Send options'}</div>
                </span>
                <div className="cs-options">
                  {options}
                </div>
              </div>
            </div>
            <button
              onClick={() => toggleModal()}
              className="btn btn-primary send-btn"
              disabled={
              // TODO. Add Acknowledgement validation when enabled
              (!(selectedOption)
                || get(storageQuote, 'mysunpowerC', false)) || Boolean(storageQuote.cloneVariant === 'amendment')
              }
            >
              {isProposalSent ? 'Sent to mySunPower' : 'Send to mySunPower'}
            </button>
          </div>
        </div>
      </Fragment>
    );
  };

  const quoteLockedView = () => {
    return (
      <div className={cx('action-button-view-container locked')}>
        <div className="locked-quote-placeholder">
          <img src={'/build/images/lock.svg'} alt="Locked Quote" />
          Proposal is locked and sent
        </div>
      </div>
    );
  };

  const acknowledgementView = () => {
    if (!acknowledgementEnabled()) return null;
    const leaseDocReviewedC = get(storageQuote, 'leaseDocReviewedC');
    const isAcknowledgedFromSfdc = leaseDocReviewedC ? leaseDocReviewedC.toLowerCase() === 'acknowledged' : false;

    return (
      <div className={cx('action-button-view-container cash')}>
        <label className="acknowledgement-container">
          <b>Check here to acknowledge</b> your solar power design,
          hardware, and proposal. I understand panel placement will
          vary based on electrical and structural design factors.
          <input
            type="checkbox"
            id="acknowledgement-checkbox"
            disabled={get(soProposalDetails, 'isAcknowledged', false) || isAcknowledgedFromSfdc}
            checked={get(soProposalDetails, 'isAcknowledged', false) || isAcknowledgedFromSfdc}
            onChange={acknowledgeProposal}
          />
          <span className="checkmark" />
        </label>
      </div>
    );
  };

  return (
    <section className="action-btn-section">
      {
        !isPdfProcessing ? acknowledgementView() : null
      }
      {
        isProposalSent ?
          quoteLockedView() :
          !isPdfProcessing ? actionButtonContainer() : null
      }
    </section>
  );
}

ActionButtonView.propTypes = {
  storageQuote: storageQuoteType.isRequired,
  updateSoProposal: PropTypes.func.isRequired,
  soProposalDetails: proposalDetailType,
  toggleSoComplianceCheckModal: PropTypes.func.isRequired,
  toggleSoTitleCheckModal: PropTypes.func.isRequired,
  showSoComplianceCheckModal: PropTypes.bool.isRequired,
  soDynamicProposal: dynamicProposalType.isRequired,
  updateSoProposalSentVia: PropTypes.func.isRequired,
  fetchSoTitleCheck: PropTypes.func.isRequired,
  account: accountType.isRequired
};

export default register(
  [],
  [],
  ActionButtonView
);
