export function colorType(props, propName, componentName) {
  if (props[propName] && !/#(..)(..)(..)/.test(props[propName])) {
    return new Error(
      `Invalid prop ${propName} (${props[propName]}) supplied to ${componentName}. Validation failed.`
    );
  }

  return null;
}

colorType.isRequired = function requiredColorType(props, propName, componentName) {
  if (!props[propName]) {
    return new Error(`Required prop ${propName} not supplied to ${componentName}. Validation failed.`);
  }

  return colorType(props, propName, componentName);
};
