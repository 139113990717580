import compact from 'lodash/compact';
import pickBy from 'lodash/pickBy';
import get from 'lodash/get';
import tryUntil from './tryUntil';
import { INTERCOM_APP_ID } from '../config';

// See: https://developers.intercom.com/docs/single-page-app
const APP_ID = INTERCOM_APP_ID;
let initialized = false;

// userData may not be defined
const makeFullName = (userData) =>
  compact([ get(userData, 'firstName'), get(userData, 'lastName') ]).join(' ');

// We cannot add company information until we have
// company name, id, and created_at.
// See https://docs.intercom.com/configure-intercom-for-your-product-or-site/customize-intercom-to-be-about-your-users/group-your-users-by-company
const makeSettings = ({ userData, account, quote }) => pickBy({
  app_id: APP_ID,
  // sales rep
  name: makeFullName(userData),
  email: get(userData, 'email'),
  user_id: get(userData, 'sfid'),
  phone: get(userData, 'phone'),
  user_company_name: get(userData, 'companyName'),
  // customer
  customer_name: get(account, 'name'),
  customer_street: get(account, 'street'),
  customer_city: get(account, 'city'),
  customer_state: get(account, 'state'),
  customer_postal_code: get(account, 'postalCode'),
  // quote
  quote_sfid: get(quote, 'sfid')
}, (value) => value);

const initializeIfNecessary = async (store, { checkStateEvery = 1000, maxTimeToLoad = Number.MAX_VALUE } = {}) => {
  if (!APP_ID || initialized) return;
  initialized = true;

  const quoteHasLoaded = () => {
    const state = store.getState();
    return Boolean(get(state, 'quote.sfid'));
  };

  await tryUntil(checkStateEvery, maxTimeToLoad, quoteHasLoaded);

  const state = store.getState();
  const userHasIntercomFeature = get(state, 'account.features.intercomChatEnabled', false);
  if (!userHasIntercomFeature) {
    return;
  }
  window.Intercom('boot', makeSettings(state));
};

const uninitialize = () => { initialized = false; };

// See https://developers.intercom.com/docs/intercom-javascript
const hide = () => {
  window.Intercom('hide');
};

export default {
  initializeIfNecessary,
  hide,
  uninitialize
};
