import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-bootstrap-modal';
import { register } from '../../../utils/redux';
import {
  evChargerConfigurationType,
  selectedSoEvChargerConfigurationType,
  storageQuoteType
} from '../../../types';
import EvChargerForSOSettings from './EvChargerForSOSettings';

export function AddEvChargerForSOModal({ storageQuote, toggleAddEvChargerForSOModal,
  evChargerConfigurations, selectedEvChargerConfigurations, partnerIsSpd }) {
  return (
    <Modal
      className="add-ev-charger-for-so-modal"
      show={storageQuote.isAddEvChargerForSOModalOpen}
      onHide={toggleAddEvChargerForSOModal}
      aria-labelledby="ModalHeader"
    >
      <Modal.Header closeButton={true}>
        <div className="modal-title">Wallbox EV Charger&trade;</div>
      </Modal.Header>
      <Modal.Body>
        <EvChargerForSOSettings
          storageQuote={storageQuote}
          evChargerConfigurations={evChargerConfigurations}
          toggleAddEvChargerForSOModal={toggleAddEvChargerForSOModal}
          selectedEvChargerConfigurations={selectedEvChargerConfigurations}
          partnerIsSpd={partnerIsSpd}
        />
      </Modal.Body>
    </Modal>
  );
}

AddEvChargerForSOModal.propTypes = {
  storageQuote: storageQuoteType.isRequired,
  toggleAddEvChargerForSOModal: PropTypes.func.isRequired,
  evChargerConfigurations: PropTypes.arrayOf(evChargerConfigurationType).isRequired,
  selectedEvChargerConfigurations: selectedSoEvChargerConfigurationType,
  partnerIsSpd: PropTypes.bool
};

export default register(
  [
    'designSelector', 'quoteSelector', 'evChargerConfigurationsSelector'
  ],
  [
    'toggleAddEvChargerForSOModal'
  ],
  AddEvChargerForSOModal
);
