import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Modal from './Modal';
import { register } from '../../../utils/redux';
import { evChargerConfigurationType, storageQuoteType } from '../../../types';
import LineInput from '../../LineInput';
import { validateEvDealerCommission } from '../../../utils/storageQuote/validator';
import { showEvChargerForStorageOnly } from '../../../utils/storageQuote/evChargers';

export function SettingsModal({ storageQuote, updateStorageQuote, modalCoordinates,
  closeModal, setError, evChargerConfigurations, partnerIsRvar }) {
  const quoteDealerCommission = storageQuote.dealerCommission !== null ? storageQuote.dealerCommission : '';
  const [ dealerCommission, setDealerCommission ] = useState(quoteDealerCommission);
  const [ evDealerCommission, setEvDealerCommission ] = useState(storageQuote.evDealerCommission);

  useEffect(() => {
    if (storageQuote) {
      if (storageQuote.dealerCommission) {
        setDealerCommission(storageQuote.dealerCommission);
      }
      if (storageQuote.evDealerCommission) {
        setEvDealerCommission(storageQuote.evDealerCommission);
      }
    }
  }, [storageQuote]);

  const handleDealerCommission = (value) => {
    setDealerCommission(value);
  };

  const handleEvDealerCommission = (value) => {
    setEvDealerCommission(value);
  };

  const modalValues = () => {
    return { name: 'settings', coordinates: modalCoordinates };
  };

  const saveDealerCommission = () => {
    if (showEvChargerForStorageOnly(storageQuote) && evChargerConfigurations) {
      const errorMessage = validateEvDealerCommission(evDealerCommission, partnerIsRvar);
      if (errorMessage) {
        setError({ message: errorMessage });
        return;
      }
    }
    updateStorageQuote(storageQuote, { dealer_commission: dealerCommission,
      ev_dealer_commission: evDealerCommission }, false);
    closeModal();
  };

  const settingsView = () => {
    if (showEvChargerForStorageOnly(storageQuote)) {
      return (
        <div className="modal-data-container">
          <div className="heading-text bold">Dealer Commission Settings</div>
          <div className="sub-heading-text finance-type">Cash Only Option</div>
          <div className="input-field-container ev-charger-enabled">
            <div className="input-label"><b>Storage</b> Commission</div>
            <LineInput
              id="dealer-commission"
              value={dealerCommission}
              rounded={true}
              positive={true}
              onChange={(newValue) => handleDealerCommission(newValue)}
              disabled={storageQuote.locked}
            />
          </div>
          { evChargerConfigurations && (
            <div className="input-field-container ev-charger-enabled">
              <div className="input-label"><b>EV Charger</b> {partnerIsRvar ? 'Additional Cost' : 'Commission'}</div>
              <LineInput
                id="ev-charger-dealer-commission"
                value={evDealerCommission}
                rounded={true}
                positive={true}
                onChange={(newValue) => handleEvDealerCommission(newValue)}
                disabled={storageQuote.locked}
              />
            </div>
          )}
          <div className="action-btn-container ev-charger-enabled">
            <div className="btn-container">
              <button
                className="btn btn-secondary cancel-btn ev-charger-enabled"
                onClick={() => {
                  closeModal();
                }}
              >
                CANCEL
              </button>
              <button
                className="btn btn-primary save-btn ev-charger-enabled"
                disabled={dealerCommission === '' || storageQuote.locked}
                onClick={saveDealerCommission}
              >
                Save
              </button>
            </div>
          </div>
        </div>
      );
    }

    return (
      <div className="modal-data-container">
        <div className="heading-text"><b>Storage</b> Quote Settings</div>
        <div className="sub-heading-text">Dealer Commission</div>
        <div className="input-field-container">
          <LineInput
            id="dealer-commission"
            value={dealerCommission}
            rounded={true}
            positive={true}
            onChange={(newValue) => handleDealerCommission(newValue)}
            disabled={storageQuote.locked}
          />
        </div>
        <div className="action-btn-container">
          <div className="cash-only-indicator">CASH OPTION ONLY</div>
          <div className="btn-container">
            <a
              className="cancel-btn"
              onClick={() => {
                closeModal();
              }}
            >
              CANCEL
            </a>
            <button
              className="btn btn-primary save-btn"
              disabled={dealerCommission === '' || storageQuote.locked}
              onClick={saveDealerCommission}
            >
              Save
            </button>
          </div>
        </div>
      </div>
    );
  };

  return (
    <Modal
      name="settings"
      showCloseButtonOnly={true}
      showIcon={true}
      modals={modalValues()}
      closeModal={closeModal}
    >
      { settingsView() }
    </Modal>
  );
}

SettingsModal.propTypes = {
  storageQuote: storageQuoteType.isRequired,
  updateStorageQuote: PropTypes.func,
  modalCoordinates: PropTypes.shape({
    top: PropTypes.number,
    left: PropTypes.number,
    right: PropTypes.number
  }),
  closeModal: PropTypes.func,
  setError: PropTypes.func,
  evChargerConfigurations: PropTypes.arrayOf(evChargerConfigurationType).isRequired,
  partnerIsRvar: PropTypes.bool.isRequired
};

export default register(
  [],
  [],
  SettingsModal
);
