import get from './get';
import createSelector from '../utils/createSelector';
import { pvModulesSelector } from '../selectors';

export function totalValues(state) {
  const quote = state.quote;
  let { selectedWallBoxQuantity } = state.evChargerSelectionModal;
  let { selectedOutletQuantity } = state.evChargerSelectionModal;
  const { pvModules } = pvModulesSelector({ pvModules: state.pvModules });
  const selectedPvModule = pvModules.find((pvModule) => pvModule.itemId === quote.pvModuleItemId);
  if (!selectedPvModule) {
    return {};
  }

  const initialWallBoxQuantity = 0;
  selectedWallBoxQuantity = selectedWallBoxQuantity == null ? initialWallBoxQuantity : selectedWallBoxQuantity;
  const initialOutletQuantity = 0;
  selectedOutletQuantity = selectedOutletQuantity == null ? initialOutletQuantity : selectedOutletQuantity;

  let evChargers = [];
  if (selectedPvModule.evmChargerConfigurations) {
    evChargers = [...new Set(
      selectedPvModule.evmChargerConfigurations.map((evm) => {
        return { id: evm.configId, name: evm.configName, type: evm.type };
      })
    )];
  }

  if (evChargers.length === 0) {
    selectedWallBoxQuantity = null;
    selectedOutletQuantity = null;
  }

  return {
    evChargerSelectionModal: {
      selectedWallBoxQuantity,
      selectedOutletQuantity,
      evChargers
    }
  };
}

export default createSelector(
  get('evChargerSelectionModal'),
  totalValues
);
