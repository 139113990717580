import React from 'react';
import PropTypes from 'prop-types';
import range from 'lodash/range';

const PanelSliderLabel = ({ label, leftPos }) => (
  <div className="label" style={{ left: `${leftPos}%` }}>
    <div className="label-value">
      {label}
    </div>
  </div>
);
PanelSliderLabel.propTypes = {
  label: PropTypes.number.isRequired,
  leftPos: PropTypes.number.isRequired
};

const PanelsSliderTooltip = ({ panels, percentChosen }) => (
  <div id="panels_slider__tooltip__track">
    &nbsp;
    <div
      id="panels_slider__tooltip"
      style={{ left: `${percentChosen}%` }}
    >
      <span>{panels}</span> PANELS
    </div>
  </div>
);
PanelsSliderTooltip.propTypes = {
  panels: PropTypes.number.isRequired,
  percentChosen: PropTypes.number.isRequired
};

const PanelsSlider = ({ panels, total, skipPanels, onChange, labelStep, disabled }) => {
  const onChangeWrapper = (e) => {
    if (skipPanels.includes(e.target.value)) return;
    onChange(e.target.value);
  };
  const percentChosen = (100 * panels / total);

  return (
    <div id="panels_slider">
      <PanelsSliderTooltip panels={panels} percentChosen={percentChosen} />
      <input
        id="panels_slider__range"
        type="range"
        min={0}
        max={total}
        value={panels}
        // These are used to calculate properties in _panels-slider.scss
        ref={(element) => {
          if (element === null) return;
          element.style.setProperty('--min', 0);
          element.style.setProperty('--val', panels);
          element.style.setProperty('--max', total);
        }}
        onChange={onChangeWrapper}
        disabled={disabled}
      />
      <div className="labels">
        <PanelSliderLabel label={0} leftPos={0} />
        {
          range(labelStep, total + 1, labelStep)
            .slice(0, -1)
            .map((label) => (
              <PanelSliderLabel key={`label-${label}`} label={label} leftPos={(label / total) * 100} />
            ))
        }
        <PanelSliderLabel label={total} leftPos={100} />
      </div>
    </div>
  );
};

PanelsSlider.propTypes = {
  onChange: PropTypes.func.isRequired,
  labelStep: PropTypes.number,
  skipPanels: PropTypes.arrayOf(PropTypes.number),
  panels: PropTypes.number.isRequired,
  total: PropTypes.number.isRequired,
  disabled: PropTypes.bool
};

PanelsSlider.defaultProps = {
  labelStep: 5
};

export default PanelsSlider;
