import React from 'react';
import PropTypes from 'prop-types';
import { register } from '../../utils/redux';
import {
  CALCULATING_SAVINGS, ROOF_PREVIEW_VISUALIZATION, ROOF_PREVIEW_MAP,
  MODE_CHANGE_PANELS, MODE_CHANGE_ARRAYS
} from '../../config';
import {
  modeType,
  quoteType,
  designType,
  panelsType,
  pvModuleType,
  accountType,
  selectedInverterType,
  monitoringConfigurationType
}
from '../../types';
import { gaTrackEvent } from '../../utils/googleAnalytics';
import ValueBox from '../ValueBox';
import PercentCircle from '../PercentCircle';
import PanelsSlider from '../PanelsSlider';
import Link from '../Link';
import RoofPreview from '../design_page/RoofPreview';
import ShowHideSection from './ShowHideSection';
import ProductSelectionModal from './product_selection_modal/ProductSelectionModal';

export function PublicDesignPage({
  annualUsage, design, panels, quote, setPanelSlider, updatePublicRoofDesign, toggleMode,
  isSaved, mode, pvModule, toggleModal, roofPreviewMode, setRoofPreviewMode, account,
  toggleProductSelectionModal, selectedInverter, monitoringConfiguration, isSpecialState, specialStateFullName
}) {
  // TODO: it's used in DesignPage too so we should create a selector
  const energyOffset = Math.floor(design.totalEnergyProduction / annualUsage * 100);
  const isNewDesign = !design.hasChanges && design.enabledPanelCount === 0;
  const saveDesign = () => {
    gaTrackEvent('quotePage:designTab:saveDesign');
    updatePublicRoofDesign(quote, design);
    toggleMode(CALCULATING_SAVINGS);
  };
  const disclaimerText = isSpecialState ?
    'Electricity Production and Offset are preliminary estimates. Final estimated ' +
    'Electricity Offset will be displayed on the Electricity page and Proposal. ' +
    `In the state of ${specialStateFullName}, the EDDiE app does not illustrate or describe ` +
    'potential financial savings associated with installing a SunPower solar system.' :

    'Electricity Production and Offset are preliminary estimates. Final estimated ' +
    'Electricity Offset will be displayed on the Savings page and Proposal.';
  const selectedPvModule = quote.locked ? quote.lockedPvModuleItem : pvModule;

  const imageClassName = (/BLK/i).test(selectedPvModule.item.description) ? 'black-module' : 'generic-module';

  const shoowHideSectionTitle = 'OTHER COMPONENTS';

  const rackingImage = quote.rackingType === 'Invisimount' ? 'invisimount' : 'racking';

  const monitoringName = (/PVS5/i).test(monitoringConfiguration.name) ? 'PVS5 & EnergyLink' : (
    (/PVS6/i).test(monitoringConfiguration.name) ? 'PVS6 & EnergyLink' : '-');

  return quote.sfid === '' ? null : (
    <div id="main-content">
      <div id="design-visualization-container" className="design-visualization-center">
        <RoofPreview
          roofPreviewMode={roofPreviewMode}
          setRoofPreviewMode={setRoofPreviewMode}
          account={account}
          interactive={true}
        />
      </div>

      <div id="value-box-container">
        <div className="section">
          <div className="row">
            <div className="slider-info">
              <div className="slider-info-image">
                <span className="panels-logo" />
              </div>
              <div className="slider-info-text">
                <h4>Place your panels dynamically</h4>
                <p>
                  Use the slider to add or remove panels.
                </p>
              </div>
            </div>
          </div>
          <div className="row">
            <PanelsSlider
              onChange={setPanelSlider}
              panels={panels.totalEnabledPanels}
              skipPanels={panels.skipPanels}
              total={panels.totalPanels}
              disabled={roofPreviewMode === ROOF_PREVIEW_MAP}
            />
          </div>
        </div>

        <div className="section">
          <div className="row">
            <div>
              <h4>Manually select where to place your panels</h4>
              <p>
                Use the Roofs and Panels toggles below for more control on
                how many roofs and panels you want as part of your design.
              </p>
            </div>
          </div>
          <div className="row">
            <ValueBox
              label="Roofs"
              value={design.enabledArrayCount}
              isSelected={mode === MODE_CHANGE_ARRAYS}
              onClick={() => {
                gaTrackEvent('quotePage:designTab:roofsButton');
                toggleMode(MODE_CHANGE_ARRAYS);
              }}
              disabled={mode === CALCULATING_SAVINGS || quote.locked || roofPreviewMode === ROOF_PREVIEW_MAP}
            />
            <ValueBox
              label="Panels"
              value={design.enabledPanelCount}
              isSelected={mode === MODE_CHANGE_PANELS}
              onClick={() => {
                gaTrackEvent('quotePage:designTab:panelsButton');
                toggleMode(MODE_CHANGE_PANELS);
              }}
              disabled={mode === CALCULATING_SAVINGS || quote.locked || roofPreviewMode === ROOF_PREVIEW_MAP}
            />
          </div>
        </div>

        {account.features.productSelectionModal && (
          <div>
            <div className="row">
              <button
                className="btn-primary product-selection-modal-btn"
                onClick={toggleProductSelectionModal}
              >
                Customize Equipment & Settings
              </button>
              <ProductSelectionModal />
            </div>

            <div className="section top-border">
              <div className="row">
                <ValueBox
                  label="SunPower Equinox&#8482; System"
                  value={selectedPvModule.item.productLine}
                  subvalue={`${selectedPvModule.item.summaryDescription} panels`}
                  imageClassName={imageClassName}
                  disabled={true}
                />
              </div>
            </div>

            <div className="show-hide-section">
              <ShowHideSection title={shoowHideSectionTitle}>
                <div>
                  <ValueBox
                    className="other-components"
                    label="Inverter"
                    value={selectedInverter.inverterName || '-'}
                    imageClassName="inverter"
                    disabled={true}
                  />
                  <ValueBox
                    className="other-components"
                    label="Mounting System"
                    value={quote.rackingType || '-'}
                    imageClassName={rackingImage}
                    disabled={true}
                  />
                  <ValueBox
                    className="other-components last"
                    label="Monitoring System"
                    value={monitoringConfiguration.name || '-'}
                    imageClassName="monitoring"
                    disabled={true}
                  />
                </div>
              </ShowHideSection>
            </div>
          </div>
        )}

        {!account.features.productSelectionModal && (
          <div className="section top-border equipment">
            <div id="module-details-icon" className="nav-content-icon">
              <a
                href="#"
                rel="noopener noreferrer"
                target="_blank"
                className="image-icon image-icon-info"
                data-tip={`${pvModule.series}-Series module details`}
                onClick={(e) => {
                  e.preventDefault();
                  return !quote.locked && toggleModal('panel-details', e.currentTarget);
                }}
              />
            </div>
            <h4>Equipment</h4>
            <div className="row">
              <ValueBox
                label="Equinox System"
                value={selectedPvModule.item.productLine}
                subvalue={`${selectedPvModule.item.summaryDescription} panels`}
                imageClassName={imageClassName}
                disabled={true}
              />
            </div>
            <div className="row small last">
              <ValueBox
                label="Monitoring"
                value={monitoringName}
                disabled={true}
              />
              <ValueBox
                label="Mounting"
                value={quote.rackingType || '-'}
                disabled={true}
              />
            </div>
          </div>
        )}

        <div className="section public-variant energy-summary">
          <div className="row">
            <div className="column">
              <ValueBox
                label="Energy Production (est.)"
                value={design.totalEnergyProduction}
                subvalue="kWh/y"
                valueColor="green"
              />
              <ValueBox
                label="Energy Needs"
                value={Math.round(annualUsage)}
                subvalue="kWh/y"
                valueColor="yellow"
              />
            </div>
            <div className="column">
              <PercentCircle label="Offset" value={energyOffset} labelPosition="inside" />
            </div>
          </div>
        </div>

        <div className="section">
          <div className="row">
            {
              isSaved ? (
                <Link
                  className="view-savings btn btn-primary"
                  onClick={() => { gaTrackEvent('quotePage:designTab:viewEstimatedSavings'); }}
                  to={`/public/quote/${quote.sfid}/savings?pat=${quote.publicAccessToken}`}
                >
                  View Estimated Savings
                </Link>
              ) : (
                <button
                  className="btn btn-primary save"
                  disabled={isNewDesign || mode === CALCULATING_SAVINGS}
                  onClick={saveDesign}
                >
                  Save Design
                </button>
              )
            }
          </div>
          <div className="row">
            <span className="disclaimer legal-text">
              {disclaimerText}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}

PublicDesignPage.propTypes = {
  quote: quoteType.isRequired,
  panels: panelsType.isRequired,
  design: designType.isRequired,
  annualUsage: PropTypes.number.isRequired,
  setPanelSlider: PropTypes.func.isRequired,
  toggleMode: PropTypes.func.isRequired,
  toggleModal: PropTypes.func.isRequired,
  updatePublicRoofDesign: PropTypes.func.isRequired,
  mode: modeType,
  isSaved: PropTypes.bool.isRequired,
  pvModule: pvModuleType.isRequired,
  account: accountType.isRequired,
  roofPreviewMode: PropTypes.oneOf([
    ROOF_PREVIEW_VISUALIZATION,
    ROOF_PREVIEW_MAP
  ]),
  setRoofPreviewMode: PropTypes.func.isRequired,
  toggleProductSelectionModal: PropTypes.func.isRequired,
  selectedInverter: selectedInverterType.isRequired,
  monitoringConfiguration: monitoringConfigurationType.isRequired,
  isSpecialState: PropTypes.bool,
  specialStateFullName: PropTypes.string
};

export default register(
  [
    'quoteSelector', 'designSelector', 'annualUsageSelector', 'panelsSelector',
    'isSavedSelector', 'modeSelector', 'pvModuleSelector',
    'roofPreviewModeSelector', 'accountSelector', 'selectedInverterSelector',
    'monitoringConfiguration', 'isSpecialStateSelector', 'specialStateFullNameSelector'
  ],
  [ 'setPanelSlider', 'updatePublicRoofDesign', 'toggleMode', 'toggleModal',
    'setRoofPreviewMode', 'toggleProductSelectionModal' ],
  PublicDesignPage
);
