import {
  FinanceModule,
  MonthlyProductionGraph,
  StorageDetails3P,
  SystemDetailsModule,
  WelcomeModule
} from 'common-components';
import { get, camelCase } from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { isPDFProcessing } from '../../actions';
import store from '../../store';
import {
  accountType,
  allSystemCostsType,
  designType,
  energyDataType,
  productConfigurationType,
  quoteType,
  selectedStorageType,
  selectedStorageWithExpansionPackType,
  storageSelectionModalType,
  userDataType
} from '../../types';
import { createPDF } from '../../utils/DynamicProposal/createPDF';
import { waitForGraph } from '../../utils/DynamicProposal/pdfHelper';
import { enabledPanelCount } from '../../utils/EddieCalculations/design';
import { isStorageSelected, getStorageSize } from '../../utils/storage';
import { partnerIsNewHomes } from '../../utils/EddieCalculations/helpers/account';
import { isEvChargerSelected, calculatedEvChargerSize } from '../../utils/EvChargers/evChargers';
import { CASH, LEASE, LOAN, PPA } from '../../utils/EddieCalculations/helpers/financeTypes';

const ProposalGenerator = ({
  account,
  allSystemCosts,
  defaultChartTab,
  design,
  energyData,
  inflationRate,
  instantQuoteMode,
  isSpecialState,
  productConfiguration,
  quote,
  constantValues,
  degradationRate,
  selectedStorage,
  selectedStorageQuantity,
  selectedFinanceOption,
  selectedStorageWithExpansionPack,
  storageSelectionModal,
  userData,
  currentPage,
  utilitySavingsType,
  skipUploadToS3
}) => {
  const isNewHomes = partnerIsNewHomes(account.partner);
  const storageSelected = isStorageSelected(
    storageSelectionModal, quote, selectedStorageWithExpansionPack, selectedStorage
  );

  const [ graphReady, setGraphReadiness ] = useState(false);

  const getTermForSavingsGraph = (selectedFinanceOption, allSystemCosts) => {
    const selectedFinOption = camelCase(selectedFinanceOption);
    switch (selectedFinOption) {
      case CASH:
        return null;
      case LEASE:
        return get(allSystemCosts, `${selectedFinOption}.leaseTerm`, null);
      case PPA:
        return get(allSystemCosts, `${selectedFinOption}.ppaTerm`, null);
      case LOAN:
        return get(allSystemCosts, `${selectedFinOption}.termInMonths`)
          ? get(allSystemCosts, `${selectedFinOption}.termInMonths`) / 12 : null;
      default:
        return null;
    }
  };

  useEffect(() => {
    if (!graphReady) {
      if (isNewHomes) setGraphReadiness(true);
      const showSavingsOverTime = document.querySelector('#savingsOverTimeGraph');
      const showTotalSavings = document.querySelector('#totalSavingsGraph');
      if (showSavingsOverTime || showTotalSavings) {
        waitForGraph('#savings-over-time-chart-canvas').then(() => { setGraphReadiness(true); });
        waitForGraph('#total-savings-chart-canvas').then(() => { setGraphReadiness(true); });
      }
      if (!showSavingsOverTime && !showTotalSavings) setGraphReadiness(true);
    }
    if (graphReady) {
      try {
        if (!isNewHomes) {
          createPDF(
            quote,
            account,
            userData,
            productConfiguration,
            instantQuoteMode,
            currentPage,
            utilitySavingsType,
            skipUploadToS3
          );
        }
        if (isNewHomes) store.dispatch(isPDFProcessing(false));
      } catch (e) {
        store.dispatch(isPDFProcessing(false));
      }
    }
  }, [graphReady]);

  const evChargerSelected = isEvChargerSelected(quote);
  const evChargerSize = calculatedEvChargerSize(quote, constantValues);
  const batterySize = getStorageSize(quote, selectedStorageQuantity);

  return (
    <div
      id="pdf-test"
      style={{
        position: 'fixed',
        top: '150vh',
        width: '1498px'
      }}
    >
      <section id="welcome">
        <WelcomeModule />
      </section>
      <section
        id="systemDetails"
      >
        <SystemDetailsModule
          {...quote}
          isPDFProcessing={true}
          evChargerSize={evChargerSize}
          evChargerSelected={evChargerSelected}
          systemSize={get(design, 'systemSize')}
          numberOfPanels={enabledPanelCount(design)}
          purchasedAnnualKwh={get(energyData, 'annualKwh.purchased')}
          totalAnnualKwh={get(energyData, 'year1Production')}
          energyOffset={get(energyData, 'energyOffset')}
          storageName={quote.storageName}
          storageSize={batterySize}
          batteryManufacturer={quote.batteryManufacturer}
          storageSelected={storageSelected}
        />
      </section>
      <section
        style={{ padding: '100px' }}
        id="storageDetails"
      >
        <StorageDetails3P
          {...quote}
          selectedStorageQuantity={selectedStorageQuantity}
          batterySize={batterySize}
          term={getTermForSavingsGraph(quote.selectedFinanceOption, allSystemCosts)}
          selectedFinanceOption={quote.selectedFinanceOption}
        />
      </section>
      <section
        style={{
          padding: '0 150px',
          marginTop: '-20%'
        }}
        id="finance"
      >
        <FinanceModule
          {...quote}
          isPDFProcessing={true}
          storageSelected={storageSelected}
          energyData={energyData}
          isSpecialState={isSpecialState}
          state={account.state}
          selectedFinanceOption={selectedFinanceOption}
          selectedInflationRate={inflationRate}
          ledger={allSystemCosts[selectedFinanceOption]}
          defaultChartTab={defaultChartTab}
          showCreditAppView={false}
          graphSelections={{
            totalSavings: false,
            savingsOverTime: false
          }}
        />
      </section>
      <section
        style={{ padding: '50px 200px' }}
        id="monthly-chart"
      >
        <MonthlyProductionGraph
          {...quote}
          energyData={energyData}
          degradationRate={degradationRate}
          chartId={'section'}
          isPDFProcessing={true}
          source="eddie"
        />
      </section>
    </div>
  );
};

ProposalGenerator.propTypes = {
  account: accountType.isRequired,
  allSystemCosts: allSystemCostsType.isRequired,
  defaultChartTab: PropTypes.string,
  design: designType.isRequired,
  energyData: energyDataType.isRequired,
  inflationRate: PropTypes.string.isRequired,
  instantQuoteMode: PropTypes.bool,
  selectedFinanceOption: PropTypes.string,
  isSpecialState: PropTypes.bool.isRequired,
  productConfiguration: productConfigurationType.isRequired,
  quote: quoteType.isRequired,
  degradationRate: PropTypes.number,
  selectedStorage: selectedStorageType.isRequired,
  constantValues: PropTypes.arrayOf(PropTypes.number),
  selectedStorageQuantity: PropTypes.number,
  selectedStorageWithExpansionPack: selectedStorageWithExpansionPackType.isRequired,
  storageSelectionModal: storageSelectionModalType.isRequired,
  userData: userDataType,
  currentPage: PropTypes.string,
  utilitySavingsType: PropTypes.string,
  skipUploadToS3: PropTypes.bool
};
export default ProposalGenerator;
