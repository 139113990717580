import React from 'react';
import PropTypes from 'prop-types';

export class ExpandableSection extends React.Component {
  render() {
    if (this.props.isExpanded) {
      return this.props.children;
    }

    return null;
  }
}

ExpandableSection.propTypes = {
  isExpanded: PropTypes.bool.isRequired,
  children: PropTypes.element.isRequired
};

export default ExpandableSection;
