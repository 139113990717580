import React from 'react';
import PropTypes from 'prop-types';
import Link from '../Link';
import { register } from '../../utils/redux';
import { CALCULATING_SAVINGS } from '../../config';
import { gaTrackEvent } from '../../utils/googleAnalytics';
import {
  quoteType,
  modeType,
  energyDataType,
  settingsType,
  accountType
} from '../../types';
import browser from '../../utils/browser';
import { MessagesOverlay } from '../MessagesOverlay';
import { nyStateEnergyOffsetCheck, nyStateEnergyOffsetMsg } from '../../utils/nyStateEnergyOffsetCheck';

export function SelectFinanceTypeButton({ name, quote, updatingLease, mode, account, partnerIsTps,
                                          partnerIsRvar, energyData, activeTab, onClick, manualMode, isPublic,
                                          instantQuoteMode, settings, setMessage }) {
  const urlPrefix = manualMode ? '/manual_mode' : (isPublic ? '/public' : instantQuoteMode ? '/instant_quote' : '');
  const urlSuffix = isPublic ? `?pat=${quote.publicAccessToken}` : '';
  const locked = isPublic ? !!quote.selectedByHomeowner : quote.locked;
  const selected = isPublic ? quote.selectedByHomeowner : quote.selectedFinanceOption;
  const showViewProposalButton = locked && selected === activeTab;
  const showSelectPaymentOptionButton = !locked && !browser.isIE11;
  const showWarning = !locked && browser.isIE11;

  function displayMsg(offsetMsgCheck) {
    nyStateEnergyOffsetMsg((offsetMsgCheck && !quote.dirty), setMessage);
    return <MessagesOverlay />;
  }

  function offsetCheck() {
    const offsetMsgCheck = nyStateEnergyOffsetCheck(account, energyData.energyOffset,
    partnerIsTps, partnerIsRvar, settings, quote);
    displayMsg(offsetMsgCheck);
  }

  return (
    <div className="select-option">
      {showViewProposalButton && (
        <Link
          className="btn btn-primary nav-action-btn"
          to={`${urlPrefix}/quote/${quote.sfid}/proposal${urlSuffix}`}
          onClick={() => gaTrackEvent('quotePage:savingsTab:viewProposal')}
        >
          View Proposal
        </Link>
      )}

      {showSelectPaymentOptionButton && (
        <button
          onClick={() => {
            gaTrackEvent(`quotePage:savingsTab:${name}SubTab:selectButton`);
            onClick();
            offsetCheck();
          }}
          className="btn btn-primary nav-action-btn"
          disabled={mode === CALCULATING_SAVINGS || updatingLease}
        >
        Select {name}
        </button>
      )}

      {showWarning && (
        <div className="warning">
          Internet Explorer 11 and previous versions are not supported.
          If you want to finalize the quote,
          upgrade your browser to Edge, Firefox, or Chrome.
        </div>
      )}
    </div>
  );
}

SelectFinanceTypeButton.propTypes = {
  account: accountType.isRequired,
  partnerIsRvar: PropTypes.bool,
  partnerIsTps: PropTypes.bool,
  energyData: energyDataType.isRequired,
  settings: settingsType.isRequired,
  setMessage: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  quote: quoteType.isRequired,
  mode: modeType,
  updatingLease: PropTypes.bool,
  activeTab: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  manualMode: PropTypes.bool.isRequired,
  isPublic: PropTypes.bool.isRequired,
  instantQuoteMode: PropTypes.bool
};
export default register(
  [
    'quoteSelector', 'modeSelector', 'updatingLeaseSelector', 'energyDataSelector',
    'partnerIsTpsSelector', 'manualModeSelector', 'isPublicSelector', 'settingsSelector',
    'instantQuoteModeSelector', 'accountSelector', 'partnerIsRvarSelector'
  ],
  ['setMessage'],
  SelectFinanceTypeButton
);
