import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import {
  accountType,
  designType,
  energyDataType,
  pvModuleForManualModeType,
  quoteType,
  settingsType
} from '../types';
import { gaTrackEvent } from '../utils/googleAnalytics';
import { nyStateEnergyOffsetCheck, nyStateEnergyOffsetMsg } from '../utils/nyStateEnergyOffsetCheck';
import { setPricingsControls } from '../utils/pricingDefaultsHelper';
import { register } from '../utils/redux';
import Link from './Link';
import { MessagesOverlay } from './MessagesOverlay';
import PercentCircle from './PercentCircle';
import ValueBox from './ValueBox';
import Roofs from './manual_mode_design/Roofs';
import SelectedInverters from './manual_mode_design/SelectedInverters';
import SystemInformation from './manual_mode_design/SystemInformation';

export function ManualModeDesignPage({
  updateSettings, resetSettings,
  design, quote, dcModule, pvModules, setMessage, account, settings,
  energyData, validateAndSaveManualModeDesignPage, partnerIsRvar, partnerIsTps
 }) {
  const isNewDesign = design.moduleArrays.length === 0;
  const hasPvModules = pvModules.length > 0;
  const selectedPvModule = pvModules.find((module) => module.itemId === quote.pvModuleItemId);

  const totalAnnualKwh = energyData.year1Production ? energyData.year1Production : '-';
  const purchasedAnnualKwh = energyData.annualKwh ? energyData.annualKwh.purchased : '-';
  const systemSizeKW = design.systemSize / 1000;

  function displayMsg(offsetMsgCheck) {
    nyStateEnergyOffsetMsg((offsetMsgCheck && !quote.dirty), setMessage);
    return <MessagesOverlay />;
  }

  useEffect(() => {
    const offsetMsgCheck = nyStateEnergyOffsetCheck(account, energyData.energyOffset,
    partnerIsTps, partnerIsRvar, settings, quote);
    displayMsg(offsetMsgCheck);
  }, [energyData.energyOffset]);

  const [ previousSize, setPreviousSize ] = useState(systemSizeKW);
  const [ previousSKU, setPreviousSKU ] = useState(quote.pvModuleItemId);

  useEffect(() => {
    if (previousSize !== systemSizeKW || previousSKU !== quote.pvModuleItemId) {
      setPreviousSize(systemSizeKW);
      setPreviousSKU(quote.pvModuleItemId);
      resetSettings(
        account,
        design,
        quote,
        pvModules,
        settings,
        updateSettings
      );
    }
  }, [ systemSizeKW, quote.pvModuleItemId ]);

  useEffect(() => {
    if (!quote.cloneOriginalSfid) {
      setPricingsControls(
        account,
        design,
        quote,
        pvModules,
        settings,
        updateSettings
      );
    }
  }, []);

  return (hasPvModules || quote.locked) ? (
    <div className="manual-mode-design-page container">
      <SystemInformation />
      {dcModule && <hr />}
      {dcModule && <SelectedInverters />}
      <hr />
      <Roofs />
      <hr />
      <div className="value-box-container" style={{ display: 'flex' }}>
        <div className="inner-value-box-container">
          <ValueBox
            label="System size"
            value={quote.dirty ? '-' : systemSizeKW}
            subvalue="kW"
            places={2}
          />
          <ValueBox
            label="Production (est.)"
            value={quote.dirty ? '-' : totalAnnualKwh}
            subvalue="kWh/y"
          />
          <ValueBox
            label="Electricity needs"
            value={quote.dirty ? '-' : purchasedAnnualKwh}
            subvalue="kWh/y"
          />
          <PercentCircle
            labelPosition="after"
            labelHorizontal={true}
            label="Offset (est.)"
            value={quote.dirty ? 0 : energyData.energyOffset}
            className="new half"
          />
        </div>
      </div>
      <hr />
      <div className="row estimate-savings-section end-xs">
        {
          quote.dirty ? (
            <button
              className="btn btn-primary save"
              disabled={isNewDesign}
              onClick={validateAndSaveManualModeDesignPage}
            >
              Save Design
            </button>
          ) : (
            <Link
              className="view-savings btn btn-primary"
              onClick={() => { gaTrackEvent('quotePage:designTab:viewEstimatedSavings'); }}
              to={`/manual_mode/quote/${quote.sfid}/savings`}
            >
              View Estimated Savings
            </Link>
          )
        }
      </div>
    </div>
  ) : (
    <div className="manual-mode-design-page container">
      <div className="alert alert--info">
        There is no module for this payment option. Please, select a different module.
      </div>
    </div>
  );
}

ManualModeDesignPage.propTypes = {
  account: accountType.isRequired,
  design: designType.isRequired,
  quote: quoteType.isRequired,
  dcModule: PropTypes.bool,
  energyData: energyDataType.isRequired,
  partnerIsRvar: PropTypes.bool,
  partnerIsTps: PropTypes.bool,
  settings: settingsType.isRequired,
  pvModules: PropTypes.arrayOf(pvModuleForManualModeType).isRequired,
  updateSettings: PropTypes.func.isRequired,
  resetSettings: PropTypes.func.isRequired,
  setMessage: PropTypes.func.isRequired,
  validateAndSaveManualModeDesignPage: PropTypes.func.isRequired
};

export default register(
  [ 'designSelector', 'quoteSelector', 'accountSelector', 'settingsSelector',
    'dcModuleSelector', 'pvModulesSelector', 'partnerIsRvarSelector',
    'energyDataSelector', 'partnerIsTpsSelector' ],
  [ 'updateSettings', 'resetSettings', 'validateAndSaveManualModeDesignPage', 'setMessage' ],
  ManualModeDesignPage
);
