import PropTypes from 'prop-types';

export const evChargerConfigurationType = PropTypes.shape({
  type: PropTypes.string,
  configName: PropTypes.string,
  configId: PropTypes.string,
  description: PropTypes.string,
  offerings: PropTypes.arrayOf(PropTypes.string),
  itemId: PropTypes.string,
  inventoryItemId: PropTypes.string
});
