import { omit, get } from 'lodash';
import { asyncPayload } from './apiHelpers';
import Api from '../utils/Api';
import { updateItemFunction } from '../utils/collectionUpdater';

export function updateRoof(roof, designId) {
  const roofId = roof.id;
  const roofData = omit(roof, ['id']);
  const requestPath = `manual_mode/designs/${designId}/roofs/${roofId}`;
  const promise = Api.update(requestPath, roofData)
    .then((result) => {
      let data = {
        design: { moduleArrays: updateItemFunction(result.roof) },
        selectedInverterItems: result.selectedInverterItems,
        quote: { dirty: true, finEng: null }
      };
      if (roof.panelCount !== get(result, 'roof.panelCount')) {
        data = { ...data, moduleChanged: true };
      }
      return data;
    });

  return {
    type: 'updateRoof',
    payload: asyncPayload({
      request: promise
    })
  };
}
