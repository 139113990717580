import React from 'react';
import cx from 'classnames';
import { register } from '../../utils/redux';
import { quoteType, selectedStorageType } from '../../types';

export function StorageBox({
  quote,
  selectedStorage
}) {
  const name = (/Sonnen/i).test(selectedStorage.name) ? 'Sonnen' : 'Sunvault';
  const storageClassName = (/Powerwall/i).test(selectedStorage.name) ? 'Powerwall' : name;

  return (
    <div className="storage-container">
      <div className={cx(storageClassName, 'value-box-image')} />
      <div className="backup-hours">
        <p>
          <span className="support-text">UP TO</span>
          <span className="hours">{quote.storageBackUpHours}</span>
          <span className="support-text">HOURS</span>
        </p>
      </div>
    </div>
  );
}

StorageBox.propTypes = {
  quote: quoteType.isRequired,
  selectedStorage: selectedStorageType.isRequired
};

export default register(
  [ 'quoteSelector', 'selectedStorageSelector' ], [], StorageBox
);
