import { isSunpowerStorage } from './sunPowerStorage';

const SERIES_PATTERN = /[A-Z] series/i;
export function getSystemName(description) {
  if (description.match(SERIES_PATTERN)) {
    return `${description[0]}-Series`;
  }
  return `${description} panels`;
}

export function getRackingName(rackingType) {
  const racking = rackingType || '-';
  return racking === 'Invisimount' ? 'InvisiMount' : racking;
}

export function getMonitoringName(selectedStorage, monitoringName) {
  if (selectedStorage &&
    selectedStorage.batteryMonitoring &&
    selectedStorage.batteryMonitoring.name) {
    return selectedStorage.batteryMonitoring.name;
  } else if (monitoringName) {
    return monitoringName;
  }
  return '-';
}

