import _get from 'lodash/get';
import get from './get';
import createSelector from '../utils/createSelector';

function setSeries({ pvModules }) {
  return {
    pvModules: pvModules.map((pvModule) => {
      const productLine = _get(pvModule, 'item.productLine');
      let series = null;
      if (productLine) {
        if (productLine.toLowerCase() === 'u series 370') {
          series = 'U370';
        } else if (productLine.toLowerCase() === 'u series 365') {
          series = 'U365';
        } else if (productLine.toLowerCase() === 'u series 405') {
          series = 'U405';
        } else if (productLine.toLowerCase() === 'spwr') {
          series = 'spwr';
        } else {
          series = productLine[0];
        }
      }
      const additional = {
        series
      };
      return Object.assign({}, pvModule, additional);
    })
  };
}

export default createSelector(
  get('pvModules'),
  setSeries
);
