import logger from './logger';

const matchesBrowser = (browserName) => {
  try {
    return window.navigator.userAgent.search(browserName) >= 0;
  } catch (err) {
    logger.warn(err);
    return undefined;
  }
};
const isFirefox = matchesBrowser('Firefox');
const isChrome = matchesBrowser('Chrome');
const isSafari = matchesBrowser('Safari');
const isIE11 = matchesBrowser('Trident/7.');

const isIos = () => !!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform);

export default {
  isChrome,
  isFirefox,
  isSafari,
  isIos,
  isIE11
};
