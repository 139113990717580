import { asyncPayloadWithNoExplicitUpdate } from './apiHelpers';
import Api from '../utils/Api';

export const fetchCsatFeedback = (quoteSfid) => (dispatch) => {
  const promise = Api.fetch(`csat_feedbacks/${quoteSfid}`)
    .then((response) => ({
      ...response
    }));

  dispatch({
    type: 'fetchCsatFeedback',
    payload: asyncPayloadWithNoExplicitUpdate({
      request: promise
    })
  });
};
