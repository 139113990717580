import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { CSSTransition } from 'react-transition-group';
import { register } from '../../utils/redux';
import { notificationsType } from '../../types';

import Notification from './Notification';

export function OptimizationModal({
  notifications,
  toggleNotificationModal,
  show
}) {
  return (
    <CSSTransition
      in={show}
      timeout={2000}
      classNames="sidebar-slide"
    >
      <div id="optimization_sidebar" className={cx('optimization-sidebar', { 'sidebar-in': show })}>
        <div className="optimization-header">
          <strong>Optimize cost</strong>
          <span
            onClick={toggleNotificationModal}
            className="image-icon-navigation-close optimization-icon-close"
          />
        </div>
        <div className="optimization-content">
          {notifications.notifications.map((notification, index) =>
            <Notification
              key={index}
              cost={notification.cost}
              header={notification.notification.header}
              body={notification.notification.body}
              showNegativeCost={notification.showNegativeCost}
            />
          )}
        </div>
      </div>
    </CSSTransition>
  );
}

OptimizationModal.propTypes = {
  notifications: notificationsType.isRequired,
  toggleNotificationModal: PropTypes.func,
  show: PropTypes.bool
};

export default register(
  ['notificationsSelector'],
  ['toggleNotificationModal'],
  OptimizationModal
);

