import React from 'react';
import PropTypes from 'prop-types';
import DesignVisualization from '../DesignVisualization';

export default function RoofPreviewVisualization({ interactive }) {
  return (
    <div>
      <DesignVisualization interactive={interactive} />
    </div>
  );
}

RoofPreviewVisualization.propTypes = {
  interactive: PropTypes.bool.isRequired
};
