import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { register } from '../../utils/redux';
import { moduleSelectionModalType } from '../../types';
import constants from '../../utils/EddieCalculations/constants';

const {
  CRITTER_GUARD,
  SKIRT_GUARD
} = constants;
export function AccessoriesSection({
  title,
  moduleSelectionModal,
  updateModuleSelectionModal,
  accessories
}) {
  return (
    <div className="module-section dynamic colors">
      <div className="module-section-title">
        {title}
      </div>
      <div className="module-section-options-container">
        {accessories.indexOf(SKIRT_GUARD) > -1 && (
          <div
            className={`module-section-options ${accessories.length === 1 ? 'half-flex' : ''}`}
            onClick={() => {
              updateModuleSelectionModal('isSkirtGuard',
              !moduleSelectionModal.isSkirtGuard);
            }}
          >
            <div
              className={cx(
                  'module-option',
                  'colors',
                  { active: moduleSelectionModal.isSkirtGuard }
                )}
            >
              <div className="module-option-text">
                  Front Edge Skirt
                </div>
            </div>
          </div>
        )}
        {accessories.indexOf(CRITTER_GUARD) > -1 && (
          <React.Fragment>
            {accessories.length === 2 && (<div className="divider" />)}
            <div
              className={`module-section-options ${accessories.length === 1 ? 'half-flex' : ''}`}
              onClick={() => {
                updateModuleSelectionModal('isCritterGuard',
                !moduleSelectionModal.isCritterGuard);
              }}
            >
              <div
                className={cx(
                    'module-option',
                    'colors',
                    { active: moduleSelectionModal.isCritterGuard }
                  )}
              >
                <div className="module-option-text">
                    Critter Guard
                  </div>
              </div>
            </div>
          </React.Fragment>
        )}
      </div>
    </div>
  );
}

AccessoriesSection.propTypes = {
  title: PropTypes.string.isRequired,
  moduleSelectionModal: moduleSelectionModalType.isRequired,
  updateModuleSelectionModal: PropTypes.func.isRequired,
  accessories: PropTypes.arrayOf(PropTypes.string)
};

export default register(
  [
    'moduleSelectionModalSelector'
  ],
  [
    'updateModuleSelectionModal'
  ],
  AccessoriesSection
);
