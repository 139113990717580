import React from 'react';
import PropTypes from 'prop-types';
import CurrencyInput from './CurrencyInput';

export default function LineRadioInput({
  id,
  label,
  value,
  readOnly = false,
  disabled = false,
  checked,
  onChange,
  onBlur,
  positive = false,
  onSelect,
  onRadioSelect,
  onInputFocus,
  additionalText,
  additionalComponent,
  textInputClass,
  rounded = false
}) {
  return (
    <div className="form-group">
      <div className={`input-container ${textInputClass}`}>
        <input
          type="radio"
          disabled={readOnly || disabled}
          checked={checked}
          onChange={() => {
            onSelect();
            if (onRadioSelect) {
              onRadioSelect();
            }
          }}
        />
        <span className="radio-label">{label}</span>
        <CurrencyInput
          id={id}
          readOnly={onChange === undefined || readOnly}
          value={value}
          onChange={onChange}
          onBlur={onBlur}
          positive={positive}
          onFocus={() => {
            onSelect();
            if (onInputFocus) {
              onInputFocus();
            }
          }}
          rounded={rounded}
          disabled={disabled}
        />
        {additionalText && (
          <span className="additional-text">{additionalText}</span>
        )}
        {additionalComponent}
      </div>
    </div>
  );
}

LineRadioInput.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([ PropTypes.number, PropTypes.string ]),
  readOnly: PropTypes.bool,
  disabled: PropTypes.bool,
  checked: PropTypes.bool.isRequired,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  positive: PropTypes.bool,
  onSelect: PropTypes.func.isRequired,
  onRadioSelect: PropTypes.func,
  onInputFocus: PropTypes.func,
  additionalText: PropTypes.string,
  textInputClass: PropTypes.string,
  rounded: PropTypes.bool,
  additionalComponent: PropTypes.element
};
