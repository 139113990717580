import { findSelectedStates } from '../../utils/admin_portal/updateHelpers';
import selectedModuleSeriesSelector from './selectedModuleSeries';

export default (state) => {
  const {
    selectedFinanceOption,
    financialOfferings,
    apFinConfigs
  } = state;
  let availableModules = [];
  const selectedModuleSeries = selectedModuleSeriesSelector(state).selectedModuleSeries;

  if (selectedFinanceOption && financialOfferings && selectedModuleSeries && apFinConfigs) {
    const selectedStates = findSelectedStates(apFinConfigs, selectedFinanceOption);

    if (selectedStates.length > 0) {
      let modules = [];
      const stateArray = financialOfferings[selectedFinanceOption];

      selectedStates.forEach((selectedState) => {
        let modulesArray = stateArray[selectedState].module.map((details) => {
          if (selectedModuleSeries.includes(details.series)) {
            return {
              name: details.name.trim(),
              moduleItemId: details.moduleItemId,
              moduleSeries: details.series
            };
          }
          return null;
        });
        modulesArray = modulesArray.filter((module) => module !== null);
        modules = [ ...modules, ...modulesArray ];
      });

      availableModules = modules.reduce((unique, o) => {
        if (!unique.some((module) => module.name === o.name)) {
          unique.push(o);
        }
        return unique;
      }, []);
    }
  }
  return {
    availableModules
  };
};
