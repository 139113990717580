import React from 'react';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { register } from '../../../utils/redux';
import { dropdownsType, pvModuleType, quoteType, selectedEvChargerConfigurationType } from '../../../types';
import { getEvOutletAllowedQuantityList } from '../../../utils/EvChargers/evChargers';

const defaultContainerClasses = 'input-container input-container--icon icon-right dropdown-with-tags quantity-field';

export function EvOutletQuantityDropDown({
  pvModule,
  selectedEvChargerConfiguration,
  dropdowns: { isEvOutletQuantityOpen },
  onSelect,
  toggleEvOutletQuantityDropdown,
  quote
}) {
  const evChargerQuantity = selectedEvChargerConfiguration.evChargerQuantity || 0;
  const evOutletQuantity = selectedEvChargerConfiguration.evOutletQuantity || 0;
  const evOutletQuantities = getEvOutletAllowedQuantityList(get(pvModule, 'evmChargerConfigurations', []),
    evChargerQuantity);
  if (evOutletQuantities.indexOf(evOutletQuantity) === -1) {
    onSelect(selectedEvChargerConfiguration.evOutletId,
      selectedEvChargerConfiguration.evOutletConfigId,
      selectedEvChargerConfiguration.evOutletConfigName,
      evOutletQuantities[0]);
  }
  const options = (<ul>
    {
      evOutletQuantities.map((i) => (
        <li
          className={cx('cs-option-container', { 'cs-selected': evOutletQuantity === i })}
          onClick={() => {
            toggleEvOutletQuantityDropdown();
            onSelect(selectedEvChargerConfiguration.evOutletId,
              selectedEvChargerConfiguration.evOutletConfigId,
              selectedEvChargerConfiguration.evOutletConfigName,
              i);
          }}
          key={`quantity${i}`}
        >
          <span className="cs-option">
            <div className="cs-option-name">{i}</div>
          </span>
        </li>
      ))
    }
  </ul>);
  return options.props.children.length > 0 && selectedEvChargerConfiguration.evOutletConfigId ? (
    <div
      id="ev_charger_quantity_dropdown"
      className={cx(defaultContainerClasses, { 'is-disabled': quote.locked })}
    >
      <i className="icon icon-chevron-down" />
      <div
        className={cx('select-dropdown cs-skin-border', { 'cs-active': isEvOutletQuantityOpen })}
        tabIndex="0"
      >
        <span className="cs-placeholder" onClick={toggleEvOutletQuantityDropdown}>
          <div className="cs-option-name">{evOutletQuantity}</div>
        </span>
        <div className="cs-options">
          {options}
        </div>
      </div>
    </div>
  ) : (
    <div id="ev_outlet_quantity_dropdown" className="input-container">
      <input type="text" value={evOutletQuantity} disabled={true} />
    </div>
  );
}

EvOutletQuantityDropDown.propTypes = {
  pvModule: pvModuleType.isRequired,
  selectedEvChargerConfiguration: selectedEvChargerConfigurationType,
  dropdowns: dropdownsType.isRequired,
  quote: quoteType.isRequired,
  onSelect: PropTypes.func.isRequired,
  toggleEvOutletQuantityDropdown: PropTypes.func.isRequired
};

export default register(
  [
    'pvModuleSelector',
    'selectedEvChargerConfigurationSelector',
    'dropdownsSelector',
    'quoteSelector'
  ],
  ['toggleEvOutletQuantityDropdown'],
  EvOutletQuantityDropDown
);

