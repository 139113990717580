export function toggleMonthlyProductionModal() {
  return {
    type: 'toggleMonthlyProductionModal',
    payload: {
      design: {
        monthlyProductionModal: (currentValue) => !currentValue
      }
    }
  };
}
