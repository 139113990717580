import d3 from 'd3';

const electricBillColumn = (source, paymentOption, inflationRate) => {
  return [
    'Electric Bill',
    source[inflationRate][paymentOption].withoutSolarElectricBill,
    source[inflationRate][paymentOption].withSolarElectricBill,
    source['0'][paymentOption].withSolarElectricBill
  ];
};

const paymentsColumn = (source, paymentOption, inflationRate) => {
  let label;
  let paymentWithInflation;
  let paymentWithoutInflation;
  if ([ 'cash', 'spwrCash' ].includes(paymentOption)) {
    label = 'Solar Net Payment';
    paymentWithInflation = source[inflationRate][paymentOption].netCost;
    paymentWithoutInflation = source['0'][paymentOption].netCost;
  } else if (paymentOption === 'lease') {
    label = 'Solar Lease Payments';
    paymentWithInflation = source[inflationRate][paymentOption].solarPayments;
    paymentWithoutInflation = source['0'][paymentOption].solarPayments;
  } else if (paymentOption === 'loan') {
    label = 'Solar Loan Payments';
    paymentWithInflation = source[inflationRate][paymentOption].solarPayments;
    paymentWithoutInflation = source['0'][paymentOption].solarPayments;
  }

  return [
    label,
    0,
    paymentWithInflation,
    paymentWithoutInflation
  ];
};

const netSavingsColumn = (source, paymentOption, inflationRate) => {
  let savingsWithoutInflation;
  let savingsWithInflation;
  const label = 'Net Savings';
  if ([ 'cash', 'spwrCash' ].includes(paymentOption)) {
    savingsWithoutInflation = source['0'][paymentOption].solarSavingsForCash;
    savingsWithInflation = source[inflationRate][paymentOption].solarSavingsForCash;
  } else {
    savingsWithoutInflation = source['0'][paymentOption].solarSavings;
    savingsWithInflation = source[inflationRate][paymentOption].solarSavings;
  }
  return [
    label,
    0,
    savingsWithInflation,
    savingsWithoutInflation
  ];
};

const prepareColors = (electricBillData,
                       paymentsData,
                       netSavingsData) => {
  const colors = {};

  colors[electricBillData[0]] = '#D7D5D7';
  colors[paymentsData[0]] = '#F98702';
  colors[netSavingsData[0]] = '#2983B6';

  return colors;
};

const prepareColumns = (electricBillData,
                        paymentsData,
                        netSavingsData) => {
  return [
    electricBillData,
    paymentsData,
    netSavingsData
  ];
};

const prepareGroups = (electricBillData,
                       paymentsData,
                       netSavingsData) => {
  return [[
    electricBillData[0],
    paymentsData[0],
    netSavingsData[0]
  ]];
};

export default (source = {}, paymentOption = 'cash', inflationRate = 4) => {
  const electricBillData = electricBillColumn(source, paymentOption, inflationRate);
  const paymentsData = paymentsColumn(source, paymentOption, inflationRate);
  const netSavingsData = netSavingsColumn(source, paymentOption, inflationRate);

  const colors = prepareColors(
    electricBillData,
    paymentsData,
    netSavingsData
  );

  const columns = prepareColumns(
    electricBillData,
    paymentsData,
    netSavingsData
  );

  const groups = prepareGroups(
    electricBillData,
    paymentsData,
    netSavingsData
  );

  const data = {
    columns,
    colors,
    type: 'bar',
    groups,
    order: false
  };

  const axis = {
    x: {
      tick: {
        format() { return ''; },
        outer: false
      },
      type: 'category'
    },
    y: {
      padding: 0,
      tick: {
        format: d3.format('$,'),
        outer: false
      }
    }
  };

  const grid = {
    y: {
      show: true
    }
  };

  return {
    data,
    axis,
    grid
  };
};
