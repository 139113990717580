import React from 'react';
import PropTypes from 'prop-types';
import ValueBox from './ValueBox';
import Modal from './Modal';
import { register } from '../utils/redux';
import { quoteType, pvModuleType, settingsType } from '../types';
import { filterPvModules, tagList } from '../utils/pvModuleFiltersAndTags';

export function isPvModuleBlack(pvModule) {
  if (!pvModule) return false;
  return (/BLK/i).test(pvModule.item.description);
}

export function PanelTypesModal({
  quote,
  pvModules,
  selectedPvModule,
  selectPvModule,
  savePublicPvModule,
  savePvModule,
  settings,
  isPublic
}) {
  const highlightedPvModule = selectedPvModule || quote.pvModuleItemId;
  const pvModuleData = pvModules.map((pvModule) => Object.assign({}, pvModule, { tags: pvModule.financialOfferings }));

  return (
    <Modal
      name="panel-types"
      title="Equinox System"
      onSave={() => isPublic ?
        savePublicPvModule(quote, highlightedPvModule) :
        savePvModule(quote, highlightedPvModule)}
      onCancel={() => selectPvModule(null)}
    >
      <span className="heading-text">Panels</span>
      {
        filterPvModules(pvModuleData, settings).map((pvModule, index) => {
          const imageClassName = isPvModuleBlack(pvModule) ? 'black-module' : 'generic-module';

          return (<ValueBox
            key={index}
            className="cs-option"
            label={pvModule.item.tagLine}
            value={pvModule.item.productLine}
            subvalue={pvModule.item.summaryDescription}
            subtitle={tagList(pvModule.financialOfferings, settings)}
            imageClassName={imageClassName}
            isSelected={pvModule.itemId === highlightedPvModule}
            onClick={() => selectPvModule(pvModule)}
          />);
        })
      }

      <span className="description prelude">All Equinox systems include:</span>

      <div className="product-section">
        <div className="text-container">
          <span className="heading-text">InvisiMount&reg; Racking</span>
          <span className="description">Recessed hardware designed to showcase SunPower panels</span>
        </div>
        <div className="product-image racking-image" />
      </div>

      <div className="product-section">
        <div className="text-container">
          <span className="heading-text">EnergyLink&trade; Monitoring</span>
          <span className="description">Real-time insights for intuitive home energy management</span>
        </div>
        <div className="product-image monitoring-image" />
      </div>
    </Modal>
  );
}

PanelTypesModal.propTypes = {
  quote: quoteType.isRequired,
  pvModules: PropTypes.arrayOf(pvModuleType).isRequired,
  selectedPvModule: PropTypes.string,
  selectPvModule: PropTypes.func.isRequired,
  savePvModule: PropTypes.func.isRequired,
  savePublicPvModule: PropTypes.func,
  settings: settingsType.isRequired,
  isPublic: PropTypes.bool
};

export default register(
  [ 'quoteSelector', 'pvModulesSelector', 'selectedPvModuleSelector', 'settingsSelector',
    'isPublicSelector' ],
  [ 'selectPvModule', 'savePvModule', 'savePublicPvModule', 'toggleModal' ],
  PanelTypesModal
);
