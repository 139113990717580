import React from 'react';
import PropTypes from 'prop-types';

import { gaTrackEvent } from '../../utils/googleAnalytics';
import { BASIC_VIEW, DETAILED_VIEW } from '../../config';

export function InstantQuoteVisualizationTabs({
  setViewMode,
  designTabs,
  viewMode
}) {
  return (
    <div className="visualization-tabs">
      {designTabs.map((tab) =>
        <button
          key={tab.mode}
          className={`btn visualization-tabs__btn ${tab.mode} ${
            (viewMode === tab.mode ? 'visualization-tabs__btn--active' : '')
            }`}
          onClick={() => {
            setViewMode(tab.mode);
            gaTrackEvent(
              tab.mode === BASIC_VIEW ?
                'instantQuotePage:designTab:basicView' : 'instantQuotePage:designTab:detailedView'
            );
          }}
        >
          {tab.label}
        </button>
      )}
    </div>
  );
}

InstantQuoteVisualizationTabs.propTypes = {
  setViewMode: PropTypes.func.isRequired,
  designTabs: PropTypes.arrayOf(PropTypes.shape({
    mode: PropTypes.string,
    label: PropTypes.string
  })),
  viewMode: PropTypes.oneOf([
    BASIC_VIEW,
    DETAILED_VIEW
  ]).isRequired
};
