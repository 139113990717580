import environment from '../utils/environment';

function errorOptionParser(error) {
  if (error.errorClass === 'FinEngService::MinPriceValidationError') {
    const errorOptions = { fieldErrors: {} };
    error.options.forEach((value) => {
      errorOptions.fieldErrors[value] = {
        pricePerWatt: 'is too small',
        pricePerKwh: 'is too small',
        grossPrice: 'is too small'
      };
    });
    return errorOptions;
  }
  return {};
}

export function extractError(error) {
  if (error.TYPE === 'ApiError') {
    return {
      error: {
        isFatal: error.isFatal,
        status: error.status,
        message: error.message,
        errorClass: error.errorClass,
        display: 'both',
        backendStack: error.backendStack,
        ...errorOptionParser(error)
      }
    };
  } else if (error.TYPE === 'UnexpectedApiError' && environment.isDevelopment()) {
    return {
      error: {
        isFatal: false,
        status: error.status,
        message: error.message,
        errorClass: 'unexpected-api-error',
        display: 'both',
        backendStack: error.backendStack
      }
    };
  }

  return {};
}

export const setError = ({
  isFatal = false, status, message = '', errorClass = '', stack, fieldErrors = {}, display = 'both'
}) => ({
  type: 'setError',
  payload: {
    error: { isFatal, status, message, errorClass, stack, fieldErrors, display }
  }
});

export const clearError = () => ({
  type: 'clearError',
  payload: {
    error: null,
    display: null
  }
});
