import { asyncPayload } from './apiHelpers';
import Api from '../utils/Api';

export function updateQuote(quoteSfid, attributes, applyBefore = false, moduleChanged = false, storageChanged = false) {
  let afterRequestBody = {
    quote: {
      finEng: null
    }
  };

  if (moduleChanged) {
    afterRequestBody = { ...afterRequestBody, moduleChanged: true };
    attributes = { ...attributes, moduleChanged: true };
  }

  if (storageChanged) {
    afterRequestBody = { ...afterRequestBody, storageChanged: true };
    attributes = { ...attributes, storageChanged: true };
  }

  const update = {
    request: Api.update(`manual_mode/quotes/${quoteSfid}/configurations`, attributes)
      .then((result) => {
        return {
          quote: result.quote
        };
      }),
    afterRequest: afterRequestBody
  };
  if (applyBefore) {
    update.beforeRequest = {
      quote: attributes
    };
  }
  return {
    type: 'updateQuote',
    payload: asyncPayload(update)
  };
}
