import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-bootstrap-modal';
import { register } from '../../utils/redux';
import {
  designType,
  pvModuleType,
  quoteType,
  selectedEvChargerConfigurationType
} from '../../types';
import EvChargerSettings from './EvChargerSettings';

export function AddEvChargerModal({ quote, pvModule, design,
  toggleAddEvChargerModalFlag, selectedEvChargerConfiguration, partnerIsSpd }) {
  return (
    <Modal
      className="add-ev-charger-modal"
      show={design.isAddEvChargerModalOpen}
      onHide={toggleAddEvChargerModalFlag}
      aria-labelledby="ModalHeader"
    >
      <Modal.Header closeButton={true}>
        <div className="modal-title">Wallbox EV Charger&trade;</div>
      </Modal.Header>
      <Modal.Body>
        <EvChargerSettings
          quote={quote}
          pvModule={pvModule}
          selectedEvChargerConfiguration={selectedEvChargerConfiguration}
          toggleAddEvChargerModalFlag={toggleAddEvChargerModalFlag}
          partnerIsSpd={partnerIsSpd}
        />
      </Modal.Body>
    </Modal>
  );
}

AddEvChargerModal.propTypes = {
  quote: quoteType.isRequired,
  pvModule: pvModuleType.isRequired,
  design: designType.isRequired,
  selectedEvChargerConfiguration: selectedEvChargerConfigurationType,
  toggleAddEvChargerModalFlag: PropTypes.func.isRequired,
  partnerIsSpd: PropTypes.bool
};

export default register(
  [
    'designSelector', 'quoteSelector', 'pvModuleSelector', 'selectedEvChargerConfigurationSelector',
    'partnerIsSpdSelector'
  ],
  [
    'toggleAddEvChargerModalFlag'
  ],
  AddEvChargerModal
);
