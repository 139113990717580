import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ModuleSection from './ModuleSection';
import { register } from '../../../utils/redux';
import {
  designType,
  quoteType,
  accountType,
  moduleSelectionModalType,
  settingsType,
  pvModuleType
} from '../../../types';
import { addEnhancedPerformanceWarrantyCost } from '../../../utils/EddieCalculations/finance';
import { guardAccessoriesEnabled } from '../../../utils/backendFeatureFlags';

export function EquipmentSelectionDrawer({
  design,
  quote,
  account,
  moduleSelectionModal,
  closeModuleSelectionModal,
  saveModuleMonitoringRacking,
  settings,
  updateSettings,
  saveSettings,
  pvModules
}) {
  const [sections] = useState([
    {
      section: 'series',
      prop: 'selectedSeries'
    },
    {
      title: 'Color',
      section: 'colors',
      prop: 'selectedColor',
      insetText: true
    },
    {
      title: 'Model',
      section: 'models',
      prop: 'selectedModel',
      insetText: true
    },
    {
      title: 'Inverter',
      section: 'inverter',
      prop: 'selectedInverter'
    },
    {
      title: 'Monitoring',
      section: 'monitoring',
      prop: 'selectedMonitoring'
    },
    {
      title: 'Racking',
      section: 'rackings',
      prop: 'selectedRacking'
    }
  ]);

  const saveModal = () => {
    saveModuleMonitoringRacking({
      quote,
      pvModuleItemId: moduleSelectionModal.pvModuleItemId,
      monitoringConfigurationId: moduleSelectionModal.monitoringConfigurationId,
      rackingType: moduleSelectionModal.selectedRacking,
      isSkirtGuard: moduleSelectionModal.isSkirtGuard,
      isCritterGuard: moduleSelectionModal.isCritterGuard
    });
    if (settings.loan.enhancedPerformanceWarranty) {
      const selectedPvModule = pvModules.find((pvModule) => pvModule.itemId === moduleSelectionModal.pvModuleItemId);
      const watts = parseInt(selectedPvModule.item.summaryDescription.replace(/\D/g, ''), 10);
      if (!addEnhancedPerformanceWarrantyCost(watts)) {
        updateSettings('loan', 'enhancedPerformanceWarranty', false);
        const loan = { enhancedPerformanceWarranty: false };
        saveSettings(quote, { ...settings, loan });
      }
    }

    closeModuleSelectionModal();
  };

  if (!design.moduleSelectionModal) {
    return null;
  }

  return (
    <div id="equipment-selection-drawer">
      <div className="mask" onClick={closeModuleSelectionModal} />
      <div className="drawer">
        <div className="header">
          <div className="title">SELECT EQUIPMENT</div>
          <div className="buttons">
            <button className="btn btn-secondary" onClick={closeModuleSelectionModal}>
              Cancel
            </button>
            <button className="btn btn-primary" onClick={saveModal}>
              Save
            </button>
          </div>
        </div>

        <div className="content">
          {sections.map((section) => (
            <ModuleSection key={section.section} {...section} />
          ))}

          { account.features.addersInEddie && guardAccessoriesEnabled() ? (
            <ModuleSection
              title="Add Accessories"
              section="accessories"
              prop=""
            />
          ) : null}
        </div>
      </div>
    </div>
  );
}

EquipmentSelectionDrawer.propTypes = {
  design: designType.isRequired,
  quote: quoteType.isRequired,
  account: accountType.isRequired,
  moduleSelectionModal: moduleSelectionModalType.isRequired,
  closeModuleSelectionModal: PropTypes.func.isRequired,
  saveModuleMonitoringRacking: PropTypes.func.isRequired,
  settings: settingsType.isRequired,
  updateSettings: PropTypes.func.isRequired,
  saveSettings: PropTypes.func.isRequired,
  pvModules: PropTypes.arrayOf(pvModuleType).isRequired
};

export default register(
  [
    'designSelector', 'quoteSelector', 'accountSelector',
    'moduleSelectionModalSelector', 'settingsSelector', 'pvModulesSelector'
  ],
  [
    'closeModuleSelectionModal', 'saveModuleMonitoringRacking', 'updateSettings', 'saveSettings'
  ],
  EquipmentSelectionDrawer
);
