import React, { useState } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { register } from '../../utils/redux';
import { getAvailableInverters, INCOMPATIBLE_INVERTERS } from '../../utils/storageQuote/inverters';

export function InverterListDropdown({ inverterType, setInverterType }) {
  const [ isDropdownOpen, setIsDropdownOpen ] = useState(false);
  const [ selectedInverterOption, setSelectedInverterOption ] = useState(inverterType);
  const inverterOptions = getAvailableInverters();

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const selectInverter = (name) => {
    setSelectedInverterOption(name);
    setInverterType(name);
  };

  const checkIfIncompatibleOption = (option) => {
    return INCOMPATIBLE_INVERTERS.indexOf(option) > -1;
  };

  const options = (<ul>
    { inverterOptions.map((option, index) => {
      return (
        <li
          className={cx('cs-option-container')}
          onClick={() => {
            selectInverter(option);
            toggleDropdown();
          }}
          key={index}
        >
          <span className="cs-option">
            <div className="cs-option-name">
              <div><b>{option}</b> Inverter</div>
              {checkIfIncompatibleOption(option) &&
              <div className="not-compatible-label">NOT COMPATIBLE</div>
              }
            </div>
          </span>
        </li>
      );
    })}
  </ul>);

  return (
    <div
      id="storage_quote_inverter_dropdown"
      className="input-container input-container--icon icon-right dropdown-with-tags"
    >
      <i className="icon icon-chevron-down" />
      <div className={`select-dropdown cs-skin-border ${isDropdownOpen ? 'cs-active' : ''}`}>
        {selectedInverterOption &&
        <span className="cs-placeholder active" onClick={() => toggleDropdown()}>
          <div className="cs-option-name"><b>{selectedInverterOption}</b> Inverter</div>
        </span>
        }
        {!selectedInverterOption &&
        <span className="cs-placeholder" onClick={() => toggleDropdown()}>
          <div className="cs-option-name">Select Inverter Type</div>
        </span>
        }
        <div className="cs-options">
          { options }
        </div>
      </div>
    </div>
  );
}

InverterListDropdown.propTypes = {
  inverterType: PropTypes.string,
  setInverterType: PropTypes.func
};

export default register(
  ['inverterTypeSelector'],
  [],
  InverterListDropdown
);

