import React from 'react';
import PropTypes from 'prop-types';
import { register } from '../../../utils/redux';

export function GraphSelectionView({ graphSelections, updateGraphSelection }) {
  const GRAPH_TITLES = [ 'savings over time', 'total savings', 'electricity bill savings' ];

  function titleToString(str) {
    return str.replace(/(?:^\w|[A-Z]|\b\w)/g, (word, index) => {
      return index === 0 ? word.toLowerCase() : word.toUpperCase();
    }).replace(/\s+/g, '');
  }

  function titleToClassName(title) {
    return title.replace(/\s/g, '-');
  }

  const graphToggle = (graphTitle) => {
    const graphString = titleToString(graphTitle);
    return (
      <div className="graph-toggle">
        <div>
          <label>Show &ldquo;{graphTitle}&rdquo; graph</label>
          <p>
          Turning this off will remove &ldquo;{graphTitle}&rdquo; graph within
          all instances of EDDiE and the proposal.
          {graphString === 'electricityBillSavings' ?
            <span> The &ldquo;electricity offset &rdquo; graph will stay </span> : ''
          }
          </p>
          <div className="toggle-btn-container">
            <label className="toggle-btn">
              <input
                type="checkbox"
                checked={graphSelections[graphString]}
                onChange={(e) => updateGraphSelection(graphString, e.target.checked)}
              />
              <span className="slider round" />
            </label>
            <label className="toggle-btn-status-label">{graphSelections[graphString] ? 'ON' : 'OFF'}</label>
          </div>
        </div>
        <div className={titleToClassName(graphTitle)} />
      </div>
    );
  };

  return (
    <div className="admin-table-container graph-selection">
      <div className="table-heading">
        <b>Proposal Management</b>
        <p>
        Selections below apply to ALL proposals
      </p>
      </div>
      {
      GRAPH_TITLES.map((graphTitle) => {
        return (
          graphToggle(graphTitle)
        );
      })
    }
    </div>
  );
}

GraphSelectionView.propTypes = {
  updateGraphSelection: PropTypes.func,
  graphSelections: PropTypes.objectOf(PropTypes.object)
};

export default register(
  [],
  [],
  GraphSelectionView
);
