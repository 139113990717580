import { compose } from 'redux';
import { connect } from 'react-redux';
import { validationErrorsSelector } from '../../selectors';

export function withErrors(Component) {
  return ({ validationErrors, ...props }) => {
    const fieldFor = props.for;

    const fieldErrors = fieldFor ? validationErrors[fieldFor] : validationErrors;
    const hasErrors = fieldErrors && fieldErrors.length > 0;

    return Component({ ...props, fieldErrors, hasErrors });
  };
}

export default compose(
  connect((state) => validationErrorsSelector(state)),
  withErrors
);
