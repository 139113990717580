import { get } from 'lodash';
import Api from '../utils/Api';
import constants from '../utils/EddieCalculations/constants';
import { setPricingsControls } from '../utils/pricingDefaultsHelper';

export const resetSettings = (
  account,
  design,
  quote,
  pvModules,
  settings,
  updateSettings
) => (dispatch) => {
  if (quote.pricingControlsConfigs && quote.pricingControlsConfigs.length > 0) {
    Api.fetch(`quotes/${quote.sfid}/settings/fetch_settings`).then((response) => {
      const cashPrice = get(response.settings, 'cash.pricePerWattWithMonitoringCost');
      if (!cashPrice) response.settings.cash.pricePerWattWithMonitoringCost = 0;
      constants.FINANCIAL_OFFERINGS.map((fo) => {
        if (settings && settings[fo].userInput && response.settings[fo]) response.settings[fo] = settings[fo];
        return fo;
      });
      dispatch({
        type: 'resetSettings',
        payload: {
          settings: response.settings
        }
      });
      setPricingsControls(
        account,
        design,
        quote,
        pvModules,
        response.settings,
        updateSettings
      );
    });
  }
};
