import React from 'react';
import get from 'lodash/get';

const selectedPaymentOptions = (settings) => [ 'cash', 'lease', 'loan', 'spwrCash', 'ppa' ]
  .filter((option) => get(settings, `${option}.showCustomer`, false));

const moduleTrim = (module) => {
  return module.replace(' Series ', '').replace('REC', 'R');
};

const adminModules = (adminFinConfigs, settings, state) => {
  const modules = [];
  const filteredAdminFinConfig = adminFinConfigs.filter(((config) =>
     selectedPaymentOptions(settings).includes(config.finType)
    )
  );
  if (filteredAdminFinConfig && filteredAdminFinConfig.every((config) => !!config.moduleConfigsAttributes)) {
    filteredAdminFinConfig.forEach((config) =>
      config.moduleConfigsAttributes.forEach((con) =>
        modules.push(moduleTrim(con.moduleSeries))
      )
    );
  }
  return modules;
};

export const tagsToShow = (tags, settings) => {
  const paymentOptions = selectedPaymentOptions(settings);
  if (paymentOptions.every((option) => tags.includes(option))) {
    return [];
  }
  return tags.filter((tag) => paymentOptions.includes(tag));
};

export const filterPvModules = (pvModules, settings) => pvModules.filter((pvModule) =>
  selectedPaymentOptions(settings).some((option) => pvModule.tags.includes(option))
);

export const filterPvModulesByAdminSettings = (pvModules, settings, quote, account) => {
  let filteredPvModules = pvModules.filter((pvModule) =>
    selectedPaymentOptions(settings).some((option) => pvModule.financialOfferings.includes(option)));
  const adminModuleConfigs = quote && quote.adminFinConfigs ?
    adminModules(quote.adminFinConfigs, settings, account.state) : false;
  if (adminModuleConfigs.length > 0) {
    filteredPvModules = filteredPvModules.filter((pvModule) =>
    adminModuleConfigs.some((config) => pvModule.series.toLowerCase() === config.toLowerCase()));
  }
  return filteredPvModules;
};

export const filterPvModulesByFinanceOfferings =
  (pvModules, settings) => pvModules.filter((pvModule) =>
  selectedPaymentOptions(settings).every((option) =>
    pvModule.tags.includes(option))
);

export function pvModuleTagToName(tag) {
  const tagLabelNameMapping = {
    cash: 'Cash',
    loan: 'Loan',
    lease: 'Lease',
    spwrCash: 'SPWR Cash',
    ppa: 'PPA'
  };

  return tagLabelNameMapping[tag];
}

export const tagList = (tags, settings) => {
  const list = tagsToShow(tags, settings);

  return list.length > 0 ? (
    <ul className="cs-option-tags">
      {tagsToShow(tags, settings).map((tag) => {
        return <li className="cs-option-tag" key={tag}>{pvModuleTagToName(tag)}</li>;
      })}
    </ul>
    ) : null;
};
