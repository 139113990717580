import u from 'updeep';

export {
  default as accountSelector,
  partnerIsTpsSelector,
  partnerIsRvarSelector,
  partnerSectorLeaseIsNewHomesSelector,
  partnerIsSpdSelector,
  partnerIsNewHomesSelector,
  partnerIsDirectSelector
} from './account';
export { default as annualUsageSelector } from './annualUsage';
export { default as adDesignSelector } from './adDesign';
export { default as energyDataSelector } from './energyData';
export { default as designSelector, systemCapacityWattsSelector, annualKwhProductionSelector } from './design';
export { default as errorSelector } from './error';
export { default as loadingSelector } from './loading';
export { default as modalsSelector } from './modals';
export { default as modeSelector } from './dynamic_design/mode';
export { default as roofPreviewModeSelector } from './dynamic_design/roofPreviewMode';
export { default as viewModeSelector } from './dynamic_design/viewMode';
export { default as pvModulesSelector } from './pvModules';
export { default as quoteSelector } from './quote';
export { default as baseQuoteSelector } from './baseQuote';
export {
    cashSystemCostSelector, spwrCashSystemCostSelector,
    leaseSystemCostSelector, loanSystemCostSelector,
    allSystemCostsSelector, mortgageSystemCostSelector,
    ppaSystemCostSelector
} from './systemCosts';
export { default as savingsSelector } from './savings';
export { default as selectedPvModuleSelector } from './selectedPvModule';
export { default as settingsSelector } from './settings';
export { default as visualizationSelector } from './visualization';
export { default as creditSelector } from './credit';
export { default as partialPrepayment } from './partialPrepayment';
export { default as messagesSelector } from './messages';
export { default as productConfigurationSelector } from './productConfiguration';
export { default as roofSelector } from './roof';
export { default as dcModuleSelector } from './dcModule';
export { default as rackingsSelector } from './rackings';
export { default as invertersSelector } from './inverters';
export { default as mainInverterSelector } from './mainInverter';
export { default as manualModeSelector } from './manualMode';
export { default as selectedInvertersSelector } from './selectedInverters';
export { default as selectedInverterSelector } from './selectedInverter';
export { default as validationErrorsSelector } from './validationErrors';
export { default as currentTabNameSelector } from './currentTabName';
export { returnURLSelector } from './query';
export { default as savingsChartSelector } from './savingsChart';
export { default as inflationRateSelector } from './inflationRate';
export { default as savingsChartInfoBoxDataSelector } from './savingsChartInfoBoxData';
export { default as totalSavingsChartSelector } from './totalSavingsChart';
export { default as panelsSelector } from './panels';
export { default as productSelectionModalSelector } from './productSelectionModal';
export { default as moduleSelectionModalSelector } from './moduleSelectionModal';
export { default as storageSelectionModalSelector } from './storageSelectionModal';
export { default as proposalSelector } from './proposal';
export { default as adobeSignSelector } from './adobeSign';
export { default as emailProposalUiSelector } from './emailProposalUi';
export { default as pvModuleSelector } from './pvModule';
export { isConnecticutSelector, isNewYorkSelector,
  isSpecialStateSelector, specialStateFullNameSelector } from './accountState';
export { default as isPublicSelector } from './isPublic';
export { default as isSavedSelector } from './isSaved';
export { default as monitoringConfiguration } from './monitoringConfiguration';
export { default as monitoringConfigurations } from './monitoringConfigurations';
export { default as dropdownsSelector } from './dropdowns';
export { showDealerFeePerWatt, showDynamicProposal } from './features';
export { default as selectedRackingSelector } from './selectedRacking';
export { default as optimizationNotificationSelector } from './optimizationNotification';
export { default as schemaSelector } from './schema';
export { default as notificationsSelector } from './notifications';
export { default as selectedInverterItemsSelector } from './selectedInverterItems';
export { default as inverterUnitsSelector } from './inverterUnits';
export { default as selectedStorageSelector } from './selectedStorage';
export { default as selectedDesign } from './selectedDesign';
export { default as designVersion } from './designVersion';
export { isV2CalculationState, isApplyHaircutState } from './calculationsVersions';
export { default as businessFunctionsSelector } from './businessFunctions';
export { default as newHomesDetailsSelector } from './newHomesDetails';
export { default as isPartnerEnabledForAnyStorageSelector } from './isPartnerEnabledForAnyStorage';
export { default as graphLoadingSelector } from './graphLoading';
export { default as instantQuoteModeSelector } from './instantQuoteMode';
export { default as canceledInstantQuoteLoadingSelector } from './isInstantQuoteLoadingCanceled';
export { default as instantQuoteDesignErrorSelector } from './checkInstantQuoteDesignError';
export { default as instantQuoteProductionSelector } from './getInstantQuoteProduction';
export {
  instantQuoteSaveDesignRequestSelector,
  instantQuoteSaveDesignPreviousRequestSelector,
  instantQuoteSyncDesignSelector
} from './getCurrentInstantQuoteSaveDesignRequest';
export { default as rightPanelExpandedSelector } from './rightPanelExpandedSelector';
export { default as storageQuoteSelector } from './storage_quote/storageQuote';
export { default as lockingStorageQuoteSelector } from './storage_quote/lockingStorageQuote';
export { default as inverterTypeSelector } from './storage_quote/inverterType';
export { default as redirectRequiredSelector } from './redirectRequired';
export { default as dirtySettingsSelector } from './dirtySettings';
export { default as selectedStorageWithExpansionPackSelector } from './selectedStorageWithExpansionPack';
export { default as storageConfigurationsSelector } from './storage_quote/storageConfigurations';
export { default as selectedStorageConfigurationSelector } from './storage_quote/selectedStorageConfiguration';
export { default as loanCalcValuesSelector } from './loanCalcValues';
export { default as savingLoanPricingSelector } from './savingLoanPricing';
export { default as loanCalcDirtySelector } from './loanCalcDirty';
export { default as updateCoPaymentSelector } from './updateCoPayment';
export { default as newCoPaymentValueSelector } from './newCoPaymentValue';
export { default as updatingLeaseSelector } from './updatingLease';
export { default as moduleChangedSelector } from './moduleChanged';
export { default as storageChangedSelector } from './storageChanged';
export { default as constantValuesSelector } from './constantValues';
export { default as proposalDetailsSelector } from './proposalDetails';
export { default as chartTermSelector } from './chartTerm';
export { default as dynamicProposalSelector } from './dynamicProposal';
export { default as defaultChartTabSelector } from './defaultChartTab';
export { default as userDataSelector } from './userData';
export { default as historyLogSelector } from './historyLog';
export { default as financialOfferingsSelector } from './admin_portal/financialOfferings';
export { default as selectedFinanceOptionSelector } from './admin_portal/selectedFinanceOption';
export { default as financialOptionsSelector } from './admin_portal/financialOptions';
export { default as partnerChannelSelector } from './admin_portal/partnerChannel';
export { default as allAvailableStatesSelector } from './admin_portal/allAvailableStates';
export { default as availableModuleSeriesSelector } from './admin_portal/availableModuleSeries';
export { default as availableModulesSelector } from './admin_portal/availableModules';
export { default as selectedModuleSeriesSelector } from './admin_portal/selectedModuleSeries';
export { default as selectedModuleSelector } from './admin_portal/selectedModule';
export { default as apFinConfigsSelector } from './admin_portal/apFinConfigs';
export { default as apSalesforceSelector } from './admin_portal/apSalesforce';
export { default as adminFinConfigsSelector } from './adminFinConfigs';
export { default as apAvailableDownPaymentsSelector } from './admin_portal/apAvailableDownPayments';
export { default as apIsFullPrepaymentAllowedSelector } from './admin_portal/apIsFullPrepaymentAllowed';
export { default as evChargerSelectionModalSelector } from './evChargerSelectionModal';
export { default as evChargerConfigurationsSelector } from './storage_quote/evChargerConfigurations';
export { default as selectedSoEvChargerConfigurationsSelector } from './storage_quote/selectedEvChargerConfigurations';
export { default as selectedEvChargerConfigurationSelector } from './selectedEvChargerConfiguration';
export { default as lockingQuoteSelector } from './lockingQuote';
export { default as showLoaderSelector } from './showLoader';
export { default as soProposalDetailsSelector } from './storage_quote/soProposalDetails';
export { default as soDynamicProposalSelector } from './storage_quote/soDynamicProposal';
export { default as showCreditAppModalSelector } from './showCreditAppModal';
export { default as creditAppUrlSelector } from './creditAppUrl';
export { default as currentStorageConfigurationSelector } from './currentStorageConfiguration';
export { default as csatFeedbackSelector } from './csatFeedback';
export { default as graphSelectionsSelector } from './admin_portal/graphSelections';
export { default as dealersFeatureFlagsSelector } from './admin_portal/dealersFeatureFlags';
export { default as storageProductConfigSelector } from './admin_portal/storageProductConfig';
export { default as pricingControlsSelector } from './admin_portal/pricingControls';
export { default as pricingControlsConfigsSelector } from './pricingControlsConfigs';
export { default as pvModuleSeriesDetailsSelector } from './pvModuleSeriesDetails';
export { default as pvModuleDetailsSelector } from './pvModuleDetails';
export { default as utilitySavingsTypeSelector } from './utilitySavingsType';

export const select = (state, selectors) => selectors.reduce((selections, selector) =>
  u(selector(state), selections), {});
