import React from 'react';
import PropTypes from 'prop-types';
import ReactTooltip from 'react-tooltip';

import { register } from '../../utils/redux';
/**
 * TODO: use Navigation, ErrorOverlay, MessageOverlay from storage_quote folder not
 * from root storage quote as design of these components might change in future
 * Add spec once requirement is finalized
 * */
import { errorType, messagesType } from '../../types';
import ErrorOverlay from './ErrorOverlay';
import MessagesOverlay from './MessagesOverlay';
import Navigation from './Navigation';
import { StorageQuoteLoader } from './StorageQuoteLoader';

export function StorageQuoteContainer({ loading, error, messages, children }) {
  const storageQuoteContainerHtmlId = 'storage-quote-container';
  const bodyDiv = document.querySelector('body');

  const showNavigation = () => {
    return !(window.location.href.indexOf('home') > -1) && !loading;
  };

  if (loading) {
    bodyDiv.classList.add('no-scroll');
  } else {
    bodyDiv.classList.remove('no-scroll');
  }

  const loader = () => {
    if (loading) {
      return (
        <div className="storage-quote-loader-container">
          <StorageQuoteLoader />
        </div>
      );
    }
    return null;
  };

  return (
    <div id={storageQuoteContainerHtmlId}>
      { showNavigation() && <Navigation /> }
      { children }
      { loader() }
      { error && (!error.display || error.display === 'both' || error.display === 'modal') && <ErrorOverlay /> }
      { messages && messages.length > 0 && <MessagesOverlay /> }
      <ReactTooltip html={true} />
    </div>
  );
}

StorageQuoteContainer.propTypes = {
  loading: PropTypes.bool.isRequired,
  children: PropTypes.element.isRequired,
  error: errorType,
  messages: messagesType
};

StorageQuoteContainer.defaultProps = {
  modals: {}
};

export default register(
  [ 'loadingSelector', 'errorSelector', 'messagesSelector' ],
  [],
  StorageQuoteContainer
);
