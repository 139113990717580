import PropTypes from 'prop-types';
import React, { createRef } from 'react';

export function EmptyFile({ upload }) {
  const uploadRef = createRef();

  return (
    <React.Fragment>
      <div className="upload upload-file">
        <b>.CSV file format only</b>
        <span>Pricing set to default (no current file)</span>
      </div>
      <button
        className="btn btn-secondary"
        onClick={() => uploadRef.current.click()}
      >
        Upload File
      </button>
      <input
        type="file"
        accept="text/csv"
        ref={uploadRef}
        onChange={(event) => upload(event)}
        onClick={(event) => {
          event.target.value = null;
        }}
        hidden={true}
      />
    </React.Fragment>
  );
}

EmptyFile.propTypes = {
  upload: PropTypes.func
};
