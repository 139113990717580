import PropTypes from 'prop-types';

export const adobeSignType = PropTypes.shape({
  widgetId: PropTypes.string,
  widgetExists: PropTypes.bool.isRequired,
  hasBeenAccepted: PropTypes.bool.isRequired,
  url: PropTypes.string,
  showWidget: PropTypes.bool.isRequired,
  willWidgetExpireWithinHour: PropTypes.bool.isRequired,
  refreshingWidget: PropTypes.bool.isRequired
});
