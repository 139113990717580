import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import ProposalViewer from './ProposalViewer';
import ProposalToolbar from './ProposalToolbar';
import { register } from '../../../utils/redux';
import { proposalType, storageQuoteType } from '../../../types';

const StorageQuoteProposalPage = () => (
  <div id="main-content" className="proposal-page" style={{ overflow: 'hidden', WebkitOverflowScrolling: 'touch' }}>
    <div className="pdf-viewer-container">
      {<ProposalToolbar />}
      {<ProposalViewer />}
    </div>
  </div>
);

StorageQuoteProposalPage.propTypes = {};

export const RegisteredProposalPage = register(
  [
    'proposalSelector',
    'storageQuoteSelector'
  ],
  [],
  StorageQuoteProposalPage
);

const prepareDocuments = ({ storageQuote, proposal, generateStorageQuoteProposalFile }) => {
  if (storageQuote.sfid && !proposal.pdfUrl) {
    generateStorageQuoteProposalFile(storageQuote, 'pdf');
  }
};

const ProposalPageLifecycle = (props) => {
  useEffect(() => {
    prepareDocuments(props);
  });

  return (<RegisteredProposalPage />);
};

ProposalPageLifecycle.propTypes = {
  storageQuote: storageQuoteType.isRequired,
  generateStorageQuoteProposalFile: PropTypes.func.isRequired,
  proposal: proposalType.isRequired
};

export default register(
  [
    'storageQuoteSelector',
    'proposalSelector'
  ],
  [
    'generateStorageQuoteProposalFile'
  ],
  ProposalPageLifecycle
);
