import React from 'react';
import PropTypes from 'prop-types';
import Tab from './Tab';
import { register } from '../utils/redux';
import { mortgageEnabled, ppaEnabled } from '../utils/backendFeatureFlags';
import constants from '../utils/EddieCalculations/constants';

const {
  CASH_FINANCE_TYPE,
  SPWR_CASH_FINANCE_TYPE,
  PPA_FINANCE_TYPE,
  MORTGAGE_FINANCE_TYPE_LABEL,
  SPWR_FINANCE_TYPE_LABEL
} = constants;

export function FinanceTabs({
  activeTab,
  toggle,
  showSpwrCash = true,
  availableTabs = {
    loan: true, lease: true, ppa: true, cash: true, spwrCash: true
  },
  partnerSectorLeaseIsNewHomes
}) {
  const getTabTitleName = (tab) => {
    if (mortgageEnabled() && partnerSectorLeaseIsNewHomes && tab === CASH_FINANCE_TYPE) {
      return MORTGAGE_FINANCE_TYPE_LABEL;
    } else if (tab === SPWR_CASH_FINANCE_TYPE) {
      return SPWR_FINANCE_TYPE_LABEL;
    } else if (tab === PPA_FINANCE_TYPE) {
      return tab.toUpperCase();
    }
    return tab;
  };

  const showTab = (tab) => {
    if (tab === SPWR_CASH_FINANCE_TYPE) {
      return showSpwrCash && availableTabs[tab];
    } else if (tab === PPA_FINANCE_TYPE) {
      return ppaEnabled() && availableTabs[tab];
    }
    return availableTabs[tab];
  };

  return (
    <div className="tabs__header-container">
      <ul className="tabs__header">
        {
          availableTabs && Object.keys(availableTabs).map((tab) =>
            <Tab
              activeTab={activeTab}
              customClass="finance-type"
              toggle={toggle}
              tabName={tab}
              title={getTabTitleName(tab)}
              showTab={showTab(tab)}
            />
          )
        }
      </ul>
    </div>
  );
}

FinanceTabs.propTypes = {
  activeTab: PropTypes.string.isRequired,
  toggle: PropTypes.func.isRequired,
  showSpwrCash: PropTypes.bool,
  availableTabs: PropTypes.shape({
    cash: PropTypes.bool,
    lease: PropTypes.bool,
    loan: PropTypes.bool,
    spwrCash: PropTypes.bool
  }),
  partnerSectorLeaseIsNewHomes: PropTypes.bool.isRequired
};

export default register(
  ['partnerSectorLeaseIsNewHomesSelector'],
  [],
  FinanceTabs
);
