export function togglePanel({ arrayNumber, panelNumber }) {
  return {
    type: 'togglePanel',
    payload: {
      design: {
        moduleArrays: {
          [arrayNumber - 1]: {
            panels: {
              [panelNumber]: {
                enabled: (value) => !value
              }
            }
          }
        },
        hasChanges: true
      },
      quote: {
        finEng: null
      },
      moduleChanged: true
    }
  };
}
