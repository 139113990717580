import { asyncPayload } from './apiHelpers';
import Api from '../utils/Api';

export function fetchPublicDynamicQuote(quoteSfid, query = {}) {
  return {
    type: 'fetchPublicDynamicQuote',
    payload: asyncPayload({
      beforeRequest: { loading: true },
      request: Api.fetchWithParams(`public/quotes/${quoteSfid}`, query)
    })
  };
}
