import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-bootstrap-modal';
import { register } from '../../../utils/redux';
import { accountType, soDynamicProposalType, storageQuoteType } from '../../../types';

export function ComplianceCheckModal({ storageQuote, showSoComplianceCheckModal,
  setSoAcknowledgeIdentityOfCustomer, acknowledgeNotifiedToCustomer,
  acknowledgeIdentityOfCustomer, setSoAcknowledgeNotifiedToCustomer, toggleSoComplianceCheckModal,
  soProposalStatus, sendSoComplianceCheck, account, soDynamicProposal }) {
  const sendComplianceCheckValue = () => {
    sendSoComplianceCheck(storageQuote, account, soDynamicProposal);
  };

  const closeModal = () => {
    toggleSoComplianceCheckModal(false);
  };

  const complianceModal = () => {
    return (
      <Modal
        className="compliance-check-modal"
        show={showSoComplianceCheckModal}
        onHide={closeModal}
        aria-labelledby="ModalHeader"
        data-keyboard="false"
        data-backdrop="static"
      >
        <Modal.Header className="compliance-check-modal-header" closeButton={true}>
          Compliance Checks
        </Modal.Header>
        <Modal.Body className="compliance-check-modal-body">
          <div>
            By checking this box, {'"Yes"'}, I acknowledge that I have verified the identity of customer through
            a U.S.federal or state government issued ID
          </div>
          <label className="compliance-acknowledgement-checkbox-container">
            Verified Customer ID
            <input
              type="checkbox"
              id="acknowledgement-identity"
              onChange={(e) => setSoAcknowledgeIdentityOfCustomer(e.target.checked)}
            />
            <span className="checkmark" />
          </label>
          <div>
            By checking this box, {'"Yes"'}, I acknowledge that I have verbally notified this customer of his/her
            right to cancel the contract within 7 calendar days of signing the contract.
          </div>
          <label className="compliance-acknowledgement-checkbox-container margin-bottom-0">
            Verbally Notified Customer of Right to Cancel
            <input
              type="checkbox"
              id="acknowledgement-notified"
              onChange={(e) => setSoAcknowledgeNotifiedToCustomer(e.target.checked)}
            />
            <span className="checkmark" />
          </label>
        </Modal.Body>
        <Modal.Footer>
          <button className="btn btn-secondary mysunpower-cancel-btn" onClick={closeModal}>Cancel</button>
          <button
            id="mysunpower-send-btn"
            className="btn btn-primary mysunpower-send-btn"
            disabled={!(acknowledgeIdentityOfCustomer && acknowledgeNotifiedToCustomer)}
            onClick={sendComplianceCheckValue}
          >
            Send to mySunPower
          </button>
        </Modal.Footer>
      </Modal>
    );
  };

  const sendingProposalModal = () => {
    return (
      <Modal
        className="proposal-status-modal"
        show={showSoComplianceCheckModal}
        onHide={closeModal}
        aria-labelledby="ModalHeader"
        data-keyboard="false"
        data-backdrop="static"
      >
        <Modal.Body className="proposal-status-modal-body">
          <img src={'/build/images/animated-loader.svg'} alt="loading" className="loader" />
          <div className="status-text">Sending Proposal...</div>
        </Modal.Body>
      </Modal>
    );
  };

  const sendingComplianceCheckModal = () => {
    return (
      <Modal
        className="proposal-status-modal"
        show={showSoComplianceCheckModal}
        onHide={closeModal}
        aria-labelledby="ModalHeader"
        data-keyboard="false"
        data-backdrop="static"
      >
        <Modal.Body className="proposal-status-modal-body">
          <img src={'/build/images/animated-loader.svg'} alt="loading" className="loader" />
        </Modal.Body>
      </Modal>
    );
  };

  const sentProposalModal = () => {
    return (
      <Modal
        className="proposal-status-modal"
        show={showSoComplianceCheckModal}
        onHide={closeModal}
        aria-labelledby="ModalHeader"
        data-keyboard="false"
        data-backdrop="static"
      >
        <Modal.Body className="proposal-status-modal-body">
          <div className="image-background">
            <img src={'/build/images/white-check.svg'} alt="sent" />
          </div>
          <div className="status-text">Proposal Sent.</div>
          <a className="close-btn" onClick={closeModal}>CLOSE</a>
        </Modal.Body>
      </Modal>
    );
  };

  return (
    <React.Fragment>
      {!soProposalStatus && !soDynamicProposal.showModalLoader && complianceModal()}
      {!soProposalStatus && soDynamicProposal.showModalLoader && sendingComplianceCheckModal()}
      {soProposalStatus && soProposalStatus === 'sending' && sendingProposalModal()}
      {soProposalStatus && soProposalStatus === 'sent' && sentProposalModal()}
    </React.Fragment>
  );
}

ComplianceCheckModal.propTypes = {
  storageQuote: storageQuoteType.isRequired,
  showSoComplianceCheckModal: PropTypes.bool.isRequired,
  setSoAcknowledgeIdentityOfCustomer: PropTypes.func.isRequired,
  setSoAcknowledgeNotifiedToCustomer: PropTypes.func.isRequired,
  toggleSoComplianceCheckModal: PropTypes.func.isRequired,
  acknowledgeIdentityOfCustomer: PropTypes.bool.isRequired,
  acknowledgeNotifiedToCustomer: PropTypes.bool.isRequired,
  soProposalStatus: PropTypes.string,
  sendSoComplianceCheck: PropTypes.func.isRequired,
  account: accountType.isRequired,
  soDynamicProposal: soDynamicProposalType.isRequired
};

export default register(
  [],
  [],
  ComplianceCheckModal
);
