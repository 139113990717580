import { asyncPayload } from './apiHelpers';
import Api from '../utils/Api';

export function fetchOrCreateMaxfitDesign(maxfitRoofDesignSfid) {
  return {
    type: 'fetchOrCreateMaxfitDesign',
    payload: asyncPayload({
      beforeRequest: { loading: true },
      request: Api.fetchOrCreate('design', maxfitRoofDesignSfid, {
        design: { maxfitRoofDesignSfid }
      })
    })
  };
}
