import { datadogRum } from '@datadog/browser-rum';
import { get } from 'lodash';
import { asyncPayload } from './apiHelpers';
import Api from '../utils/Api';
import { datadogTrackLoadingTime } from '../utils/datadogUserMonitoring';

function logDatadogTiming(startTime, response, firstLoad = false) {
  const loadingTime = Date.now() - startTime;
  const userId = response.userData.sfid;
  const partnerId = response.data.apFinConfigs[0].partnerAccountId;
  if (userId) {
    datadogRum.addRumGlobalContext('adminPortal', {
      userSfid: userId,
      partnerId
    });
    datadogTrackLoadingTime(
      'AdminPortal Update',
      loadingTime,
      userId,
      partnerId,
      null,
      null
    );
  }
}

export const updateAdminPortal = () => (dispatch, getState) => {
  const startTime = Date.now();
  const currentState = getState();
  const apFinConfigs = currentState.apFinConfigs;
  const graphSelections = currentState.graphSelections;
  const accountDetails = get(currentState, 'apSalesforce.accountDetails');
  const userName = accountDetails ? (`${accountDetails.firstName} ${accountDetails.lastName}`) : '';
  const data = {
    ap_fin_configs: apFinConfigs,
    user_name: userName,
    graphSelections
  };
  const promise = Api.updateForAdmin('admin_portals', data)
  .then((resp) => {
    logDatadogTiming(startTime, resp);
    return resp.data;
  });

  dispatch({
    type: 'updateAdminPortal',
    payload: asyncPayload({
      beforeRequest: { loading: true },
      request: promise
    })
  });
};
