export function updateQuoteName(value) {
  return {
    type: 'updateQuoteName',
    payload: {
      quote: {
        name: value
      }
    }
  };
}
