export default {
  DEFAULT_BASE_PRICE_PER_WATT: 2.0,
  LEASE_CELL_MONITORING_COST: 540,
  PPA_CELL_MONITORING_COST: 540,
  DEFAULT_CELL_MONITORING_COST: 289,
  EPW_FEE: 0.15,
  EPW_MIN_WATTS: 350,
  EPW_MAX_WATTS: 415,
  DEFAULT_INCENTIVES_HAIRCUT_PERCENTAGE: 20,
  RACKING_DEALER_FEE_PER_WATT: 0.05,
  NJ_TREC_INCENTIVE_ID: 3436313,
  NY_DEFAULT_INFLATION_RATE: '3',
  ALL_STATE_DEFAULT_INFLATION_RATE: '4',
  NON_SUNPOWER_GROUND_MOUNT_RACKING: 'non-sunpower ground mount',
  LARGE_SYSTEM_DISCOUNT_SYSTEM_SIZE: 10000,
  LOAN_FINANCE_TYPE: 'loan',
  CASH_FINANCE_TYPE: 'cash',
  LEASE_FINANCE_TYPE: 'lease',
  PPA_FINANCE_TYPE: 'ppa',
  SPWR_CASH_FINANCE_TYPE: 'spwrCash',
  MORTGAGE_FINANCE_TYPE: 'mortgage',
  LOAN_DEFAULT_PRE_PAYMENT_PERCENTAGE: 30,
  LOAN_PRE_PAYMENT_MONTH: 18,
  TOTAL_SAVINGS_GRAPH: 'totalSavingsGraph',
  SAVINGS_OVER_TIME_GRAPH: 'savingsOverTimeGraph',
  SOURCE: 'eddie',
  CRITTER_GUARD: 'critter_guard',
  SKIRT_GUARD: 'skirt_guard',
  PRICE_PER_WATT: 'price_per_watt',
  DEALER_FEE_PER_WATT: 'dealer_fee_per_watt',
  MORTGAGE_FINANCE_TYPE_LABEL: 'Mortgage Finance',
  SPWR_FINANCE_TYPE_LABEL: 'SPWR Cash',
  LOAN_FINANCE_TYPE_LABEL: 'Loan',
  CASH_FINANCE_TYPE_LABEL: 'Cash',
  LEASE_FINANCE_TYPE_LABEL: 'Lease',
  PPA_FINANCE_TYPE_LABEL: 'PPA',
  EV_CHARGER: 'ev_charger',
  EV_OUTLET: 'ev_outlet',
  EV_CHARGER_TYPE: 'ev charger',
  EV_OUTLET_TYPE: 'ev outlet',
  MAX_EV_CHARGER_DEALER_FEE: 10000,
  PROPOSAL_SENT: 'sent',
  CREDIT_PENDING: 'pending',
  CREDIT_NOT_PRESENT: 'not present',
  CREDIT_APP_SENT: 'sent',
  CREDIT_APP_OPENED: 'opened',
  ACKNOWLEDGEMENT_ACTION: 'acknowledge',
  COMPLETED: 'completed',
  HIDDEN: 'hidden',
  FINANCIAL_OFFERINGS: [ 'loan', 'ppa', 'lease', 'cash' ],
  CA: 'CA',
  ENPHASE: 'enphase',
  TESLA: 'tesla',
  SUNVAULT: 'sunvault',
  SUNPOWER: 'sunpower',
  PRICING_CONTROLS: 'pricingControls',
  MAX_STORAGE_COMMISSION_ID: 20000,
  INTEREST_RATE_CA: { TX: 9.59, IL: 8.59 },
  MIN_SYS_SIZE_STORAGE: 'minimumSystemSizeForStorage'
};
