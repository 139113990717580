import Api from '../utils/Api';
import { asyncPayload } from './apiHelpers';

export function uploadProposalFile(sfid, formData) {
  const requestPath = `/quotes/${sfid}/upload_pdf_s3`;
  const promise = Api.postFile(requestPath, formData)
    .then(async (response) => {
      const jsonBody = await response.json();
      return {
        account: { isPDFProcessing: false },
        quote: { dpS3Url: jsonBody.url }
      };
    }).catch((_error) => {
      return {
        account: { isPDFProcessing: false },
        quote: { dpS3Data: 'error' }
      };
    });

  return {
    type: 'uploadProposalFile',
    payload: asyncPayload({
      request: promise
    })
  };
}
