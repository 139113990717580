import React from 'react';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { register } from '../../../utils/redux';
import {
  quoteType,
  dropdownsType,
  pvModuleType,
  selectedEvChargerConfigurationType,
  settingsType
} from '../../../types';
import { tagList } from '../../../utils/pvModuleFiltersAndTags';
import { getEvChargerList, getEvChargerAllowedQuantityList } from '../../../utils/EvChargers/evChargers';
import { truncateString } from '../../../utils/common';
import { paymentOptionForEvCharger } from '../../../utils/calculations';

const defaultContainerClasses = 'input-container input-container--icon icon-right dropdown-with-tags';

export function EvChargerListDropDown({
  pvModule,
  selectedEvChargerConfiguration,
  dropdowns: { isEvChargerListOpen },
  onSelect,
  settings,
  toggleEvChargerListDropdown,
  quote
}) {
  const selectedEvConfigName = get(selectedEvChargerConfiguration, 'evChargerConfigName', null);
  const selectedEvConfigId = get(selectedEvChargerConfiguration, 'evChargerConfigId', null);
  const selectedEvChargerId = get(selectedEvChargerConfiguration, 'evChargerId', null);
  const selectedEvChargerQuantity = get(selectedEvChargerConfiguration, 'evChargerQuantity',
    getEvChargerAllowedQuantityList()[0]);
  const options = (<ul>
    <li
      className={cx('cs-option-container',
        { 'cs-selected': !selectedEvConfigId })}
      onClick={() => {
        toggleEvChargerListDropdown();
        onSelect(selectedEvChargerId, null);
      }}
      key={`list${0}`}
    >
      <span className="cs-option">
        <div className="cs-option-name">None</div>
      </span>
    </li>
    {getEvChargerList(pvModule.evmChargerConfigurations).map((option) => {
      return (
        <li
          className={cx('cs-option-container',
            { 'cs-selected': option.configId === selectedEvConfigId })}
          onClick={() => {
            toggleEvChargerListDropdown();
            onSelect(selectedEvChargerId, option.configId, option.configName, selectedEvChargerQuantity);
          }}
          key={`list${option.configId}`}
        >
          <span className="cs-option">
            <div className="cs-option-name" data-tip={option.configName}>{truncateString(option.configName, 15)}</div>
            {tagList(paymentOptionForEvCharger(pvModule.evmChargerConfigurations, option.configId), settings)}
          </span>
        </li>
      );
    })}
  </ul>);

  return getEvChargerList(pvModule.evmChargerConfigurations).length === 0 ? (
    <div
      id="ev_charger_dropdown"
      className={defaultContainerClasses}
    >
      <input type="text" value="None" disabled={true} />
    </div>
  ) : (
    <div
      id="ev_charger_dropdown"
      className={cx(defaultContainerClasses, { 'is-disabled': quote.locked })}
    >
      <i className="icon icon-chevron-down" />
      <div
        className={cx('select-dropdown cs-skin-border', { 'cs-active': isEvChargerListOpen })}
        tabIndex="0"
      >
        <span className="cs-placeholder" onClick={toggleEvChargerListDropdown}>
          <div className="cs-option-name" data-tip={selectedEvConfigName}>
            {truncateString(selectedEvConfigName, 15) || 'None'}
          </div>
          {tagList(paymentOptionForEvCharger(pvModule.evmChargerConfigurations, selectedEvConfigId), settings)}
        </span>
        <div className="cs-options">
          {options}
        </div>
      </div>
    </div>
  );
}

EvChargerListDropDown.propTypes = {
  pvModule: pvModuleType.isRequired,
  selectedEvChargerConfiguration: selectedEvChargerConfigurationType,
  dropdowns: dropdownsType.isRequired,
  quote: quoteType.isRequired,
  onSelect: PropTypes.func.isRequired,
  toggleEvChargerListDropdown: PropTypes.func.isRequired,
  settings: settingsType.isRequired
};

export default register(
  [
    'pvModuleSelector',
    'dropdownsSelector',
    'selectedEvChargerConfigurationSelector',
    'quoteSelector',
    'accountSelector',
    'settingsSelector'
  ],
  ['toggleEvChargerListDropdown'],
  EvChargerListDropDown
);

