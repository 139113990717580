import PropTypes from 'prop-types';

export const adModuleSpec = PropTypes.shape({
  name: PropTypes.string,
  size: PropTypes.arrayOf(PropTypes.number).isRequired,
  series: PropTypes.string,
  spacing: PropTypes.arrayOf(PropTypes.number).isRequired,
  wattage: PropTypes.number,
  id: PropTypes.string
});

export const adShapeType = PropTypes.shape({
  points: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.number)).isRequired,
  type: PropTypes.string
});

export const adRoofObjectType = PropTypes.shape({
  id: PropTypes.string,
  type: PropTypes.string,
  shape: adShapeType.isRequired,
  tilt: PropTypes.number,
  azimuth: PropTypes.number,
  production: PropTypes.objectOf(PropTypes.number),
  roof_id: PropTypes.string,
  editedAt: PropTypes.number
});

export const adLayersType = PropTypes.shape({
  roofs: PropTypes.arrayOf(adRoofObjectType).isRequired,
  modules: PropTypes.arrayOf(adRoofObjectType).isRequired,
  trees: PropTypes.arrayOf(adRoofObjectType).isRequired,
  obstructions: PropTypes.arrayOf(adRoofObjectType).isRequired,
  setbacks: PropTypes.arrayOf(adRoofObjectType).isRequired
});

export const adDesignType = PropTypes.shape({
  layers: adLayersType.isRequired,
  modules_spec: adModuleSpec.isRequired,
  source_timestamp: PropTypes.string
});

export const adProductionType = PropTypes.shape({
  production: PropTypes.number.isRequired,
  systemSize: PropTypes.number.isRequired,
  energyOffset: PropTypes.number.isRequired
});
