import PropTypes from 'prop-types';

export const accountType = PropTypes.shape({
  name: PropTypes.string.isRequired,
  street: PropTypes.string.isRequired,
  city: PropTypes.string.isRequired,
  state: PropTypes.string.isRequired,
  postalCode: PropTypes.string.isRequired,
  annualUsage: PropTypes.number,
  isPDFProcessing: PropTypes.bool.isRequired
});

