import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import Slider from 'rc-slider';
import cx from 'classnames';

import { register } from '../../utils/redux';
import {
  loanSystemCostType,
  quoteType,
  creditType,
  settingsType,
  loanCalcValuesType,
  errorType,
  accountType
} from '../../types';
import { formatCurrency, formatNumber } from '../../utils/formatNumber';
import SecondaryValueBox from '../SecondaryValueBox';
import { gaTrackEvent } from '../../utils/googleAnalytics';
import LineInput from '../LineInput';
import constants from '../../utils/EddieCalculations/constants';
import {
  initialCalculatorInputs,
  findCreditProductName,
  calculateLoanPrePayment
} from '../../utils/loanCalculatorHelpers';
import AnimatedValueView from '../AnimatedValueView';
import {
  filterCreditProducts,
  filterCreditProductsByTerm,
  filterAprPercentagesByTerm,
  isValidProduct
} from '../../utils/creditProductsHelper';

const {
  LOAN_DEFAULT_PRE_PAYMENT_PERCENTAGE,
  LOAN_PRE_PAYMENT_MONTH
} = constants;

const usePrevious = (value) => {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
};

export function LoanCalculatorView(
  {
    account,
    loanSystemCost,
    settings,
    quote,
    credit,
    error,
    loanCalcDirty,
    savingLoanPricing,
    fetchFinEngLoanCalc,
    loanCalcValues,
    updateLoan,
    updateCoPayment,
    newCoPaymentValue
  }) {
  const {
    monthlyRent,
    adjustedMonthlyRent,
    totalPayment,
    payoffDate,
    newLoanAmountFromFinEng
  } = loanCalcValues || {};

  const {
    initialDownPayment,
    initialPrePaymentPercentage,
    initialPrePayment,
    initialLoanAmount,
    initialContractPrice
  } = initialCalculatorInputs(settings, credit, loanSystemCost);

  const [ contractPrice, setContractPrice ] = useState(initialContractPrice);
  const [ loanAmount, setLoanAmount ] = useState(initialLoanAmount);
  const [ apr, setApr ] = useState(settings.loan.aprType);
  const [ term, setTerm ] = useState(settings.loan.termInMonths);
  const [ availableAprPercentages, setAvailableAprPercentages ] =
    useState(filterAprPercentagesByTerm(credit.products, term));
  const [ aprPercentage, setAprPercentage ] = useState(settings.loan.aprPercentage);
  const prevApr = usePrevious(apr);
  const [ downPayment, setDownPayment ] = useState(initialDownPayment);
  const [ showEditLoanFields, setShowEditLoanFields ] = useState(false);
  const [ prePaymentPercentage, setPrePaymentPercentage ] = useState(initialPrePaymentPercentage);
  const [ prePayment, setPrePayment ] = useState(initialPrePayment);
  const [ savePricingClicked, setSavePricingClicked ] = useState(false);
  const [ isDirty, setIsDirty ] = useState(false);

  const isSavingLoanPricingInProgress = savingLoanPricing;

  const creditSymbol = <div className="estimated-credit-symbol" />;

  const callFinEng = (setLoanAmountToZero = false) => {
    if (!quote.locked) {
      fetchFinEngLoanCalc({
        downPayment,
        contractPrice: setLoanAmountToZero ? 0 : contractPrice,
        prePayment,
        prePaymentPercentage,
        apr,
        aprPercentage,
        term,
        creditProductName: findCreditProductName(credit, term, apr, aprPercentage)
      }, quote, account, loanSystemCost);
    }
  };

  const marks = {
    [LOAN_DEFAULT_PRE_PAYMENT_PERCENTAGE]: creditSymbol
  };

  const onSliderChange = (value) => {
    setPrePaymentPercentage(value);
    const prePayment = calculateLoanPrePayment(loanAmount, value);
    setPrePayment(prePayment);
  };

  useEffect(() => {
    if (newLoanAmountFromFinEng) {
      setContractPrice(newLoanAmountFromFinEng);
      setLoanAmount(newLoanAmountFromFinEng);

      const prePayment = calculateLoanPrePayment(newLoanAmountFromFinEng, prePaymentPercentage);
      setPrePayment(prePayment);
    }
  }, [newLoanAmountFromFinEng]);

  useEffect(() => {
    if (isValidProduct(credit.products, apr, aprPercentage, term)) {
      if (prevApr && apr && apr !== prevApr) {
        const setLoanAmountToZero = true;
        callFinEng(setLoanAmountToZero);
      } else {
        callFinEng();
      }
    }
  }, [ apr, term, aprPercentage ]);

  useEffect(() => {
    // when fineng sends new co-payment value which is different from what is entered
    // by user

    if (updateCoPayment && newCoPaymentValue && formatNumber(newCoPaymentValue) !== formatNumber(downPayment)) {
      setDownPayment(newCoPaymentValue);
      setLoanAmount(contractPrice - newCoPaymentValue);
    }
  }, [updateCoPayment]);

  useEffect(() => {
    setIsDirty(loanCalcDirty);
  }, [loanCalcDirty]);

  const onAfterChange = () => {
    callFinEng();
    return true;
  };

  const toggleEditLoanFields = () => {
    setShowEditLoanFields(true);
  };

  const onDownPaymentChange = (value) => {
    setIsDirty(true);
    setDownPayment(value);
  };

  const onDownPaymentBlur = () => {
    setIsDirty(true);
    callFinEng();
  };

  const onAprPercentageChange = (event) => {
    const selectedAprPercentage = parseFloat(event.target.value);
    const selectedProduct = filterCreditProducts(credit.products, selectedAprPercentage, term);
    setIsDirty(true);
    setApr(selectedProduct[0].aprTypeC);
    setAprPercentage(selectedAprPercentage);
  };

  const onTermChange = (event) => {
    const selectedTerm = parseInt(event.target.value, 10);
    setIsDirty(true);
    const products = filterCreditProductsByTerm(credit.products, selectedTerm);
    setAvailableAprPercentages(filterAprPercentagesByTerm(products, selectedTerm));
    if (products) {
      setApr(products[0].aprTypeC);
      setAprPercentage(products[0].choiceRateC);
    }
    setTerm(selectedTerm);
  };

  const savePricing = () => {
    updateLoan(quote, {
      downPayment,
      apr,
      aprPercentage,
      term,
      loanCalcValues
    }, 'loanCalculator');
    setSavePricingClicked(true);
  };

  const sliderClassName = (prePaymentPercentage >= LOAN_DEFAULT_PRE_PAYMENT_PERCENTAGE - 3) &&
  (prePaymentPercentage <= LOAN_DEFAULT_PRE_PAYMENT_PERCENTAGE + 3) ? 'magnetize' : '';

  return (
    <div className="loan loan-container loan-calculator-container">
      <div className="calculator-heading-container">
        <img className="calculator-image" src="/build/images/calculator.svg" alt="Loan Calculator" />
        <div>
          <div className="pricing-label">
            {formatCurrency(loanAmount, 0)}
          </div>
          <div className="pricing-heading-label">Loan Amount</div>
        </div>

        <div className="save-btn-container">
          { isSavingLoanPricingInProgress &&
            <div className="status-text">SAVING</div>
          }
          { !isDirty && savePricingClicked && !isSavingLoanPricingInProgress && !error &&
            <div className="status-text success">&#x2714;SAVED</div>
          }
          {!isSavingLoanPricingInProgress &&
          <button
            id="save-pricing-btn"
            className={cx('btn', 'save-btn', isSavingLoanPricingInProgress ? 'loader-btn' : '')}
            disabled={quote.locked || !isDirty}
            onClick={savePricing}
          >
            Save Pricing
          </button>
          }
          {isSavingLoanPricingInProgress &&
          <button
            className={cx('btn', 'save-btn', isSavingLoanPricingInProgress ? 'loader-btn' : '')}
            disabled={quote.locked || !isDirty}
            onClick={savePricing}
          >
            <img src="/build/images/button-loader.svg" alt="Loader" className="btn-loader" />
          </button>
          }
        </div>
      </div>
      <div className="calculated-loan-details-container">
        <div className="heading">MONTHLY PAYMENTS</div>
        <div className="calculated-loan-summary">
          <SecondaryValueBox
            id="monthly-rent"
            label={`Before Month ${LOAN_PRE_PAYMENT_MONTH}`}
            currency={true}
            rounded={true}
            value={monthlyRent}
            animate={true}
          />
          <div className="value-divider" />
          <SecondaryValueBox
            id="adjusted-monthly-rent"
            label={`After Month ${LOAN_PRE_PAYMENT_MONTH}`}
            currency={true}
            rounded={true}
            value={adjustedMonthlyRent}
            valueColor="green"
            valueWeight="bold"
            animate={true}
          />
        </div>
        <div className="calculated-loan-prepayment-container">
          <div className="calculated-loan-prepayment-row">
            <div className="prepayment-label">Prepayment (at Month {LOAN_PRE_PAYMENT_MONTH})</div>
            <div className="prepayment-value">
              {formatCurrency(calculateLoanPrePayment(loanAmount, prePaymentPercentage), 0)}
            </div>
          </div>
          <div className="calculated-loan-slider-container">
            <Slider
              disabled={quote.locked}
              value={prePaymentPercentage}
              onChange={onSliderChange}
              onAfterChange={onAfterChange}
              marks={marks}
              className={sliderClassName}
            />
            <div className="percentage-value">{prePaymentPercentage}%</div>
          </div>
          <div className="estimated-credit-row">
            {creditSymbol}
            <div className="estimated-credit-label">
              Est. Tax Credit Amount
            </div>
          </div>
          <div className="estimated-prepayment-subtext">
            The loan is structured around a prepayment equal
            to {LOAN_DEFAULT_PRE_PAYMENT_PERCENTAGE}% of the loan amount.
          </div>
          {!quote.locked && !showEditLoanFields && (
            <div
              id="edit-loan-link"
              className="edit-loan"
              onClick={() => {
                toggleEditLoanFields();
                gaTrackEvent('quotePage:savingsTab:loanSubTab:editLink');
              }}
            >
              Edit Loan
            </div>
          )}
          {(quote.locked || showEditLoanFields) && (
            <div className="edit-loan-container">
              <LineInput
                label="Co-Payment"
                id="co-payment"
                value={downPayment}
                onFocus={() => gaTrackEvent('quotePage:savingsTab:loanCoPaymentOption')}
                readOnly={quote.locked}
                onChange={onDownPaymentChange}
                onBlur={onDownPaymentBlur}
              />
              <div className="form-group term">
                <label>Loan Term</label>
                <div className="input-container">
                  <select
                    id="term-select"
                    name="term"
                    value={term}
                    disabled={quote.locked}
                    onFocus={() => gaTrackEvent('quotePage:savingsTab:loanTermSelection')}
                    onChange={onTermChange}
                  >

                    {credit.terms.map((term, key) => <option key={key} value={term}>{parseInt(term, 10) / 12}</option>)}
                  </select>
                </div>
              </div>
              <div className="form-group apr-percentage">
                <label>APR</label>
                <div className="input-container">
                  {
                    quote.locked && quote.version < 5 && !aprPercentage ?
                      (<select
                        id="apr-type-select"
                        name="aprType"
                        value={apr}
                        disabled={quote.locked}
                      >
                        { credit.aprs.map((apr, key) => <option key={key} value={apr}>{apr}</option>) }
                      </select>)
                      :
                      (<select
                        id="apr-percentage-select"
                        name="aprPercentage"
                        value={aprPercentage}
                        disabled={quote.locked}
                        onChange={onAprPercentageChange}
                      >
                        {availableAprPercentages.map(
                          (aprPercentage, key) =>
                            <option key={key} value={aprPercentage}>
                              {parseFloat(aprPercentage).toFixed(2)}%
                            </option>)}
                      </select>)
                  }
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="calculated-loan-final-details-container">
        <div className="heading">LOAN DETAILS</div>
        <div className="calculated-loan-payoff-details">
          <div className="value">{payoffDate}</div>
          <div className="label">Payoff Date</div>
        </div>
        <div className="calculated-loan-payoff-details">
          <AnimatedValueView
            id="total-payment-div"
            value={totalPayment}
            className="value"
            currency={true}
            rounded={true}
          />
          <div className="label">Total Payment</div>
        </div>
      </div>
    </div>
  );
}

LoanCalculatorView.propTypes = {
  loanSystemCost: loanSystemCostType.isRequired,
  quote: quoteType.isRequired,
  credit: creditType.isRequired,
  settings: settingsType.isRequired,
  loanCalcValues: loanCalcValuesType,
  error: errorType,
  account: accountType,
  loanCalcDirty: PropTypes.bool,
  updateCoPayment: PropTypes.bool,
  newCoPaymentValue: PropTypes.number,
  savingLoanPricing: PropTypes.bool,
  fetchFinEngLoanCalc: PropTypes.func.isRequired,
  updateLoan: PropTypes.func.isRequired
};

export default register(
  [
    'loanSystemCostSelector', 'quoteSelector', 'creditSelector', 'settingsSelector',
    'loanCalcValuesSelector', 'savingLoanPricingSelector', 'errorSelector', 'loanCalcDirtySelector',
    'updateCoPaymentSelector', 'newCoPaymentValueSelector', 'accountSelector'
  ],
  [ 'fetchFinEngLoanCalc', 'updateLoan' ],
  LoanCalculatorView
);
