import React from 'react';
import PropTypes from 'prop-types';

export default function Label(props) {
  const { children, ...rest } = props;
  return (
    <label {...rest}>{children}</label>
  );
}
Label.propTypes = {
  className: PropTypes.string,
  children: PropTypes.string.isRequired
};

