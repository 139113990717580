import PropTypes from 'prop-types';

const financeTypeObj = {
  systemPriceSelection: PropTypes.string.isRequired,
  pricePerWatt: PropTypes.oneOfType([ PropTypes.number, PropTypes.string ]).isRequired,
  grossPrice: PropTypes.oneOfType([ PropTypes.number, PropTypes.string ]).isRequired,
  adders: PropTypes.oneOfType([ PropTypes.number, PropTypes.string ]).isRequired,
  discount: PropTypes.oneOfType([ PropTypes.number, PropTypes.string ]).isRequired,
  systemPrice: PropTypes.number.isRequired,
  systemCost: PropTypes.number.isRequired,
  sumTotal: PropTypes.number.isRequired,
  showCustomer: PropTypes.bool.isRequired,
  rebateSelection: PropTypes.string.isRequired,
  overrideRebate: PropTypes.oneOfType([ PropTypes.number, PropTypes.string ]).isRequired
};

const storageTypeObj = {
  storagePriceSelection: PropTypes.string.isRequired,
  storagePrice: PropTypes.oneOfType([ PropTypes.number, PropTypes.string ]).isRequired,
  storageDealerFee: PropTypes.oneOfType([ PropTypes.number, PropTypes.string ]).isRequired,
  storageTotal: PropTypes.number.isRequired
};

export const financeType = PropTypes.shape(financeTypeObj);

export const settingsType = PropTypes.shape({
  cash: PropTypes.shape(Object.assign({}, financeTypeObj, storageTypeObj)).isRequired,
  lease: PropTypes.shape(Object.assign({}, financeTypeObj, {
    pricePerKwh: PropTypes.oneOfType([ PropTypes.number, PropTypes.string ]).isRequired
  })
  ).isRequired,
  loan: PropTypes.shape(
    Object.assign({}, financeTypeObj, storageTypeObj, {
      coPayment: PropTypes.oneOfType([ PropTypes.number, PropTypes.string ]).isRequired,
      termInMonths: PropTypes.number
    })
  ).isRequired,
  general: PropTypes.shape({
    nonSunpowerRacking: PropTypes.bool.isRequired
  }).isRequired,
  activeTab: PropTypes.string.isRequired
});

