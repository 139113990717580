import React from 'react';
import PropTypes from 'prop-types';
import { GOOGLE_MAPS_API_KEY } from '../../config';

const GOOGLE_URL = 'https://www.google.com' +
  `/maps/embed/v1/view?center=$lat,$lon&zoom=21&key=${GOOGLE_MAPS_API_KEY}&maptype=satellite`;

export default function DesignMap({ latitude, longitude, height }) {
  const url = GOOGLE_URL
    .replace('$lat', latitude)
    .replace('$lon', longitude);

  return (
    <iframe
      className="design-map"
      frameBorder="0"
      style={{ border: 0, height, width: '100%' }}
      src={url}
    />
  );
}

DesignMap.propTypes = {
  latitude: PropTypes.number.isRequired,
  longitude: PropTypes.number.isRequired,
  height: PropTypes.string.isRequired
};
