import u from 'updeep';
import throttle from 'lodash/throttle';
import { applyMiddleware, createStore, compose } from 'redux';
import get from 'lodash/get';
import promiseMiddleware from 'redux-promise-middleware';
import thunk from 'redux-thunk';
import { setError } from './actions';
import environment from './utils/environment';
import { initialState } from './config';
import logger from './utils/logger';

const saveLocalStorageThrottle = throttle((store) => {
  localStorage.setItem('redux', JSON.stringify(store.getState()));
}, 100);

const saveLocalStorageMiddleware = (store) => (next) => (action) => {
  saveLocalStorageThrottle(store);
  return next(action);
};

const crashReporter = (_store) => (next) => (action) => {
  try {
    return next(action);
  } catch (error) {
    logger.error(error);

    return next(setError({
      isFatal: false,
      errorClass: 'unexpected-frontend',
      message: get(error, 'message', error)
    }));
  }
};

let middleware = [
  crashReporter,
  promiseMiddleware(),
  thunk
];

if (environment.isDevelopment()) {
  // This is useful to graph redux state via Capybara
  // #evaluate_script during feature specs.
  middleware = [
    saveLocalStorageMiddleware,
    ...middleware
  ];
}

export const createEddieStore = () => createStore(
  (state = initialState, { payload = {} }) => u(payload, state),
  compose(
    applyMiddleware(...middleware),
    /* eslint-disable no-underscore-dangle */
    window.__REDUX_DEVTOOLS_EXTENSION__ ? window.__REDUX_DEVTOOLS_EXTENSION__() : (f) => f
  )
);

const store = createEddieStore();
export default store;
