import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { register } from '../../../utils/redux';
import { Modal } from '../../Modal';
// import { updateAdminPortal } from '../../../actions';

export function OutOfStockModal({ apFinConfig, financeType, updateApFinConfig, updateAdminPortal }) {
  const modals = {};
  const [ expiredProduct, setExpiredProduct ] = useState({});

  useEffect(() => {
    if (apFinConfig.expiredProducts) { setExpiredProduct(apFinConfig.expiredProducts); }
  }, [apFinConfig.expiredProducts]);

  return (
    <div>
      { apFinConfig.expiredProducts.loanProduct ||
        (apFinConfig.expiredProducts.module && apFinConfig.expiredProducts.module !== null) ?
          <Modal
            modals={modals}
            showButtons={false}
            name="Out-of-Stock-Modal"
          >
            <div className="text-container">
              The default selection is no longer available for one or more markets.
              <br />
              <ul>
                { expiredProduct.loanProduct ? <li><b>Loan Term : </b>
                  {expiredProduct.loanProduct.loanTerm} Years , <b>APR : </b>
                  {expiredProduct.loanProduct.choiceRate} %</li> : '' }
                { expiredProduct.module ? <li><b>Module : </b>{expiredProduct.module.moduleName}</li> : '' }
              </ul>
              Please contact your RSM for more information.
            </div>
            <div className="stock-action-container">
              <button
                className="btn btn-save margin-20"
                onClick={() => {
                  updateApFinConfig(financeType, 'expiredProducts', {
                    loanProduct: null,
                    module: null
                  });
                  updateAdminPortal();
                }
                }
              >
                OK
              </button>
            </div>
          </Modal> : ''
      }
    </div>
  );
}

OutOfStockModal.propTypes = {
  financeType: PropTypes.string.isRequired,
  updateApFinConfig: PropTypes.func.isRequired,
  apFinConfig: PropTypes.objectOf(PropTypes.object),
  updateAdminPortal: PropTypes.func
};

export default register(
  [],
  [],
  OutOfStockModal
);
