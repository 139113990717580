import React from 'react';
import PropTypes from 'prop-types';
import { moduleType } from '../types';
import svgPath from '../utils/svgPath';

export default function Module({ module, on = false, isHovered = false }) {
  const hoverColor = isHovered ? 'blue' : 'white';
  const color = on ? 'gray' : hoverColor;

  return (
    <path
      className="panel"
      d={svgPath(module)}
      stroke="gray"
      fill={color}
    />
  );
}

Module.propTypes = {
  module: moduleType,
  on: PropTypes.bool,
  isHovered: PropTypes.bool
};
