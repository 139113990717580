import u from 'updeep';
import get from 'lodash/get';
import { asyncPayload } from '../apiHelpers';
import Api from '../../utils/Api';
import { generateStorageQuoteProposalFile } from './generateStorageQuoteProposalFile';

export function lockStorageQuoteAndGenerateProposal(storageQuote, browserHistory, proposalUrl) {
  const promise = Api.lockQuoteWithPollingWithErrorHandling(`/storage/quotes/${storageQuote.sfid}/lock`,
    {},
    `storage/quotes/${storageQuote.sfid}`);

  return (dispatch) => {
    const response = dispatch({
      type: 'lockStorageQuote',
      payload: asyncPayload({
        beforeRequest: {
          loading: true
        },
        request: promise,
        afterRequest: {
          loading: false
        },
        errorRequest: {
          loading: false
        }
      })
    });

    response.then((data) => {
      if (!get(data, 'value.error', null)) {
        const result = dispatch(generateStorageQuoteProposalFile(storageQuote, 'pdf'));
        result.then((fileResult) => {
          browserHistory.push(proposalUrl);
          return u(data, fileResult);
        });
      }
    });
  };
}
