import React from 'react';
import PropTypes from 'prop-types';
import { formatCurrency } from '../../utils/formatNumber';

export default function LineItem({ label, value, sign, labelClass = 'label' }) {
  return (
    <div className="line-item supporting-text">
      <span className={labelClass}>{label}</span>
      <span className="value">
        {sign}{formatCurrency(value, 0)}
      </span>
    </div>
  );
}

LineItem.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.number.isRequired,
  labelClass: PropTypes.string,
  sign: PropTypes.string
};
