import PropTypes from 'prop-types';
import React from 'react';
import ReactTooltip from 'react-tooltip';
import CloseIcon from '../../../../images/close.svg';
import InfoIcon from '../../../../images/info.svg';

export function ResetBar({ onReset }) {
  return (
    <div className="action-bar">
      <a onClick={onReset} className="reset">
        Reset</a> back to default
      <InfoIcon
        data-tip={true}
        data-event="click focus"
        data-for="info-tooltip"
        className="info"
      />
      <ReactTooltip
        id="info-tooltip"
        className="tooltip-box"
        aria-haspopup="true"
        globalEventOff="click"
        effect="solid"
        event="click"
        type="light"
        place="left"
        clickable={true}
      >
        <p className="tooltip-text">
          This will reset the pricing structure back to the original
          default state and reset the current pricing file.
        </p>
        <CloseIcon
          onClick={() => {
            document.querySelector('.tooltip-box').classList.remove('show');
          }}
        />
      </ReactTooltip>
    </div>
  );
}

ResetBar.propTypes = {
  onReset: PropTypes.func
};
