import PropTypes from 'prop-types';
import React, { Fragment, createRef } from 'react';
import ErrorIcon from '../../../../images/error.svg';
import ResetIcon from '../../../../images/reset.svg';
import { Modal } from '../../Modal';
import { PricingDefaultsError } from './PricingDefaultsError';

export function UploadModal({
  deletePricingFile,
  showLoading,
  showReset,
  setModal,
  showSuccess,
  setSuccess,
  setPreview,
  setReset,
  upload,
  error,
  rows,
  setError
}) {
  const reuploadRef = createRef();

  const header = () => {
    if (showSuccess) return 'File uploaded';
    if (showLoading) return 'Uploading file...';
    if (showReset) return 'Reset the current file';
    if (error.title) return error.title;
    return '';
  };

  const subtitle = () => {
    if (showReset) return 'and set pricing back to the default?';
    if (error.message) return error.message;
    return '';
  };

  const getDuplicates = () => {
    return rows.filter((row) => row.isDuplicate).length;
  };

  const getMultiplePo = () => {
    return rows.filter((row) => row.hasMultiplePo).length;
  };

  const getOverlappingSizes = () => {
    return rows.filter((row) => row.hasOverlappingSize).length;
  };

  const Success = () => (
    <img
      src="/build/images/success.gif"
      alt="Succes upload"
      width="100px"
      height="100px"
    />
  );

  const Loading = () => (
    <img
      src="/build/images/loading.gif"
      alt="Uploading file"
      width="100px"
      height="100px"
    />
  );


  const Icon = () => {
    if (showLoading) return <Loading />;
    if (error.title) return <ErrorIcon width={100} height={100} />;
    if (showSuccess) return <Success />;
    return <ResetIcon width={100} height={100} />;
  };

  const Message = () => {
    const head = header();
    const body = subtitle();
    const duplicates = getDuplicates();
    const multiplePo = getMultiplePo();
    const overlappingSizes = getOverlappingSizes();
    const validationErrors = error.errors;
    return (
      <Fragment>
        {head && <div className="header">{head}</div>}
        {body && <div className="subtitle">{body}</div>}
        {!showReset && !validationErrors && !showLoading &&
        (duplicates > 0 || multiplePo > 0 || overlappingSizes > 0) && (
          <div className="upload-duplicates">
            <PricingDefaultsError
              duplicates={duplicates}
              multiplePo={multiplePo}
              overlappingSizes={overlappingSizes}
              id="modal-tooltip"
            />
            <span>
              . VIEW FILE TO SEE:
            </span>
          </div>
        )}
        {validationErrors && validationErrors.length ? (
          <div id="validation-errors">
            {validationErrors.map((error) => (
              <span>{error}</span>
            ))}
          </div>
        ) : null}
      </Fragment>
    );
  };

  const LoadingBtns = () => (
    <div className="loadings-btns btns">
      <a
        onClick={() => {
          setModal(false);
        }}
      >
        Cancel
      </a>
    </div>

  );

  const SuccessBtns = () => (
    <div className="success-btns btns">
      <button
        className="btn btn-secondary view"
        onClick={() => {
          setModal(false);
          setSuccess(false);
          setPreview(true);
        }}
      >
        View File
      </button>
      <a
        onClick={() => {
          setModal(false);
          setReset(false);
          setError({});
          setSuccess(false);
        }}
      >
        Close
      </a>
    </div>
  );

  const ResetBtns = () => (
    <div className="reset-btns btns">
      <button
        className="btn btn-primary"
        onClick={deletePricingFile}
      >
        Yes, Reset
      </button>
      <button
        className="btn btn-secondary"
        onClick={() => {
          setReset(false);
          setModal(false);
        }}
      >
        Cancel
      </button>
    </div>
  );

  const ErrorBtns = () => (
    <div className="error-btns btns">
      <button
        className="btn btn-secondary replace"
        onClick={() => {
          reuploadRef.current.click();
        }}
      >
        Upload File
      </button>
      <input
        type="file"
        accept="text/csv"
        ref={reuploadRef}
        onChange={(event) => upload(event)}
        onClick={(event) => {
          event.target.value = null;
        }}
        hidden={true}
      />
      <a
        onClick={() => {
          setModal(false);
          setReset(false);
          setError({});
          setSuccess(false);
        }}
      >
        Close
      </a>
    </div>
  );

  const Actions = () => {
    if (showLoading) return <LoadingBtns />;
    if (showSuccess) return <SuccessBtns />;
    if (showReset) return <ResetBtns />;
    if (error.title) return <ErrorBtns />;
    return null;
  };

  return (
    <Modal
      id="upload-modal"
      name="upload-modal"
      containerClass="container"
      showButtons={false}
      modals={{}}
    >
      <Icon />
      <Message />
      <Actions />
    </Modal>
  );
}

UploadModal.propTypes = {
  showLoading: PropTypes.bool,
  showReset: PropTypes.bool,
  showSuccess: PropTypes.bool,
  setModal: PropTypes.func,
  setError: PropTypes.func,
  setReset: PropTypes.func,
  setPreview: PropTypes.func,
  setSuccess: PropTypes.func,
  upload: PropTypes.func,
  rows: PropTypes.arrayOf(PropTypes.any || null),
  deletePricingFile: PropTypes.func,
  error: PropTypes.objectOf({
    title: PropTypes.string,
    message: PropTypes.string,
    errors: PropTypes.arrayOf(PropTypes.string)
  })
};
