export default (state) => ({
  dropdowns: {
    isModuleListOpen: state.dropdowns === 'moduleList',
    isMonitoringConfigurationOpen: state.dropdowns === 'monitoringConfiguration',
    isStorageListOpen: state.dropdowns === 'storageList',
    isStorageQuantityOpen: state.dropdowns === 'storageQuantity',
    isInverterQuantityOpen: state.dropdowns === 'inverterQuantity',
    isStorageListWithMultiInverterOpen: state.dropdowns === 'storageListWithMultiInverter',
    isStorageQuantityWithMultiInverterOpen: state.dropdowns === 'storageQuantityWithMultiInverter',
    isExpansionPackQuantityOpen: state.dropdowns === 'expansionPackQuantity',
    isEvChargerListOpen: state.dropdowns === 'evChargerList',
    isEvChargerQuantityOpen: state.dropdowns === 'evChargerQuantity',
    isEvOutletListOpen: state.dropdowns === 'evOutletList',
    isEvOutletQuantityOpen: state.dropdowns === 'evOutletQuantity',
    isDesignListOpen: state.dropdowns === 'designList'
  }
});
