export default {
  CASH: 'cash',
  LOAN: 'loan',
  LEASE: 'lease',
  PPA: 'pPA',
  AVAILABLE_FINANCIAL_OFFERINGS: [ 'loan', 'pPA', 'lease', 'cash' ],
  ERROR_MESSAGE: {
    DEFAULT_PRICES_ERROR_MESSSAGE: 'Only one pricing default is accepted. Please remove one of the options.',
    MINIMUM_DEFAULT_VALUE_ERROR: 'Must be at least $1.50',
    MINIMUM_DEALER_VALUE_ERROR: 'Must be at greater than $0'
  },
  INSTALL_DAYS: [ 30, 45, 60, 90, 120, 150, 180 ],
  PRICING_OPTIONS: [ 'pricePerKwh', 'pricePerWatt', 'dealerFeePerWatt' ],
  PREVIEW_COLUMNS: [
    'Status', 'Market', 'Financial Option', 'SKU',
    'Pricing Option', 'Dollar Value',
    'Min Dollar Value', 'Max Dollar Value',
    'System Size Min (kW)', 'System Size Max (kW)'
  ],
  GENERATED_COLUMNS: [
    'Status', 'isDuplicate', 'hasMultiplePo', 'hasOverlappingSize'
  ]
};
