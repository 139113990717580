import u from 'updeep';

export function resetArrayPairings() {
  return {
    type: 'resetArrayPairings',
    payload: u.updateIn('design.moduleArrays.*.roofPlaneNumber', null)
  };
}

export function assignModuleArray(arrayNumber, update) {
  return {
    type: 'assignModuleArray',
    payload: {
      design: {
        moduleArrays: {
          [arrayNumber - 1]: update
        }
      }
    }
  };
}

export function hoverModuleArray(hoveredModuleArray) {
  return {
    type: 'hoverModuleArray',
    payload: { hoveredModuleArray }
  };
}
