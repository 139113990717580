import React, { useState } from 'react';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import Modal from './Modal';
import { formatCurrency } from '../utils/formatNumber';
import { register } from '../utils/redux';
import { gaTrackEvent } from '../utils/googleAnalytics';
import { leasePpaFullPrepaymentEnabled, leasePpaDownPaymentEnabled } from '../utils/backendFeatureFlags';
import { savingsType, settingsType, quoteType, accountType, partialPrepaymentType } from '../types';

export function EditLeaseModal({ settings, quote, account, partialPrepayment,
  updateLease }) {
  const [ paymentEscalator, setPaymentEscalator ] = useState(get(settings, 'lease.paymentEscalator'));
  const [ downPayment, setDownPayment ] = useState(get(settings, 'lease.downPayment'));
  const [ fullPrepayment, setFullPrepayment ] = useState(get(settings, 'lease.fullPrepayment'));
  const availableEscalaton = quote.finEng && quote.finEng.leaseEscalator ?
    Object.keys(quote.finEng.leaseEscalator).map(Number).sort() : [];
  return (
    <Modal
      name="edit-lease"
      onSave={() => {
        updateLease({
          quote,
          data: {
            downPayment,
            paymentEscalator,
            fullPrepayment
          }
        });
      }}
      showButtonsTop={false}
    >
      {leasePpaDownPaymentEnabled() && (<div className="form-group down-payment">
        <label>Down Payment</label>

        <div className="input-container">
          <select
            name="down-payment"
            value={downPayment}
            disabled={quote.locked}
            onChange={(e) => setDownPayment(parseInt(e.target.value, 10))}
            onFocus={() => gaTrackEvent('quotePage:savingsTab:leaseDownPaymentSelection')}
          >
            { partialPrepayment.map((x, i) => {
              return <option key={i} value={x}>{formatCurrency(x, 0)}</option>;
            }) }
          </select>
        </div>

      </div>)}

      <div className="form-group payment-escalator">
        <label>Payment Escalator</label>

        <div className="input-container">
          <select
            name="payment-escalator"
            value={paymentEscalator}
            disabled={quote.locked}
            onChange={(e) => setPaymentEscalator(parseFloat(e.target.value))}
            onFocus={() => gaTrackEvent('quotePage:savingsTab:leasePaymentEscalatorSelection')}
          >
            {availableEscalaton.map((escalation) =>
              <option value={escalation}>{escalation}</option>
            )}
          </select>
        </div>

      </div>

      {leasePpaFullPrepaymentEnabled() && (<div className="form-group full-prepayment">
        <label htmlFor="full-prepayment">Full Prepayment</label>
        <div className="checkbox input-container">
          <label>
            <input
              id="full-prepayment"
              type="checkbox"
              checked={fullPrepayment}
              onChange={(e) => setFullPrepayment(e.target.checked)}
              onClick={() => gaTrackEvent('quotePage:savingsTab:leaseFullPrepaymentOption')}
              disabled={quote.locked || !account.prePaymentAllowedState}
            />
            <span />
          </label>
        </div>
      </div>)}

    </Modal>
  );
}

EditLeaseModal.propTypes = {
  savings: savingsType.isRequired,
  settings: settingsType.isRequired,
  quote: quoteType.isRequired,
  account: accountType.isRequired,
  partnerIsNewHomes: PropTypes.bool.isRequired,
  partialPrepayment: partialPrepaymentType.isRequired,
  updateLease: PropTypes.func.isRequired
};

export default register(
  [
    'savingsSelector', 'settingsSelector', 'quoteSelector',
    'accountSelector', 'partialPrepayment', 'partnerIsNewHomesSelector'
  ],
  ['updateLease'],
  EditLeaseModal
);
