import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-bootstrap-modal';
import ModuleSection from './ModuleSection';
import InverterSection from './InverterSection';
import AccessoriesSection from './AccessoriesSection';
import { register } from '../../utils/redux';
import { accountType, designType, moduleSelectionModalType, pvModuleType, quoteType, settingsType } from '../../types';
import { addEnhancedPerformanceWarrantyCost } from '../../utils/EddieCalculations/finance';
import { guardAccessoriesEnabled } from '../../utils/backendFeatureFlags';
import { accessoriesToShow } from '../../utils/admin_portal/quoteHelpers';
import { isSunPowerModule } from '../../utils/selectedModuleValidationUtility';
import { getPvModuleSeriesDeatils } from '../../utils/EddieCalculations/design';

function getRackingName(racking) {
  return racking === 'Invisimount' ? 'InvisiMount' : racking;
}

function pvModuleSeriesIconImgUrl(series, pvModuleSeriesDetails) {
  const details = getPvModuleSeriesDeatils(series, pvModuleSeriesDetails);
  return details.iconImg;
}

export function ModuleSelectionModal({
  design,
  quote,
  account,
  moduleSelectionModal,
  closeModuleSelectionModal,
  saveModuleMonitoringRacking,
  updateModuleSelectionModal,
  settings,
  updateSettings,
  saveSettings,
  pvModules,
  pvModuleSeriesDetails
}) {
  const accessories = accessoriesToShow(quote.adminFinConfigs, settings);
  const selectedPvModule = pvModules.find((pvModule) => pvModule.itemId === moduleSelectionModal.pvModuleItemId);

  const validateAccessoriesSelection = () => {
    if (!isSunPowerModule(selectedPvModule)) {
      updateModuleSelectionModal('isSkirtGuard', false);
    }
  };

  const saveModal = () => {
    validateAccessoriesSelection();
    saveModuleMonitoringRacking({
      quote,
      pvModuleItemId: moduleSelectionModal.pvModuleItemId,
      monitoringConfigurationId: moduleSelectionModal.monitoringConfigurationId,
      rackingType: moduleSelectionModal.selectedRacking,
      isSkirtGuard: moduleSelectionModal.isSkirtGuard,
      isCritterGuard: moduleSelectionModal.isCritterGuard
    });
    if (settings.loan.enhancedPerformanceWarranty) {
      const selectedPvModule = pvModules.find((pvModule) => pvModule.itemId === moduleSelectionModal.pvModuleItemId);
      const watts = parseInt(selectedPvModule.item.summaryDescription.replace(/\D/g, ''), 10);
      if (!addEnhancedPerformanceWarrantyCost(watts)) {
        updateSettings('loan', 'enhancedPerformanceWarranty', false);
        const loan = { enhancedPerformanceWarranty: false };
        saveSettings(quote, { ...settings, loan });
      }
    }
  };

  return (
    <Modal
      className="module-selection-modal"
      show={design.moduleSelectionModal}
      onHide={closeModuleSelectionModal}
      aria-labelledby="ModalHeader"
    >
      <Modal.Header closeButton={true} />
      <Modal.Body className="module-selection-modal-body">
        <div className="module-selection-modal-title">
          <div className="module-selection-icon-black">
            &nbsp;
          </div>
          Equipment
        </div>
        <div className="row">
          <div className="column">
            <ModuleSection
              title="Series"
              section="series"
              prop="selectedSeries"
              getText={() => {}}
              getImageUrl={(value) => pvModuleSeriesIconImgUrl(value, pvModuleSeriesDetails)}
            />
            <ModuleSection title="Color" section="colors" prop="selectedColor" getText={(value) => value} />
            <ModuleSection
              title="Model" section="models" prop="selectedModel" getText={(value) => value}
              noImage={true}
            />
          </div>
          <div className="divider" />
          <div className="column">
            <InverterSection />
            <ModuleSection
              title="Racking"
              section="rackings"
              prop="selectedRacking"
              getText={(value) => getRackingName(value)}
            />
          </div>
        </div>
        <div className="row">
          { account.features.addersInEddie
            && guardAccessoriesEnabled()
            && accessories.length > 0
            && moduleSelectionModal.showAdders &&
            (!isSunPowerModule(selectedPvModule) ?
              (
                <AccessoriesSection
                  title="Accessories"
                  accessories={['critter_guard']}
                />
              ) :
              (<AccessoriesSection title="Accessories" accessories={accessories} />)
            )
          }
        </div>
      </Modal.Body>
      <Modal.Footer>
        <a onClick={closeModuleSelectionModal}>Cancel</a>
        <button className="btn btn-primary" onClick={saveModal}>
          Accept Selection
        </button>
      </Modal.Footer>
    </Modal>
  );
}

ModuleSelectionModal.propTypes = {
  design: designType.isRequired,
  quote: quoteType.isRequired,
  account: accountType.isRequired,
  moduleSelectionModal: moduleSelectionModalType.isRequired,
  closeModuleSelectionModal: PropTypes.func.isRequired,
  saveModuleMonitoringRacking: PropTypes.func.isRequired,
  settings: settingsType.isRequired,
  updateSettings: PropTypes.func.isRequired,
  saveSettings: PropTypes.func.isRequired,
  pvModules: PropTypes.arrayOf(pvModuleType).isRequired,
  updateModuleSelectionModal: PropTypes.func.isRequired,
  pvModuleSeriesDetails: PropTypes.arrayOf(PropTypes.object)
};

export default register(
  [
    'designSelector', 'quoteSelector', 'accountSelector',
    'moduleSelectionModalSelector', 'settingsSelector', 'pvModulesSelector',
    'pvModuleSeriesDetailsSelector'
  ],
  [
    'closeModuleSelectionModal', 'saveModuleMonitoringRacking', 'updateSettings', 'saveSettings',
    'updateModuleSelectionModal'
  ],
  ModuleSelectionModal
);
