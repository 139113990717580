import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { register } from '../../utils/redux';
import { formatCurrency } from '../../utils/formatNumber';
import LineItem from './LineItem';
import ValueBox from '../ValueBox';
import { gaTrackEvent } from '../../utils/googleAnalytics';
import { businessFunctionsType, creditType, loanSystemCostType,
          quoteType, savingsType, settingsType } from '../../types';
import { MOSAIC } from '../../utils/EddieCalculations/helpers/setting';
import { isEvChargerSelected, showEvCharger } from '../../utils/EvChargers/evChargers';

export function LoanView({
  loanSystemCost,
  quote,
  credit,
  toggleModal,
  pricingDisclaimer,
  isPublic,
  isV2CalculationState,
  partnerIsNewHomes,
  businessFunctions,
  settings
}) {
  const editLoanClassNames = cx('edit-loan supporting-text');
  const evChargerSelected = isEvChargerSelected(quote);
  const showEvChargerInCost = showEvCharger(partnerIsNewHomes, quote, businessFunctions) && evChargerSelected;
  const apr = partnerIsNewHomes ? loanSystemCost.loanInterestRate : settings.loan.aprPercentage;

  return (
    <div className="loan loan-container">
      {
        credit.loanIsDeclined ? (
          <p className="declined-text">Declined</p>
        ) : (
          <div className="breakout-summary">
            <div className="loan-summary">
              <ValueBox
                label="Co-Payment" currency={true}
                rounded={true}
                value={loanSystemCost.coPayment}
              />
              <ValueBox
                label="Monthly"
                currency={true}
                rounded={true}
                value={loanSystemCost.monthlyPayments[0].costWithTax}
              />
            </div>
            <div className="breakout-summary-breakdown-container">
              <span className="breakout-summary-breakdown supporting-text">
                After {formatCurrency(loanSystemCost.coPayment, 0)} co-payment,
                <br />
                pay {formatCurrency(loanSystemCost.monthlyPayments[0].costWithTax)}/month
                for {loanSystemCost.termInMonths / 12} years{' '}
                at {parseFloat(apr).toFixed(2)}% apr
              </span>
              { !isPublic && !quote.locked && (quote.creditBureau === MOSAIC) && (
                <a
                  href="#"
                  className={editLoanClassNames}
                  onClick={(e) => {
                    gaTrackEvent('quotePage:savingsTab:loanSubTab:editLink');
                    toggleModal('edit-loan', e.target);
                  }}
                >
                  Edit Loan
                </a>
              )}
              {!isV2CalculationState &&
                <div className="breakout-line-items">
                  <LineItem label="Loan amount" value={loanSystemCost.loanAmount} />
                  <LineItem label="Co-payment" value={loanSystemCost.coPayment} />
                  <hr />
                  <LineItem label="Total Contract Price" value={loanSystemCost.totalContractPrice} />
                  {loanSystemCost.stateAndLocalIncentives > 0 &&
                    <LineItem
                      label="State & local incentives (est.)*"
                      value={loanSystemCost.stateAndLocalIncentives}
                      sign="-"
                    />
                  }
                  {loanSystemCost.federalTaxCredit > 0 &&
                    <LineItem
                      label="Federal tax credit (est.)*"
                      value={loanSystemCost.federalTaxCredit} sign="-"
                    />
                  }
                  {loanSystemCost.stateTaxCredit > 0 &&
                    <LineItem
                      label="State tax credit (est.)*"
                      value={loanSystemCost.stateTaxCredit} sign="-"
                    />
                  }
                  <hr />
                  <LineItem
                    label={showEvChargerInCost ? 'Net Cost (est. w/EV Charger)' : 'Net Cost (est.)*'}
                    value={loanSystemCost.netCost}
                    rounded={true}
                    labelClass={showEvChargerInCost ? 'label bold' : 'label'}
                  />
                </div>
              }
              {isV2CalculationState &&
                <div className="breakout-line-items">
                  <LineItem label="Total Contract Price" value={loanSystemCost.totalContractPrice} />
                  {loanSystemCost.stateAndLocalIncentives > 0 &&
                    <LineItem
                      label="State & local incentives (est.)*"
                      value={loanSystemCost.stateAndLocalIncentives}
                      sign="-"
                    />
                  }
                  <hr />
                  <LineItem
                    label="Net Contract Price"
                    value={loanSystemCost.netContractPrice}
                  />
                  <LineItem label="Co-payment" value={loanSystemCost.coPayment} />
                  <hr />
                  <LineItem
                    label="Loan Amount"
                    value={loanSystemCost.loanAmount}
                  />
                  {loanSystemCost.federalTaxCredit > 0 &&
                    <LineItem
                      label="Federal tax credit (est.)*"
                      value={loanSystemCost.federalTaxCredit} sign="-"
                    />
                  }
                  {loanSystemCost.stateTaxCreditWithoutTaxAbatement > 0 &&
                    <LineItem
                      label="State tax credit (est.)*"
                      value={loanSystemCost.stateTaxCreditWithoutTaxAbatement} sign="-"
                    />
                  }
                  {loanSystemCost.year1TaxAbatement > 0 &&
                    <LineItem
                      label="Yr. 1 property tax abatement (est.)*"
                      value={loanSystemCost.year1TaxAbatement} sign="-"
                    />
                  }
                  {loanSystemCost.year2To4TaxAbatement > 0 &&
                    <LineItem
                      label="Yr. 2-4 property tax abatement (est.)*"
                      value={loanSystemCost.year2To4TaxAbatement} sign="-"
                    />
                  }
                  <hr />
                  <LineItem
                    label={showEvChargerInCost ? 'Net Cost (est. w/EV Charger)' : 'Net Cost'}
                    value={loanSystemCost.netCost}
                    rounded={true}
                    labelClass={showEvChargerInCost ? 'label bold' : 'label'}
                  />
                </div>
              }
              { pricingDisclaimer }
              <hr />
              <span className="line-item breakout-summary-breakdown supporting-text">
                <div className="label">
                  SunPower will provide the complete, installed system and will
                   provide an installation warranty in addition to the equipment
                   warranties, subject to the terms and conditions of your home
                   improvement contract. In addition to warranties, SunPower
                   will provide a production estimate and perform testing
                   upon successful installation to ensure that the installed
                   system is working as designed.
                </div>
              </span>
              <hr />
            </div>
          </div>
        )
      }
    </div>
  );
}

LoanView.propTypes = {
  settings: settingsType.isRequired,
  loanSystemCost: loanSystemCostType.isRequired,
  quote: quoteType.isRequired,
  credit: creditType.isRequired,
  toggleModal: PropTypes.func.isRequired,
  pricingDisclaimer: PropTypes.element,
  isPublic: PropTypes.bool,
  isV2CalculationState: PropTypes.bool.isRequired,
  savings: savingsType.isRequired,
  partnerIsNewHomes: PropTypes.bool,
  businessFunctions: businessFunctionsType
};

export default register(
  [
    'loanSystemCostSelector', 'quoteSelector', 'creditSelector',
    'isPublicSelector', 'savingsSelector', 'isV2CalculationState',
    'partnerIsNewHomesSelector', 'businessFunctionsSelector', 'settingsSelector'
  ],
  [
    'toggleModal'
  ],
  LoanView
);
